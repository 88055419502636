import {openMenu} from "../../Function/common";
import {useTranslate} from "../../Hooks/translate.hook";

export default function Contact (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // HANDLER START
    function textHandler(e, index) {
        props.setContact(props.contact.map((item, i) => {
            if (i === index) {
                item.text = e.target.value;
                return item;
            } else {
                return item;
            }
        }))
    }

    function noteHandler (e, index) {
        props.setContact(props.contact.map((item, i) => {
            if (i === index) {
                item.note = e.target.value;
                return item;
            } else {
                return item;
            }
        }))
    }
    // HANDLER END

    // FUNC START
    function chooseContact (type, index) {
        props.setContact(props.contact.map((item, i) => {
            if (i === index) {
                item.type = type;
                return item;
            } else {
                return item;
            }
        }))
    }

    function addContact () { props.setContact([...props.contact, {type: "mobile"}]) }

    function deleteContact (index) { props.setContact(props.contact.filter((item, i) => i !== index)) }
    // FUNC END

    return (
        <>
            <div className="contact-container">
                {
                    props.contact.map((item, index) => (
                        <div className="crm-contact m-b-xs">
                            <div className="c-input-group">
                                <div className="c-input-group-prepend" onClick={e => openMenu(e)}>
                                    <button className="btn btn-white dropdown-toggle btn-type" type="button">
                                        <i className={`fa fa-${item.type} fa-fw`} />
                                        <span className="caret" />
                                    </button>
                                    <ul className="dropdown-menu crm-contact-type">
                                        <li>
                                            <a onClick={() => chooseContact("mobile", index)}>
                                                <i className="fa fa-mobile fa-fw"/> { translate('Мобильный') }
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("whatsapp", index)}>
                                                <i className="fa fa-whatsapp fa-fw" /> WhatsApp
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("envelope-o", index)}>
                                                <i className="fa fa-envelope-o fa-fw" /> E-mail
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("skype", index)}>
                                                <i className="fa fa-skype fa-fw" /> Skype
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("globe", index)}>
                                                <i className="fa fa-globe fa-fw" /> { translate('Ссылка') }
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("map-marker", index)}>
                                                <i className="fa fa-map-marker fa-fw" /> { translate('Адрес') }
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("instagram", index)}>
                                                <i className="fa fa-instagram fa-fw" /> Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => chooseContact("facebook", index)}>
                                                <i className="fa fa-facebook fa-fw" /> Facebook
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*CONTACT*/}
                                <input
                                    type="text"
                                    className="form-control contact-input crm-phone-input"
                                    name="contact"
                                    placeholder={ translate('Мобильный') }
                                    value={item.text}
                                    onChange={e => textHandler(e, index)}
                                />
                                {/*NOTE*/}
                                <input
                                    type="text"
                                    className="form-control"
                                    name="contact_note"
                                    placeholder={ translate('Примечание') }
                                    value={item.note}
                                    onChange={e => noteHandler(e, index)}
                                />
                                {
                                    props.contact.length <= 1 ? null :
                                        (
                                            <div className="c-input-group-append">
                                                <a className="btn btn-white text-danger delete-contact" title="Удалить" onClick={() => deleteContact(index)}>
                                                    <i className="ion-minus" />
                                                </a>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <a className="pull-right crm-dashed-link add-contact" title="Добавить контакт" onClick={addContact}>
                + { translate('еще') }
            </a>
        </>
    )
}