import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useContext, useEffect, useState} from "react";
import {filterDisable, findObject} from "../../../../../Function/common";
import { calculateSalary } from "../../../../../Function/lessonFunction";
import {SettingContext} from "../../../../../Context/SettingContext";
import typeLessons from "../../../../../Assets/data/JSON/typeLessons.json";
import URL from "../../../../../Api/URL.json";
import currency from "../../../../../Assets/data/JSON/currency.json";

export default function TableRegisterOfLesson ({ params }) {

    // VALUE START
    let countCustomers = 0;
    // VALUE END

    // CONTEXT START
    const { lessonSetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ employee, setEmployee ] = useState({});
    const [ wage, setWage ] = useState([]);
    const [ customersLesson, setCustomersLesson ] = useState([]);
    const [ groupsLesson, setGroupsLesson ] = useState([]);
    const [ managers, setManagers ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {
            const lessons = await request(`${URL.back_end}/filter_employee_lesson`, 'POST', { ...params });
            const manager = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } });
            const [ employeeData ] = await request(`${URL.back_end}/employee/${params.employee_id}`, 'GET');
            const wages = await request(`${URL.back_end}/employee_wage/${params.employee_id}`, 'GET');

            let customers = [];
            let groups    = [];

            if (lessons.length) {
                customers = await request(`${URL.back_end}/filter_lesson_customer`, 'POST', { lesson_ids : lessons.map(item => item.lesson_id) });
                groups    = await request(`${URL.back_end}/lesson_group`, 'GET');
            }

            if (error) throw error

            setSql(lessons.filter((lesson) => lesson.status === 1).sort( (a, b) => new Date(a.date_start) - new Date(b.date_start) ));
            setEmployee(employeeData);
            setWage(wages);
            setCustomersLesson(customers);
            setGroupsLesson(groups);
            setManagers(manager.users);
        }

        fetchData().catch(e => console.log(e))

    }, [params])
    // INIT DATA END

    // FUNC START
    function calculateTime (date_start, date_end) {
        let [ hourStart, minStart ] = date_start.split('T')[1].slice(0, 5).split(':');
        let [ hourEnd, minEnd ] = date_end.split('T')[1].slice(0, 5).split(':');

        return (( Number(hourEnd) === 0 ? 24 : Number(hourEnd) ) * 60 + Number(minEnd)) - (Number(hourStart) * 60 + Number(minStart));
    }

    function calculateAllTime () {
        let sumTime = 0;

        for (let i = 0; i < sql.length; i++) {
            sumTime += calculateTime(sql[i].date_start, sql[i].date_end);
        }

        return sumTime;
    }
    // FUNC END

    // RENDER TABLE BODY START
    function renderTableBody (item, index) {
        const group   = groupsLesson.filter(g => g.lesson_id === item.lesson_id);
        const members = customersLesson.filter(cust => cust.lesson_id === item.lesson_id);
        const { salary } = calculateSalary([item], wage, members);

        countCustomers += members.length;

        function renderPresence (customerArr) {
            const customers = customerArr.filter((customer) => customer.presence === 1);

            if (!customers.length) return <></>;

            return customers.map((customer) => (
                <a href={`/company/1/customer/view?id=${customer.customer_id}`} target="_blank"> { customer.name } </a>
            ));
        }

        function renderNoPresence (customersArr) {
            const customers = customersArr.filter(customer => customer.presence === 0);

            if (!customers.length) return <></>;

            return (
                <div className="m-t-xs">
                    <i>
                        Відсутність : {
                            customers.map(customer => <a href={`/company/1/customer/view?id=${customer.customer_id}`} target="_blank"> {customer.name} </a>)
                        }
                    </i>
                </div>
            )
        }

        function renderManagers (customers) {

            if (!customers.length) return <></>;

            const array = [];

            for (let i = 0; i < customers.length; i++) {
                for (let j = 0; j < managers.length; j++) {
                    if (customers[i].manager === managers[j].user_id) {
                        array.push(managers[j].name);
                    }
                }
            }

            if (!array.length) return '—';

            return array.join(', ');
        }

        return (
            <tr>
                <td className="text-center"> { index } </td>
                <td className="text-center"> { item.date_start.split('T')[0] } </td>
                <td>
                    <div>
                        {
                            item.type_lesson === 2 ?
                                group.map(g => g.title).join(', ') :
                                renderPresence(members)
                        }
                    </div>
                    { renderNoPresence(members) }
                </td>
                <td>
                    { renderManagers(members) }
                </td>
                <td>
                    { findObject(filterDisable(lessonSetting.subject), item.subject).name }
                    <small className="text-muted"> ({ translate(typeLessons[item.type_lesson]) }) </small>
                </td>
                <td className="text-center"> { members.length } { translate('шт.') } </td>
                <td className="text-center"> { calculateTime(item.date_start, item.date_end) } </td>
                <td className="text-right"> { salary ? salary : '—' } </td>
            </tr>
        )
    }
    // RENDER TABLE BODY END

    return (
        <div className="ibox-content m-b-sm">
            <div className="row">
                <div className="col-sm-12 text-center m-b-sm">
                    <h3 className="font-noraml">
                        <strong> { translate('Реестр проведённых уроков') } </strong> — { employee.name }
                    </h3>
                    <h4 className="font-noraml">
                        { translate('За период с') } { params.date_start } { translate('по') } { params.date_end }
                    </h4>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-condensed">
                    <thead>
                        <tr>
                            <th className="text-center"> № </th>
                            <th className="text-center"> { translate('Дата') } </th>
                            <th className="text-center"> { translate('Группа') } / { translate('Клиент') } </th>
                            <th className="text-center"> { translate('Ответственный') } </th>
                            <th className="text-center"> { translate('Предмет') } </th>
                            <th className="text-center"> { translate('Люди') } </th>
                            <th className="text-center"> { translate('Минуты') } </th>
                            <th className="text-right"> { translate('Ставка') } { currency[basicSetting?.baseOption.currency] } </th>
                        </tr>
                    </thead>
                    <tbody>
                        { sql.map((item, index) => renderTableBody(item, index + 1)) }
                        <tr>
                            <td className="text-right font-bold" colSpan={5}> { translate('Итого') } </td>
                            <td className="text-center font-bold"> { countCustomers } </td>
                            <td className="text-center font-bold"> { calculateAllTime() } </td>
                            <td className="text-right">
                                { calculateSalary(sql, wage, customersLesson).salary }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}