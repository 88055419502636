import {useContext, useEffect, useRef, useState} from "react";
import Gender from "../../../Components/Input/Gender";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, closeModal, findCustomObject} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useArchive} from "../../../Hooks/archive.hook";
import {errorNotify} from "../../../Function/Notify";

export default function AddUser (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting } = useContext(SettingContext)
    // CONTEXT END

    // HOOK START
    const { error, request } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [role, setRole] = useState({});
    const [name, setName] = useState('');
    const [employee, setEmployee] = useState({});
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState();
    const [password, setPassword] = useState('');
    const [language, setLanguage] = useState('Український');
    const [phone, setPhone] = useState('');
    const [helpBlock, setHelpBlock] = useState('none');
    // STATE END

    // STATE VALID START
    const [formValid, setFormValid] = useState(false);

    const [nameError, setNameError] = useState('Необходимо заполнить «ФИО».');
    const [emailError, setEmailError] = useState('Необходимо заполнить «E-mail».');
    const [passwordError, setPasswordError] = useState('Необходимо заполнить «Пароль».');

    const [nameDirty, setNameDirty] = useState(false);
    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    // STATE VALID END

    // VALID USE EFFECT START
    useEffect(() => {
        if (role.role_id === undefined || nameError || emailError || passwordError) {
            setFormValid(false);
        } else {
            setFormValid(true)
        }
    }, [role, name, email, password])
    // VALID USE EFFECT END

    // HANDLER START
    function nameHandler (e) {
        setName(e.target.value);
        if (e.target.value.length === 0) {
            setNameError(`${translate('Необходимо заполнить')} «${translate('ФИО')}».`);
        } else {
            setNameError('');
        }
    }
    function emailHandler (e) {
        setEmail(e.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(e.target.value)) {
            setEmailError(`${translate('Неверный')} «E-mail» ${translate('адрес')}.`);
        } else {
            setEmailError('');
        }
    }
    function passwordHandler (e) {
        setPassword(e.target.value);
        const re_number = /(?=.*[0-9]).*/;
        const re_letter = /(?=.*[^A-Za-z0-9]).*/;

        if (e.target.value.length <= 6) {
            setPasswordError(`${translate('Количество символов должно быть не меньше 6.')}`)
        } else {
            if (!re_number.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать хотя бы одну цифр.')}`)
            } else if (re_letter.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать только латинские буквы и цифры.')}`)
            } else {
                setPasswordError('');
            }
        }
    }
    function helpBlockHandler () {
        helpBlock === "none" ? setHelpBlock("block") : setHelpBlock("none");
    }
    const blurHandler = (e) => {
        // eslint-disable-next-line default-case
        switch (e.target.name) {
            case 'name':
                setNameDirty(true)
                break;
            case 'email':
                setEmailDirty(true)
                break;
            case 'password':
                setPasswordDirty(true)
                break;
        }
    }
    // HANDLER END

    // ADD USER START
    async function addUser (e) {
        e.preventDefault();

        document.getElementById('btn_submit').disabled = true;

        let user = {
            role: role.role_id,
            name,
            email,
            gender,
            password,
            phone,
            systemId: Object.keys(employee).length ? employee.employee_id : null,
            interface_language: language,
            f_removed: 0
        }

        filterDisableEntity(customSetting.customField, "user").map(item => {
            user[item.systemName] = document.forms["add_user"].elements[item.systemName].value
        })

        let data;

        try {
            data = await request(`${URL.back_end}/register`, 'POST', user)
        } catch (e) {
            document.getElementById('btn_submit').disabled = false;
            return errorNotify(translate(e.message));
        }

        await pushArchive(1, 'user', data.user_id, user, null);

        user.password = data.password;

        user["user_id"] = data.user_id;
        props.updateSql([...props.sql, user]);
        closeModal(e, modal)
    }
    // ADD USER END

    return (
        <div id="add-user" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" name="add_user" onSubmit={e => addUser(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                            <h4 className="modal-title">
                                { translate('Добавить пользователя') }  &nbsp;—&nbsp;&nbsp;
                                <span className="dropdown">
                                    <a className="dropdown-toggle crm-dashed-link text-lowercase">
                                        { translate('Активен') }
                                        <span className="caret" />
                                    </a>
                                    <ul role="menu" className="dropdown-menu">
                                        <li>
                                            <a>{translate('Активен')}</a>
                                        </li>
                                        <li>
                                            <a>{translate('Заблокирован')}</a>
                                        </li>
                                    </ul>
                                </span>
                            </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*Роль(и)*/}
                            <div className="form-group form-group field-user-role_ids required">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Роль(и)') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseSingleObject
                                        sql={props.roleData}
                                        setProps={setRole}
                                        topicSearch="name"
                                        type_id="role_id"
                                    />
                                </div>
                            </div>
                            {/*ФИО + ПОЛ*/}
                            <div className={`form-group required ${(nameDirty && nameError) ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-name">{translate('ФИО')}</label>
                                </div>
                                <div className="col-sm-6" style={{paddingRight: "0"}}>
                                    <input
                                        type="text"
                                        id="user-name"
                                        className="form-control"
                                        name="name"
                                        placeholder={translate('Например, Иванов Иван')}
                                        autoComplete="off"
                                        value={name}
                                        onChange={e => nameHandler(e)}
                                        onBlur={e => blurHandler(e)}
                                    />
                                    <div className="small text-danger">{(nameDirty && nameError) ? nameError : null}</div>
                                </div>
                                <div className="col-sm-3" style={{paddingLeft: "1px"}}>
                                    <Gender
                                        setProps={setGender}
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            {
                                role.role_id ?
                                    findCustomObject(props.roleData, "role_id", role.role_id).interface === 2 ?
                                        (
                                            <div className="form-group required">
                                                <div className="col-sm-3">
                                                    <label className="control-label" htmlFor="user-email">Педагог</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <InputChooseSingleObject
                                                        sql={props.employeeData}
                                                        setProps={setEmployee}
                                                        topicSearch="name"
                                                        type_id="employee_id"
                                                    />
                                                </div>
                                            </div>
                                        )
                                        : null
                                    : null
                            }
                            {/*EMAIL*/}
                            <div className={`form-group field-user-email required ${(emailDirty && emailError) ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-email">E-mail</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="user-email"
                                        name="email"
                                        className="form-control"
                                        maxLength="255"
                                        placeholder={`${translate('Например')}, user@mail.ua`}
                                        value={email}
                                        onChange={e => emailHandler(e)}
                                        onBlur={e => blurHandler(e)}
                                    />
                                    <div className="help-block">{(emailDirty && emailError) ? emailError : null}</div>
                                </div>
                            </div>
                            {/*PASSWORD*/}
                            <div className={`form-group field-user-password required ${(passwordDirty && passwordError) ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-password">{translate('Пароль')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="user-password"
                                        name="password"
                                        className="new-pass form-control"
                                        autoComplete="off"
                                        value={password}
                                        onChange={e => passwordHandler(e)}
                                        onBlur={e => blurHandler(e)}
                                    />
                                    <div className="help-block">{(passwordDirty && passwordError) ? passwordError : null}</div>
                                </div>
                            </div>
                            {/*INTERFACE LANGUAGE*/}
                            <div className="form-group field-user-language">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Язык интерфейса') }
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <select id="user-language" className="form-control" onChange={e => setLanguage(e.target.value)}>
                                        <option value="ua"> Українська </option>
                                        <option value="ru"> Русский </option>
                                        <option value="en"> English </option>
                                    </select>
                                </div>
                            </div>
                            {/*PHONE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Номер телефона') }
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <b>{translate('Дополнительные поля')}</b>
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "user").map(item => (
                                        <div className="form-group field-user-inn">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-inn">{item.name}</label>
                                            </div>
                                            <div className="col-sm-9">
                                                { typeField(item) }
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        {/*MODAL FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" disabled={!formValid} id="btn_submit">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}