import {useTranslate} from "../../../../Hooks/translate.hook";
import {findObject, openModal} from "../../../../Function/common";
import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import iconCalendar from "../../../../Assets/data/JSON/iconCalendar.json";
import currency from "../../../../Assets/data/JSON/currency.json";

export default function DiscountRow ({ data, setEditDiscount }) {

    // CONTEXT START
    const { basicSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function editDiscount () {
        setEditDiscount(data);
        openModal('edit-discount');
    }

    function typeDiscount (type) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 1: return translate('Процентная скидка');
            case 2: return translate('Денежная скидка');
        }
    }

    function getCurrency (type) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 1 : return "%";
            case 2 : return currency[basicSetting.baseOption.currency];
        }
    }

    return (
        <div className="row">
            <div className="m-b-xs col-xs-12 p-xxs crm-hover-block">
                <a className="crm-modal-btn crm-thin-link" title={translate('Править скидку')} onClick={editDiscount}>
                    <i className="ion-edit pull-right big" />
                    <div>
                        <strong className="text-success">
                            <i className="fa fa-ticket m-r-xs" />
                            { typeDiscount(data.type_discount) } — { data.amount } { getCurrency(data.type_discount) }
                        </strong>
                    </div>
                    <div className="text-muted m-l-md">
                        {
                            lessonSetting ?
                                JSON.parse(data.lessons).map(item => findObject(lessonSetting.subject, item) ? findObject(lessonSetting.subject, item).name : '').join(', ')
                                : null
                        }
                        &nbsp;—&nbsp;
                        {
                            data.type_lessons ? JSON.parse(data.type_lessons).map(item => (
                                <i className={`${iconCalendar[item]} text-muted m-r-xs`} />
                            )) : null
                        }
                        &nbsp;
                        { data.date_start } – { data.date_end }
                        &nbsp;{ data.note && <b>( { data.note } )</b> }
                    </div>
                </a>
            </div>
        </div>
    )
}