import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import InputMask from "react-input-mask";

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {success} from "../../../../Function/Notify";
import {useTranslate} from "../../../../Hooks/translate.hook";

export default function CalendarOption () {

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [dow, setDow] = useState([]);
    const [minTime, setMinTime] = useState('');
    const [maxTime, setMaxTime] = useState('');
    const [interval, setInterval] = useState('');
    const [initialView, setInitialView] = useState('');

    // CARD
    const [showAudience, setShowAudience] = useState();
    const [showSubject, setShowSubject] = useState();
    const [showTypeAndCustomer, setShotTypeAndCustomer] = useState();
    const [showEmployee, setShowEmployee] = useState();
    const [showNote, setShowNote] = useState();
    const [showGroups, setShowGroups] = useState();
    const [useBrackets, setUseBrackets] = useState();

    // OPTIONS
    const [calendarOptions, setCalendarOptions] = useState();
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!lessonSetting) return

        const { calendarOption } = lessonSetting;

        setDow(calendarOption.dow);
        setMinTime(calendarOption.minTime);
        setMaxTime(calendarOption.maxTime);
        setInterval(calendarOption.interval);
        setInitialView(calendarOption.initialView);
        setShowAudience(calendarOption.showAudience);
        setShowSubject(calendarOption.showSubject);
        setShotTypeAndCustomer(calendarOption.showTypeAndCustomer);
        setShowEmployee(calendarOption.showEmployee);
        setShowNote(calendarOption.showNote);
        setShowGroups(calendarOption.showGroups);
        setUseBrackets(calendarOption.useBrackets);
        setCalendarOptions(calendarOption.calendarOptions);
        checkBoxInit(calendarOption.dow);
    }, [lessonSetting])
    // INIT DATA END

    // HANDLER START
    function dowHandler (e) {
        let elems = document.querySelectorAll('#dow:checked');
        let arr = [].map.call(elems, function (obj) {
            return +obj.value;
        })

        setDow([...arr]);
    }
    function checkBoxInit (arrCard) {

        let arr_card = document.querySelectorAll('#dow');

        for (let i = 0; i < arr_card.length; i++) {
            arr_card[i].checked = false;
        }

        for (let i = 0; i < arr_card.length; i++) {
            for (let j = 0; j < arrCard.length; j++) {
                if (+arr_card[i].value === arrCard[j]) {
                    arr_card[i].checked = true;
                }
            }
        }
    }
    // HANDLER END

    // ADD CALENDAR OPTION START
    async function addCalendarOption (e) {
        e.preventDefault();

        lessonSetting.calendarOption = {
            dow, minTime, maxTime, interval, initialView, showAudience, showSubject, showTypeAndCustomer, showEmployee, showNote, showGroups, useBrackets, calendarOptions
        }

        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return console.log(error)
        success("Изменения сохранены")
    }
    // ADD CALENDAR OPTION END

    return (
        <div className="settings-block__content-content">
            <ToastContainer />
            <div id="settings-container" className="modal-content">
                {/*HEADER*/}
                <div className="modal-header">
                    <h4 className="modal-title"> { translate('Настройки календаря') } </h4>
                </div>
                {/*FORM*/}
                <form className="form-horizontal" onSubmit={e => addCalendarOption(e)}>
                    {/*BODY*/}
                    <div className="modal-body p-md">
                        {/*DOW*/}
                        <div className="form-group required" onChange={e => dowHandler(e)}>
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Рабочие дни') } </label>
                            </div>
                            <div className="col-sm-7">
                                <div style={{ marginBottom: '5px' }}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={1}
                                            id="dow"
                                        /> { translate('Пн') }
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={2}
                                            id="dow"
                                        /> { translate('Вт') }
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={3}
                                            id="dow"
                                        /> { translate('Ср') }
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={4}
                                            id="dow"
                                        /> { translate('Чт') }
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={5}
                                            id="dow"
                                        /> { translate('Пт') }
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={6}
                                            id="dow"
                                        /> { translate('Сб') }
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={0}
                                            id="dow"
                                        /> { translate('Вс') }
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/*WORK TIME*/}
                        <div className="form-group required">
                            <div className="col-sm-4">
                                <label className="control-label" style={{ textAlign: 'left' }}> { translate('Рабочее время') } </label>
                            </div>
                            <div className="col-sm-3">
                                <div className="input-group input-group-sm">
                                    <span className="input-group-addon"> { translate('От') } </span>
                                    <InputMask
                                        type="text"
                                        className="form-control time-input"
                                        mask="99:99"
                                        value={minTime}
                                        placeholder={lessonSetting?.calendarOption.minTime ? lessonSetting.calendarOption.minTime : "__:__"}
                                        onChange={e => setMinTime(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="input-group input-group-sm">
                                    <span className="input-group-addon"> { translate('До') } </span>
                                    <InputMask
                                        type="text"
                                        className="form-control time-input"
                                        mask="99:99"
                                        value={maxTime}
                                        placeholder={lessonSetting?.calendarOption.maxTime ? lessonSetting.calendarOption.maxTime : "__:__"}
                                        onChange={e => setMaxTime(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*INTERVAL*/}
                        <div className="form-group required">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Интервал') } </label>
                            </div>
                            <div className="col-sm-6">
                                <select className="form-control" onChange={e => setInterval(e.target.value)}>
                                    <option value="00:05:00" selected={interval === "00:05:00"}>5 { translate('минут') } </option>
                                    <option value="00:10:00" selected={interval === "00:10:00"}>10 { translate('минут') } </option>
                                    <option value="00:15:00" selected={interval === "00:15:00"}>15 { translate('минут') } </option>
                                    <option value="00:20:00" selected={interval === "00:20:00"}>20 { translate('минут') } </option>
                                    <option value="00:30:00" selected={interval === "00:30:00"}>30 { translate('минут') } </option>
                                    <option value="00:60:00" selected={interval === "00:60:00"}>60 { translate('минут') } </option>
                                </select>
                            </div>
                        </div>
                        {/*INITIAL VIEW*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('По умолчанию открыт') } </label>
                            </div>
                            <div className="col-sm-6">
                                <select className="form-control" value={initialView} onChange={e => setInitialView(e.target.value)}>
                                    <option value="timeGridDay"> { translate('День') } </option>
                                    <option value="timeGridWeek"> { translate('Неделя') } </option>
                                    <option value="dayGridMonth"> { translate('Месяц') } </option>
                                </select>
                            </div>
                        </div>
                        {/*CARD*/}
                        <div className="form-group required">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Карточка') } </label>
                            </div>
                            <div className="col-sm-7">
                                <div style={{marginBottom: "6px"}}>
                                    <label>
                                        <input type="checkbox" onChange={e => setShowAudience(e.currentTarget.checked)} checked={showAudience}/> { translate('Отображать аудиторию') }
                                    </label>
                                    <br />
                                    <label>
                                        <input type="checkbox" onChange={e => setShowSubject(e.currentTarget.checked)} checked={showSubject}/> { translate('Отображать предмет') }
                                    </label>
                                    <br />
                                    <label>
                                        <input type="checkbox" onChange={e => setShotTypeAndCustomer(e.currentTarget.checked)} checked={showTypeAndCustomer}/> { translate('Отображать тип и участников') }
                                    </label>
                                    <br />
                                    <label>
                                        <input type="checkbox" onChange={e => setShowEmployee(e.currentTarget.checked)} checked={showEmployee}/> { translate('Отображать педагога') }
                                    </label>
                                    <br />
                                    <label>
                                        <input type="checkbox" onChange={e => setShowNote(e.currentTarget.checked)} checked={showNote}/> { translate('Отображать комментарий') }
                                    </label>
                                    <br />
                                    {/*<label>*/}
                                    {/*    <input type="checkbox" onChange={e => setShowGroups(e.currentTarget.checked)} checked={showGroups}/> Отображать состав группы*/}
                                    {/*</label>*/}
                                    {/*<br />*/}
                                    {/*<label>*/}
                                    {/*    <input type="checkbox" onChange={e => setUseBrackets(e.currentTarget.checked)} checked={useBrackets}/> Использовать новые строки*/}
                                    {/*</label>*/}
                                </div>
                            </div>
                        </div>
                        {/*OPTIONS*/}
                        {/*<div className="form-group">*/}
                        {/*    <div className="col-sm-4">*/}
                        {/*        <label className="control-label">Опции</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-sm-7">*/}
                        {/*        <label>*/}
                        {/*            <input type="checkbox" onChange={e => setCalendarOptions(e.currentTarget.checked)} checked={calendarOptions}/> Сразу выстраивать уроки по аудиториям*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/*FOOTER*/}
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-sm btn-w-m btn-success">
                            <i className="fa fa-save" /> { translate('Сохранить') }
                        </button>
                        <button type="button" className="btn btn-sm btn-w-m btn-white">
                            { translate('Отмена') }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}