import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {useArchive} from "../../../Hooks/archive.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import male from "../../../Assets/image/male.png";
import female from "../../../Assets/image/female.png";

export default function UserColumns (props) {

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    // SWEET MODAL END

    // BUTTON FUNC START
    function buttonArchive (value) {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";
            // REQUEST
            await request(`${URL.back_end}/user/${props.data.user_id}`, 'PUT', { f_removed : value });

            if (value === 0) {
                await pushArchive(4, 'user', props.data.user_id, { f_removed : 1 }, { f_removed : 0 })
            } else {
                await pushArchive(3, 'user', props.data.user_id, { f_removed : 0 }, { f_removed : 1 })
            }

            props.sql.forEach(function (item, i) {
                if (props.data.user_id === item.user_id) {
                    props.sql[i].f_removed = value;
                    props.updateSql([...props.sql]);
                }
            })
        })
    }

    async function buttonDelete () {
        await request(`${URL.back_end}/user/${props.data.user_id}`, 'DELETE');

        window.location.reload();
    }
    // BUTTON FUNC END

    // SELECT FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }
    // SELECT FUNC END

    return (
        <tr className={`w1 ${props.data.f_removed !== 0 ? "danger" : null}`}>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input type="checkbox" className="kv-row-checkbox" name="selection[]" value={props.data.user_id} onClick={getSelect} />
            </td>
            <td className="w1">
                { props.data.user_id }
            </td>
            <td className="w1">
                <img
                    className="img-circle"
                    src={props.data.avatar || (props.data.gender === "Мужчина" ? male : female)}
                    alt=""
                    style={{width: "35px", height: "35px"}}
                />
            </td>
            <td className="w1">
                <a href={`mailto:${props.data.email}`}>
                    { props.data.email }
                </a>
            </td>
            <td className="w1">
                { props.data.name }
            </td>
            <td className="w1">
                { props.roleData.length ? props.roleData.filter(item => item.role_id === props.data.role)[0].name : null }
            </td>
            <td className="w1">
                { props.data.gender ? translate(props.data.gender === 1 ? 'Мужчина' : 'Женщина') : <span className="not-set">({ translate('не задано') })</span> }
            </td>
            <td className="w1">
                { props.data.phone ? props.data.phone : <span className="not-set">({ translate('не задано') })</span> }
            </td>
            {/*<td className="w1" data-col-seq="10">*/}
            {/*    <div style={{ fontSize: "11px" }}>*/}
            {/*        <span>*/}
            {/*            <i className="ion-clock fa-fw" />&nbsp;*/}
            {/*            20:05 <br />*/}
            {/*            <i className="ion-calendar fa-fw"/>&nbsp;*/}
            {/*            21.05.2022*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</td>*/}
            <td className="w1">
                {
                    props.data.f_removed === 0 ?
                        <a className="pull-right" title={ translate('В архив') } onClick={() => buttonArchive(1)}>
                            <i className="fa fa-lg fa-trash-o text-danger" />
                        </a> :
                        <>
                            <a className="pull-right" title={ translate('Удалить') } onClick={() => buttonDelete()}>
                                <i className="fa fa-lg fa-trash-o text-danger" />
                            </a>
                            <a className="pull-right m-r-xs" title={ translate('Из архива') } onClick={() => buttonArchive(0)}>
                                <i className="fa fa-reply text-navy" />
                            </a>
                        </>
                }
            </td>
        </tr>
    )
}