import {useContext, useEffect, useRef, useState} from "react";
import {closeModal, filterDisable} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {useNavigate} from "react-router-dom";
import UIMenuInput from "../../../Components/InputChoose/UIMenuInput";
import InputMask from "react-input-mask";

export default function FilterLesson (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [customer, setCustomer] = useState('');
    const [group, setGroup] = useState('');
    const [employee, setEmployee] = useState('');
    const [manager, setManager] = useState('');
    const [subject, setSubject] = useState('');
    const [type_lesson, setTypeLesson] = useState('');
    const [audience, setAudience] = useState('');
    const [status, setStatus] = useState('');
    const [status3, setStatus3] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');

        // SEARCH
    const [ customerSearch, setCustomerSearch ] = useState('');
    const [ groupSearch, setGroupSearch ] = useState('');
    const [ employeeSearch, setEmployeeSearch ] = useState('');
    const [ managerSearch, setManagerSearch ] = useState('');
    // STATE END

    // OBJECT FILTER START
    Object.filter = function(obj, filtercheck) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // INIT DATA START
    useEffect(() => {
        if (!props.value) {
            setCustomer('')
            setGroup('')
            setEmployee('')
            setManager('')
            setSubject('')
            setTypeLesson('')
            setAudience('')
            setStatus('')
            setStatus3('')
            setDateStart('')
            setDateEnd('')
            return
        }

        setCustomer(props.value.customer)
        setGroup(props.value.group)
        setEmployee(props.value.employee)
        setManager(props.value.manager)
        setSubject(props.value.subject)
        setTypeLesson(props.value.type_lesson)
        setAudience(props.value.audience)
        setStatus(props.value.status)
        setStatus3(props.value.status3)
        setDateStart(props.value.dateStart)
        setDateEnd(props.value.dateEnd)
    }, [props.value])
    // INIT DATA END

    // FILTER LESSON START
    function filterLesson (e) {
        e.preventDefault();

        let lesson = {
            customer,
            group,
            employee,
            manager,
            subject,
            type_lesson,
            audience,
            status,
            status3,
            dateStart,
            dateEnd
        }

        let obj = Object.filter(lesson, (value) => value);

        if (Object.keys(obj).length === 0) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/${props.page}/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }

    }
    // FILTER LESSON END

    return (
        <div id="filter-lesson" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <form className="form-horizontal modal-content" onSubmit={e => filterLesson(e)}>
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span>×</span>
                        </button>
                        <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                    </div>
                    {/*BODY*/}
                    <div className="modal-body p-md">
                        {/*DATE*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label">
                                    { translate('Дата') }
                                </label>
                            </div>
                            <div className="col-sm-4">
                                <div className="input-group">
                                    <label className="input-group-addon">
                                        <span className="text-lowercase">{translate('с')}</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={dateStart}
                                        onChange={(event) => setDateStart(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="input-group">
                                    <label className="input-group-addon">
                                        <span className="text-lowercase">{translate('До')}</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={dateEnd}
                                        onChange={(event) => setDateEnd(event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*CUSTOMER*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { customer && <i className="ion-person text-muted" /> } { translate('Клиент') } </label>
                            </div>
                            <div className="col-sm-8">
                                <UIMenuInput
                                    data={props.customerData.filter(item => item.f_removed === 0)}
                                    searchText={customerSearch}
                                    setSearchText={setCustomerSearch}
                                    setId={setCustomer}
                                    value={customer}
                                    placeholder="Поиск клиента"
                                    type_id="customer_id"
                                    topic="name"
                                />
                            </div>
                        </div>
                        {/*GROUP*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { group && <i className="ion-person-stalker text-muted" /> } { translate('Группа') } </label>
                            </div>
                            <div className="col-sm-8">
                                <UIMenuInput
                                    data={props.groupData.filter(item => item.f_removed === 0)}
                                    searchText={groupSearch}
                                    setSearchText={setGroupSearch}
                                    setId={setGroup}
                                    value={group}
                                    placeholder="Поиск группы"
                                    type_id="group_id"
                                    topic="title"
                                />
                            </div>
                        </div>
                        {/*EMPLOYEE*/}
                        {
                            props.access && (
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label"> { employee && <i className="ion-university text-muted" /> } { translate('Педагог') } </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <UIMenuInput
                                            data={props.employeeData.filter(item => item.f_removed === 0)}
                                            searchText={employeeSearch}
                                            setSearchText={setEmployeeSearch}
                                            setId={setEmployee}
                                            value={employee}
                                            placeholder="Поиск педагога"
                                            type_id="employee_id"
                                            topic="name"
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {/*MANAGER*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { manager && <i className="ion-person text-muted" /> } { translate('Ответственный') } </label>
                            </div>
                            <div className="col-sm-8">
                                <UIMenuInput
                                    data={props.managerData.filter(item => item.f_removed === 0)}
                                    searchText={managerSearch}
                                    setSearchText={setManagerSearch}
                                    setId={setManager}
                                    value={manager}
                                    placeholder="Поиск ответственного"
                                    type_id="user_id"
                                    topic="name"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Статус') } </label>
                            </div>
                            <div className="col-sm-8">
                                <select className="form-control" value={status} onChange={e => setStatus(e.target.value)}>
                                    <option value=''> { translate('Не задано') } </option>
                                    <option value={0}> { translate('Запланирован') } </option>
                                    <option value={1}> { translate('Проведен') } </option>
                                    <option value={2}> { translate('Отменен') } </option>
                                </select>
                            </div>
                        </div>
                        {/*SUBJECT*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Предмет') } </label>
                            </div>
                            <div className="col-sm-8">
                                <select className="form-control" value={subject} onChange={e => setSubject(e.target.value)}>
                                    <option value=''> { translate('Не задано') } </option>
                                    {
                                        lessonSetting && filterDisable(lessonSetting.subject).map(item => (
                                            <option value={`${item.id}`}> { item.name } </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/*FORMAT LESSON*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Тип урока') } </label>
                            </div>
                            <div className="col-sm-8">
                                <select className='form-control' value={type_lesson} onChange={e => setTypeLesson(e.target.value)}>
                                    <option value=''> { translate('Не задано') } </option>
                                    <option value={1}> { translate('Индивидуальный') } </option>
                                    <option value={2}> { translate('Групповой') } </option>
                                    <option value={3}> { translate('Пробный') } </option>
                                </select>
                            </div>
                        </div>
                        {/*AUDIENCE*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Аудитория') } </label>
                            </div>
                            <div className="col-sm-8">
                                <select className="form-control" value={audience} onChange={e => setAudience(e.target.value)}>
                                    <option value=''> { translate('Не задано') } </option>
                                    {
                                        lessonSetting && filterDisable(lessonSetting.room).map(item => (
                                            <option value={`${item.id}`}> { item.name } </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/*STATUS*/}
                        <div className="form-group">
                            <div className="col-sm-4">
                                <label className="control-label"> { translate('Статус') } </label>
                            </div>
                            <div className="col-sm-8">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={status3}
                                        onChange={e => setStatus3(e.target.checked ? '2' : '')}
                                    /> Приховати скасовані
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*FOOTER*/}
                    <div className="modal-footer">
                        {/*RESET FILTER*/}
                        <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр') } onClick={e => { closeModal(e, modal); navigate(`/company/1/${props.page}/index`) }}>
                            <i className="fa fa-eraser" />
                        </button>
                        {/*CANCEL*/}
                        <button type="button" className="btn btn-sm btn-w-m btn-white" data-dismiss="modal" onClick={e => closeModal(e, modal)}>
                            { translate('Отмена') }
                        </button>
                        {/*ACCEPT*/}
                        <button type="submit" className="btn btn-w-m btn-sm btn-primary js-filter-apply">
                            <i className="fa fa-filter" /> { translate('Применить') }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}