import '../../../Assets/css/AddTariff.css';
import DateWidget from "../../../Components/Widget/DateWidget";
import {useContext, useRef, useState} from "react";
import URL from '../../../Api/URL.json';
import {useHttp} from "../../../Hooks/http.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, closeModal} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {validate} from "../../../Function/validateForm";
import currency from "../../../Assets/data/JSON/currency.json";
import {useArchive} from "../../../Hooks/archive.hook";

export default function AddTariff () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [title, setTitle] = useState('');
    const [billing, setBilling] = useState('Поурочная');
    const [lessonNumber, setLessonNumber] = useState('');
    const [lessonDuration, setLessonDuration] = useState(null);
    const [price, setPrice] = useState('');
    const [pricePerLesson, setPricePerLesson] = useState(null);
    const [bill, setBill] = useState('Любой');
    const [period, setPeriod] = useState(null);
    // STATE END

    // HANDLER START
    function periodHandler (e) {
        if (e.target.value.length <= 4) {
            setPeriod(e.target.value);
        }
    }
    function billingHandler (e) {
        let special_input = document.getElementsByClassName("js-special");
        setBilling(e.target.value);
        if (e.target.value !== "Поурочная") {
            for (let i = 0; i < special_input.length; i++) {
                if(!special_input[i].classList.contains("hide"))
                {
                    special_input[i].classList.toggle("hide");
                }
            }
        } else {
            for (let i = 0; i < special_input.length; i++) {
                special_input[i].classList.toggle("hide");
            }
        }
    }
    function lessonNumberHandler (e) {
        setLessonNumber(e.target.value);
        calculatePerLesson();
    }
    function priceHandler (e) {
        setPrice(e.target.value);
        calculatePerLesson();
    }
    // HANDLER END

    // MODAL FUNC START
    function showAdditional () {
        let additional_input = document.getElementsByClassName("js-additional");

        for (let i = 0; i < additional_input.length; i++) {
            if (additional_input[i].style.display === "none") {
                additional_input[i].style.display = "block";
            } else {
                additional_input[i].style.display = "none";
            }
        }
    }
    function calculatePerLesson () {
        const price_value = document.getElementById('price').value;
        const count_value = document.getElementById('lesson_number').value;
        let current_price = +price_value > 0 ? +price_value : 0;
        let count = +count_value > 0 ? +count_value : 1;
        let cost = current_price / count;
        setPricePerLesson(`${Math.floor(cost * 100) / 100}`);
    }
    // MODAL FUNC END

    // ADD TARIFF START
    async function addTariff (e) {
        e.preventDefault();

        let validObj = {
            "Название" : !title.length,
            "Кол-во уроков" : !(lessonNumber.toString().length) && billing === "Поурочная",
            "Стоимость" : !price.toString().length
        }

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        let tariff = {
            title, billing, price, bill, period,
            lesson_number: lessonNumber > 0 ? lessonNumber : null,
            lesson_duration: lessonDuration ? lessonDuration : null,
            price_per_lesson: pricePerLesson,
            f_removed: 0
        }

        filterDisableEntity(customSetting.customField, "tariff").map(item => {
            tariff[item.systemName] = document.forms["add_tariff"].elements[item.systemName].value
        })

        const { insertId } = await request(`${URL.back_end}/tariff`, 'POST', tariff);
        await pushArchive(1, 'tariff', insertId, tariff, null);

        if (error) return console.log(error);

        window.location.reload();
    }
    // ADD TARIFF END

    return (
        <div id="add-tariff" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal tariff-form" name="add_tariff" onSubmit={e => addTariff(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title">{translate('Добавить абонемент')}</h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*TITLE*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="title">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        className="form-control js-focus-ctl"
                                        placeholder={translate('Например, Стандарт')}
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*BILLING*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="tariff-type">{translate('Тарификация')}</label>
                                </div>
                                <div className="col-sm-8 highlight-checkbox">
                                    <div id="tariff-type" onChange={e => billingHandler(e)}>
                                        <label>
                                            <input
                                                type="radio"
                                                name="Tariff[type]"
                                                value="Поурочная"
                                                defaultChecked="true"
                                            />
                                            <b> {translate('Поурочная')} </b>
                                            — {translate('списание за каждый урок. Например, клиент платит 8000 за 8 уроков;')}
                                        </label>
                                        <div />
                                        {/*<label>*/}
                                        {/*    <input*/}
                                        {/*        type="radio"*/}
                                        {/*        name="Tariff[type]"*/}
                                        {/*        value="Помесячная"*/}
                                        {/*    />*/}
                                        {/*    <b> {translate('Помесячная')} </b>*/}
                                        {/*    — {translate('списание стоимости абонемента единоразово каждый месяц;')}*/}
                                        {/*</label>*/}
                                        {/*<div />*/}
                                        {/*<label>*/}
                                        {/*    <input*/}
                                        {/*        type="radio"*/}
                                        {/*        name="Tariff[type]"*/}
                                        {/*        value="Недельная"*/}
                                        {/*    />*/}
                                        {/*    <b> {translate('Недельная')} </b>*/}
                                        {/*    — {translate('списание стоимости абонемента единоразово каждую неделю.')}*/}
                                        {/*</label>*/}
                                    </div>
                                </div>
                            </div>
                            {/*LESSON NUMBER*/}
                            <div className="js-special">
                                <div className="form-group required">
                                    <div className="col-sm-4">
                                        <label className="control-label" htmlFor="lesson_number">{translate('Кол-во уроков')}</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="number"
                                            id="lesson_number"
                                            className="form-control count-control hide-arrows"
                                            name="lesson_number"
                                            placeholder={`${translate('Например')}, 8`}
                                            value={lessonNumber}
                                            onChange={e => lessonNumberHandler(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*LESSON DURATION*/}
                            <div className="js-special">
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label" htmlFor="tariff-duration">{translate('Длительность урока, мин.')}</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="number"
                                            id="tariff-duration"
                                            className="form-control hide-arrows"
                                            name="Tariff[duration]"
                                            placeholder={`${translate('Например')}, 60`}
                                            value={lessonDuration}
                                            onChange={e => setLessonDuration(e.target.value)}
                                        />
                                        {
                                            lessonDuration && (
                                                <div className="text-navy">
                                                    { translate('Списание средств с баланса клиентов будет происходить пропорционально продолжительности урока') }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*SETTING PRICE*/}
                            <h4 className="border-bottom m-b-md m-t-md no-padding">{translate('Настройка стоимости')}</h4>
                            {/*PRICE*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="price">{translate('Стоимость')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        id="price"
                                        className="form-control price-control hide-arrows"
                                        name="price"
                                        placeholder={`${translate('Например')}, 800`}
                                        autoComplete="off"
                                        value={price}
                                        onChange={e => priceHandler(e)}
                                    />
                                </div>
                            </div>
                            {/*PRICE PER LESSON*/}
                            <div className="form-group js-special">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="price-per-lesson">{translate('Цена за один урок')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <span id="price-per-lesson" className="form-control text-lowercase">
                                        { pricePerLesson ? pricePerLesson : "0,00"} { basicSetting && currency[basicSetting.baseOption.currency] }
                                    </span>
                                </div>
                            </div>
                            {/*ADVANCED SETTING*/}
                            <a className="pull-right crm-dashed-link m-l-sm text-lowercase" onClick={showAdditional}>{translate('Показать')} ⇳</a>
                            <h4 className="border-bottom m-b-md m-t-md no-padding"> { translate('Расширенные настройки') } </h4>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "tariff").map(item => (
                                        <div className="form-group js-additional" style={{display: "none"}}>
                                            <div className="col-sm-4">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-8">
                                                {typeField(item)}
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                            {/*BILL*/}
                            {/*<div className="form-group js-additional" style={{display: "none"}}>*/}
                            {/*    <div className="col-sm-4">*/}
                            {/*        <label className="control-label" htmlFor="tariff-calculation_type">{translate('Клиентский счёт')}</label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-sm-8">*/}
                            {/*        <select className="form-control" onChange={e => setBill(e.target.value)}>*/}
                            {/*            <option value="Любой">{translate('Любой')}</option>*/}
                            {/*            <option value="Базовый счет">{translate('Базовый счет')}</option>*/}
                            {/*            <option value="Отдельный счет">{translate('Отдельный счет')}</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*PERIOD*/}
                            <div className="form-group js-additional" style={{display: "none"}}>
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="period">{translate('Период действия')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="period-end-container">
                                        <input
                                            type="text"
                                            className="form-control period_count_autocomplete ui-autocomplete-input"
                                            id="period"
                                            autoComplete="off"
                                            value={period}
                                            onChange={e => periodHandler(e)}
                                        />
                                        <DateWidget
                                            dateEnd={period}
                                            dateStart={`${new Date().toLocaleDateString()}`}
                                            updateDateEnd={setPeriod}
                                            style={{
                                                top: "34px",
                                                left: "5px",
                                                width: "360px"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-success" id="btn_submit">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}