import SideMenu from "../../Components/SideMenu/SideMenu";
import {useContext, useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import TariffTable from "./Table/TariffTable";
import {useHttp} from "../../Hooks/http.hook";
import URL from '../../Api/URL.json';
import AddTariff from "./Modal/AddTariff";
import AcceptModal from "../../Components/Modal/AcceptModal";
import EditTariff from "./Modal/EditTariff";
import FilterTariff from "./Modal/FilterTariff";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import {useTranslate} from "../../Hooks/translate.hook";
import { ToastContainer } from 'react-toastify';
import {openModal, pagination} from "../../Function/common";
import {SettingContext} from "../../Context/SettingContext";
import TabHeader from "../../Components/TabHeader";
import TabAction from "../../Components/TabAction";

export default function Tariff () {

    // CONTEXT START
    const { pageSize } = useContext(SettingContext);
    // CONTEXT END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [sql, setSql] = useState([]);
    const [customerTariff, setCustomerTariff] = useState([]);

        // DATA
    const [counts, setCounts] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [tariffEdit, setTariffEdit] = useState({});
    const [loading, setLoading] = useState(true);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {

            const data = await request(`${URL.back_end}/tariff_filter`, 'POST', { page, limit : pageSize.tariff, data : { f_removed : 0, ...params } });

            if (error) throw error;

            setSql(data.tariffs);
            setCounts(data.counts);
            setLoading(false);
        }

        fetchData().catch(e => console.log(e))

    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {

            const custTariff = await request(`${URL.back_end}/tariff_customer`, 'GET');

            if (error) throw error;

            setCustomerTariff(custTariff);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // BUTTON FUNC START
    function buttonEdit () {
        if (selectedRows.length === 1) {
            openModal('edit-tariff')
            setTariffEdit(sql.filter(item => item.tariff_id === +selectedRows[0])[0])
        }
    }
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="tariff" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON ADD*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-plus"
                                    text="Добавить"
                                    handler={() => openModal('add-tariff')}
                                />
                                {/*BUTTON EDIT*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    handler={() => buttonEdit()}
                                    disable={!selectedRows.length}
                                />
                                {/*BUTTON ANOTHER*/}
                                {/*<TabAction*/}
                                {/*    access={true}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="fa fa-ellipsis-h"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' },*/}
                                {/*            { link : '#', text : 'Экспорт в файл...', icon : 'fa fa-download' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*BUTTON FILTER*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    icon="fa fa-filter"
                                    text="Фильтр"
                                    handler={() => openModal('filter-tariff')}
                                />

                                {/*FILTER OPTIONS*/}
                                {
                                    filterParams &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    title: translate('Название'),
                                                    billing: translate('Тарификация'),
                                                    priceMin: translate('Стоимость от'),
                                                    priceMax: translate('Стоимость до'),
                                                    f_removed: translate('Архив')
                                                }}
                                                meansData={{
                                                    f_removed : {
                                                        arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    }
                                                }}
                                                page="tariff"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to={'/company/1/tariff/index'}> { translate('Текущие') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).f_removed === 1 ? 'active' : '' : ''}>
                                        <Link to={`/company/1/tariff/index/${encodeURI(JSON.stringify({ f_removed : 1 }))}`}>
                                            { translate('Архивные') }
                                        </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div className="tab-pane active">
                                        <div className="panel-body">
                                            <div className="grid-view is-bs3 hide-resize">
                                                {/*TABLE HEADER*/}
                                                <TabHeader
                                                    counts={counts}
                                                    entity='tariff'
                                                />
                                                {/*TABLE BODY*/}
                                                <div className="border-bottom" />
                                                <TariffTable
                                                    sql={sql}
                                                    customerTariff={customerTariff}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                    loading={loading}
                                                />
                                                <div className="text-center" translate="no">
                                                    { pageSize && pagination(counts, pageSize, 'tariff', page) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <AddTariff />

            <EditTariff
                tariffEdit={tariffEdit}
            />

            <FilterTariff
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
            />

            <AcceptModal />
        </div>
    )
}