import {closeModal, openModal} from "../../../Function/common";
import {useContext, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {validate} from "../../../Function/validateForm";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import AnalyticModal from "./AnalyticModal";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";

export default function SendMessageChat ({ chatData, employeeData, managerData }) {

    // REF START
    const modal = useRef();
    // REF END

    // VALUE START
    const domain = (window.location.hostname).split('.')[0];
    // VALUE END

    // CONTEXT START
    const { customerSetting, salesSetting, integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ token, setToken ] = useState('');
    const [ text, setText ] = useState('');
    const [ is_lead, setIsLead ] = useState(-1);
    const [ employee, setEmployee ] = useState('-1');
    const [ manager, setManager ] = useState('-1');
    const [ lastLesson, setLastLesson ] = useState(false);
    const [ file, setFile ] = useState({});
    const [ archival, setArchival ] = useState('-1');
    const [ source, setSource ] = useState([]);
    const [ refusal, setRefusal ] = useState([]);
    const [ kommoId, setKommoId ] = useState(false);
    // STATE END

    // SEND MESSAGE CHAT START
    async function apiTelegram (chat_id) {
        if (!file.name) {
            const response = await request(`https://api.telegram.org/bot${token}/sendMessage`, 'POST', { chat_id, text });

            const obj_message = {
                name: 'admin',
                username: 'admin',
                text,
                chat_id,
                date: Math.ceil(new Date().getTime() / 1000),
                social: 'telegram',
                message_id: response.result.message_id,
                checked: true
            };

            await request(`${URL.back_end}/message`, 'POST', obj_message);
        } else {
            const formData = new FormData();
            formData.append('document', file);
            formData.append('chat_id', chat_id);
            formData.append('caption', text);

            const response = await request(`https://api.telegram.org/bot${token}/sendDocument`, 'POST', formData, {}, true);

            const uniq_file_id = response.result.document.file_unique_id + response.result.date;
            const file_name = uniq_file_id + '.' + file.name.split(/\.([^.]+)$/)[1];

            const serverData = new FormData();
            serverData.append('file', file, file_name);

            await request(`${URL.back_end}/save-file/customer/${chat_id}`, 'POST', serverData, {}, true);

            const messageData = { name : 'admin', username : 'admin', document : JSON.stringify({ file_name, link : `https://${(window.location.hostname).split('.')[0]}.rist.pro${URL.back_end}/download/customer/${chat_id}/${file_name}/1`, originalName : file.name }) , chat_id: chat_id, date : response.result.date, social : 'telegram', message_id: response.result.message_id, checked : true };

            await request(`${URL.back_end}/message`, 'POST', messageData);

            if (text) {
                const obj_message = {
                    name: 'admin',
                    username: 'admin',
                    text,
                    chat_id,
                    date: Math.ceil(new Date().getTime() / 1000),
                    social: 'telegram',
                    message_id: response.result.message_id,
                    checked: true
                };

                await request(`${URL.back_end}/message`, 'POST', obj_message);
            }
        }
    }

    async function sendMessageChat (e) {
        e.preventDefault();

        const valid_obj = {
            "Бот" : !token.length,
            "Сообщение" : !text.length && !file.length
        }

        if (validate(valid_obj, translate)) return

        const CHAT_DATA =
          lastLesson ?
            (await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit: true, data: { lastLesson: true, withChatId: true } })).customers
            : (await request(`${URL.back_end}/customer_message`, 'POST', { nolimit: true })).messages;
        const sourceArr = source.map((item) => item.id);
        const refusalArr = refusal.map((item) => item.id);

        const filteredChatData = CHAT_DATA.filter(item => {
            if (item.noMail) return false;
            if (Number(is_lead) !== -1 && Number(is_lead) !== item.is_lead) return false;
            if (Number(employee) !== -1 && Number(employee) !== item.employee_id) return false;
            if (Number(manager) !== -1 && Number(manager) !== item.manager) return false;
            if (Number(archival) !== -1 && Number(archival) !== item.f_removed) return false;
            if (sourceArr.length && !sourceArr.includes(Number(item.source))) return false;
            if (refusalArr.length && !refusalArr.includes(Number(item.refusal))) return false;
            if (kommoId && !item.kommo_id) return false;
            return true;
        });

        for (const item of filteredChatData) {
            try {
                await apiTelegram(item.chat_id);
            } catch (e) {
                console.log(e);
            }
        }

        const customerCount = filteredChatData.filter((item) => item.is_lead === 0).length;
        const leadCount = filteredChatData.filter((item) => item.is_lead === 1).length;
        const customerActiveCount = filteredChatData.filter((item) => item.is_lead === 0 && item.f_removed === 0).length;
        const leadActiveCount = filteredChatData.filter((item) => item.is_lead === 1 && item.f_removed === 0).length;

        const mailingHistory = {
            bot_name: integration.telegram.find((bot) => bot.token === token)?.username,
            message: text,
            file: file?.name,
            customerCount,
            leadCount,
            customerActiveCount,
            leadActiveCount,
            customerArchiveCount: customerCount - customerActiveCount,
            leadArchiveCount: leadCount - leadActiveCount,
            sources: sourceArr.length ? JSON.stringify(sourceArr) : null
        }

        await request(`${URL.back_end}/mailing_history`, 'POST', mailingHistory);

        window.location.reload();
    }
    // SEND MESSAGE CHAT END

    // HANDLER START
    function analyticModalHandler (event) {
        closeModal(event, modal);
        openModal('analytic-modal');
    }
    // HANDLER END

    return (
        <>
            <div id="send-message-chat" className="modal in" ref={modal}>
                <div className="modal-dialog">
                    <div className="modal-content common-modal-content">
                        <form className="form-horizontal" onSubmit={e => sendMessageChat(e)}>
                            {/*HEADER*/}
                            <div className="modal-header">
                                <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                    <span>×</span>
                                </button>
                                <h4 className="modal-title"> Розсилка повідомлень </h4>
                            </div>
                            {/*BODY*/}
                            <div className="modal-body modal-body-search p-md">
                                <div className="form-group required">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            Оберіть бота
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => setToken(e.target.value)}>
                                            <option value=''> { translate('Не задано') } </option>
                                            {
                                                integration ? integration.telegram.map(item => (
                                                    <option value={item.token}> { item.username } </option>
                                                )) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group required">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Сообщение') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                    <textarea
                                        className="form-control"
                                        onChange={e => setText(e.target.value)}
                                    />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Файл') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <label htmlFor="report-file">
                                            <input
                                                type="file"
                                                id="report-file"
                                                multiple={true}
                                                style={{ display : 'none' }}
                                                onChange={e => setFile(e.target.files[0])}
                                            />
                                            {
                                                file.name ? <b> <i className="ion-paperclip" style={{ fontSize : '17px' }} /> { file.name } </b> : (
                                                    <a className="btn btn-sm btn-outline btn-white">
                                                        { translate('Прикрепить файл') }
                                                    </a>
                                                )
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            Тип клієнта
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => setIsLead(e.target.value)}>
                                            <option value={-1}> { translate('Клиенты') } / { translate('Лиды') } </option>
                                            <option value={0}> { translate('Клиенты') } </option>
                                            <option value={1}> { translate('Лиды') } </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Педагог') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => setEmployee(e.target.value)} disabled={ manager !== '-1' }>
                                            <option value={-1}> { translate('Не задано') } </option>
                                            { employeeData.map(item => ( <option value={item.employee_id}> { item.name } </option> )) }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Ответственный') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => setManager(e.target.value)} disabled={ employee !== '-1' }>
                                            <option value={-1}> { translate('Не задано') } </option>
                                            { managerData.map(item => ( <option value={item.user_id}> { item.name } </option> )) }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Архив') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => setArchival(e.target.value)}>
                                            <option value={-1}> { translate('Не задано') } </option>
                                            <option value={0}> Поточні </option>
                                            <option value={1}> { translate('Архивные') } </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Источник') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        {
                                            salesSetting && (
                                                <InputChooseMultiObject
                                                    sql={salesSetting.leadSource}
                                                    value={source}
                                                    setProps={setSource}
                                                    topicSearch="name"
                                                    type_id="id"
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    (Number(archival) === 1 && Number(is_lead) !== -1) && (
                                        <div className="form-group">
                                            <div className="col-sm-4">
                                                <label className="control-label">
                                                    { translate('Причины отказов') }
                                                </label>
                                            </div>
                                            <div className="col-sm-8">
                                                {
                                                    customerSetting && (
                                                        <InputChooseMultiObject
                                                            sql={Number(is_lead) === 0 ? customerSetting.refusal : salesSetting.leadReject}
                                                            value={refusal}
                                                            setProps={setRefusal}
                                                            topicSearch="name"
                                                            type_id="id"
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label">
                                            { translate('Остался 1 урок') }
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="checkbox"
                                            onChange={e => setLastLesson(e.target.checked)}
                                        />
                                    </div>
                                </div>
                                {
                                    (domain === 'tst' || domain === 'speaknroll') ? (
                                        <div className="form-group">
                                            <div className="col-sm-4">
                                                <label className="control-label">
                                                    Імпортовані учні
                                                </label>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="checkbox"
                                                    onChange={e => setKommoId(e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                            {/*FOOTER*/}
                            <div className="modal-footer">
                                <div className="left">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary btn-outline"
                                        title="Аналітика розсилки"
                                        onClick={(event) => analyticModalHandler(event)}
                                    >
                                        <i className="ion-map" />
                                    </button>
                                </div>
                                <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                    { translate('Отмена') }
                                </button>
                                <button type="submit" className="btn btn-w-m btn-sm btn-success">
                                    <i className="fa fa-send" /> Відправити
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AnalyticModal />
        </>
    )
}