import contactJSON from "../../Assets/data/JSON/contact.json";
import React from "react";
import {useTranslate} from "../../Hooks/translate.hook";

export default function ContactBlock ({ contact, access }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    if (!contact || !access) return

    const arr_contact = JSON.parse(contact);

    if (!arr_contact[0].type) return

    function getType (type, text) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'mobile' : return `tel:${text}`;
            case 'whatsapp' : return `whatsapp:${text}`;
            case 'envelope-o' : return `mailto:${text}`;
            case 'skype' : return `skype:${text}`;
            case 'globe' : return text;
            case 'map-marker' : return text;
            case 'instagram' : return `https://www.instagram.com/${text}`;
            case 'facebook' : return `facebook:${text}`;
        }
    }

    return arr_contact.map(item => (
        <div className="row">
            <div className="col-xs-5 text-muted m-b-xs"> { translate(contactJSON[item.type]) } </div>
            <div className="col-xs-7 m-b-xs text-right" style={{ overflowX : 'hidden' }}>
                <a href={getType(item.type, item.text)} target="_blank">
                    { item.text } <span className="small"> { item.note ? `(${item.note})` : null }</span>
                </a>
            </div>
        </div>
    ))
}