import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal, filterDisable} from "../../../../../Function/common";
import React, {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {validate} from "../../../../../Function/validateForm";
import URL from "../../../../../Api/URL.json";
import currency from "../../../../../Assets/data/JSON/currency.json";
import moment from "moment";

export default function EditSalary ({ sqlCard, setSqlCard, editSalary }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, customerSetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ amount, setAmount ] = useState(0);
    const [ type, setType ] = useState();
    const [ reason, setReason ] = useState([]);
    const [ date_start, setDateStart ] = useState(moment().format('YYYY-MM-DD'));
    const [ date_end, setDateEnd ] = useState('');
    const [ lessons, setLessons ] = useState([]);
    const [ type_lessons, setTypeLessons ] = useState([]);
    const [ client_from, setClientFrom ] = useState();
    const [ client_to, setClientTo ] = useState();
    const [ lesson_duration, setLessonDuration ] = useState();
    const [ increasePerClient, setIncreasePerClient ] = useState(false);
    const [ increasePropDuration, setIncreasePropDuration ] = useState(false);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(editSalary).length) return;

        setAmount(editSalary.amount);
        setType(editSalary.type.toString());
        setReason(editSalary.reason ? JSON.parse(editSalary.reason) : []);
        setDateStart(editSalary.date_start);
        setDateEnd(editSalary.date_end);
        setLessons(JSON.parse(editSalary.lessons));
        setTypeLessons(JSON.parse(editSalary.type_lessons));
        setClientFrom(editSalary.client_from);
        setClientTo(editSalary.client_to);
        setLessonDuration(editSalary.lesson_duration);
        setIncreasePerClient(editSalary.increasePerClient);
        setIncreasePropDuration(editSalary.increasePropDuration);
    }, [editSalary])
    // INIT DATA END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (date_end) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(date_start))).asDays();

            const newDateEnd = moment(date_end).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function lessonsHandler () {
        let elems = document.querySelectorAll('#salary-lessons-edit:checked');
        let arr = [].map.call(elems, (obj) => +obj.value)
        setLessons([...arr]);
    }

    function typeLessonsHandler () {
        let elems = document.querySelectorAll('#salary-type_lessons-edit:checked');
        let arr = [].map.call(elems, (obj) => +obj.value)
        setTypeLessons([...arr]);
    }

    function typeReasonHandler () {
        let elems = document.querySelectorAll('#reason-type-edit:checked');
        let arr = [].map.call(elems, (obj) => +obj.value)
        setReason([...arr]);
    }
    // HANDLER END

    // ADD SALARY START
    async function addSalary (e) {
        e.preventDefault();

        const validObj = {
            "Значение" : !amount || amount < 0,
            "Причины пропуска" : type !== '1' && !reason.length,
            "Тип расчета" : !type,
            "Период действия" : !date_start || !date_end,
            "Предметы" : !lessons.length,
            "Типы уроков" : !type_lessons.length,
            "Кол-во клиентов" : (client_from <= 0 && client_from !== '') || (client_to <= 0 && client_to !== '')
        }

        if (validate(validObj, translate)) return

        const salary = {
            reason : reason.length ? JSON.stringify(reason) : undefined,
            amount,
            type,
            date_start,
            date_end,
            lessons : JSON.stringify(lessons),
            type_lessons : JSON.stringify(type_lessons),
            client_from : Number(client_from) !== 0 ? Number(client_from) : null,
            client_to : Number(client_to) !== 0 ? Number(client_to) : null,
            lesson_duration : Number(lesson_duration) !== 0 ? Number(lesson_duration) : null,
            increasePerClient,
            increasePropDuration
        }

        await request(`${URL.back_end}/employee_wage/${editSalary.id}`, 'PUT', salary);

        window.location.reload();
    }
    // ADD SALARY END

    // DELETE SALARY START
    async function deleteSalary (e) {
        e.preventDefault();

        await request(`${URL.back_end}/employee_wage/${editSalary.id}`, 'DELETE');

        window.location.reload();
    }
    // DELETE SALARY END

    return (
        <div className="modal in" id="edit-salary" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}><span>×</span></button>
                        <h4 className="modal-title"> { translate('Править ставку') } </h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addSalary(e)}>
                        <div className="modal-body p-md">
                            {/* VALUE */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Значение') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={ translate('Например') + ', 50' }
                                            value={amount}
                                            onChange={e => setAmount(e.target.value)}
                                        />
                                        <span className="input-group-addon">
                                           { basicSetting && currency[basicSetting.baseOption.currency] }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* TYPE */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Тип расчета') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={type} onChange={e => setType(e.target.value)}>
                                        <option value={1}> { translate('За посетивших') } </option>
                                        <option value={2}> { translate('За пропустивших по причине') } </option>
                                        <option value={3}> { translate('За посетивших или пропустивших по причине') } </option>
                                    </select>
                                </div>
                            </div>
                            {/* REASON */}
                            <div className={`form-group required ${type === '1' && 'hide'}`}>
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Причины пропуска') } </label>
                                </div>
                                <div className="col-sm-8">
                                    {
                                        customerSetting &&
                                            filterDisable(customerSetting.reason).map(item => (
                                                <>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="reason-type-edit"
                                                            value={item.id}
                                                            checked={reason.includes(item.id)}
                                                            onChange={() => typeReasonHandler()}
                                                        />
                                                        &nbsp;{item.name}
                                                    </label>
                                                    <br />
                                                </>
                                            ))
                                    }
                                </div>
                            </div>
                            {/* PERIOD */}
                            <div className="form-group required m-b-md">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Период действия') } </label>
                                </div>
                                <div className="col-sm-4" style={{ paddingRight : '2px' }}>
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('с') } </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_start}
                                            onChange={(event) => dateStartHandler(event)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4" style={{ paddingRight : '2px' }}>
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('по') } </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            max={moment(date_start).add(2, 'year').format('YYYY-MM-DD')}
                                            min={moment(date_start).add(1, 'day').format('YYYY-MM-DD')}
                                            value={date_end}
                                            onChange={(event) => setDateEnd(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <h4 className="text-navy border-bottom m-b-sm no-padding"> { translate('Базовые условия') } </h4>

                            {/* SUBJECT */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label no-padding">
                                        { translate('Предметы') }
                                    </label>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple">
                                    {
                                        lessonSetting &&
                                            filterDisable(lessonSetting.subject).map(item => (
                                                <>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="lessons"
                                                            id="salary-lessons-edit"
                                                            value={item.id}
                                                            checked={lessons.includes(item.id)}
                                                            onChange={() => lessonsHandler()}
                                                        />
                                                        &nbsp;{item.name}
                                                    </label>
                                                    <br />
                                                </>
                                            ))
                                    }
                                </div>
                            </div>

                            {/* TYPE LESSON */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label no-padding">
                                        { translate('Типы уроков') }
                                    </label>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple lesson-type-area">
                                    <div id="discount-lesson_type_ids">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="type_lessons"
                                                id="salary-type_lessons-edit"
                                                value={1}
                                                checked={type_lessons.includes(1)}
                                                onChange={() => typeLessonsHandler()}
                                            />
                                            &nbsp;{translate('Индивидуальный')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="type_lessons"
                                                id="salary-type_lessons-edit"
                                                value={2}
                                                checked={type_lessons.includes(2)}
                                                onChange={() => typeLessonsHandler()}
                                            />
                                            &nbsp;{translate('Групповой')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="type_lessons"
                                                id="salary-type_lessons-edit"
                                                value={3}
                                                checked={type_lessons.includes(3)}
                                                onChange={() => typeLessonsHandler()}
                                            />
                                            &nbsp;{translate('Пробный')}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <h4 className="text-navy border-bottom m-b-md m-t-md no-padding"> { translate('Дополнительные условия') } </h4>

                            {/* NUMBER OF CLIENT */}
                            <div className="form-group m-t-none">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Кол-во клиентов') } </label>
                                </div>
                                <div className="col-sm-4">
                                    <input
                                        type="number"
                                        className="form-control hide-arrows"
                                        placeholder={ translate('Например') + ', 1' }
                                        value={client_from}
                                        onChange={e => setClientFrom(e.target.value)}
                                    />
                                    <div className="help-block" style={{ color : '#a94442' }}>
                                        { client_from <= 0 && client_from !== '' ? translate('Введите значение больше 0') : '' }
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <input
                                        type="number"
                                        className="form-control hide-arrows"
                                        placeholder={ translate('Например') + ', 5' }
                                        value={client_to}
                                        onChange={e => setClientTo(e.target.value)}
                                    />
                                    <div className="help-block" style={{ color : '#a94442' }}>
                                        { client_to <= 0 && client_to !== '' ? translate('Введите значение больше 0') : '' }
                                    </div>
                                </div>
                            </div>

                            {/* LESSON DURATION */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Длительность урока') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={ translate('Например') + ', 45' }
                                            value={lesson_duration}
                                            onChange={e => setLessonDuration(e.target.value)}
                                        />
                                        <span className="input-group-addon text-lowercase text-muted"> { translate('минут') } </span>
                                    </div>
                                </div>
                            </div>

                            <h4 className="text-navy border-bottom m-b-sm no-padding"> { translate('Прогрессивная ставка') } </h4>

                            <div className="form-group">
                                <div className="col-sm-4" />
                                <div className="col-sm-8">
                                    <label className="font-noraml">
                                        <input
                                            type="checkbox"
                                            checked={increasePerClient}
                                            onChange={e => setIncreasePerClient(e.target.checked)}
                                        />
                                        &nbsp;{ translate('Умножить значение на кол-во посетивших') }
                                    </label>
                                    <label className="font-noraml">
                                        <input
                                            type="checkbox"
                                            disabled={!lesson_duration}
                                            checked={increasePropDuration}
                                            onChange={e => setIncreasePropDuration(e.target.checked)}
                                        />
                                        &nbsp;{ translate('Увеличить значение пропорционально длительности') }
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-danger btn-outline" onClick={e => deleteSalary(e)}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}