import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditKnowledge from "./Modal/EditKnowledge";
import {openModal, switchToggle} from "../../../../Function/common";
import $ from 'jquery';
import 'jquery-ui-bundle';
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('#studystatussearch-f_name').focus();

    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/customerSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'knowledge'}),
                success: function(data){
                    setting.customerSetting = data;
                }
            });
        }
    });
}

export default function KnowledgeClient () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editKnowledge, setEditKnowledge ] = useState({});
    const [ deleteKnowledge, setDeleteKnowledge ] = useState({});
    // STATE END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.customerSetting) return
        drag(setting);
    }, [setting])
    // INIT DRAG END

    // COMPONENT FUNCTION START
    async function disabledKnowledge (e, data) {
        setting.customerSetting.knowledge = setting.customerSetting.knowledge.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', { customerSetting: JSON.stringify(setting.customerSetting) })

        switchToggle(e, data.disabled);
    }

    function deleteHandler (item) {
        setDeleteKnowledge(item);
        openModal('delete-knowledge-overlay');
        openModal('delete-knowledge-modal');
    }

    async function deleteKnowledgeHandler () {
        setting.customerSetting.knowledge = setting.customerSetting.knowledge.filter(item => {
            if (item.id !== deleteKnowledge.id) {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {customerSetting: JSON.stringify(setting.customerSetting)})

        window.location.reload();
    }
    // COMPONENT FUNCTION END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">
                    { translate('Уровни знаний') } <span className="text-muted">({ setting.customerSetting?.knowledge.length })</span>
                </h4>
            </div>
            <div className="modal-body p-md">
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={ translate('Фильтр по названию') }
                                />
                                <span className="input-group-addon" style={{ cursor: 'pointer' }}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal('add-knowledge')}>
                                <i className="ion-plus" /> { translate('Добавить') }
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                    {
                        setting.customerSetting?.knowledge.map(item => (
                            <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {opacity: 0.5} : {}} data-id={item.id}>
                                <td className="col-sm-10">
                                    <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> { item.name }
                                    <small className="text-muted"> [ID { item.id }] </small>
                                </td>
                                <td className="text-right col-sm-2">
                                    <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={e => disabledKnowledge(e, item)}>
                                        <i className={`fa ${item.disabled ? "text-success" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                    </a>
                                    <a className="btn btn-xs btn-white m-r-xxs" onClick={() => { openModal('edit-knowledge'); setEditKnowledge(item) }}>
                                        <i className="fa fa-pencil text-success fa-fw" />
                                    </a>
                                    <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item)}>
                                        <i className="fa fa-trash-o text-danger fa-fw" />
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <EditKnowledge
                editKnowledge={editKnowledge}
            />
            <DeleteModal
                id="delete-knowledge"
                handler={deleteKnowledgeHandler}
            />
        </>
    )
}