import { Link, useParams } from 'react-router-dom';
import {useHttp} from "../../../Hooks/http.hook";
import {useContext} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {findObject, filterDisable, openModal, getTariffInfo} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import {success} from "../../../Function/Notify";
import shortid from "shortid";
import URL from "../../../Api/URL.json";
import currency from "../../../Assets/data/JSON/currency.json";
import Dropdown from "../../../Components/DropdownMenu/Dropdown";

export default function CustomerColumns (props) {

    // CONTEXT START
    const { basicSetting, customerSetting } = useContext(SettingContext);
    // CONTEXT END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    const sweet_text = document.getElementById("sweet-text");
    // SWEET MODAL END

    // FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }

    function getTariff (customer) {
        let tariffs = props.tariffsData.filter(tariff => tariff.customer_id === customer.customer_id);
        let title = tariffs.map(item => item.title).join(', ');
        let obj = getTariffInfo(tariffs, customer.balance);

        return { title, ...obj }
    }

    function toDelete () {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";
        sweet_text.innerText = `${translate('Вы действительно хотите удалить клиента безвозвратно?')}`;

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            await request(`${URL.back_end}/customer/${props.data.customer_id}`, 'DELETE');

            if (error) return console.log(error)

            props.updateSql(props.sql.filter(item => item.customer_id !== props.data.customer_id))

            success(translate('Клиент удален'))
        })
    }

    function toArchive (data) {
        openModal('archive-modal')

        props.setCustId(data.customer_id)
        props.setName(data.name)
    }
    // FUNC END

    // VALUE START
    const tariff_info = getTariff(props.data);
    // VALUE END

    // FUNCTION START
    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <tr className={`w1 ${props.data.f_removed !== 0 && "danger"}`}>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={props.data.customer_id}
                    onClick={getSelect}
                />
            </td>
            <td>
                {
                    customerSetting && (
                        <Dropdown
                            initValue={findObject(customerSetting.status, props.data.status)?.name}
                            arr={filterDisable(customerSetting.status)}
                            type='name'
                            objType='status'
                            url={`/customer/${props.data.customer_id}`}
                            string={false}
                            resultType='id'
                        />
                    )
                }
            </td>
            <td className="w1">
                <Link to={`/company/1/customer/view?id=${props.data.customer_id}`}>
                    <i className="ion-person m-r-xs" />
                    { props.data.f_removed === 1 ? <strike> { props.data.name } </strike> : props.data.name }
                </Link>
            </td>
            <td className="w1">
                <Dropdown
                    initValue={props.data.manager !== null ? props.managerData.filter(item => item.user_id === props.data.manager)[0]?.name : translate('Не задано')}
                    arr={sortArray(props.managerData, 'name')}
                    type='name'
                    objType='manager'
                    url={`/customer/${props.data.customer_id}`}
                    string={false}
                    resultType='user_id'
                />
            </td>
            <td className="w1">
                {
                    props.groupsData.filter(group => group.customer_id === props.data.customer_id).map(item => (
                        <div key={shortid.generate()}>
                            <Link to={`/company/1/group/view?id=${item.group_id}`}>
                                { item.title }
                            </Link>
                            <br />
                        </div>
                    ))
                }
            </td>
            {
                props.access ? (
                    <>
                        <td className="w1">
                            {
                                basicSetting && (
                                    <>
                                        <span className={tariff_info.balance >= 0 ? 'text-navy' : 'text-danger'}>{ tariff_info.balance } { currency[basicSetting.baseOption.currency] }</span> / <span className={tariff_info.lesson >= 0 ? 'text-navy' : 'text-danger' }>{ tariff_info.lesson } { translate('уроков') }</span>
                                    </>
                                )
                            }
                        </td>
                        <td className="w1">
                            { tariff_info.title }
                        </td>
                    </>
                ) : null
            }
            <td className="w1">
                {
                    props.employeesData.filter(emp => emp.customer_id === props.data.customer_id).map(item => (
                        <div key={shortid.generate()}>
                            <Link to={`/company/1/employee/view?id=${item.employee_id}`}>
                                {item.name}
                            </Link>
                            <br />
                        </div>
                    ))
                }
            </td>
            <td className="w1">
                { props.data.chat_id ? <span className="text-navy"> Активно </span> : <span className="text-danger"> Не активно </span> }
            </td>
            {
                filterParams ? JSON.parse(filterParams).f_removed === 1 ? (
                  <td>
                      <span>{(props.data.date_a)?.split('T').join(' ').slice(0,16)}</span>
                  </td>
                ) : null : null
            }
            {/*<td className="w1">*/}
            {/*    <span className="not-set">(не задано)</span>*/}
            {/*</td>*/}
            {
                props.access ? (
                    <td className="w1">
                        {
                            props.data.f_removed === 0 ?
                                <a className="pull-right" title={translate('В архив')} onClick={() => toArchive(props.data)}>
                                    <i className="fa fa-lg fa-trash-o text-danger" />
                                </a> :
                                <a className="pull-right" title={translate('Удалить')} onClick={toDelete}>
                                    <i className="fa fa-lg fa-trash-o text-danger" />
                                </a>
                        }
                    </td>
                ) : null
            }
        </tr>
    )
}