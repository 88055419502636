// CSS
import '../../Assets/css/Auth.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../Assets/css/animate.css';

// HOOK
import {Link} from "react-router-dom";
import {useHttp} from "../../Hooks/http.hook";
import {useContext, useEffect, useState} from "react";

// CONTEXT
import { AuthContext } from "../../Context/AuthContext";
import { ToastContainer } from 'react-toastify';

// NOTIFY
import {errorNotify} from "../../Function/Notify";

// JSON
import URL from '../../Api/URL.json';
import {useTranslate} from "../../Hooks/translate.hook";

export default function Auth () {

    // HOOKS START
    const { request } = useHttp();
    const { translate } = useTranslate();
    const auth = useContext(AuthContext);
    // HOOKS END

    // STATE START
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ remember, setRemember ] = useState(false);
    // STATE END

    // VALID STATE START
    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [emailError, setEmailError] = useState('E-mail не может быть пустым');
    const [passwordError, setPasswordError] = useState('Пароль не может быть пустым');
    const [formValid, setFormValid] = useState(false);
    // VALID STATE END

    // USE EFFECT VALID START
    useEffect(() => {

        if (passwordError || emailError) setFormValid(false);
        else setFormValid(true);

    }, [passwordError, emailError])
    // USE EFFECT VALID END

    // HANDLER START
    function emailHandler (value) {

        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        setEmail(value);

        if (!re.test(value)) {
            setEmailError( translate('Неверный email адрес') );
        } else {
            setEmailError('');
        }
    }

    function passwordHandler(e) {

        setPassword(e.target.value);

        if (e.target.value.length < 6) {
            setPasswordError(translate('Пароль меньше 6 символов'));
        } else {
            setPasswordError('');
        }
    }

    function blurHandler(e) {
        // eslint-disable-next-line default-case
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true);
                break;
            case 'password':
                setPasswordDirty(true);
                break;
        }
    }
    // HANDLER END

    // FUNCTION START
    function showPassword (e) {
        e.preventDefault();

        const passwordField = document.getElementById('loginform-password');

        if (passwordField.type === 'password') {
            passwordField.type = 'text';
            e.currentTarget.style.opacity = .7;
        } else {
            passwordField.type = 'password';
            e.currentTarget.style.opacity = 1;
        }
    }
    // FUNCTION END

    // LOG IN START
    async function logIn (e) {
        e.preventDefault();

        let user = { email, password, remember }

        try {
            const data = await request(`${URL.back_end}/login`, 'POST', user);

            localStorage.setItem('i18n', data.interface_language);

            auth.login(data.token, data.user_id, data.role, data.name, data.interface_language);
        } catch (e) {
            if (e.message === "Пользователь не найден") {
                errorNotify(translate(e.message));
                setEmailError(translate(e.message));
            } else {
                errorNotify(translate(e.message));
                setPasswordError(translate(e.message));
            }
        }
    }
    // LOG IN END

    return (
        <>
            <ToastContainer />
            <div className="middle-box text-center loginscreen animated fadeInDown" style={{ height: `${window.innerHeight-1}px` }}>
                <h2 className="text-center"> RIST </h2>
                <h3 className="font-noraml"> { translate('Вход в личный кабинет') } </h3>
                <form className="form-horizontal m-t" onSubmit={e => logIn(e)}>
                    {/*E-MAIL*/}
                    <div className={`form-group required ${(emailDirty && emailError) ? "has-error" : "has-success"}`}>
                        <div className="col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="E-mail"
                                name="email"
                                autoComplete="email"
                                onChange={e => emailHandler((e.target.value).trim())}
                                onBlur={e => blurHandler(e)}
                                value={email}
                            />
                        </div>
                        <div className="col-lg-12">
                            <p className="help-block help-block-error">{(emailDirty && emailError) ? emailError : null}</p>
                        </div>
                    </div>
                    {/*PASSWORD*/}
                    <div className={`form-group required ${(passwordDirty && passwordError) ? "has-error" : "has-success"}`}>
                        <div className="col-lg-12">
                            <label htmlFor="loginform-password" id="show-pass" style={{ position : "absolute", top : "7px", right : "10px", opacity : 1, cursor : "pointer" }} onClick={e => showPassword(e)}>
                                <i className="fa fa-eye" />
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="loginform-password"
                                name="password"
                                autoComplete="current-password"
                                placeholder={translate('Пароль')}
                                onChange={e => passwordHandler(e)}
                                onBlur={e => blurHandler(e)}
                                value={password}
                            />
                        </div>
                        <div className="col-lg-12">
                            <p className="help-block help-block-error">{(passwordDirty && passwordError) ? passwordError : null}</p>
                        </div>
                    </div>
                    {/*REMEMBER*/}
                    <div className="col-lg-12 remember-block">
                        <label>
                            <input
                                type="checkbox"
                                checked={remember}
                                onChange={() => setRemember(!remember)}
                            />
                            &nbsp;{ translate('Запомнить меня на этом компьютере') }
                        </label>
                    </div>
                    {/*LOG IN*/}
                    <button type="submit" className="btn btn-success block full-width m-b" name="login-button" disabled={!formValid}>
                        <i className="ion-log-in" /> { translate('Войти в кабинет') }
                    </button>
                </form>
            </div>
        </>
    )
}