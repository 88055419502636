import SideMenu from "../../../Components/SideMenu/SideMenu";
import React, {useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import URL from "../../../Api/URL.json";
import {openModal} from "../../../Function/common";
import ScheduleRow from "../../Card/Employee/Row/ScheduleRow";
import ScheduleModal from "../../Card/Employee/Modal/ScheduleModal";
import SalaryWidget from "../../../Components/Widget/SalaryWidget";
import jwt_decode from "jwt-decode";
import InputMask from "react-input-mask";
import $ from 'jquery';

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact from "../../../Assets/data/JSON/contact.json";
import EmployeeSchedule from "../../../Components/Table/EmployeeSchedule";
import TabAction from "../../../Components/TabAction";
import {RegisterOfLesson} from "../../Card/Employee/Report/Modal/RegisterOfLesson";
import PayrollStatement from "../../Card/Employee/Report/Modal/PayrollStatement";
import BalanceBlock from "../../Card/Employee/Block/BalanceBlock";
import EditEmployee from "../../Employee/Modal/EditEmployee";
import {useArchive} from "../../../Hooks/archive.hook";

function handler () {
    $("#user-change_password").change(function () {
        $("#user-password, #gen-new-pass").attr("disabled", !$(this).is(":checked"))
    })
}

export default function EmployeeProfile () {

    // CONTEXT START
    const userData = jwt_decode(localStorage.getItem('userData'));
    // CONTEXT END

    // REF START
    const checkboxPassword = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp()
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState(undefined);
    const [language, setLanguage] = useState('');

       // DATA
    const [sqlCard, setSqlCard] = useState({});
    const [wage, setWage] = useState([]);
    const [employeeLesson, setEmployeeLesson] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {
            const data = await request(`${URL.back_end}/employee/${userData.systemId}`, 'GET');
            const user = await request(`${URL.back_end}/user/${userData.user_id}`, 'GET');
            const lessons = await request(`${URL.back_end}/employee_lesson/${userData.systemId}`, "GET");
            const wages = await request(`${URL.back_end}/employee_wage/${userData.systemId}`, 'GET');

            if (error) throw error;

            setEmployeeLesson(lessons);
            setSqlCard(data[0]);
            setWage(wages);
            setName(user[0].name);
            setGender(user[0].gender);
            setPhone(user[0].phone);
            setLanguage(user[0].interface_language);
        }

        fetchData().catch(e => console.log(e))
        handler()

    }, [])
    // INIT DATA END

    // FUNC START
    function ageParser (age) {
        if (!age) return <span className="text-danger"> { translate('не задано (дата рождения)') } </span>;

        const birthDate = age.split('.');
        const currentDate = (new Date().toLocaleDateString()).split(".");
        return `(${+currentDate[2] - +birthDate[2]} ${translate('года')})`
    }

    function infoGender (gender) {
        if (gender === 1) {
            return ( <><i className="ion-man" /> {translate('Мужчина')}</> )
        } else if (gender === 2) {
            return ( <><i className="ion-woman" /> {translate('Женщина')}</> )
        } else {
            return ( <span className="text-danger">{translate('не задано (пол учителя)')}</span> )
        }
    }

    function scheduleRow (day) {
        let scheduleObj = sqlCard.schedule ? JSON.parse(sqlCard.schedule) : null;

        if (scheduleObj[day].length !== 0) {
            return (
                <ScheduleRow
                    day={day}
                    time={scheduleObj[day]}
                />
            )
        } else {
            return null;
        }
    }

    function showForm () {
        let form = document.getElementById("profile-form");
        form.style.display === "none" ? form.style.display = "block" : form.style.display = "none";
    }

    function encodeImageFileAsURL(element) {
        let file = element.currentTarget.files[0];
        let reader = new FileReader();

        reader.onloadend = async function() {
            await request(`${URL.back_end}/employee/${sqlCard.employee_id}`, "PUT", { avatar: reader.result })
            document.getElementById("ava").src = `${reader.result}`;
        }

        reader.readAsDataURL(file);
    }
    // FUNC END

    // EDIT INFO START
    async function editInfo (e) {
        e.preventDefault();

        const allowPass = checkboxPassword.current.checked;

        const user = { name, gender, phone, password : allowPass ? password : undefined , interface_language : language }

        await request(`${URL.back_end}/user/${userData.user_id}`, 'PUT', user);
        await pushArchive(2, 'user', userData.user_id, user, sqlCard);

        if (error) return console.log(error);

        localStorage.setItem('i18n', language);

        window.location.reload();
    }
    // EDIT INFO END

    return (
        <div id="wrapper">
            <SideMenu page="employee" />
            <ToastContainer />
            <div id="page-wrapper" className="gray-bg">
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success btn-outline"
                                    title="Изменить педагога"
                                    handler={() => openModal('edit-employee')}
                                    icon="ion-edit"
                                    text="Править"
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-info btn-outline"
                                    title={translate('Реестр проведённых уроков')}
                                    icon="fa fa-file-text-o"
                                    text="Реестр проведённых уроков"
                                    handler={() => openModal('register-of-lesson')}
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-info btn-outline"
                                    title={translate('Выписка по зарплате')}
                                    icon="fa fa-file-text-o"
                                    text="Выписка по зарплате"
                                    handler={() => openModal('payroll-statement')}
                                />
                            </div>
                        </div>
                    </div>
                    {/*MAIN*/}
                    <div className="row">
                        <div className="col-md-8 col-sm-7">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content border-bottom">
                                    <div>
                                        <span className="pull-right text-right text-muted">
                                            { ageParser(sqlCard?.age) }
                                            <br />
                                            { infoGender(sqlCard?.gender) }
                                        </span>
                                        <span className="pull-left m-r-sm">
                                            <label htmlFor="avatar">
                                                <a className="crm-avatar-btn" style={{ position: 'relative', zIndex: 1 }}>
                                                <img
                                                    className="img-thumbnail img-circle"
                                                    id="ava"
                                                    src={ sqlCard.avatar }
                                                    alt="Avatar"
                                                    style={{ width: "50px", height: "50px" }}
                                                />
                                            </a>
                                            </label>
                                            <input type="file" id="avatar" onChange={e => encodeImageFileAsURL(e)} style={{display: "none"}} />
                                        </span>
                                        <h2 className="font-noraml no-margins no-padding"> {sqlCard?.name} </h2>
                                    </div>
                                    <div className="clearfix" />
                                    <div className="m-b-md bg-warning p-xs" style={{userSelect : 'none'}}>
                                        <a className="crm-dashed-link text-uppercase font-bold pull-right" onClick={showForm}>
                                            <i className="ion-edit" /> { translate('Обновить информацию') }
                                        </a>
                                        <i className="ion-ios-information-outline" />
                                        <em> { translate('Чтобы изменить фотографию — кликните по ней.') } </em>
                                    </div>
                                    <form className="form-horizontal" id="profile-form" style={{ display: 'none' }} onSubmit={e => editInfo(e)}>
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-name"> { translate('ФИО') } </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={translate('Введите ФИО')}
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-gender"> { translate('Пол') } </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <select className="form-control" value={gender} onChange={e => setGender(e.target.value)}>
                                                    <option value=''> { translate('Не задано') } </option>
                                                    <option value={1}> { translate('Мужчина') } </option>
                                                    <option value={2}> { translate('Женщина') } </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-phone"> { translate('Телефон') } </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <InputMask
                                                    className="form-control"
                                                    mask="+99(999)999-9999"
                                                    placeholder={translate('Введите телефон')}
                                                    value={phone}
                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label">
                                                    <input
                                                        type="checkbox"
                                                        id="user-change_password"
                                                        ref={checkboxPassword}
                                                    />
                                                    &nbsp;{ translate('Сменить пароль') }
                                                </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    id="user-password"
                                                    className="new-pass form-control"
                                                    disabled={true}
                                                    placeholder={ translate('Задайте новый пароль') }
                                                    autoComplete="off"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-language"> { translate('Язык интерфейса') } </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <select id="user-language" className="form-control" value={language} onChange={e => setLanguage(e.target.value)}>
                                                    <option value="">По умолчанию (ru)</option>
                                                    <option value="ua">Українська</option>
                                                    <option value="ru" selected="">Русский</option>
                                                    <option value="en">English</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-success btn-w-m">
                                                <i className="fa fa-save" /> { translate('Сохранить') }
                                            </button>
                                        </div>
                                    </form>
                                    <div className="clearfix" />
                                    {/*TABLE*/}
                                    <div className="table-responsive">
                                        <h4 className="m-b-xs m-l-sm m-r-sm"> {translate('Расписание на ближайшие 7 дней')} </h4>
                                        <EmployeeSchedule
                                            employeeLesson={employeeLesson}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-5">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content border-bottom">
                                    <div className="row">
                                        <div className="col-xs-5 text-muted m-b-xs"> ID </div>
                                        <div className="col-xs-7 m-b-xs text-right"> #{sqlCard.employee_id} </div>
                                        <div className="col-xs-5 text-muted m-b-xs"> { translate('Уроки') } </div>
                                        <div className="col-xs-7 m-b-xs text-right">
                                            <a>
                                                { employeeLesson.length } { translate('штук') }
                                            </a>
                                            <span className="dropdown">
                                                <a className="dropdown-toggle small text-success m-l-sm crm-dashed-link" title={ translate('Добавить') }>
                                                    заплан.
                                                </a>
                                                <ul className="dropdown-menu dropdown-left"></ul>
                                            </span>
                                        </div>
                                        {
                                            sqlCard.status && (
                                                <>
                                                    <div className="col-xs-5 text-muted m-b-xs"> { translate('Статус') } </div>
                                                    <div className="col-xs-7 m-b-xs text-right text-navy"> { sqlCard.status } </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    {/* CONTACTS */}
                                    {
                                        sqlCard.contact &&
                                            JSON.parse(sqlCard.contact).map(item => (
                                                <>
                                                    <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                                    <div className="row">
                                                        <div className="col-xs-5 text-muted m-b-xs"> { translate(contact[item.type]) } </div>
                                                        <div className="col-xs-7 m-b-xs text-right" style={{overflowX : "hidden"}}>
                                                            <a href={`tel:${item.text}`}> {item.text} <span className="small">{item.note ? `(${item.note})` : null}</span></a>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                    }
                                    {/*<hr className="hr-line-dashed m-t-sm m-b-sm" />*/}
                                    {/*<BalanceBlock*/}
                                    {/*    sqlCard={sqlCard}*/}
                                    {/*    wage={wage}*/}
                                    {/*    employeeLesson={employeeLesson}*/}
                                    {/*    access={false}*/}
                                    {/*/>*/}
                                    <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                    {/*HEADER*/}
                                    <p className="no-padding m-b-xs m-t-xs" onClick={() => openModal("add-schedule")}>
                                        <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right">
                                            <span className="text-lowercase font-normal"> { translate('Править') } </span>
                                        </a>
                                        <strong className="text-muted"> { translate('График работы') } </strong>
                                    </p>
                                    {/*BODY*/}
                                    {
                                        sqlCard.schedule ?
                                            <a className="crm-hover-block crm-modal-btn p-xxs" title={ translate('График работы') } style={{display: "block"}} onClick={() => openModal("add-schedule")}>
                                                {[0,1,2,3,4,5,6].map(item => scheduleRow(item))}
                                            </a>
                                            :
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-danger alert-warning text-center p-xxs">
                                                        ({ translate('не задано') })
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* ADDITIONAL INFORMATION */}
                            <SalaryWidget
                                employeeLesson={employeeLesson}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}

            <ScheduleModal
                sql={sqlCard}
                updateSql={setSqlCard}
                editSchedule={sqlCard.schedule ? JSON.parse(sqlCard.schedule) : null}
            />

            <RegisterOfLesson
                sqlCard={sqlCard}
            />

            <PayrollStatement
                sqlCard={sqlCard}
            />

            <EditEmployee
                access={false}
                employeeEdit={sqlCard}
            />
        </div>
    )
}