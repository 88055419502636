// HOOK
import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {useTranslate} from "../../../Hooks/translate.hook";

// COMPONENT
import Gender from "../../../Components/Input/Gender";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";

// FUNCTION
import {typeField} from "../../../Function/TypeField";
import {filterDisableEntity, filterDisable, closeModal, findRequired, getRequired, openMenu, findObject} from "../../../Function/common";
import shortid from "shortid";

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// JSON
import URL from '../../../Api/URL.json';
import Contact from "../../../Components/Input/Contact";

import React from "react";
import {validate} from "../../../Function/validateForm";
import {useNavigate} from "react-router-dom";
import {useArchive} from "../../../Hooks/archive.hook";

function MemoAddCustomer (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, salesSetting, customerSetting, integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState(2);
    const [contact, setContact] = useState([{ type : 'mobile' }]);
    const [legal, setLegal] = useState('');
    const [note, setNote] = useState('');
    const [source, setSource] = useState({});
    const [employee, setEmployee] = useState([]);
    const [status, setStatus] = useState('');
    const [chat_id, setChat_id] = useState('');
    const [branch, setBranch] = useState('');
    // STATE END

    // ADD CUSTOMER START
    async function addCustomer (e) {
        e.preventDefault();

        const noteValue = document.getElementById('customer_note').innerText;

        let validObj = {
            "ФИО" : !name.length,
            "Дата рождения" : findRequired(customSetting.requiredField, "age", "customer").require ? !age.length : null,
            "Контакт" : findRequired(customSetting.requiredField, "contact", "customer").require ? !contact[0].type : null,
            "Пол" : findRequired(customSetting.requiredField, "gender", "customer").require ? !gender.length : null,
            "Примечание" : findRequired(customSetting.requiredField, "note", "customer").require ? !noteValue.length : null,
            "Отв. педагог" : findRequired(customSetting.requiredField, "employee", "customer").require ? !employee.length : null
        }

        filterDisableEntity(customSetting.customField, "customer").map(item => {
            validObj[item.name] = item.require ? !document.forms["add_customer"].elements[item.systemName].value : null
        })

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        let customer = {
            name,
            age,
            gender,
            note: noteValue,
            chat_id : chat_id ? chat_id : null,
            contact: contact[0].type ? JSON.stringify(contact) : null,
            legal: legal ? legal : null,
            branch : branch ? branch : null,
            status: status ? status : null,
            source: source.id,
            is_lead: 0,
            f_removed : 0,
            c_date : `${new Date().toLocaleDateString('en-CA')}T${new Date().toLocaleTimeString()}Z`
        }

        filterDisableEntity(customSetting.customField, "customer").map(item => {
            let value = document.forms["add_customer"].elements[item.systemName].value;
            customer[item.systemName] = value.length ? value : null;
        })

        const data = await request(`${URL.back_end}/customer`, 'POST', customer);

        await request(`${URL.back_end}/customer_employee/${data.insertId}`, 'POST', employee.map(item => item.employee_id))

        await pushArchive(1, 'customer', data.insertId, customer, null);

        if (error) return console.log(error);

        navigate(data.insertId ? `/company/1/customer/view?id=${data.insertId}` : `/company/1/customer/index/`);
    }
    // ADD CUSTOMER END

    return (
       <div id="add-customer" className="modal in" ref={modal}>
           <ToastContainer />
           <div className="modal-dialog">
               <div className="modal-content common-modal-content">
                   <form className="form-horizontal" onSubmit={e => addCustomer(e)} name="add_customer">
                       {/*HEADER*/}
                       <div className="modal-header">
                           <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                               <span>×</span>
                           </button>
                           <h4 className="modal-title">
                               {translate('Добавить клиента')} &nbsp;—&nbsp;&nbsp;
                               <span className="dropdown" onClick={e => openMenu(e)}>
                                   <a className="dropdown-toggle crm-dashed-link text-lowercase" role="button">
                                       { status ? findObject(customerSetting.status, status)?.name : translate('Не задано') }
                                       <span className="caret" />
                                   </a>
                                   <ul className="dropdown-menu">
                                       {
                                           customerSetting ?
                                               filterDisable(customerSetting.status).map(item => (
                                                   <li key={shortid.generate()}>
                                                       <a onClick={() => setStatus(item.id)}>
                                                           {item.name}
                                                       </a>
                                                   </li>
                                               ))
                                               : null
                                       }
                                   </ul>
                               </span>
                           </h4>
                       </div>
                       {/*BODY*/}
                       <div className="modal-body p-md">
                           {/* NAME */}
                           <div className="form-group field-customer-name required">
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       { translate('ФИО') }
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   <input
                                       type="text"
                                       id="customer-name"
                                       className="form-control"
                                       name="name"
                                       placeholder={translate('Например, Иванов Иван')}
                                       value={name}
                                       onChange={e => setName(e.target.value)}
                                   />
                               </div>
                           </div>
                           {/* BIRTHDAY + GENDER */}
                           <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "age", "customer") : null}`}>
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       { translate('Дата рождения') }
                                   </label>
                               </div>
                               <div className="col-sm-3" style={{paddingRight: 0}}>
                                   <input
                                       type="date"
                                       className="form-control dob-input"
                                       value={age}
                                       onChange={e => setAge(e.target.value)}
                                   />
                               </div>
                               <div className="col-sm-6">
                                   <Gender setProps={setGender} />
                                   <label className="font-noraml pull-right m-t-xs m-r-sm"> { translate('Пол') } </label>
                               </div>
                           </div>
                           {/* CONTACTS */}
                           <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "contact", "customer") : null}`}>
                               <div className="col-sm-3">
                                   <label className="control-label" htmlFor="customer-contact_contacts">{translate('Контакты')}</label>
                               </div>
                               <div className="col-sm-9">
                                   <Contact
                                       setContact={setContact}
                                       contact={contact}
                                   />
                               </div>
                           </div>
                           {/* LEGAL */}
                           <div className="form-group">
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       { translate('Заказчик') }
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   <input
                                       type="text"
                                       className="form-control"
                                       placeholder={ translate('Имя заказчика') }
                                       value={ legal }
                                       onChange={e => {setLegal(e.target.value)}}
                                   />
                               </div>
                           </div>
                           {/* BRANCH */}
                           <div className="form-group">
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       Бот за замовчуванням
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   {
                                       integration ?
                                           (
                                               <select className="form-control" onChange={e => setBranch(e.target.value)}>
                                                   <option value=''> { translate('Не задано') } </option>
                                                   { integration.telegram.map(item => <option value={item.id}> { item.username } </option>) }
                                               </select>
                                           )
                                           : null
                                   }
                               </div>
                           </div>
                           {/* NOTE */}
                           <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "note", "customer") : null}`}>
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       { translate('Примечание') }
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   <span
                                       id="customer_note"
                                       className="form-control textarea"
                                       role="textbox"
                                       contentEditable="true"
                                       suppressContentEditableWarning={true}
                                   />
                               </div>
                           </div>
                           {/* SOURCE */}
                           <div className="form-group field-customer-lead_source_id">
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       { translate('Источник') }
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   <InputChooseSingleObject
                                       sql={salesSetting ? filterDisable(salesSetting.leadSource) : []}
                                       setProps={setSource}
                                       topicSearch="name"
                                       type_id="id"
                                   />
                               </div>
                           </div>
                           {/*Отв. педагог*/}
                           <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "employee", "customer") : null}`}>
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       { translate('Отв. педагог') }
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   <InputChooseMultiObject
                                       sql={props.dataEmployee.filter(item => item.f_removed === 0)}
                                       setProps={setEmployee}
                                       type_id="employee_id"
                                       topicSearch="name"
                                   />
                               </div>
                           </div>
                           {/*CHAT_ID*/}
                           <div className="form-group">
                               <div className="col-sm-3">
                                   <label className="control-label">
                                       chat_id
                                   </label>
                               </div>
                               <div className="col-sm-9">
                                   <input
                                       type="number"
                                       className="form-control"
                                       onChange={e => setChat_id(e.target.value)}
                                   />
                               </div>
                           </div>
                           {/*ADDITIONAL FIELDS*/}
                           <div className="form-group">
                               <div className="col-sm-4">
                                   <b> { translate('Дополнительные поля') } </b>
                               </div>
                           </div>
                           {
                               customSetting ?
                                   filterDisableEntity(customSetting.customField, "customer").map(item => (
                                       <div className={`form-group ${item.require && "required"}`}>
                                           <div className="col-sm-3">
                                               <label className="control-label">{item.name}</label>
                                           </div>
                                           <div className="col-sm-9">
                                               { typeField(item) }
                                           </div>
                                       </div>
                                   ))
                                   : null
                           }
                       </div>
                       {/*FOOTER*/}
                       <div className="modal-footer">
                           <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                               { translate('Отмена') }
                           </button>
                           <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit">
                               <i className="fa fa-save" /> { translate('Сохранить') }
                           </button>
                       </div>
                   </form>
               </div>
           </div>
       </div>
    )
}

export const AddCustomer = React.memo(MemoAddCustomer);