import {memo} from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";

export const PresenceBlock = memo(({ lessonData }) => {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // VALUE START
    const countPresence = lessonData.filter(item => !!item.presence === true).length;
    // VALUE END

    return (
        <div className="row">
            <div className="col-xs-5 m-b-sm">
                { translate('Посещений') }
            </div>
            <div className="col-xs-7 m-b-sm text-right">
                { countPresence > 0 ? <span> { countPresence } { translate('шт.') }  </span> :  <span className="text-danger font-bold"> { translate('ни одного') } </span>}
            </div>
        </div>
    )
})