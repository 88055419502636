// HOOK
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";

// JSON
import URL from "../../../Api/URL.json";
import iconOperation from "../../../Assets/data/JSON/iconOperation.json";
import operation from "../../../Assets/data/JSON/operation.json";

// CUSTOM INPUT
import InputChooseSingle from "../../../Components/InputChoose/InputChooseSingle";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {SettingContext} from "../../../Context/SettingContext";

// FUNCTION
import {
    filterDisable,
    filterDisabledAccess,
    filterDisableEntity,
    findObject,
    findRequired
} from "../../../Function/common";
import {AuthContext} from "../../../Context/AuthContext";
import {typeField} from "../../../Function/TypeField";
import {closeModal, getRequired} from "../../../Function/common";
import {validate} from "../../../Function/validateForm";
import {useArchive} from "../../../Hooks/archive.hook";
import moment from "moment";

export default function AddPay (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, paySetting, customSetting, currentTime } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [c_date, setDate] = useState(moment(currentTime).format('YYYY-MM-DD'));
    const [account, setAccount] = useState({});
    const [article, setArticle] = useState('');
    const [dataBill, setDataBill] = useState([]);
    const [bill, setBill] = useState({});
    const [amount, setAmount] = useState(0);
    const [payer, setPayer] = useState('');
    const [note, setNote] = useState('');
    const [location, setLocation] = useState(null);
    const [customer, setCustomer] = useState({});
    // STATE END

    // BILL INIT START
    useEffect(() => {
        if (!Object.keys(customer).length) return

        async function relateFetch () {
            let bill = await request(`${URL.back_end}/customer_tariff/${customer.customer_id}`, 'GET');

            bill = bill.filter((item) => item.f_removed === 0).map((item) => {
                let lessons = lessonSetting ? item.lessons ? JSON.parse(item.lessons).map(item => findObject(lessonSetting.subject, +item).name).join('/') : '' : ''
                item.title = `${item.title} / ${lessons} / ${item.balance}`
                return item;
            })

            if (error) throw error

            setDataBill([ { id: -1, title: translate('Базовый счет') }, ...bill ]);
        }

        relateFetch().catch(e => console.log(e))
    }, [customer])
    // BILL INIT END

    // INIT CHOOSE CUSTOMER START
    useEffect(() => {
        if (props.customer) setCustomer(props.customer)
    }, [props.customer])
    // INIT CHOOSE CUSTOMER END

    // ADD PAY START
    async function addPay (e) {
        e.preventDefault();

        let validObj = {
            "Дата" : !c_date.length,
            "Клиент" : !Object.keys(customer).length,
            "Клиентский счёт" : !Object.keys(bill).length,
            "Сумма" : props.typeOperation !== 3 ? !(/^[0-9]+(\.[0-9]+)?$/).test(amount) : !(/^-?[0-9]+(\.[0-9]+)?$/).test(amount),
            "Счет" : findRequired(customSetting.requiredField, "account", "pay").require ? !Object.keys(account).length : null,
            "Стаття" : findRequired(customSetting.requiredField, "article", "pay").require ? !Object.keys(article).length : null
        }

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        if (account.id !== undefined) {
            paySetting.account = paySetting.account.map(item => {
                if (+item.id === +account.id) {
                    item.payment += 1;
                    return item;
                } else {
                    return item;
                }
            })
            await request(`${URL.back_end}/setting`, 'PUT', { paySetting: JSON.stringify(paySetting) })
        }

        if (article.id !== undefined) {
            paySetting.article = paySetting.article.map(item => {
                if (+item.id === +article.id) {
                    item.payment += 1;
                    return item;
                } else {
                    return item;
                }
            })
            await request(`${URL.back_end}/setting`, 'PUT', { paySetting: JSON.stringify(paySetting) })
        }

        let pay = {
            type_operation: +props.typeOperation,
            account: account.id,
            article: article.id,
            bill: Object.keys(bill).length ? bill.id : null,
            customer_id: Object.keys(customer).length ? customer.customer_id : null,
            c_date : moment(c_date).format('YYYY-MM-DD'),
            amount : Number(amount),
            payer, note, location
        }

        filterDisableEntity(customSetting.customField, "pay").map(item => {
            pay[item.systemName] = document.forms["add_pay"].elements[item.systemName].value
        })

        const { insertId } = await request(`${URL.back_end}/pay`, 'POST', pay);

        await pushArchive(1, 'pay', insertId, pay, null);

        if (error) return error;

        window.location.reload();
    }
    // ADD PAY END

    return (
        <div id={props.id ? props.id : 'add-pay'} className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span>×</span>
                        </button>
                        <h4 className="modal-title">
                            { translate('Добавить') } <span className="text-lowercase"> { translate(operation[props.typeOperation]) } </span>
                        </h4>
                    </div>
                    <form className="form-horizontal" name="add_pay" onSubmit={e => addPay(e)}>
                        {/*BODY*/}
                        <div className="modal-body" style={{ paddingBottom: 0 }}>
                            {/*PAY TYPE AND DATE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Тип и дата') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-control" style={{cursor: "not-allowed"}}>
                                                <i className={`${iconOperation[props.typeOperation]} text-muted`} /> {translate(operation[props.typeOperation])}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={c_date}
                                                onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*PAY ACCOUNT AND ARTICLE*/}
                            <div className={`form-group ${ customSetting ? getRequired(customSetting.requiredField, 'account', 'pay') : null } ${ customSetting ? getRequired(customSetting.requiredField, 'article', 'pay') : null }`}>
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Счет и статья') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputChooseSingleObject
                                                sql={paySetting ? filterDisabledAccess(paySetting.account, auth.userId) : []}
                                                setProps={setAccount}
                                                topicSearch="name"
                                                type_id="id"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputChooseSingleObject
                                                sql={paySetting ? filterDisable(paySetting.article).filter(item => item.type_payment.includes(`${props.typeOperation}`)) : []}
                                                setProps={setArticle}
                                                topicSearch="name"
                                                type_id="id"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*LOCATION*/}
                            <div className="form-group field-pay-location_id">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Локация')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingle
                                        data={["Zoom"]}
                                        setProps={setLocation}
                                        value={location ? location : `(${translate('не задано')})`}
                                    />
                                </div>
                            </div>
                            {/*CUSTOMER*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Клиент')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={props.customerData.filter(item => item.f_removed === 0)}
                                        setProps={setCustomer}
                                        value={customer.name}
                                        topicSearch="name"
                                        type_id="customer_id"
                                    />
                                    <div className={`help-block text-danger ${customer.name !== undefined ? "hide" : null}`}>
                                        { translate('Выберите клиента, если вы пополняете клиентский счет') }
                                    </div>
                                </div>
                            </div>
                            {/*BILL*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="pay-ctt_id">{translate('Клиентский счёт')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group">
                                        <InputChooseSingleObject
                                            sql={dataBill}
                                            setProps={setBill}
                                            topicSearch="title"
                                            type_id="id"
                                        />
                                        <div className="input-group-addon" style={{ minWidth: '80px' }}>
                                            <a className="selected-customer crm-add-tariff text-lowercase">
                                                <small className="selected-customer-hide text-lowercase"> { translate('Добавить') } </small>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`help-block text-danger ${customer.name !== undefined ? "hide" : null}`}>
                                        { translate('Выберите счет, если используются раздельные счета') }
                                    </div>
                                </div>
                            </div>
                            {/*AMOUNT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="font-noraml control-label">
                                        { translate('Сумма') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 1000`}
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*PAYER*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Плательщик')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, ФИО или наименование компании')}
                                        value={payer}
                                        onChange={e => setPayer(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Комментарий')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Оплата за обучение')}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "pay").map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label"> { item.name } </label>
                                            </div>
                                            <div className="col-sm-8">
                                                { typeField(item) }
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}