import '../../../../../Assets/css/DiscountModal.css';
import DateWidget from "../../../../../Components/Widget/DateWidget";
import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from '../../../../../Api/URL.json';
import {useArchive} from "../../../../../Hooks/archive.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {filterDisable, closeModal} from "../../../../../Function/common";
import React from "react";
import {validate} from "../../../../../Function/validateForm";
import moment from "moment";

export const AddDiscount = React.memo(({ sql }) => {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    const btn_lesson = useRef();
    const btn_type_lesson = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [typeDiscount, setTypeDiscount] = useState('1');
    const [amount, setAmount] = useState('');
    const [note, setNote] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [typeLessons, setTypeLessons] = useState([]);
    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState('');
    // STATE END

    // VALID START START
    const [lessonsError, setLessonsError] = useState('');
    const [typeLessonsError, setTypeLessonsError] = useState('');
    // VALID STATE END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (dateEnd) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(dateStart))).asDays();

            const newDateEnd = moment(dateEnd).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function lessonsHandler() {
        let elems = document.querySelectorAll('#discount-lessons:checked');
        let arr = [].map.call(elems, function (obj) {
            return +obj.value;
        })

        setLessons([...arr]);

        if (arr.length === 0) {
            setLessonsError(`${translate('Необходимо заполнить «Предметы».')}`);
        } else {
            setLessonsError('');
        }
    }
    function typeLessonsHandler() {
        let elems = document.querySelectorAll('#discount-type_lessons:checked');
        let arr = [].map.call(elems, function (obj) {
            return +obj.value;
        })

        setTypeLessons([...arr]);

        if (arr.length === 0) {
            setTypeLessonsError(`${translate('Необходимо заполнить «Типы уроков».')}`);
        } else {
            setTypeLessonsError('');
        }
    }
    // HANDLER END

    // MODAL FUNC START
    function lessonsCheckAll() {
        btn_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#discount-lessons');

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].checked === false) {
                arr[i].checked = true;
            }
        }

        lessonsHandler();
    }

    function lessonsUncheckAll() {
        btn_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#discount-lessons');

        for (let i = 0; i < arr.length; i++) {
            arr[i].checked = false;
        }

        lessonsHandler();
    }

    function typeLessonsCheckAll() {
        btn_type_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#discount-type_lessons');

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].checked === false) {
                arr[i].checked = true;
            }
        }

        typeLessonsHandler();
    }

    function typeLessonsUncheckAll() {
        btn_type_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#discount-type_lessons');

        for (let i = 0; i < arr.length; i++) {
            arr[i].checked = false;
        }

        typeLessonsHandler();
    }
    // MODAL FUNC END

    // ADD DISCOUNT START
    async function addDiscount (e) {
        e.preventDefault();

        const validObj = {
            "Тип скидки" : !typeDiscount.length,
            "Скидка" : !amount.length,
            "Предметы" : !lessons.length,
            "Типы уроков" : !typeLessons.length,
            "Период действия" : !dateStart || !dateEnd
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        const newDiscount = {
            customer_id : sql.customer_id,
            amount,
            date_start : dateStart,
            date_end : dateEnd,
            lessons : JSON.stringify(lessons),
            type_lessons : JSON.stringify(typeLessons),
            type_discount: +typeDiscount,
            note
        }

        await request(`${URL.back_end}/customer_discount`, 'POST', newDiscount);

        if (error) return console.log(error)

        window.location.reload();
    }
    // ADD DISCOUNT END

    return (
        <div id="add-discount" className="modal in" role="dialog" ref={modal}>}
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addDiscount(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Добавить скидку для') } { sql.name } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            <h4 className="text-navy border-bottom m-b-md no-padding">{translate('Основные параметры')}</h4>
                            {/*TYPE DISCOUNT*/}
                            <div className="form-group field-discount-type required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Тип скидки') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select id="discount-type" className="form-control" onChange={e => setTypeDiscount(e.target.value)}>
                                        <option value="1">{translate('Процентная скидка')}</option>
                                        <option value="2">{translate('Денежная скидка')}</option>
                                    </select>
                                </div>
                            </div>
                            {/*AMOUNT*/}
                            <div className={`form-group field-discount-amount required`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="discount-amount">{translate('Скидка')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        id="discount-amount"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 12.20`}
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group field-discount-note">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="discount-note">{translate('Примечание')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <textarea
                                        id="discount-note"
                                        className="form-control autoresize"
                                        name="note"
                                        rows="1"
                                        placeholder={translate('Любое текстовое примечание')}
                                        style={{height : "32px"}}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                            <h4 className="text-navy border-bottom m-b-md no-padding">{translate('Условия применения')}</h4>
                            {/*LESSON*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label no-padding" htmlFor="discount-subject_ids">{translate('Предметы')}</label>
                                    <br />
                                    <a className="crm-dashed-link small crm-toggle-all active" ref={btn_lesson}>
                                        <span className="action-toggle-all" onClick={lessonsCheckAll}>{translate('выбрать все')}</span>
                                        <span className="action-toggle-none" onClick={lessonsUncheckAll}>{translate('снять все')}</span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple subject-area" onChange={lessonsHandler}>
                                    <div id="discount-subject_ids">
                                        {
                                            lessonSetting ?
                                                filterDisable(lessonSetting.subject).map(item => (
                                                    <>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                value={item.id}
                                                                name="lessons"
                                                                id="discount-lessons"
                                                            />
                                                            &nbsp;{ item.name }
                                                        </label>
                                                        <br />
                                                    </>
                                                ))
                                                : null
                                        }
                                    </div>
                                    <div className="text-danger small">{lessonsError ? lessonsError : null}</div>
                                </div>
                            </div>
                            {/*TYPE LESSON*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label no-padding" htmlFor="discount-lesson_type_ids">{translate('Типы уроков')}</label>
                                    <br />
                                    <a className="crm-dashed-link small crm-toggle-all active" ref={btn_type_lesson}>
                                        <span className="action-toggle-all" onClick={typeLessonsCheckAll}>{translate('выбрать все')}</span>
                                        <span className="action-toggle-none" onClick={typeLessonsUncheckAll}>{translate('снять все')}</span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple lesson-type-area">
                                    <div id="discount-lesson_type_ids" onChange={typeLessonsHandler}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={1}
                                                name="type_lessons"
                                                id="discount-type_lessons"
                                            />
                                            &nbsp;{translate('Индивидуальный')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={2}
                                                name="type_lessons"
                                                id="discount-type_lessons"
                                            />
                                            &nbsp;{translate('Групповой')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={3}
                                                name="type_lessons"
                                                id="discount-type_lessons"
                                            />
                                            &nbsp;{translate('Пробный')}
                                        </label>
                                    </div>
                                    <div className="text-danger small">{typeLessonsError ? typeLessonsError : null}</div>
                                </div>
                            </div>
                            {/*DATE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Период действия') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        {/*DATE START*/}
                                        <div className="col-sm-6">
                                            <label style={{ position: "absolute", marginTop: "8px", marginLeft: "5px" }}>
                                                { translate('с') }
                                            </label>
                                            <input
                                                type="date"
                                                id="discount-date-start"
                                                className="form-control text-center"
                                                value={dateStart}
                                                onChange={(event) => dateStartHandler(event)}
                                            />
                                        </div>
                                        {/*DATE END*/}
                                        <div className="col-sm-6">
                                            <label style={{ position: "absolute", marginTop: "8px", marginLeft: "5px" }}>
                                                { translate('до') }
                                            </label>
                                            <input
                                                type="date"
                                                id="discount-date-start"
                                                className="form-control text-center"
                                                max={moment(dateStart).add(2, 'year').format('YYYY-MM-DD')}
                                                min={moment(dateStart).add(1, 'day').format('YYYY-MM-DD')}
                                                value={dateEnd}
                                                onChange={(event) => setDateEnd(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})