import Gender from "../../../Components/Input/Gender";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import URL from '../../../Api/URL.json';
import {typeField} from "../../../Function/TypeField";
import {useHttp} from "../../../Hooks/http.hook";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useArchive} from "../../../Hooks/archive.hook";
import {
    closeModal,
    filterDisable,
    filterDisableEntity,
    findObject,
    findRequired,
    getRequired,
    openMenu
} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {useTranslate} from "../../../Hooks/translate.hook";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import shortid from "shortid";
import Contact from "../../../Components/Input/Contact";
import {validate} from "../../../Function/validateForm";

export default function EditCustomer (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, customerSetting, salesSetting, integration } = useContext(SettingContext)
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [age, setAge] = useState(null);
    const [gender, setGender] = useState(null);
    const [contact, setContact] = useState([{ type : 'mobile' }]);
    const [legal, setLegal] = useState(null);
    const [note, setNote] = useState(null);
    const [source, setSource] = useState({});
    const [employee, setEmployee] = useState([]);
    const [status, setStatus] = useState(null);
    const [chat_id, setChat_id] = useState(null);
    const [branch, setBranch] = useState(null);
    // STATE END

    // FUNCTION START
    async function relateFetch () {
        const employees = await request(`${URL.back_end}/customer_employee/${props.editCustomer.customer_id}`, 'GET');

        if (error) throw error;

        setEmployee(employees);
    }
    // FUNCTION END

    // INIT DATE START
    useEffect(() => {
        if (!Object.keys(props.editCustomer).length) return

        setName(props.editCustomer.name);
        setAge(props.editCustomer.age);
        setContact(props.editCustomer.contact ? JSON.parse(props.editCustomer.contact) : [{ type : 'mobile' }])
        setLegal(props.editCustomer.legal);
        setNote(props.editCustomer.note);
        setSource({ id: props.editCustomer?.source });
        setStatus(props.editCustomer.status);
        setGender(props.editCustomer.gender);
        setChat_id(props.editCustomer.chat_id);
        setBranch(props.editCustomer.branch);

        props.customerEmployee ? setEmployee(props.customerEmployee) : relateFetch().catch(e => console.log(e));

        filterDisableEntity(customSetting ? customSetting.customField : [], "customer").map(item => {
            document.forms["edit_customer"].elements["cust-edit-"+item.systemName].value = props.editCustomer[item.systemName] ? props.editCustomer[item.systemName] : null;
        })

    }, [props.editCustomer])
    // INIT DATE END

    // EDIT CUSTOMER START
    async function editCustomer (e) {
        e.preventDefault();

        const noteValue = document.getElementById('edit-customer_note').innerText;

        let validObj = {
            "ФИО" : !name.length,
            "Дата рождения" : findRequired(customSetting.requiredField, "age", "customer").require ? !age.length : null,
            "Контакт" : findRequired(customSetting.requiredField, "contact", "customer").require ? !contact[0].type : null,
            "Пол" : findRequired(customSetting.requiredField, "gender", "customer").require ? !gender.length : null,
            "Примечание" : findRequired(customSetting.requiredField, "note", "customer").require ? !noteValue.length : null,
            "Отв. педагог" : findRequired(customSetting.requiredField, "employee", "customer").require ? !employee.length : null
        }

        filterDisableEntity(customSetting.customField, "customer").map(item => {
            validObj[item.name] = item.require ? !document.forms["edit_customer"].elements["cust-edit-"+item.systemName].value : null
        })

        if (validate(validObj, translate)) return

        let customer = {
            name,
            age,
            gender,
            legal,
            branch,
            note: noteValue,
            status,
            chat_id : chat_id ? chat_id : null,
            customer_id : props.editCustomer.customer_id,
            c_date : props.editCustomer.c_date,
            contact: contact[0].type ? JSON.stringify(contact) : null,
            source: source.id,
            is_lead: props.editCustomer.is_lead,
            f_removed : props.editCustomer.f_removed
        }

        filterDisableEntity(customSetting.customField, "customer").map(item => {
            let value = document.forms["edit_customer"].elements["cust-edit-"+item.systemName].value;
            customer[item.systemName] = value.length ? value : null;
        })

        await request(`${URL.back_end}/customer/${props.editCustomer.customer_id}`, 'PUT', customer);
        await request(`${URL.back_end}/customer_employee/${props.editCustomer.customer_id}`, 'PUT', employee.map(item => item.employee_id));
        await pushArchive(2, 'customer', props.editCustomer.customer_id, customer, props.editCustomer);

        if (error) console.log(error)

        window.location.reload();
    }
    // EDIT CUSTOMER END

    return (
        <div id="edit-customer" className="modal in" ref={modal}>
            <ToastContainer />
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editCustomer(e)} name="edit_customer">
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">
                                { translate('Изменить клиента') } &nbsp;—&nbsp;&nbsp;
                                <span className="dropdown" onClick={e => openMenu(e)}>
                                    <a className="dropdown-toggle crm-dashed-link text-lowercase">
                                       { status ? findObject(customerSetting.status, status)?.name : translate('Не задано') }
                                        <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        {
                                            customerSetting ?
                                                filterDisable(customerSetting.status).map(item => (
                                                    <li key={shortid.generate()}>
                                                        <a onClick={() => setStatus(item.id)}>
                                                            {item.name}
                                                        </a>
                                                    </li>
                                                ))
                                                : null
                                        }
                                    </ul>
                               </span>
                            </h4>
                        </div>
                        {/*BODY*/}
                        <div className='modal-body p-md'>
                            {/*NAME*/}
                            <div className='form-group field-customer-name required'>
                                <div className='col-sm-3'>
                                    <label className='control-label'>
                                        {translate('ФИО')}
                                    </label>
                                </div>
                                <div className='col-sm-9'>
                                    <input
                                      type='text'
                                      id='customer-name'
                                      className='form-control'
                                      name='name'
                                      placeholder={translate('Например, Иванов Иван')}
                                      value={name}
                                      onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*BIRTHDAY + AGE*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'age', 'customer') : null}`}>
                                <div className='col-sm-3'>
                                    <label className='control-label'>
                                        {translate('Дата рождения')}
                                    </label>
                                </div>
                                <div className='col-sm-3' style={{ paddingRight: 0 }}>
                                    <input
                                      type='date'
                                      className='form-control dob-input'
                                      value={age}
                                      onChange={e => setAge(e.target.value)}
                                    />
                                </div>
                                <div className='col-sm-6'>
                                    <Gender
                                      setProps={setGender}
                                      value={gender}
                                    />
                                    <label className='font-noraml pull-right m-t-xs m-r-sm'>
                                        {translate('Пол')}
                                    </label>
                                </div>
                            </div>
                            {/*CONTACT*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'contact', 'customer') : null}`}>
                                <div className='col-sm-3'>
                                    <label className='control-label'
                                           htmlFor='customer-contact_contacts'>{translate('Контакты')}</label>
                                </div>
                                <div className='col-sm-9'>
                                    <Contact
                                      setContact={setContact}
                                      contact={contact}
                                    />
                                </div>
                            </div>
                            {/*LEGAL*/}
                            <div className='form-group'>
                                <div className='col-sm-3'>
                                    <label className='control-label'> {translate('Заказчик')} </label>
                                </div>
                                <div className='col-sm-9'>
                                    <input
                                      type='text'
                                      id='customer-legal_name'
                                      className='form-control'
                                      placeholder={translate('Имя заказчика')}
                                      value={legal}
                                      onChange={e => {
                                          setLegal(e.target.value);
                                      }}
                                    />
                                </div>
                            </div>
                            {/* BRANCH */}
                            <div className='form-group'>
                                <div className='col-sm-3'>
                                    <label className='control-label'>
                                        Бот за замовчуванням
                                    </label>
                                </div>
                                <div className='col-sm-9'>
                                    {
                                        integration ?
                                          (
                                            <select className='form-control' onChange={e => setBranch(e.target.value)}
                                                    value={branch}>
                                                <option value=''> {translate('Не задано')} </option>
                                                {integration.telegram.map(item => <option
                                                  value={item.id}> {item.username} </option>)}
                                            </select>
                                          )
                                          : null
                                    }
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'note', 'customer') : null}`}>
                                <div className='col-sm-3'>
                                    <label className='control-label'
                                           htmlFor='customer-note'> {translate('Примечание')} </label>
                                </div>
                                <div className='col-sm-9'>
                                    <span
                                      id='edit-customer_note'
                                      className='form-control textarea'
                                      role='textbox'
                                      contentEditable='true'
                                      suppressContentEditableWarning={true}
                                    >
                                        {note}
                                    </span>
                                </div>
                            </div>
                            {/*SOURCE*/}
                            <div className='form-group field-customer-lead_source_id'>
                                <div className='col-sm-3'>
                                    <label className='control-label'>
                                        {translate('Источник')}
                                    </label>
                                </div>
                                <div className='col-sm-9'>
                                    <InputChooseSingleObject
                                      sql={salesSetting ? filterDisable(salesSetting.leadSource) : []}
                                      setProps={setSource}
                                      topicSearch='name'
                                      type_id='id'
                                      value={source ? salesSetting ? findObject(salesSetting.leadSource, source.id) ? findObject(salesSetting.leadSource, source.id).name : null : null : ''}
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'employee', 'customer') : null}`}>
                                <div className='col-sm-3'>
                                    <label className='control-label'>
                                        {translate('Отв. педагог')}
                                    </label>
                                </div>
                                <div className='col-sm-9'>
                                    <InputChooseMultiObject
                                      sql={props.dataEmployee.filter(item => item.f_removed === 0)}
                                      setProps={setEmployee}
                                      value={employee}
                                      topicSearch='name'
                                      type_id='employee_id'
                                    />
                                </div>
                            </div>
                            {/*CHAT_ID*/}
                            <div className='form-group'>
                                <div className='col-sm-3'>
                                    <label className='control-label'>
                                        chat_id
                                    </label>
                                </div>
                                <div className='col-sm-9'>
                                    <input
                                      type='number'
                                      className='form-control'
                                      value={chat_id}
                                      onChange={e => setChat_id(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*CUSTOM FIELD*/}
                            <div className='form-group'>
                                <div className='col-sm-4'><b> {translate('Дополнительные поля')} </b></div>
                            </div>
                            {
                                customSetting ?
                                  filterDisableEntity(customSetting.customField, 'customer').map(item => (
                                    <div className={`form-group ${item.require ? 'required' : ''}`}>
                                        <div className='col-sm-3'>
                                            <label className='control-label'>{item.name}</label>
                                        </div>
                                        <div className='col-sm-9'>
                                            {typeField(item, 'cust-edit-')}
                                        </div>
                                    </div>
                                  ))
                                  : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-sm btn-w-m btn-white'
                                    onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type='submit' className='btn btn-sm btn-w-m btn-success'>
                                <i className='fa fa-save' /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}