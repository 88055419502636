import {useContext, useRef, useState} from "react";
import {closeModal} from "../../../../../Function/common";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from "../../../../../Api/URL.json";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {validate} from "../../../../../Function/validateForm";

export default function AddCustomField () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {customSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [entity, setEntity] = useState('');
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [systemName, setSystemName] = useState('');
    const [placeholder, setPlaceholder] = useState(null);
    const [defaultValue, setDefaultValue] = useState(null);
    const [option, setOption] = useState([""]);
    // STATE END

    // ADD CUSTOM FIELD START
    async function addCustomField (e) {
        e.preventDefault();

        let validObj = {
            "Раздел CRM" : !entity.length,
            "Тип поля" : !type.length,
            "Название" : !name.length,
            "Системное имя" : !(/^\S+[A-Za-z1-9]$/gi).test(systemName)
        }

        if (validate(validObj, translate)) return

        let customField = {
            id : customSetting.customField.length > 0 ? customSetting.customField[customSetting.customField.length-1].id+1 : 0,
            disable: false,
            require: false,
            option : option.length === 1 && option[0] === "" ? null : option ,
            entity, type, name, systemName, placeholder, defaultValue
        }

        customSetting.customField.push(customField);
        await request(`${URL.back_end}/setting/custom`, 'PUT', {customSetting: JSON.stringify(customSetting)})
        if (error) return error;
        window.location.reload();
    }
    // ADD CUSTOM FIELD END

    return (
        <div id="add-custom-field" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">{translate('Добавить дополнительное поле')}</h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addCustomField(e)}>
                        <div className="modal-body p-md">
                            {/*ENTITY*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="customfield-related_model">{translate('Раздел CRM')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <select id="customfield-related_model" className="form-control" onChange={e => setEntity(e.target.value)}>
                                        <option value="">{translate('Не задано')}</option>
                                        <option value="customer">{translate('Карточка клиента')}</option>
                                        <option value="group">{translate('Карточка группы')}</option>
                                        <option value="CustomerContract">{translate('Клиентский договор')}</option>
                                        <option value="employee">{translate('Карточка педагога')}</option>
                                        <option value="Company">{translate('Карточка юр. лица')}</option>
                                        <option value="Task">{translate('Карточка задачи')}</option>
                                        <option value="BankAccount">{translate('Банковский счет')}</option>
                                        <option value="tariff">{translate('Абонементы')}</option>
                                        <option value="user">{translate('Модель пользователя')}</option>
                                        <option value="TestResult">{translate('Модель результата теста')}</option>
                                        <option value="subject">{translate('Модель предмета')}</option>
                                        <option value="pay">{translate('Модель платежа')}</option>
                                        <option value="LeadSource">{translate('Модель источника привлечения')}</option>
                                        <option value="lesson">{translate('Модель урока')}</option>
                                        <option value="discount">{translate('Модель скидки')}</option>
                                    </select>
                                    <div className="help-block" />
                                </div>
                            </div>
                            {/*TYPE*/}
                            <div className="form-group field-customfield-type required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="customfield-type">{translate('Тип поля')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <select id="customfield-type" className="form-control js-reload-ctl" onChange={e => setType(e.target.value)}>
                                    <optgroup label="Поле для ввода (input)">
                                        <option value="">{translate('Не задано')}</option>
                                        <option value="1">{translate('Строка')} (type=text)</option>
                                        <option value="16">{translate('Валютное поле')} ({translate('Дробное число')})</option>
                                        <option value="2">{translate('Дата')} ({translate('дд.мм.гггг')})</option>
                                        <option value="3">{translate('Время')} ({translate('чч:мм')})</option>
                                        <option value="4">{translate('Дата и время')} ({translate('дд.мм.гггг чч:мм')})</option>
                                        <option value="5">{translate('Дейтпикер')} ({translate('календарь')})</option>
                                        <option value="6">{translate('Целое число')}</option>
                                        <option value="7">{translate('Дробное число')}</option>
                                        <option value="10">E-mail (type=email)</option>
                                        <option value="12">{translate('Телефон')} ({translate('маска')})</option>
                                        <option value="11">Web {translate('адрес')} (URL)</option>
                                    </optgroup>
                                    <optgroup label={`${translate('Выбор из списка')} (select)`}>
                                        <option value="9">{translate('Выбор одного значения')} (select)</option>
                                        <option value="13">{translate('Выбор нескольких значений')} (select)</option>
                                    </optgroup>
                                    <optgroup label={`${translate('Системные поля')}`}>
                                        <option value="14">{translate('Привязка локации')}</option>
                                        <option value="15">{translate('Привязка предмета')}</option>
                                    </optgroup>
                                    <optgroup label={`${translate('Прочее')}`}>
                                        <option value="8">{translate('Чекбокс (Да/нет)')}</option>
                                    </optgroup>
                                </select>
                                    <div className="help-block" />
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group field-customfield-title required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="customfield-title">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Номер школы')}
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                    />
                                </div>
                            </div>
                            {/*SYSTEM NAME*/}
                            <div className="form-group field-customfield-name required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="customfield-name">{translate('Системное имя')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, schoolname`}
                                        onChange={e => setSystemName(e.target.value)}
                                        value={systemName}
                                    />
                                </div>
                            </div>
                            {/*PLACEHOLDER*/}
                            <div className="form-group field-customfield-placeholder">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="customfield-placeholder">{translate('Плейсхолдер')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например: Например, школа №6')}
                                        onChange={e => setPlaceholder(e.target.value)}
                                        value={placeholder}
                                    />
                                </div>
                            </div>
                            {/*OPTION*/}
                            {
                                type === "9" || type === "13" ?
                                    (
                                        <>
                                            <hr />
                                            <div className="form-group">
                                                <div className="col-sm-3">
                                                    <label className="control-label">{translate('Значения')}</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    {
                                                        option.map((item, index) => (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-sm m-b-xs"
                                                                    placeholder={`${translate('Значение')} ${index+1}`}
                                                                    onChange={e => {
                                                                        option[index] = e.target.value
                                                                        setOption([...option])
                                                                    }}
                                                                    value={item}
                                                                />
                                                                {index !== 0 ?
                                                                    <a className="pull-right m-r-sm" style={{marginTop: "-30px"}} onClick={() => {
                                                                        let temp_arr = option.filter((item2, index2) => {
                                                                            if (index !== index2) {
                                                                                return item2;
                                                                            }
                                                                        })
                                                                        setOption(temp_arr);
                                                                    }
                                                                    }>
                                                                        <i className="ion-minus" />
                                                                    </a>
                                                                    : null}
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                                <div className="col-sm-1">
                                                    <a title={translate('Добавить')} style={{marginTop: "6px", display: "inline-block"}} onClick={() => setOption([...option, ""])}>
                                                        <i className="ion-plus" />
                                                    </a>
                                                </div>
                                                <div className="col-sm-9 col-sm-offset-3 help-block text-danger" />
                                            </div>
                                            <hr className="m-t-none" />
                                        </>
                                    )
                                    : null
                            }
                            {/*DEFAULT VALUE*/}
                            <div className="form-group field-customfield-default">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="customfield-default">{translate('Значение по умолч.')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, школа №6')}
                                        onChange={e => setDefaultValue(e.target.value)}
                                        value={defaultValue}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}