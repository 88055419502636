import {useHttp} from "../../../Hooks/http.hook";
import {useEffect, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {getDateMonthDiapason, objectToUrlParams} from "../../../Function/common";
import URL from "../../../Api/URL.json";

export default function CustomerActiveLost () {

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // VALUE START
    const objDateMonthDiapason = getDateMonthDiapason();
    // VALUE END

    // STATE START
    const [ data, setData ] = useState({});
    const [ date_start, setDateStart ] = useState(objDateMonthDiapason.date_start);
    const [ date_end, setDateEnd ] = useState(objDateMonthDiapason.date_end);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const params_obj = {
                date_start, date_end
            }

            const [ response ] = await request(`${URL.back_end}/dashboard/customerActiveLost?${objectToUrlParams(params_obj)}`, 'GET');

            setData(response);
        }

        fetchData().catch(e => console.log(e))

    }, [date_start, date_end])
    // INIT DATA END

    return (
        <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
            <div className="dashboard-item">
                <label className="font-bold h4 text-center dashboard-item-head no-margins">
                    Аналітика по клієнтам
                    <h5>
                        від
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center', marginBottom : '5px' }}
                            onChange={e => setDateStart(e.target.value)}
                            value={date_start}
                        />
                        <br />
                        до
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center' }}
                            onChange={e => setDateEnd(e.target.value)}
                            value={date_end}
                        />
                    </h5>
                </label>
                <div className="row">
                    <div className="col-sm-12 no-padding">
                        <div className="dashboard-chart" id="chart-8">
                            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
                                <thead>
                                <tr>
                                    <th> Тип уроку </th>
                                    <th> Притік </th>
                                    <th> Відтік </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> { translate('Индивидуальный') } </td>
                                        <td> { data.active_ind } </td>
                                        <td> { data.lost_ind } </td>
                                    </tr>
                                    <tr>
                                        <td> { translate('Групповой') } </td>
                                        <td> { data.active_group } </td>
                                        <td> { data.lost_group } </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}