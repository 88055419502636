import { useHttp } from '../../../Hooks/http.hook';
import { useTranslate } from '../../../Hooks/translate.hook';
import { useEffect, useState } from 'react';
import URL from '../../../Api/URL.json';

export default function SpendLessonAnalytic() {

	// HOOK START
	const { request } = useHttp();
	const { translate } = useTranslate();
	// HOOK END

	// STATE START
	const [ data, setData ] = useState({});
	// STATE END

	// INIT DATA START
	useEffect(() => {
		async function fetchData() {
			const response = await request(`${URL.back_end}/dashboard/spendLessonAnalytic`, 'GET');
			setData(response);
		}

		fetchData().catch((error) => console.error(error));
	}, [])
	// INIT DATA END

	return (
	  <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
		  <div className="dashboard-item">
			  <label className="font-bold h4 text-center dashboard-item-head no-margins">
				  Аналітика по урокам
			  </label>
			  <div className="row">
				  <div className="col-sm-12 no-padding">
					  <div className="dashboard-chart" id="chart-10">
						  <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
							  <thead>
							  <tr>
								  <th />
								  <th>Індивідуальні</th>
								  <th>Групові</th>
								  <th>Скасовані</th>
							  </tr>
							  </thead>
							  <tbody>
							  <tr>
								  <td>Місяць</td>
								  <td>{data.indLessonMonth}</td>
								  <td>{data.groupLessonMonth}</td>
								  <td>{data.cancelMonth}</td>
							  </tr>
							  <tr>
								  <td>Тиждень</td>
								  <td>{data.indLessonWeek}</td>
								  <td>{data.groupLessonWeek}</td>
								  <td>{data.cancelWeek}</td>
							  </tr>
							  </tbody>
						  </table>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	)

}