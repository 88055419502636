import DateWidget from "../../../Components/Widget/DateWidget";
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {useArchive} from "../../../Hooks/archive.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal} from "../../../Function/common";
import {validate} from "../../../Function/validateForm";
import currency from "../../../Assets/data/JSON/currency.json";

export default function EditTariff (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    // SWEET MODAL END

    // STATE START
    const [title, setTitle] = useState('');
    const [billing, setBilling] = useState(null);
    const [lessonNumber, setLessonNumber] = useState('');
    const [lessonDuration, setLessonDuration] = useState(null);
    const [price, setPrice] = useState('');
    const [pricePerLesson, setPricePerLesson] = useState(null);
    const [bill, setBill] = useState('Любой');
    const [period, setPeriod] = useState(null);
    // STATE END

    // INIT DATE START
    useEffect(() => {
        if (!Object.keys(props.tariffEdit).length) return

        setTitle(props.tariffEdit.title);
        billingHandler(props.tariffEdit.billing);
        setLessonNumber(props.tariffEdit.lesson_number);
        setLessonDuration(props.tariffEdit.lesson_duration);
        setPrice(props.tariffEdit.price);
        setPricePerLesson(props.tariffEdit.price_per_lesson);
        setBill(props.tariffEdit.bill);
        setPeriod(props.tariffEdit.period);

        filterDisableEntity(customSetting ? customSetting.customField : [], "tariff").map(item => {
            document.forms["edit_tariff"].elements["tariff-edit-"+item.systemName].value = props.tariffEdit[item.systemName];
        })

    }, [props.tariffEdit])
    // INIT DATE END

    // HANDLER START
    function periodHandler (e) {
        if (e.target.value.length <= 4) {
            setPeriod(e.target.value);
        }
    }
    function billingHandler (value) {
        if (value === undefined) return
        let special_input = document.getElementsByClassName("js-special-edit");
        setBilling(value);
        if (value !== "Поурочная") {
            for (let i = 0; i < special_input.length; i++) {
                special_input[i].className += " hide";
            }
        } else {
            for (let i = 0; i < special_input.length; i++) {
                special_input[i].className = special_input[i].className.replace(" hide", "");
            }
        }
    }
    function lessonNumberHandler (e) {
        setLessonNumber(e.target.value);
        calculatePerLesson();
    }
    function priceHandler (e) {
        setPrice(e.target.value);
        calculatePerLesson();
    }
    // HANDLER END

    // MODAL FUNC START
    function showAdditional () {
        let additional_input = document.getElementsByClassName("js-additional");

        for (let i = 0; i < additional_input.length; i++) {
            if (additional_input[i].style.display === "none") {
                additional_input[i].style.display = "block";
            } else {
                additional_input[i].style.display = "none";
            }
        }
    }
    function calculatePerLesson () {
        const price_value = document.getElementById('edit-price').value;
        const count_value = document.getElementById('edit-lesson_number').value;
        let current_price = +price_value > 0 ? +price_value : 0;
        let count = +count_value > 0 ? +count_value : 1;
        let cost = current_price / count;
        setPricePerLesson(`${Math.floor(cost * 100) / 100}`);
    }
    // MODAL FUNC END

    // EDIT TARIFF START
    async function editTariff (e) {
        e.preventDefault();

        let validObj = {
            "Название" : !title.length,
            "Кол-во уроков" : !(lessonNumber.toString().length) && billing === "Поурочная",
            "Стоимость" : !price.toString().length
        }

        if (validate(validObj, translate)) return

        let tariff = {
            title, price, billing, bill, period,
            lesson_number : lessonNumber > 0 ? lessonNumber : null,
            lesson_duration: lessonDuration ? lessonDuration : null,
            price_per_lesson: pricePerLesson,
            f_removed: props.tariffEdit.f_removed
        }

        filterDisableEntity(customSetting.customField, "tariff").map(item => {
            tariff[item.systemName] = document.forms["edit_tariff"].elements["tariff-edit-"+item.systemName].value
        })

        await request(`${URL.back_end}/tariff/${props.tariffEdit.tariff_id}`, 'PUT', tariff);

        await pushArchive(2, 'tariff', props.tariffEdit.tariff_id, { ...tariff, tariff_id : props.tariffEdit.tariff_id, added : props.tariffEdit.added }, props.tariffEdit);

        if (error) return console.log(error);

        window.location.reload();
    }
    // EDIT TARIFF END

    // ARCHIVE TARIFF START
    async function buttonArchive (value) {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";
            modal.current.style.display = "none";

            // REQUEST

            await request(`${URL.back_end}/tariff/${props.tariffEdit.tariff_id}`, 'PUT', {f_removed: value})
            if (error) return console.log(error)
            props.sql.forEach(function (item, i) {
                if (props.tariffEdit.tariff_id === item.tariff_id) {
                    props.sql[i].f_removed = value;
                    props.updateSql([...props.sql]);
                }
            })
        })
    }
    // ARCHIVE TARIFF END

    return (
        <div id="edit-tariff" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal tariff-form" name="edit_tariff" onSubmit={e => editTariff(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title">{translate('Править абонемент')}</h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*TITLE*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="title">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        className="form-control js-focus-ctl"
                                        placeholder={translate('Например, Стандарт')}
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*BILLING*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="tariff-type">{translate('Тарификация')}</label>
                                </div>
                                <div className="col-sm-8 highlight-checkbox">
                                    <span className="form-control">
                                        {translate(billing)}
                                    </span>
                                </div>
                            </div>
                            {/*LESSON NUMBER*/}
                            <div className="js-special-edit">
                                <div className="form-group required">
                                    <div className="col-sm-4">
                                        <label className="control-label" htmlFor="lesson_number">{translate('Кол-во уроков')}</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="number"
                                            id="edit-lesson_number"
                                            className="form-control count-control hide-arrows"
                                            name="lesson_number"
                                            placeholder={`${translate('Например')}, 8`}
                                            value={lessonNumber}
                                            onChange={e => lessonNumberHandler(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*LESSON DURATION*/}
                            <div className="js-special-edit">
                                <div className="form-group">
                                    <div className="col-sm-4">
                                        <label className="control-label" htmlFor="tariff-duration">{translate('Длительность урока, мин.')}</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="number"
                                            id="tariff-duration"
                                            className="form-control hide-arrows"
                                            name="Tariff[duration]"
                                            placeholder={`${translate('Например')}, 60`}
                                            value={lessonDuration}
                                            onChange={e => setLessonDuration(e.target.value)}
                                        />
                                        {
                                            lessonDuration && (
                                                <div className="text-navy">
                                                    { translate('Списание средств с баланса клиентов будет происходить пропорционально продолжительности урока') }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*SETTING PRICE*/}
                            <h4 className="border-bottom m-b-md m-t-md no-padding">{translate('Настройка стоимости')}</h4>
                            {/*PRICE*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="price">{translate('Стоимость')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        id="edit-price"
                                        className="form-control price-control hide-arrows"
                                        name="price"
                                        placeholder={`${translate('Например')}, 800`}
                                        autoComplete="off"
                                        value={price}
                                        onChange={e => priceHandler(e)}
                                    />
                                </div>
                            </div>
                            {/*PRICE PER LESSON*/}
                            <div className="form-group js-special-edit">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="price-per-lesson">{translate('Цена за один урок')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <span id="price-per-lesson" className="form-control text-lowercase">
                                        {pricePerLesson ? pricePerLesson : "0,00"} { basicSetting && currency[basicSetting.baseOption.currency] }
                                    </span>
                                </div>
                            </div>
                            {/*ADVANCED SETTING*/}
                            <a className="pull-right crm-dashed-link m-l-sm text-lowercase" onClick={showAdditional}>{translate('Показать')} ⇳</a>
                            <h4 className="border-bottom m-b-md m-t-md no-padding">{translate('Расширенные настройки')}</h4>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "tariff").map(item => (
                                        <div className="form-group js-additional" style={{display: "none"}}>
                                            <div className="col-sm-4">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-8">
                                                {typeField(item, "tariff-edit-")}
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                            {/*BILL*/}
                            {/*<div className="form-group js-additional" style={{display: "none"}}>*/}
                            {/*    <div className="col-sm-4">*/}
                            {/*        <label className="control-label" htmlFor="tariff-calculation_type">{translate('Клієнтський рахунок')}</label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-sm-8">*/}
                            {/*        <select className="form-control" onChange={e => setBill(e.target.value)}>*/}
                            {/*            <option value="Любой" selected={bill === "Любой"}>{translate('Любой')}</option>*/}
                            {/*            <option value="Базовый счет" selected={bill === "Базовый счет"}>{translate('Базовый счет')}</option>*/}
                            {/*            <option value="Отдельный счет" selected={bill === "Отдельный счет"}>{translate('Отдельный счет')}</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*PERIOD*/}
                            <div className="form-group js-additional" style={{display: "none"}}>
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="period">{translate('Период действия')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="period-end-container">
                                        <input
                                            type="text"
                                            className="form-control period_count_autocomplete ui-autocomplete-input"
                                            id="period"
                                            autoComplete="off"
                                            value={period}
                                            onChange={e => periodHandler(e)}
                                        />
                                        <DateWidget
                                            dateEnd={period}
                                            dateStart={`${new Date().toLocaleDateString()}`}
                                            updateDateEnd={setPeriod}
                                            style={{
                                                top: "34px",
                                                left: "5px",
                                                width: "360px"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*BRANCH*/}
                            {/*<div className="form-group required js-additional" style={{display: "none"}}>*/}
                            {/*    <div className="col-sm-4">*/}
                            {/*        <label className="control-label" htmlFor="branch">{translate('Филиалы')}</label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-sm-8">*/}
                            {/*        <div style={{paddingTop: "5px"}}>*/}
                            {/*            <label className="branch font-noraml no-margins">*/}
                            {/*                <input*/}
                            {/*                    type="checkbox"*/}
                            {/*                    name="branch"*/}
                            {/*                    value="1"*/}
                            {/*                    defaultChecked="true"*/}
                            {/*                />*/}
                            {/*                &nbsp;branch*/}
                            {/*            </label>*/}
                            {/*            <br />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            {
                                props.tariffEdit.f_removed === 0 ?
                                    <a className="btn btn-danger btn-outline crm-ajax-link pull-left" title={translate('В архив')} onClick={() => buttonArchive(1)}>
                                        <i className="fa fa-trash-o" />
                                    </a> :
                                    <a className="btn btn-primary btn-outline crm-ajax-link pull-left" title={translate('Из архива')} onClick={() => buttonArchive(0)}>
                                        <i className="fa fa-reply" />
                                    </a>
                            }
                            <button type="button" className="btn btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}