import {openModal} from "../../../../Function/common";
import UserRow from "../Row/UserRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useEffect, useState} from "react";
import AddUser from "../Modal/User/AddUser";
import EditUser from "../Modal/User/EditUser";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export default function UserBlock ({ sqlCard, setSqlCard }) {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ accounts, setAccounts ] = useState([]);
    const [ editUser, setEditUser ] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!Object.keys(sqlCard).length) return

        async function fetchData () {

            const account = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 2, systemId : sqlCard.employee_id } })

            if (error) throw error

            setAccounts(account.users);
        }

        fetchData().catch(e => console.log(e))

    }, [ sqlCard ])
    // INIT DATA END

    return (
        <>
            <p className="no-padding m-b-xs m-t-xs">
                <strong className="text-muted"> { translate('Доступ в CRM') } </strong>
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal("add-user")}>
                    <span className="text-lowercase font-noraml"> { translate('Предоставить') } </span>
                </a>
            </p>
            {
                accounts.length ?
                    accounts.map(item => (
                        <UserRow
                            data={item}
                            setEditUser={setEditUser}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({ translate('не задано') })
                            </div>
                        </div>
                    </div>
            }

            {/*USER*/}
            <AddUser
                sql={sqlCard}
                updateSql={setSqlCard}
            />

            <EditUser
                sql={sqlCard}
                updateSql={setSqlCard}
                editUser={editUser}
            />
        </>
    )
}