import {useRef} from "react";
import {useTranslate} from "../../Hooks/translate.hook";

export default function DeleteModal ({ id, handler, text, close }) {

	// REF START
	const overlay = useRef();
	const alert = useRef();
	// REF END

	// HOOK START
	const { translate } = useTranslate();
	// HOOK END

	// BUTTON FUNC START
	function confirmHandler (event) {
		handler();
		if (close) closeModal(event);
	}

	function closeModal (e) {
		e.preventDefault();

		overlay.current.style.display = "none";
		alert.current.style.display = "none";
	}
	// BUTTON FUNC END

	return (
		<>
			<div className="sweet-overlay" id={id + '-overlay'} tabIndex={-1} style={{opacity: 1.05, display: "none"}} ref={overlay}/>
			<div className="sweet-alert showSweetAlert visible" id={id + '-modal'} style={{marginTop: "-91px", display: "none"}} ref={alert}>
				<h2>{ translate('Подтвердите действие') }</h2>
				<p id="sweet-text"> { text ? translate(text) : translate('Вы уверены, что хотите удалить запись?') } </p>
				<div className="sa-button-container">
					<button className="confirm" id="sweet-confirm" tabIndex={1} onClick={(event) => confirmHandler(event)}>
						{ translate('Да') }
					</button>
					<button className="cancel" tabIndex={2} onClick={e => closeModal(e)}>
						{ translate('Нет') }
					</button>
				</div>
			</div>
		</>
	)
}