import React, {memo} from "react";
import {Link} from "react-router-dom";
import {useTranslate} from "../../../../Hooks/translate.hook";

export const EmployeeBlock = memo(({ customerEmployee, access }) => {

    const { translate } = useTranslate();

    if (!access) return

    return (
        <>
            <hr className="hr-line-dashed m-t-sm m-b-xs" />
            <div className="row">
                <div className="col-xs-5 text-muted m-b-xs"> { translate('Педагог') } </div>
                <div className="col-xs-7 m-b-xs text-right">
                    {
                        customerEmployee.length ?
                            customerEmployee.map(item => (
                                <div>
                                    <Link to={`/company/1/employee/view?id=${item.employee_id}`} target="_blank">
                                        {item.name}
                                    </Link>
                                    <br />
                                </div>
                            ))
                            : <span className="text-danger text-lowercase">{translate('Не задано')}</span>
                    }
                </div>
            </div>
        </>
    )
})