import {useContext, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import {closeModal, maxId} from "../../../../../Function/common";
import URL from "../../../../../Api/URL.json";

export default function AddLeadReject () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    // STATE END

    // ADD REFUSAL START
    async function addLeadReject (e) {
        e.preventDefault();

        if (!name) return errorNotify(`Необходимо заполнить "Название"`)

        let refusal = {
            id: salesSetting.leadReject.length > 0 ? maxId(salesSetting.leadReject)+1 : 0,
            name,
            disabled: false
        }

        salesSetting.leadReject.push(refusal);

        await request(`${URL.back_end}/setting`, 'PUT', {salesSetting: JSON.stringify(salesSetting)})

        if (error) return error;

        window.location.reload();
    }
    // ADD REFUSAL END

    return (
        <div id="add-lead-reject" className="modal in" style={{ paddingRight: '16px' }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addLeadReject(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">
                                { translate('Добавить причину отказа') }
                            </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Название') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, не устроили условия')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}