// HOOK
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslate} from "../../Hooks/translate.hook";
import {errorNotify} from "../../Function/Notify";

export default function InputChooseMultiObject (props) {

    // REF START
    const chosen_drop = useRef();
    const chosen_container = useRef();
    const chosen_result = useRef();
    // REF END

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [ searchValue, setSearchValue ] = useState("");
    const [ choice, setChoice ] = useState([]);
    // STATE END

    useEffect(() => {
        setSearchValue(translate('Выберите') ? translate('Выберите') : "")
    }, [translate])

    // USE EFFECT START
    useEffect(() => {
        if (props.value !== undefined && props.value !== null) {
            setChoice(props.value)
        }
    }, [props.value])
    // USE EFFECT END

    // USE MEMO START
    const searchItems = useMemo(() => {
        if (searchValue !== "" && searchValue !== " ") {
            return props.sql.filter(item => item[props.topicSearch] ? item[props.topicSearch].toLowerCase().includes(searchValue.toLowerCase()) : false);
        } else {
            return [];
        }
    },[searchValue])
    // USE MEMO END

    // SHOW CHOSEN DROP START
    function chosenShow () {
        setSearchValue("");
        chosen_drop.current.style.left = "0";
        chosen_container.current.classList += " chosen-container-active";

        document.addEventListener('click', function(event) {
            if (!chosen_container.current.contains(event.target)) {
                chosen_drop.current.style.left = "-9999px";
                setSearchValue(translate('Выберите') ? translate('Выберите') : "")
                chosen_container.current.classList = "chosen-container chosen-container-multi";
            }
        });
    }
    // SHOW CHOSEN DROP END

    // FUNCTION START
    function searchQuery (e) {
        setSearchValue(e.target.value);
    }

    function addChoice (e, item) {
        if (e.target.className !== "result-selected") {

            if (props.limit) {
                if (props.limit <= choice.length) {
                    return errorNotify(props.limitError);
                }
            }

            let obj = {
                name : e.target.dataset.name
            }

            obj[props.type_id] = +e.target.dataset.id;
            setChoice([...choice,obj]);
            props.setProps([...choice,item]);
        }

        e.target.className = "result-selected";
    }

    function removeChoice (e) {
        choice.splice(e.target.dataset.index,1);
        setChoice([...choice]);
        props.setProps([...choice]);
    }

    function findArray (item) {
        return choice.map(item => item[props.type_id]).includes(item[props.type_id]);
    }

    function sortArray (arr) {
        return arr.filter(item => item[props.topicSearch]).sort((a, b) => a[props.topicSearch].replace(/і/g, 'и').localeCompare(b[props.topicSearch].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div className="chosen-container chosen-container-multi" style={{ width: '100%' }} ref={chosen_container}>
            <ul className="chosen-choices" onClick={chosenShow}>
                {
                    choice.length > 0 ?
                        choice.map((item,index) => (
                            <li className="search-choice">
                                <span>{item.name || item.title}</span>
                                <a className="search-choice-close"
                                   data-id={item[props.type_id]}
                                   data-index={index}
                                   onClick={e => removeChoice(e)}
                                />
                            </li>
                        ))
                    : null
                }
                <li className="search-field">
                    <input
                        type="text"
                        className="default"
                        autoComplete="off"
                        style={{width: "88.0625px"}}
                        value={searchValue}
                        onChange={e => searchQuery(e)}
                    />
                </li>
            </ul>
            <div className="chosen-drop" ref={chosen_drop}>
                <div className="chosen-results" ref={chosen_result}>
                    {
                        searchItems.length > 0 ?
                            sortArray(searchItems).map(item => (
                                findArray(item) ?
                                      <li className="result-selected">{item[props.topicSearch]}</li>
                                    : <li className="active-result" data-name={item[props.topicSearch]} data-id={item[props.type_id]} onClick={e => addChoice(e, item)}>{item[props.topicSearch]}</li>
                            )) :
                            sortArray(props.sql).map(item => (
                                findArray(item) ?
                                    <li className="result-selected">{item[props.topicSearch]}</li>
                                  : <li className="active-result" data-name={item[props.topicSearch]} data-id={item[props.type_id]} onClick={e => addChoice(e, item)}>{item[props.topicSearch]}</li>
                            ))
                    }
                </div>
            </div>
        </div>
    )
}