import {Link} from "react-router-dom";
import UserColumns from "./UserColumns";
import {useTranslate} from "../../../Hooks/translate.hook";
import React from "react";

export default function UserTable (props) {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    // USER TABLE FUNC START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;
        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            props.updateRows(tempSelection);
        }
        else {
            selection.forEach(element => element.checked = false);
            props.updateRows([])
        }
    }

    function renderTbody () {

        if (props.loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (props.sql.length) {
            return props.sql.map(item => (
                <UserColumns
                    data={item}
                    sql={props.sql}
                    updateSql={props.updateSql}
                    updateRows={props.updateRows}
                    roleData={props.roleData}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // USER TABLE FUNC END

    return (
        <>
            <div id="w1-container" className="table-responsive kv-grid-container">
                <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                    <thead>
                        <tr>
                            <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.98%"}}>
                                <input
                                    type="checkbox"
                                    className="select-on-check-all"
                                    name="selection_all"
                                    onClick={e => selection_all(e)}
                                />
                            </th>
                            <th style={{width: "2.66%"}}>
                                <Link to="/company/1/user/index?sort=id" data-sort="id">ID</Link>
                            </th>
                            <th style={{width: "4.57%"}}>
                                <Link to="/company/1/user/index?sort=avatar" data-sort="avatar">{translate('Фото')}</Link>
                            </th>
                            <th style={{width: "17.55%"}}>
                                <Link to="/company/1/user/index?sort=email" data-sort="email">E-mail</Link>
                            </th>
                            <th style={{width: "19.78%"}}>
                                <Link to="/company/1/user/index?sort=name" data-sort="name">{translate('ФИО')}</Link>
                            </th>
                            <th style={{width: "20.48%"}}>
                                <Link to="/company/1/user/index?sort=role_ids" data-sort="role_ids">{translate('Роль(и)')}</Link>
                            </th>
                            <th style={{width: "6.55%"}}>
                                <Link to="/company/1/user/index?sort=gender" data-sort="gender">{translate('Пол')}</Link>
                            </th>
                            <th style={{width: "10.76%"}}>
                                <Link to="/company/1/user/index?lang=en-En&amp;sort=phone" data-sort="phone">{translate('Телефон')}</Link>
                            </th>
                            {/*<th style={{width: "11.11%"}}>*/}
                            {/*    <Link to="/company/1/user/index?sort=last_login" data-sort="last_login">{translate('Последний вход')}</Link>*/}
                            {/*</th>*/}
                            <th style={{width: "3.5%"}}>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody> { renderTbody() } </tbody>
                </table>
            </div>
        </>
    )
}