import {Link} from "react-router-dom";
import GroupColumns from "./GroupColumns";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import React, {useEffect, useState} from "react";

export default function GroupTable (props) {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ customersData, setCustomersData ] = useState([]);
    const [ employeesData, setEmployeesData ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {
            const customers = await request(`${URL.back_end}/group_customer`, 'GET');
            const employees = await request(`${URL.back_end}/group_employee`, 'GET');

            if (error) throw error;

            setCustomersData(customers);
            setEmployeesData(employees);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // GROUP TABLE FUNC START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;

        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            props.updateRows(tempSelection);
        } else {
            selection.forEach(element => element.checked = false);
            props.updateRows([])
        }
    }

    function renderTbody () {

        if (props.loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (props.sql.length) {
            return props.sql.map(item => (
                <GroupColumns
                    data={item}
                    sql={props.sql}
                    customersData={customersData}
                    employeesData={employeesData}
                    updateSql={props.updateSql}
                    updateRows={props.updateRows}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // GROUP TABLE FUNC END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.98%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection_all"
                                onClick={e => selection_all(e)}
                            />
                        </th>
                        <th style={{width: "8.28%"}}>
                            <Link to="/company/1/group/index?sort=-name">
                                {translate('Название')}
                            </Link>
                        </th>
                        <th style={{width: "8.43%"}}>
                            <Link to="/company/1/group/index?sort=status_id">
                                {translate('Статус')}
                            </Link>
                        </th>
                        <th style={{width: "9.93%"}}>
                            <Link to="/company/1/group/index?sort=note">
                                {translate('Описание')}
                            </Link>
                        </th>
                        <th style={{width: "10.14%"}}>
                            <Link to="company/1/group/index?sort=customer_ids">
                                {translate('Клиенты')}
                            </Link>
                        </th>
                        <th style={{width: "9.87%"}}>
                            <Link to="/company/1/group/index?sort=level_id">
                                {translate('Уровень знаний')}
                            </Link>
                        </th>
                        <th style={{width: "13.88%"}}>
                            <Link to="/company/1/group/index?sort=teacher_ids">
                                {translate('Отв. педагог')}
                            </Link>
                        </th>
                        <th style={{width: "4.87%"}}>
                            <Link to="/company/1/group/index?sort=row_color">
                                Період
                            </Link>
                        </th>
                        <th style={{width: "2.27%"}}>
                            &nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody>{ renderTbody() }</tbody>
            </table>
        </div>
    )
}