import "../../Assets/css/Lead.css";
import SideMenu from "../../Components/SideMenu/SideMenu";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTranslate } from "../../Hooks/translate.hook";
import { openModal } from "../../Function/common";
import LeadBoard from "./Table/LeadBoard";
import {useHttp} from "../../Hooks/http.hook";
import {useContext, useEffect, useState} from "react";
import URL from "../../Api/URL.json";
import AddLead from "./Modal/AddLead";
import EditLead from "./Modal/EditLead";
import TabAction from "../../Components/TabAction";
import {SettingContext} from "../../Context/SettingContext";
import {LeadTable} from "./Table/LeadTable";
import FilterLead from "./Modal/FilterLead";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import ArchiveModal from "../../Components/Modal/ArchiveModal";
import {AuthContext} from "../../Context/AuthContext";
import ImportModal from "../../Components/Modal/ImportModal/ImportModal";
import {success} from "../../Function/Notify";
import AcceptModal from "../../Components/Modal/AcceptModal";

export default function Lead () {

    // CONTEXT START
    const { customSetting, pageSize, salesSetting, integration } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    const sweet_text = document.getElementById("sweet-text");
    // SWEET MODAL END

    // SWAP VIEW PARAMS START
    const [ searchParams ] = useSearchParams();
    const viewMode = searchParams.get('view_mode');
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // SWAP VIEW PARAMS END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ counts, setCounts ] = useState(0);

        // DATA
    const [ archiveCustomer, setArchiveCustomer ] = useState({});
    const [ employeeData, setEmployeeData ] = useState([]);
    const [ managerData, setManagerData ] = useState([]);
    const [ selectedLead, setSelectedLead ] = useState([]);
    const [ editCustomer, setEditCustomer ] = useState({});
    const [ loading, setLoading ] = useState(true);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            const searchObj = viewMode === 'table' ? { page, limit : pageSize.customer, data : { is_lead : 1, f_removed : 0, ...params } } : { nolimit : true, data : { is_lead : 1, f_removed : 0, ...params } };
            const data = await request(`${URL.back_end}/customer_filter`, 'POST', searchObj );

            if (error) throw error;

            setSql(data.customers);
            setCounts(data.counts);
            setLoading(false);
        }

        fetchData().catch(e => console.log(e))

    }, [page, pageSize, filterParams, viewMode])

    useEffect(() => {

        async function fetchData () {

            const employees = await request(`${URL.back_end}/employee`, 'GET');
            const managers  = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } });

            if (error) throw error

            setEmployeeData(employees);
            setManagerData(managers.users);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // FUNCTION START
    function editButton () {
        if (selectedLead.length === 1) {
            setEditCustomer(sql.filter(item => item.customer_id === selectedLead[0])[0])
            openModal("edit-lead")
        }
    }

    function deleteButton () {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";
        sweet_text.innerText = `${translate('Вы действительно хотите удалить клиентов безвозвратно?')}`;

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            await request(`${URL.back_end}/customer`, 'DELETE', { customer_ids : selectedLead });

            setSql( sql.filter(item => !selectedLead.includes(item.customer_id)) )

            success(translate('Клиент удален'))
        })
    }
    // FUNCTION END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="lead" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: '624px' }}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="?view_mode=board" className={`btn btn-sm btn-white ${(!viewMode || viewMode === 'board') && 'active'}`} title={translate('Отображение: в виде воронки')}>
                                        <i className="ion-stats-bars fa fa-rotate-180" />
                                    </Link>
                                    <Link to="?view_mode=table" className={`btn btn-sm btn-white ${viewMode === 'table' && 'active'}`} title={translate('Отображение: в виде таблицы')}>
                                        <i className="ion-navicon-round" />
                                    </Link>
                                </div>
                                {/*BUTTON ADD*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-plus"
                                    text="Добавить"
                                    handler={() => openModal('add-lead')}
                                />
                                {/*BUTTON EDIT*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    disable={!selectedLead.length}
                                    handler={() => editButton()}
                                />
                                {/*BUTTON DELETE*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-danger"
                                    icon="fa fa-trash-o"
                                    text="Удалить"
                                    disable={!selectedLead.length}
                                    handler={() => deleteButton()}
                                />
                                {/*BUTTON ANOTHER*/}
                                <TabAction
                                    access={true}
                                    className="dropdown btn-group no-padding"
                                    icon="fa fa-ellipsis-h"
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',
                                        text : 'Другое',
                                        list : [
                                            { link : '#', text : 'Импорт из файла...', icon : 'fa fa-upload', handler : () => openModal('import-modal') }
                                        ]
                                    }}
                                />
                                {/*BUTTON FILTER*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    icon="fa fa-filter"
                                    text="Фильтр"
                                    handler={() => openModal('filter-lead')}
                                />
                                {
                                    (filterParams && salesSetting && managerData.length) && (
                                        <FilterOptions
                                            data={JSON.parse(decodeURI(filterParams))}
                                            means={{
                                                source     : translate('Источник'),
                                                name       : translate('ФИО'),
                                                leadStatus : translate('Этап воронки'),
                                                refusal    : translate('Причина отказа'),
                                                gender     : translate('Пол'),
                                                ageMin     : translate('Возраст от'),
                                                ageMax     : translate('Возраст до'),
                                                contact    : translate('Контакт'),
                                                manager    : translate('Ответственный'),
                                                employee   : translate('Педагог'),
                                                mainTags   : translate('Теги'),
                                                branch     : 'Бот за замовчуванням',
                                                f_removed  : translate('Архив'),
                                                withChat   : 'Заявки',
                                                noTask     : 'Заявки',
                                                noComment  : 'Заявки',
                                                minCdate   : 'Дата від',
                                                maxCdate   : 'Дата до',
                                                ...customSetting.customField.reduce((acc, item) => {
                                                    acc[item.systemName] = item.name;
                                                    return acc;
                                                }, {})
                                            }}
                                            meansData={{
                                                source : {
                                                    arr : salesSetting.leadSource,
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                leadStatus : {
                                                    arr : [ { id: 0, name : translate('Не разобрано') } , ...salesSetting.leadStatus],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                refusal : {
                                                    arr : salesSetting.leadReject,
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                branch : {
                                                    arr: integration.telegram,
                                                    type_id : 'id',
                                                    topic: 'username'
                                                },
                                                gender : {
                                                    arr : [ { id : 1, name : translate('Мужчина') }, { id : 2, name : translate('Женщина') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                manager : {
                                                    arr : [ { user_id : -1, name : translate('Без ответственного') }, ...managerData ],
                                                    type_id : 'user_id',
                                                    topic : 'name'
                                                },
                                                employee : {
                                                    arr : employeeData,
                                                    type_id : 'employee_id',
                                                    topic : 'name'
                                                },
                                                f_removed : {
                                                    arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                withChat : {
                                                    arr : [ { id : 1, name : 'Заявки з підключеним чатом' } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                noTask : {
                                                    arr : [ { id : 1, name : 'Заявки без задач' } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                noComment : {
                                                    arr : [ { id : 1, name : 'Заявки без коментарів' } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                }
                                            }}
                                            page='lead'
                                            viewMode={viewMode}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* BODY */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to={`/company/1/lead/index/${viewMode ? `?view_mode=${viewMode}` : ''}`}>
                                            { translate('Текущие') }
                                        </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).manager === `${auth?.userId}` ? 'active' : '' : ''}>
                                        <Link to={`/company/1/lead/index/${encodeURI(JSON.stringify({ manager : `${auth?.userId}` }))}${viewMode ? `?view_mode=${viewMode}` : ''}`}>
                                            { translate('Я — ответственный') }
                                        </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).f_removed === '1' ? 'active' : '' : ''}>
                                        <Link to={`/company/1/lead/index/${encodeURI(JSON.stringify({ f_removed : '1' }))}${viewMode ? `?view_mode=${viewMode}` : ''}`}>
                                            { translate('Архив (отказы)') }
                                        </Link>
                                    </li>
                                </ul>
                                {
                                    (!viewMode || viewMode === 'board') ?
                                        <LeadBoard
                                            sql={sql}
                                            updateSql={setSql}
                                            managerData={managerData}
                                            setSelectedLead={setSelectedLead}
                                            setArchiveCustomer={setArchiveCustomer}
                                        /> :
                                        <LeadTable
                                            sql={sql}
                                            setSql={setSql}
                                            managerData={managerData}
                                            pageSize={pageSize}
                                            page={page}
                                            counts={counts}
                                            viewMode={viewMode}
                                            setSelectedLead={setSelectedLead}
                                            setArchiveCustomer={setArchiveCustomer}
                                            loading={loading}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <AddLead
                dataEmployee={employeeData}
            />

            <EditLead
                dataEmployee={employeeData}
                editCustomer={editCustomer}
            />

            <FilterLead
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                managerData={managerData}
                employeeData={employeeData}
                viewMode={viewMode}
            />

            <ArchiveModal
                customer_id={archiveCustomer.customer_id}
                name={archiveCustomer.name}
                sql={sql}
                updateSql={setSql}
                lead={true}
            />

            <ImportModal
                entity="lead"
            />

            <AcceptModal />
        </div>
    )
}