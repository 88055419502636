// CONTEXT
import {SettingContext} from "../../Context/SettingContext";

// HOOK
import {useContext, useEffect, useRef, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";

// JSON
import URL from '../../Api/URL.json';

// FUNCTION
import { openModal, pagination } from "../../Function/common";

// COMPONENT
import SideMenu from "../../Components/SideMenu/SideMenu";
import EmployeeTable from "./Table/EmployeeTable";
import AddEmployee from "./Modal/AddEmployee";
import AcceptModal from "../../Components/Modal/AcceptModal";
import EditEmployee from "./Modal/EditEmployee";
import FilterEmployee from "./Modal/FilterEmployee";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import { ToastContainer } from 'react-toastify';
import TabHeader from "../../Components/TabHeader";
import TabAction from "../../Components/TabAction";
import PayrollStatement from "../Card/Employee/Report/Modal/PayrollStatement";
import DeleteEntityModal from '../../Components/Modal/DeleteEntityModal';

export default function Employee () {

    // CONTEXT START
    const { employeeSetting, lessonSetting, pageSize } = useContext(SettingContext);
    // CONTEXT END

    // REF START
    const btn_filter_setting = useRef();
    // REF END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // HOOK START
    const { error, request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [sql, setSql] = useState([]);
    const [counts, setCounts] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [employeeEdit, setEmployeeEdit] = useState({});

        // DATA
    const [groupsData, setGroupsData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [loading, setLoading] = useState(false);
    // STATE END

    // INIT SQL START
    useEffect(() => {

        if (!pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            let data = await request(`${URL.back_end}/employee_filter`, 'POST', { page, limit : pageSize.employee, data : { f_removed : 0, ...params } });

            if (error) throw error;

            data.employees = data.employees.sort((a, b) => a.name.replace(/і/g, 'и').localeCompare(b.name.replace(/і/g, 'и')));

            setSql(data.employees);
            setCounts(data.counts);
            setLoading(false);
        }

        fetchData().catch(e => console.log(e))

    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {

            const groups = await request(`${URL.back_end}/group`, 'GET');
            const customers = await request(`${URL.back_end}/customer`, 'GET');

            if (error) throw error

            setGroupsData(groups);
            setCustomerData(customers);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT SQL END

    // BUTTON FUNC START
    function buttonEdit () {
        if (selectedRows.length === 1) {
            openModal('edit-employee');
            setEmployeeEdit(sql.filter(item => item.employee_id === +selectedRows[0])[0])
        }
    }

    const buttonFilterSetting = () => btn_filter_setting.current.classList.toggle("open");
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="employee" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: "624px" }}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    title="Добавить педагога"
                                    handler={() => openModal('add-employee')}
                                    icon="ion-plus"
                                    text="Добавить"
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    disable={selectedRows.length <= 0}
                                    title="Изменить педагога"
                                    handler={() => buttonEdit()}
                                    icon="ion-edit"
                                    text="Править"
                                />
                                {/*<TabAction*/}
                                {/*    access={true}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-printer"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-info btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'Выписка по зарплате', icon : 'fa fa-file-text-o', handler : () => openModal('payroll-statement') },*/}
                                {/*            // { link : '#', text : 'Рейтинг педагогов', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Журнал посещаемости', icon : 'fa fa-file-text-o' },*/}
                                {/*            // { link : '#', text : 'Конверсия пробных уроков', icon : 'fa fa-file-text-o' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*<TabAction*/}
                                {/*    access={true}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="fa fa-ellipsis-h"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' },*/}
                                {/*            { divider : true },*/}
                                {/*            { link : '#', text : 'Импорт из файла...', icon : 'fa fa-upload' },*/}
                                {/*            { link : '#', text : 'Экспорт в файл...', icon : 'fa fa-download' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*BUTTON FILTER*/}
                                <div className="btn-group btn-group-sm dropdown pull-right" ref={btn_filter_setting}>
                                    <TabAction
                                        access={true}
                                        className="btn btn-primary"
                                        style={{ width : '96px' }}
                                        icon="fa fa-filter"
                                        handler={() => openModal('filter-employee')}
                                        text="Фильтр"
                                    />
                                    <a className="btn btn-primary dropdown-toggle" style={{paddingRight: "7px", paddingLeft: "7px", width: "24px"}} onClick={buttonFilterSetting}>
                                        <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/company/1/employee/index">
                                                { translate('Сбросить фильтр') }
                                            </Link>
                                        </li>
                                        <li className="divider" />
                                        <li>
                                            <a>
                                                { translate('Управление фильтрами') }
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*FILTER OPTIONS*/}
                                {
                                    ( filterParams && employeeSetting ) &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    name: translate('ФИО'),
                                                    contact: translate('Контакт'),
                                                    age: translate('Дата рождения'),
                                                    gender: translate('Пол'),
                                                    subjects: translate('Предмет'),
                                                    qualification: translate('Квалификация'),
                                                    group: translate('Группа'),
                                                    customer : translate('Клиенты'),
                                                    f_removed: translate('Архив')
                                                }}
                                                meansData={{
                                                    qualification : {
                                                        arr : employeeSetting.qualification,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    subjects : {
                                                        arr : lessonSetting.subject,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    group : {
                                                        arr : groupsData,
                                                        type_id : 'group_id',
                                                        topic : 'title'
                                                    },
                                                    customer : {
                                                        arr : customerData,
                                                        type_id : 'customer_id',
                                                        topic : 'name'
                                                    },
                                                    f_removed : {
                                                        arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    }
                                                }}
                                                page="employee"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            {/*TABS CONTAINER*/}
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to={'/company/1/employee/index'}> { translate('Текущие') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).f_removed === 1 ? 'active' : '' : null}>
                                        <Link to={`/company/1/employee/index/${encodeURI(JSON.stringify({ f_removed : 1 }))}`}>
                                            { translate('Архивные') }
                                        </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body">
                                            <div className="grid-view is-bs3 hide-resize">
                                                {/*TAB HEADER*/}
                                                <TabHeader
                                                    counts={counts}
                                                    entity="employee"
                                                />
                                                {/*TAB BODY*/}
                                                <div className="border-bottom" />
                                                <EmployeeTable
                                                    sql={sql}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                    loading={loading}
                                                />
                                                <div className="text-center" translate="no">
                                                    { pageSize && pagination(counts, pageSize, 'employee', page) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <AddEmployee
                groupsData={groupsData}
            />

            <EditEmployee
                access={true}
                groupsData={groupsData}
                employeeEdit={employeeEdit}
            />

            <FilterEmployee
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                groupsData={groupsData}
                customerData={customerData}
            />

            {/*ACCEPT MODAL*/}
            <AcceptModal />
            <DeleteEntityModal />
        </div>
    )
}