import {useTranslate} from "../../../../Hooks/translate.hook";
import {openModal} from "../../../../Function/common";
import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {findObject} from "../../../../Function/common";
import URL from "../../../../Api/URL.json";
import {useHttp} from "../../../../Hooks/http.hook";
import {memo} from "react";
import iconCalendar from "../../../../Assets/data/JSON/iconCalendar.json";
import currency from "../../../../Assets/data/JSON/currency.json";

export const TariffRow = memo(({ data, tariffData, setEditTariff, setTariffData, archive }) => {

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { basicSetting } = useContext(SettingContext);
    const { request } = useHttp();
    // HOOK END

    // FUNCTION START
    async function editTariff () {
        if (tariffData.length > 0) {
            setEditTariff(data)
            return openModal("edit-tariff-customer")
        }

        const tariffs = await request(`${URL.back_end}/tariff`, 'GET');

        await setTariffData([...tariffs]);

        setEditTariff(data)
        openModal("edit-tariff")
    }
    // FUNCTION END

    return (
        <div className="row">
            <div className="m-b-none col-xs-12 p-xs crm-hover-block">
                <a className="crm-ajax-link crm-thin-link" title={translate('Править абонемент')} onClick={editTariff}>
                    <i className="ion-edit pull-right big" />
                    <div className={archive ? 'archive' : ''}>
                        <strong className={archive ? 'text-muted' : 'text-success'}>
                            <i className="ion-pricetag m-r-xs" /> { data.title }
                        </strong>
                    </div>
                    <div className="text-muted m-l-md">
                        {
                            lessonSetting ?
                                JSON.parse(data.lessons).map(item => findObject(lessonSetting.subject, item) ? findObject(lessonSetting.subject, item).name : '').join(", ")
                                : null
                        } —&nbsp;
                        {
                            data.type_lessons ? JSON.parse(data.type_lessons).map(item => (
                                <i className={`${iconCalendar[item]} text-muted m-r-xs`} />
                            )) : null
                        }
                        {data.date_start} – {data.date_end}&nbsp;
                        {data.note ? `(${data.note})` : null}
                    </div>
                    <div className="m-l-md font-bold">
                        { translate('Остаток по счету') } : <span className={data.balance > 0 ? 'text-navy' : 'text-danger'}> {data.balance ? data.balance : 0} { basicSetting && currency[basicSetting.baseOption.currency] }</span> ({Math.floor((data.balance ? data.balance : 0) / data.price_per_lesson)} {translate('уроков')})
                    </div>
                </a>
            </div>
        </div>
    )
})