import {useHttp} from "../../../../../Hooks/http.hook";
import {useContext, useRef, useState} from "react";
import {InputChooseMultiArray} from "../../../../../Components/InputChoose/InputChooseMultiArray";
import InputMask from "react-input-mask";
import InputChooseMultiObject from "../../../../../Components/InputChoose/InputChooseMultiObject";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {limitYearDate, filterDisable, closeModal} from "../../../../../Function/common";
import {SettingContext} from "../../../../../Context/SettingContext";
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import { validate } from "../../../../../Function/validateForm";
import moment from "moment";
import {useArchive} from "../../../../../Hooks/archive.hook";

export default function AddRegularLesson (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [dow, setDow] = useState([]);
    const [time_start, setTimeStart] = useState('');
    const [time_end, setTimeEnd] = useState('');
    const [audience, setAudience] = useState({});
    const [subject, setSubject] = useState({});
    const [employee, setEmployee] = useState([]);
    const [date_start, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [date_end, setDateEnd] = useState('');

    // HELP STATE
    const [minute, setMinute] = useState('60');
    // STATE END

    // VALID STATE START
    const [timeStartError, setTimeStartError] = useState('');
    const [timeEndError, setTimeEndError] = useState('');
    // VALID STATE END

    // MODAL FUNC START
    function switchDow (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case translate('Понедельник') : return 1;
            case translate('Вторник') : return 2;
            case translate('Среда') : return 3;
            case translate('Четверг') : return 4;
            case translate('Пятница') : return 5;
            case translate('Суббота') : return 6;
            case translate('Воскресенье') : return 0;
        }
    }
    // MODAL FUNC END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (date_end) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(date_start))).asDays();

            const newDateEnd = moment(date_end).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function dowHandler(value) {
        return value.map(item => {
            return switchDow(item);
        });
    }
    function timeStartHandler(e) {
        setTimeStart(e.target.value);
        if ((/_/g).test(e.target.value)) {
            setTimeStartError(`${translate('Необходимо заполнить')} «${translate('Начало')}».`);
        } else {
            setTimeStartError('');
        }
        durationHandler(e.target.value)
    }
    function minuteHandler(e) {
        setMinute(e.target.value);
        durationHandler(time_start);
    }
    function timeEndHandler(e) {
        setTimeEnd(e.target.value);
        if ((/_/g).test(e.target.value)) {
            setTimeEndError(`${translate('Необходимо заполнить')} «${translate('Конец')}».`);
        } else {
            setTimeEndError('');
        }
        if (!e.target.value || !time_start) return
        let [startHour, startMinute] = time_start.split(":");
        let [endHour, endMinute] = e.target.value.split(":");
        let result_minute = `${(Number(endHour)*60+Number(endMinute))-(Number(startHour)*60+Number(startMinute))}`;
        setMinute(Number(result_minute) ? result_minute : "");
    }
    function durationHandler(start) {
        let help_minute = document.getElementById('help-minute').value;
        if (!start || !help_minute) return
        let [startHour, startMinute] = start.split(":");
        let date = new Date();
        date.setHours(+startHour);
        date.setMinutes(+startMinute+Number(help_minute));
        setTimeEnd(`${date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()}`);
    }
    // HANDLER END

    // ADD REGULAR LESSON START
    async function addRegularLesson (e) {
        e.preventDefault();

        let validObj = {
            "День недели" : !dow.length,
            "Длительность" : !time_start.length || !time_end.length,
            "Предмет" : !Object.keys(subject).length,
            "Период" : !date_start || !date_end,
            "Педагог(и)" : !employee.length
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        const data = {
            time_start, time_end, date_start, date_end,
            dow : dowHandler(dow),
            audience : audience.id,
            subject : subject.id,
            employee : employee.map(item => item.employee_id),
            group: props.sql.group_id,
            type_lesson : 2
        }

        await request(`${URL.back_end}/regular_lesson`, 'POST', data);
        await pushArchive(1, 'regularLesson', props.sql.group_id, data, null);

        if (error) return console.log(error)

        window.location.reload();
    }
    // ADD REGULAR LESSON END

    return (
        <div id="add-regular-lesson" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addRegularLesson(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title">{translate('Регулярный урок')} ({props.sql.title})</h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*WARNING*/}
                            <p className="p-xs bg-warning m-b-md text-center">
                                {translate('Сохраняется для группы')} <strong>{props.sql.title}</strong>
                            </p>
                            {/*DAY OF WEEK*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('День недели')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiArray
                                        data={[`${translate('Понедельник')}`, `${translate('Вторник')}`, `${translate('Среда')}`, `${translate('Четверг')}`, `${translate('Пятница')}`, `${translate('Суббота')}`, `${translate('Воскресенье')}`]}
                                        setProps={setDow}
                                        getProps={dow}
                                    />
                                </div>
                            </div>
                            {/*DURATION*/}
                            <div className={`form-group required ${timeStartError || timeEndError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Длительность')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        {/*TIME START*/}
                                        <div className="col-sm-4">
                                            <input
                                                type="time"
                                                className="form-control time-input time-to text-center"
                                                value={time_start}
                                                onChange={(event) => timeStartHandler(event)}
                                            />
                                            <div className="help-block">{timeStartError ? timeStartError : null}</div>
                                        </div>
                                        {/*MINUTE*/}
                                        <div className="col-sm-4">
                                            <span className="text-muted" style={{position: "absolute", margin: "8px 0 0 10px"}}>{translate('мин')}</span>
                                            <input
                                                type="text"
                                                className="form-control duration-input text-center"
                                                id="help-minute"
                                                maxLength="4"
                                                value={minute}
                                                onChange={e => minuteHandler(e)}
                                            />
                                        </div>
                                        {/*TIME END*/}
                                        <div className="col-sm-4">
                                            <input
                                                type="time"
                                                className="form-control time-input time-to text-center"
                                                value={time_end}
                                                onChange={(event) => timeEndHandler(event)}
                                            />
                                            <div className="help-block">{timeEndError ? timeEndError : null}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*AUDIENCE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Аудитория')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.room) : []}
                                        setProps={setAudience}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предмет')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.subject) : []}
                                        setProps={setSubject}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Педагог(и)')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={props.employeeData.filter(item => item.f_removed === 0)}
                                        setProps={setEmployee}
                                        topicSearch="name"
                                        type_id="employee_id"
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Период')}</label>
                                </div>
                                {/*DATE START*/}
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <span className="input-group-addon">↦</span>
                                        <input
                                            type="date"
                                            className="form-control text-center"
                                            value={date_start}
                                            onChange={(event) => dateStartHandler(event)}
                                        />
                                    </div>
                                </div>
                                {/*DATE END*/}
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input
                                            type="date"
                                            className="form-control"
                                            min={moment(date_start).add(1, 'day').format('YYYY-MM-DD')}
                                            max={moment(date_start).add(1, 'year').format('YYYY-MM-DD')}
                                            value={ date_end }
                                            onChange={(event) => setDateEnd(event.target.value) }
                                        />
                                        <span className="input-group-addon">↤</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}