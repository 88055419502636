export default function Footer () {
    return (
        <footer className="footer no-padding" id="main-footer">
            <div className="container m-t-sm text-center">
                <p className="text-muted no-padding m-b-sm">
                    ©&nbsp;
                    <a href="#" target="_blank" className="text-success crm-thin-link">
                        RISTCRM
                    </a>
                    &nbsp;&nbsp;
                    <strong>beta 1.0</strong>
                    &nbsp;|&nbsp;
                    <strong>{new Date().toLocaleDateString()}</strong>
                </p>
            </div>
        </footer>
    );
}