import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {openModal} from "../../../../Function/common";
import EditGroupStatus from "./EditModal/EditGroupStatus";
import $ from 'jquery';
import 'jquery-ui-bundle';
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('#studystatussearch-f_name').focus();

    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/lessonSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'groupStatus'}),
                success: function(data){
                    setting.lessonSetting = data;
                }
            });
        }
    });
}

export default function StatusGroup () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const {lessonSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.lessonSetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // STATE START
    const [editGroupStatus, setEditGroupStatus] = useState({});
    const [deleteGroup, setDeleteGroup] = useState({});
    // STATE END

    // COMPONENT FUNCTION START
    async function disabledStatus (data) {
       lessonSetting.groupStatus = lessonSetting.groupStatus.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return console.log(error)
    }

    function deleteHandler (item) {
        setDeleteGroup(item);
        openModal('delete-status-group-overlay');
        openModal('delete-status-group-modal');
    }

    async function deleteStatusHandler () {
        lessonSetting.groupStatus = lessonSetting.groupStatus.filter(item => {
            if (item.id !== deleteGroup.id) {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})

        window.location.reload();
    }
    // COMPONENT FUNCTION END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Статусы групп')} <span className="text-muted">({lessonSetting ? lessonSetting.groupStatus.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success crm-ajax-link text-lowercase" onClick={() => openModal('add-group-status')}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                        {
                            lessonSetting ?
                                lessonSetting.groupStatus.map(item => (
                                    <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {opacity: 0.5} : {}} data-id={`${item.id}`}>
                                        <td className="col-sm-10">
                                            <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                            <small className="text-muted"> [ID {item.id}] </small>
                                        </td>
                                        <td className="text-right col-sm-2">
                                            <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledStatus(item)}>
                                                <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                            </a>
                                            <a className="btn btn-xs btn-white m-r-xxs" onClick={() => {openModal("edit-group-status"); setEditGroupStatus(item)}}>
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item)}>
                                                <i className="fa fa-trash-o text-danger fa-fw" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
            <EditGroupStatus
                editGroupStatus={editGroupStatus}
            />
            <DeleteModal
                id="delete-status-group"
                handler={deleteStatusHandler}
            />
        </>
    )
}