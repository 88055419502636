import Picker from "emoji-picker-react";
import React, {memo, useContext, useRef} from "react";
import {ChatMessage} from "./ChatMessage";
import URL from '../../../../Api/URL.json';
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useHttp} from "../../../../Hooks/http.hook";
import {useEffect, useState} from "react";
import {errorNotify, success} from "../../../../Function/Notify";
import ws from "../../../../Api/ws.json";
import '../../../../Assets/css/Chat.css';
import {AuthContext} from "../../../../Context/AuthContext";
import MessageMenu from "../../../../Components/Popover/MessageMenu";
import ModalPhoto from "./ModalPhoto";
import PinBlock from "./PinBlock";
import {getCaretCharOffset, openMenu, setCaretCharOffset} from "../../../../Function/common";
import arrow from "../../../../Assets/image/arrow_down.svg";
import {SettingContext} from "../../../../Context/SettingContext";

// SOCKET
let socket = new WebSocket(`${ws.url}/message?${(window.location.hostname).split('.')[0]}`);

// HEARTBEAT
const HEARTBEAT_INTERVAL = 10000; // интервал отправки "heartbeat" сообщений
const HEARTBEAT_TIMEOUT = 2000; // таймаут ожидания ответа на "heartbeat" сообщение

export const Chat = memo(function MemoChat (props) {

    // CONTEXT START
    const { integration } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // REF START
    const chatRef = useRef(null);
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ messages, setMessages ] = useState([]);
    const [ text, setText ] = useState('');
    const [ file, setFile ] = useState({});
    const [ editMessage, setEditMessage ] = useState({});
    const [ numShow, setNumShow ] = useState(10);
    const [ chooseChat, setChooseChat ] = useState(1);

        // MESSAGE
    const [ menuStyle, setMenuStyle ] = useState({});
    const [ menuData, setMenuData ] = useState({});
    const [ fullPhoto, setFullPhoto ] = useState('');

        // SOCKET
    const [ heartbeatTimer, setHeartbeatTimer ] = useState(null);
    const [ checkHeartbeatInterval, setCheckHeartbeatInterval ] = useState(null);
    const [ wss, setWss ] = useState(null);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.sqlCard.chat_id || !props.access) return

        document.getElementById('leadhistory').oncontextmenu = () => false;

        async function fetchData () {
            const message = await request(`${URL.back_end}/message/${props.sqlCard.chat_id}`, 'GET');

            setMessages(message);
        }

        fetchData().catch(e => console.log(e))

        setChooseChat(props.sqlCard.branch || 1);

        const getText = localStorage.getItem(props.sqlCard.chat_id);

        if (getText) {
            setText(getText);
            document.getElementById('text-message').textContent = getText;
        } else {
            setText('');
            document.getElementById('text-message').textContent = '';
        }

    }, [props.sqlCard])

    useEffect(() => {
        if (!props.sqlCard.chat_id) return;
        return localStorage.setItem(props.sqlCard.chat_id, text);
    }, [props.sqlCard, text])

    useEffect(() => {

        if (chatRef.current) chatRef.current.scrollTop = chatRef.current.scrollHeight;

    }, [messages, chatRef])
    // INIT DATA END

    if (!props.access) return

    // SOCKET EVENT START
    socket.onopen = () => {
        if (auth.role.interface !== 1) return

        setWss(socket);
        clearTimeout(heartbeatTimer);
        startHeartbeat(socket);
    }

    socket.onmessage = (event) => {
        if (auth.role.interface !== 1) return;

        if (event.data !== 'heartbeat') {
            const data = JSON.parse(event.data);

            if (data[0].chat_id !== props.sqlCard.chat_id) return

            setMessages([...messages, ...data]);
        }

        clearTimeout(heartbeatTimer);
        clearInterval(checkHeartbeatInterval);
        setHeartbeatTimer(null);
    }

    function startHeartbeat () {
        setCheckHeartbeatInterval(setInterval(() => {
            if (!wss) return;

            if (wss.readyState !== WebSocket.OPEN) {
                clearInterval(checkHeartbeatInterval);
                return;
            }

            if (!heartbeatTimer) {
                sendHeartbeat();
            }
        }, HEARTBEAT_INTERVAL))

        function sendHeartbeat() {
            if (wss.readyState === WebSocket.OPEN) {
                wss.send('heartbeat');

                setHeartbeatTimer(setTimeout(() => {
                    console.warn('Сервер не ответил на "heartbeat" сообщение');
                    wss.close();
                }, HEARTBEAT_TIMEOUT));
            }
        }

        sendHeartbeat();
    }

    socket.onclose = () => {
        clearTimeout(heartbeatTimer);
        clearInterval(heartbeatTimer);

        setTimeout(() => {
            socket = new WebSocket(`${ws.url}/message?${(window.location.hostname).split('.')[0]}`);
            setWss(socket);
        }, 5000)
    }
    // SOCKET EVENT END

    // SCROLL EVENT START
    function scrollChat (e) {
        if (e.target.scrollTop === 0) {
            setNumShow(numShow + 2);
            e.target.scrollTop = 2;
        }
    }

    // const blockChat = document.getElementById('scroll-chat');
    // if (blockChat && numShow === 10) blockChat.scrollTop = blockChat.scrollHeight;
    // SCROLL EVENT END

    // FUNC START
    function updateChatsBlock (message_id) {
        let new_arr = props.chatsSql.map(item => {
            if (item.chat_id === props.sqlCard.chat_id) {
                item.text = text;
                item.date = Math.ceil(new Date().getTime() / 1000);
                item.message_id = message_id;
                item.checked = true;
                return item;
            } else {
                return item;
            }
        })

        props.setChatsSql(new_arr);
    }

    async function sendMessage () {
        const token = props.integration.telegram.find((item) => Number(item.id) === Number(chooseChat))?.token;

        if (!props.sqlCard.chat_id) return errorNotify(`Необходимо подключить клиента по ссылке`);

        if (!(text.trim()).length && !file.name) return;

        if (file.name) {

            const formData = new FormData();
            formData.append('document', file);
            formData.append('chat_id', props.sqlCard.chat_id);

            const response = await request(`https://api.telegram.org/bot${token}/sendDocument`, 'POST', formData, {}, true);

            if (file.type === 'video/mp4') {
                const uniq_file_id = response.result.video.file_unique_id + response.result.date;
                const file_name = uniq_file_id + '.' + file.name.split(/\.([^.]+)$/)[1];

                const serverData = new FormData();
                serverData.append('file', file, file_name);

                await request(`${URL.back_end}/save-file/customer/${props.sqlCard.chat_id}`, 'POST', serverData, {}, true);

                let messageData = { name : 'admin', username : 'admin', document : JSON.stringify({ file_name, link : `https://${(window.location.hostname).split('.')[0]}.rist.pro${URL.back_end}/download/customer/${props.sqlCard.chat_id}/${file_name}/1`, originalName : file.name }) , chat_id: props.sqlCard.chat_id, date : response.result.date, social : 'telegram', message_id: response.result.message_id, checked : true };

                const { insertId } = await request(`${URL.back_end}/message`, 'POST', messageData);

                if (error) return console.log(error);

                messageData.id = insertId;

                setFile({});

                setMessages([...messages, messageData]);
            } else {
                const uniq_file_id = response.result.document.file_unique_id + response.result.date;
                const file_name = uniq_file_id + '.' + file.name.split(/\.([^.]+)$/)[1];

                const serverData = new FormData();
                serverData.append('file', file, file_name);

                await request(`${URL.back_end}/save-file/customer/${props.sqlCard.chat_id}`, 'POST', serverData, {}, true);

                let messageData = { name : 'admin', username : 'admin', document : JSON.stringify({ file_name, link : `https://${(window.location.hostname).split('.')[0]}.rist.pro${URL.back_end}/download/customer/${props.sqlCard.chat_id}/${file_name}/1`, originalName : file.name }) , chat_id: props.sqlCard.chat_id, date : response.result.date, social : 'telegram', message_id: response.result.message_id, checked : true };

                const { insertId } = await request(`${URL.back_end}/message`, 'POST', messageData);

                if (error) return console.log(error);

                messageData.id = insertId;

                setFile({});

                setMessages([...messages, messageData]);
            }

            if (props.chatsSql) return updateChatsBlock(response.result.message_id);

        } else if (text.length && Object.keys(editMessage).length === 0) {

            let response;

            try {
                response = await request(`https://api.telegram.org/bot${token}/sendMessage`, 'POST',{ chat_id: props.sqlCard.chat_id, text });
            } catch (e) {
                errorNotify( translate('Клиент остановил бота') )
            }

            let obj_message = { name: 'admin', username: 'admin', text, chat_id: props.sqlCard.chat_id, date: Math.ceil(new Date().getTime()/1000), social: 'telegram', message_id: response.result.message_id, checked : true }

            const responseSql = await request(`${URL.back_end}/message`, 'POST', obj_message);
            await request(`${URL.back_end}/message_checked/${props.sqlCard.chat_id}`, 'PUT', { checked : true });

            obj_message.id = responseSql.insertId;

            setText('');
            document.getElementById('text-message').textContent = null;

            setMessages([...messages, ...[obj_message]]);

            if (props.chatsSql) return updateChatsBlock(response.result.message_id);

        } else if (Object.keys(editMessage).length > 0) {

            const { id, chat_id, message_id } = editMessage;

            await request(`https://api.telegram.org/bot${token}/editMessageText`, 'POST', { chat_id, message_id, text })

            await request(`${URL.back_end}/message/${id}`, 'PUT', {text});

            setText('');
            document.getElementById('text-message').textContent = null;

            setEditMessage({});

            setMessages(
                messages.map(item => {
                    if (item.id === id) {
                        item.text = text;
                        return item;
                    } else {
                        return item;
                    }
                })
            )

            if (props.chatsSql) return updateChatsBlock(message_id);

        }
    }

    function showEmoji () {
        const emoji = document.getElementsByClassName("emoji-picker-react")[0];

        if (emoji.classList.contains('display-flex')) emoji.classList.remove('display-flex');
        else emoji.classList.add('display-flex');

        const scroll_chat = document.getElementById('wrapper');

        function closeHandler (event) {
            event.preventDefault();

            if (!event.target.closest('.emoji-picker-react')) {
                emoji.classList.remove('display-flex');

                scroll_chat.removeEventListener('click', closeHandler, false);
            }
        }

        scroll_chat.addEventListener('click', closeHandler, false);
    }

    async function setChecked () {
        await request(`${URL.back_end}/message_checked/${props.sqlCard.chat_id}`, 'PUT', { checked: true })

        if (error) return console.log(error)

        success(`Сообщения отмечены как прочитаные`)
    }

    function handlerKeyDown (event) {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (isMobile) return;

        if (event.keyCode === 13) {
            if (event.shiftKey) {
                return;
            } else {
                return sendMessage();
            }
        }
    }

    // function handlerText (e) {
    //     const target = e.target;
    //     const calculateHeight = (((target.value).split('\n').length - 1) * 21);
    //     console.log(calculateHeight);
    //     const height = calculateHeight > 197 ? 197 : calculateHeight + 35;
    //
    //     if (calculateHeight > 197) {
    //         target.style.overflowY = 'auto';
    //     } else {
    //         target.style.overflowY = 'hidden';
    //     }
    //
    //     target.style.height = `${ height }px`;
    //
    //     setText(e.target.value);
    // }

    // function handlerEmoji (event, object) {
    //     event.preventDefault();
    //
    //     const inputText = document.getElementById('text-message');
    //     const cursorPosition = inputText.selectionStart;
    //     const emoji = object.emoji;
    //
    //     const newValue = text.slice(0, cursorPosition) + emoji + text.slice(cursorPosition);
    //     setText(newValue);
    //
    //     inputText.focus();
    // }

    function handlerText (event) {
        event.preventDefault();

        const textValue = event.target.innerText;

        if (!(textValue.trim()).length) {
            event.target.innerText = null;
            setText('');
            return;
        }

        setText(textValue);
    }

    function handlerTextPaste (event) {
        event.preventDefault();

        let paste = (event.clipboardData || window.clipboardData).getData("text");
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();

        setText(document.getElementById('text-message').innerText);
    }

    function handlerEmoji (event, { emoji }) {
        event.preventDefault();

        const textMessage = document.getElementById('text-message');
        const innerTextMessage = textMessage.innerText;
        const cursorPosition = getCaretCharOffset(textMessage);

        const newValue = innerTextMessage.slice(0, cursorPosition) + emoji + innerTextMessage.slice(cursorPosition);

        textMessage.textContent = newValue;

        setCaretCharOffset(textMessage, cursorPosition + emoji.length);

        setText(newValue);
    }

    function resetEditText () {
        setEditMessage({});
        setText('');
        document.getElementById('text-message').textContent = '';
    }
    // FUNC END

    return (
        <div id="leadhistory" className="tab-pane active" onKeyDown={e => handlerKeyDown(e)}>
            <div className="panel-body chat-bg-wrapper">

                <div className="chat-header">
                   <span className="btn-group btn-group-xs" onClick={e => openMenu(e)}>
                       <span className="caption"> { integration && integration.telegram.find(item => Number(item.id) === Number(chooseChat))?.username } </span>
                       <span className="arrow_down btn dropdown-toggle" style={{ float : 'right' }}>
                           <img
                               src={arrow}
                               style={{ verticalAlign : 'text-bottom', margin : 0 }}
                               alt='arrow'
                           />
                       </span>
                       <ul className="dropdown-menu">
                           {
                               integration && integration.telegram.map(item => (
                                   <li onClick={() => setChooseChat(item.id)}>
                                       <a> { item.username } <span className="text-muted small">({ item.name })</span> </a>
                                   </li>
                               ))
                           }
                       </ul>
                   </span>
                </div>

                {
                    messages.filter(item => item.pin).length ? (
                        <PinBlock
                            message={messages.filter(item => item.pin)}
                        />
                    ) : null
                }

                <div id="scroll-chat" className="feed-note-wrapper scroll-chat" style={{ overflowY: 'auto', height: '550px' }} onScroll={e => scrollChat(e)} ref={chatRef}>
                    <div className="feed-note-fixer">

                        <ChatMessage
                            message={messages}
                            numShow={numShow}
                            setMenuStyle={setMenuStyle}
                            setMenuData={setMenuData}
                            setFullPhoto={setFullPhoto}
                            sqlCard={props.sqlCard}
                            chooseChat={chooseChat}
                        />

                        <MessageMenu
                            menuStyle={menuStyle}
                            menuData={menuData}
                            token={props.integration?.telegram.filter(item => item.id === chooseChat)[0]?.token}
                            message={messages}
                            setMessage={setMessages}
                            setText={setText}
                            setEditMessage={setEditMessage}
                        />

                        <ModalPhoto
                            photo={fullPhoto}
                        />
                    </div>
                </div>
                {/*EDIT MESSAGE DISPLAY*/}
                {
                    Object.keys(editMessage).length !== 0 ?
                        (
                            <div className="edit-message-wrapper">
                                <div className="edit-message-text-wrapper">
                                    <div className="edit-message-text">
                                        <p dir="auto"> { editMessage.text } </p>
                                        <div className="message-title" dir="auto"> { translate('Править сообщение') } </div>
                                    </div>
                                </div>
                                <button className="edit-message-close" type="button" onClick={resetEditText}>×</button>
                            </div>
                        )
                        : null
                }
                {/*INPUT*/}
                <div className="message-input-wrapper">
                    <Picker
                        onEmojiClick={(event, object) => handlerEmoji(event, object)}
                        disableSearchBar={true}
                        lazyLoad={true}
                        lazyLoadEmojis={true}
                    />
                    <button type="button" className="message-emoji" onClick={showEmoji}>
                        <i className="ion-happy-outline" style={{ fontSize: '19px' }} />
                    </button>
                    <div className="message-input-text">
                        {
                            file.name ? (
                                <span className="form-control text-muted" style={{ outline: 0, border: 0, backgroundColor: 'inherit' }}>
                                    { file.name } <i className="ion-android-arrow-up" style={{ fontSize: '15px' }} />
                                </span>
                            ) : (
                                <div className="input-scroller">
                                    <div className="input-scroller-content">
                                        <div
                                            className={`form-control${text.length ? ' touched' : ''}`}
                                            id="text-message"
                                            contentEditable="true"
                                            dir="auto"
                                            role="textbox"
                                            aria-label="Message"
                                            onInput={(event) => handlerText(event)}
                                            onPaste={(event) => handlerTextPaste(event)}
                                        />
                                        <span className="placeholder-text"> { translate('Сообщение') } </span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/*<div className="message-input-text">*/}
                    {/*    {*/}
                    {/*        file.name ?*/}
                    {/*            (*/}
                    {/*                <span className="form-control text-muted" style={{ outline: 0, border: 0, backgroundColor: 'inherit' }}>*/}
                    {/*                    { file.name } <i className="ion-android-arrow-up" style={{fontSize: "15px"}} />*/}
                    {/*                </span>*/}
                    {/*            ) : (*/}
                    {/*                // <textarea*/}
                    {/*                //     className="form-control"*/}
                    {/*                //     id="text-message"*/}
                    {/*                //     autoComplete="off"*/}
                    {/*                //     autoCorrect="off"*/}
                    {/*                //     placeholder={`${translate('Введите сообщение')}...`}*/}
                    {/*                //     value={text}*/}
                    {/*                //     onChange={e => handlerText(e)}*/}
                    {/*                // />*/}
                    {/*            )*/}
                    {/*    }*/}
                    {/*</div>*/}

                    <div className="chatMenu" onClick={setChecked}>
                        <span className="chat-menu-button">
                            <i className="ion-checkmark" style={{ fontSize: '19px' }} />
                        </span>
                    </div>
                    <div className="chatMenu">
                        <label htmlFor="upload-file" style={{ display : 'initial' }}>
                            <span className="chat-menu-button">
                                <i className="ion-paperclip" style={{ fontSize : '23px', transform : 'rotate(30deg)' }} />
                            </span>
                        </label>
                        <input
                            type="file"
                            id="upload-file"
                            multiple={true}
                            style={{ display : 'none' }}
                            value={Object.keys(file).length ? file : ''}
                            onChange={e => setFile(e.target.files[0])}
                        />
                    </div>
                    <div className="send-message" onClick={sendMessage}>
                        <i className="fa fa-send" />
                    </div>
                </div>
            </div>
        </div>
    )
})