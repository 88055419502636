import React, {memo, useContext} from "react";
import {getTariffInfo} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {SettingContext} from "../../../../Context/SettingContext";
import currency from "../../../../Assets/data/JSON/currency.json";

export const TotalBalanceBlock = memo(({ sqlCard, customerTariff, access }) => {

    const { translate } = useTranslate();
    const { basicSetting } = useContext(SettingContext);
    const tariffInfo = getTariffInfo(customerTariff, sqlCard.balance);

    if (!access) return

    return (
        <div className="row">
            <div className="col-xs-5 m-b-sm font-bold">
                <h4 className="text-muted no-margins">{ translate('Общий остаток') }</h4>
            </div>
            <div className="col-xs-7 m-b-sm text-right font-bold">
                <span className={`m-r-xs ${tariffInfo.lesson > 0 ? 'text-navy' : 'text-danger'}`}> { tariffInfo.lesson } {translate('уроков')}</span>
                <span className={tariffInfo.balance > 0 ? 'text-navy' : 'text-danger'}> { tariffInfo.balance } { basicSetting && currency[basicSetting.baseOption.currency] } </span>
                {/*<div className="text-navy m-t-xs">~10.06.2022</div>*/}
            </div>
        </div>
    )
})