// HOOK
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useTranslate} from "../../Hooks/translate.hook";
import {AuthContext} from "../../Context/AuthContext";

// JSON
import Menu from '../../Assets/data/JSON/menu.json';
import URL from "../../Api/URL.json";
import ws from '../../Api/ws.json';

// SHORTID
import shortid from 'shortid';

// TOAST
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {info} from "../../Function/Notify";

// SOUND
import { incomeMessageSound } from '../../Function/sound';
import {useHttp} from "../../Hooks/http.hook";
import {SettingContext} from "../../Context/SettingContext";

// SOCKET
let socket = new WebSocket(`${ws.url}/message?${(window.location.hostname).split('.')[0]}`);

// HEARTBEAT
const HEARTBEAT_INTERVAL = 10000; // интервал отправки "heartbeat" сообщений
const HEARTBEAT_TIMEOUT = 2000; // таймаут ожидания ответа на "heartbeat" сообщение

export default function SideMenu (props) {

    // CONTEXT START
    const auth = useContext(AuthContext);
    const { currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ size, setSize ] = useState(window.innerHeight);
    const [ chats, setChats ] = useState(0);
    const [ tasks, setTasks ] = useState({});

        // SOCKET
    const [ heartbeatTimer, setHeartbeatTimer ] = useState(null);
    const [ checkHeartbeatInterval, setCheckHeartbeatInterval ] = useState(null);
    const [ wss, setWss ] = useState(null);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!currentTime) return

        async function fetchData () {

            const data = await request(`${URL.back_end}/filter_task`, 'POST', { nolimit : true, data : { date_end : currentTime.split('T')[0], status : 1, f_removed : 0, user : auth.userId } });

            const overdue = data.tasks.filter(item => {
                if (item.date) {
                    const taskDate = new Date(item.date + 'T' + item.time + 'Z').getTime();

                    if (taskDate < new Date(currentTime).getTime()) {
                        return item;
                    }
                }
            }).length;

            const today = data.tasks.filter(item => {
                if (item.date) {
                    const taskDate = new Date(item.date + 'T' + item.time + 'Z').getTime();

                    if (taskDate > new Date(currentTime).getTime() && item.date === currentTime.split('T')[0]) {
                        return item;
                    }
                }
            }).length;

            setTasks({ overdue, today });
        }

        fetchData().catch(e => console.log(e))

    }, [currentTime])
    // INIT DATA END

    // SOCKET HANDLER START
    socket.onopen = () => {
        if (auth.role.interface !== 1) return

        setWss(socket);
        clearTimeout(heartbeatTimer);
        startHeartbeat(socket);
    }

    socket.onmessage = (event) => {
        if (auth.role.interface !== 1) return

        if (event.data !== 'heartbeat') {
            const message_arr = JSON.parse(event.data)[0];

            setChats(chats + 1);

            if (message_arr.name === 'admin') return

            incomeMessageSound();

            if (message_arr.text.includes('/start')) {
                info( `${message_arr.name} ${translate('подключился к CRM')}`);
            } else {
                info(`${translate('Новое сообщение от')} ${message_arr.name}`);
            }
        }

        clearTimeout(heartbeatTimer);
        clearInterval(checkHeartbeatInterval);
        setHeartbeatTimer(null);
    }

    function startHeartbeat () {
        setCheckHeartbeatInterval(setInterval(() => {
            if (!wss) return;

            if (wss?.readyState !== WebSocket.OPEN) {
                clearInterval(checkHeartbeatInterval);
                return;
            }

            if (!heartbeatTimer) {
                sendHeartbeat();
            }
        }, HEARTBEAT_INTERVAL))

        function sendHeartbeat() {
            if (wss?.readyState === WebSocket.OPEN) {
                wss.send('heartbeat');

                setHeartbeatTimer(setTimeout(() => {
                    console.warn('Сервер не ответил на "heartbeat" сообщение');
                    wss.close();
                }, HEARTBEAT_TIMEOUT));
            }
        }

        sendHeartbeat();
    }

    socket.onclose = () => {
        clearTimeout(heartbeatTimer);
        clearInterval(heartbeatTimer);

        setTimeout(() => {
            socket = new WebSocket(`${ws.url}/message?${(window.location.hostname).split('.')[0]}`);
            setWss(socket);
        }, 5000)
    }
    // SOCKET HANDLER END

    // FOOTER AUTO RESIZE START
    useEffect(() => {
        let navbarHeight = document.getElementById("nav-side-menu").clientHeight;
        let wrapperHeight = document.getElementById("page-wrapper").clientHeight;

        window.addEventListener('resize', () => {setSize(window.innerHeight)});

        if (navbarHeight > wrapperHeight) {
            document.getElementById("page-wrapper").style.minHeight = `${navbarHeight}px`;
        }

        if (navbarHeight < wrapperHeight) {
            document.getElementById("page-wrapper").style.minHeight = `${size - 97}px`
        }

    }, [size])
    // FOOTER AUTO RESIZE END

    // FUNCTION START
    function switchInterface (id) {
        // eslint-disable-next-line default-case
        switch (id) {
            case 1 : return (
                <li className={props.page === "profile" ? "active" : ""}>
                    <Link to={`/company/1/profile/index`}>
                        <i className="ion-person" /> { translate('Мой профиль' )}
                    </Link>
                </li>
            )
            case 2 : return (
                <li className={props.page === "employee" ? "active" : ""}>
                    <Link to={`/teacher/1/profile/index`}>
                        <i className="ion-person" /> { translate('Мой профиль') }
                    </Link>
                </li>
            )
            case 3 : return (
                <li className={props.page === "customer" ? "active" : ""}>
                    <Link to={`/customer/1/profile/index`}>
                        <i className="ion-person" /> { translate('Мой профиль') }
                    </Link>
                </li>
            )
        }
    }

    function getNotification (name) {
        // eslint-disable-next-line default-case
        switch (name) {
            case 'chats' : return chats ? (
                <strong className="label label-default" title={`${chats} новых сообщения`} style={{ cursor : 'pointer' }}>
                    { chats }
                </strong>
            ) : null
            case 'agile' : return (
                <>
                    {
                        tasks.today ? (
                            <strong className="label label-primary" title={`${tasks.today} задача на сегодня`} style={{ cursor : 'pointer' }}>
                                { tasks.today }
                            </strong>
                        ) : null
                    }
                    {
                        tasks.overdue ? (
                            <strong className="label label-danger" title={`${tasks.overdue} простроченых задач`} style={{ cursor : 'pointer' }}>
                                { tasks.overdue }
                            </strong>
                        ) : null
                    }
                </>
            )
        }
    }
    // FUNCTION END

    return (
        <nav className="navbar navbar-default navbar-static-side" role="navigation" id="nav-side-menu" style={{ userSelect : 'none' }}>
            <ToastContainer />
            <div className="sidebar-collapse" style={{width: "200px"}} id="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    { switchInterface(auth.role.interface) }
                    {
                        auth.role.menu !== undefined ?
                            JSON.parse(auth.role.menu).map(item => (
                                <li className={props.page === Menu[item].value ? 'active' : ''} key={shortid.generate()}>
                                    <Link to={`/company/1/${Menu[item].value}/index/`}>
                                        <i className={Menu[item].icon} /> {translate(Menu[item].name)}
                                        { getNotification(Menu[item].value) }
                                    </Link>
                                </li>
                            )) : null
                    }
                    <li>
                        <a onClick={() => auth.logout()}>
                            <i className="ion-android-exit fa" /> {translate('Выход')}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}