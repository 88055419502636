import {useContext, useEffect, useState} from "react";
import URL from "../../../../../Api/URL.json";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {findObject, filterDisable} from "../../../../../Function/common";
import iconCalendar from "../../../../../Assets/data/JSON/iconCalendar.json";
import "../../../../../Assets/css/Report.css";
import {SettingContext} from "../../../../../Context/SettingContext";

export default function TableVisitLog ({ params }) {

    // VALUE START
    const dayOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    // VALUE END

    // CONTEXT START
    const { customerSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ customer, setCustomer ] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {
            const data = await request(`${URL.back_end}/filter_customer_lesson/`, 'POST', params);
            const [cust] = await request(`${URL.back_end}/customer/${params.customer_id}`, 'GET');

            if (error) throw error

            setSql(data.sort(( a, b ) => new Date(a.date_start) - new Date(b.date_start)));
            setCustomer(cust);
        }

        fetchData().catch(e => console.log(e))

    }, [params])
    // INIT DATA END

    // RENDER FUNC START
    function renderTableBody (item) {

        let [year, month, day] = item.date_start.split('T')[0].split('-');
        let dow = new Date(item.date_start).getDay();

        function renderStatus (data) {

            let { presence, mark, status, reason } = data;

            if (status === 1 && !!presence) {
                if (mark !== null) {
                    return <td className="text-center"> { lessonSetting ? findObject(filterDisable(lessonSetting.mark), mark).ball : null } </td>;
                } else {
                    return <td className="text-center"> + </td>;
                }
            } else if (status === 1 && !!presence === false) {
                return (
                    <td className="text-center bg-warning text-danger" title={reason ? customerSetting ? findObject(filterDisable(customerSetting.reason), reason).name : null : translate('Не задано')}>
                        н <span className="attention" />
                    </td>
                )
            } else if (status === 2) {
                return <td className="text-center text-danger font-bold"> – </td>
            } else if (status === 0) {
                return <td className="text-center" style={{ color : "#ccc" }}> · </td>
            }

        }

        function renderMark (data) {

            let { presence, mark_homework, status } = data;

            if (status === 1 && !!presence) {
                if (mark_homework !== null) {
                    return <td className="text-center"> { lessonSetting ? findObject(filterDisable(lessonSetting.mark), mark_homework).ball : null } </td>;
                } else {
                    return <td />;
                }
            } else if (status === 1 && !!presence === false) {
                return <td className="text-center bg-warning text-danger" />
            } else if (status === 2) {
                return <td className="text-center text-danger font-bold"> – </td>
            } else if (status === 0) {
                return <td className="text-center" style={{ color : "#ccc" }}> · </td>
            }

        }


        return (
            <tr>
                <td className="text-center">
                    <strong className="text-lowercase">
                        { day }.{ month } <i className={`${iconCalendar[item.type_lesson]} text-muted`} /> { translate(dayOfWeek[dow]) }
                    </strong>
                </td>
                { renderStatus(item) }
                { renderMark(item) }
            </tr>
        )
    }

    function calculatePresence (arr) {
        let present = arr.filter(item => !!item.presence === true && item.status === 1).length;
        let countLesson = arr.filter(item => item.status === 1).length;
        return present > 0 ? ((present * 100) / countLesson).toFixed(2) : 0;
    }
    // RENDER FUNC END

    return (
        <div className="ibox-content m-b-sm">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h3> { translate('Журнал посещений') } / { customer.name } </h3>
                    <h4 className="font-noraml"> { translate('За период с') } { params.date_start } { translate('по') } { params.date_end } </h4>
                </div>
            </div>
            <table className="table table-condensed table-bordered" style={{ maxWidth : '400px', margin : '0 auto' }}>
                <thead>
                    <tr>
                        <th className="text-center"> { translate('Дата') } </th>
                        <th className="text-center"> { translate('Статус') } </th>
                        <th className="text-center"> { translate('Оценка за ДЗ') } </th>
                    </tr>
                </thead>
                <tbody>
                    { sql.map(item => renderTableBody(item)) }
                    <tr>
                        <td className="text-center">
                            <strong title={ translate('Процент посещаемости') }> % </strong>
                        </td>
                        <td className="text-center">
                            { calculatePresence(sql) } %
                        </td>
                        <td className="text-center"> — </td>
                    </tr>
                </tbody>
            </table>
            <div className="row">
                <div className="col-xs-12 m-b-md">
                    <blockquote>
                        <p style={{ fontSize: 'smaller' }}>
                            <strong> { translate('Итого уроков') } : </strong>
                        </p>
                        <p style={{ fontSize : 'smaller' }}>
                            { translate('Запланировано') } { sql.filter(item => item.status === 0).length } / { translate('Посещено') } { sql.filter(item => !!item.presence === true && item.status === 1).length } / { translate('Пропущено') } {  sql.filter(item => !!item.presence === false && item.status === 1).length } / { translate('Отменено') } { sql.filter(item => item.status === 2).length }
                        </p>
                    </blockquote>
                </div>
            </div>
            <div className="clearfix">
                <div className="pull-left m-r-xs">
                    <span className="p-xxs text-center legend-item" style={{ color: "#ccc" }}>·</span>  — { translate('запланировано') }
                </div>
                <div className="pull-left m-r-xs">
                    <span className="p-xxs text-center legend-item">5</span> — { translate('посетил') }
                </div>
                <div className="pull-left m-r-xs">
                    <span className="p-xxs text-center legend-item">+</span> — { translate('посетил без оценки') }
                </div>
                <div className="pull-left m-r-xs">
                    <span className="bg-warning text-danger p-xxs text-center legend-item">н</span> — { translate('пропуск') }
                </div>
                <div className="pull-left m-r-xs"><span
                    className="p-xxs text-danger text-center legend-item font-bold">–</span> — { translate('отмена') }
                </div>
            </div>
        </div>
    )
}