import SideMenu from "../../Components/SideMenu/SideMenu";
import {Link, useParams} from "react-router-dom";

// CALENDAR
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import uaLocale from "@fullcalendar/core/locales/uk";
import enLocale from "@fullcalendar/core/locales/en-gb";
import ruLocale from '@fullcalendar/core/locales/ru';
import interactionPlugin from '@fullcalendar/interaction';
// CALENDAR
import '../../Assets/css/CalendarTeacher.css';
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import URL from '../../Api/URL.json';
import {useTranslate} from "../../Hooks/translate.hook";
import {SettingContext} from "../../Context/SettingContext";
import TabAction from "../../Components/TabAction";
import {findObject, openModal} from "../../Function/common";
import iconCalendar from "../../Assets/data/JSON/iconCalendar.json";
import CalendarTeacherFilter from "./Modal/CalendarTeacherFilter";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import AddLesson from "../Calendar/Modal/AddLesson";
import CalendarPopover from "../../Components/Popover/CalendarPopover";
import {AuthContext} from "../../Context/AuthContext";
import EditLesson from "../Calendar/Modal/EditLesson";
import SpendLesson from "../Lesson/Modal/SpendLesson";

export default function CalendarTeacher () {

    // CONTEXT START
    const { basicSetting, lessonSetting, employeeSetting } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ typeLesson, setTypeLesson ] = useState('');

        // DATA
    const [ groupData, setGroupData ] = useState([]);
    const [ employeeData, setEmployeeData ] = useState([]);
    const [ customerData, setCustomerData ] = useState([]);
        // POPOVER
    const [ popoverData, setPopoverData ] = useState({});
    const [ popoverStyle, setPopoverStyle ] = useState({});
    const [ editLesson, setEditLesson ] = useState({});
    // STATE END

    // VALUE START
    const storageDate = localStorage.getItem('calendarTeacher_date') ? localStorage.getItem('calendarTeacher_date') : new Date();
    // VALUE END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // FUNC START
    function handlerTypeLesson(e, format) {
        e.preventDefault();

        setTypeLesson(format);
        openModal('add-lesson')
    }

    function switchLocale () {
        const locale = basicSetting ? basicSetting.baseOption.interfaceLanguage : "ru";
        // eslint-disable-next-line default-case
        switch (locale) {
            case 'ua' : return uaLocale;
            case 'en' : return enLocale;
            case 'ru' : return ruLocale;
        }
    }
    // FUNC END

    // INIT DATA START
    useEffect(() => {

        async function fetchData() {

            const groups = await request(`${URL.back_end}/group`, 'GET')
            const employees = await request(`${URL.back_end}/employee`, 'GET')
            const customers = await request(`${URL.back_end}/customer`, 'GET')

            setGroupData(groups);
            setEmployeeData(employees);
            setCustomerData(customers);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="calendar" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: '624px' }}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions calendar-create-buttons">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BTN GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="/company/1/calendar/index" className="btn btn-sm btn-white" title={translate('Отображение: в виде календаря')}>
                                        <i className="ion-calendar" />
                                    </Link>
                                    <Link to="/company/1/lesson/index" className="btn btn-sm btn-white" title={translate('Отображение: в виде таблицы')}>
                                        <i className="ion-navicon-round" />
                                    </Link>
                                    <Link to="/company/1/calendar-teacher/index" className="btn btn-sm btn-white active" title={translate('График работы педагогов')}>
                                        <i className="ion-university" />
                                    </Link>
                                </div>
                                <TabAction
                                    access={true}
                                    className="dropdown btn-group no-padding"
                                    icon="ion-plus"
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-success btn-outline btn-w-m dropdown-toggle',
                                        text : 'Добавить',
                                        list : [
                                            { text : 'Индивидуальный', icon : 'ion-person', handler : (e) => handlerTypeLesson(e, 1) },
                                            { text : 'Групповой', icon : 'ion-person-stalker', handler : (e) => handlerTypeLesson(e, 2) },
                                            { text : 'Пробный', icon : 'ion-asterisk', handler : (e) => handlerTypeLesson(e, 3) }
                                        ]
                                    }}
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-primary btn-outline pull-right"
                                    icon="fa fa-filter"
                                    title={translate('Фильтр')}
                                    text="Фильтр"
                                    handler={() => openModal('filter-schedule')}
                                />
                                {
                                    ( filterParams && employeeSetting ) &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    qualification : `${translate('Квалификация')} (одна з кваліфікацій)`,
                                                    qualification2 : `${translate('Квалификация')} (всі обрані кваліфікації)`,
                                                    employee      : translate('Педагог')
                                                }}
                                                meansData={{
                                                    qualification : {
                                                        arr : employeeSetting.qualification,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    qualification2 : {
                                                        arr : employeeSetting.qualification,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    employee : {
                                                        arr : employeeData,
                                                        type_id : 'employee_id',
                                                        topic : 'name'
                                                    }
                                                }}
                                                page="calendar-teacher"
                                            />
                                        )
                                }
                                {/*COLOR TEACHER*/}
                                <div className="text-center m-t-xs" id="teacher-legend" />
                            </div>
                        </div>
                    </div>
                    {/*CALENDAR*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content">
                                    <FullCalendar
                                        plugins={[timeGridPlugin, interactionPlugin]}
                                        locales={[switchLocale()]}
                                        initialView='timeGridDay'
                                        timeZone='UTC'
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'timeGridWeek,timeGridDay'
                                        }}
                                        initialDate={storageDate}
                                        allDaySlot={false}
                                        height="auto"
                                        fixedWeekCount={false}
                                        slotLabelFormat={[{ hour:"2-digit", minute: "2-digit" }]}
                                        displayEventTime={true}
                                        displayEventEnd={true}
                                        eventOrder="sorting"
                                        slotDuration={lessonSetting ? lessonSetting.calendarOption.interval : "00:15:00"}
                                        slotMinTime={lessonSetting ? lessonSetting.calendarOption.minTime : "09:00:00"}
                                        slotMaxTime={lessonSetting ? lessonSetting.calendarOption.maxTime : "22:00:00"}
                                        events={
                                            async (event) => {
                                                const { startStr, endStr } = event;

                                                const params = filterParams ? JSON.parse(filterParams) : null;
                                                localStorage.setItem('calendarTeacher_date', startStr.split('T')[0]);

                                                return await request(`${URL.back_end}/calendar_teacher_lesson`, 'POST', { startStr, endStr, params });
                                            }
                                        }
                                        eventClick={
                                            (info) => {
                                                if (info.el.offsetParent.classList.contains('busytime')) {
                                                    const data = info.event.extendedProps.data;

                                                    const obj = {
                                                        access   : auth.role.interface,
                                                        customer : data.customer,
                                                        employee : data.employee,
                                                        group    : data.group,
                                                        ...data.lesson
                                                    }

                                                    setPopoverData(obj);
                                                    setPopoverStyle({
                                                        top: `${info.jsEvent.pageY-200}px`, left: `${info.jsEvent.pageX-160}px`, minWidth: "330px", display: "block"
                                                    })
                                                }
                                            }
                                        }
                                        eventDidMount={
                                            (info) => {
                                                const { is_lesson } = info.event.extendedProps.data;

                                                if (is_lesson) {
                                                    // DATA
                                                    const { customer, group, employee, left, width, lesson } = info.event.extendedProps.data;
                                                    const parentElement = info.el.offsetParent;

                                                    // STYLE
                                                    parentElement.classList.add('busytime');
                                                    parentElement.style.left = `${left}%`;
                                                    parentElement.style.width = `${width}%`;
                                                    parentElement.style.zIndex = 150;
                                                    parentElement.style.borderLeft = '6px solid inherit';
                                                    parentElement.style.border = '1px solid inherit';
                                                    info.el.style.backgroundColor = '#ffffff';

                                                    // DESCRIPTION
                                                    const audienceRoom = lessonSetting ? lessonSetting.calendarOption.showAudience ? findObject(lessonSetting.room, lesson.audience) ? findObject(lessonSetting.room, lesson.audience).name : null : null : null;
                                                    const lessonSubject = lessonSetting ? lessonSetting.calendarOption.showSubject ? findObject(lessonSetting.subject, lesson.subject) ? findObject(lessonSetting.subject, lesson.subject).name : null : null : null;

                                                    const time = info.el.childNodes[0].childNodes[0].childNodes[0];
                                                    const event_title = info.el.childNodes[0].childNodes[0].childNodes[1];
                                                    const title = event_title.childNodes[0];

                                                    if (lesson.status === 1) {
                                                        time.innerHTML = `<big><i class="fa fa-check"></i></big><span>${ info.timeText }</span> ${audienceRoom ? `<i id="a-m-xs" class="ion-location"></i>${audienceRoom}` : ''}`
                                                    } else if (lesson.status === 2) {
                                                        time.innerHTML = `<big><i class="fa fa-minus-circle"></i></big><span>${ info.timeText }</span> ${audienceRoom ? `<i id="a-m-xs" class="ion-location"></i>${audienceRoom}` : ''}`
                                                        info.el.classList += " text-dec-line";
                                                    } else {
                                                        time.innerHTML = `<big><i class="fa fa-square-o"></i></big><span>${ info.timeText }</span> ${audienceRoom ? `<i id="a-m-xs" class="ion-location"></i>${audienceRoom}` : ''}`
                                                    }

                                                    title.innerHTML =
                                                        `${lessonSubject ? `<i class="ion-ios-book"></i> ${lessonSubject}` : ''}
                                                     ${customer.length || group.length ? `<i class='${iconCalendar[lesson.type_lesson]}'></i>` : ''}
                                                     ${customer.length ? `${customer.map(item => item.name)}` : ''}
                                                     ${group.length ? `${group.map(item => item.title)}` : ''}
                                                     ${lessonSetting ? lessonSetting.calendarOption.showEmployee ? employee.length ? `<i class="ion-university"></i>${employee.map(item => item.name)}`: '' : '' : ''}
                                                     ${lessonSetting ? lessonSetting.calendarOption.showNote ? lesson.l_note ? `<i>${lesson.l_note}</i>` : '' : '' : ''}`

                                                } else {
                                                    const { borderColor, left, width, employeeLegend } = info.event.extendedProps.data;
                                                    const parentElement = info.el.offsetParent;

                                                    parentElement.style.left = `${left}%`;
                                                    parentElement.style.width = `${width}%`;
                                                    info.el.style.borderColor = borderColor;
                                                    document.getElementById('teacher-legend').innerHTML = employeeLegend.join(' ');
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}
            <AddLesson
                groupData={groupData}
                employeeData={employeeData}
                customerData={customerData}
                type_lesson={typeLesson}
            />

            <EditLesson
                groupData={groupData}
                employeeData={employeeData}
                customerData={customerData}
                editLesson={editLesson}
            />

            <SpendLesson
                employeeData={employeeData}
                groupData={groupData}
                customerData={customerData}
                editLesson={popoverData}
            />

            <CalendarTeacherFilter
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                employeeData={employeeData}
            />

            {/* POPOVER */}
            <CalendarPopover
                data={popoverData}
                setData={setPopoverData}
                popoverStyle={popoverStyle}
                setPopoverStyle={setPopoverStyle}
                setEditLesson={setEditLesson}
                side='top'
                sideStyle={{ left: '50%' }}
            />
        </div>
    )
}