// HOOK
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useHttp} from "../../../Hooks/http.hook";
import React, {useContext, useEffect, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";

// COMPONENT
import SideMenu from "../../../Components/SideMenu/SideMenu";
import VisitStats from "../../../Components/Widget/VisitStats";
import AcceptModal from "../../../Components/Modal/AcceptModal";
import EditGroup from '../../Group/Modal/EditGroup';
import Error from "../../Error/Error";

// FUNCTION
import {findObject, filterDisableEntity, openModal} from "../../../Function/common";

// CONTEXT
import {SettingContext} from "../../../Context/SettingContext";

// JSON
import URL from '../../../Api/URL.json';
import TabAction from "../../../Components/TabAction";
import {CustomerBlock} from "./Block/CustomerBlock";
import {RegularLessonBlock} from "./Block/RegularLessonBlock";
import {LessonsBlock} from "./Block/LessonsBlock";
import {TaskBlock} from "./Block/TaskBlock";
import {AuthContext} from "../../../Context/AuthContext";
import {useArchive} from "../../../Hooks/archive.hook";
import CommentBlock from "./Block/CommentBlock";
import DeleteModal from "../../../Components/Modal/DeleteModal";

export default function GroupCard () {

    // CONTEXT START
    const { lessonSetting, customSetting } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // NAVIGATE START
    const navigate = useNavigate();
    // NAVIGATE END

    // GROUP ID START
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    // GROUP ID END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [sqlCard, setSqlCard] = useState({});

        // DATA
    const [dataCustomer, setDataCustomer] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [groupLesson, setGroupLesson] = useState([]);
    const [groupEmployees, setGroupEmployees] = useState([]);
    const [regularLesson, setRegularLesson] = useState([]);
    const [managerData, setManagerData] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        async function fetchData() {
            const current_group = await request(`${URL.back_end}/group/${id}`, 'GET');
            const customers = await request(`${URL.back_end}/customer`, 'GET');
            const employees = await request(`${URL.back_end}/employee`, 'GET');
            const groups = await request(`${URL.back_end}/group`, 'GET');
            const lessons = await request(`${URL.back_end}/group_lesson/${id}`, 'GET');
            const groupEmployee = await request(`${URL.back_end}/group_employee/${id}`, 'GET');
            const regLesson = await request(`${URL.back_end}/filter_regular_lesson`, 'POST', { group_id : id });
            const managers = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } });

            if (error) throw error;

            setGroupLesson(lessons);
            setSqlCard(current_group[0]);
            setDataCustomer(customers);
            setEmployeeData(employees);
            setGroupData(groups);
            setGroupEmployees(groupEmployee);
            setRegularLesson(regLesson);
            setManagerData(managers.users);
        }

        fetchData().catch(e => console.log(e))

    }, [error, id, request])
    // INIT DATA END

    // BUTTON FUNC START
    async function buttonArchive () {
        await request(`${URL.back_end}/group/${sqlCard.group_id}`, 'PUT', { f_removed: !sqlCard.f_removed })

        if (!sqlCard.f_removed) {
            await pushArchive(4, 'group', sqlCard.group_id, { f_removed : 1 }, { f_removed : 0 })
        } else {
            await pushArchive(3, 'group', sqlCard.group_id, { f_removed : 0 }, { f_removed : 1 })
        }

        if (error) return console.log(error)

        window.location.reload();
    }

    function archiveHandler () {
        openModal('archive-group-modal');
        openModal('archive-group-overlay');
    }
    // BUTTON FUNC END

    if (error || sqlCard === undefined) return <Error text={`${translate('Группу с')} id ${id} ${translate('не найдено')}`} />

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="group" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON BACK*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-default btn-outline"
                                    icon="ion-arrow-left-c"
                                    text="Назад"
                                    handler={() => navigate(-1)}
                                />
                                {/*BUTTON EDIT*/}
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-success btn-outline"
                                    icon="ion-edit"
                                    text="Править"
                                    handler={() => openModal('edit-group')}
                                />
                                {/*BUTTON ARCHIVE*/}
                                <TabAction
                                    access={auth.role.interface === 1 && sqlCard.f_removed === 0}
                                    className="btn btn-sm btn-w-m btn-danger btn-outline"
                                    icon="fa fa-trash-o"
                                    title={translate('В архив')}
                                    text="В архив"
                                    handler={() => archiveHandler()}
                                />
                                <TabAction
                                    access={auth.role.interface === 1 && sqlCard.f_removed === 1}
                                    className="btn btn-sm btn-w-m btn-primary btn-outline"
                                    icon="fa fa-reply"
                                    title={translate('Из архива')}
                                    text="Из архива"
                                    handler={() => archiveHandler()}
                                />
                            </div>
                        </div>
                    </div>
                    {/*ROW*/}
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content border-bottom">
                                    {/*HEADER*/}
                                    <div className={sqlCard.f_removed !== 0 && 'archive'}>
                                        {/*DATE*/}
                                        <span className="pull-right text-right text-muted">
                                            {translate('Начало обучения')} <br />
                                            {sqlCard.date_start} {sqlCard.date_end && `— ${sqlCard.date_end}`}
                                            </span>
                                        <a className="pull-left m-r-sm" title={translate('Загрузить фото')}>
                                            <img
                                                className="img-thumbnail img-circle"
                                                src="https://cdn.alfacrm.com/images/group-default.png"
                                                alt=''
                                                style={{width: "50px", height: "50px"}}
                                            />
                                        </a>
                                        {/*TITLE*/}
                                        <h2 className="font-noraml no-margins no-padding"> {sqlCard.title} </h2>
                                        {/*BRANCH*/}
                                        <h4 className="font-noraml m-t-sm no-padding text-muted">
                                            <i className="ion-location"/>
                                        </h4>
                                        {/*NOTE*/}
                                        { sqlCard.description && <div className="border-left p-xxs"> { sqlCard.description } </div> }
                                    </div>
                                    {/*BODY*/}
                                    <VisitStats
                                        groupData={groupData}
                                        employeeData={employeeData}
                                        groupLesson={groupLesson}
                                        customerData={dataCustomer}
                                    />
                                </div>
                            </div>
                            <CommentBlock
                                sqlCard={sqlCard}
                                managerData={managerData}
                            />
                            <TaskBlock
                                sqlCard={sqlCard}
                            />
                        </div>
                        {/*DETAIL INFO*/}
                        <div className="col-lg-4">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content border-bottom">
                                    {/*LESSONS*/}
                                    <LessonsBlock
                                        sqlCard={sqlCard}
                                        groupLesson={groupLesson}
                                        employeeData={employeeData}
                                        customerData={dataCustomer}
                                        groupData={groupData}
                                    />
                                    {/* LIMIT */}
                                    {
                                        sqlCard.limit && (
                                            <div className="row">
                                                <div className="col-xs-5 text-muted m-b-sm">{translate('Лимит участников')}</div>
                                                <div className="col-xs-7 m-b-sm text-right">
                                                    { sqlCard.limit }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/*KNOWLEDGE*/}
                                    {
                                        sqlCard.knowledge &&
                                        (
                                            <div className="row">
                                                <div className="col-xs-5 text-muted m-b-sm">{translate('Уровень')}</div>
                                                <div className="col-xs-7 m-b-sm text-right">
                                                    {sqlCard.knowledge}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/*STATUS*/}
                                    {
                                        sqlCard.status &&
                                        (
                                            <div className="row">
                                                <div className="col-xs-5 text-muted m-b-sm">{translate('Статус')}</div>
                                                <div className="col-xs-7 m-b-sm text-right">
                                                    {
                                                        lessonSetting ?
                                                            findObject(lessonSetting.groupStatus, sqlCard.status) ?
                                                                findObject(lessonSetting.groupStatus, sqlCard.status).name :
                                                                null
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/*EMPLOYEE*/}
                                    {
                                        groupEmployees.length ?
                                        (
                                            <div className="row">
                                                <div className="col-xs-5 text-muted m-b-sm">{translate('Ответственный педагог')}</div>
                                                <div className="col-xs-7 m-b-sm text-right">
                                                    {
                                                        groupEmployees.map(item => (
                                                            <>
                                                                <Link to={`/company/1/employee/view?id=${item.employee_id}`}>
                                                                    {item.name}
                                                                </Link>
                                                                <br />
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        customSetting &&
                                            filterDisableEntity(customSetting.customField, "group").map(item => sqlCard[item.systemName] && (
                                                <div className="row">
                                                    <div className="col-xs-5 text-muted m-b-sm"> {item.name} </div>
                                                    <div className="col-xs-7 text-right m-b-sm break-word">
                                                        {
                                                            sqlCard[item.systemName] ? (/\["/gi).test(sqlCard[item.systemName]) ? JSON.parse(sqlCard[item.systemName]).join(', ') : sqlCard[item.systemName] :
                                                                <span className="text-danger text-lowercase">{translate('Не задано')}</span>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                    }
                                    {/*CUSTOMER*/}
                                    <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                    <CustomerBlock
                                        sqlCard={sqlCard}
                                        regularLesson={regularLesson}
                                        dataCustomer={dataCustomer}
                                        groupLesson={groupLesson}
                                    />
                          
                                    {/*REGULAR LESSONS*/}
                                    <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                    <RegularLessonBlock
                                        sqlCard={sqlCard}
                                        regularLesson={regularLesson}
                                        employeeData={employeeData}
                                        dataCustomer={dataCustomer}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*MODAL*/}

            {/*EDIT GROUP*/}
            <EditGroup
                updateSql={setSqlCard}
                employeeData={employeeData}
                editGroup={sqlCard}
                typeModal="card"
            />

            {/*ACCEPT MODAL*/}
            <AcceptModal />

            <DeleteModal
                id="archive-group"
                handler={buttonArchive}
                text={'Ви впевнені, що хочете архівувати/розархівувати групу?'}
            />
        </div>
    )
}