// HOOK
import {useContext, useRef, useState, memo} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useArchive} from "../../../../../Hooks/archive.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";

// JSON
import URL from '../../../../../Api/URL.json';

// CONTEXT
import {SettingContext} from "../../../../../Context/SettingContext";

// FUNCTION
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {closeModal} from "../../../../../Function/common";
import {success} from "../../../../../Function/Notify";
import {validate} from "../../../../../Function/validateForm";

export const AddQualification = memo(function MemoAddQual(props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // CONTEXT START
    const { customerSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ subject, setSubject ] = useState({});
    const [ knowledge, setKnowledge ] = useState({});
    const [ note, setNote ] = useState(null);
    // STATE END

    // ADD QUALIFICATION START
    async function addQualification(e) {
        e.preventDefault();

        let validObj = {
            "Предмет" : !Object.keys(subject).length,
            "Уровень" : !Object.keys(knowledge).length
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

            // PROCESS

        let arr_qualification = props.sql.qualification !== null && props.sql.qualification !== undefined ?
            JSON.parse(props.sql.qualification) : null;

        let qualification = {
            id : arr_qualification !== null ? arr_qualification.length : 0,
            subject: subject.id, knowledge: knowledge.id, note
        }

        arr_qualification !== null ? arr_qualification.push(qualification) : arr_qualification = [qualification]

        await request(`${URL.back_end}/customer/${props.sql.customer_id}`, 'PUT', {qualification: JSON.stringify(arr_qualification)})

        if (error) return console.log(error)
        await pushArchive(1, "Knowledge", props.sql.customer_id, qualification, null);


        let update_customer = { qualification: JSON.stringify(arr_qualification) }

        for(let key in props.sql) {
            if (key !== "qualification") {
                update_customer[key] = props.sql[key];
            }
        }

        props.updateSql(update_customer);

        success(`Запись успешно добавлена`)

        modal.current.style.display = "none";
    }
    // ADD QUALIFICATION END

    return (
        <div id="add-qualification" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">{translate('Добавить квалификацию')}</h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addQualification(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предмет')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? lessonSetting.subject.filter(item => item.disabled !== true) : []}
                                        type_id="id"
                                        topicSearch="name"
                                        setProps={setSubject}
                                    />
                                </div>
                            </div>
                            {/*KNOWLEDGE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Уровень')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={customerSetting ? customerSetting.knowledge.filter(item => item.disabled !== true) : []}
                                        type_id="id"
                                        topicSearch="name"
                                        setProps={setKnowledge}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Примечание')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Любое текстовое примечание')}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => prevProps.sql === nextProps.sql)