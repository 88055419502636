import {useContext, useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal, filterDisable} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {useNavigate} from "react-router-dom";
import UIMenuInput from "../../../Components/InputChoose/UIMenuInput";

export default function FilterPay (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { paySetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key] })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [customer_id, setCustomer] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [amountMin, setAmountMin] = useState('');
    const [amountMax, setAmountMax] = useState('');
    const [type_operation, setTypeOperation] = useState('');
    const [article, setArticle] = useState('');
    const [account, setAccount] = useState('');
    const [manager, setManager] = useState('');

        // SEARCH STATE
    const [customerSearch, setCustomerSearch] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.value) {
            setCustomer('');
            setDateStart('');
            setDateEnd('');
            setAmountMin('');
            setAmountMax('');
            setTypeOperation('');
            setArticle('');
            setAccount('');
            setManager('');
            return;
        }

        setCustomer(props.value.customer_id);
        setDateStart(props.value.dateStart);
        setDateEnd(props.value.dateEnd);
        setAmountMin(props.value.amountMin);
        setAmountMax(props.value.amountMax);
        setTypeOperation(props.value.type_operation);
        setArticle(props.value.article);
        setArticle(props.value.account);
        setManager(props.value.manager);
    }, [props.value])
    // INIT DATA END

    // FILTER PAY START
    function filterPay (e) {
        e.preventDefault();

        let pay = {
            customer_id,
            dateStart,
            dateEnd,
            amountMin,
            amountMax,
            type_operation,
            article,
            account,
            manager
        }

        let obj = Object.filter(pay, (value) => value);

        if (Object.keys(obj).length === 0) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/pay/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }

    }
    // FILTER PAY END

    return (
        <div id="filter-pay" className="modal in" style={{ paddingRight: "16px" }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterPay(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body modal-body-search p-md">
                            {/* CUSTOMER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { customer_id && <i className="ion-person text-muted" /> } { translate('Клиент') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <UIMenuInput
                                        data={props.customerData}
                                        searchText={customerSearch}
                                        setSearchText={setCustomerSearch}
                                        setId={setCustomer}
                                        value={customer_id}
                                        placeholder="Поиск клиента"
                                        type_id="customer_id"
                                        topic="name"
                                    />
                                </div>
                            </div>
                            {/* DATE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Дата') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={dateStart}
                                                onChange={e => setDateStart(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={dateEnd}
                                                onChange={e => setDateEnd(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*AMOUNT*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Сумма') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={translate('От')}
                                                value={amountMin}
                                                onChange={e => setAmountMin(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={translate('До')}
                                                value={amountMax}
                                                onChange={e => setAmountMax(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*TYPE OPERATION*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Тип операции') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={type_operation} onChange={e => setTypeOperation(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='1'> { translate('Доход') } </option>
                                        <option value='2'> { translate('Возврат средств') } </option>
                                        <option value='3'> { translate('Корректировка') } </option>
                                    </select>
                                </div>
                            </div>
                            {/*ARTICLE*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Статья') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={article} onChange={e => setArticle(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            paySetting && filterDisable(paySetting.article).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Аккаунт') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={account} onChange={e => setAccount(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            paySetting && filterDisable(paySetting.account).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            {/*RESET FILTER*/}
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр и вернуться в таблицу') } onClick={e => { closeModal(e, modal); navigate('/company/1/pay/index/') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            {/*SETTING FIELDS*/}
                            {/*<a className="btn btn-sm btn-success btn-outline pull-left" title={ translate('Настройка полей') }>*/}
                            {/*    <i className="fa fa-cog fa-fw" />*/}
                            {/*</a>*/}
                            {/*CANCEL*/}
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            {/*ACCEPT*/}
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}