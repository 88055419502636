import {openModal} from "../../../../Function/common";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import EditSourceGroup from "./Modal/EditSourceGroup";
import $ from 'jquery';
import 'jquery-ui-bundle';
import {useTranslate} from "../../../../Hooks/translate.hook";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('#studystatussearch-f_name').focus();

    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/salesSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'sourceGroup'}),
                success: function(data){
                    setting.salesSetting = data;
                }
            });
        }
    });
}

export default function SourceGroup () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const {salesSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [editSourceGroup, setEditSourceGroup] = useState({});
    const [deleteSourceGroup, setDeleteSourceGroup] = useState({});
    // STATE END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.salesSetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // COMP FUNC START
    function deleteHandler (item) {
        setDeleteSourceGroup(item);
        openModal('delete-source-group-overlay');
        openModal('delete-source-group-modal');
    }

    async function deleteSourceGroupHandler () {
        salesSetting.sourceGroup = salesSetting.sourceGroup.filter(item => {
            if (item.id !== deleteSourceGroup.id) {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {salesSetting: JSON.stringify(salesSetting)})

        window.location.reload();
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Группы источников')} <span className="text-muted">({salesSetting ? salesSetting.sourceGroup.length : 0})</span></h4>
            </div>
            <div className="modal-body p-md">
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    className="form-control"
                                    placeholder="Фильтр по названию"
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-block btn-sm btn-success" onClick={() => openModal("add-source-group")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                <div className="border-bottom" />
                <div className="sortable-list ui-sortable">
                    {
                        salesSetting ?
                            salesSetting.sourceGroup.map(item => (
                                <div className="border-bottom sortable-item row row-item ui-sortable-handle" data-id={`${item.id}`}>
                                    <div className="col-xs-7">
                                        <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                        <small className="text-muted m-r-sm"> [ID {item.id}] </small>
                                    </div>
                                    <div className="col-xs-2 text-muted"> {item.source} {translate('источников')} </div>
                                    <div className="col-xs-3 text-right">
                                        <a className="btn btn-xs btn-white m-r-xxs" onClick={() => {openModal("edit-source-group"); setEditSourceGroup(item)}}>
                                            <i className="fa fa-pencil text-success fa-fw" />
                                        </a>
                                        <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item)}>
                                            <i className="fa fa-trash-o text-danger fa-fw" />
                                        </a>
                                    </div>
                                </div>
                            ))
                            : null
                    }
                </div>
            </div>
            <EditSourceGroup
                editSourceGroup={editSourceGroup}
            />
            <DeleteModal
                id="delete-source-group"
                handler={deleteSourceGroupHandler}
            />
        </>
    )
}