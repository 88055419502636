import React, {memo, useState} from "react";
import {openModal} from "../../../../Function/common";
import GroupRow from "../Row/GroupRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddGroup from "../Modal/Group/AddGroup";
import EditGroup from "../Modal/Group/EditGroup";

export const GroupBlock = memo(function ({ sqlCard, customerGroup, groupData }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editGroup, setEditGroup ] = useState({});
    // STATE END

    return (
        <>
            <h4 className="no-padding m-b-xs m-t-xs">
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal("add-group")}>
                    <span className="text-lowercase font-noraml">{ translate('Добавить') }</span>
                </a>
                <span className="text-muted">{translate('Группы')}</span>
            </h4>
            {
                customerGroup.length > 0 ?
                    customerGroup.map((item, index) => (
                        <GroupRow
                            data={item}
                            index={index+1}
                            setEditGroup={setEditGroup}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({translate('не задано')})
                            </div>
                        </div>
                    </div>
            }

            {/*GROUP*/}
            <AddGroup
                sql={sqlCard}
                groupData={groupData}
            />

            <EditGroup
                sql={sqlCard}
                groupData={groupData}
                editGroup={editGroup}
            />
        </>
    )
})