import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useContext, useEffect, useState} from "react";
import URL from "../../../../../Api/URL.json";
import {calculateSalary} from "../../../../../Function/lessonFunction";
import {SettingContext} from "../../../../../Context/SettingContext";
import currency from "../../../../../Assets/data/JSON/currency.json";
import iconCalendar from "../../../../../Assets/data/JSON/iconCalendar.json";
import typeLessons from "../../../../../Assets/data/JSON/typeLessons.json";
import {filterDisable, findObject} from "../../../../../Function/common";
import moment from "moment";

export default function TablePayrollStatement ({ params }) {

    // CONTEXT START
    const { lessonSetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ customersLesson, setCustomersLesson ] = useState([]);
    const [ employee, setEmployee ] = useState({});
    const [ wage, setWage ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const lessons = await request(`${URL.back_end}/filter_employee_lesson`, 'POST', { ...params, status : 1 });
            const wages = await request(`${URL.back_end}/employee_wage/${params.employee_id}`, 'GET');
            const [ employeeData ] = await request(`${URL.back_end}/employee/${params.employee_id}`, 'GET');
            let customers = [];

            if (lessons.length) {
                customers = await request(`${URL.back_end}/filter_lesson_customer`, 'POST', { lesson_ids : lessons.map(item => item.lesson_id), presence : 1 });
            }

            if (error) throw error

            setSql(lessons);
            setCustomersLesson(customers);
            setEmployee(employeeData);
            setWage(wages);
        }

        fetchData().catch(e => console.log(e))

    }, [params])
    // INIT DATA END

    // RENDER TABLE BODY START
    function calculateTime (date_start, date_end) {
        let [ hourStart, minStart ] = date_start.split('T')[1].slice(0, 5).split(':');
        let [ hourEnd, minEnd ] = date_end.split('T')[1].slice(0, 5).split(':');

        return (Number(hourEnd) * 60 + Number(minEnd)) - (Number(hourStart) * 60 + Number(minStart));
    }

    function renderMissLessons (missLessons) {

        if (!missLessons.length) return

        return (
            <tr>
                <td>
                    <small> { translate('Пропущенные записи') } </small>
                </td>
                <td colSpan={2}>
                    {
                        missLessons.map(item => (
                            <small className="m-r-sm">
                                <i className={iconCalendar[item.type_lesson]} /> { moment(item.date_start).format('DD.MM') }
                            </small>
                        ))
                    }
                </td>
                <td className="text-center">
                    { missLessons.length } { translate('шт.') }
                </td>
                <td className="text-right"> 0 </td>
            </tr>
        )
    }

    function renderTableBody (sql) {

        let lessons = sql.map(item => {
            let customers = customersLesson.filter(cust => cust.lesson_id === item.lesson_id);

            let { salary, clearSalary, progressive } = calculateSalary([item], wage, customers);

            item.salary = salary;
            item.clearSalary = clearSalary;
            item.progressive = progressive;
            item.customersCount = customers.length;
            item.duration = calculateTime(item.date_start, item.date_end);

            return item;
        })

        let resultLessons = [];

        for (let i = 0; i < lessons.length; i++) {
            for (let j = 0; j < lessons.length; j++) {
                if (lessons[i].subject === lessons[j].subject && lessons[i].type_lesson === lessons[j].type_lesson && lessons[i].customersCount === lessons[j].customersCount && lessons[i].duration === lessons[j].duration) {
                    if (resultLessons.find(elem => elem.subject === lessons[j].subject && elem.type_lesson === lessons[j].type_lesson && elem.customersCount === lessons[j].customersCount && elem.duration === lessons[j].duration)) {
                        resultLessons = resultLessons.map(item => {
                            if (item.lesson_id === lessons[i].lesson_id) {
                                item.amount += 1;
                                return item;
                            } else {
                                return item;
                            }
                        })
                        continue;
                    }

                    resultLessons.push({
                        lesson_id : lessons[i].lesson_id,
                        subject : lessons[i].subject,
                        type_lesson : lessons[i].type_lesson,
                        salary : lessons[i].salary,
                        clearSalary : lessons[i].clearSalary,
                        progressive : lessons[i].progressive,
                        customersCount : lessons[i].customersCount,
                        date_start : lessons[i].date_start,
                        duration : lessons[i].duration,
                        payout : lessons[i].payout,
                        amount : 1
                    })
                }
            }
        }

        return (
            <tbody>
                {
                    resultLessons.filter(item => item.salary !== 0).map(item => (
                        <tr>
                            <td className="text-left"> { findObject(filterDisable(lessonSetting?.subject), item.subject).name } </td>
                            <td className="text-left">
                                <i className={`${iconCalendar[item.type_lesson]} text-muted`} /> { typeLessons[item.type_lesson] } <span className="text-muted"> ({ item.type_lesson === 2 && `${item.customersCount} ${translate('чел.')}, ` }{item.duration} { translate('мин.') }) </span>
                            </td>
                            <td className="text-left"> { item.progressive && item.salary !== item.clearSalary ? <> <strike class="text-muted"> { item.clearSalary } </strike> { item.salary } </> : item.salary } </td>
                            <td className="text-center"> { item.amount } { translate('шт.') } </td>
                            <td className="text-right"> { item.salary * item.amount } </td>
                            <td className="text-center"> { item.payout ? 'Так' : 'Ні' } </td>
                        </tr>
                    ))
                }
                { renderMissLessons(resultLessons.filter(item => item.salary === 0)) }
                <tr>
                    <td colSpan={3}> </td>
                    <td className="font-bold text-center"> { resultLessons.map(item => item.amount).reduce((curr, next) => curr + next, 0) } { translate('шт.') } </td>
                    <td className="font-bold text-right"> { resultLessons.map(item => item.salary * item.amount).reduce((curr, next) => curr + next, 0) } </td>
                </tr>
            </tbody>
        )
    }
    // RENDER TABLE BODY END

    return (
        <div className="ibox-content m-b-sm" style={{ position: 'relative', paddingTop: '50px' }}>
            <div className="row">
                <div className="col-sm-12 text-center m-b-sm">
                    <h3 className="font-noraml">
                        <strong> { translate('Выписка по зарплате') } </strong> — { employee.name }
                    </h3>
                    <h4 className="font-noraml">
                        { translate('За период с') } { params.date_start } { translate('по') } { params.date_end }
                    </h4>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-condensed">
                    <thead>
                        <tr>
                            <th className="col-sm-2 text-left"> { translate('Предмет') } </th>
                            <th className="col-sm-4 text-left"> { translate('Тип') } </th>
                            <th className="col-sm-3 text-left"> { translate('Ставка') } </th>
                            <th className="col-sm-1 text-center"> { translate('Кол-во') } </th>
                            <th className="col-sm-2 text-right"> { translate('Сумма') } { currency[basicSetting?.baseOption.currency] } </th>
                            <th className="col-sm-1 text-center"> Виплачено </th>
                        </tr>
                    </thead>
                    { renderTableBody(sql) }
                </table>
            </div>
        </div>
    )
}