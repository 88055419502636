import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import URL from "../../../../../Api/URL.json";
import {closeModal, maxId} from "../../../../../Function/common";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";

export default function AddGroupStatus () {

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // CONTEXT START
    const {lessonSetting} = useContext(SettingContext)
    // CONTEXT END

    // REF START
    const modal = useRef();
    // REF END

    // STATE START
    const [name, setName] = useState('');
    // STATE END

    // ADD GROUP STATUS START
    async function addGroupStatus (e) {
        e.preventDefault();

        if (!name.length) return errorNotify(`Необходимо заполнить "Название"`)

        let groupStatus = {
            id: lessonSetting.groupStatus.length > 0 ? maxId(lessonSetting.groupStatus)+1 : 0,
            name: name,
            disabled: false
        }

        lessonSetting.groupStatus.push(groupStatus);
        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return error;
        window.location.reload();
    }
    // ADD GROUP STATUS END

    return (
        <div id="add-group-status" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addGroupStatus(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить статус')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            <div className="form-group field-groupstatus-name required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="groupstatus-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Активен')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}