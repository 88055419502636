import {Link} from "react-router-dom";
import RoleColumns from "./RoleColumns";
import {useTranslate} from "../../../../Hooks/translate.hook";
import shortid from "shortid";

export default function RoleTable (props) {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.98%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection_all"
                            />
                        </th>
                        <th style={{width: "25.2%"}}>
                            <Link to="/company/1/user/role-index?sort=name">
                                {translate('Название')}
                            </Link>
                        </th>
                        <th style={{width: "51.61%"}}>
                            {translate('Доступные права')}
                        </th>
                        <th style={{width: "19.21%"}}>
                            {translate('Пункты меню')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.sql.map(item => (
                            <RoleColumns
                                data={item}
                                sql={props.sql}
                                updateSql={props.updateSql}
                                updateRows={props.updateRows}
                                key={shortid.generate()}
                            />
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}