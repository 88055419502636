import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {filterDisable, getDateMonthDiapason, objectToUrlParams} from "../../../Function/common";
import URL from "../../../Api/URL.json";
import {SettingContext} from "../../../Context/SettingContext";

export default function  ConvertedIntoPayment () {

    // const options = {
    //     title : false,
    //     chart : { type : 'column' },
    //     yAxis : { title : { text : false } },
    //     xAxis : {
    //         categories : [ 'Май 111', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', 'Январь', 'Февраль', 'Март', 'Апрель', 'Май'],
    //         labels : {
    //             rotation: -45,
    //             style : {
    //                 fontSize : '13px',
    //                 color : '#666666'
    //             }
    //         }
    //     },
    //     plotOptions : { line : { lineWidth : 1, threshold : null, states : { hover : { lineWidth : 5 } } } },
    //     series : [
    //         { name : 'Доходы', data : [1,2,3,4,5,6,7,8,9,10,11,12], color : 'rgb(124, 181, 236)' },
    //         { name : 'Реализация', data : [1,2,3,4,5,6,7,8,9,11,10,22], color : 'rgb(67, 67, 72)' }
    //     ],
    //     legend : { layout : 'horizontal', align : 'center' },
    //     lang: {
    //         loading: 'Загрузка...',
    //         months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    //         weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    //         shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    //         exportButtonTitle: 'Экспорт',
    //         printButtonTitle: 'Печать',
    //         rangeSelectorFrom: 'С',
    //         rangeSelectorTo: 'По',
    //         rangeSelectorZoom: 'Период',
    //         downloadPNG: 'Скачать PNG',
    //         downloadJPEG: 'Скачать JPEG',
    //         downloadPDF: 'Скачать PDF',
    //         downloadSVG: 'Скачать SVG',
    //         printChart: 'Напечатать график',
    //         viewInFullScreen : 'В полный экран'
    //     }
    // }
    //
    // const options2 = {
    //     chart: {
    //         type: 'column'
    //     },
    //     title : false,
    //     accessibility: {
    //         announceNewData: {
    //             enabled: true
    //         }
    //     },
    //     xAxis: {
    //         type: 'category'
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'Total percent market share'
    //         }
    //
    //     },
    //     legend: {
    //         enabled: false
    //     },
    //     plotOptions: {
    //         series: {
    //             borderWidth: 0,
    //             dataLabels: {
    //                 enabled: true,
    //                 format: '{point.y:.1f}%'
    //             }
    //         }
    //     },
    //     series: [
    //         {
    //             name: 'Browsers',
    //             colorByPoint: true,
    //             data: [
    //                 {
    //                     name: 'Chrome',
    //                     y: 100,
    //                     drilldown: 'Chrome'
    //                 },
    //                 {
    //                     name: 'Safari',
    //                     y: 19.84,
    //                     drilldown: 'Safari'
    //                 },
    //                 {
    //                     name: 'Firefox',
    //                     y: 4.18,
    //                     drilldown: 'Firefox'
    //                 },
    //                 {
    //                     name: 'Edge',
    //                     y: 4.12,
    //                     drilldown: 'Edge'
    //                 },
    //                 {
    //                     name: 'Opera',
    //                     y: 2.33,
    //                     drilldown: 'Opera'
    //                 },
    //                 {
    //                     name: 'Internet Explorer',
    //                     y: 0.45,
    //                     drilldown: 'Internet Explorer'
    //                 },
    //                 {
    //                     name: 'Other',
    //                     y: 1.582,
    //                     drilldown: null
    //                 }
    //             ]
    //         }
    //     ],
    //     lang: {
    //         loading: 'Загрузка...',
    //         months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    //         weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    //         shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    //         exportButtonTitle: 'Экспорт',
    //         printButtonTitle: 'Печать',
    //         rangeSelectorFrom: 'С',
    //         rangeSelectorTo: 'По',
    //         rangeSelectorZoom: 'Период',
    //         downloadPNG: 'Скачать PNG',
    //         downloadJPEG: 'Скачать JPEG',
    //         downloadPDF: 'Скачать PDF',
    //         downloadSVG: 'Скачать SVG',
    //         printChart: 'Напечатать график',
    //         viewInFullScreen : 'В полный экран'
    //     }
    // }
    //
    // Exporting(Highcharts);
    //
    // useEffect(() => {
    //
    //     const chart1 = document.getElementById('chart-1');
    //
    //     if (!chart1) return;
    //
    //     Highcharts.chart('chart-1', options2);
    //
    // }, [])

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    // HOOK END

    // VALUE START
    const objDateMonthDiapason = getDateMonthDiapason();
    // VALUE END

    // STATE START
    const [ data, setData ] = useState([]);
    const [ chooseManager, setChooseManager ] = useState('');
    const [ dateFrom, setDateFrom ] = useState(objDateMonthDiapason.date_start);
    const [ dateTo, setDateTo ] = useState(objDateMonthDiapason.date_end);
    const [ source, setSource ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const params_obj = {
                dateFrom, dateTo, source
            }

            const response = await request(`${URL.back_end}/dashboard/convertedIntoPayment?${objectToUrlParams(params_obj)}`, 'GET');

            setData(response);

        }

        fetchData().catch(e => console.log(e))

    }, [source, dateFrom, dateTo])
    // INIT DATA END

    return (
        <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
            <div className="dashboard-item">
                <label className="font-bold h4 text-center dashboard-item-head no-margins">
                    Успішні ліди
                    <h5>
                        Джерело трафіку
                        <select style={{ outline : 0, border : 0, textAlign : 'center', marginBottom : '5px' }} onChange={e => setSource(e.target.value)}>
                            <option value=''> Усі </option>
                            {
                                salesSetting ?
                                    filterDisable(salesSetting.leadSource).map(item => (
                                        <option value={item.id}> { item.name } </option>
                                    ))
                                    : null
                            }
                        </select> <br />
                        від
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center', marginBottom : '5px' }}
                            onChange={e => setDateFrom(e.target.value)}
                            value={dateFrom}
                        />
                        <br />
                        до
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center' }}
                            onChange={e => setDateTo(e.target.value)}
                            value={dateTo}
                        />
                    </h5>
                </label>
                <div className="row">
                    <div className="col-sm-12 no-padding">
                        <div className="dashboard-chart" id="chart-2">
                            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
                                <thead>
                                    <tr>
                                        <th>
                                            <select style={{ outline : 0, border : 0, textAlign : 'center' }} onChange={e => setChooseManager(e.target.value)}>
                                                <option value={''}> Менеджер </option>
                                                { data.map(item => <option value={item.user_id}> { item.name } </option> ) }
                                            </select>
                                        </th>
                                        <th> Конверсія (шт.) </th>
                                        <th> Конверсія (%) </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chooseManager ?
                                            data.filter(item => item.user_id === +chooseManager).map(item => (
                                                <tr>
                                                    <td> { item.name } </td>
                                                    <td> { item.amount } </td>
                                                    <td> { item.conversion } % </td>
                                                </tr>
                                            ))
                                            : data.map(item => (
                                                <tr>
                                                    <td> { item.name } </td>
                                                    <td> { item.amount } </td>
                                                    <td> { item.conversion } % </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
