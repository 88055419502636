import "../../../../../Assets/css/SettingStyle/EditModal.css";
import {useContext, useEffect, useRef, useState} from "react";
import {closeModal} from "../../../../../Function/common";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {errorNotify} from "../../../../../Function/Notify";
import URL from "../../../../../Api/URL.json";

export default function EditReason (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {customerSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.editReason) return
        setType(props.editReason.type);
        setName(props.editReason.name);
        setValue(props.editReason.value);
    }, [props.editReason])
    // INIT DATA END

    // EDIT REASON START
    async function editReason (e) {
        e.preventDefault();

        let validity = {"Название" : !name.length, "Значение": !value.length, result: 0}
        for (let key in validity) {
            if (validity[key] && key !== "result") {
                errorNotify(`Необходимо заполнить "${key}"`);
                validity.result += 1;
            }
        }

        if (validity.result > 0) return;

        let reason = {
            id: props.editReason.id,
            type, name, value,
            disabled: props.editReason.disabled
        }

        customerSetting.reason = customerSetting.reason.map(item => {
            if (item.id === props.editReason.id) {
                return reason;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {customerSetting: JSON.stringify(customerSetting)})
        if (error) return error;
        window.location.reload();
    }
    // EDIT REASON END

    return (
        <div id="edit-reason" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editReason(e)}>
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> Править причину </h4>
                        </div>
                        <div className="modal-body p-md">
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="lessonreason-type">{translate('Тип')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <select id="lessonreason-type" className="form-control" onChange={e => setType(e.target.value)}>
                                        <option value="" selected={type === ""}>{translate('Не задано')}</option>
                                        <option value="1" selected={type === "1"}>{translate('Множитель стоимости')}</option>
                                        <option value="2" selected={type === "2"}>{translate('Натуральное значение')}</option>
                                    </select>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="lessonreason-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Болезнь')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*VALUE*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="lessonreason-coef">{translate('Значение')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 1`}
                                        step="0.001"
                                        value={value}
                                        onChange={e => setValue(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fix-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-info btn-outline" title={translate('История изменений')}>
                                    <i className="fa fa-history" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                Отмена
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> Сохранить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}