// HOOK
import {useTranslate} from "../../Hooks/translate.hook";
import {useHttp} from "../../Hooks/http.hook";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";

// COMPONENT
import SideMenu from "../../Components/SideMenu/SideMenu";
import ChatFeed from "./ChatFeed";
import {CustomerCard} from "../Card/Customer/CustomerCard";
import {Collapse} from 'react-collapse/lib/Collapse';
import ChatsMenu from "../../Components/Popover/ChatsMenu";
import FilterChat from "./Modal/FilterChat";

// CSS
import "../../Assets/css/Chats.css";

// JSON
import URL from "../../Api/URL.json";
import ws from '../../Api/ws.json';

// FUNCTION
import {openModal} from "../../Function/common";
import {success} from "../../Function/Notify";

// CONTEXT
import {SettingContext} from "../../Context/SettingContext";
import {AuthContext} from "../../Context/AuthContext";

// IMAGE
import arrow_down from '../../Assets/image/arrow_down.svg';
import SendMessageChat from "./Modal/SendMessageChat";
import ChatPagination from './ChatPagination';

// SOCKET
let socket = new WebSocket(`${ws.url}/message_chat?${(window.location.hostname).split('.')[0]}`);

// HEARTBEAT
const HEARTBEAT_INTERVAL = 10000; // интервал отправки "heartbeat" сообщений
const HEARTBEAT_TIMEOUT = 2000; // таймаут ожидания ответа на "heartbeat" сообщение

export default function Chats () {

    // CONTEXT START
    const { integration } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // CUSTOMER ID START
    const [ searchParams ] = useSearchParams();
    const id = searchParams.get("id");
    // CUSTOMER ID END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ managersData, setManagersData ] = useState([]);
    const [ employeeData, setEmployeeData ] = useState([]);
    const [ searchText, setSearchText ] = useState('');
    const [ menuStyle, setMenuStyle ] = useState({});
    const [ menuData, setMenuData ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ messageCounts, setMessageCounts ] = useState(0);
    const [ page, setPage ] = useState(1);

        // SOCKET
    const [ heartbeatTimer, setHeartbeatTimer ] = useState(null);
    const [ checkHeartbeatInterval, setCheckHeartbeatInterval ] = useState(null);
    const [ wss, setWss ] = useState(null);

        // COLLAPSE
    const [ active, setActive ] = useState(true);
    const [ archive, setArchive ] = useState(false);
    // STATE END

    // FUNC CHATS START
    // function filterMessage (arr) {
    //     let last_message = {};
    //
    //     // eslint-disable-next-line array-callback-return
    //     arr.map(item => {
    //         last_message[item.chat_id] = item;
    //     })
    //
    //     let result = [];
    //
    //     for (let key in last_message) {
    //         result.push(last_message[key]);
    //     }
    //
    //     return result.sort((a, b) => b.date - a.date).sort((a, b) => Number(b.pinned) - Number(a.pinned))
    // }

    async function handlerNotification () {
        const btn_notification = document.getElementById('btn_notification');

        integration.telegram = integration.telegram.map(item => {
            item.notification = !item.notification;
            return item;
        })

        btn_notification.className = `fa fa-bell${integration.telegram.filter(item => item.notification === true).length ? '' : '-slash'}-o icon-alert`;

        await request(`${URL.back_end}/setting`, 'PUT', {integration: JSON.stringify(integration)});

        if (error) return error;

        success(translate(integration.telegram.filter(item => item.notification).length ? 'Уведомление включено' : 'Уведомление выключено'));

    }

    const archiveChat = (arr, bool) => arr.filter(item => Number(item.archive) === bool)
    const messageNotRead = (arr, bool) => arr.filter(item => item.checked === 0 && Number(item.archive) === bool)
    // FUNC CHATS END

    // SOCKET HANDLER START
    socket.onopen = () => {
        if (auth.role.interface !== 1) return

        setWss(socket);
        clearTimeout(heartbeatTimer);
        startHeartbeat(socket);
    }

    socket.onmessage = (event) => {
        if (auth.role.interface !== 1) return;

        if (event.data !== 'heartbeat') {
            const params = filterParams ? JSON.parse(filterParams) : {};

            fetchData(params).catch(e => console.log(e));

            return;
        }

        clearTimeout(heartbeatTimer);
        clearInterval(checkHeartbeatInterval);
        setHeartbeatTimer(null);
    }

    function startHeartbeat () {
        setCheckHeartbeatInterval(setInterval(() => {
            if (!wss) return;

            if (wss.readyState !== WebSocket.OPEN) {
                clearInterval(checkHeartbeatInterval);
                return;
            }

            if (!heartbeatTimer) {
                sendHeartbeat();
            }
        }, HEARTBEAT_INTERVAL))

        function sendHeartbeat() {
            if (wss.readyState === WebSocket.OPEN) {
                wss.send('heartbeat');

                setHeartbeatTimer(setTimeout(() => {
                    console.warn('Сервер не ответил на "heartbeat" сообщение');
                    wss.close();
                }, HEARTBEAT_TIMEOUT));
            }
        }

        sendHeartbeat();
    }

    socket.onclose = () => {
        clearTimeout(heartbeatTimer);
        clearInterval(heartbeatTimer);

        setTimeout(() => {
            socket = new WebSocket(`${ws.url}/message?${(window.location.hostname).split('.')[0]}`);
            setWss(socket);
        }, 5000)
    }
    // SOCKET HANDLER END

    // GET MESSAGES START
    async function fetchData (params) {
        const data = await request(`${URL.back_end}/customer_message/`, 'POST', { page, limit: 10, data: { ...params } });

        if (error) throw error;

        setSql(data.messages);
        setMessageCounts(data.counts);

        setLoading(false);
    }
    // GET MESSAGES END

    // INIT DATA START
    useEffect(() => {

        document.getElementById('chats-block').oncontextmenu = () => false;

        setLoading(true);

        const params = filterParams ? JSON.parse(filterParams) : {};

        fetchData(params).catch(e => console.log(e));

    }, [filterParams, page])
    // INIT DATA END

    useEffect(() => {

        async function fetchData () {
            const user_data = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1, f_removed : 0 } });
            const emp_data = await request(`${URL.back_end}/employee_filter`, 'POST', { nolimit : true, data : { f_removed : 0 } });

            if (error) throw error;

            setManagersData(user_data.users);
            setEmployeeData(emp_data.employees);
        }

        fetchData().catch(e => console.log(e))

    }, [])

    // SEARCH START
    const searchItem = useMemo(() => {
        if (searchText) {
            let text = sql.filter(item => item.text?.toLowerCase().includes(searchText.toLowerCase()))
            let name = sql.filter(item => item.name?.toLowerCase().includes(searchText.toLowerCase()))
            let username = sql.filter(item => item.username?.toLowerCase().includes(searchText.toLowerCase()))
            return text.length ? text : (name.length ? name : username)
        } else {
            return null;
        }
    }, [searchText, sql])
    // SEARCH END

    return (
        <div id="wrapper">
            <SideMenu page="chats" />
            <div id="page-wrapper" className="gray-bg" style={{ minHeight : '626px' }}>
                <div className="wrapper crm-content">
                    <div className="col-lg-3" style={{ marginBottom : "10px" }}>
                        <div className="ibox-content border-bottom inbox_message" id="chats-block">
                            {/* SEARCH */}
                            <div className="row search-container">
                                <i
                                    className="fa fa-send icon-send"
                                    onClick={() => openModal('send-message-chat')}
                                />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="search-input"
                                    placeholder={ translate('Поиск') }
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                />
                                <i
                                    className="fa fa-filter"
                                    id="btn_filter"
                                    style={!filterParams ? { color : 'black' } : {}}
                                    onClick={() => openModal('filter-chat')}
                                />
                                {/*<i*/}
                                {/*    className={`fa fa-bell${integration?.telegram.filter(item => item.group_id).length && integration?.telegram.filter(item => item.notification === true).length ? '' : '-slash'}-o icon-alert`}*/}
                                {/*    id="btn_notification"*/}
                                {/*    onClick={() => handlerNotification()}*/}
                                {/*/>*/}
                            </div>

                            <hr style={{ marginTop: "10px", marginBottom : "10px" }} />

                            {/* ARCHIVE */}
                            <div className="row chat-title" onClick={() => {setArchive(!archive); setActive(!active)}}>
                                <div className="col-xs-10 text-muted" style={{ textTransform : "uppercase", fontWeight : 600 }}>
                                    <span> { translate('Архив') } <i className="ion-archive" /> </span>
                                    <span className="message_not_read"> { messageNotRead(sql, 1).length } </span>
                                </div>
                                <div className="text-right" style={{ marginRight : "12px" }}>
                                    <div className="arrow_down" data-is-active={archive}>
                                        <img src={arrow_down} alt="" />
                                    </div>
                                </div>
                            </div>

                            <Collapse isOpened={archive}>
                                <div className="inbox_message_list">
                                    <div className="inbox_message_list_container" id="archive_container">
                                        <div className="inbox_message_list_wrapper">
                                            {
                                                loading ? (
                                                    <div className='spiner-example' style={{ height : '100px', paddingTop : '40px' }}>
                                                        <div className='sk-spinner sk-spinner-wave sk-margin'>
                                                            <div className='sk-rect1'></div>
                                                            <div className='sk-rect2'></div>
                                                            <div className='sk-rect3'></div>
                                                            <div className='sk-rect4'></div>
                                                            <div className='sk-rect5'></div>
                                                        </div>
                                                    </div>
                                                ) : (searchItem ? searchItem : archiveChat(sql, 1)).length ? (searchItem ? searchItem : archiveChat(sql, 1)).map(item => (
                                                        <ChatFeed
                                                            data={item}
                                                            sql={archiveChat(sql, 1)}
                                                            setSql={setSql}
                                                            setMenuStyle={setMenuStyle}
                                                            setMenuData={setMenuData}
                                                            block_id="archive_container"
                                                            menu_id="archive_menu"
                                                        />
                                                    )) :
                                                    <div className="empty-chats">
                                                        <h3> { translate('Нету чатов') } </h3>
                                                    </div>
                                            }

                                            <ChatsMenu
                                                menuStyle={menuStyle}
                                                menuData={menuData}
                                                sql={sql}
                                                setSql={setSql}
                                                menu_id="archive_menu"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Collapse>

                            <hr style={{ marginTop: "0px", marginBottom : "10px" }} />

                            {/* ACTIVE */}
                            <div className="row chat-title" onClick={() => { setActive(!active); setArchive(!archive) }}>
                                <div className="col-xs-10 text-muted" style={{ textTransform : "uppercase", fontWeight : 600 }}>
                                    <span> { translate('Беседы') } <i className="ion-chatbox" /> </span>
                                    <span className="message_not_read"> { messageNotRead(sql, 0).length } </span>
                                </div>
                            </div>
                            <Collapse isOpened={active}>
                                <div className="inbox_message_list">
                                    <div className="inbox_message_list_container" id="active_container">
                                        <div className="inbox_message_list_wrapper">
                                            {
                                                loading ? (
                                                    <div className='spiner-example' style={{ height : '100px', paddingTop : '40px' }}>
                                                        <div className='sk-spinner sk-spinner-wave sk-margin'>
                                                            <div className='sk-rect1'></div>
                                                            <div className='sk-rect2'></div>
                                                            <div className='sk-rect3'></div>
                                                            <div className='sk-rect4'></div>
                                                            <div className='sk-rect5'></div>
                                                        </div>
                                                    </div>
                                                ) : (searchItem ? searchItem : archiveChat(sql, 0)).length ? (searchItem ? searchItem : archiveChat(sql, 0)).map(item => (
                                                        <ChatFeed
                                                            data={item}
                                                            sql={archiveChat(sql, 0)}
                                                            setSql={setSql}
                                                            setMenuStyle={setMenuStyle}
                                                            setMenuData={setMenuData}
                                                            block_id="active_container"
                                                            menu_id="active_menu"
                                                        />
                                                    )) :
                                                    <div className="empty-chats">
                                                        <h3> { translate('Нету чатов') } </h3>
                                                    </div>
                                            }

                                            <ChatsMenu
                                                menuStyle={menuStyle}
                                                menuData={menuData}
                                                sql={sql}
                                                setSql={setSql}
                                                menu_id="active_menu"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <ChatPagination
                              counts={messageCounts}
                              page={page}
                              setPage={setPage}
                            />
                        </div>
                    </div>
                    {
                        id ?
                            <CustomerCard
                                chats={true}
                                chatsSql={sql}
                                setChatsSql={setSql}
                            />
                        : null
                    }
                </div>
            </div>

            <FilterChat
                managersData={managersData}
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
            />

            <SendMessageChat
                chatData={sql}
                employeeData={employeeData}
                managerData={managersData}
            />

        </div>
    )
}