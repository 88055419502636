import '../../Assets/css/CalendarPopover.css';
import {useContext, useRef} from "react";
import {useHttp} from "../../Hooks/http.hook";
import {SettingContext} from "../../Context/SettingContext";
import {findObject} from "../../Function/common";
import {useTranslate} from "../../Hooks/translate.hook";
import {openModal} from "../../Function/common";
import URL from '../../Api/URL.json';
import typeLessons from "../../Assets/data/JSON/typeLessons.json";
import currency from "../../Assets/data/JSON/currency.json";
import FileList from "../List/FileList";
import {useArchive} from "../../Hooks/archive.hook";
import jwt_decode from "jwt-decode";

export default function CalendarPopover (props) {

    // REF START
    const popover = useRef();
    const fc_media_screen = document.getElementsByClassName('fc-media-screen')[0];
    // REF END

    // CONTEXT START
    const { lessonSetting, customerSetting, basicSetting } = useContext(SettingContext);
    const userData = jwt_decode( JSON.parse(localStorage.getItem('userData')).token );
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // POPOVER FUNC START
    function getStatus (status) {
        // eslint-disable-next-line default-case
        switch (status) {
            case 0: return translate('запланирован');
            case 1: return translate('проведен');
            case 2: return translate('отменен');
        }
    }

    function popoverClose() {
        if (fc_media_screen) fc_media_screen.style.opacity = 1;

        props.setData({ });
        props.setPopoverStyle({ display: 'none' });
    }

    function getTime(dateStart, dateEnd) {
        if (!dateStart || !dateEnd) return

        let startTime = dateStart.split('T')[1].slice(0, 5);
        let endTime = dateEnd.split('T')[1].slice(0, 5);
        const [endHours, endMinute] = endTime.split(':')
        const [startHours, startMinute] = startTime.split(':');
        const duration = ( (Number(endHours) === 0 ? 24 : Number(endHours) ) * 60 + Number(endMinute)) - (Number(startHours) * 60 + Number(startMinute));

        return `${translate('с')} ${startTime} ${translate('до')} ${endTime} (${duration} ${translate('мин.')})`
    }

    function buttonOpen () {
        if (props.data.status === 0 || props.data.status === 2) {
            openModal('edit-lesson');
            props.setEditLesson(props.data);
        } else {
            openModal('spend-lesson');
            props.setPopoverStyle({})
        }
    }

    function renderLessonIcon (type_lesson) {
        // eslint-disable-next-line default-case
        switch (type_lesson) {
            case 'Индивидуальный' : return 'ion-person'
            case 'Групповой' : return 'ion-person-stalker'
            case 'Пробный' : return 'ion-asterisk'
        }
    }

    function spendLesson () {
        document.getElementById('spend-lesson').style.display = 'block';
        props.setPopoverStyle({})
    }
    // POPOVER FUNC END

    // REQUEST START
    async function changeStatus (e, status) {
        e.preventDefault();

        e.currentTarget.disabled = true;

        if (status === 0) {

            await request(`${URL.back_end}/lesson/${props.data.lesson_id}`, 'PUT', { status : status, commission : null })
            await request(`${URL.back_end}/lesson_customer_data/${props.data.lesson_id}`, 'PUT', { commission : null, mark : null, reason : null, presence : null, note : null, debt : null, mark_homework : null })

            if (props.data.group.length) {
                let customerGroup = [];
                const lessonCustomer = props.data.customer.map(item => item.customer_id);

                for (let i = 0; i < props.data.group.length; i++) {
                    const response = await request(`${URL.back_end}/group_customer/${props.data.group[i].group_id}`, 'GET');
                    customerGroup = [...customerGroup, ...response];
                }

                customerGroup = customerGroup.filter(item => !lessonCustomer.includes(item.customer_id)).map(item => item.customer_id);

                await request(`${URL.back_end}/lesson_customer/${props.data.lesson_id}`, 'POST', customerGroup);
            }

            for (let j = 0; j < props.data.customer.length; j++) {
                const tariffs = await request(`${URL.back_end}/customer_tariff/${props.data.customer[j].customer_id}`, 'GET');

                let curr_tariff = tariffs.filter((item) => item.f_removed === 0).filter((item) => item.lessons ?
                    JSON.parse(item.lessons).includes(props.data.subject) &&
                    JSON.parse(item.type_lessons).includes(props.data.type_lesson) &&
                    new Date(item.date_start.split('T')[0]).getTime() <= new Date(props.data.date_start.split('T')[0]).getTime() &&
                    new Date(item.date_end.split('T')[0]).getTime() >= new Date(props.data.date_end.split('T')[0]).getTime()
                    : false);

                if (curr_tariff.length > 0) {
                    for (let i = 0; i < curr_tariff.length; i++) {
                        let balance = curr_tariff[i].balance + props.data.customer[j].commission;
                        await request(`${URL.back_end}/customer_tariff/${curr_tariff[i].id}`, 'PUT', { balance });
                    }
                } else {
                    let balance = props.data.customer[j].balance + props.data.customer[j].commission;
                    await request(`${URL.back_end}/customer/${props.data.customer[j].customer_id}`, 'PUT', { balance })
                }
            }
        } else {
            await request(`${URL.back_end}/lesson/${props.data.lesson_id}`, 'PUT', { status: status, edited : true })
        }

        await pushArchive(2, 'lesson', props.data.lesson_id, { status }, { status : props.data.status });

        window.location.reload();
    }
    // REQUEST END

    return (
        <div className={`popover fade ${props.side === undefined ? "top" : props.side} in`} role="tooltip" style={props.popoverStyle} ref={popover}>
            <div className="arrow" style={props.sideStyle} />
            <h3 className="popover-title">
                <i className={props.data.regular_id ? 'ion-pin' : 'fa fa-square-o'} />  {translate(typeLessons[props.data.type_lesson])} { getStatus(props.data.status) }
                <a className="pull-right crm-thin-link" style={{position: "absolute", right: "7px", fontSize: "20px", top: 0, color: "#676a6c"}} onClick={popoverClose}>
                    ×
                </a>
            </h3>
            <div className="popover-content">
                {/*TYPE LESSON*/}
                <dl className="row m-b-none m-t-xs">
                    <dt className="col-sm-5 text-muted">{translate('Тип')}</dt>
                    <dd className="col-sm-7">
                        <i className={`${renderLessonIcon(props.data.type_lesson)} text-muted`} /> {translate(typeLessons[props.data.type_lesson])}
                        <small className="pull-right" title={`${translate('Нормализован (сформировался из постоянного расписания)')}`}>
                            { translate(props.data.regular_id ? 'норм' : 'раз') }
                        </small>
                    </dd>
                    {/*TIME*/}
                    <dt className="col-sm-5 text-muted"> { translate('Время') } </dt>
                    <dd className="col-sm-7"> { getTime(props.data.date_start, props.data.date_end) } </dd>
                    {/*AUDIENCE*/}
                    {
                        lessonSetting ? findObject(lessonSetting.room, props.data.audience) ? (
                            <>
                                <dt className="col-sm-5 text-muted"> { translate('Аудитория') } </dt>
                                <dd className="col-sm-7">
                                    <i className="ion-location text-muted" /> { findObject(lessonSetting.room, props.data.audience).name }
                                </dd>
                            </>
                        ) : null : null
                    }
                    {/*EMPLOYEE*/}
                    {
                        props.data.employee?.length ?
                            (
                                <div>
                                    <dt className="col-sm-5 text-muted">{translate('Педагог(и)')}</dt>
                                    <dd className="col-sm-7">
                                        {
                                            props.data.employee.map(item => (
                                                <>
                                                    <a href={`/company/1/employee/view?id=${item.employee_id}`} target="_blank" className={item.f_removed === 1 ? 'text-line-through' : null}>
                                                        { item.name }
                                                    </a>
                                                    <br />
                                                </>
                                            ))
                                        }
                                    </dd>
                                </div>
                            )
                            : null
                    }
                    {/*SUBJECT*/}
                    <dt className="col-sm-5 text-muted">{translate('Предмет')}</dt>
                    <dd className="col-sm-7">{lessonSetting ? findObject(lessonSetting.subject, props.data.subject) ? findObject(lessonSetting.subject, props.data.subject).name  : "" : null}</dd>
                    {/*GROUP*/}
                    {
                        props.data.group?.length ?
                            (
                                <div>
                                    <dt className="col-sm-5 text-muted">{translate('Группы')}</dt>
                                    <dd className="col-sm-7">
                                        {
                                            props.data.group.map(item => (
                                                <>
                                                    <a href={`/company/1/group/view?id=${item.group_id}`} target="_blank" className={item.f_removed === 1 ? 'text-line-through' : null}>
                                                        {item.title}
                                                    </a>
                                                    <br />
                                                </>
                                            ))
                                        }
                                    </dd>
                                </div>
                            )
                            : null
                    }
                    {/*HOMEWORK*/}
                    {
                        props.data.homework ? (
                            <div>
                                <dt className="col-sm-5 text-muted"> { translate('Домашнее задание') } </dt>
                                <dd className="col-sm-7" style={{ overflowX: "hidden" }}> { props.data.homework } </dd>
                            </div>
                        ) : null
                    }
                    {/*NOTE*/}
                    {
                        props.data.l_note ? (
                            <div>
                                <dl className="row m-b-none m-t-none" style={{ border: "1px dashed lightblue", overflowX: "hidden" }}>
                                    <dt className="col-sm-5 text-muted"> { translate('Комментарий') } </dt>
                                    <dd className="col-sm-7"> { props.data.l_note } </dd>
                                </dl>
                            </div>
                        ) : null
                    }
                    {/*FILES*/}
                    {
                        props.data.storageFile?.length ? (
                            <div>
                                <dt className="col-sm-5 text-muted"> { translate('Файлы') } </dt>
                                <dd className="col-sm-7">
                                    <div className="m-b-none m-t-none">
                                        <FileList
                                            storageFile={props.data.storageFile}
                                            entity="lesson"
                                            id={props.data.lesson_id}
                                        />
                                    </div>
                                </dd>
                            </div>
                        ) : null
                    }
                </dl>
                <div className="row">
                    <div className="col-sm-12">
                        {
                            props.data.customer?.length ?
                                (
                                    <>
                                        <hr className="m-t-xs m-b-xs" />
                                        {
                                            props.data.customer.filter(item => props.data.status === 1 ? item.presence !== null : item.f_removed === 0).map((item, index) => (
                                                <>
                                                    { index + 1 }.&nbsp;
                                                    <a href={`/company/1/customer/view/?id=${item.customer_id}`} target="_blank" className="crm-dashed-link">
                                                        { item.presence ? <i className="ion-android-checkmark-circle m-r-xs" /> : null }
                                                        <span className={`customer-name ${item.f_removed === 1 ? 'text-line-through' : ''}`}>
                                                            { item.name }
                                                        </span>
                                                        {
                                                            (props.data.status === 1 && userData.role.interface === 1) && (
                                                                <span className="pull-right">
                                                                    { item.commission } { basicSetting && currency[basicSetting.baseOption.currency] }
                                                                </span>
                                                            )
                                                        }
                                                    </a>
                                                    {
                                                        (props.data.access === 3 && props.data.status === 1 && userData.systemId === item.customer_id) && (
                                                            <>
                                                                <span className="pull-right text-right">
                                                                    { translate('Оценка за урок / Причина') }: { item.presence ? findObject(lessonSetting?.mark, item.mark)?.ball : findObject(customerSetting?.reason, item.reason)?.name } <br />
                                                                    { translate('Оценка за ДЗ') }: { findObject(lessonSetting?.mark, item.mark_homework)?.ball } <br />
                                                                    { translate('Примечание') }: { item.note }
                                                                </span>
                                                                <br />
                                                            </>
                                                        )
                                                    }
                                                    <br />
                                                </>
                                            ))
                                        }
                                    </>
                                ) : null
                        }
                    </div>
                </div>
                {/*BUTTON*/}
                <hr className="m-b-xs m-t-xs" />
                {
                    props.data.access === 1 || props.data.access === 2 ?
                        (
                            <div className="row">
                                <div className="col-sm-4 p-xxs">
                                    <a className="btn btn-xs btn-white crm-modal-btn" style={{ width: '100%' }} onClick={() => buttonOpen()}>
                                        <i className="fa fa-edit" /> { translate('Открыть') }
                                    </a>
                                </div>
                                <div className="col-sm-4 p-xxs">
                                    <button type="button" className="btn btn-xs btn-white crm-modal-btn" style={{width: '100%'}} disabled={props.data.status !== 0} onClick={() => spendLesson()}>
                                        <i className="fa fa-check" /> { translate('Провести') }
                                    </button>
                                </div>
                                <div className="col-sm-4 p-xxs">
                                    {
                                        props.data.status === 0 ?
                                            <a className="btn btn-xs btn-white" style={{width: "100%"}} onClick={e => changeStatus(e, 2)}>
                                                <i className="fa fa-minus-circle" /> { translate('Отменить') }
                                            </a> :
                                            <button className="btn btn-xs btn-white" style={{width: "100%"}} onClick={e => changeStatus(e, 0)}>
                                                <i className="fa fa-reply" /> { translate('Вернуть') }
                                            </button>
                                    }
                                </div>
                            </div>
                        ) : null
                        // : (
                        //     <div className="row">
                        //         <div className="col-sm-4 p-xxs">
                        //             <label htmlFor="sendHomework">
                        //                 { translate('Отправить д/з') }
                        //             </label>
                        //             <input
                        //                 type="file"
                        //                 id="sendHomework"
                        //             />
                        //         </div>
                        //     </div>
                        // )
                }
            </div>
        </div>
    )
}