import {closeModal, filterDisable} from "../../Function/common";
import {useContext, useRef, useState} from "react";
import {SettingContext} from "../../Context/SettingContext";
import {InputChooseSingleObject} from "../InputChoose/InputChooseSingleObject";
import {useHttp} from "../../Hooks/http.hook";
import URL from "../../Api/URL.json";
import {useTranslate} from "../../Hooks/translate.hook";

// TOAST
import {success} from "../../Function/Notify";
import 'react-toastify/dist/ReactToastify.css';
import {useArchive} from "../../Hooks/archive.hook";

export default function ArchiveModal (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customerSetting, salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [ refusal, setRefusal ] = useState({});
    // STATE END

    // ARCHIVE START
    async function archive (e) {
        e.preventDefault();

        const customer = { refusal: refusal.id, date_a: new Date().toISOString(), f_removed: 1 }

        await request(`${URL.back_end}/customer/${props.customer_id}`, 'PUT', customer);

        await pushArchive(3, 'customer', props.customer_id, customer, { refusal : null, f_removed : 0 } );

        if (error) return console.log(error);

        if (props.page) {
            let update_customer = Object.assign({}, props.sql);
            update_customer.refusal = JSON.stringify(refusal);
            update_customer.f_removed = 1;
            props.updateSql(update_customer);
        } else if (props.lead) {
            let temp = props.sql.filter(item => item.customer_id !== props.customer_id)
            props.updateSql([...temp]);
        } else {
            props.sql.forEach(function (item, i) {
                if (props.customer_id === item.customer_id) {
                    props.sql[i].f_removed = 1;
                    props.updateSql([...props.sql]);
                }
            })
        }

        closeModal(e, modal);

        success( translate('Клиент помещен в архив') )
    }
    // ARCHIVE END

    return (
        <div id="archive-modal" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => archive(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('В архив') } { props.name ? ` — ${props.name} ` : null } </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*WARNING*/}
                            <p className="p-xs bg-warning m-b-md">
                                { translate('Вы можете указать причину отказа. По причинам отказов можно сформировать отчет и контролировать отток.') }
                            </p>
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Причина отказа') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    {
                                        props.lead ? (
                                            <InputChooseSingleObject
                                                sql={salesSetting ? filterDisable(salesSetting.leadReject) : []}
                                                setProps={setRefusal}
                                                topicSearch="name"
                                                type_id="id"
                                            />
                                        ) : (
                                            <InputChooseSingleObject
                                                sql={customerSetting ? filterDisable(customerSetting.refusal) : []}
                                                setProps={setRefusal}
                                                topicSearch="name"
                                                type_id="id"
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-danger" disabled={Object.keys(refusal).length === 0}>
                                <i className="fa fa-trash" /> { translate('В архив') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}