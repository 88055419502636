import {memo, useEffect, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import moment from "moment";
import 'moment-timezone';
import "../../../Assets/css/Agile.css";
import $ from 'jquery';
import {Link, useNavigate} from "react-router-dom";
import {errorNotify} from "../../../Function/Notify";

function drag (navigate) {
    $('.sortable-list').sortable({
        revert: 100,
        connectWith: ".sortable-list",
        distance: 50,
        tolerance: "pointer",
        items: "li:not(.empty-list)",
        update : function (event, ui) {
            if (!(this === ui.item.parent()[0])) return

            const task_id = $(ui.item).data('id');
            const status = $(event.target).data('status')
            let date;

            // eslint-disable-next-line default-case
            switch (status) {
                case 1 : {
                    date = moment().tz('Europe/Kiev').utcOffset(0).toDate();
                    date.setDate(date.getDate()-1)
                    break;
                } case 2 : {
                    date = moment().tz('Europe/Kiev').utcOffset(0).toDate();
                    break;
                } case 3 : {
                    date = moment().tz('Europe/Kiev').utcOffset(0).toDate();
                    date.setDate(date.getDate()+1)
                    break;
                } case 4 : {
                    date = null;
                    break;
                } case 5 : {
                    date = moment().tz('Europe/Kiev').utcOffset(0).toDate();

                    const firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

                    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

                    date.setDate(lastDayOfMonth.getDate());

                    break;
                }
            }

            const domain = (window.location.hostname).split('.')[0];

            if (domain === "localhost" || domain === "ristcrm") return errorNotify(`Неизвестный домен`)

            $.ajax({
                url: `${URL.back_end}/task/${task_id}`,
                type: "PUT",
                headers: {
                    'Content-type' : 'application/json',
                    'domain' : domain
                },
                data: JSON.stringify({date : date ? date.toLocaleDateString('en-CA') : ''})
            })

        },
        receive: () => {
            navigate(0);
        }
    })
}

export const AgileBoard = memo(({ sql, setSelectedTask }) => {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [ userTask, setUserTask ] = useState([]);
    const [ customerTask, setCustomerTask ] = useState([]);
    const [ groupTask, setGroupTask ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {
            const users = await request(`${URL.back_end}/user_task`, 'GET');
            const customers = await request(`${URL.back_end}/customer_task`, 'GET');
            const groups = await request(`${URL.back_end}/group_task`, 'GET');

            if (error) throw error

            setUserTask(users);
            setCustomerTask(customers);
            setGroupTask(groups);
        }

        fetchData().catch(e => console.log(e))

        scrollXBlock();
        drag(navigate);

    }, [error, navigate, request])
    // INIT DATA END

    // HANDLER START
    function selectTaskHandler () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = [];

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(+element.value)
            }
        })

        setSelectedTask(tempSelection);
    }

    function scrollXBlock () {
        const container = document.querySelector('.scroll-block');
        let isDown = false;
        let startX;
        let scrollLeft;

        container.addEventListener('mousedown', (e) => {
            isDown = true;
            container.classList.add('active');
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;
        });

        container.addEventListener('mouseleave', () => {
            isDown = false;
            container.classList.remove('active');
        });

        container.addEventListener('mouseup', () => {
            isDown = false;
            container.classList.remove('active');
        });

        container.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = x - startX;
            container.scrollLeft = scrollLeft - walk;
        });
    }
    // HANDLER END

    // SORT FUNCTION START
    function sortTask (arr) {
        let overdue  = [];
        let today    = [];
        let tomorrow = [];
        let month    = [];
        let noLimit  = [];

        for (let i = 0; i < arr.length; i++) {
            const dateNow = moment().tz('Europe/Kiev').utcOffset(0).toDate();

            if (!arr[i].date) noLimit.push(arr[i]);

            const dateTask = new Date(arr[i].date);
            const [hours, minute] = arr[i].time.split(':');
            dateTask.setHours(+hours);
            dateTask.setMinutes(+minute);

            if (dateNow > dateTask) {
                overdue.push(arr[i]);
            } else if (dateNow < dateTask && dateNow.toLocaleDateString('en-CA') === dateTask.toLocaleDateString('en-CA')) {
                today.push(arr[i]);
            } else if (dateNow < dateTask) {
                dateNow.setDate(dateNow.getDate() + 1);

                if (dateNow.toLocaleDateString('en-CA') === dateTask.toLocaleDateString('en-CA')) {
                    tomorrow.push(arr[i])
                } else {
                    const firstDayOfNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1);

                    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

                    dateNow.setDate(lastDayOfMonth.getDate());

                    if (dateNow > dateTask) {
                        month.push(arr[i]);
                    }
                }
            }
        }

        return { overdue, today, tomorrow, month, noLimit }
    }
    // SORT FUNCTION END

    let { overdue, today, tomorrow, month, noLimit } = sortTask(sql);

    // RENDER FUNCTION START
    function renderList (arr, element, status) {

        function getTextClass (type) {
            // eslint-disable-next-line default-case
            switch (type) {
                case 'danger' : return 'text-muted';
                case 'info' : return 'text-success';
                case 'success' : return 'text-navy';
                case 'primary' : return 'text-muted';
            }
        }

        return (
            <ul className="sortable-list agile-list crm-table open no-padding ui-sortable" data-status={status}>
                {
                    !arr.length && (
                        <li className="text-center empty-list">
                            <span className="not-set"> { translate('нет задач') } </span>
                        </li>
                    )
                }
                {
                    arr.map(item => (
                        <li className={ `${element}-element no-padding draggable ui-sortable-handle` } data-id={item.task_id}>
                            <a title={ translate('Открыть карточку задачи') }>
                                <p className={`${getTextClass(element)} p-xxs no-margins gray-bg`} style={{ fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden",  textOverflow: "ellipsis" }}>
                                    <span className="pull-right text-muted font-bold m-l-sm"> { item.task_id } </span> { item.text.split('\n')[0] }
                                </p>
                            </a>
                            <label className="p-xxs no-margins font-noraml">
                                {/*<small className="task-text"> { item.text } </small>*/}
                                <small title={ translate('Исполнитель(и)') }>
                                    <span className="text-muted">{ translate('Исполнитель(и)') } : </span> { userTask.filter(user => user.task_id === item.task_id).map(item => item.name).join(', ') }
                                </small>
                                <div className="agile-detail">
                                    {
                                        customerTask.map(customer => {
                                            if (customer.task_id === item.task_id) {
                                                return (
                                                    <a href={`/company/1/customer/view?id=${customer.customer_id}`} target="_blank" className="no-padding m-t-xxs m-b-xxs m-r-xs" title={translate('Открыть карточку')}>
                                                        <small className="text-muted">
                                                            <i className="ion-person m-r-xxs" /> { customer.name }
                                                        </small>
                                                    </a>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        groupTask.map(group => {
                                            if (group.task_id === item.task_id) {
                                                return (
                                                    <a href={`/company/1/group/view?id=${group.group_id}`} target="_blank" className="no-padding m-t-xxs m-b-xxs m-r-xs" title={translate('Открыть карточку')}>
                                                        <small className="text-muted">
                                                            <i className="ion-person-stalker m-r-xxs" /> { group.title }
                                                        </small>
                                                    </a>
                                                )
                                            }
                                        })
                                    }
                                    <div className="m-t-xs" />
                                    {
                                        element !== 'primary' && (
                                            <small className={`${getTextClass(element)} m-r-md`} title={translate('Дата выполнения')}>
                                                <i className="ion-calendar" /> { item.date }
                                                <i className="ion-clock m-l-xs" /> { item.time }
                                            </small>
                                        )
                                    }
                                    <span className="pull-right">
                                        <input
                                            type="checkbox"
                                            className="kv-row-checkbox"
                                            name="selection[]"
                                            value={item.task_id}
                                            onClick={e => selectTaskHandler(e)}
                                        />
                                    </span>
                                </div>
                            </label>
                        </li>
                    ))
                }
            </ul>
        )
    }
    // RENDER FUNCTION END

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="tabs-container m-b-sm">
                    <ul className="nav nav-tabs">
                        <li className="active">
                            <Link to="/company/1/agile/index">
                                { translate('Текущие') }
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link to="/company/1/agile/index?TaskSearch%5Bf_assigned_id%5D=741">*/}
                        {/*        {translate('Я — исполнитель')}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Link to="/company/1/agile/index?TaskSearch%5Bf_user_id%5D=741">*/}
                        {/*        {translate('Я — автор')}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                    {/*TABLE VIEW*/}
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane active">
                            <div className="panel-body agile-board-body">
                                {/*<div className="text-muted text-right small" style={{ padding : "0 5px 7px 5px" }}>*/}
                                {/*    { translate('Сортировка') }*/}
                                {/*</div>*/}
                                <div className="row scroll-block">
                                    {/* OVERDUE */}
                                    <div className="col-sm-3">
                                        <h3 className="text-danger"> { translate('Просроченные') } — { overdue.length } </h3>
                                        { renderList(overdue, 'danger', 1) }
                                    </div>
                                    {/* TODAY */}
                                    <div className="col-sm-3">
                                        <h3 className="text-success"> { translate('На сегодня') } — { today.length } </h3>
                                        { renderList(today, 'info', 2) }
                                    </div>
                                    {/* TOMORROW */}
                                    <div className="col-sm-3">
                                        <h3 className="text-navy"> { translate('На завтра') } — { tomorrow.length } </h3>
                                        { renderList(tomorrow, 'success', 3) }
                                    </div>
                                    {/* WEEK */}
                                    <div className="col-sm-3">
                                        <h3 className="text-warning"> { translate('На месяц') } — { month.length } </h3>
                                        { renderList(month, 'warning', 5) }
                                    </div>
                                    {/* NO TIME LIMIT */}
                                    <div className="col-sm-3">
                                        <h3 className="text-muted"> { translate('Без срока') } — { noLimit.length } </h3>
                                        { renderList(noLimit, 'primary', 4) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})