import {Link} from "react-router-dom";
import TariffColumns from "./TariffColumns";
import {useTranslate} from "../../../Hooks/translate.hook";
import React from "react";

export default function TariffTable (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // TARIFF TABLE FUNC START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;
        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            props.updateRows(tempSelection);
        }
        else {
            selection.forEach(element => element.checked = false);
            props.updateRows([])
        }
    }

    function renderTbody () {

        if (props.loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (props.sql.length) {
            return props.sql.map(item => (
                <TariffColumns
                    data={item}
                    sql={props.sql}
                    customerTariff={props.customerTariff}
                    updateSql={props.updateSql}
                    updateRows={props.updateRows}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // TARIFF TABLE FUNC END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "5.98%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection_all"
                                onClick={e => selection_all(e)}
                            />
                        </th>
                        <th style={{width: "44.83%"}}>
                            <Link to="/company/1/tariff/index?sort=-name">
                                {translate('Название')}
                            </Link>
                        </th>
                        <th style={{width: "12.72%"}}>
                            <Link to="/company/1/tariff/index?sort=type">
                                {translate('Тарификация')}
                            </Link>
                        </th>
                        <th style={{width: "10.39%"}}>
                            <Link to="/company/1/tariff/index?sort=price">
                                {translate('Стоимость')}
                            </Link>
                        </th>
                        <th style={{width: "12.74%"}}>
                            <Link to="/company/1/tariff/index?sort=usedCount">
                                {translate('Используется')}
                            </Link>
                        </th>
                        <th style={{width: "9.92%"}}>
                            <Link to="/company/1/tariff/index?sort=added">
                                {translate('Добавлен')}
                            </Link>
                        </th>
                        <th style={{width: "3.42%"}}>
                            &nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody> { renderTbody() } </tbody>
            </table>
        </div>
    )
}