import {closeModal, maxId} from "../../../../../Function/common";
import {useContext, useRef, useState} from "react";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useHttp} from "../../../../../Hooks/http.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {errorNotify} from "../../../../../Function/Notify";
import URL from "../../../../../Api/URL.json";

export default function AddTelegram () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ name, setName ] = useState('');
    const [ welcome, setWelcome ] = useState('');
    const [ welcomeNew, setWelcomeNew ] = useState('');
    const [ token, setToken ] = useState('');
    const [ branch, setBranch ] = useState('');
    const [ messageNoWork, setMessageNoWork ] = useState('');
    const [ startWorkTime, setStartWorkTime ] = useState('00:00');
    const [ endWorkTime, setEndWorkTime ] = useState('23:59');
    // STATE END

    // ADD TELEGRAM START
    async function addTelegram (e) {
        e.preventDefault();

        let validity = {
            "Название" : !name.length,
            "Api Token" : token.length < 10,
            result : 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                validity.result += 1;
                errorNotify(`${translate('Необходимо заполнить')} "${key}"`)
            }
        }

        if (validity.result > 0) return

        let username;

        try {
            const response = await request(`https://api.telegram.org/bot${token}/getMe`)
            username = response.result.username;
        } catch (e) {
            return errorNotify(`${translate('Неверный')} Api Token`);
        }

        const newTelegramBot = {
            id : integration.telegram.length ? maxId(integration.telegram) + 1 : 1 ,
            name,
            token,
            username,
            welcome,
            welcomeNew,
            branch,
            messageNoWork,
            startWorkTime,
            endWorkTime,
            notification : false
        }

        integration.telegram = integration.telegram.length ? [ ...integration.telegram, newTelegramBot ] : [ newTelegramBot ];

        await request(`${URL.back_end}/setting`, 'PUT', {integration: JSON.stringify(integration)})

        if (error) return error;

        window.location.reload();
    }
    // ADD TELEGRAM END

    return (
        <div id="add-telegram" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addTelegram(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить телеграм')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            <p className="p-xs bg-warning m-b-md text-center">
                                {translate('Можно использовать такие параметры для поля')}
                                <strong> "{`${translate('Приветственное сообщение')}`}" </strong>
                                %username%, %first_name%, %break%
                            </p>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label"
                                           htmlFor="social-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={`${translate('Например')}, test_bot`}
                                      value={name}
                                      onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="social-access_token">Api Token</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={translate('Вставьте токен бота')}
                                      value={token}
                                      onChange={e => setToken(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> {translate('Приветственное сообщение')} </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={translate('Например, Привет %first_name%')}
                                      value={welcome}
                                      onChange={e => setWelcome(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label
                                      className="control-label"> {translate('Приветственное сообщение для новых пользователей')} </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={translate('Например, Привет %first_name%')}
                                      value={welcomeNew}
                                      onChange={e => setWelcomeNew(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Гілка
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      className="form-control"
                                      placeholder="Наприклад, 1"
                                      onChange={e => setBranch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='col-sm-4'>
                                    <label className='control-label'>
                                        Робочий час
                                    </label>
                                </div>
                                <div className='col-sm-4'>
                                    <input
                                      type='time'
                                      className='form-control'
                                      onChange={(event) => setStartWorkTime(event.target.value)}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <input
                                      type='time'
                                      className='form-control'
                                      onChange={(event) => setEndWorkTime(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Повідомлення не в робочий час
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      className="form-control"
                                      placeholder={translate('Например, Привет %first_name%')}
                                      onChange={e => setMessageNoWork(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-sm btn-w-m btn-white'
                                    onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type='submit' className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}