import {openModal} from "../../../../Function/common";
import QualificationRow from "../Row/QualificationRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useState} from "react";
import AddQualification from "../Modal/Qualification/AddQualification";
import EditQualification from "../Modal/Qualification/EditQualification";

export default function QualBlock ({ sqlCard, setSqlCard }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editQual, setEditQual ] = useState({});
    // STATE END

    return (
        <>
            <p className="no-padding m-b-xs m-t-xs">
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal("add-qualification")}>
                    <span className="text-lowercase font-noraml">{translate('Добавить')}</span>
                </a>
                <strong className="text-muted">{translate('Квалификация')}</strong>
            </p>
            {
                sqlCard.qualification ? JSON.parse(sqlCard.qualification).map(item => (
                        <QualificationRow
                            data={item}
                            setEditQualification={setEditQual}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({translate('не задано')})
                            </div>
                        </div>
                    </div>
            }

            {/*QUALIFICATION*/}
            <AddQualification
                sql={sqlCard}
                updateSql={setSqlCard}
            />

            <EditQualification
                sql={sqlCard}
                updateSql={setSqlCard}
                editQualification={editQual}
            />
        </>
    )
}