// HOOK
import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {useArchive} from "../../../Hooks/archive.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {useTranslate} from "../../../Hooks/translate.hook";

// COMPONENT
import InputChooseSingle from "../../../Components/InputChoose/InputChooseSingle";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import { ToastContainer } from 'react-toastify';

// PACKAGE
import InputMask from "react-input-mask";

// JSON
import URL from '../../../Api/URL.json';

// FUNCTION
import {filterDisable, filterDisableEntity, closeModal, getRequired, findRequired} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";

// CSS
import '../../../Assets/css/DataPicker.css'
import 'react-toastify/dist/ReactToastify.css';
import {validate} from "../../../Function/validateForm";

export default function AddGroup (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, customerSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [dateStart, setDateStart] = useState(new Date().toLocaleDateString());
    const [dateEnd, setDateEnd] = useState('');
    const [knowledge, setKnowledge] = useState('');
    const [employee, setEmployee] = useState([]);
    const [limit, setLimit] = useState('');
    const [status, setStatus] = useState('');
    const [enableDateEnd, setEnableDateEnd] = useState(true);
    // STATE END

    // VALID STATE START
    const [titleError, setTitleError] = useState('Необходимо заполнить «Название».');
    const [titleDirty, setTitleDirty] = useState(false);
    const [limitError, setLimitError] = useState('');
    // VALID STATE END

    // HANDLER START
    function titleHandler(e) {
        setTitle(e.target.value);
        if (e.target.value.length === 0) {
            setTitleError('Необходимо заполнить «Название».')
        } else {
            setTitleError('');
        }
    }
    function limitHandler(e) {
        setLimit(e.target.value);
        if (!+e.target.value) {
            setLimitError('Значение «Лимит участников» должно быть целым числом.');
        } else {
            setLimitError('');
        }
    }
    function checkboxHandler(e) {
        setEnableDateEnd(!e.target.checked);
        setDateEnd(null);
    }
    // HANDLE END

    // ADD GROUP START
    async function addGroup(e) {
        e.preventDefault();

        let validObj = {
            "Название" : !title.length,
            "Описание" : findRequired(customSetting.requiredField, "description", "group").require ? !description.length : null,
            "Конец обучения" : findRequired(customSetting.requiredField, "date_end", "group").require ? !dateEnd.length : null,
            "Уровень знаний" : findRequired(customSetting.requiredField, "knowledge", "group").require ? !knowledge.length : null,
            "Отв. педагог" : findRequired(customSetting.requiredField, "employee", "group").require ? !employee.length : null,
            "Лимит участников" : findRequired(customSetting.requiredField, "limit", "group").require ? !limit.length : null,
            "Статус" : findRequired(customSetting.requiredField, "status", "group").require ? !Object.keys(status).length : null
        }

        filterDisableEntity(customSetting.customField, "group").map(item => {
            validObj[item.name] = item.require ? !document.forms["add_group"].elements[`add-group-${item.systemName}`].value : null
        })

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        let group = {
            title,
            description,
            knowledge,
            limit,
            status: status.id,
            date_start: dateStart,
            date_end: dateEnd,
            f_removed: 0
        }

        filterDisableEntity(customSetting.customField, "group").map(item => {
            group[item.systemName] = document.forms["add_group"].elements[`add-group-${item.systemName}`].value;
        })

        const new_group = await request(`${URL.back_end}/group`, 'POST', group);
        await request(`${URL.back_end}/group_employee/${new_group.insertId}`, 'POST', employee.map(item => item.employee_id))
        await pushArchive(1, 'group', new_group.insertId, group, null);

        if (error) return console.log(error);

        window.location.reload();
    }
    // ADD GROUP END

    return (
        <div id={props.id ? props.id : "add-group"} className="modal in" ref={modal}>
            <ToastContainer />
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span>×</span>
                        </button>
                        <h4 className="modal-title"> {translate('Добавить группу')} </h4>
                    </div>
                    <form className="form-horizontal" name="add_group" onSubmit={e => addGroup(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*TITLE*/}
                            <div className={`form-group field-group-name required ${(titleError && titleDirty) ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="title">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        placeholder={translate('Например, Группа 1')}
                                        value={title}
                                        onChange={e => titleHandler(e)}
                                        onBlur={e => setTitleDirty(true)}
                                    />
                                    <div className="help-block">{(titleError && titleDirty) ? titleError : null}</div>
                                </div>
                            </div>
                            {/*DESCRIPTION*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "description", "group") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Описание')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Любое описание')}
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "date_end", "group") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="group-date">{translate('Период обучения')}</label>
                                </div>
                                <div className="col-sm-4" style={{paddingRight: "2px"}}>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <input type="checkbox" disabled={true} defaultChecked={true} />
                                            &nbsp;{translate('с')}&nbsp;
                                        </span>
                                        <InputMask
                                            type="text"
                                            className="form-control date-input"
                                            id="group-date"
                                            placeholder="__.__.____"
                                            mask="99.99.9999"
                                            autoComplete="off"
                                            value={dateStart}
                                            onChange={e => setDateStart(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4" style={{paddingLeft: "2px"}}>
                                    <div className="input-group">
                                        <label className="input-group-addon">
                                            <input type="checkbox" onChange={e => checkboxHandler(e)}/>
                                            <span className="text-lowercase">{translate('До')}</span>
                                        </label>
                                        <InputMask
                                            type="text"
                                            id="group-date"
                                            className="form-control date-input"
                                            placeholder="__.__.____"
                                            mask="99.99.9999"
                                            autoComplete="off"
                                            disabled={enableDateEnd}
                                            value={dateEnd}
                                            onChange={e => setDateEnd(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*KNOWLEDGE*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "knowledge", "group") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Уровень знаний')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingle
                                        data={customerSetting ? filterDisable(customerSetting.knowledge).map(item => item.name) : []}
                                        setProps={setKnowledge}
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "employee", "group") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Отв. педагог')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={props.employeeData.filter(item => item.f_removed === 0)}
                                        type_id="employee_id"
                                        topicSearch="name"
                                        setProps={setEmployee}
                                    />
                                </div>
                            </div>
                            {/*LIMIT*/}
                            <div className={`form-group ${limitError ? "has-error" : null} ${customSetting ? getRequired(customSetting.requiredField, "limit", "group") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="group-limit">{translate('Лимит участников')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, 30')}
                                        value={limit}
                                        onChange={e => limitHandler(e)}
                                    />
                                    <div className="help-block">{limitError ? limitError : null}</div>
                                </div>
                            </div>
                            {/*STATUS*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "status", "group") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Статус')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.groupStatus) : []}
                                        setProps={setStatus}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "group").map(item => (
                                        <div className={`form-group ${item.require && "required"}`}>
                                            <div className="col-sm-3">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-8">
                                                {typeField(item, "add-group-")}
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                            {/*BRANCH*/}
                            <div className="form-group hide">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Филиалы')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div style={{paddingTop: "5px"}}>
                                        <label className="branch font-noraml no-margins">
                                            <input type="checkbox" defaultChecked={true} />
                                            &nbsp;Duche Education
                                        </label>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}