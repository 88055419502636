import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {useTranslate} from "../../../Hooks/translate.hook";
import {memo} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import currency from "../../../Assets/data/JSON/currency.json";
import URL from "../../../Api/URL.json";
import {Link} from "react-router-dom";
import jwt_decode from 'jwt-decode';

export const Alert = memo((props) => {

    // CONTEXT START
    const { basicSetting } = useContext(SettingContext);
    const userData = jwt_decode( JSON.parse( localStorage.getItem('userData') ).token );
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ debtor, setDebtor ] = useState({ count : 0, balance : 0 });
    const [ noTariff, setNoTariff ] = useState(0);
    const [ endTariff, setEndTariff ] = useState(0);
    const [ free, setFree ] = useState(0);
    const [ lastLesson, setLastLesson ] = useState(0);
    const [ zeroLesson, setZeroLesson ] = useState(0);
    const [ favCustomer, setFavCustomer ] = useState(0);
    const [ duplicateContact, setDuplicateContact ] = useState(0);
    const [ lostLesson, setLostLesson ] = useState(0);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!basicSetting) return

        let { notification } = basicSetting;

        async function fetchData () {

            // NO TARIFF
            if (notification.tariff === '2') {
                const [ customers ] = await request(`${URL.back_end}/filter_customer_tariff`, 'POST', { noTariff : true })
                setNoTariff(customers.count);
            }

            // DEBTOR
            if (notification.debtor === '2') {
                const [ customers ] = await request(`${URL.back_end}/filter_customer_tariff`, 'POST', { debtor : true })
                setDebtor({ count : customers.count, balance : customers.balance })
            }

            // FREE
            if (notification.free === '1') {
                const customers = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit : true, count: true, data : { freeLesson : true } });
                setFree(customers.counts);
            }

            // LAST LESSON
            if (notification.lastLesson) {
                const { counts } = await request(`${URL.back_end}/get_last_lesson`, 'GET');
                setLastLesson(counts);
            }

            // DUPLICATE CONTACT
            if (notification.duplicate === '1') {
                const { counts } = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit : true, count: true, data : { duplicateContact : 1 } });
                setDuplicateContact(counts);
            }

            const { counts } = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit : true, count: true, data : { favorite : 1 } });
            setFavCustomer(counts);

            const dataEndTariff = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit: true, count: true, data : { endTariff : 1, f_removed: 0 } });
            setEndTariff(dataEndTariff.counts);

            const dataZeroLesson = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit: true, count: true, data : { zeroLesson : 1 } });
            setZeroLesson(dataZeroLesson.counts);
        }

        async function teacherFetchData () {
            const data = await request(`${URL.back_end}/alert_lost_lesson/${userData.systemId}`, 'GET');
            setLostLesson(data.count);
        }

        if (userData.role.interface === 2) {
            teacherFetchData().catch(e => console.log(e))
        } else {
            fetchData().catch(e => console.log(e))
        }

    }, [basicSetting])
    // INIT DATA END

    // FUNCTION START
    function renderBell (danger, warning) {

        if (danger) {
            return (
                <a className="flashing" data-toggle="dropdown">
                    <i className="fa fa-bell-o text-danger" />
                </a>
            )
        } else if (warning) {
            return (
                <a className="flashing" data-toggle="dropdown">
                    <i className="fa fa-bell-o text-warning" />
                </a>
            )
        } else {
            return (
                <a data-toggle="dropdown">
                    <i className="fa fa-bell-o" />
                </a>
            )
        }
    }
    // FUNCTION END

    return (
        <>
            { renderBell(debtor.count || endTariff || zeroLesson || lostLesson, noTariff || lastLesson || duplicateContact) }
            <ul className="dropdown-menu dropdown-left" style={{ right : '-180px' }}>
                {
                    lostLesson ? (
                      <li className="text-danger">
                          <Link to={`/company/1/lesson/index/${encodeURI(JSON.stringify({ lostLesson: 1 }))}`}>
                              <i className="ion-alert-circled" /> Не проведені заняття: {lostLesson}
                          </Link>
                      </li>
                    ) : null
                }
                {
                    debtor.count ? (
                        <li className="text-danger">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ debtor : '1' }))}`}>
                                <i className="ion-cash" /> { debtor.count } { translate('Должников') } ({ debtor.balance } { currency[basicSetting.baseOption.currency] })
                            </Link>
                        </li>
                    ) : null
                }
                {
                    zeroLesson ? (
                        <li className="text-danger">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ zeroLesson : '1' }))}`}>
                                <i className="ion-alert-circled" /> { translate('Осталось 0 уроков') } : { zeroLesson }
                            </Link>
                        </li>
                    ) : null
                }
                {
                    lastLesson ? (
                        <li className="text-warning">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ lastLesson : '1' }))}`}>
                                <i className="ion-alert-circled" /> { translate('Остался 1 урок') } : { lastLesson }
                            </Link>
                        </li>
                    ) : null
                }
                {
                    free ? (
                        <li className="text-success">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ freeLesson : '1' }))}`}>
                                <i className="ion-asterisk" /> { translate('С бесплатным уроком : ') } { free }
                            </Link>
                        </li>
                    ) : null
                }
                {
                    endTariff ? (
                        <li className="text-danger">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ endTariff : '1' }))}`}>
                                <i className="ion-pricetags" /> { endTariff } { translate('просроченных абонементов') }
                            </Link>
                        </li>
                    ) : null
                }
                {
                    noTariff ? (
                        <li className="text-warning">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ noTariff : '1' }))}`}>
                                <i className="ion-pricetags" /> { noTariff } { translate('клиентов без абонемента') }
                            </Link>
                        </li>
                    ) : null
                }
                {
                    duplicateContact ? (
                        <li className="text-warning">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ duplicateContact : '1' }))}`}>
                                <i className="ion-person" /> {translate('Дублирование клиентов')} : { duplicateContact }
                            </Link>
                        </li>
                    ) : null
                }
                {
                    favCustomer ? (
                        <li className="text-navy">
                            <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ favorite : '1' }))}`}>
                                <i className="fa fa-star-o" /> { translate('Отмеченных клиентов') } : { favCustomer }
                            </Link>
                        </li>
                    ) : null
                }
            </ul>
        </>
    )
})