import {useTranslate} from "../../Hooks/translate.hook";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../Context/SettingContext";

export default function SalaryWidget ({ employeeLesson }) {

    // CONTEXT START
    const { currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // VALUE START
    const months = ['Январь' , 'Февраль' , 'Март' , 'Апрель' , 'Май' , 'Июнь' , 'Июль' , 'Август' , 'Сентябрь' , 'Октябрь' , 'Ноябрь' , 'Декабрь'];
    const prevMonth = new Date().getMonth() - 1;
    // VALUE END

    // STATE START
    const [ firstMonth, setFirstMonth ] = useState([]);
    const [ secondMonth, setSecondMonth ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        setFirstMonth(employeeLesson.filter(item => new Date(item.date_start).getMonth() === prevMonth && item.status === 1))
        setSecondMonth(employeeLesson.filter(item => new Date(item.date_start).getMonth() === new Date().getMonth() && item.status === 1))

    }, [employeeLesson])
    // INIT DATA END

    // RENDER FUNC INFO START
    function mathTime (arr) {
        let count = 0;

        for (let i = 0; i < arr.length; i++) {
            let [ hourStart, minuteStart ] = (arr[i].date_start).split('T')[1].split(':');
            let [ hourEnd, minuteEnd ] = (arr[i].date_end).split('T')[1].split(':');
            count += (Number(hourEnd) * 60 + Number(minuteEnd)) - (Number(hourStart * 60 + Number(minuteStart)))
        }

        return count;
    }

    function renderFirstMonth () {

        let groups = firstMonth.filter(item => item.type_lesson === 2)
        let individuals = firstMonth.filter(item => item.type_lesson === 1)

        return (
            <>
                <div className="col-sm-12">
                    <div className="row m-b-sm">
                        <div className="col-xs-4 text-center">
                            <small className="text-muted"> { translate('Групповой') } </small>
                            <h4 className="no-margins font-noraml">
                                { groups.length } <span className="text-primary"> { translate('шт') } </span>
                            </h4>
                        </div>
                        <div className="col-xs-4 text-center">
                            <small className="text-muted"> { translate('Индивидуальный') } </small>
                            <h4 className="no-margins font-noraml">
                                { individuals.length } <span className="text-primary"> { translate('шт') } </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="row m-b-sm">
                        <div className="col-xs-4 text-center">
                            <small className="text-muted"> { translate('Групповой') } </small>
                            <h4 className="no-margins font-noraml">
                                { mathTime(groups) } <span className="text-primary"> { translate('мин') } </span>
                            </h4>
                        </div>
                        <div className="col-xs-4 text-center">
                            <small className="text-muted"> { translate('Индивидуальный') } </small>
                            <h4 className="no-margins font-noraml">
                                { mathTime(individuals) } <span className="text-primary"> { translate('мин') } </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function renderSecondMonth () {

        let groups = secondMonth.filter(item => item.type_lesson === 2)
        let individuals = secondMonth.filter(item => item.type_lesson === 1)

        return (
            <>
                <div className=" col-sm-12">
                    <div className="row m-b-sm">
                        <div className="col-xs-4 text-center">
                            <small className="text-muted"> { translate('Групповой') } </small>
                            <h4 className="no-margins font-noraml">
                                { groups.length } <span className="text-primary"> { translate('шт') } </span>
                            </h4>
                        </div>
                        <div className="col-xs-4 text-center">
                            <small className="text-muted"> { translate('Индивидуальный') } </small>
                            <h4 className="no-margins font-noraml">
                                { individuals.length } <span className="text-primary"> { translate('шт') } </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="row m-b-sm">
                        <div className="col-xs-4 text-center">
                            <small className="text-muted">{translate('Групповой')}</small>
                            <h4 className="no-margins font-noraml">
                                { mathTime(groups) } <span className="text-primary">{translate('мин')}</span>
                            </h4>
                        </div>
                        <div className="col-xs-4 text-center">
                            <small className="text-muted">{translate('Индивидуальный')}</small>
                            <h4 className="no-margins font-noraml">
                                { mathTime(individuals) } <span className="text-primary">{translate('мин')}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // RENDER FUNC INFO END

    return (
        <div className="ibox float-e-margins m-b-sm">
            <div className="ibox-content border-bottom">
                <div className="salary-widget">
                    <span className="pull-left"> {translate('Статистика')} </span>
                    <div className="row">
                        {/*MONTH*/}
                        <div className="col-sm-12">
                            <h3 className="text-right m-b-xs font-noraml m-r-md"> { translate(months[prevMonth]) } </h3>
                        </div>
                        {/*INFO*/}
                        { renderFirstMonth() }
                        {/*MONTH*/}
                        <div className="col-sm-12">
                            <h3 className="text-right m-b-xs font-noraml m-r-md"> { translate(months[new Date().getMonth()]) } </h3>
                        </div>
                        {/*INFO*/}
                        { renderSecondMonth() }
                    </div>
                </div>
            </div>
        </div>
    )
}