import InputMask from "react-input-mask";
import {InputChooseMultiArray} from "../Components/InputChoose/InputChooseMultiArray";
import {useContext} from "react";
import {SettingContext} from "../Context/SettingContext";
import currency from "../Assets/data/JSON/currency.json";

function typeField (data, prefix="") {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { basicSetting } = useContext(SettingContext);

    // eslint-disable-next-line default-case
    switch (data.type) {
        case '1' :
            return (
                <input
                    type="text"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '2' :
            return (
                <InputMask
                    type="text"
                    className="form-control"
                    mask="99.99.9999"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '3' :
            return (
                <InputMask
                    type="text"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    mask="99:99"
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '4' :
            return (
                <InputMask
                    type="text"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    mask="99.99.9999 99:99"
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '5' :
            return (
                <input
                    type="date"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '6' :
            return (
                <input
                    type="number"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = +e.target.value}
                    autoComplete="off"
                />
            )
        case '7' :
            return (
                <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '8' :
            return (
                <input
                    type="checkbox"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '9' :
            return (
                <select className="form-control" id={prefix+data.systemName} placeholder={data.placeholder} defaultValue={data.defaultValue} onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}>
                    <option value="">Не задано</option>
                    {
                        data.option.map(item => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>
            )
        case '10' :
            return (
                <input
                    type="email"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '11' :
            return (
                <input
                    type="text"
                    className="form-control"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '12' :
            return (
                <InputMask
                    type="text"
                    className="form-control"
                    mask="+99 (999) 999-99-99"
                    id={prefix+data.systemName}
                    placeholder={data.placeholder}
                    defaultValue={data.defaultValue}
                    onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                />
            )
        case '13' :
            return (
                <InputChooseMultiArray
                    data={data.option}
                    id={prefix+data.systemName}
                />
            )
        case '16' :
            return (
                <div className="input-group input-group-sm">
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id={prefix+data.systemName}
                        placeholder={data.placeholder}
                        defaultValue={data.defaultValue}
                        onChange={e => document.getElementById(prefix+data.systemName).value = e.target.value}
                    />
                    <span className="input-group-addon"> { basicSetting && currency[basicSetting.baseOption.currency] } </span>
                </div>
            )

    }
}

export {typeField}