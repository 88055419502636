import {Link} from "react-router-dom";
import iconOperation from "../../../Assets/data/JSON/iconOperation.json";
import operation from "../../../Assets/data/JSON/operation.json";
import {useContext} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {findObject} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import currency from "../../../Assets/data/JSON/currency.json";

export default function PayColumns (props) {

    // CONTEXT START
    const { lessonSetting, paySetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // COLUMN FUNC START
    function renderAppointmentCustomer () {

        if (props.data.bill === - 1) {
            return (
                <>
                    <Link to={`/company/1/customer/view?id=${ props.data.customer_id }`}>
                        <i className="ion-person" /> { props.customerData.filter(item => item.customer_id === props.data.customer_id)[0]?.name }
                    </Link> → { translate('Базовый счет') } { props.data.balance && `/ ${props.data.balance}` }
                </>
            )
        } else {
            const bill = props.tariffData.filter(item => item.id === props.data.bill && item.customer_id === props.data.customer_id)[0];

            const appointmentText = bill ?
                props.data.bill !== -1 ? `${bill.title} / ${JSON.parse(bill.lessons).map(item => findObject(lessonSetting.subject, +item)?.name).join('/')} ${props.data.balance ? `/ ${props.data.balance}` : ''} `
                    : bill.title : translate('Удаленный абонемент');

            return (
                <>
                    <Link to={`/company/1/customer/view?id=${ props.data.customer_id }`}>
                        <i className="ion-person" /> { props.customerData.filter(item => item.customer_id === props.data.customer_id)[0]?.name }
                    </Link> → { appointmentText }
                </>
            )
        }
    }

    function renderAppointmentEmployee () {
        return (
            <Link to={`/company/1/employee/view?id=${ props.data.employee_id }`}>
                <i className="ion-university" /> { props.employeeData.filter(item => item.employee_id === props.data.employee_id)[0]?.name }
            </Link>
        )
    }

    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = [];

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }
    // COLUMN FUNC END

    return (
        <tr className="w1">
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={props.data.pay_id}
                    onClick={getSelect}
                />
            </td>
            {/*DATE*/}
            <td> { props.data.c_date } </td>
            {/*TYPE OPERATION*/}
            <td>
                <i className={iconOperation[props.data.type_operation]}/> { translate(operation[props.data.type_operation]) } #{props.data.pay_id}
            </td>
            {/*AMOUNT*/}
            <td> { props.data.amount } { basicSetting && currency[basicSetting.baseOption.currency] } </td>
            {/*ACCOUNT*/}
            <td>
                { paySetting ? findObject(paySetting.account, props.data.account) ? findObject(paySetting.account, props.data.account).name : "Не знайдено" : null }
            </td>
            {/*ARTICLE*/}
            <td>
                { paySetting ? findObject(paySetting.article, props.data.article) ? findObject(paySetting.article, props.data.article).name : "Не знайдено" : null }
            </td>
            {/*APPOINTMENT*/}
            <td> { props.data.type_operation === 4 ? renderAppointmentEmployee() : renderAppointmentCustomer() } </td>
            {/*PAYER*/}
            <td> { props.data.payer } </td>
            {/*NOTE*/}
            <td> { props.data.note } </td>
        </tr>
    )
}