import {useCallback, useContext} from "react";
import en from "../Assets/locales/en.json";
import ua from "../Assets/locales/ua.json";
import {SettingContext} from "../Context/SettingContext";

export const useTranslate = () => {
    //
    // const { locale } = useContext(SettingContext);
    //
    // function getLocale () {
    //     // eslint-disable-next-line default-case
    //     switch (locale) {
    //         case 'ua' : return ua
    //         case 'en' : return en
    //     }
    // }
    //
    // const translate = useCallback((text) => {
    //     if (locale === null) return
    //     if (locale === "ru") return text;
    //     return getLocale()[text] || text;
    // }, [locale])
    //
    // return { translate }

    const lang = localStorage.getItem('i18n') ? localStorage.getItem('i18n') : 'ua';

    function getLocale () {
        // eslint-disable-next-line default-case
        switch (lang) {
            case 'ua':
                return ua;
            case 'en':
                return en;
            default:
                return ua;
        }
    }

    const translate = useCallback((text) => {
        if (lang === null) return text;
        else if (lang === 'ru') return text;

        return getLocale()[text] || text;
    }, [lang])

    return { translate }
}