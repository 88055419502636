import SideMenu from "../../../Components/SideMenu/SideMenu";
import $ from 'jquery';
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import { TotalBalanceBlock } from "./Block/TotalBalanceBlock";
import jwt_decode from "jwt-decode";
import URL from "../../../Api/URL.json";

// TOAST
import { ToastContainer } from 'react-toastify';
import {success} from "../../../Function/Notify";
import 'react-toastify/dist/ReactToastify.css';
import SpongeBob from "../../../Components/Table/SpongeBob";
import {PresenceBlock} from "./Block/PresenceBlock";
import ProfilePayBlock from "./Block/ProfilePayBlock";
import {useArchive} from "../../../Hooks/archive.hook";
import {
    findObject,
    getCurrentWeekDateRange,
    getDateParamsMonthDiapason,
    getDateYearRange,
    openMenu
} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";

function handler () {
    $("#user-change_password").change(function () {
        $("#user-password, #gen-new-pass").attr("disabled", !$(this).is(":checked"))
    })
}

export default function CustomerProfile () {

    // REF START
    const checkboxPassword = useRef();
    // REF END

    // CONTEXT START
    const userData = jwt_decode(localStorage.getItem('userData'));
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState(undefined);
    const [language, setLanguage] = useState('');
    const [subjectFilter, setSubjectFilter] = useState('');
    const [limitFilter, setLimitFilter] = useState(90);

       // DATA
    const [sqlCard, setSqlCard] = useState({});
    const [lessonData, setLessonData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [tariffData, setTariffData] = useState([]);
    const [payCounts, setPayCounts] = useState(0);
    // STATE END

    // INIT HANDLER START
    useEffect(() => {

        async function fetchData () {
            const [ data ] = await request(`${URL.back_end}/customer/${userData.systemId}`, 'GET');
            const user = await request(`${URL.back_end}/user/${userData.user_id}`, 'GET');
            const customerTariff = await request(`${URL.back_end}/customer_tariff/${userData.systemId}`, 'GET');
            const groups = await request(`${URL.back_end}/group`, 'GET');
            const employees = await request(`${URL.back_end}/employee`, 'GET');
            const customers = await request(`${URL.back_end}/customer`, 'GET');
            const pays = await request(`${URL.back_end}/pay_filter`, 'POST', { nolimit : true, data : { type_operation : 1, customer_id : userData.systemId } })

            if (error) throw error;

            setSqlCard(data);
            setGroupData(groups);
            setEmployeeData(employees);
            setCustomerData(customers);
            setTariffData(customerTariff);
            setPayCounts(pays.counts);

            setName(user[0].name);
            setGender(user[0].gender);
            setPhone(user[0].phone);
            setLanguage(user[0].interface_language);
        }

        fetchData().catch(e => console.log(e))
        handler()

    }, [])

    useEffect(() => {

        async function fetchData () {

            const { date_start, date_end } = getFilterDate(limitFilter);

            const params = {
                customer_id : userData.systemId,
                subject: subjectFilter.length ? Number(subjectFilter) : undefined,
                date_start,
                date_end
            }

            const lessons = await request(`${URL.back_end}/filter_customer_lesson`, 'POST', { ...params });

            setLessonData(lessons);
        }

        fetchData().catch(e => console.log(e))

    }, [limitFilter, subjectFilter])
    // INIT HANDLER END

    // FUNC START
    function getFilterDate (limit) {
        // eslint-disable-next-line default-case
        switch (limit) {
            case 7: return getCurrentWeekDateRange();
            case 30: return getDateParamsMonthDiapason(0, 1);
            case 90: return getDateParamsMonthDiapason(-1, 2);
            case 180: return getDateParamsMonthDiapason(-3, 3);
            case 360: return getDateYearRange();
        }
    }

    function showForm () {
        let form = document.getElementById("profile-form");
        form.style.display === "none" ? form.style.display = "block" : form.style.display = "none";
    }

    function infoGender (gender) {
        if (gender === 1) {
            return ( <><i className="ion-man" /> {translate('Мужчина')}</> )
        } else if (gender === 2) {
            return ( <><i className="ion-woman" /> {translate('Женщина')}</> )
        } else {
            return ( <span className="text-danger">{translate('Не задано')}</span> )
        }
    }
    // FUNC END

    // EDIT INFO START
    async function editInfo (e) {
        e.preventDefault();

        const allowPass = checkboxPassword.current.checked;

        const user = { name, gender, phone, password : allowPass ? password : undefined , interface_language : language }

        await request(`${URL.back_end}/user/${userData.user_id}`, 'PUT', user);
        await pushArchive(2, 'user', userData.user_id, user, sqlCard);

        if (error) return console.log(error);

        localStorage.setItem('i18n', language);

        window.location.reload();
    }
    // EDIT INFO END

    return (
        <div id="wrapper">
            <SideMenu page="customer" />
            <ToastContainer />
            <div id="page-wrapper" className="gray-bg">
                <div className="wrapper crm-content">
                    <div className="row">
                        <div className="col-md-8 col-sm-7">
                            <div className="ibox m-b-sm">
                                <div className="ibox-content border-bottom p-md">
                                    <div>
                                        <span className="pull-left m-r-sm">
                                            <a className="crm-avatar-btn" title="Загрузить фото">
                                                <img
                                                    className="img-thumbnail img-circle"
                                                    src="//cdn.alfacrm.com/images/empty-male.png"
                                                    style={{ width: '50px', height: '50px' }}
                                                />
                                            </a>
                                        </span>
                                        <h2 className="font-noraml no-margins no-padding"> { sqlCard.name } </h2>
                                    </div>
                                    <div className="clearfix m-b-sm" />
                                    <div className="m-b-md p-xs" style={{ userSelect: 'none' }}>
                                        <a className="crm-dashed-link text-uppercase font-bold pull-right" id="update-data" onClick={showForm}>
                                            <i className="ion-edit" /> {translate('Обновить информацию')}
                                        </a>
                                    </div>
                                    <form className="form-horizontal" id="profile-form" style={{ display: 'none' }} onSubmit={e => editInfo(e)}>
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label">
                                                    <input
                                                        type="checkbox"
                                                        id="user-change_password"
                                                        ref={checkboxPassword}
                                                    />
                                                    &nbsp;{translate('Сменить пароль')}
                                                </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    id="user-password"
                                                    className="new-pass form-control"
                                                    disabled="disabled"
                                                    placeholder={translate('Задайте новый пароль')}
                                                    autoComplete="off"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-success btn-w-m">
                                                <i className="fa fa-save" /> {translate('Сохранить')}
                                            </button>
                                        </div>
                                    </form>
                                    <div className="clearfix" />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="m-b-sm">
                                                { translate('Виджет посещений') }
                                                <small style={{ color: '#1D2129' }}> ({ lessonData.length } { translate('уроков') }) </small>
                                                <span className="dropdown pull-right font-noraml m-l-md" onClick={e => openMenu(e)}>
                                                        <a className="dropdown-toggle crm-thin-link">
                                                            <span className="text-muted sponge-bob-dd-limited">± {limitFilter} {translate('дней')}</span>
                                                            <small className="caret m-b-xs" />
                                                        </a>
                                                        <ul className="dropdown-menu" style={{ marginLeft: '-90px' }}>
                                                            <li onClick={() => setLimitFilter(7)}>
                                                                <a> ±7 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(30)}>
                                                                <a> ±30 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(90)}>
                                                                <a> ±90 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(180)}>
                                                                <a> ±180 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(360)}>
                                                                <a> ±360 {translate('дней')} </a>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                <span className="dropdown pull-right font-noraml m-l-md" onClick={e => openMenu(e)}>
                                                        <a className="dropdown-toggle crm-thin-link">
                                                            <span className="text-muted sponge-bob-dd-limited" style={{ textTransform: 'lowercase' }}>
                                                                {
                                                                    subjectFilter.length ?
                                                                        findObject(lessonSetting.subject, Number(subjectFilter))?.name :
                                                                        translate('Все')
                                                                }
                                                            </span>
                                                            <small className="caret m-b-xs" />
                                                        </a>
                                                        <ul className="dropdown-menu" style={{ marginLeft: '-90px' }}>
                                                            <li onClick={() => setSubjectFilter('')}>
                                                                <a> { translate('Все') } </a>
                                                            </li>
                                                            {
                                                                lessonSetting && lessonSetting.subject.map((item) => (
                                                                    <li onClick={() => setSubjectFilter(`${item.id}`)}>
                                                                        <a> { item.name } </a>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </span>
                                                <div className="visible-xs m-t-sm" />
                                            </h4>
                                            <div className="clearfix" />
                                            {/*SPONGE BOB TABLE*/}
                                            <SpongeBob
                                                lessonData={lessonData}
                                                sqlCard={sqlCard}
                                                groupData={groupData}
                                                employeeData={employeeData}
                                                customerData={customerData}
                                                tariffData={tariffData}
                                                access={userData.role.interface}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-md-5">
                            <div className="ibox m-b-sm">
                                <div className="ibox-content border-bottom p-md">
                                    {/* TOTAL BALANCE */}
                                    <TotalBalanceBlock
                                        sqlCard={sqlCard}
                                        tariffData={tariffData}
                                    />
                                    <PresenceBlock
                                        lessonData={lessonData}
                                    />
                                    <ProfilePayBlock
                                        payCounts={payCounts}
                                    />
                                    {/*<a className="btn btn-sm btn-white btn-block text-muted crm-modal-btn">*/}
                                    {/*    <i className="ion-card" /> { translate('Оплата картой online') }*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}