export default function ModalPhoto ({ photo }) {

    return (
        <div className="modal in" id="modal-photo">
            <div className="modal-dialog">
                <div className="modal-content" style={{ border : 0, borderRadius : 0, background : 0, boxShadow : 'none', padding : '20px' }} onClick={() => document.getElementById('modal-photo').style.display = 'none'}>
                    <img
                        src={ photo }
                        id="modal-photo-img"
                        alt="image"
                        className="full-media"
                        style={{ borderRadius : 0 }}
                    />
                </div>
            </div>
        </div>
    )
}