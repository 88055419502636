import {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import URL from "../../../../../Api/URL.json";
import {closeModal} from "../../../../../Function/common";
import "../../../../../Assets/css/SettingStyle/EditModal.css";
import {errorNotify} from "../../../../../Function/Notify";

export default function EditAccount (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {paySetting, basicSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [allowed, setAllowed] = useState([]);

    // DATA
    const [userData, setUserData] = useState([]);
    // STATE END

    // GET USER DATA START
    useEffect(() => {
        async function fetchData () {
            const user = await request(`${URL.back_end}/user`, 'GET');
            if (error) throw error;
            await setUserData(user);
        }
        fetchData().catch(e => console.log(e));
    }, [])
    // GET USER DATA END

    // INIT DATA START
    useEffect(() => {
        if (!props.editAccount) return
        setName(props.editAccount.name);
        setAllowed(props.editAccount.allowed);
    }, [props.editAccount])
    // INIT DATA END

    // EDIT ACCOUNT START
    async function editAccount (e) {
        e.preventDefault();

        let elems = document.querySelectorAll('#edit-allowed-user:checked');
        let allowed = [].map.call(elems, function (obj) {
            return obj.value;
        })

        let validity = {
            "Название" : !name.length,
            "Доступно для" : !allowed.length,
            result: 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                errorNotify(`Необходимо заполнить "${key}"`)
                validity.result += 1;
            }
        }

        if (validity.result > 0) return

        let account = {
            id: props.editAccount.id,
            branch : props.editAccount.branch,
            payment: props.editAccount.payment,
            name, allowed,
            disabled: props.editAccount.disabled
        }

        paySetting.account = paySetting.account.map(item => {
            if (item.id === props.editAccount.id) {
                return account;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        if (error) return error;
        window.location.reload();
    }
    // EDIT ACCOUNT END

    return (
        <div id="edit-account" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editAccount(e)}>
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> Править кассу (счет) </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*BRANCH*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payaccount-branch_id">{translate('Филиал')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <span className="form-control">{basicSetting ? basicSetting.baseOption.nameCompany : null}</span>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group field-payaccount-name required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payaccount-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Касса №1')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*ALLOWED*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payaccount-user_ids">{translate('Доступно для')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div id="payaccount-user_ids">
                                        {
                                            allowed ?
                                                userData.map(item => (
                                                    <>
                                                        <label>
                                                            <input type="checkbox" name="pay-account" id="edit-allowed-user" value={item.user_id} defaultChecked={allowed.includes(item.user_id.toString())} /> {item.name}
                                                        </label>
                                                        <br />
                                                    </>
                                                ))
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}