import {closeModal} from "../../../Function/common";
import React, {useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useNavigate} from "react-router-dom";

export default function FilterChat ({ managersData, value }) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [ manager, setManager ] = useState('');
    const [ notRead, setNotRead ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!value) {
            setManager('');
            setNotRead('');
            return;
        }

        setManager(value.manager);
        setNotRead(value.notRead);

    }, [ value ])
    // INIT DATA END

    // FILTER CHAT START
    function filterChat (e) {
        const data = {
            manager,
            notRead
        }

        const obj = Object.filter(data, (value) => value);

        if (!Object.keys(obj).length) {
            closeModal(e, modal);
            navigate('/company/1/chats/index/');
        } else {
            navigate(`/company/1/chats/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }
    }
    // FILTER CHAT END

    return (
        <div id="filter-chat" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterChat(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body modal-body-search p-md">
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Ответственный') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={manager} onChange={e => setManager(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={-1}> { translate('Без ответственного') } </option>
                                        {
                                            managersData.filter(item => item.f_removed === 0).map(item => (
                                                <option value={item.user_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Тільки непрочитані
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="checkbox"
                                        checked={notRead}
                                        onChange={e => setNotRead(e.target.checked ? '1' : '')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр и вернуться в таблицу') } onClick={e => { closeModal(e, modal); navigate('/company/1/chats/index/') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}