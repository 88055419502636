// HOOK
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";

// FUNCTION
import {openModal, switchToggle} from "../../../../Function/common";

// MODAL
import EditBranch from "./Modal/EditBranch";

// JSON
import URL from "../../../../Api/URL.json";

// JQUERY
import $ from 'jquery';
import 'jquery-ui-bundle';

function drag (basicSetting) {
    $('#studystatussearch-f_name').focus();

    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/basicSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'branch'}),
                success: function(data) {
                    basicSetting = data;
                }
            });
        }
    });
}

export default function Branch () {

    // CONTEXT START
    let { basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editBranch, setEditBranch ] = useState({});
    // STATE END

    // DRAG INIT START
    useEffect(() => {
        if (!basicSetting) return

        drag(basicSetting)
    }, [basicSetting])
    // DRAG INIT END

    // COMP FUNC START
    async function disableBranch (e, data) {
        basicSetting.branch = basicSetting.branch.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });

        switchToggle(e, data.disabled);

        await request(`${URL.back_end}/setting`, 'PUT', { basicSetting: JSON.stringify(basicSetting) });
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title"> { translate('Филиалы') } ({ basicSetting ? basicSetting.branch.length : 0 }) </h4>
            </div>
            <div className="modal-body p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-lg">
                    { translate(`Филиал — это город или область, в котором может быть несколько Локаций. У каждого филиала своя база клиентов, педагогов, задач, абонементов и т. д.`) }
                </p>
                {/*FORM*/}
                <form>
                    <div className="row m-b-lg">
                        <div className="col-sm-9">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={ translate('Фильтр по названию' )}
                                />
                                <span className="input-group-addon" style={{ cursor: 'pointer' }}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-block btn-success text-lowercase" onClick={() => openModal("add-branch")}>
                                <i className="ion-plus" /> { translate('Добавить') }
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                        {
                            basicSetting?.branch.map(item => (
                                <tr className="sortable-item ui-sortable-handle" style={{ opacity: `${item.disabled ? "0.5" : "1"}` }} data-id={item.id}>
                                    <td>
                                        <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{ opacity: 0.5 }} />
                                        <strong>
                                            <span className="text-muted m-l-sm">#{ item.id }</span> { item.name }
                                        </strong>
                                    </td>
                                    <td className="text-right">
                                        <a className="m-r-xs" title={ translate('Сделать неактивным') } onClick={e => disableBranch(e, item)}>
                                            <i className={`fa fa-lg ${item.disabled ? "fa-toggle-off text-success" : "fa-toggle-on text-navy"} fa-fw`} />
                                        </a>
                                        <a className="btn btn-sm btn-white" onClick={() => { setEditBranch(item); openModal('edit-branch') }}>
                                            <i className="fa fa-pencil text-success fa-fw" />
                                        </a>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <EditBranch
                editBranch={editBranch}
            />
        </>
    )
}