import {openMenu, openModal} from "../../../../Function/common";
import React, { useContext, useEffect, useState } from 'react';
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddPay from "../../../Pay/Modal/AddPay";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import { SettingContext } from '../../../../Context/SettingContext';
import currency from '../../../../Assets/data/JSON/currency.json';

export default function PayBlock ({ customerData, sqlCard }) {

    // CONTEXT START
    const { basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ typeOperation, setTypeOperation ] = useState();
    const [ pays, setPays ] = useState({});
    // STATE END

    useEffect(() => {

        if (!Object.keys(sqlCard).length) return

        async function fetchData () {

            const pays = await request(`${URL.back_end}/pay_filter`, 'POST', { nolimit : true, data : { customer_id : sqlCard.customer_id } })

            setPays(pays);
        }

        fetchData().catch(e => console.log(e))

    }, [sqlCard])

    // FUNC START
    function calculateIncome (pays) {
        let income = 0;

        if (!Object.keys(pays).length) return 0;

        for (let i = 0; i < pays.pays.length; i++) {
            if (pays.pays[i].type_operation === 1) {
                income += pays.pays[i].amount;
            } else if (pays.pays[i].type_operation === 3 && pays.pays[i].amount > 0) {
                income += pays.pays[i].amount;
            }
        }

        return income;
    }
    // FUNC END

    // HANDLER START
    function chooseTypeHandler (e, type) {
        e.preventDefault();

        setTypeOperation(type);
        openModal('add-cast-pay');
    }
    // HANDLER END

    return (
        <>
            <div className="row">
                <div className="col-xs-5 text-muted m-b-xs">
                    { translate('Платежи') } <a target="_blank" href={`/company/1/log/index/${ encodeURI( JSON.stringify( { newKey : 'customer', entity : 'pay', newValue : sqlCard.customer_id, newOperation : '='  } ) ) }`} rel="noreferrer"> <i className="fa fa-history text-navy"/> </a>
                </div>
                <div className="col-xs-7 m-b-xs text-right">
                    <a className="crm-dashed-link" target="_blank" href={`/company/1/pay/index/${ encodeURI( JSON.stringify( { customer_id : sqlCard.customer_id } ) ) }`} rel="noreferrer">
                        { pays.counts } { translate('шт') }. ({ calculateIncome(pays) } { basicSetting && currency[basicSetting.baseOption.currency] })
                    </a>
                    <span className="dropdown" onClick={e => openMenu(e)}>
                        <a className="dropdown-toggle text-success text-lowercase m-l-sm crm-dashed-link" title={ translate('Добавить платеж') }>
                            { translate('Добавить') }
                        </a>
                        <ul role="menu" className="dropdown-menu dropdown-left">
                            <li onClick={e => chooseTypeHandler(e, 1)}>
                                <a className="crm-modal-btn">
                                    <i className="ion-log-in" /> {translate('Доход')}
                                </a>
                            </li>
                            <li onClick={e => chooseTypeHandler(e, 2)}>
                                <a className="crm-modal-btn">
                                    <i className="ion-ios-minus-outline" /> {translate('Возврат средств')}
                                </a>
                            </li>
                            <li onClick={e => chooseTypeHandler(e, 3)}>
                                <a className="crm-modal-btn">
                                    <i className="ion-wand" /> {translate('Корректировка')}
                                </a>
                            </li>
                        </ul>
                    </span>
                </div>
            </div>

            <AddPay
                customerData={customerData}
                typeOperation={typeOperation}
                customer={sqlCard}
                id="add-cast-pay"
            />
        </>
    )
}