import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {findObject, openModal} from "../../../../Function/common";

export default function QualificationRow (props) {

    // CONTEXT START
    const { lessonSetting, employeeSetting } = useContext(SettingContext);
    // CONTEXT END

    // ROW FUNCTION START
    function editQualification () {
        props.setEditQualification(props.data);
        openModal('edit-qualification');
    }
    // ROW FUNCTION END

    return (
        <div className="row">
            <a className="col-sm-12 p-xxs crm-hover-block crm-modal-btn" onClick={editQualification}>
                <i className="ion-edit pull-right" />
                <strong className="text-success"> {lessonSetting ? findObject(lessonSetting.subject, props.data.subject) ? findObject(lessonSetting.subject, props.data.subject).name : "Не знайдено" : null} </strong>
                <small className="text-muted"> — {employeeSetting ? findObject(employeeSetting.qualification, props.data.knowledge) ? findObject(employeeSetting.qualification, props.data.knowledge).name : "Не знайдено" : null } </small>
            </a>
        </div>
    )
}