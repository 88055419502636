import {useHttp} from "../../../Hooks/http.hook";
import {useEffect, useState} from "react";
import URL from "../../../Api/URL.json";

export default function EmployeeActiveLost () {

    // HOOK START
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ data, setData ] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const [ response ] = await request(`${URL.back_end}/dashboard/employeeActiveLost`, 'GET');

            setData(response);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    return (
        <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
            <div className="dashboard-item">
                <label className="font-bold h4 text-center dashboard-item-head no-margins">
                    Аналітика  по викладачам
                </label>
                <div className="row">
                    <div className="col-sm-12 no-padding">
                        <div className="dashboard-chart" id="chart-6">
                            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
                                <thead>
                                <tr>
                                    <th> Притік </th>
                                    <th> Відтік </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> { data.active } </td>
                                        <td> { data.lost } </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}