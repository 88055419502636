import {useHttp} from "../../../../../Hooks/http.hook";
import {useContext, useEffect, useRef, useState} from "react";
import URL from '../../../../../Api/URL.json';
import {useArchive} from "../../../../../Hooks/archive.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {findObject, filterDisable, openModal} from "../../../../../Function/common";
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal} from "../../../../../Function/common";
import {success, errorNotify} from "../../../../../Function/Notify";
import {validate} from "../../../../../Function/validateForm";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";

export default function EditQualification (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, employeeSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ subject, setSubject ] = useState('');
    const [ knowledge, setKnowledge ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.editQualification).length) return

        setSubject(lessonSetting ? findObject(lessonSetting.subject, props.editQualification.subject) ? findObject(lessonSetting.subject, props.editQualification.subject) : {} : {});
        setKnowledge(props.editQualification.knowledge);
    }, [props.editQualification])
    // INIT DATA END

    // DELETE QUALIFICATION START
    function deleteHandler () {
        openModal('delete-qual-modal');
        openModal('delete-qual-overlay');
    }

    async function deleteQualification () {
        let arr_qualification = JSON.parse(props.sql.qualification);

        arr_qualification = arr_qualification.filter(item => {
            if (item.id !== props.editQualification.id) {
                return item;
            }
        })

        // INDEXING
        arr_qualification = arr_qualification.map((item,index) => {
            item.id = index;
            return item;
        })

        let data = { qualification: arr_qualification.length > 0 ? JSON.stringify(arr_qualification) : null }

        await request(`${URL.back_end}/employee/${props.sql.employee_id}`, 'PUT', data);

        if (error) return console.log(error);

        await pushArchive(3, "Qualification", props.sql.employee_id);

        window.location.reload();
    }
    // DELETE QUALIFICATION END

    // EDIT QUALIFICATION START
    async function editQualification (e) {

        e.preventDefault();

        let validObj = {
            "Предмет" : !Object.keys(subject).length,
            "Квалификация" : !Object.keys(knowledge).length
        }

        if (validate(validObj, translate)) return

        let arr_qualification = JSON.parse(props.sql.qualification);

        let data = {
            id: props.editQualification.id,
            subject: subject.id, knowledge: knowledge.id
        }

        arr_qualification = arr_qualification.map(item => {
            if (item.id === props.editQualification.id) {
                return data;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/employee/${props.sql.employee_id}`, 'PUT', {qualification: JSON.stringify(arr_qualification)});
        if (error) return console.log(error);
        await pushArchive(2, "Qualification", props.sql.employee_id, data, props.editQualification);

        let update_employee = { qualification: JSON.stringify(arr_qualification) }

        for (let key in props.sql) {
            if (key !== "qualification") {
                update_employee[key] = props.sql[key];
            }
        }

        props.updateSql(update_employee);
        modal.current.style.display = "none";
    }
    // EDIT QUALIFICATION END

    return (
        <div id="edit-qualification" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">{translate('Править квалификацию')}</h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => editQualification(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предмет')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? lessonSetting.subject.filter(item => item.disabled !== true) : []}
                                        type_id="id"
                                        topicSearch="name"
                                        setProps={setSubject}
                                        value={subject.name}
                                    />
                                </div>
                            </div>
                            {/*QUALIFICATION*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Квалификация')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <InputChooseSingleObject
                                        sql={employeeSetting ? filterDisable(employeeSetting.qualification) : []}
                                        setProps={setKnowledge}
                                        value={employeeSetting ? findObject(employeeSetting.qualification, knowledge) ? findObject(employeeSetting.qualification, knowledge).name : null : null}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-danger btn-outline" onClick={deleteHandler}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <DeleteModal
                id="delete-qual"
                handler={deleteQualification}
            />

        </div>
    )
}