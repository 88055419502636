import {useContext, useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal, filterDisable} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {useNavigate} from "react-router-dom";

export default function FilterEmployee (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { employeeSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function(obj, filtercheck) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [subjects, setSubjects] = useState('');
    const [qualification, setQual] = useState('');
    const [group, setGroup] = useState('');
    const [customer, setCustomer] = useState('');
    const [f_removed, setFremoved] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.value) {
            setName('');
            setContact('');
            setSubjects('');
            setQual('');
            setGroup('');
            setCustomer('');
            setFremoved('');
            return;
        }

        setName(props.value.name);
        setContact(props.value.contact);
        setSubjects(props.value.subjects);
        setQual(props.value.qualification);
        setGroup(props.value.group);
        setCustomer(props.value.customer);
        setFremoved(props.value.f_removed);
    }, [props.value])
    // INIT DATA END

    // FILTER EMPLOYEE START
    function filterEmployee (e) {
        e.preventDefault();

        let employee = {
            name,
            contact,
            subjects,
            qualification,
            group,
            customer,
            f_removed
        }

        const obj = Object.filter(employee, (value) => value);

        if (Object.keys(obj).length === 0) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/employee/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }
    }
    // FILTER EMPLOYEE END

    // FUNCTION START
    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div id="filter-employee" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterEmployee(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body modal-body-search p-md">
                            {/*NAME*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('ФИО') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('ФИО') }
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*SUBJECT*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Предмет') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={subjects} onChange={e => setSubjects(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            lessonSetting && filterDisable(lessonSetting.subject).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*QUALIFICATION*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Квалификация') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className='form-control' value={qualification} onChange={e => setQual(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            employeeSetting && filterDisable(employeeSetting.qualification).map(item => (
                                                <option value={`${item.id}`}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*GROUP*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Группа') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className='form-control' value={group} onChange={e => setGroup(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            sortArray(props.groupsData, 'title').map(item => (
                                                <option value={item.group_id}> { item.title } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* INDIVIDUAL */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Клиенты') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={customer} onChange={e => setCustomer(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            sortArray(props.customerData, 'name').map(item => (
                                                <option value={item.customer_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* ARCHIVE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Архив') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className='form-control' value={f_removed} onChange={e => setFremoved(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='0'> { translate('Активные') } </option>
                                        <option value='1'> { translate('Архивные') } </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            {/*RESET FILTER*/}
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр и вернуться в таблицу') } onClick={e => { closeModal(e, modal); navigate('/company/1/employee/index/') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            {/*CANCEL*/}
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            {/*ACCEPT*/}
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}