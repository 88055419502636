import {memo, useState} from "react";
import {openModal} from "../../../../Function/common";
import RegularLessonRow from "../Row/RegularLessonRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddRegularLesson from "../Modal/RegularLesson/AddRegularLesson";
import EditRegularLesson from "../Modal/RegularLesson/EditRegularLesson";

export const RegularLessonBlock = memo(function ({ sqlCard, regularLesson, employeeData, dataCustomer }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editRegularLesson, setEditRegularLesson ]  = useState({});
    // STATE END

    return (
        <>
            <a className="crm-dashed-link m-l-xl pull-right" onClick={() => openModal('add-regular-lesson')}>
                <span className="text-lowercase"> { translate('Добавить') } </span>
            </a>
            <h4 className="no-padding m-t-xs m-b-xs text-muted">
                { translate('Регулярные уроки') }
                {/*<div className="font-noraml m-t-xs"> {`${translate('Всего')} 0 ${translate('посещений')}`} </div>*/}
            </h4>
            {
                regularLesson.length ?
                    regularLesson.map(item => (
                        <RegularLessonRow
                            data={item}
                            employeeData={employeeData}
                            setEditRegularLesson={setEditRegularLesson}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({translate('не задано')})
                            </div>
                        </div>
                    </div>
            }

            {/*REGULAR LESSON*/}
            <AddRegularLesson
                sql={sqlCard}
                employeeData={employeeData}
                customerData={dataCustomer}
            />

            <EditRegularLesson
                sql={sqlCard}
                employeeData={employeeData}
                customerData={dataCustomer}
                editRegularLesson={editRegularLesson}
            />
        </>
    )
})