import '../../../../../Assets/css/UserModal.css';
import {useHttp} from "../../../../../Hooks/http.hook";
import {useEffect, useRef, useState} from "react";
import URL from "../../../../../Api/URL.json";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {getRole} from "../../../../../Function/RoleCommon";
import {closeModal} from "../../../../../Function/common";
import {useArchive} from "../../../../../Hooks/archive.hook";

export default function AddUser (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState(null);
    // STATE END

    // INIT USE EFFECT START
    useEffect(() => {
        setName(props.sql.name);
    }, [props.sql])
    // INIT USE EFFECT END

    // VALID STATE START
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    // VALID STATE END

    // HANDLER START
    function nameHandler(e) {
        setName(e.target.value);
        if (e.target.value.length === 0) {
            setNameError(`${translate('Необходимо заполнить')} «${translate('ФИО')}».`);
        } else {
            setNameError('');
        }
    }

    function emailHandler(e) {
        setEmail(e.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(e.target.value)) {
            setEmailError(`${translate('Неверный')} «E-mail»`);
        } else {
            setEmailError('');
        }
    }

    function passwordHandler(e) {
        setPassword(e.target.value);
        const re_number = /(?=.*[0-9]).*/;
        const re_letter = /(?=.*[^A-Za-z0-9]).*/;

        if (e.target.value.length <= 6) {
            setPasswordError(`${translate('Количество символов должно быть не меньше 6.')}`)
        } else {
            if (!re_number.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать хотя бы одну цифр.')}`)
            } else if (re_letter.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать только латинские буквы и цифры.')}`)
            } else {
                setPasswordError('');
            }
        }
    }

    function blurHandler(e) {
        // eslint-disable-next-line default-case
        switch (e.target.name) {
            case 'user-email':
                setEmailDirty(true)
                break;
            case 'user-password':
                setPasswordDirty(true)
                break;
        }
    }
    // HANDLER END

    // ADD USER START
    async function addUser (e) {
        e.preventDefault();

        const role = getRole(await request(`${URL.back_end}/role`, 'GET'), 2);

        const user = { name, email, password, role: role.role_id, systemId: props.sql.employee_id, f_removed: 0 }

        const new_user = await request(`${URL.back_end}/register`, "POST", user);

        if (new_user.message !== undefined) return setEmailError('Такой пользователь уже существует');

        if (error) return console.log(error);

        await pushArchive(1, 'user', new_user.user_id, { user_id : new_user.user_id, name, email, role : role.role_id, systemId: props.sql.employee_id, f_removed : 0}, null);

        window.location.reload();
    }
    // ADD USER END

    return (
        <div id="add-user" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addUser(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Предоставить доступ') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*NAME*/}
                            <div className={`form-group field-user-name required ${nameError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('ФИО') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={e => nameHandler(e)}
                                    />
                                    <div className="help-block">{nameError ? nameError : null}</div>
                                </div>
                            </div>
                            {/*EMAIL*/}
                            <div className={`form-group field-user-email required ${(emailError && emailDirty ? "has-error" : null)}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">E-mail</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="user-email"
                                        placeholder={`${translate('Например')}, email@mail.ua`}
                                        value={email}
                                        onChange={e => emailHandler(e)}
                                        onBlur={e => blurHandler(e)}
                                    />
                                    <div className="help-block">{(emailError && emailDirty) ? emailError : null}</div>
                                </div>
                            </div>
                            {/*PASSWORD*/}
                            <div className={`form-group required ${(passwordError && passwordDirty) ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Пароль') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="new-pass form-control"
                                        name="user-password"
                                        autoComplete="off"
                                        value={password}
                                        onChange={e => passwordHandler(e)}
                                        onBlur={e => blurHandler(e)}
                                    />
                                    <div className="help-block">{(passwordError && passwordDirty) ? passwordError : null}</div>
                                </div>
                            </div>
                            {/*STATUS*/}
                            <div className="form-group field-user-status">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Статус') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <select id="user-status" className="form-control" onChange={e => setStatus(e.target.value)}>
                                        <option value={1}> { translate('Активен') } </option>
                                        <option value={2}> { translate('Заблокирован') } </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit" disabled={nameError || passwordError || emailError}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}