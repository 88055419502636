import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {findObject} from "../../../../Function/common";
import React from "react";
import {openModal} from "../../../../Function/common";

export const QualificationRow = React.memo(function MemoQualRow ({ data, setEditQualification }) {

    // CONTEXT START
    const { customerSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    function editQualification() {
        setEditQualification(data);
        openModal('edit-qualification');
    }

    return (
        <div className="row">
            <div className="m-b-xs p-xxs crm-hover-block" onClick={editQualification} style={{cursor: "pointer"}}>
                <a className="crm-hover-block crm-modal-btn">
                    <i className="ion-edit pull-right" />
                    <strong className="text-success">{lessonSetting ? findObject(lessonSetting.subject, data.subject) ? findObject(lessonSetting.subject, data.subject).name : "Не знайдено" : null}</strong>
                    &nbsp;—&nbsp;
                    <span className="text-muted">{customerSetting ? findObject(customerSetting.knowledge, data.knowledge) ? findObject(customerSetting.knowledge, data.knowledge).name : "Не знайдено" : null} {data.note ? `(${data.note})` : null}</span>
                </a>
                <div className="clearfix" />
            </div>
        </div>
    )
}, (prevProps, nextProps) => prevProps.data === nextProps.data)