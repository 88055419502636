import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import URL from "../../../../Api/URL.json";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {openModal} from "../../../../Function/common";
import EditLocation from "./EditModal/EditLocation";
import EditRoom from "./EditModal/EditRoom";
import shortid from "shortid";
import $ from "jquery";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function dragLocation (setting) {
    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/lessonSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'location'}),
                success: function(data){
                    setting.lessonSetting = data;
                }
            });
        }
    });
}

function dragRoom (setting) {
    $('.sortable-list-room').sortable({
        connectWith: '.sortable-list-room',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable1-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable1-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/lessonSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'room'}),
                success: function(data){
                    setting.lessonSetting = data;
                }
            });
        }
    });
}

export default function RoomLesson () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const {lessonSetting, basicSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.lessonSetting) return
        dragLocation(setting)
        dragRoom(setting)
    }, [setting])
    // INIT DRAG END

    // STATE START
    const [editLocation, setEditLocation] = useState({});
    const [editRoom, setEditRoom] = useState({});
    const [deleteRoom, setDeleteRoom] = useState({});
    // STATE END

    // COMPONENT FUNCTION START
    async function disabledLocation (data) {
        lessonSetting.location = lessonSetting.location.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return console.log(error)
    }

    async function disabledRoom (data) {
        lessonSetting.room = lessonSetting.room.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        })
        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return console.log(error)
    }

    function deleteHandler (item) {
        setDeleteRoom(item);
        openModal('delete-room-lesson-overlay');
        openModal('delete-room-lesson-modal');
    }

    async function deleteRoomHandler () {
        lessonSetting.room = lessonSetting.room.filter(item => {
            if (item.id !== deleteRoom.id) {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})

        window.location.reload();
    }
    // COMPONENT FUNCTION END

    return (
        <>
            <div>
                <div className="modal-header">
                    <h4 className="modal-title">{translate('Локации')} {basicSetting ? basicSetting.baseOption.nameCompany : null} <span className="text-muted">({lessonSetting ? lessonSetting.location.length : null})</span></h4>
                </div>
                <div className="modal-body p-md">
                    {/*WARNING*/}
                    <p className="p-xs bg-warning m-b-md">
                        {translate('Локация — это территориальное подразделение компании в рамках одного филиала.')}
                    </p>
                    {/*FORM*/}
                    <form>
                        <div className="row m-b-md">
                            <div className="col-sm-9">
                                <div className="input-group input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control input-sm"
                                        placeholder={translate('Фильтр по названию')}
                                    />
                                    <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-location")}>
                                    <i className="ion-plus" /> {translate('Добавить')}
                                </a>
                            </div>
                        </div>
                    </form>
                    {/*TABLE*/}
                    <table className="table table-stripped table-condensed m-b-sm border-bottom">
                        <tbody className="sortable-list ui-sortable">
                        {
                            lessonSetting ?
                                lessonSetting.location.map(item => (
                                    <tr className="sortable-item ui-sortable-handle" style={{opacity: `${item.disabled ? "0.5" : "1"}`}} data-id={`${item.id}`} key={shortid.generate()}>
                                        <td className="col-sm-10">
                                            <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} />
                                            <small> {item.name} </small>
                                            <small className="text-muted m-r-sm"> [ID {item.id}] </small>
                                        </td>
                                        <td className="text-right col-sm-2">
                                            <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledLocation(item)}>
                                                <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={() => {openModal("edit-location"); setEditLocation(item)}}>
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <div className="modal-body p-md">
                    {/*TITLE*/}
                    <h4 className="modal-title m-b-md">
                        {translate('Аудитории')} {basicSetting ? basicSetting.baseOption.nameCompany : null} <span className="text-muted">({lessonSetting ? lessonSetting.room.length : null})</span>
                    </h4>
                    {/*WARNING*/}
                    <p className="p-xs bg-warning m-b-md">
                        {translate('Аудитория — это место, в котором могут проводиться уроки.')}
                    </p>
                    {/*FORM*/}
                    <form>
                        <div className="row m-b-md">
                            <div className="col-sm-9">
                                <div className="input-group input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control input-sm"
                                        placeholder={translate('Фильтр по названию')}
                                    />
                                    <span className="input-group-addon" style={{cursor: "pointer"}}>
                                        <i className="ion-search" />
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-room")}>
                                    <i className="ion-plus" /> {translate('Добавить')}
                                </a>
                            </div>
                        </div>
                    </form>
                    {/*TABLE*/}
                    <table className="table table-stripped table-condensed m-b-sm border-bottom">
                        <tbody className="sortable-list-room ui-sortable">
                        {
                            lessonSetting ?
                                lessonSetting.location.map(location => {

                                    let result_arr = [];
                                    let bool = false;

                                    lessonSetting.room.map(room => {
                                        if (location.id === +room.location) {
                                            bool = true;
                                            result_arr.push(
                                                <div className="border-bottom sortable1-item row ui-sortable-handle" style={{opacity: `${room.disabled ? "0.5" : "1"}`}} data-id={`${room.id}`} key={shortid.generate()}>
                                                    <div className="col-xs-9" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                        <i className="ion-arrow-swap fa fa-rotate-90 text-muted m-r-xs" style={{opacity: 0.5}} />
                                                        <small className="m-r-xs" style={{ marginBottom: "2px", border: `1px solid ${room.color}`, borderBottom: `5px solid ${room.color}` }}> {room.name} </small>
                                                        <small className="text-muted m-r-sm"> [ID {room.id}] </small>
                                                    </div>
                                                    <div className="col-xs-3 text-right" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                        <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledRoom(room)}>
                                                            <i className={`fa ${room.disabled ? "" : "text-navy"} fa-lg fa-toggle-${room.disabled ? "off" : "on"} fa-fw`} />
                                                        </a>
                                                        <a className="btn btn-xs btn-white" onClick={() => {openModal("edit-room"); setEditRoom(room)}}>
                                                            <i className="fa fa-pencil text-success fa-fw m-r-xxs" />
                                                        </a>
                                                        <a className="btn btn-xs btn-white" onClick={() => deleteHandler(room)}>
                                                            <i className="fa fa-trash-o text-danger fa-fw" />
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })

                                    if (bool) {
                                        return (
                                            <>
                                                <div className="border-bottom" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                                                    <p className="text-navy font-bold no-padding no-margins"> ••• {basicSetting ? basicSetting.baseOption.nameCompany : null} / {location.name} </p>
                                                </div>
                                                <div className="sortable1-list-room ui-sortable">
                                                    {
                                                        result_arr.map(item => <div key={shortid.generate()}>{item}</div>)
                                                    }
                                                </div>
                                            </>
                                        )
                                    }

                                })
                                : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <EditLocation editLocation={editLocation} />
            <EditRoom editRoom={editRoom} />
            <DeleteModal
                id="delete-room-lesson"
                handler={deleteRoomHandler}
            />
        </>
    )
}