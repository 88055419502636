import {Link, useParams, useSearchParams} from "react-router-dom";

// COMPONENT
import PayTable from "./Table/PayTable";
import SideMenu from "../../Components/SideMenu/SideMenu";

// HOOK
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import {useArchive} from "../../Hooks/archive.hook";
import {useTranslate} from "../../Hooks/translate.hook";

// MODAL
import AddPay from "./Modal/AddPay";
import EditPay from "./Modal/EditPay";
import FilterPay from "./Modal/FilterPay";
import AcceptModal from "../../Components/Modal/AcceptModal";
import { ToastContainer } from 'react-toastify';

// JSON
import URL from "../../Api/URL.json";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";

// CONTEXT
import {SettingContext} from "../../Context/SettingContext";
import {AuthContext} from "../../Context/AuthContext";

// FUNCTION
import {filterDisable, openModal, pagination} from "../../Function/common";
import jwt_decode from "jwt-decode";
import TabHeader from "../../Components/TabHeader";
import TabAction from "../../Components/TabAction";
import AddPayoutSalary from "./Modal/AddPayoutSalary";

export default function Pay () {

    // CONTEXT START
    const { paySetting, pageSize } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [sql, setSql] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [typeOperation, setTypeOperation] = useState('');
    const [editPay, setEditPay] = useState({});

        // DATA
    const [counts, setCounts] = useState();
    const [customerData, setCustomerData] = useState([]);
    const [managerData, setManagerData] = useState([]);
    const [tariffData, setTariffData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [loading, setLoading] = useState(true);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!auth.role.interface || !pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            let data;

            if (auth.role.interface === 1) {
                data = await request(`${URL.back_end}/pay_filter`, 'POST', { page, limit : pageSize.pay, data : { ...params }});
            } else if (auth.role.interface === 3) {
                const customer_id = +jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;
                data = await request(`${URL.back_end}/pay_filter`, 'POST', { page, limit : pageSize.pay, data : { ...params, customer_id }});
            }

            if (error) throw error;

            setSql(data.pays);
            setCounts(data.counts);
            setLoading(false);
        }
        fetchData().catch(e => console.log(e))
    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {

            const customers = await request(`${URL.back_end}/customer`, 'GET');
            const managers = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } });
            const tariffs = await request(`${URL.back_end}/customer_tariff`, 'GET');
            const { employees } = await request(`${URL.back_end}/employee_filter`, 'POST', { nolimit : true, data : { f_removed : 0 } })

            if (error) throw error

            setCustomerData(customers);
            setManagerData(managers.users);
            setTariffData(tariffs);
            setEmployeeData(employees);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // BUTTON FUNC START
    function handlerTypeOperation (e, modal, type_operation) {
        e.preventDefault();
        setTypeOperation(type_operation);
        openModal(modal);
    }

    function buttonEdit () {
        const editObj = sql.filter(item => item.pay_id === +selectedRows[0])[0];

        if (editObj.type_operation !== 4) {
            openModal('edit-pay');
            setEditPay(editObj);
        }
    }
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="pay" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: '624px'}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions" style={ auth.role.interface === 3 ? { display : 'none' } : {} }>
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*{*/}
                                {/*    auth.role.interface === 1 &&*/}
                                {/*        <div className="btn-group crm-view-change m-r-xs">*/}
                                {/*            <Link to="/company/1/pay/index" className="btn btn-sm btn-white active" title={translate('Платежи')}>*/}
                                {/*                <i className="ion-cash" />*/}
                                {/*            </Link>*/}
                                {/*            <Link to="/company/1/bill/index" className="btn btn-sm btn-white" title={translate('Счёта')}>*/}
                                {/*                <i className="ion-pricetag" />*/}
                                {/*            </Link>*/}
                                {/*        </div>*/}
                                {/*}*/}
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="dropdown btn-group no-padding"
                                    icon="ion-plus"
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-success btn-w-m dropdown-toggle',
                                        text : 'Добавить',
                                        list : [
                                            { text : 'Доход', icon : 'ion-log-in', handler : e => handlerTypeOperation(e, 'add-pay', 1)},
                                            { text : 'Возврат средств', icon : 'ion-ios-minus-outline', handler : e => handlerTypeOperation(e, 'add-pay',2) },
                                            { text : 'Корректировка', icon : 'ion-wand', handler : e => handlerTypeOperation(e, 'add-pay',3) },
                                            { text : 'Выплата зарплаты', icon : 'ion-university', handler : e => handlerTypeOperation(e, 'add-payout-salary', 4) }
                                        ]
                                    }}
                                />

                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    handler={() => buttonEdit()}
                                    disable={selectedRows.length !== 1}
                                />

                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-printer"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-info btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'Оборотная ведомость', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Доход от реализации', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Доход от реализации по локациям', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Обороты по статьям', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Обороты по счетам (кассам)', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Отчет управляющего', icon : 'fa fa-file-text-o' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}

                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-printer"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : [*/}
                                {/*            { link : '/company/1/discount/index', text : 'Управление скидками', icon : 'fa fa-ticket' },*/}
                                {/*            { divider : true },*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' },*/}
                                {/*            { divider : true },*/}
                                {/*            { link : '#', text : 'Импорт из файла...', icon : 'fa fa-upload' },*/}
                                {/*            { link : '#', text : 'Экспорт в файл...', icon : 'fa fa-download' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}

                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    icon="fa fa-filter"
                                    text="Фильтр"
                                    handler={() => openModal('filter-pay')}
                                />
                                
                                <TabAction
                                    access={auth.role.interface === 3}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    icon="ion-card"
                                    text="Внести платеж"
                                />
                                <TabAction
                                    access={auth.role.interface === 3}
                                    className="btn btn-w-m btn-primary btn-sm"
                                    icon="fa fa-eye"
                                    text="Смотреть"
                                />
                                <TabAction
                                    access={auth.role.interface === 3}
                                    className="btn btn-sm btn-w-m btn-info crm-modal-btn"
                                    icon="fa fa-file-text-o"
                                    text="Прогноз"
                                />

                                {
                                    ( paySetting && filterParams ) &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    customer_id : translate('Клиент'),
                                                    dateStart : translate('Дата от'),
                                                    dateEnd : translate('Дата до'),
                                                    amountMin: translate('Сумма от'),
                                                    amountMax: translate('Сумма до'),
                                                    type_operation: translate('Тип операции'),
                                                    article: translate('Статья'),
                                                    account : translate('Аккаунт'),
                                                    manager: translate('Менеджер'),
                                                    type_payment: translate('Тип оплаты')
                                                }}
                                                meansData={{
                                                    customer_id : {
                                                        arr : customerData,
                                                        type_id : 'customer_id',
                                                        topic : 'name'
                                                    },
                                                    type_operation : {
                                                        arr : [ { id : 1, name : translate('Доход') }, { id : 2, name : translate('Возврат средств') }, { id : 3, name : translate('Корректировка') } ],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    article : {
                                                        arr : filterDisable(paySetting.article),
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    account : {
                                                        arr : filterDisable(paySetting.account),
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    manager : {
                                                        arr : managerData,
                                                        type_id : 'user_id',
                                                        topic : 'name'
                                                    }
                                                }}
                                                page="pay"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/* NAV TABS */}
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to={'/company/1/pay/index/'}> { translate('Все') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).type_operation === '1' ? 'active' : '' : null}>
                                        <Link to={`/company/1/pay/index/${encodeURI(JSON.stringify({ type_operation : '1' }))}`}> { translate('Доход') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).type_operation === '2' ? 'active' : '' : null}>
                                        <Link to={`/company/1/pay/index/${encodeURI(JSON.stringify({ type_operation : '2' }))}`}> { translate('Возврат средств') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).type_operation === '3' ? 'active' : '' : null}>
                                        <Link to={`/company/1/pay/index/${encodeURI(JSON.stringify({ type_operation : '3' }))}`}> { translate('Корректировка') } </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body" id="pay-table" style={{padding: "10px"}}>
                                            <div className="grid-view is-bs3 hide-resize">
                                                {/*TAB HEADER*/}
                                                <TabHeader
                                                    counts={counts}
                                                    entity='pay'
                                                />
                                                {/*TABLE BODY*/}
                                                <div className="border-bottom" />
                                                <PayTable
                                                    sql={sql}
                                                    customerData={customerData}
                                                    employeeData={employeeData}
                                                    tariffData={tariffData}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                    loading={loading}
                                                />
                                                <div className="text-center" translate="no">
                                                    {pageSize && pagination(counts, pageSize, 'pay', page)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <AddPay
                sql={sql}
                updateSql={setSql}
                customerData={customerData}
                managersData={managerData}
                typeOperation={typeOperation}
            />

            <AddPayoutSalary
                type_operation={typeOperation}
                employeeData={employeeData}
            />

            <EditPay
                sql={sql}
                updateSql={setSql}
                customerData={customerData}
                managersData={managerData}
                tariffData={tariffData}
                editPay={editPay}
            />

            <FilterPay
                customerData={customerData}
                managerData={managerData}
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
            />

            {/*ACCEPT BUTTON*/}
            <AcceptModal />
        </div>
    )
}