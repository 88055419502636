import {useEffect, useRef, useState} from "react";
import {closeModal, openModal} from "../../../Function/common";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import URL from "../../../Api/URL.json";
import {errorNotify, success} from "../../../Function/Notify";
import ComparisonModal from "./ComparisonModal";

export default function ImportModal ({ entity }) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ importFiles, setImportFiles ] = useState([]);
    const [ selectFile, setSelectFile ]   = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const files = await request(`${URL.back_end}/get-csv/${entity}`, 'GET');

            setImportFiles(files);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // SEND IMPORT FILE START
    async function sendImportFile (e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append('csv', e.target.files[0]);

        const response = await request(`${URL.back_end}/save-csv/${entity}`, 'POST', formData, {}, true);

        if (response.message) return errorNotify(response.message);

        const files = await request(`${URL.back_end}/get-csv/${entity}`, 'GET');

        setImportFiles(files);

        success(translate('Файл загружен'));
    }
    // SEND IMPORT FILE END

    return (
        <>
            <div id="import-modal" className="modal in" ref={modal}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content common-modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">
                                { translate('Импорт данных из файлов') } <span className="text-muted">({ importFiles.length })</span>
                            </h4>
                        </div>
                        <div className="modal-body p-sm">
                            <div className="p-xs bg-warning m-b-sm small">
                                <p className="m-b-none"> { translate('Принимаются файлы в формате CSV.') } </p>
                                <p className="m-b-xs m-t-xs">
                                    { translate('Импорт происходит в 3 этапа:') }
                                    <strong> 1 — { translate('подготовка и загрузка файла') }</strong>,
                                    <strong> 2 — { translate('формирование соответствия полей') }</strong>, { translate('и') }
                                    <strong> 3 — { translate('непосредственно импорт') }</strong>.
                                </p>
                            </div>

                            <p className="text-right m-b-xs" style={{ position : 'relative' }}>
                                <label htmlFor="importInput">
                                    <a className="crm-csv-btn crm-dashed-link m-l-sm" title="Загрузить файл" style={{ position: "relative", zIndex: 1 }}>
                                        { translate('Загрузить CSV') }
                                    </a>
                                </label>
                                <input
                                    type="file"
                                    multiple={true}
                                    accept=".csv"
                                    name="csv"
                                    id="importInput"
                                    style={{ display : 'none' }}
                                    onChange={e => sendImportFile(e)}
                                />
                            </p>
                            <div style={{ maxHeight : "450px", overflowY : "auto" }}>
                                <table className="table table-stripped table-condensed no-margins border-bottom">
                                    <tbody>
                                    {
                                        importFiles.length ? importFiles.map(item => (
                                            <tr>
                                                <td>
                                                    <small>
                                                        <i className="fa fa-file-text-o" /> { item.fileName }
                                                    </small>
                                                    <small className="text-muted m-l-xs text-lowercase"> ({ translate('Клиенты') }) </small>
                                                </td>
                                                <td>
                                                    <small>
                                                        { translate('Заголовки распознаны') } ({ item.columns.length }) →
                                                        <a className="crm-dashed-link" onClick={() => { setSelectFile(item); openModal('comparison-fields') }}> { translate('Задать соответствие полей') } </a>
                                                    </small>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="3"> { translate('Нет загруженных файлов') } </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ComparisonModal
                entity={entity}
                selectFile={selectFile}
                importFiles={importFiles}
                setImportFiles={setImportFiles}
            />
        </>
    )
}