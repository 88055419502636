function scrollTo(scrollDuration) {
    let scrollStep = -window.scrollY / (scrollDuration / 15),
        scrollInterval = setInterval(function(){
            if (window.scrollY !== 0 ) {
                window.scrollBy( 0, scrollStep );
            }

            else clearInterval(scrollInterval);
        },15);
}

function smoothScroll(container, target, duration) {
    let targetPosition = target.offsetTop - container.offsetTop;
    let startPosition = container.scrollTop;
    let distance = targetPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        let timeElapsed = currentTime - startTime;
        container.scrollTop = ease(timeElapsed, startPosition, distance, duration);
        if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
}

export { scrollTo, smoothScroll }