import { useState, useCallback } from "react";
import { errorNotify } from "../Function/Notify";

export const useHttp = () => {

    const [error, setError] = useState(null);

    const domain = (window.location.hostname).split('.')[0];

    const request = useCallback(async (url, method = 'POST', body = null, headers = {}, noheaders = false) => {

        if (body && !noheaders) {
            body = JSON.stringify(body);
            headers['Content-type'] = 'application/json';
        }

        headers['domain'] = domain;

        if (headers.domain === "localhost" || headers.domain === "ristcrm") return errorNotify(`Неизвестный домен`)

        try {
            const response = await fetch(url, {method, body, headers, mode: "cors"});
            const data = await response.json();

            if (!response.ok) throw new Error(data.message || 'Что-то пошло не так');

            return data;
        } catch (e) {
            setError(e.message);
            throw e;
        }

    }, [])

    const clearError = useCallback(() => setError(null), []);

    return {request, error, clearError}
}