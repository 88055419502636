import {useTranslate} from "../../../../Hooks/translate.hook";
import {findObject, openModal} from "../../../../Function/common";
import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import typeLessons from "../../../../Assets/data/JSON/typeLessons.json";

export default function RegularLessonRow (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    function daysOfWeek (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 0: return translate('Вс')
            case 1: return translate('Пн')
            case 2: return translate('Вт')
            case 3: return translate('Ср')
            case 4: return translate('Чт')
            case 5: return translate('Пт')
            case 6: return translate('Сб')
        }
    }

    function getEmployee (employee) {
        if (!employee) return translate('Не задано')
        let employee_ids = JSON.parse(employee);

        return props.employeeData.filter(item => employee_ids.includes(item.employee_id)).map(item => item.name).join(', ')
    }

    function editRegularLesson () {
        props.setEditRegularLesson(props.data);

        openModal('edit-regular-lesson');
    }

    return (
        <div className="row js-regular-lesson-list m-n">
            <div className="row crm-hover-block p-xxs">
                <div className="col-xs-12">
                    <a onClick={editRegularLesson}>
                        <big className="text-uppercase font-bold big text-success"> {daysOfWeek(props.data.dow)} </big>
                        <small className="font-bold text-success"> {props.data.time_start} — {props.data.time_end} </small>
                        <small className="text-success"><i className="ion-location m-l-xs" /> {findObject(lessonSetting.room, props.data.audience) ? findObject(lessonSetting.room, props.data.audience).name : translate('Не найдено')} </small>
                        <i className="ion-edit pull-right" />
                    </a>
                </div>
                <div className="col-xs-12 text-muted">
                    <small><i className="ion-ios-book"/> {findObject(lessonSetting.subject, props.data.subject) ? findObject(lessonSetting.subject, props.data.subject).name : translate('Не найдено')} </small>
                    <small> ({ translate(typeLessons[props.data.type_lesson]) }) </small>
                </div>
                <div className={`col-xs-12 text-muted ${props.data.employee ? "" : "hide"}`}>
                    <small>
                        <i className="ion-university"/> { getEmployee(props.data.employee) }
                    </small>
                </div>
                <div className="col-xs-12 text-muted">
                    <small className="text-lowercase">
                        <i className="ion-ios-calendar" /> {translate('действует')} {props.data.date_start ? `${translate('с')} ${props.data.date_start}` : ""} {props.data.date_end ? `${translate('до')} ${props.data.date_end}` : ""}
                    </small>
                </div>
            </div>
        </div>
    )
}