import {useEffect, useMemo, useState} from "react";
import UIMenu from "../../Widget/UIMenu";
import {useTranslate} from "../../../Hooks/translate.hook";
import { memo } from "react";
import {openMenu} from "../../../Function/common";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";

export const SearchBlock = memo(({ customerData }) => {

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [searchText, setSearchText] = useState('');
    const [filterChoose, setFilterChoose] = useState('ion-person');
    const [groupData, setGroupData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    // STATE END

    // VALUE START
    const filterArr = ['ion-person', 'ion-person-stalker', 'ion-university'];
    const filterMean = {
        'ion-person' : translate('Клиент'),
        'ion-person-stalker' : translate('Группа'),
        'ion-university' : translate('Педагог')
    }
    // VALUE END

    // INIT DATA START
    useEffect(() => {

        async function fetchGroups () {

            const groups = await request(`${URL.back_end}/group`, 'GET');

            setGroupData(groups);
        }

        async function fetchEmployees () {

            const employees = await request(`${URL.back_end}/employee`, 'GET');

            setEmployeeData(employees);
        }

        if (filterChoose === 'ion-person-stalker' && !groupData.length) {
            fetchGroups().catch(e => console.log(e))
        } else if (filterChoose === 'ion-university' && !employeeData.length) {
            fetchEmployees().catch(e => console.log(e))
        } else if (filterChoose === 'ion-person-stalker' && groupData.length) {
            return;
        } else if (filterChoose === 'ion-university' && employeeData.length) {
            return;
        }

    }, [filterChoose])
    // INIT DATA END

    // USE MEMO START
    const searchItems = useMemo(() => {
        if (searchText) {
            if (filterChoose === 'ion-person') {
                return customerData.filter(item => item.name ? item.name.toLowerCase().includes(searchText.toLowerCase()) : false);
            } else if (filterChoose === 'ion-person-stalker') {
                return groupData.filter(item => item.title ? item.title.toLowerCase().includes(searchText.toLowerCase()) : false);
            } else if (filterChoose === 'ion-university') {
                return employeeData.filter(item => item.name ? item.name.toLowerCase().includes(searchText.toLowerCase()) : false);
            }
        } else {
            return [];
        }
    },[customerData, searchText, filterChoose])
    // USE MEMO END

    return (
        <li className="pull-left crm-fulltext-search" style={{ display: 'flex' }}>
            <a className="btn-group" onClick={e => openMenu(e)}>
                <a className="dropdown-toggle text-muted"> <i className={filterChoose} /> </a>
                <ul className="dropdown-menu">
                    {
                        filterArr.map(item => (
                            <li key={item} onClick={() => setFilterChoose(item)}>
                                <a> <i className={item} /> { filterMean[item] } </a>
                            </li>
                        ))
                    }
                </ul>
            </a>
            <a style={{ paddingTop: "10px", paddingLeft: 0 }}>
                <input
                    type="text"
                    className="input-sm ui-autocomplete-input"
                    style={{ width: "150px", border: "none" }}
                    placeholder={ `${translate('Поиск')} (${filterMean[filterChoose]})` }
                    autoComplete="off"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                />

                <UIMenu
                    result={searchItems}
                    filterChoose={filterChoose}
                />
            </a>
        </li>
    )
});