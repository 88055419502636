import {useTranslate} from "../../../Hooks/translate.hook";
import shortid from "shortid";

export default function FeedElement (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function getAction (action) {
        // eslint-disable-next-line default-case
        switch (action) {
            case 1 : return translate('Добавил')
            case 2 : return translate('Редактировал')
            case 3 : return translate('Удалил')
            case 4 : return translate('Восстановил')
        }
    }

    function getDate (currentDate) {
        const [ date, time ] = currentDate.split('T');

        return (
            <small className="text-muted">
                <i className="ion-calendar" /> { date }
                <i className="ion-clock m-l-xs" /> { time.slice(0, 5) }
            </small>
        )
    }

    function meanKey (key, oldValue, newValue) {
        // eslint-disable-next-line default-case
        switch (key) {
            case 'f_removed' : {
                oldValue[key] = oldValue[key] === 1 ? translate('Архив') : translate('Актив');
                newValue[key] = newValue[key] === 1 ? translate('Архив') : translate('Актив');
                return;
            }
            case 'status' : {
                if (props.data.entity === 'lesson') {
                    oldValue[key] = oldValue[key] === 0 ? translate('запланирован') : oldValue[key] === 1 ? translate('проведен') : translate('отменен')
                    newValue[key] = newValue[key] === 0 ? translate('запланирован') : newValue[key] === 1 ? translate('проведен') : translate('отменен')
                    return;
                } else {
                    return;
                }
            }
        }
    }

    function renderField (newField, oldField) {

        function renderObject (object) {

            let array = ['JSON : { '];

            for (let key in object) {
                if (object[key]) {
                    array.push(`${key} : ${object[key]}`);
                    array.push(' \n');
                }
            }

            array.pop();
            array.push(' }')
            return array.join('');
        }

        return (
            <td className="col-lg-7" style={{ whiteSpace : 'break-spaces' }}>
                <small>{ oldField !== null && oldField !== undefined ? Array.isArray(oldField) ? oldField.map(item => typeof item === 'object' ? renderObject(item) : item).join(', ') : oldField : <span className="not-set">({ translate('пусто') })</span> }</small>
                &nbsp;→&nbsp;
                <small>{ newField !== null && newField !== undefined ? Array.isArray(newField) ? newField.map(item => typeof item === 'object' ? renderObject(item) : item).join(', ') : newField : <span className="not-set">({ translate('пусто') })</span> }</small>
            </td>
        )
    }

    function getField (newField, oldField) {

        let arr_field = [];

        for (let key in oldField) {
            if (!(oldField[key] === newField[key])) {

                if (!oldField[key] && !newField[key]) continue;

                meanKey(key, oldField, newField);

                arr_field.push(
                    <tr key={shortid.generate()}>
                        <td className="col-lg-5">
                            <small>
                                {/*Статус (<span className="text-muted">status</span>)*/}
                                <span className="text-muted"> { key } </span>
                            </small>
                        </td>
                        { renderField(newField[key], oldField[key]) }
                    </tr>
                )
            }
        }

        return arr_field;
    }

    return (
        <div className="feed-element">
            <div className="media-body">
                <small className="text-muted pull-right"> { props.user?.email } </small>
                { props.user?.name } # { props.user?.user_id }
                <span className="text-muted text-lowercase"> { getAction(props.data.action) } </span>
                { props.data.entity } # { props.data.id } <br />
                { getDate(props.data.c_date) }
                <table className="table table-sm table-bordered m-t-sm m-b-none">
                    <tbody>
                        { getField(JSON.parse(props.data.newField), JSON.parse(props.data.oldField)).map(item => item) }
                    </tbody>
                </table>
            </div>
        </div>
    )
}