import {openModal} from "../../../../Function/common";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import "../../../../Assets/css/PayArticle.css";
import iconOperation from "../../../../Assets/data/JSON/iconOperation.json";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditArticle from "./EditModal/EditArticle";
import $ from 'jquery';
import 'jquery-ui-bundle';
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/paySetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'article'}),
                success: function(data){
                    setting.paySetting = data;
                }
            });
        }
    });
}

export default function PayArticle () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const { basicSetting, paySetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.paySetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // STATE START
    const [editArticle, setEditArticle] = useState({});
    const [deleteArticle, setDeleteArticle] = useState({});
    // STATE END

    // COMP FUNC START
    async function disabledArticle (data) {
        paySetting.article = paySetting.article.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
    }

    function deleteHandler (item) {
        setDeleteArticle(item);
        openModal('delete-pay-article-overlay');
        openModal('delete-pay-article-modal');
    }

    async function deleteArticleHandler () {
        paySetting.article = paySetting.article.filter(item => {
            if (item.id !== deleteArticle.id) {
                return item;
            }
        });

        paySetting.category = paySetting.category.map(item => {
            if (item.id === deleteArticle.category) {
                item.article -= 1;
                return item;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})

        window.location.reload();
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{ basicSetting?.baseOption.nameCompany } — {translate('Статьи доходов и расходов')} <span className="text-muted">({paySetting ? paySetting.article.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-md">
                    {translate(
                        `Статьи применяются для дополнительного сегментирования доходов и расходов (например, Арендные
                    платежи, Выплата заработной платы, Реализация учебников и т.д.)`
                    )}
                </p>
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-block btn-sm btn-success crm-ajax-link text-lowercase" onClick={() => openModal("add-article")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <div className="border-bottom" />
                {
                    paySetting ?
                        paySetting.category.map(category => {

                            let result_arr = [];

                            paySetting.article.map(article => {
                                if (category.id === article.category) {
                                    result_arr.push(
                                        <div className="border-bottom sortable-item row row-item ui-sortable-handle" style={article.disabled ? {opacity: 0.5} : {}} data-id={`${article.id}`}>
                                            <div className="col-xs-8">
                                                <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {article.name}
                                                <small className="text-muted m-r-sm"> [ID {article.id}] </small>
                                                {
                                                    article.type_payment.map(item => (
                                                        <i className={`${iconOperation[item]} m-r-xs`} />
                                                    ))
                                                }
                                            </div>
                                            <div className="col-xs-1 text-right">
                                                <span className="text-muted m-r-sm" title={translate('Количество платежных документов')}>
                                                    {article.payment} <i className="fa fa-money" />
                                                </span>
                                            </div>
                                            <div className="col-xs-3 text-right">
                                                <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledArticle(article)}>
                                                    <i className={`fa ${article.disabled ? "" : "text-navy"} fa-lg fa-toggle-${article.disabled ? "off" : "on"} fa-fw`} />
                                                </a>
                                                <a className="btn btn-xs btn-white m-r-xxs" onClick={() => {openModal("edit-article"); setEditArticle(article)}}>
                                                    <i className="fa fa-pencil text-success fa-fw" />
                                                </a>
                                                <a className="btn btn-xs btn-white" onClick={() => deleteHandler(article)}>
                                                    <i className="fa fa-trash-o text-danger fa-fw" />
                                                </a>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            return (
                                <>
                                    <div className="border-bottom row-header">
                                        <p className="text-muted font-bold no-padding no-margins">{category.name}</p>
                                    </div>
                                    <div className="sortable-list ui-sortable">
                                        {
                                            result_arr.map(item => item)
                                        }
                                    </div>
                                </>
                            )
                        })
                        : null
                }
            </div>
            <EditArticle
                editArticle={editArticle}
            />
            <DeleteModal
                id="delete-pay-article"
                handler={deleteArticleHandler}
            />
        </>
    )
}