import React, {memo, useEffect, useRef, useState} from "react";
import RegularLessonRow from "../Row/RegularLessonRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddRegularLesson from "../Modal/RegularLesson/AddRegularLesson";
import EditRegularLesson from "../Modal/RegularLesson/EditRegularLesson";
import {openModal} from "../../../../Function/common";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export const RegularLessonBlock = memo(function ({ sqlCard, customerGroup, groupData, employeeData }) {

    // REF START
    const regular_lesson_container = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [type_lesson, setTypeLesson] = useState('');
    const [lessonGroup, setLessonGroup] = useState({});
    const [regularLesson, setRegularLesson] = useState([]);
    const [editRegularLesson, setEditRegularLesson] = useState({});
    const [editLessonGroup, setEditLessonGroup] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const regLesson = await request(`${URL.back_end}/filter_regular_lesson`, 'POST', { customer_id : sqlCard.customer_id })

            if (error) throw error

            setRegularLesson(regLesson);

        }

        fetchData().catch(e => console.log(e))

    }, [sqlCard])
    // INIT DATA END

    // FUNC START
    function addButton () {
        if (customerGroup.length) {
            regular_lesson_container.current.classList.toggle("open")
        } else {
            addRegularLesson(1)
        }
    }

    function addRegularLesson(type, group) {
        openModal('add-regular-lesson');

        setTypeLesson(type);

        if (group) {
            setLessonGroup(groupData.filter(item => item.group_id === group.group_id)[0]);
        } else {
            setLessonGroup(null);
        }
    }
    // FUNC END

    return (
        <>
            <div className="dropdown m-l-xl pull-right" ref={regular_lesson_container}>
                <a className="dropdown-toggle crm-dashed-link" title={ translate('Добавить') } onClick={addButton}>
                    <span className="text-lowercase">{ translate('Добавить') }</span>
                </a>
                <ul role="menu" className="dropdown-menu">
                    <li>
                        <a onClick={() => addRegularLesson(1)}>
                            { translate('Индивидуально') }
                        </a>
                    </li>
                    <li className="divider" />
                    {
                        customerGroup.length > 0 ?
                            customerGroup.map(item => (
                                <li>
                                    <a onClick={() => addRegularLesson(2, item)}>
                                        → { item.title }
                                    </a>
                                </li>
                            )) : null
                    }
                </ul>
            </div>
            <h4 className="no-padding m-t-xs m-b-xs text-muted"> {translate('Регулярные уроки')} </h4>
            {
                regularLesson.length ?
                    regularLesson.map(item => (
                        <RegularLessonRow
                            data={item}
                            groupData={groupData}
                            employeeData={employeeData}
                            setEditRegularLesson={setEditRegularLesson}
                            setEditLessonGroup={setEditLessonGroup}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({ translate('не задано') })
                            </div>
                        </div>
                    </div>
            }

            {/*REGULAR LESSON*/}
            <AddRegularLesson
                sql={sqlCard}
                employeeData={employeeData}
                type_lesson={type_lesson}
                lessonGroup={lessonGroup}
            />

            <EditRegularLesson
                sql={sqlCard}
                employeeData={employeeData}
                editRegularLesson={editRegularLesson}
                editLessonGroup={editLessonGroup}
            />
        </>
    )
})