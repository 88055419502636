import {useTranslate} from "../../../Hooks/translate.hook";
import {useContext, useRef, useState} from "react";
import {closeModal} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, filterDisable} from "../../../Function/common";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import {errorNotify, success} from "../../../Function/Notify";

export default function ComparisonModal ({ entity, selectFile, importFiles, setImportFiles }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ fieldImport, setFieldImport ] = useState({});
    // STATE END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // HANDLER START
    function handlerFieldImport (columnName, systemName) {
        let obj = fieldImport;

        obj[columnName] = systemName;

        setFieldImport(obj);
    }
    // HANDLER END

    // RENDER SELECT START
    function renderOption (entity) {

        // eslint-disable-next-line default-case
        switch (entity) {
            case 'customer' : return (
                <>
                    <optgroup label={ translate('Основные поля') }>
                        {
                            filterDisableEntity(customSetting?.requiredField, 'customer').filter(fields => fields.systemName !== 'employee' && fields.systemName !== 'contact').map(fields => (
                                <option value={fields.systemName}> { (fields.require || fields.require === null)  && '*' } { fields.name } </option>
                            ))
                        }
                        <option value='balance'> { translate('Общий остаток') } </option>
                        <option value='legal'> { translate('Заказчик') } </option>
                        <option value='mobile'> { translate('Номер телефону') } </option>
                    </optgroup>
                    <optgroup label={ translate('Дополнительные поля') }>
                        {
                            filterDisableEntity(customSetting?.customField, 'customer').map(fields => (
                                <option value={fields.systemName}> { fields.require && '*' } { fields.name } </option>
                            ))
                        }
                    </optgroup>
                </>
            )
            case 'lead' : return (
                <>
                    <optgroup label={ translate('Основные поля') }>
                        {
                            filterDisableEntity(customSetting?.requiredField, 'customer').filter(fields => fields.systemName !== 'employee' && fields.systemName !== 'contact').map(fields => (
                                <option value={fields.systemName}> { (fields.require || fields.require === null)  && '*' } { fields.name } </option>
                            ))
                        }
                        <option value='balance'> { translate('Общий остаток') } </option>
                        <option value='legal'> { translate('Заказчик') } </option>
                        <option value='mobile'> { translate('Номер телефону') } </option>
                        <option value='leadStatus'> { translate('Этап воронки') } </option>
                        <option value='manager'> { translate('Ответственный') } (id) </option>
                        <option value='manager_name'> { translate('Ответственный') } (name) </option>
                        <option value='employee'> { translate('Отв. педагог') } (id) </option>
                        <option value='employee_name'> { translate('Отв. педагог') } (name) </option>
                        <option value='tariff'> { translate('Абонемент') } (id) </option>
                        <option value='tariff_name'> { translate('Абонемент') } (name) </option>
                        <option value='c_date'> { translate('Дата добавления') } </option>
                    </optgroup>
                    <optgroup label={ translate('Дополнительные поля') }>
                        {
                            filterDisableEntity(customSetting?.customField, 'customer').map(fields => (
                                <option value={fields.systemName}> { fields.require && '*' } { fields.name } </option>
                            ))
                        }
                    </optgroup>
                    <optgroup label={ translate('Теги статистики') }>
                        {
                            salesSetting ?
                                    filterDisable(salesSetting.leadTags).map(item => (
                                        <option value={item.name}> { item.name } </option>
                                    ))
                                : null
                        }
                    </optgroup>
                </>
            )
        }

    }
    // RENDER SELECT END

    // IMPORT START
    async function comparison (e) {
        e.preventDefault();

        const data = Object.filter(fieldImport, value => value);

        const response = await request(`${URL.back_end}/import-csv/${entity}`, 'POST', { fileName : selectFile.fileName, data } );

        if (response.message) return errorNotify(response.message);

        success(translate('Успешно импортировано'))

        const arr_imports = importFiles.filter(item => item.fileName !== selectFile.fileName);
        setImportFiles(arr_imports);

        closeModal(e, modal);
    }
    // IMPORT END

    return (
        <div id="comparison-fields" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form onSubmit={e => comparison(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">
                                { translate('Сопоставление полей') } <span className="text-muted"> { selectFile?.fileName } </span>
                            </h4>
                        </div>
                        <div className="modal-body p-sm">
                            <div className="row m-b-xs border-bottom">
                                <div className="col-sm-6 small text-muted font-bold p-xxs"> { translate('Заголовок в файле') } </div>
                                <div className="col-sm-6 text-center small text-muted font-bold p-xxs"> { translate('Соответствие в CRM') } </div>
                            </div>
                            {
                                selectFile?.columns?.map(item => (
                                    <div className="row m-b-xs border-bottom">
                                        <div className="col-sm-6 p-xxs">
                                            <label className="control-label small" style={{ cursor : 'pointer' }}>
                                                { item }
                                            </label>
                                        </div>
                                        <div className="col-sm-6">
                                            <select className="form-control input-sm m-b-xs" onChange={e => handlerFieldImport(item, e.target.value)}>
                                                <option value=''> </option>
                                                { renderOption(entity) }
                                            </select>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}