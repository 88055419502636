import {memo, useEffect, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import {useTranslate} from "../../../Hooks/translate.hook";
import {success} from "../../../Function/Notify";

export const AgileColumns = memo(({ data, sql, setSql, setSelectedTask }) => {

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const sweet_text = document.getElementById("sweet-text");
    const confirm_button = document.getElementById("sweet-confirm");
    // SWEET MODAL END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [executors, setExecutors] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const users = await request(`${URL.back_end}/user_task/${data.task_id}`, 'GET');

            if (error) throw error

            setExecutors(users);
        }

        fetchData().catch(e => console.log(e))

    }, [data])
    // INIT DATA END

    // COLUMNS FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        setSelectedTask(tempSelection);
    }

    function btnArchive (value) {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";

        confirm_button.addEventListener('click', async () => {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            await request(`${URL.back_end}/task/${data.task_id}`, 'PUT', { f_removed : value })

            if (error) return console.log(error)

            const task_arr = sql.filter(item => item.task_id !== data.task_id);
            setSql(task_arr);
        })
    }

    function deleteTask () {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";
        sweet_text.innerText = translate('Удалить задачу?')

        confirm_button.addEventListener('click', async () => {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            await request(`${URL.back_end}/task/${data.task_id}`, 'DELETE');

            if (error) return console.log(error)

            const task_arr = sql.filter(item => item.task_id !== data.task_id);
            setSql(task_arr);

            success(translate('Задача удалена'))
        })
    }
    // COLUMNS FUNC END

    return (
        <tr>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select">
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={data.task_id}
                    onClick={getSelect}
                />
            </td>
            <td>
                <div style={{ fontSize : '11px' }}>
                    { data.c_date ? <span> { data.c_date } </span> : <span className="not-set"> ({ translate('не задано') }) </span> }
                </div>
            </td>
            <td>
                {
                    executors.map(item => (
                        <>
                            { item.name }
                            <br />
                            <small className="text-muted"> { item.email } </small>
                            <br />
                        </>
                    ))
                }
            </td>
            <td>
                <div style={{ fontSize : '11px' }}>
                    <span>
                        {
                            data.date ? (
                                <>
                                    <i className="ion-calendar fa-fw" /> { data.date } <br />
                                    <i className="ion-clock fa-fw" /> { data.time }
                                </>
                            ) : translate('Без срока')
                        }
                    </span>
                </div>
            </td>
            <td> { data.text } </td>
            <td> </td>
            <td>
                {
                    data.date_done ? (
                        <div style={{ fontSize : '11px' }}>
                            <span> <i className="ion-calendar fa-fw" /> { data.date_done } </span>
                        </div>
                    ) : <span className="not-set">({ translate('не задано') })</span>
                }
            </td>
            <td>
                {
                    data.f_removed === 1 ? (
                        <a className="pull-right m-l-xs" title={translate('Удалить')} onClick={() => deleteTask()}>
                            <i className="fa fa-lg fa-trash-o text-danger" />
                        </a>
                    ) : null
                }
                {
                    data.status !== 2 ? (
                        data.f_removed === 0 ?
                            <a className="pull-right" title={translate('В архив')} onClick={() => btnArchive(1)}>
                                <i className="fa fa-lg fa-trash-o text-danger" />
                            </a> :
                            <a className="pull-right" title={translate('Из архив')} onClick={() => btnArchive(0)}>
                                <i className="fa fa-reply text-navy" />
                            </a>
                    ) : null
                }
            </td>
        </tr>
    )
})