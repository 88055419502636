import {useTranslate} from "../../../Hooks/translate.hook";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {closeModal} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import UIMenuInput from "../../../Components/InputChoose/UIMenuInput";

export default function CalendarTeacherFilter ({ value, employeeData }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { employeeSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [ qualification, setQualification ] = useState([]);
    const [ qualification2, setQualification2 ] = useState([]);
    const [ employee, setEmployee ] = useState('');

        // SEARCH
    const [ employeeSearch, setEmployeeSearch ] = useState('');
    // STATE END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // INIT DATA START
    useEffect(() => {

        if (!value) {
            setQualification([]);
            setQualification2([]);
            setEmployee('');
            return;
        }

        setQualification(value.qualification ? value.qualification : []);
        setQualification2(value.qualification2 ? value.qualification2 : []);
        setEmployee(value.employee);

    }, [value])
    // INIT DATA END

    // FUNCTION START
    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }
    // FUNCTION END

    // FILTER START
    function filterSchedule (e) {

        e.preventDefault();

        const schedule = {
            qualification : qualification.length ? qualification.map(item => item.id ? item.id : item) : [],
            qualification2 : qualification2.length ? qualification2.map(item => item.id ? item.id : item) : [],
            employee : employee ? employee : ''
        }

        const obj = Object.filter(schedule, (value) => value.length);

        if (!Object.keys(obj).length) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/calendar-teacher/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }
    }
    // FILTER END

    return (
        <div id="filter-schedule" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterSchedule(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        <div className="modal-body modal-body-search p-md">
                            {/* QUALIFICATION */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Квалификация') }
                                        <br />
                                        <small> (збіг по одній з кваліфікацій) </small>
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={employeeSetting ? employeeSetting.qualification : []}
                                        setProps={setQualification}
                                        type_id="id"
                                        topicSearch="name"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Квалификация') }
                                        <br />
                                        <small> (збіг по всіх обраних кваліфікаціях) </small>
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={employeeSetting ? employeeSetting.qualification : []}
                                        setProps={setQualification2}
                                        type_id="id"
                                        topicSearch="name"
                                    />
                                </div>
                            </div>
                            {/* EMPLOYEE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Педагог') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <UIMenuInput
                                        data={sortArray(employeeData, 'name').filter(item => item.f_removed === 0)}
                                        searchText={employeeSearch}
                                        setSearchText={setEmployeeSearch}
                                        setId={setEmployee}
                                        value={employee}
                                        placeholder="Поиск педагога"
                                        type_id="employee_id"
                                        topic="name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр и вернуться в таблицу') } onClick={e => { closeModal(e, modal); navigate('/company/1/calendar-teacher/index') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}