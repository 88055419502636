import {Link} from "react-router-dom";
import {useContext} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {findObject} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import typeLessons from "../../../Assets/data/JSON/typeLessons.json";

export default function LessonColumns (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // COLUMNS FUNC START
    function getTime(dateStart, dateEnd) {
        let startTime = dateStart.split('T')[1].slice(0, 5);
        let endTime = dateEnd.split('T')[1].slice(0, 5);

        return `${startTime} -\n${endTime}`
    }
    // COLUMNS FUNC END

    // SELECT FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }
    // SELECT FUNC END

    return (
        <tr style={{whiteSpace: "pre-wrap"}}>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={props.data.lesson_id}
                    onClick={getSelect}
                />
            </td>
            {/*ID*/}
            <td>
                { translate(typeLessons[props.data.type_lesson]) }
            </td>
            {/*DATE*/}
            <td>
                { props.data.date_start.split('T')[0] }
            </td>
            {/*CUSTOMER*/}
            <td>
                {
                    props.customersData.filter(customer => customer.lesson_id === props.data.lesson_id).length > 0 ?
                        props.customersData.filter(customer => customer.lesson_id === props.data.lesson_id).map(item => (
                            <>
                                <a href={`/company/1/customer/view?id=${item.customer_id}`} target="_blank">
                                    {item.name}
                                </a>
                                <br />
                            </>
                        )) : <span className="not-set">({translate('не задано')})</span>
                }
            </td>
            {/*TIME*/}
            <td>
                { getTime(props.data.date_start, props.data.date_end) }
            </td>
            {/*SUBJECT*/}
            <td>
                {lessonSetting ? findObject(lessonSetting.subject, props.data.subject) ? findObject(lessonSetting.subject, props.data.subject).name : null : null}
            </td>
            {/*EMPLOYEE*/}
            <td>
                {
                    props.employeesData.filter(employee => employee.lesson_id === props.data.lesson_id).length ?
                        props.employeesData.filter(employee => employee.lesson_id === props.data.lesson_id).map(item => (
                            <>
                                <Link to={`/company/1/employee/view?id=${item.employee_id}`}>
                                    { item.name }
                                </Link>
                                <br />
                            </>
                    )) : <span className="not-set">({translate('не задано')})</span>
                }
            </td>
            {/*TOPIC*/}
            <td>
                {props.data.topic ? props.data.topic : <span className="not-set">({translate('не задано')})</span>}
            </td>
            {/*HOMEWORK*/}
            <td>
                {props.data.homework ? props.data.homework : <span className="not-set">({translate('не задано')})</span>}
            </td>
            {/*COMMISSION*/}
            <td>
                {props.data.commission ? props.data.commission : <span className="not-set">({translate('не задано')})</span>}
            </td>
            {/*NOTE*/}
            <td>
                {props.data.l_note}
            </td>
        </tr>
    )
}