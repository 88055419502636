import {useMemo, useRef, useState} from "react";
import React from "react";
import {useTranslate} from "../../Hooks/translate.hook";

function MemoInput (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // REF START
    const chosen_container = useRef();
    const chosen_drop = useRef();
    const chosen_result = useRef();
    // REF END

    // STATE START
    const [searchValue, setSearchValue] = useState(translate('Выберите значение(я)'));

    let resValue = document.getElementById(props.id) ? document.getElementById(props.id).value ? JSON.parse(document.getElementById(props.id).value) : [] : [];
    // STATE END

    // SEARCH ITEMS START
    const searchItems = useMemo(() => {
        if (searchValue !== "" && searchValue !== " ")
        {
            return props.data.filter(item => item.includes(searchValue));
        } else {
            return [];
        }
    },[searchValue])
    // SEARCH ITEMS END

    // INPUT MULTI FUNC START
    function addChoice (e) {
        if (e.target.className !== "result-selected" && props.id === undefined) {
            props.setProps([...props.getProps, e.target.dataset.name]);

            e.target.className = "result-selected";
        } else {
            // setResValue([...resValue, e.target.dataset.name])
            document.getElementById(props.id).value = JSON.stringify([...resValue, e.target.dataset.name]);
            chosen_drop.current.style.left = "-9999px";
            setSearchValue(translate('Выберите значение(я)'))
            chosen_container.current.classList = "chosen-container chosen-container-multi";
        }
    }

    function chosenShow () {
        setSearchValue('');
        chosen_drop.current.style.left = "0";
        chosen_container.current.classList += " chosen-container-active";

        document.addEventListener('click', function(event) {
            if (!chosen_container.current.contains(event.target)) {
                chosen_drop.current.style.left = "-9999px";
                setSearchValue(translate('Выберите значение(я)'))
                chosen_container.current.classList = "chosen-container chosen-container-multi";
            }
        });
    }

    function removeChoice (e) {
        if (props.id === undefined) {
            props.getProps.splice(e.target.dataset.index, 1);
            props.setProps([...props.getProps]);
        } else {
            let temp_arr = resValue.filter(item => {
                if (item !== e.target.dataset.name) {
                    return item;
                }
            })
            document.getElementById(props.id).value = JSON.stringify(temp_arr);

            chosen_drop.current.style.left = "-9999px";
            setSearchValue(translate('Выберите значение(я)'))
            chosen_container.current.classList = "chosen-container chosen-container-multi";
        }

        chosen_result.current.querySelector(`[data-name = "${e.target.dataset.name}"]`).classList = "active-result";
    }

    function searchQuery (e) {setSearchValue(e.target.value)}
    // INPUT MULTI FUNC END

    return (
        <div className="chosen-container chosen-container-multi" style={{width: "100%"}} ref={chosen_container}>
            <ul className="chosen-choices" onClick={chosenShow}>
                {
                    props.id === undefined ?
                        props.getProps.length > 0 ?
                            props.getProps.map((item, index) => (
                                <li className="search-choice" key={index}>
                                    <span>{item}</span>
                                    <a className="search-choice-close" data-index={index} data-name={item} onClick={e => removeChoice(e)} />
                                </li>
                            )) : null
                        :
                        resValue.length > 0 ?
                            resValue.map((item, index) => (
                                <li className="search-choice" key={index}>
                                    <span>{item}</span>
                                    <a className="search-choice-close" data-index={index} data-name={item} onClick={e => removeChoice(e)} />
                                </li>
                            ))
                            : null
                }
                <li className="search-field">
                    <input
                        type="hidden"
                        id={props.id}
                        value={JSON.stringify(resValue)}
                    />
                    <input
                        type="text"
                        placeholder={translate('Выберите значение(я)')}
                        className="default"
                        autoComplete="off"
                        style={{width: "167.012px"}}
                        value={searchValue}
                        onChange={e => searchQuery(e)}
                    />
                </li>
            </ul>
            <div className="chosen-drop" ref={chosen_drop}>
                <ul className="chosen-results" ref={chosen_result}>
                    {
                        props.id === undefined ?
                                searchItems.length > 0 ?
                                    searchItems.map((item, index) => (
                                        props.getProps.includes(item) ?
                                            <li className="result-selected" data-name={item} key={index}>{item}</li> :
                                            <li className="active-result" data-name={item} key={index} onClick={e => addChoice(e)}>{item}</li>
                                    )) :
                                    props.data.map((item, index) => (
                                        props.getProps.includes(item) ?
                                            <li className="result-selected" data-name={item} key={index}>{item}</li> :
                                            <li className="active-result" data-name={item} key={index} onClick={e => addChoice(e)}>{item}</li>
                                    ))
                             :
                            searchItems.length > 0 ?
                                searchItems.map((item, index) => (
                                    resValue.includes(item) ?
                                        <li className="result-selected" data-name={item} key={index}>{item}</li> :
                                        <li className="active-result" data-name={item} key={index} onClick={e => addChoice(e)}>{item}</li>
                                )) :
                                props.data.map((item, index) => (
                                    resValue.includes(item) ?
                                        <li className="result-selected" data-name={item} key={index}>{item}</li> :
                                        <li className="active-result" data-name={item} key={index} onClick={e => addChoice(e)}>{item}</li>
                                ))
                    }
                </ul>
            </div>
        </div>
    )
}

export const InputChooseMultiArray = React.memo(MemoInput);