import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {useArchive} from "../../../Hooks/archive.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useContext} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import currency from "../../../Assets/data/JSON/currency.json";

export default function TariffColumns (props) {

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    // SWEET MODAL END

    // CONTEXT START
    const { basicSetting, currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // SELECT FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }
    // SELECT FUNC END

    // ANOTHER FUNC START
    function convertDate () {

        let [ date, time ] = props.data.added.split('T');

        return (
            <div style={{fontSize : "11px"}}>
                <span>
                    <i className="ion-clock fa-fw" /> { time.slice(0, 5) }
                    <br />
                    <i className="ion-calendar fa-fw" /> { date }
                </span>
            </div>
        )
    }

    function renderUsed () {
        const currentTariffs = props.customerTariff.filter((item) => +item.tariff_id === +props.data.tariff_id);

        const total = currentTariffs.length;
        const used = currentTariffs.filter((item) => new Date(currentTime).getTime() <= new Date(item.date_end).getTime()).length;
        const archive = total - used;

        if (!total) return <span className="not-set text-lowercase"> { translate('Нет') } </span>

        return (
            <>
                <a className="font-bold" title={ translate('Всего') }> { total } </a> –
                <a className="text-info" title={ translate('Активные') }> { used } </a> ,
                <a className="text-muted" title={ translate('Истекшие') } > { archive } </a>
            </>
        )
    }

    function buttonArchive (value) {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            // REQUEST

            await request(`${URL.back_end}/tariff/${props.data.tariff_id}`, 'PUT', { f_removed: value })

            if (error) return console.log(error)

            if (value === 0) {
                await pushArchive(4, 'tariff', props.data.tariff_id, { f_removed : 1 }, { f_removed : 0 })
            } else {
                await pushArchive(3, 'tariff', props.data.tariff_id, { f_removed : 0 }, { f_removed : 1 })
            }

            props.sql.forEach(function (item, i) {
                if (props.data.tariff_id === item.tariff_id) {
                    props.sql[i].f_removed = value;
                    props.updateSql([...props.sql]);
                }
            })
        })
    }
    // ANOTHER FUNC END

    return (
        <tr className={`${props.data.f_removed !== 0 && "danger"}`}>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" width={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={props.data.tariff_id}
                    onClick={getSelect}
                />
            </td>
            <td>
                { props.data.title }
            </td>
            <td>
                { translate(props.data.billing) }
            </td>
            <td>
                { props.data.price } { basicSetting && currency[basicSetting.baseOption.currency] }
            </td>
            <td>
                { renderUsed() }
            </td>
            <td>
                { props.data.added ? convertDate(props.data.added) : null }
            </td>
            <td>
                {
                    props.data.f_removed === 0 ?
                        <a className="pull-right" title={translate('В архив')} onClick={() => buttonArchive(1)}>
                            <i className="fa fa-lg fa-trash-o text-danger" />
                        </a> :
                        <a className="pull-right" title={translate('Из архива')} onClick={() => buttonArchive(0)}>
                            <i className="fa fa-reply text-navy" />
                        </a>
                }
            </td>
        </tr>
    )
}