import {useContext, useRef, useState} from "react";
import {closeModal, maxId} from "../../../../../Function/common";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from "../../../../../Api/URL.json";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";

export default function AddReason () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {customerSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    // STATE END

    // ADD REASON START
    async function addReason (e) {
        e.preventDefault();

        let validity = {
            "Название" : !name.length,
            "Значение": !value.length,
            result: 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                errorNotify(`Необходимо заполнить "${key}"`);
                validity.result += 1;
            }
        }

        if (validity.result > 0) return;

        let reason = {
            id: customerSetting.reason.length ? maxId(customerSetting.reason)+1 : 1,
            type, name, value,
            disabled: false
        }

        customerSetting.reason.push(reason);

        await request(`${URL.back_end}/setting`, 'PUT', {customerSetting: JSON.stringify(customerSetting)})

        if (error) return error;

        window.location.reload();
    }
    // ADD REASON END

    return (
        <div id="add-reason" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addReason(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить причину')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*TYPE*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="lessonreason-type">{translate('Тип')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <select id="lessonreason-type" className="form-control" onChange={e => setType(e.target.value)}>
                                        <option value="">{translate('Не задано')}</option>
                                        <option value="1">{translate('Множитель стоимости')}</option>
                                        <option value="2">{translate('Натуральное значение')}</option>
                                    </select>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="lessonreason-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Болезнь')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*VALUE*/}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="lessonreason-coef">{translate('Значение')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 1`}
                                        step="0.001"
                                        value={value}
                                        onChange={e => setValue(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}