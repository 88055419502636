import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useEffect, useState} from "react";
import URL from "../../../../Api/URL.json";

export default function ManagerBlock ({ sqlCard }) {

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ manager, setManager ] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const [ data ] = await request(`${URL.back_end}/user/${sqlCard.manager}`, 'GET');

            setManager(data);

        }

        fetchData().catch(e => console.log(e))

    }, [sqlCard])
    // INIT DATA END

    return (
        <div className="row">
            <div className="col-xs-5 text-muted m-b-xs">
                { translate('Менеджер') }
            </div>
            <div className="col-xs-7 m-b-xs text-right">
                { manager?.name } <span className="text-muted small"> { manager?.phone } </span>
            </div>
        </div>
    )
}