import '../../Assets/css/InlineChosen.css';

import {useEffect, useMemo, useRef, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import URL from '../../Api/URL.json';
import {useArchive} from "../../Hooks/archive.hook";
import {useTranslate} from "../../Hooks/translate.hook";

export default function ManagerChosen (props) {

    // REF START
    const chosen_drop = useRef();
    const chosen_container = useRef();
    const chosen_result = useRef();
    const chosen_single = useRef();
    // REF END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [choseValue,setChoseValue] = useState('Выберите');
    const [searchValue, setSearchValue] = useState('');
    const [managers, setManagers] = useState([]);
    // STATE END

    // CHECK PROPS START
    useEffect(() => {
        if (props.value !== undefined && props.value !== null) {

            if (managers.length === 0) {

                async function fetchData () {

                    const manager = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1, f_removed : 0 } });

                    setManagers(manager.users);

                    setChoseValue(manager.users.filter(item => item.user_id === props.value)[0]?.name);
                }

                fetchData().catch(e => console.log(e))

            } else {
                setChoseValue(managers.filter(item => item.user_id === props.value)[0]?.name);
            }


            chosen_single.current.classList = "chosen-single";
        }

        return () => setChoseValue(translate('Выберите'));
    }, [props.value])
    // CHECK PROPS END

    // USE MEMO START
    const searchItems = useMemo(() => {
        if (searchValue !== "" && searchValue !== " ")
        {
            return managers.filter(item => item[props.topicSearch].includes(searchValue));
        } else {
            return [];
        }
    },[searchValue])
    // USE MEMO END

    // SHOW CHOSEN START
    async function chosenShow () {
        if (managers.length === 0) {
            const manager = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1, f_removed: 0 } });
            setManagers(manager.users);
        }

        chosen_drop.current.style.left = "0";
        chosen_container.current.classList += " chosen-container-active chosen-with-drop"

        document.addEventListener("click", function (event) {
            if (!chosen_container.current.contains(event.target)) {
                chosen_drop.current.style.left = "-9999px";
                chosen_container.current.classList = "chosen-container chosen-container-single";
            }
        })
    }
    // SHOW CHOSEN END

    // CUSTOM INPUT FUNC START
    async function addChoice (e) {
        setChoseValue(e.target.dataset.name);

        await request(`${URL.back_end}/customer/${props.data.customer_id}`, 'PUT', { manager: +e.target.dataset.id })
        await pushArchive(2, 'customer', props.data.customer_id, { manager : +e.target.dataset.id }, { manager : null });

        chosen_drop.current.style.left = "-9999px";
        chosen_container.current.classList = "chosen-container chosen-container-single";
        chosen_single.current.classList = "chosen-single";
    }
    // CUSTOM INPUT FUNC END

    // FUNCTION START
    function sortArray (arr) {
        return arr.filter(item => item[props.topicSearch]).sort((a, b) => a[props.topicSearch].replace(/і/g, 'и').localeCompare(b[props.topicSearch].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div className="col-xs-5 text-right">
            <div className="hidden-sm hidden-md hidden-lg m-b-sm" />
            <span className="inline-chosen">
                <div className="chosen-container chosen-container-single" style={{ width: '100%', textAlign: "left" }} ref={chosen_container}>
                    <a className="chosen-single" tabIndex={-1} onClick={chosenShow} ref={chosen_single}>
                        <span> { choseValue } </span>
                        <div>
                        <b />
                    </div>
                    </a>
                    <div className="chosen-drop" ref={chosen_drop}>
                        <div className="chosen-search">
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={e => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                        </div>
                        <ul className="chosen-results" ref={chosen_result}>
                            {
                                searchItems.length > 0 ?
                                    sortArray(searchItems).map(item => (
                                        <li className="active-result" data-name={item[props.topicSearch]} data-id={item[props.type_id]} key={item[props.type_id]} onClick={e => addChoice(e)}>{item[props.topicSearch]}</li>
                                    )) :
                                    sortArray(managers).map(item => (
                                        <li className="active-result" data-name={item[props.topicSearch]} data-id={item[props.type_id]} key={item[props.type_id]} onClick={e => addChoice(e)}>{item[props.topicSearch]}</li>
                                    ))
                            }
                        </ul>
                    </div>
                </div>
            </span>
        </div>
    )
}