import {useTranslate} from "../../../../Hooks/translate.hook";

export default function AccountClient () {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <div className="settings-block__content-content">
            <div id="settings-container" className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{translate('Настройки ЛК ученика')}</h4>
                </div>
                <form className="form-horizontal">
                    <div className="modal-body p-md">
                        <div className="form-group">
                            <div className="col-sm-3">
                                <label>{translate('Период истории в прогнозе')}</label>
                            </div>
                            <div className="col-sm-9">
                                <select className="form-control">
                                    <option value="7">±7 {translate('дней')}</option>
                                    <option value="30">±30 {translate('дней')}</option>
                                    <option value="90">±90 {translate('дней')}</option>
                                    <option value="180">±180 {translate('дней')}</option>
                                    <option value="360">±360 {translate('дней')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-3" />
                            <div className="col-sm-9">
                                <div>
                                    <label>
                                        <input type="checkbox" value="1" /> {translate('Может просматривать загруженные файлы в ЛК')}
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" value="2" /> {translate('Может видеть стоимость урока')}
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" value="3" /> {translate('Может видеть комментарии к урокам')}
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" value="4" /> {translate('Может видеть личное примечание к уроку')}
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" value="5" /> {translate('Имеет доступ к файлам в уроках')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-sm btn-w-m btn-success">
                            <i className="fa fa-save" /> {translate('Сохранить')}
                        </button>
                        <button type="button" className="btn btn-sm btn-w-m btn-white">
                            {translate('Отмена')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}