import SideMenu from "../../../Components/SideMenu/SideMenu";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import RoleTable from "./Table/RoleTable";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import {useTranslate} from "../../../Hooks/translate.hook";
import {openModal} from "../../../Function/common";
import AddRole from "./Modal/AddRole";
import TabAction from "../../../Components/TabAction";

export default function Role () {

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [sql,setSql] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    // STATE END

    // INIT SQL START
    useEffect(() => {
        async function fetchData () {
            const data = await request(`${URL.back_end}/role`, 'GET');
            setSql(data);
        }
        fetchData().catch(e => console.log(e))
    }, [request])
    // INIT SQL END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="user" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="/company/1/user/index" className="btn btn-sm btn-white" title={translate('Управление пользователями')}>
                                        <i className="ion-person" />
                                    </Link>
                                    <Link to="/company/1/user/role-index" className="btn btn-sm btn-white active" title={translate('Управление ролями')}>
                                        <i className="ion-ios-body" />
                                    </Link>
                                </div>
                                {/*BUTTON ADD*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    icon="ion-plus"
                                    text="Добавить"
                                    handler={() => openModal('add-role')}
                                />
                                {/*BUTTON EDIT*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    disable={true}
                                />
                                {/*BUTTON ANOTHER*/}
                                {/*<TabAction*/}
                                {/*    access={true}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="fa fa-ellipsis-h"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className="active">
                                        <Link to="/company/1/user/role-index">{translate('Текущие')}</Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body">
                                            <div className="grid-view is-bs3 hide-resize">
                                                {/*TABLE HEADER*/}
                                                <div className="text-muted text-right m-b-xs">
                                                        <span style={{lineHeight: 0}}>
                                                            {translate('Отображены строки')}&nbsp;
                                                            <b>1 — {sql.length}</b>. {translate('Всего в базе')}&nbsp;
                                                            <b>{sql.length}</b>. &nbsp;&nbsp;
                                                            <span className="visible-xs"><br /></span>
                                                            <a className="crm-dashed-link">{translate('Настройка полей')}</a>
                                                            . &nbsp; {translate('Пагинация')}&nbsp;
                                                            <span className="dropdown">
                                                                <a className="dropdown-toggle crm-dashed-link">
                                                                    20 {translate('строк')}
                                                                </a>
                                                                <ul className="dropdown-menu" style={{marginLeft: "-90px"}}>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=20">
                                                                            20 {translate('строк')}
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=50">
                                                                            50 {translate('строк')}
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=100">
                                                                            100 {translate('строк')}
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=200">
                                                                            200 {translate('строк')}
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=500">
                                                                            500 {translate('строк')}
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                        </span>
                                                </div>
                                                {/*TABLE BODY*/}
                                                <div className="border-bottom" />
                                                <RoleTable
                                                    sql={sql}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <AddRole
                sql={sql}
                updateSql={setSql}
            />
        </div>
    )
}