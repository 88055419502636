import {Link} from "react-router-dom";
import moment from "moment";

function findObject (arr, id) {
    if (id === null) {return {name: "Не задано"}}
    let obj = arr.filter(item => {
        if (+item.id === +id) {
            return item;
        }
    })
    return obj[0];
}

function findObjectWCheck (arr, id) {
    let obj = arr.filter(item => {
        if (item.id === id) {
            return item;
        }
    })
    return obj[0];
}

function findCustomObject (arr, custId, id) {
    // eslint-disable-next-line array-callback-return
    let obj = arr.filter(item => {
        if (item[custId] === id) {
            return item;
        }
    })
    return obj[0];
}

function getRequired (arr, systemName, entity) {
    let require = arr.filter(item => {
        if (item.systemName === systemName && item.entity === entity) {
            return item;
        }
    })[0]?.require;

    return require ? "required" : "";
}

function findRequired (arr, systemName, entity) {
    let obj = arr.filter(item => {
        if (item.systemName === systemName && item.entity === entity) {
            return item;
        }
    })
    return obj[0];
}

function filterDisable (arr) {
    return arr.filter(item => item.disabled !== true);
}

function filterDisabledAccess (arr, id) {
    return arr.filter(item => item.disabled !== true && item.allowed.includes(id.toString()));
}

function filterDisableEntity (arr, entity) {
    return arr.filter(item => item.disabled !== true && item.entity === entity);
}

function openModal (id) {
    let modal = document.getElementById(id);
    modal.style.display = "block";

    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    }
}

function closeModal (e, modal) {
    e.preventDefault();
    modal.current.style.display = "none";
}

function chunkArray (perChunk, inputArray) {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
}

function paginationCount (length, size) {
    let count = 0;

    while (length > 0) {
        length -= size;
        count += 1;
    }

    return Array.from({length: count}, (_, i) => i + 1);
}

function pagination (counts, pageSize, entity, page, viewMode) {

    if (!counts) return

    const paginationCounts = pageSize ? paginationCount(counts, pageSize[entity]).length : 20;

    const visiblePages = 10; // Количество отображаемых страниц

    let startPage = Math.max(page - Math.floor(visiblePages / 2), 1);
    let endPage = Math.min(startPage + visiblePages - 1, paginationCounts);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    return (
            <ul className="pagination" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                <li className={`prev ${page > 1 ? '' : 'disabled'}`}>
                    <Link to={page > 1 ? `?page=${+page-1}${viewMode ? `&view_mode=${viewMode}` : ''}` : `${viewMode ? `?view_mode=${viewMode}` : ''}`}>«</Link>
                </li>
                {
                    Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(item => (
                        <li className={+page === item ? 'active' : ''}>
                            <Link to={`?page=${item}${viewMode ? `&view_mode=${viewMode}` : ''}`}>
                                { item }
                            </Link>
                        </li>
                    ))
                }
                <li className={`next ${page < paginationCounts ? '' : 'disabled'}`}>
                    <Link to={page < paginationCounts ? `?page=${+page+1}${viewMode ? `&view_mode=${viewMode}` : ''}` : `${viewMode ? `?view_mode=${viewMode}` : ''}`}>
                        »
                    </Link>
                </li>
            </ul>
    )
}

function openMenu (e) {
    const currentTarget = e.currentTarget;

    const handler = (event) => {
        if (!event.target.closest('.dropdown')) {
            const dropdown = document.querySelectorAll('.dropdown');

            for (let i = 0; i < dropdown.length; i++) {
                dropdown[i].classList.remove('open');
            }

            window.removeEventListener('click', handler, false);
        }
    }

    window.addEventListener('click', handler, false);

    currentTarget.classList.toggle('open');
}

function maxId (arr) {
    return Math.max.apply(null, arr.map(item => item.id))
}

function getTariffInfo (tariff, basicBalance) {
    let lesson = 0;
    let balance = basicBalance || 0;

    if (!tariff.length) return { balance, lesson }

    if (tariff.length === 1) {
        lesson = Math.floor(tariff[0].balance / tariff[0].price_per_lesson);
        balance += +(tariff[0].balance);
    } else {
        let arrAmountLesson  = tariff.map(item => Math.floor(item.balance / item.price_per_lesson));
        let arrAmountBalance = tariff.map(item => Number(item.balance));
        lesson   = arrAmountLesson.reduce( ( curr, next ) => curr + next );
        balance += +arrAmountBalance.reduce( ( curr, next ) => curr + next );
    }


    return { balance : balance.toFixed(2), lesson };
}

function getTariffCount (tariff) {

    let countLesson = [];

    if (!tariff.length) return { countLesson }

    for (let i = 0; i < tariff.length; i++) {
        countLesson.push({
            id : tariff[i].id,
            lessons : JSON.parse(tariff[i].lessons),
            type_lessons : JSON.parse(tariff[i].type_lessons),
            count : Math.floor(tariff[i].balance / tariff[i].price_per_lesson)
        })
    }

    return { countLesson };
}

function filterArchive (arr) { return arr.filter(item => item.f_removed === 0) }

function switchToggle (e, disabled) {
    e.preventDefault();

    let target = e.target;

    target.classList.replace(disabled ? 'fa-toggle-on' : 'fa-toggle-off', !disabled ? 'fa-toggle-on' : 'fa-toggle-off');
    target.classList.replace(disabled ? 'text-navy' : 'text-success', !disabled ? 'text-navy' : 'text-success');
    target.parentNode.parentNode.parentNode.style.opacity = disabled ? '0.5' : '1';
}

function limitYearDate (maxYear) {
    const dateNow = new Date();

    dateNow.setFullYear( dateNow.getFullYear() + maxYear );

    return dateNow.toLocaleDateString('en-CA');
}

function objectToUrlParams(obj) {
    let urlParams = '';

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key]) {
            if (urlParams.length > 0) {
                urlParams += '&';
            }
            urlParams += encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
        }
    }

    return urlParams;
}

function calculateDiscount (discountArr, subject, type_lesson, date_start, date_end, amount) {

    const currentDiscount = discountArr.filter(item =>
        JSON.parse(item.lessons).includes(subject) &&
        JSON.parse(item.type_lessons).includes(type_lesson) &&
        ( new Date(item.date_start).getTime() <= new Date(date_start.split('T')[0]).getTime() && new Date(item.date_end).getTime() > new Date(date_end.split('T')[0]).getTime() )
    )

    if (currentDiscount.length && amount > 0) {

        // eslint-disable-next-line default-case
        switch (currentDiscount[0].type_discount) {
            case 1 : return (amount - (amount * currentDiscount[0].amount) / 100) < 0 ? 0 : (amount - (amount * currentDiscount[0].amount) / 100);
            case 2 : return (amount - currentDiscount[0].amount) < 0 ? 0 : (amount - currentDiscount[0].amount);
        }

    } else {
        return amount;
    }
}

function getCurrentWeekDateRange () {
    const now = new Date();
    const firstDayOfWeek = new Date(now);
    firstDayOfWeek.setDate(now.getDate() - now.getDay() + 1);

    const lastDayOfWeek = new Date(now);
    lastDayOfWeek.setDate(now.getDate() + (7 - now.getDay()));

    const date_start = moment(firstDayOfWeek).format('YYYY-MM-DD');
    const date_end = moment(lastDayOfWeek).format('YYYY-MM-DD');

    return { date_start, date_end };
}

function getDateParamsMonthDiapason(startParams, endParams) {
    const temp_start = new Date();
    const date_start = moment(new Date(temp_start.getFullYear(), temp_start.getMonth() + startParams, 1)).format('YYYY-MM-DD');

    const temp_end = new Date();
    const temp_end_next = new Date(temp_end.getFullYear(), temp_end.getMonth() + endParams, 0);
    const date_end = moment(temp_end_next).format('YYYY-MM-DD');

    return { date_start, date_end };
}

function getDateMonthDiapason () {

    const temp_start = new Date();
    const date_start = moment(new Date(temp_start.getFullYear(), temp_start.getMonth(), 1)).format('YYYY-MM-DD');

    const temp_end = new Date();
    const date_end = moment(new Date(temp_end.getFullYear(), temp_end.getMonth() + 1, 0)).format('YYYY-MM-DD');

    return { date_start, date_end }
}

function getDateYearRange() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    const startDate = new Date(year, 0, 2); // начало текущего года
    const endDate = new Date(year, 11, 31); // конец текущего года

    const date_start = startDate.toISOString().split('T')[0];
    const date_end = endDate.toISOString().split('T')[0];

    return { date_start, date_end }
}

function addMinutesToTime(timeString, minutesToAdd) {
    const [hours, minutes] = timeString.split(':');
    const totalMinutes = Number(hours) * 60 + Number(minutes) + minutesToAdd;

    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;

    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
}

function getCaretCharOffset (element) {
    let caretOffset = 0;

    if (window.getSelection) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
    }

    else if (document.selection && document.selection.type != "Control") {
        const textRange = document.selection.createRange();
        const preCaretTextRange = document.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
    }

    return caretOffset;
}

function setCaretCharOffset (element, offset) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(element.firstChild, offset);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
}

function calculateEndDate(startDate, daysOfWeek, numberOfLessons) {
    if (!numberOfLessons) return null;

    const start = new Date(startDate);
    const lessonDays = daysOfWeek.sort();

    let currentDate = new Date(start);

    let lessonsCount = 0;

    while (lessonsCount < numberOfLessons) {
        const currentDayOfWeek = currentDate.getDay();

        if (lessonDays.includes(currentDayOfWeek)) {
            lessonsCount++;
        }

        if (lessonsCount < numberOfLessons) {
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    // Передаем последнюю дату занятия
    return currentDate.toLocaleDateString('en-CA');
}


export { calculateEndDate, getCaretCharOffset, setCaretCharOffset, addMinutesToTime, getDateYearRange, getDateParamsMonthDiapason, getCurrentWeekDateRange, getDateMonthDiapason, calculateDiscount, objectToUrlParams, limitYearDate, findObject, findCustomObject, filterDisable, closeModal, openModal, filterDisabledAccess, filterDisableEntity, findObjectWCheck, findRequired, getRequired, chunkArray, pagination, openMenu, maxId, getTariffInfo, getTariffCount, filterArchive, switchToggle };