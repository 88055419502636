import {useRef, useState} from "react";
import {closeModal} from "../../../../Function/common";
import menu from "../../../../Assets/data/JSON/menu.json";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import {ToastContainer} from "react-toastify";
import {success, errorNotify} from "../../../../Function/Notify";
import shortid from "shortid";

export default function AddRole (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const {request, error} = useHttp();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [rank, setRank] = useState('');
    const [interfaces, setInterfaces] = useState('');
    // STATE END

    // ADD ROLE START
    async function addRole (e) {
        e.preventDefault();

        let elems = document.querySelectorAll('#navigation:checked');
        let menu = [].map.call(elems, function (obj) {
            return +obj.value;
        })

        let validity = {
            "Название" : !name.length,
            "Ранг" : !rank.length,
            "Интерфейс" : !interfaces.length,
            "Пункты меню" : !menu.length,
            result : 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                validity.result += 1;
                errorNotify(`Необходимо заполнить "${key}"`)
            }
        }

        let role = {
            name, rank: +rank, interface: interfaces, menu: JSON.stringify(menu), power: JSON.stringify([])
        }

        if (validity.result > 0) return

        await request(`${URL.back_end}/role`, 'POST', role)
        if (error) return error
        await props.updateSql([...props.sql, ...[role]])
        closeModal(e, modal)
        success(`Роль успешно создана`)
    }
    // ADD ROLE END

    return (
        <div id="add-role" className="modal in" ref={modal}>
            <ToastContainer />
            <div className="modal-dialog modal-lg">
                <div className="modal-content common-modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">Добавить роль</h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addRole(e)}>
                        <div className="modal-body p-md">
                            {/*NAME*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="aclrole-name">Название</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Например, Менеджер"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*RANG*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="aclrole-priority">Ранг</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Например, 800"
                                        value={rank}
                                        onChange={e => setRank(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*INTERFACE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="aclrole-view_mode_id">Интерфейс</label>
                                </div>
                                <div className="col-sm-9">
                                    <select className="form-control" onChange={e => setInterfaces(e.target.value)}>
                                        <option value="">Не задано</option>
                                        <option value="1">Администрирование</option>
                                        <option value="2">Кабинет педагога</option>
                                        <option value="3">Кабинет клиента</option>
                                    </select>
                                </div>
                            </div>
                            {/*MENU*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="aclrole-navigation_ids">Пункты меню</label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="sortable-list ui-sortable" style={{padding: 0}}>
                                        {
                                            Object.keys(menu).map(item => (
                                                <div className="dnd-item ui-sortable-handle" key={shortid.generate()}>
                                                    <label className="font-noraml no-margins" style={{borderBottom: "1px #999 dashed"}}>
                                                        <i className="dnd-icon ion-arrow-swap fa fa-rotate-90" />&nbsp;
                                                        <input type="checkbox" id="navigation" value={item} /> &nbsp;
                                                        <i className={menu[item].icon} /> {menu[item].name} &nbsp; &nbsp;&nbsp;
                                                        <small className="text-muted">{menu[item].link}</small>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*POWER*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="aclrole-resource_ids">Доступные права</label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="resource-field">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                Отмена
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> Сохранить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}