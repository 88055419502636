import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisable, findObject, calculateDiscount} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import {AuthContext} from "../../../Context/AuthContext";
import URL from "../../../Api/URL.json";
import currency from "../../../Assets/data/JSON/currency.json";

export default function ColumnsResponsive (props) {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // CONTEXT START
    const { lessonSetting, customerSetting, basicSetting } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // STATE START
    const [commission, setCommission] = useState(0);
    const [mark, setMark] = useState(null);
    const [mark_homework, setMarkHomework] = useState(null);
    const [note, setNote] = useState(null);
    const [reason, setReason] = useState(null);
    const [presence, setPresence] = useState(true);
    const [staticCommission, setStaticCommission] = useState(0);
    const [customerTariff, setCustomerTariff] = useState([]);
    const [customerDiscount, setCustomerDiscount] = useState([]);
    const [constCommission, setConstCommission] = useState(false);
    const [showTariff, setShowTariff] = useState({});
    // STATE END

    useEffect(() => {
        setCommission(props.data.commission);
        setMark(props.data.mark);
        setMarkHomework(props.data.mark_homework);
        setNote(props.data.note);
        setReason(props.data.reason);
        setPresence((props.data.presence !== null && props.data.presence !== undefined) ? props.data.presence : true);

        let arr_customer = props.customer.filter(item => {
            if (item.customer_id === props.data.customer_id) {
                item.commission = props.data.commission !== null && props.data.commission !== undefined ? props.data.commission : ( Number(commission) ? Number(commission) : 0 );
                item.mark = props.data.mark !== null && props.data.mark !== undefined ? props.data.mark : mark;
                item.reason = props.data.reason !== null && props.data.reason !== undefined ? props.data.reason : reason;
                item.note = props.data.note ? props.data.note : note;
                item.mark_homework = props.data.mark_homework !== null && props.data.mark_homework !== undefined ? props.data.mark_homework : mark_homework;

                if (props.editLesson.status === 2) {
                    item.presence = false;
                } else {
                    item.presence = props.data.presence !== null && props.data.presence !== undefined ? props.data.presence : presence;
                }

                return item;
            } else {
                return item;
            }
        })

        props.setCustomer(arr_customer);

        async function fetchData () {
            const tariffs = await request(`${URL.back_end}/customer_tariff/${props.data.customer_id}`, 'GET');
            const discounts = await request(`${URL.back_end}/customer_discount/${props.data.customer_id}`, 'GET');

            if (error) throw error;

            setCustomerTariff(tariffs.filter((item) => item.f_removed === 0));
            setCustomerDiscount(discounts);
        }

        fetchData().catch(e => console.log(e))

    }, [props.data])

    // HANDLER START
    function changerArr (key, value) {
        let arr_customer = props.customer.filter(item => {
            if (item.customer_id === props.data.customer_id) {
                item[key] = value;
                return item;
            } else {
                return item;
            }
        })

        props.setCustomer(arr_customer);
    }

    function commissionHandler (value) {
        setCommission(value);
        changerArr('commission', value);
    }

    function markHandler (value) {
        setMark(value);
        changerArr('mark', value);

        if (reason !== null) {
            setReason('');
            changerArr('reason', null);
        }
    }

    function reasonHandler (value) {
        setReason(value);
        changerArr('reason', value);

        if (mark !== null) {
            setMark('');
            changerArr('mark', null);
        }

        let obj = findObject(filterDisable(customerSetting.reason), value);
        let newCommission = obj.type === '1' ? staticCommission * Number(obj.value) : Number(obj.value);
        setCommission( newCommission );
        changerArr('commission', newCommission);
        setConstCommission(obj.type !== '1');
    }

    function presenceHandler (value) {
        setPresence(value);
        changerArr('presence',value);

        if (value === true) {
            setCommission(staticCommission);
            changerArr('commission', staticCommission);
        } else if (value === false) {
            let firstReason = filterDisable(customerSetting.reason)[0].id;
            reasonHandler(firstReason);
        }
    }

    function noteHandler (value) {
        setNote(value);
        changerArr('note', value);
    }

    function markHomeWorkHandler (value) {
        setMarkHomework(value);
        changerArr('mark_homework', value);
    }
    // HANDLER END

    // SUBJECT TRIGGER START
    useEffect(() => {

        if (Object.keys(props.subject).length === 0) return

        const currentTariff = customerTariff.filter(item =>
            JSON.parse(item.lessons).includes(props.subject.id) &&
            JSON.parse(item.type_lessons).includes(props.type_lesson) &&
            (
                new Date(item.date_start.split('T')[0]).getTime() <= new Date(props.editLesson.date_start.split('T')[0]).getTime()
                && new Date(item.date_end.split('T')[0]).getTime() >= new Date(props.editLesson.date_end.split('T')[0]).getTime()
            )
        );

        setShowTariff(currentTariff[0]);

        let calculateCommission = currentTariff.length > 0 ? currentTariff[0].price_per_lesson : 0;
        calculateCommission = calculateDiscount(customerDiscount, props.subject.id, props.type_lesson, props.editLesson.date_start, props.editLesson.date_end, calculateCommission);

        setStaticCommission(calculateCommission);

        if (props.editLesson.status !== 0) return

        commissionHandler(calculateCommission);

    }, [props.subject, customerTariff])
    // SUBJECT TRIGGER END

    // MINUTE TRIGGER START
    useEffect(() => {

        if (props.editLesson.status === 1 || constCommission) return

        const currentTariff = customerTariff.filter(item =>
            JSON.parse(item.lessons).includes(props.subject.id) &&
            JSON.parse(item.type_lessons).includes(props.type_lesson) &&
            (
                new Date(item.date_start.split('T')[0]).getTime() <= new Date(props.editLesson.date_start.split('T')[0]).getTime()
                && new Date(item.date_end.split('T')[0]).getTime() >= new Date(props.editLesson.date_end.split('T')[0]).getTime()
            )
        );

        if (currentTariff.length) {
            setShowTariff(currentTariff[0]);

            const [calculateCommission, lesson_duration] = [calculateDiscount(customerDiscount, props.subject.id, props.type_lesson, props.editLesson.date_start, props.editLesson.date_end, currentTariff[0].price_per_lesson), currentTariff[0].lesson_duration];

            if (!lesson_duration) return

            const result = (Number(props.minute) / lesson_duration).toFixed(2) * calculateCommission;
            commissionHandler(result);
            setStaticCommission(result);
        }

    }, [props.minute, customerTariff])

    useEffect(() => {

        if (props.editLesson.status === 0 || constCommission) return

        const currentTariff = customerTariff.filter(item =>
            JSON.parse(item.lessons).includes(props.subject.id) &&
            JSON.parse(item.type_lessons).includes(props.type_lesson) &&
            (
                new Date(item.date_start.split('T')[0]).getTime() <= new Date(props.editLesson.date_start.split('T')[0]).getTime()
                && new Date(item.date_end.split('T')[0]).getTime() >= new Date(props.editLesson.date_end.split('T')[0]).getTime()
            )
        );

        if (currentTariff.length) {
            const [calculateCommission, lesson_duration] = [calculateDiscount(customerDiscount, props.subject.id, props.type_lesson, props.editLesson.date_start, props.editLesson.date_end, currentTariff[0].price_per_lesson), currentTariff[0].lesson_duration];
            if (!lesson_duration) return

            const result = (Number(props.minute) / lesson_duration).toFixed(2) * calculateCommission;
            commissionHandler(result);
            setStaticCommission(result);
        }

    }, [props.minute])
    // MINUTE TRIGGER END

    // FUNC START
    function removeCustomer () {
        // eslint-disable-next-line no-restricted-globals
        const answer = confirm( translate('Вы уверены, что хотите удалить запись?') );

        if (answer) {
            const arr_customer = props.customer.filter(item => item.customer_id !== props.data.customer_id)
            props.setCustomer(arr_customer);
        }
    }
    // FUNC END

    return (
        <tr className={`details-item ${presence ? '' : 'warning'}`}>
            <td className="col-sm-4" style={{ verticalAlign: 'middle' }}>
                <a href={`/company/1/customer/view?id=${props.data.customer_id}`} target="_blank" className="crm-dashed-link" title={translate('Открыть карточку в новом окне')} style={{position: "absolute", left: "-5px"}}>
                    <i className="ion-arrow-up-c" />
                </a>
                {/*CHECKBOX*/}
                <label style={{cursor: "pointer", width: "100%"}}>
                    <input
                        type="checkbox"
                        className="details-attend"
                        checked={presence}
                        onChange={e => presenceHandler(e.target.checked)}
                    />
                    <span className="text-success"> {props.data.name} </span>
                </label>
            </td>
            {/*COMMISSION*/}
            <td className="col-sm-2">
                <div className="input-group input-group-sm">
                    {
                        auth.role.interface !== 2 ? (
                            <>
                                <input
                                    type="number"
                                    className="form-control input-sm details-commission hide-arrows"
                                    placeholder={translate('Комиссия')}
                                    value={commission}
                                    disabled={props.editLesson.status === 1}
                                    onChange={e => commissionHandler(e.target.value)}
                                />
                                <span className="input-group-addon hidden-xs"> { basicSetting && currency[basicSetting.baseOption.currency] } </span>
                            </>
                        ) : (
                            <span className="form-control input-sm details-commission" style={{ minWidth : '86.6px' }}>
                                { commission > 0 ? <span className="text-navy text-lowercase"> { translate('Абонемент') }  </span> : <span className="text-danger ext-lowercase"> { translate('Нет') } </span> }
                            </span>
                        )
                    }
                </div>
            </td>
            {/*MARK*/}
            <td className={`col-sm-2 ${!lessonSetting.lessonOption.mark && 'hide'}`}>
                {
                    presence && lessonSetting && customerSetting ?
                        (
                            <select className="form-control" value={mark} onChange={e => markHandler(e.target.value !== '' ? e.target.value : null)}>
                                <option value=''> </option>
                                {
                                    filterDisable(lessonSetting.mark).map(item => (
                                        <option value={item.id}> { item.ball } </option>
                                    ))
                                }
                            </select>
                        )
                        :
                        (
                            <select className="form-control" value={reason} onChange={e => reasonHandler(e.target.value ? e.target.value : null,)}>
                                {
                                    filterDisable(customerSetting.reason).map(item => (
                                        <option value={item.id}> { item.name } </option>
                                    ))
                                }
                            </select>
                        )
                }
            </td>
            {/* HOMEWORK MARK */}
            <td className={`col-sm-2 ${!lessonSetting.lessonOption.mark && 'hide'}`}>
                <select className="form-control" value={mark_homework} onChange={e => markHomeWorkHandler(e.target.value ? e.target.value : null)}>
                    <option value=''> </option>
                    {
                        lessonSetting && (
                            filterDisable(lessonSetting.mark).map(item => (
                                <option value={item.id}> { item.ball } </option>
                            ))
                        )
                    }
                </select>
            </td>
            {/*NOTE*/}
            <td className={`${lessonSetting.lessonOption.mark ? 'col-sm-4' : 'col-sm-5'}`}>
                <input
                    type="text"
                    className="form-control input-sm details-note"
                    placeholder={translate('Примечание')}
                    value={note}
                    onChange={e => noteHandler(e.target.value)}
                />
            </td>
            <td className="col-sm-2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {
                    showTariff ? (
                        <small className="text-success"> { showTariff.title } </small>
                    ) : (
                        <small className="text-danger"> { translate('Базовый счет') } </small>
                    )
                }
            </td>
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }} onClick={() => removeCustomer(props.data.customer_id)}>
                <a className="details-remove-btn">
                    <i className="fa fa-minus" />
                </a>
            </td>
        </tr>
    )
}