// PACKAGE
import React, {useState} from "react";

// FUNCTION
import { openModal } from "../../../../Function/common";

// ROW
import { QualificationRow } from "../Row/QualificationRow";

// MODAL
import { AddQualification } from "../Modal/Qualification/AddQualification";
import EditQualification from "../Modal/Qualification/EditQualification";

// HOOK
import { useTranslate } from "../../../../Hooks/translate.hook";

export const QualificationBlock = React.memo(function MemoQualBlock ({ sqlCard, setSqlCard }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editQualification, setEditQualification ] = useState({});
    // STATE END

    return (
        <>
            <h4 className="no-padding m-b-xs m-t-xs">
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal("add-qualification")}>
                    <span className="text-lowercase font-noraml"> { translate('Добавить') } </span>
                </a>
                <span className="text-muted"> { translate('Предмет и уровень') } </span>
            </h4>
            {
                sqlCard.qualification ?
                    JSON.parse(sqlCard.qualification).map(item => (
                        <QualificationRow
                            data={item}
                            setEditQualification={setEditQualification}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({ translate('не задано') })
                            </div>
                        </div>
                    </div>
            }

            {/*QUALIFICATION*/}
            <AddQualification
                sql={sqlCard}
                updateSql={setSqlCard}
            />

            <EditQualification
                sql={sqlCard}
                updateSql={setSqlCard}
                editQualifiaction={editQualification}
            />
        </>
    )
}, (prevProps, nextProps) => prevProps.sqlCard === nextProps.sqlCard)