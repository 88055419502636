// HOOK
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";

// JSON
import URL from "../../../Api/URL.json";

// COMPONENT
import SideMenu from "../../../Components/SideMenu/SideMenu";
import SalaryWidget from "../../../Components/Widget/SalaryWidget";
import EditEmployee from '../../Employee/Modal/EditEmployee';
import AcceptModal from "../../../Components/Modal/AcceptModal";
import Error from "../../Error/Error";
import UserBlock from "./Block/UserBlock";
import ScheduleBlock from "./Block/ScheduleBlock";
import QualBlock from "./Block/QualBlock";
import { ToastContainer } from 'react-toastify';
import TabAction from "../../../Components/TabAction";
import EmployeeSchedule from "../../../Components/Table/EmployeeSchedule";
import SalaryBlock from "./Block/SalaryBlock";
import {RegisterOfLesson} from "./Report/Modal/RegisterOfLesson";

// CONTEXT
import {SettingContext} from "../../../Context/SettingContext";

// FUNCTION
import {filterDisableEntity, findObject, openModal} from "../../../Function/common";

// IMAGE
import male from "../../../Assets/image/male.png";
import female from "../../../Assets/image/female.png";

// CSS
import 'react-toastify/dist/ReactToastify.css';
import PayrollStatement from "./Report/Modal/PayrollStatement";
import {useArchive} from "../../../Hooks/archive.hook";
import CommentBlock from "./Block/CommentBlock";
import ContactBlock from "../../../Components/Block/ContactBlock";
import DeleteModal from "../../../Components/Modal/DeleteModal";

export default function EmployeeCard () {

    // CONTEXT START
    const { integration, lessonSetting, customSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // NAVIGATE START
    const navigate = useNavigate();
    // NAVIGATE END

    // CUSTOMER ID START
    const [ searchParams ] = useSearchParams();
    const id = searchParams.get("id");
    // CUSTOMER ID END

    // STATE START
    const [ sqlCard, setSqlCard ]       = useState({});
    const [ groupsData, setGroupsData ] = useState([]);

       // DATA
    const [ employeeCustomers, setEmployeeCustomers ] = useState([]);
    const [ employeeLesson, setEmployeeLesson ] = useState([]);
    const [ groupEmployee, setGroupEmployee ]   = useState([]);
    const [ wage, setWage ]                     = useState([]);
    const [ managerData, setManagerData ]       = useState([]);
    const [ chooseChat, setChooseChat ]         = useState(1);
    const [ openChat, setOpenChat ]             = useState(false);
    // STATE END

    // INIT DATE START
    useEffect(() => {

        async function fetchData () {
            const data = await request(`${URL.back_end}/employee/${id}`, "GET");
            const groups = await request(`${URL.back_end}/group`, 'GET');
            const lessons = await request(`${URL.back_end}/employee_lesson/${id}`, "GET");
            const wages = await request(`${URL.back_end}/employee_wage/${id}`, 'GET');
            const managers = await request(`${URL.back_end}/user`, 'GET');
            const customers = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit: true, data: { employee: id } })
            const group_employee = await request(`${URL.back_end}/employee_group/${id}`, 'GET');

            if (error) throw error;

            setSqlCard(data[0]);
            setGroupsData(groups);
            setEmployeeLesson(lessons);
            setWage(wages);
            setManagerData(managers);
            setEmployeeCustomers(customers.customers);
            setGroupEmployee(group_employee);
        }

        fetchData().catch(e => console.log(e))

    }, [error, id, request])

    useEffect(() => {

        if (!integration || !sqlCard.branch) return

        const { telegram } = integration;

        const telegram_arr = telegram.filter(item => Number(item.branch) === Number(sqlCard.branch));

        if (telegram_arr.length) {
            const telegram_id = telegram_arr[0]?.id;
            setChooseChat(telegram_id);
        }

    }, [integration, sqlCard.branch])
    // INIT DATE END

    // BUTTON FUNC START
    async function buttonArchive () {
        await request(`${URL.back_end}/employee/${sqlCard.employee_id}`, 'PUT', { f_removed: !sqlCard.f_removed });

        if (!sqlCard.f_removed) {
            await pushArchive(4, 'employee', sqlCard.employee_id, { f_removed : 1 }, { f_removed : 0 })
        } else {
            await pushArchive(3, 'employee', sqlCard.employee_id, { f_removed : 0 }, { f_removed : 1 })
        }

        if (error) return console.log(error)

        window.location.reload();
    }

    function archiveHandler () {
        openModal('archive-employee-modal');
        openModal('archive-employee-overlay');
    }
    // BUTTON FUNC END

    // ANOTHER FUNC START
    function infoGender (gender) {
        if (gender === 1) {
            return ( <><i className="ion-man" /> {translate('Мужчина')}</> )
        } else if (gender === 2) {
            return ( <><i className="ion-woman" /> {translate('Женщина')}</> )
        } else {
            return ( <span className="text-danger">{translate('не задано (пол учителя)')}</span> )
        }
    }

    function ageParser (age) {
        if (!age) return <span className="text-danger">{translate('не задано (дата рождения)')}</span>;
        const birthDate = age.split('.');
        const currentDate = (new Date().toLocaleDateString()).split(".");
        return `(${+currentDate[2] - +birthDate[2]} ${translate('года')})`
    }

    function encodeImageFileAsURL(element) {
        let file = element.currentTarget.files[0];
        let reader = new FileReader();

        reader.onloadend = async function() {
            await request(`${URL.back_end}/employee/${sqlCard.employee_id}`, "PUT", {avatar: reader.result})
            document.getElementById("ava").src = `${reader.result}`;
        }
        reader.readAsDataURL(file);
    }
    // ANOTHER FUNC END

    if (error) return <Error text={`${translate('Педагога с')} id ${id} ${translate('не найдено')}`} />

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="employee" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight : "624px"}}>
                <div className="wrapper crm-content">
                    <div>
                        {/*ACTIONS*/}
                        <div className="row m-b-sm crm-stick crm-actions">
                            <div className="col-sm-12">
                                <div className="ibox-content border-bottom">
                                    {/*BUTTON BACK*/}
                                    <TabAction
                                        access={true}
                                        className="btn btn-sm btn-w-m btn-default btn-outline"
                                        icon="ion-arrow-left-c"
                                        text="Назад"
                                        handler={() => navigate(-1)}
                                    />
                                    {/*BUTTON EDIT*/}
                                    <TabAction
                                        access={true}
                                        className="btn btn-sm btn-w-m btn-success btn-outline"
                                        title={translate('Редактировать педагога')}
                                        icon="ion-edit"
                                        text="Править"
                                        handler={() => openModal('edit-employee')}
                                    />
                                    {/*BUTTON ARCHIVE*/}
                                    <TabAction
                                        access={sqlCard.f_removed === 0}
                                        className="btn btn-sm btn-w-m btn-danger btn-outline"
                                        title={translate('В архив')}
                                        icon="fa fa-trash-o"
                                        text="В архив"
                                        handler={() => archiveHandler()}
                                    />
                                    <TabAction
                                        access={sqlCard.f_removed === 1}
                                        className="btn btn-sm btn-w-m btn-primary btn-outline"
                                        title={translate('Из архива')}
                                        icon="fa fa-reply"
                                        text="Из архива"
                                        handler={() => archiveHandler()}
                                    />
                                    {/*BUTTON REPORT*/}
                                    <TabAction
                                        access={true}
                                        className="dropdown btn-group no-padding"
                                        icon="ion-printer"
                                        dropdown={{
                                            buttonClass : 'btn btn-sm btn-info btn-w-m btn-outline dropdown-toggle',
                                            text : 'Отчеты',
                                            list : [
                                                { link : '#', text : 'Выписка по зарплате', icon : 'fa fa-file-text-o', handler : () => openModal('payroll-statement') },
                                                { link : '#', text : 'Реестр проведённых уроков', icon : 'fa fa-file-text-o', handler : () => openModal('register-of-lesson') },
                                            ]
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*INFO*/}
                        <div className="row">
                            {/*GENERAL INFO*/}
                            <div className="col-lg-8">
                                <div className="ibox float-e-margins m-b-sm">
                                    <div className={`ibox-content border-bottom ${sqlCard.f_removed === 1 && 'archive'}`}>
                                        {/*AGE + GENDER*/}
                                        <span className="pull-right text-right text-muted">
                                            { sqlCard.age } { ageParser(sqlCard.age) }
                                            <br />
                                            { infoGender(sqlCard.gender )}
                                        </span>
                                        {/*AVATAR*/}
                                        <span className="pull-left m-r-sm">
                                            <label htmlFor="avatar">
                                                <img
                                                    className="img-thumbnail img-circle"
                                                    alt="image"
                                                    src={sqlCard.avatar || (sqlCard.gender === 'Мужчина' ? male : female)}
                                                    style={{width: "50px", height: "50px"}}
                                                />
                                            </label>
                                            <input type="file" id="avatar" onChange={e => encodeImageFileAsURL(e)} style={{display: "none"}} />
                                        </span>
                                        {/*NAME*/}
                                        <h2 className="font-noraml no-margins no-padding"> { sqlCard.name } </h2>
                                        {/*BRANCH*/}
                                        <h4 className="font-noraml m-t-md no-padding text-muted">
                                            <i className="ion-location" />
                                        </h4>
                                        {/*NOTE*/}
                                        {
                                            sqlCard.note &&
                                                <div className="border-left p-xxs text-muted m-b-xs">
                                                    { sqlCard.note.split('\n').map(item => (
                                                        <>
                                                            { item } <br/>
                                                        </>
                                                    )) }
                                                </div>
                                        }
                                        {/*TABLE*/}
                                        <div className="table-responsive">
                                            <h4 className="m-b-xs m-l-sm m-r-sm">{ translate('Расписание на ближайшие 7 дней') }</h4>
                                            <EmployeeSchedule
                                                employeeLesson={employeeLesson}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* FUTURE CHAT */}
                                {/*<div className="ibox m-b-sm">*/}
                                {/*    <div className="ibox-content border-bottom">*/}
                                {/*        <h4 style={{ margin : 0, padding : 0, cursor : 'pointer' }} onClick={() => setOpenChat(!openChat)}>*/}
                                {/*            { translate('Чат') }*/}
                                {/*            <span style={{ marginRight : '12px', float : 'right' }}>*/}
                                {/*                <span className="arrow_down" data-is-active={openChat}>*/}
                                {/*                    <img src={arrow_down} alt='arrow' />*/}
                                {/*                </span>*/}
                                {/*            </span>*/}
                                {/*        </h4>*/}
                                {/*        <Collapse isOpened={openChat}>*/}
                                {/*            <Chat*/}
                                {/*                integration={integration}*/}
                                {/*                sqlCard={sqlCard}*/}
                                {/*                chooseChat={chooseChat}*/}
                                {/*                access={true}*/}
                                {/*            />*/}
                                {/*        </Collapse>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <CommentBlock
                                    sqlCard={sqlCard}
                                    managerData={managerData}
                                />
                            </div>
                            {/*ANOTHER INFO*/}
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins m-b-sm">
                                    <div className="ibox-content border-bottom">
                                        <div className="row">
                                            {/*ID*/}
                                            <div className="col-xs-5 text-muted m-b-xs"> ID </div>
                                            <div className="col-xs-7 m-b-xs text-right"> #{sqlCard.employee_id} </div>
                                            {/*LESSON*/}
                                            <div className="col-xs-5 text-muted m-b-xs"> {translate('Уроки')} </div>
                                            <div className="col-xs-7 m-b-xs text-right">
                                                { employeeLesson.length } { translate('штук') }
                                            </div>
                                            {/*CUSTOMERS*/}
                                            <div className="col-xs-5 text-muted m-b-xs"> Індивідуальні </div>
                                            <div className="col-xs-7 m-b-xs text-right">
                                                <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({employee: sqlCard.employee_id}))}`}>
                                                    { employeeCustomers.length } { translate('штук') }
                                                </Link>
                                            </div>
                                            {/*GROUPS*/}
                                            <div className="col-xs-5 text-muted m-b-xs"> Групові </div>
                                            <div className="col-xs-7 m-b-xs text-right">
                                                <Link to={`/company/1/group/index/${encodeURI(JSON.stringify({employee: sqlCard.employee_id}))}`}>
                                                    { groupEmployee.length } { translate('штук') }
                                                </Link>
                                            </div>
                                            {
                                                sqlCard.status && (
                                                    <>
                                                        <div className="col-xs-5 text-muted m-b-xs"> { translate('Статус') } </div>
                                                        <div className="col-xs-7 m-b-xs text-right text-navy"> { sqlCard.status } </div>
                                                    </>
                                                )
                                            }
                                            {
                                                sqlCard.subjects &&
                                                    (
                                                        <>
                                                            <div className="col-xs-5 text-muted m-b-xs"> {translate('Предметы')} </div>
                                                            <div className="col-xs-7 m-b-xs text-right"> {JSON.parse(sqlCard.subjects).map(item => findObject(lessonSetting.subject, +item).name).join(", ")} </div>
                                                        </>
                                                    )
                                            }
                                            {/* CUSTOM FIELD */}
                                            {
                                                customSetting &&
                                                    filterDisableEntity(customSetting.customField, "employee").map(item => sqlCard[item.systemName] && (
                                                        <>
                                                            <div className="col-xs-5 text-muted m-b-xs"> {item.name} </div>
                                                            <div className="col-xs-7 m-b-xs text-right">
                                                                {
                                                                    sqlCard[item.systemName] ? typeof JSON.parse(sqlCard[item.systemName]) === "object" ? JSON.parse(sqlCard[item.systemName]).length !== 0 ? JSON.parse(sqlCard[item.systemName]).map(item => (<>{item} <br /></>)) : <span className="text-danger text-lowercase"> Не задано </span> : sqlCard[item.systemName] : <span className="text-danger text-lowercase"> Не задано </span>
                                                                }
                                                            </div>
                                                        </>
                                                    ))
                                            }
                                        </div>
                                        {/*CONTACTS*/}
                                        <ContactBlock
                                            contact={sqlCard.contact}
                                            access={true}
                                        />
                                        {/*<hr className="hr-line-dashed m-t-sm m-b-sm" />*/}
                                        {/* BALANCE */}
                                        {/*<BalanceBlock*/}
                                        {/*    sqlCard={sqlCard}*/}
                                        {/*    wage={wage}*/}
                                        {/*    employeeLesson={employeeLesson}*/}
                                        {/*    access={true}*/}
                                        {/*/>*/}
                                        <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                        <ScheduleBlock
                                            sqlCard={sqlCard}
                                            setSqlCard={setSqlCard}
                                        />
                                        {/*QUALIFICATIONS*/}
                                        <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                        <QualBlock
                                            sqlCard={sqlCard}
                                            setSqlCard={setSqlCard}
                                        />
                                        {/*SALARY*/}
                                        <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                        <SalaryBlock
                                            sqlCard={sqlCard}
                                            wage={wage}
                                            setSqlCard={setSqlCard}
                                        />
                                        {/*ACCESS*/}
                                        <hr className="hr-line-dashed m-t-sm m-b-sm" />
                                        <UserBlock
                                            sqlCard={sqlCard}
                                            setSqlCard={setSqlCard}
                                        />
                                    </div>
                                </div>
                                {/*SALARY WIDGET*/}
                                <SalaryWidget
                                    employeeLesson={employeeLesson}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <RegisterOfLesson
                sqlCard={sqlCard}
            />

            <PayrollStatement
                sqlCard={sqlCard}
            />

            {/*EDIT EMPLOYEE*/}
            <EditEmployee
                updateSql={setSqlCard}
                employeeEdit={sqlCard}
                groupsData={groupsData}
                typeModal="card"
            />

            {/*ACCEPT MODAL*/}
            <AcceptModal />

            <DeleteModal
                id="archive-employee"
                handler={buttonArchive}
                text={'Ви впевнені, що хочете архівувати/розархівувати педагога?'}
            />
        </div>
    )
}