import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {openModal} from "../../../../Function/common";
import URL from "../../../../Api/URL.json";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditAccount from "./EditModal/EditAccount";
import $ from 'jquery';
import 'jquery-ui-bundle';
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/paySetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'account'}),
                success: function(data){
                    setting.paySetting = data;
                }
            });
        }
    });
}

export default function PayAccount () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const { basicSetting, paySetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.paySetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // STATE START
    const [editAccount, setEditAccount] = useState({});
    const [deleteAccount, setDeleteAccount] = useState({});
    // STATE END

    // COMP FUNC START
    async function disabledAccount (data) {
        paySetting.account = paySetting.account.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
    }

    function deleteHandler (item) {
        setDeleteAccount(item);
        openModal('delete-pay-account-overlay');
        openModal('delete-pay-account-modal');
    }

    async function deleteAccountHandler () {
        paySetting.account = paySetting.account.filter(item => {
            if (item.id !== deleteAccount.id) {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})

        window.location.reload();
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{ basicSetting?.baseOption.nameCompany } — {translate('Кассы и счета')} <span className="text-muted">({paySetting ? paySetting.account.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-md">
                    {translate(
                        `Кассы и счета необходимы для сегментирования доходов и расходов. Например, деньги поступающие в виде
                    наличных отправляем в кассу, а принятые через терминал — на Счет №1.`
                    )}
                </p>
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon">
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-block btn-sm btn-success text-lowercase" onClick={() => openModal("add-account")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed border-bottom m-b-md">
                    <tbody className="sortable-list ui-sortable">
                        {
                           paySetting ?
                               paySetting.account.map(item => (
                                   <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {opacity: 0.5} : {}} data-id={`${item.id}`}>
                                       <td>
                                           <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                           <span className="text-muted"> [ID {item.id}]</span>
                                       </td>
                                       <td className="text-right">
                                           <span className="text-muted m-r-sm" title={translate('Количество платежных документов')}> {item.payment} <i className="fa fa-money" /></span>
                                           <span className="text-muted m-r-sm" title={translate('Количество пользователей, имеющих доступ')}> {item.allowed.length} <i className="fa fa-unlock" /></span>
                                           &nbsp;&nbsp;
                                           <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledAccount(item)}>
                                               <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                           </a>
                                           <a className="btn btn-xs btn-white m-r-xxs" title={translate('Править кассу (счет)')} onClick={() => {openModal("edit-account"); setEditAccount(item)}}>
                                               <i className="fa fa-pencil text-success fa-fw" />
                                           </a>
                                           <a className="btn btn-xs btn-white" title={translate('Удалить кассу (счет)')} onClick={() => deleteHandler(item)}>
                                               <i className="fa fa-trash-o text-danger fa-fw" />
                                           </a>
                                       </td>
                                   </tr>
                               ))
                           : null
                        }
                    </tbody>
                </table>
            </div>
            <EditAccount
                editAccount={editAccount}
            />
            <DeleteModal
                id="delete-pay-account"
                handler={deleteAccountHandler}
            />
        </>
    )
}