import {openModal} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";

export default function StreamLesson () {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title"> {translate('Онлайн стримы')} </h4>
            </div>
            <div className="modal-body p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-md">
                    {translate(
                        `Онлайн стримы — это трансляции онлайн занятий, которые вы можете
                    проводить в вашей компании. После добавления, стрим можно привязать к аудитории, группе или педагогу
                    и использовать в ЛК клиента, рассылках и т.д.`
                    )}
                </p>
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate('Фильтр по названию или URL')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-block btn-success text-lowercase" onClick={() => openModal("add-stream")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">

                    </tbody>
                </table>
            </div>
        </>
    )
}