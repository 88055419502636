import React, {useContext, useEffect, useRef, useState} from "react";
import {closeModal, filterDisable, filterDisableEntity} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useNavigate} from "react-router-dom";
import {SettingContext} from "../../../Context/SettingContext";
import {typeField} from "../../../Function/TypeField";
import UIMenuInput from "../../../Components/InputChoose/UIMenuInput";

export default function FilterLead ({ value, managerData, employeeData, viewMode }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, salesSetting, integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [ source, setSource ] = useState('');
    const [ name, setName ] = useState('');
    const [ manager, setManager ] = useState('');
    const [ employee, setEmployee ] = useState('');
    const [ leadStatus, setLeadStatus ] = useState('');
    const [ branch, setBranch ] = useState('');
    const [ refusal, setRefusal ] = useState('');
    const [ gender, setGender ] = useState('');
    const [ ageMin, setAgeMin ] = useState('');
    const [ ageMax, setAgeMax ] = useState('');
    const [ contact, setContact ] = useState('');
    const [ mainTags, setMainTags ] = useState('');
    const [ f_removed, setFremoved ] = useState('');
    const [ noTask, setNoTask ] = useState('');
    const [ withChat, setWithChat ] = useState('');
    const [ noComment, setNoComment ] = useState('');
    const [ minCdate, setMinCdate ] = useState('');
    const [ maxCdate, setMaxCdate ] = useState('');

        // SEARCH
    const [employeeSearch, setEmployeeSearch] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!value) {
            setSource('');
            setName('');
            setManager('');
            setEmployee('');
            setMinCdate('');
            setMaxCdate('');
            setLeadStatus('');
            setRefusal('');
            setGender('');
            setAgeMin('');
            setAgeMax('');
            setContact('');
            setMainTags('');
            setFremoved('');
            setNoTask('');
            setNoComment('');
            setBranch('');
            setWithChat('');

            customSetting && filterDisableEntity(customSetting.customField, 'customer').filter(item => item?.type !== '13').map(item => document.forms["filter_lead"].elements[item.systemName].value = '');

            return
        }

        setSource(value.source);
        setName(value.name);
        setManager(value.manager);
        setEmployee(value.employee);
        setMinCdate(value.minCdate);
        setMaxCdate(value.maxCdate);
        setLeadStatus(value.leadStatus);
        setRefusal(value.refusal);
        setGender(value.gender);
        setAgeMin(value.ageMin);
        setAgeMax(value.ageMax);
        setContact(value.contact);
        setMainTags(value.mainTags);
        setFremoved(value.f_removed);
        setNoTask(value.noTask);
        setNoComment(value.noComment);
        setBranch(value.branch);
        setWithChat(value.withChat);

        customSetting && filterDisableEntity(customSetting?.customField, 'customer').filter(item => item?.type !== '13').map(item => document.forms["filter_lead"].elements[item.systemName].value = value[item.systemName] ? value[item.systemName] : '');

    }, [value])
    // INIT DATA END

    // FILTER LEAD START
    function filterLead (e) {

        e.preventDefault();

        let lead = {
            source,
            name,
            manager,
            employee,
            minCdate,
            maxCdate,
            leadStatus,
            refusal,
            gender,
            ageMin,
            ageMax,
            contact,
            mainTags,
            f_removed,
            noTask,
            noComment,
            branch,
            withChat
        }

        filterDisableEntity(customSetting.customField, 'customer').filter(item => item.type !== '13').map(item => {
            const value = document.forms["filter_lead"].elements[item.systemName].value;
            lead[item.systemName] = value.length ? value : null;
        })

        let obj = Object.filter(lead, (value) => value);

        if (!Object.keys(obj).length) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/lead/index/${encodeURI(JSON.stringify(obj))}${viewMode ? `?view_mode=${viewMode}` : ''}`);
            closeModal(e, modal);
        }

    }
    // FILTER LEAD END

    // FUNCTION START
    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div id="filter-lead" className="modal in" style={{ paddingLeft : '17px' }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" name="filter_lead" onSubmit={e => filterLead(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                                <span className="sr-only"> { translate('Закрыть') } </span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        <div className="modal-body modal-body-search p-md">
                            {/* SOURCE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Источник') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={source} onChange={e => setSource(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            salesSetting && filterDisable(salesSetting.leadSource).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* NAME */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('ФИО') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* MANAGER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Ответственный') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={manager} onChange={e => setManager(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={-1}> { translate('Без ответственного') } </option>
                                        {
                                            sortArray(managerData, 'name').filter(item => item.f_removed === 0).map(item => (
                                                <option value={item.user_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Педагог') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <UIMenuInput
                                        data={employeeData}
                                        searchText={employeeSearch}
                                        setSearchText={setEmployeeSearch}
                                        setId={setEmployee}
                                        value={employee}
                                        placeholder="Поиск педагога"
                                        type_id="employee_id"
                                        topic="name"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Дата створення
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={minCdate}
                                                onChange={e => setMinCdate(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={maxCdate}
                                                onChange={e => setMaxCdate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* LEAD STATUS */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Этап воронки') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={leadStatus} onChange={e => setLeadStatus(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={0}> { translate('Не разобрано') } </option>
                                        {
                                            salesSetting && filterDisable(salesSetting.leadStatus).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* REFUSAL */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Причина отказа') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={refusal} onChange={e => { setRefusal(e.target.value); setFremoved('1'); }}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            salesSetting && filterDisable(salesSetting.leadReject).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* GENDER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Пол') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={gender} onChange={e => setGender(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='1'> { translate('Мужчина') } </option>
                                        <option value='2'> { translate('Женщина') } </option>
                                    </select>
                                </div>
                            </div>
                            {/* AGE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="CustomerSearch_f_age">
                                        { translate('Возраст') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={ translate('От') }
                                                value={ageMin}
                                                onChange={e => setAgeMin(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={ translate('До') }
                                                value={ageMax}
                                                onChange={e => setAgeMax(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* CONTACT */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="CustomerSearch_f_phone">
                                        { translate('Контакт') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contact}
                                        onChange={e => setContact(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* MAIN TAGS */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Теги') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => setMainTags(e.target.value)} value={mainTags}>
                                        <option value=''> </option>
                                        { salesSetting ? filterDisable(salesSetting.tags).map(item => (<option value={item.name}> { item.name } </option>)) : null }
                                    </select>
                                </div>
                            </div>
                            {/* ARCHIVE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Архив') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => setFremoved(e.target.value)} value={f_removed}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='0'> { translate('Активные') } </option>
                                        <option value='1'> { translate('Архивные') } </option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Бот за замовчуванням
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    {
                                        integration ?
                                            (
                                                <select className="form-control" onChange={e => setBranch(e.target.value)}>
                                                    <option value=''> { translate('Не задано') } </option>
                                                    { integration.telegram.map(item => <option key={item.id} value={item.id}> { item.username } </option>) }
                                                </select>
                                            )
                                            : null
                                    }
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, 'customer').filter(item => item.type !== '13').map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-4">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-8">
                                                { typeField(item) }
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> Заявки з підключеним чатом </label>
                                </div>
                                <div className="col-sm-8">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={withChat}
                                            onChange={e => setWithChat(e.target.checked ? '1' : '')}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> Заявки без задач </label>
                                </div>
                                <div className="col-sm-8">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={noTask}
                                            onChange={e => setNoTask(e.target.checked ? '1' : '')}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> Заявки без коментарів </label>
                                </div>
                                <div className="col-sm-8">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={noComment}
                                            onChange={e => setNoComment(e.target.checked ? '1' : '')}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-sm btn-danger btn-outline pull-left" title={translate('Сбросить фильтр и вернуться в таблицу')} onClick={e => {
                                closeModal(e, modal);
                                navigate(`/company/1/lead/index/${viewMode ? `?view_mode=${viewMode}` : ''}`)
                            }}>
                                <i className="fa fa-eraser fa-fw" />
                            </a>
                            <a className="btn btn-sm btn-success btn-outline pull-left" title={translate('Настройка полей')}>
                                <i className="fa fa-cog fa-fw" />
                            </a>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}