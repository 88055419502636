import React, {useEffect, useState} from "react";
import {openModal} from "../../../../Function/common";
import DiscountRow from "../Row/DiscountRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import { AddDiscount } from "../Modal/Discount/AddDiscount";
import { EditDiscount } from "../Modal/Discount/EditDiscount";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export const DiscountBlock = React.memo(({ sqlCard, setSqlCard, access }) => {

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ discounts, setDiscounts ] = useState([]);
    const [ editDiscount, setEditDiscount ] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!Object.keys(sqlCard).length) return

        async function fetchData () {

            const data = await request(`${URL.back_end}/customer_discount/${sqlCard.customer_id}`, 'GET');

            setDiscounts(data);

        }

        fetchData().catch(e => console.log(e));

    }, [ sqlCard ])
    // INIT DATA END

    if (!access) return

    return (
        <>
            <hr className="hr-line-dashed m-t-sm m-b-sm" />
            {/*HEADER*/}
            <h4 className="no-padding m-b-xs m-t-xs">
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal("add-discount")}>
                    <span className="text-lowercase font-noraml"> { translate('Добавить') } </span>
                </a>
                <span className="text-muted"> { translate('Скидки') } </span>
            </h4>
            {/*BODY*/}
            {
                discounts.length ?
                    discounts.map(item => (
                        <DiscountRow
                            data={item}
                            setEditDiscount={setEditDiscount}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({ translate('не задано') })
                            </div>
                        </div>
                    </div>
            }

            {/*DISCOUNT*/}
            <AddDiscount
                sql={sqlCard}
                updateSql={setSqlCard}
            />

            <EditDiscount
                sql={sqlCard}
                updateSql={setSqlCard}
                editDiscount={editDiscount}
            />
        </>
    )
}, (prevProps, nextProps) => prevProps.sqlCard === nextProps.sqlCard)