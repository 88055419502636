import {useRef} from "react";
import {closeModal} from "../../../../../Function/common";
import {useTranslate} from "../../../../../Hooks/translate.hook";

export default function AddStream () {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <div id="add-stream" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить стрим')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*NAME*/}
                            <div className="form-group field-streaming-name required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="streaming-name">{translate('Наименование')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, Zoom room 1`}
                                    />
                                </div>
                            </div>
                            {/*PURPOSE*/}
                            <div className="form-group field-streaming-types required">
                                <div className="col-sm-4">
                                    <label className="control-label">{translate('Предназначение')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div id="streaming-types">
                                        <label className="no-margins">
                                            <input type="checkbox" name="Streaming[types][]" value="1" /> {translate('Для аудиторий')}
                                        </label>
                                        <br />
                                        <label className="no-margins">
                                            <input type="checkbox" name="Streaming[types][]" value="2" /> {translate('Для групп')}
                                        </label>
                                        <br />
                                        <label className="no-margins">
                                            <input type="checkbox" name="Streaming[types][]" value="3" /> {translate('Для педагогов')}
                                        </label>
                                        <br />
                                        <label className="no-margins">
                                            <input type="checkbox" name="Streaming[types][]" value="4" /> {translate('Для регулярного расписания')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/*URL*/}
                            <div className="form-group field-streaming-url required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="streaming-url">{translate('URL стрима')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, https://zoom.us/321456987`}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}