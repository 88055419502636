import {useState, useCallback, useEffect} from "react";
import jwt_decode from "jwt-decode";

const storageName = 'userData';

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [role, setRole] = useState(null);
    const [name, setName] = useState(null);
    const [interface_language, setInterface_language] = useState(null);
    const [systemId, setSystemId] = useState(null);

    const login = useCallback((jwt, id, rul, username, sysId, intLang) => {
        setToken(jwt);
        setUserId(id);
        setRole(rul);
        setName(username);
        setSystemId(sysId);
        setInterface_language(intLang);

        localStorage.setItem(storageName, JSON.stringify({
            token: jwt
        }))
    },[])

    const logout = useCallback(() => {
        setToken(null);
        setUserId(null);
        setRole(null);
        setName(null);
        setInterface_language(null);
        setSystemId(null);

        localStorage.removeItem(storageName);
        localStorage.removeItem('calendar_date');
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName));

        if (data && data.token) {
            const decode = jwt_decode(data.token);

            login(data.token, decode.user_id, decode.role, decode.name, decode.systemId, decode.interface_language);
        }
    }, [login])

    return { login, logout, token, userId, role, name, interface_language, systemId };
}