import {Link} from "react-router-dom";
import React, {memo} from "react";
import LessonColumns from "./LessonColumns";
import {useTranslate} from "../../../Hooks/translate.hook";

export const LessonTable = memo(({ sql, updateSql, updateRows, customerLesson, employeeLesson, loading  }) => {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // LESSON TABLE FUNC START
    function selection_all(e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;
        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            updateRows(tempSelection);
        }
        else {
            selection.forEach(element => element.checked = false);
            updateRows([])
        }
    }

    function renderTbody () {

        if (loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (sql.length) {
            return sql.map(item => (
                <LessonColumns
                    data={item}
                    sql={sql}
                    customersData={customerLesson}
                    employeesData={employeeLesson}
                    updateSql={updateSql}
                    updateRows={updateRows}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={11}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // LESSON TABLE FUNC END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.64%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection_all"
                                onClick={e => selection_all(e)}
                            />
                        </th>
                        <th style={{width: "8.36%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=id"> { translate('Тип урока') } </Link>
                        </th>
                        <th style={{width: "9.76%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=lesson_date"> { translate('Дата') } </Link>
                        </th>
                        <th style={{width: "13.61%"}}>
                            { translate('Участники') }
                        </th>
                        <th style={{width: "7.32%"}}>
                            {translate('Время')}
                        </th>
                        <th style={{width: "11.06%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=subject_id" data-sort="subject_id">{translate('Предмет')}</Link>
                        </th>
                        <th style={{width: "13.51%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=teacher_ids" data-sort="teacher_ids">{translate('Педагог(и)')}</Link>
                        </th>
                        <th style={{width: "12.33%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=topic" data-sort="topic">{translate('Тема')}</Link>
                        </th>
                        <th style={{width: "100.9%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=homework" data-sort="homework">{translate('Домашнее задание')}</Link>
                        </th>
                        <th style={{width: "10.18%"}}>
                            <Link to="/company/1/lesson/index?lang=en-En&amp;sort=earning" data-sort="earning">{translate('Комиссия')}</Link>
                        </th>
                        <th style={{width: "13.74%"}}>
                            {translate('Комментарий')}
                        </th>
                    </tr>
                </thead>
                <tbody> { renderTbody() } </tbody>
            </table>
        </div>
    )
})