const VISIBLE_PAGES = 5;

function paginationCount (length, size) {
	let count = 0;

	while (length > 0) {
		length -= size;
		count += 1;
	}

	return Array.from({length: count}, (_, i) => i + 1);
}

export default function ChatPagination({ counts, page, setPage }) {

	if (!counts) return;

	const PAGINATION_COUNTS = paginationCount(counts, 10).length;

	function prevPageHandler (event) {
		event.preventDefault();
		if (page > 1) setPage(page - 1);
	}

	function nextPageHandler (event) {
		event.preventDefault();
		if (page < PAGINATION_COUNTS) setPage(page + 1);
	}

	let startPage = Math.max(page - Math.floor(VISIBLE_PAGES / 2), 1);
	let endPage = Math.min(startPage + VISIBLE_PAGES - 1, PAGINATION_COUNTS);

	if (endPage - startPage + 1 < VISIBLE_PAGES) {
		startPage = Math.max(endPage - VISIBLE_PAGES + 1, 1);
	}

	return (
	  <ul className="pagination" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
		  <li className={`prev ${page > 1 ? '' : 'disabled'}`}>
			  <a onClick={(event) => prevPageHandler(event)}>
				  «
			  </a>
		  </li>
		  {
			  Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(item => (
				<li className={+page === item ? 'active' : ''}>
					<a onClick={() => setPage(item)}>
						{item}
					</a>
				</li>
			  ))
		  }
		  <li className={`next ${page < PAGINATION_COUNTS ? '' : 'disabled'}`}>
			  <a onClick={(event) => nextPageHandler(event)}>
				  »
			  </a>
		  </li>
	  </ul>
	)
}