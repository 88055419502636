import {Link} from "react-router-dom";
import { useParams } from 'react-router-dom';
import CustomerColumns from "./CustomerColumns";
import ArchiveModal from "../../../Components/Modal/ArchiveModal";
import {useEffect, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import React from "react";

function MemoCustomerTable (props) {

    // STATE START
    const [ custId, setCustId ] = useState('');
    const [ name, setName ] = useState('');
    const [ groupsData, setGroupsData ] = useState([]);
    const [ tariffsData, setTariffsData ] = useState([]);
    const [ employeesData, setEmployeesData ] = useState([]);
    const [ filterStatus, setFilterStatus ] = useState(false);
    // STATE END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // INIT DATA START
    useEffect(() => {

        async function fetchData() {
            const groups = await request(`${URL.back_end}/customer_group`, 'GET');
            const tariffs = await request(`${URL.back_end}/customer_tariff`, 'GET');
            const employees = await request(`${URL.back_end}/customer_employee`, 'GET');

            if (error) throw error;

            setGroupsData(groups);
            setTariffsData(tariffs);
            setEmployeesData(employees);
        }

        fetchData().catch(e => console.log(e));

    }, [error, request])
    // INIT DATA END

    // CUSTOMER TABLE FUNC START
    function filterDateArchive () {
        document.getElementById('data_a_arrow').style.display = 'inline-block';

        setFilterStatus(!filterStatus);

        props.updateSql(props.sql.sort((a, b) => filterStatus ? new Date(a.date_a) - new Date(b.date_a) : new Date(b.date_a) - new Date(a.date_a)));
    }

    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;

        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            props.updateRows(tempSelection);
        }
        else {
            selection.forEach(element => element.checked = false);
            props.updateRows([])
        }
    }

    function renderTbody () {

        if (props.loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (props.sql.length) {
            return props.sql.map(item => (
                <CustomerColumns
                    data={item}
                    sql={props.sql}
                    updateSql={props.updateSql}
                    updateRows={props.updateRows}
                    managerData={props.managerData}
                    groupsData={groupsData}
                    tariffsData={tariffsData}
                    employeesData={employeesData}
                    setCustId={setCustId}
                    setName={setName}
                    access={props.access}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // CUSTOMER TABLE FUNC END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container scroll-block">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.83%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection-all"
                                onClick={e => selection_all(e)}
                            />
                        </th>
                        <th style={{width: "16.62%"}}>
                            {translate('Статус обучения')}
                        </th>
                        <th style={{width: "14%"}}>
                            {translate('ФИО')}
                        </th>
                        <th style={{width: "19.14%"}}>
                            {translate('Ответственный')}
                        </th>
                        <th style={{width: "11.62%"}}>
                            {translate('Группы')}
                        </th>
                        {
                            props.access ? (
                                <>
                                    <th style={{width: "15.68%"}}>
                                        {translate('Общий остаток')}
                                    </th>
                                    <th style={{width: "14.28%"}}>
                                        {translate('Абонементы')}
                                    </th>
                                </>
                            ) : null
                        }
                        <th style={{width: "13.67%"}}>
                            {translate('Отв. педагог')}
                        </th>
                        <th style={{ width : '8%' }}>
                            { translate('Чаты') }
                        </th>
                        {
                            filterParams ? JSON.parse(filterParams).f_removed === 1 ? (
                              <th className="text-success" style={{ width: '10%', cursor: 'pointer', userSelect: 'none' }} onClick={() => filterDateArchive()}>
                                  Дата архівації <span id="data_a_arrow" style={{ display: 'none' }}>{filterStatus ? '↓' : '↑'}</span>
                              </th>
                            ) : null : null
                        }
                        {
                            props.access ? (
                                <th style={{width: "3.8%"}}>
                                    &nbsp;
                                </th>
                            ) : null
                        }
                    </tr>
                </thead>
                <tbody> { renderTbody() } </tbody>
            </table>

            <ArchiveModal
                customer_id={custId}
                name={name}
                sql={props.sql}
                updateSql={props.updateSql}
            />
        </div>
    )
}

export const CustomerTable = React.memo(MemoCustomerTable);