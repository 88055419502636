import {openModal} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";
import ScheduleModal from "../Modal/ScheduleModal";
import ScheduleRow from "../Row/ScheduleRow";

export default function ScheduleBlock ({ sqlCard, setSqlCard }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function scheduleRow (day) {
        let scheduleObj = sqlCard.schedule ? JSON.parse(sqlCard.schedule) : null;

        if (scheduleObj[day].length !== 0) {
            return (
                <ScheduleRow
                    day={day}
                    time={scheduleObj[day]}
                />
            )
        } else {
            return null;
        }
    }

    return (
        <>
            <p className="no-padding m-b-xs m-t-xs" onClick={() => openModal("add-schedule")}>
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right">
                    <span className="text-lowercase font-normal">{translate('Править')}</span>
                </a>
                <strong className="text-muted">{translate('График работы')}</strong>
            </p>
            {
                sqlCard.schedule ?
                    <a className="crm-hover-block crm-modal-btn p-xxs" title={translate('График работы')} style={{display: "block"}} onClick={() => openModal("add-schedule")}>
                        {[0,1,2,3,4,5,6].map(item => scheduleRow(item))}
                    </a>
                    :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({translate('не задано')})
                            </div>
                        </div>
                    </div>
            }

            {/*SCHEDULE*/}
            <ScheduleModal
                sql={sqlCard}
                updateSql={setSqlCard}
                editSchedule={sqlCard.schedule ? JSON.parse(sqlCard.schedule) : null}
            />
        </>
    )
}