import {openModal} from "../../../../Function/common";
import {useContext, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import typeField from "../../../../Assets/data/JSON/typeField.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditCustomField from "./Modal/EditCustomField";

export default function CustomField () {

    // CONTEXT START
    const {customSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [editCustomField, setEditCustomField] = useState({});
    // STATE END

    // VALUE START
    const means = [{"customer" : `${translate('Карточка клиента')}`}, {"group" : `${translate('Карточка группы')}`}, {"employee" : `${translate('Карточка педагога')}`}, {"tariff": `${translate('Абонементы')}`}, {"user" : `${translate('Модель пользователя')}`}, {"subject" : `${translate('Модель предмета')}`}, {"pay" : `${translate('Модель платежа')}`}, {"lesson" : `${translate('Модель урока')}`}, {"discount" : `${translate('Модель скидки')}`}]
    // VALUE END

    // COMP FUNC START
    async function disabledField (data) {
        customSetting.customField = customSetting.customField.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {customSetting: JSON.stringify(customSetting)})
        if (error) return console.log(error)
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Дополнительные поля')} <span className="text-muted">({customSetting ? customSetting.customField.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-md">
                    {translate(
                        `Дополнительные поля — отличная возможность расширить функционал CRM системы. В них можно хранить
                    дополнительную информацию в отдельных ячейках, а так же фильтровать и сортировать данные по
                    ним.`
                    )}
                </p>
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                             <div className="input-group input-group-sm">
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate('Фильтр по названию')}
                                 />
                                 <span className="input-group-addon" style={{cursor: "pointer"}}>
                                     <i className="ion-search" />
                                 </span>
                             </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="pull-left btn btn-sm btn-block btn-success crm-modal-btn text-lowercase" onClick={() => openModal("add-custom-field")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <div className="border-bottom" />
                <div className="m-b-md">
                    {
                        customSetting ?
                            means.map(mean => {

                                let result_arr = [];
                                let bool = true;

                                customSetting.customField.map(field => {
                                    if (mean[field.entity] !== undefined) {

                                        bool = false;

                                        result_arr.push(
                                            <div className="border-bottom sortableCustomer-item row ui-sortable-handle">
                                                <div className="col-xs-9" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                    <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} />
                                                    <small style={field.disabled ? {opacity: 0.5} : null}> {field.name}<span className="text-muted"> [{field.systemName}] </span> — {typeField[field.type]} {field.option ? (`[${field.option.map(item => ` ${item} `)}]`) : null}</small>
                                                </div>
                                                <div className="col-xs-3 text-right" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                    <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledField(field)}>
                                                        <i className={`fa ${field.disabled ? "" : "text-navy"} fa-lg fa-toggle-${field.disabled ? "off" : "on"} fa-fw`} />
                                                    </a>
                                                    <a className="btn btn-xs btn-white crm-modal-btn" title={translate('Править дополнительное поле')} onClick={() => {openModal("edit-custom-field"); setEditCustomField(field)}}>
                                                        <i className="fa fa-pencil text-success fa-fw" />
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                                if (!bool) {
                                    return (
                                        <>
                                            <div className="border-bottom" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                                                <p className="text-navy font-bold no-padding no-margins"> ••• {Object.values(mean)[0]} </p>
                                            </div>
                                            <div className="sortableCustomer-list ui-sortable">
                                                {result_arr.map(item => item)}
                                            </div>
                                        </>
                                    )
                                }
                            })
                            : null
                    }
                </div>
            </div>

            <EditCustomField
                editCustomField={editCustomField}
            />
        </>
    )
}