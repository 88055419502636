import {useMemo, useState} from "react";
import {useTranslate} from "../../Hooks/translate.hook";

export default function UIMenuInput ({ data, searchText, setSearchText, setId, type_id, topic, placeholder, value }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ focus, setFocus ] = useState(false);
    // STATE END

    const searchItems = useMemo(() => {
        if (searchText) {
            return data.filter(item => item[topic] ? item[topic].toLowerCase().includes(searchText.toLowerCase()) : false);
        } else {
            return [];
        }
    },[data, searchText])

    return (
        <>
            <input
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder={ translate(placeholder) }
                value={value ? data.filter(item => item[type_id] === +value).length ? data.filter(item => item[type_id] === +value)[0][topic] : null : searchText}
                onChange={e => { setSearchText(e.target.value); setId(''); }}
                onFocus={() => setFocus(!focus)}
            />
            <ul className={`ui-menu ui-widget ui-widget-content ui-autocomplete ui-front ${!focus ? 'hide' : ''}`} style={{ top: '40px', width: '351.612px', maxHeight : '200px', overflow : 'auto' }}>
                {
                    (searchItems.length ? searchItems : data).map(item => (
                        <li style={{ padding: '5px' }} className="ui-menu-item">
                            <small className="ui-menu-item-wrapper">
                                <a className="ui-link" onClick={() => { setId(item[type_id].toString()); setSearchText(''); setFocus(false) }}>
                                    <i className="ion-person text-muted" /> { item[topic] }
                                </a>
                            </small>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}