import {Link} from "react-router-dom";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {openModal} from "../../../../Function/common";

export default function GroupRow (props) {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <div className={`row ${props.data.f_removed ? 'archive' : ''}`}>
            <div className="col-sm-12 m-b-xs p-xxs crm-hover-block">
                <span className="text-muted"> {props.index} </span>
                <a className="crm-modal-btn pull-right" onClick={() => {openModal('edit-group'); props.setEditGroup(props.data);}}>
                    <i className="ion-edit" />
                </a>
                <Link to={`/company/1/group/view?id=${props.data.group_id}`} title={translate('Открыть карточку')} target="_blank">
                    <i className="ion-person-stalker m-r-xs" />
                    {props.data.title}
                </Link>
                <span className="text-muted"> {translate('с')} {props.data.date_start} </span>
                {props.data.date_end ? <span className="text-muted"> {translate('по')} {props.data.date_end} </span> : null}
            </div>
        </div>
    )
}