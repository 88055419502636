import {openMenu} from "../../Function/common";
import {useTranslate} from "../../Hooks/translate.hook";
import URL from "../../Api/URL.json";
import {useHttp} from "../../Hooks/http.hook";
import {success} from "../../Function/Notify";
import {useArchive} from "../../Hooks/archive.hook";

export default function FileList ({ storageFile, setStorageFile, type, entity, id }) {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    // HOOK END

    // FUNCTION START
    async function deleteFile (item, e) {
        await request(`${URL.back_end}/delete-file/${entity}/${id}/${item}`, 'DELETE');
        await pushArchive(3, 'crmFile', id);

        if (error) return console.log(error);

        if (type === 'modal') {
            const temp_arr = storageFile.filter(file => file !== item);
            setStorageFile(temp_arr);
        } else {
            e.target.parentNode.parentNode.parentNode.remove();
        }

        success( translate('Файл успешно удален') )
    }
    // FUNCTION END

    return (
        <div className="file-area-list">
                {
                    storageFile.map(item => (
                        <span className="dropdown" onClick={e => openMenu(e)} id={item}>
                            <a className="dropdown-toggle file-area-item m-l-sm m-r-sm m-b-xs pull-left text-center crm-dashed-link">
                                <i className="fa fa-file-o text-muted" />
                                <br />
                                { item }
                            </a>
                            <ul className="dropdown-menu" style={{ marginTop: '5px' }}>
                                <li className="dropdown-header text-center" style={{ padding : '5px' }}>
                                    { item }
                                </li>
                                <li className="divider no-padding" />
                                <li>
                                    <a href={`${URL.back_end}/download/${entity}/${id}/${item}/${2}`} target="_blank" style={{ padding : '2px auto' }}>
                                        <i className="ion-eye" /> { translate('Посмотреть') }
                                    </a>
                                </li>
                                <li>
                                    <a href={`${URL.back_end}/download/${entity}/${id}/${item}/${1}`} target="_blank" style={{ padding : '2px auto' }}>
                                        <i className="ion-android-download" /> { translate('Скачать') }
                                    </a>
                                </li>
                                <li>
                                    <a style={{ padding : '2px auto' }} onClick={(e) => deleteFile(item, e)}>
                                        <i className="fa fa-trash-o" /> { translate('Удалить') }
                                    </a>
                                </li>
                            </ul>
                        </span>
                    ))
                }
        </div>
    )
}