import {Link} from "react-router-dom";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {openModal} from "../../../../Function/common";

export default function CustomerRow (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return (
        <div className="col-sm-12 p-xxs crm-hover-block" style={{paddingTop: "3px", paddingBottom: "3px"}}>
            <small className="text-muted"> {props.index} </small>
            <a className="crm-modal-btn pull-right" onClick={() => {openModal('edit-customer'); props.setEditCustomer(props.data);}}><i className="ion-edit" /></a>
            <Link to={`/company/1/customer/view?id=${props.data.customer_id}`} title={translate('Открыть карточку')}>
                <i className="ion-person m-r-xs" />{props.data.name}
            </Link>
            <small className="text-muted">
                &nbsp;
                <span className="text-lowercase">{translate('с')}</span> {props.data.date_start}
                {props.data.date_end ? <><span className="text-lowercase"> {translate('по')}</span> {props.data.date_end}</> : null}
            </small>
        </div>
    )
}