import {closeModal} from "../../../../../Function/common";
import {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useHttp} from "../../../../../Hooks/http.hook";
import {validate} from "../../../../../Function/validateForm";
import URL from "../../../../../Api/URL.json";

export default function EditTelegram (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ name, setName ] = useState('');
    const [ welcome, setWelcome ] = useState('');
    const [ welcomeNew, setWelcomeNew ] = useState('');
    const [ token, setToken ] = useState('');
    const [ branch, setBranch ] = useState('');
    const [ messageNoWork, setMessageNoWork ] = useState('');
    const [ startWorkTime, setStartWorkTime ] = useState('00:00');
    const [ endWorkTime, setEndWorkTime ] = useState('23:59');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        setName(props.editTelegram.name);
        setWelcome(props.editTelegram.welcome);
        setWelcomeNew(props.editTelegram.welcomeNew);
        setToken(props.editTelegram.token);
        setBranch(props.editTelegram.branch);
        setMessageNoWork(props.editTelegram.messageNoWork);
        setStartWorkTime(props.editTelegram.startWorkTime);
        setEndWorkTime(props.editTelegram.endWorkTime);

    }, [props.editTelegram])
    // INIT DATA END

    // EDIT TELEGRAM START
    async function editTelegram (e) {

        e.preventDefault();

        let validObj = {
            "Название" : !name.length
        }

        if (validate(validObj, translate)) return

        integration.telegram = integration.telegram.map(item => {
            if (item.id === props.editTelegram.id) {
                item.name = name;
                item.welcome = welcome;
                item.welcomeNew = welcomeNew;
                item.branch = branch;
                item.messageNoWork = messageNoWork;
                item.startWorkTime = startWorkTime;
                item.endWorkTime = endWorkTime;

                return item;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', { integration: JSON.stringify(integration) })

        if (error) return error;

        window.location.reload();
    }
    // EDIT TELEGRAM END

    return (
        <div id="edit-telegram" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editTelegram(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Править телеграм') } </h4>
                        </div>
                        <div className="modal-body p-md">
                            <p className="p-xs bg-warning m-b-md text-center">
                                {translate('Можно использовать такие параметры для поля')}
                                <strong> "{`${translate('Приветственное сообщение')}`}" </strong>
                                %username%, %first_name%, %break%
                            </p>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        {translate('Название')}
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={`${translate('Например')}, test_bot`}
                                      value={name}
                                      onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Api Token
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      className="form-control"
                                      value={token}
                                      disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        {translate('Приветственное сообщение')}
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={translate('Например, Привет %first_name%')}
                                      value={welcome}
                                      onChange={e => setWelcome(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label
                                      className="control-label"> {translate('Приветственное сообщение для новых пользователей')} </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={translate('Например, Привет %first_name%')}
                                      value={welcomeNew}
                                      onChange={e => setWelcomeNew(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Гілка
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      className="form-control"
                                      placeholder="Наприклад, 1"
                                      value={branch}
                                      onChange={e => setBranch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='col-sm-4'>
                                    <label className='control-label'>
                                        Робочий час
                                    </label>
                                </div>
                                <div className='col-sm-4'>
                                    <input
                                      type='time'
                                      className='form-control'
                                      value={startWorkTime}
                                      onChange={(event) => setStartWorkTime(event.target.value)}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <input
                                      type='time'
                                      className='form-control'
                                      value={endWorkTime}
                                      onChange={(event) => setEndWorkTime(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        Повідомлення не в робочий час
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                      className="form-control"
                                      placeholder={translate('Например, Привет %first_name%')}
                                      value={messageNoWork}
                                      onChange={e => setMessageNoWork(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white"
                                    onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}