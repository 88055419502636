import '../../Assets/css/VisitStats.css';
import CalendarPopover from "../Popover/CalendarPopover";
import {useContext, useState} from "react";
import EditLesson from "../../Pages/Calendar/Modal/EditLesson";
import SpendLesson from "../../Pages/Lesson/Modal/SpendLesson";
import {useTranslate} from "../../Hooks/translate.hook";
import URL from "../../Api/URL.json";
import {useHttp} from "../../Hooks/http.hook";
import {AuthContext} from "../../Context/AuthContext";

export default function VisitStats (props) {

    // CONTEXT START
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // VALUE START
    const dayOfWeek = [`${translate('Вс')}`, `${translate('Пн')}`, `${translate('Вт')}`, `${translate('Ср')}`, `${translate('Чт')}`, `${translate('Пт')}`, `${translate('Сб')}`];
    // VALUE END

    // STATE START
    const [popoverData, setPopoverData] = useState({});
    const [popoverStyle, setPopoverStyle] = useState({});
    const [editLesson, setEditLesson] = useState({});
    // STATE END

    // FUNCTION WIDGET START
    function shortDate(value) {
        let [year, month, day] = value.split("T")[0].split("-");
        return `${day}.${month}`;
    }

    function filterLesson (visitData) {
        if (visitData === undefined) return
        return visitData.sort((a, b) => new Date(a.date_start) - new Date(b.date_start));
    }

    function getBackground (status) {
        // eslint-disable-next-line default-case
        switch (status) {
            case 0 : return "gray-bg"
            case 1 : return "bg-success"
            case 2 : return "gray-bg"
        }
    }
    function getColorText (status) {
        // eslint-disable-next-line default-case
        switch (status) {
            case 0 : return "text-muted"
            case 1 : return "text-success"
            case 2 : return "text-muted"
        }
    }

    async function showPopover (e, item) {
        const customers = await request(`${URL.back_end}/lesson_customer/${item.lesson_id}`, 'GET');
        const employees = await request(`${URL.back_end}/lesson_employee/${item.lesson_id}`, 'GET');
        const groups = await request(`${URL.back_end}/lesson_group/${item.lesson_id}`, 'GET');

        setPopoverData({
            lesson_id: item.lesson_id,
            regular_id : item.regular_id,
            type_lesson: item.type_lesson,
            date_start: item.date_start,
            date_end: item.date_end,
            startTime: item.time_start,
            endTime: item.time_end,
            audience: item.audience,
            subject: item.subject,
            topic: item.topic,
            homework: item.homework,
            note: item.note,
            dow: item.dow,
            status: item.status,
            customer: customers,
            employee: employees,
            group: groups,
            access : auth.role.interface
        });

        setPopoverStyle({
            top: `${e.pageY-318}px`,
            left: `${e.pageX-150}px`,
            minWidth: "330px",
            display: "block"
        })
    }
    // FUNCTION WIDGET END

    return (
        <div className="row m-t-sm">
            <div className="col-sm-12">
                {/*FILTER*/}
                <small className="pull-right">
                    <span className="dropdown">
                        <a className="dropdown-toggle text-muted"> ±90 { translate('дней') } </a>
                    </span>
                </small>
                <h5 className="font-noraml text-muted border-bottom m-b-sm no-padding">
                    { translate('История и прогноз посещений') }
                </h5>
                {/*MAIN*/}
                <div className="visit-stats" style={{ userSelect: 'none' }}>
                    {
                        filterLesson(props.groupLesson).map(lesson => {
                            if (new Date().getTime() > new Date(lesson.date_start).getTime()) {
                                return (
                                    <span className={`${getBackground(lesson.status)} pull-left m-r-xs m-b-xs text-center es-ld`} onClick={e => showPopover(e, lesson)}>
                                     <small className={getColorText(lesson.status)}>
                                        <strike style={lesson.status === 2 ? {} : {textDecoration: "none"}}>
                                            <i className="ion-person-stalker popover-container m-r-xxs" />
                                            {dayOfWeek[new Date(lesson.date_start).getDay()]}
                                            <br/> {shortDate(lesson.date_start)}
                                        </strike>
                                    </small>
                                </span>
                                )
                            }
                        })
                    }
                    <span className="pull-left m-r-xs m-b-xs text-center font-bold es-divider" title="Всё, что слева - прошедшие занятия, справа - будущие.">
                        <i className="ion-flag" />
                        <small>
                            <small className="font-noraml"> Сейчас </small>
                        </small>
                    </span>
                    {
                        filterLesson(props.groupLesson).map(lesson => {
                            if (new Date().getTime() < new Date(lesson.date_start).getTime()) {
                                return (
                                    <span className={`${getBackground(lesson.status)} pull-left m-r-xs m-b-xs text-center es-ld`} onClick={e => showPopover(e, lesson)}>
                                     <small className={getColorText(lesson.status)}>
                                        <strike style={lesson.status === 2 ? {} : {textDecoration: "none"}}>
                                            <i className="ion-person-stalker popover-container m-r-xxs"/>
                                            {dayOfWeek[new Date(lesson.date_start).getDay()]}
                                            <br/> {shortDate(lesson.date_start)}
                                        </strike>
                                    </small>
                                </span>
                                )
                            }
                        })
                    }
                </div>
                <div className="clearfix" />
                {/*LEGEND*/}
                <div className="es-legend">
                    <ul className="list-unstyled m-t-none">
                        <li className="pull-left m-t-xs">
                        <span className="bg-success">
                            <small className="text-success">{translate('дд.мм')}</small>
                        </span>
                            <small className="m-r-lg"> — {translate('Проведён')}</small>
                        </li>
                        <li className="pull-left m-t-xs">
                            <span className="gray-bg"><small>{translate('дд.мм')}</small></span>
                            <small className="m-r-lg"> — {translate('Запланирован')}</small>
                        </li>
                        <li className="pull-left m-t-xs">
                            <span className="gray-bg text-muted"><small><strike>{translate('дд.мм')}</strike></small></span>
                            <small className="m-r-lg"> — {translate('Отменён')}</small>
                        </li>
                    </ul>
                </div>

                <CalendarPopover
                    data={popoverData}
                    setData={setPopoverData}
                    popoverStyle={popoverStyle}
                    setPopoverStyle={setPopoverStyle}
                    setEditLesson={setEditLesson}
                    side="right"
                    sideStyle={{top: "50%"}}
                />

                <EditLesson
                    groupData={props.groupData}
                    employeeData={props.employeeData}
                    editLesson={editLesson}
                />

                <SpendLesson
                    employeeData={props.employeeData}
                    groupData={props.groupData}
                    customerData={props.customerData}
                    editLesson={popoverData}
                />
            </div>
        </div>
    )
}