import {useContext, useEffect} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import URL from "../../../Api/URL.json";
import {useHttp} from "../../../Hooks/http.hook";
import {openModal} from "../../../Function/common";
import $ from 'jquery';
import 'jquery-ui-bundle';
import {useTranslate} from "../../../Hooks/translate.hook";
import shortid from "shortid";
import {errorNotify, success} from "../../../Function/Notify";
import {maxId} from "../../../Function/common";

function drag () {

    $(".sortable-list").sortable({
        revert: 100,
        connectWith: ".sortable-list",
        distance: 50,
        tolerance: "pointer",
        items: "li:not(.empty-list)",
        update: function (event, ui) {
            if (!(this === ui.item.parent()[0])) return

            const customer_id = $(ui.item).data('id');
            const status = $(event.target).data('status');

            window.adjustHeight();

            const domain = (window.location.hostname).split('.')[0];

            if (domain === "localhost" || domain === "ristcrm") return errorNotify(`Неизвестный домен`)

            $.ajax({
                url: `${URL.back_end}/customer/${customer_id}`,
                type: 'PUT',
                headers: {
                    'Content-type' : 'application/json',
                    'domain' : domain
                },
                data: JSON.stringify({ leadStatus : status })
            }).done(
                function (res) {
                    window.location.reload();
                }
            )
        }
    })

}

window.adjustHeight = function() {
    let $the, element = $(".board-columns .board-column ul");

    element.css("height", "auto");
    window.boardMaxHeight = 0;

    element.each(function() {
        if ($(this).height() > window.boardMaxHeight)
        {
            window.boardMaxHeight = $(this).height();
            $the = $(this);
        }
    });

    element.height(window.boardMaxHeight);

    if ($the) $the.css("height", "auto");
}

export default function LeadBoard (props) {

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // COMP FUNC START
    const getCustomer = (id) => props.sql.filter(item => item.leadStatus === id).sort(( a, b ) => new Date(b.c_date) - new Date(a.c_date));

    async function nextStatus (lead, currStatus) {
        if (currStatus === 0) {
            lead.leadStatus = salesSetting.leadStatus[0].id;

            await request(`${URL.back_end}/customer/${lead.customer_id}`, 'PUT', {leadStatus: lead.leadStatus})

            if (error) return console.log(error)

            let temp = props.sql.map(item => {
                if (item.customer_id === lead.customer_id) {
                    return lead;
                } else {
                    return item;
                }
            })

            props.updateSql([...temp]);
        } else {

            let index = null

            for (let i = 0; i < salesSetting.leadStatus.length; i++) {
                if (salesSetting.leadStatus[i].id === currStatus) {
                    index = i;
                }
            }

            if (!salesSetting.leadStatus[index+1]) return

            lead.leadStatus = salesSetting.leadStatus[index+1].id;

            await request(`${URL.back_end}/customer/${lead.customer_id}`, 'PUT', {leadStatus: lead.leadStatus})
            if (error) return console.log(error)

            let temp = props.sql.map(item => {
                if (item.customer_id === lead.customer_id) {
                    return lead;
                } else {
                    return item;
                }
            })

            props.updateSql(temp);
        }
    }

    async function toClient (customer) {
        const temp_arr = props.sql.filter(item => item.customer_id !== customer.customer_id);

        props.updateSql(temp_arr);

        await request(`${URL.back_end}/customer/${customer.customer_id}`, 'PUT', { is_lead: 0 })

        if (error) return console.log(error)
    }

    function toArchive (customer) {
        props.setArchiveCustomer(customer);
        openModal("archive-modal")
    }

    async function fromArchive (customer) {
        let temp = props.sql.filter(item => item.customer_id !== customer.customer_id);

        props.updateSql([...temp]);

        await request(`${URL.back_end}/customer/${customer.customer_id}`, 'PUT', { f_removed : 0 })

        if (error) return console.log(error)

        success(translate('Клиент удален из архива'))
    }

    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = [];

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(+element.value)
            }
        })

        props.setSelectedLead(tempSelection);
    }

    function getTags (tags) {
        return Object.keys(tags).map(item => (
            <span className="label m-r-xs" title={item} key={shortid.generate()}>
                { tags[item] }
            </span>
        ))
    }

    function scrollXBlock () {
        const container = document.querySelector('.scroll-block');
        let isDown = false;
        let startX;
        let scrollLeft;

        container.addEventListener('mousedown', (e) => {
            isDown = true;
            container.classList.add('active');
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;
        });

        container.addEventListener('mouseleave', () => {
            isDown = false;
            container.classList.remove('active');
        });

        container.addEventListener('mouseup', () => {
            isDown = false;
            container.classList.remove('active');
        });

        container.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = x - startX;
            container.scrollLeft = scrollLeft - walk;
        });
    }
    // COMP FUNC END

    // INIT HANDLER START
    useEffect(() => {
        drag()
        scrollXBlock()
        window.adjustHeight()
    }, [props.sql])
    // INIT HANDLER END

    return (
        <div className="tab-content">
            <div id="tab-1" className="tab-pane active">
                <div className="panel-body board-columns">
                    {/*TABLE HEADER*/}
                    <div className="text-muted text-right small" style={{ padding: '0 5px 7px 5px' }}>
                        { translate('Всего в базе') } <b>{ props.sql.length }</b>.&nbsp;
                        { translate('Отображено 5 этапов воронки.') }&nbsp;
                    </div>
                    {/*TABLE BODY*/}
                    <div className="row scroll-block" style={{ cursor : 'ew-resize' }}>
                        <div className="board-column horizontal-scroll col-xs-12 col-sm-6 col-md-4 col-lg-3 lead-items">
                            <h3 className="no-margins p-xxs"
                                style={{color: "#6a6a6a", cursor: "text", overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                <strong className="pull-right m-l-sm m-r-sm">
                                    <input type="checkbox" className="no-margins" disabled="disabled" style={{position: "absolute"}} />
                                </strong>
                                <span style={{display: "inline-flex", maxWidth: "245px", overflow: "hidden"}}>{translate('Не разобрано')}</span>
                                <span style={{opacity: 0.7}}> {getCustomer(0).length} </span>
                            </h3>
                            <ul className="sortable-list agile-list lead-list horizontal-scroll crm-table open no-padding ui-sortable" style={{height: "128px"}} data-status={0}>
                                {
                                    getCustomer(0).length ?
                                        getCustomer(0).map(item => (
                                            <li className="no-padding draggable lead-element ui-sortable-handle" data-id={item.customer_id}>
                                                <p className="p-xxs no-margins gray-bg" style={{color: "#6a6a6a", fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden",  textOverflow: "ellipsis"}}>
                                                    <a style={{color: "inherit", textDecoration: "underline", maxWidth: "70%", display: "inline-block", overflowX: "hidden", textOverflow: "ellipsis"}} href={`/company/1/customer/view?id=${item.customer_id}`}>
                                                        { item.name ? item.name : (item.contact && <> <i className="fa fa-mobile" /> { JSON.parse(item.contact).filter(item => item.type === 'mobile')[0]?.text } </>) }
                                                    </a>
                                                    {
                                                        item.c_date ?
                                                            ( <span className="small board-action-replace pull-right text-muted"> { new Date().toLocaleDateString('en-CA') === item.c_date.split('T')[0] ? item.c_date.split('T')[1].slice(0, 5) : item.c_date.split('T')[0] } </span> )
                                                            : null
                                                    }
                                                    {
                                                        item.f_removed === 0 ? (
                                                            <a className="board-action m-l-sm pull-right" title={translate('В архив')} style={{color: "inherit"}} onClick={() => toArchive(item)}>
                                                                <i className="fa fa-trash-o" />
                                                            </a>
                                                        ) : (
                                                            <a className="board-action m-l-sm pull-right" title={ translate('Из архива') } style={{ color : 'inherit', marginTop : '.5px' }} onClick={() => fromArchive(item)}>
                                                                <i className="fa fa-reply" style={{ marginLeft : '-3px' }} />
                                                            </a>
                                                        )
                                                    }
                                                    <a className="board-action pull-right m-l-sm" title={translate('Перенести на следующий этап')} style={{color: "inherit"}} onClick={() => nextStatus(item, 0)}>
                                                        <i className="fa fa-arrow-right" />
                                                    </a>
                                                    <a className="board-action pull-right" title={translate('Сделать клиентом')} style={{color: "inherit"}} onClick={() => toClient(item)}>
                                                        <i className="fa fa-user" />
                                                    </a>
                                                </p>
                                                <label className="p-xxs no-margins font-noraml" style={{whiteSpace: "normal", opacity: 0.8}}>
                                                    {
                                                        item.name && item.contact ?
                                                                JSON.parse(item.contact).filter(item => item.type === 'mobile').map(contact => (
                                                                    <div className="small">
                                                                        <div className="m-b-xs" title={ translate('Контакты') }>
                                                                            <i className="fa fa-mobile" /> { contact.text }
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            : null
                                                    }
                                                    {
                                                        item.manager && (
                                                            <p className="small m-t-xs m-b-none" title={translate('Ответственный менеджер')}>
                                                                <i className="fa fa-thumb-tack" /> {props.managerData.filter(user => user.user_id === item.manager)[0]?.name}
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        item.tags ?
                                                            Object.keys(JSON.parse(item.tags)).length ?
                                                                <p className="small m-t-xs m-b-none" style={{ overflowWrap : 'break-word' }}>
                                                                    <i className="ion-stats-bars m-r-xs" /> { getTags(JSON.parse(item.tags)) }
                                                                </p>
                                                                : null
                                                            : null
                                                    }
                                                    {
                                                        item.mainTags ?
                                                            JSON.parse(item.mainTags).length ?
                                                                <p className="small m-t-xs m-b-none" style={{ overflowWrap : 'break-word' }}>
                                                                    <i className="fa fa-tags" /> { JSON.parse(item.mainTags).map(item => (
                                                                        <span className="label m-r-xs" title={item} key={shortid.generate()}>
                                                                            { item }
                                                                        </span>
                                                                    )) }
                                                                </p>
                                                                : null
                                                            : null
                                                    }
                                                    <p className="small m-t-xs m-b-none">
                                                         <span className="m-r-xs">
                                                             <i className="ion-chatbubbles" /> 0
                                                         </span>
                                                        <span className="pull-right">
                                                            <input
                                                                type="checkbox"
                                                                className="small kv-row-checkbox"
                                                                name="selection[]"
                                                                onChange={() => getSelect()}
                                                                value={item.customer_id}
                                                            />
                                                        </span>
                                                    </p>
                                                </label>
                                            </li>
                                        ))
                                        : <></>
                                        // <li className="text-center empty-list">
                                        //     <span className="text-danger"> { translate('нет лидов') } </span>
                                        // </li>
                                }
                            </ul>
                        </div>
                        {
                            salesSetting ?
                                salesSetting.leadStatus.map(item => (
                                    <div className="board-column horizontal-scroll col-xs-12 col-sm-6 col-md-4 col-lg-3 lead-items">
                                        <h3 className="no-margins p-xxs" style={{cursor: "text", overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                            <strong className="pull-right m-l-sm m-r-sm">
                                                <input type="checkbox" className="no-margins" disabled="disabled" style={{position: "absolute"}} />
                                            </strong>
                                            <span style={{color: `${item.color}`,display: "inline-flex", maxWidth: "245px", overflow: "hidden"}}>{item.name}</span>
                                            <span style={{opacity: 0.7}}> {getCustomer(item.id).length} </span>
                                        </h3>
                                        <ul className="sortable-list agile-list lead-list horizontal-scroll crm-table open no-padding ui-sortable" style={{height: "128px"}} data-status={item.id}>
                                            {
                                                getCustomer(item.id).length ?
                                                    getCustomer(item.id).map(customer => (
                                                        <li className="no-padding draggable lead-element ui-sortable-handle" data-id={customer.customer_id}>
                                                            <p className="p-xxs no-margins gray-bg" style={{color: `${item.color}`, fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden",  textOverflow: "ellipsis"}}>
                                                                <a style={{color: "inherit", textDecoration: "underline", maxWidth: "70%", display: "inline-block", overflowX: "hidden", textOverflow: "ellipsis"}} href={`/company/1/customer/view?id=${customer.customer_id}`}>
                                                                    { customer.name ? customer.name : (customer.contact && <> <i className="fa fa-mobile" /> { JSON.parse(customer.contact).filter(item => item.type === 'mobile')[0]?.text } </>) }
                                                                </a>
                                                                {
                                                                    customer.c_date ?
                                                                        ( <span className="small board-action-replace pull-right text-muted"> { new Date().toLocaleDateString('en-CA') === customer.c_date.split('T')[0] ? customer.c_date.split('T')[1].slice(0, 5) : customer.c_date.split('T')[0] } </span> )
                                                                        : null
                                                                }
                                                                {
                                                                    customer.f_removed === 0 ? (
                                                                        <a className="board-action m-l-sm pull-right" title={translate('В архив')} style={{color: "inherit"}} onClick={() => toArchive(customer)}>
                                                                            <i className="fa fa-trash-o" />
                                                                        </a>
                                                                    ) : (
                                                                        <a className="board-action m-l-sm pull-right" title={ translate('Из архива') } style={{ color : 'inherit', marginTop : '.5px' }} onClick={() => fromArchive(customer)}>
                                                                            <i className="fa fa-reply" style={{ marginLeft : '-3px' }} />
                                                                        </a>
                                                                    )
                                                                }
                                                                {
                                                                    maxId(salesSetting.leadStatus) !== item.id ? (
                                                                        <a className="board-action pull-right m-l-sm" title={translate('Перенести на следующий этап')} style={{color: "inherit"}} onClick={() => nextStatus(customer, item.id)}>
                                                                            <i className="fa fa-arrow-right" />
                                                                        </a>
                                                                    ) : null
                                                                }
                                                                <a className="board-action pull-right" title={translate('Сделать клиентом')} style={{color: "inherit"}} onClick={() => toClient(customer)}>
                                                                    <i className="fa fa-user" />
                                                                </a>
                                                            </p>
                                                            <label className="p-xxs no-margins font-noraml" style={{whiteSpace: "normal", opacity: 0.8}}>
                                                                {
                                                                    customer.name && customer.contact ?
                                                                        JSON.parse(customer.contact).filter(cont => cont.type === 'mobile').map(contact => (
                                                                            <div className="small">
                                                                                <div className="m-b-xs" title={ translate('Контакты') }>
                                                                                    <i className="fa fa-mobile" /> { contact.text }
                                                                                    <br />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        : null
                                                                }
                                                                {
                                                                    customer.manager && (
                                                                        <p className="small m-t-xs m-b-none" title={translate('Ответственный менеджер')}>
                                                                            <i className="fa fa-thumb-tack" /> { props.managerData.filter(item => item.user_id === customer.manager)[0]?.name }
                                                                        </p>
                                                                    )
                                                                }
                                                                {
                                                                    customer.tags ?
                                                                        Object.keys(JSON.parse(customer.tags)).length > 0 ?
                                                                            <p className="small m-t-xs m-b-xs" style={{ overflowWrap : 'break-word' }}>
                                                                                <i className="ion-stats-bars m-r-xs" /> {getTags(JSON.parse(customer.tags))}
                                                                            </p>
                                                                            : null
                                                                        : null
                                                                }
                                                                {
                                                                    customer.mainTags ?
                                                                        JSON.parse(customer.mainTags).length ?
                                                                            <p className="small m-t-xs m-b-none" style={{ overflowWrap : 'break-word' }}>
                                                                                <i className="fa fa-tags" /> { JSON.parse(customer.mainTags).map(item => (
                                                                                <span className="label m-r-xs" title={item} key={shortid.generate()}>
                                                                                    { item }
                                                                                </span>
                                                                            )) }
                                                                            </p>
                                                                            : null
                                                                        : null
                                                                }
                                                                <p className="small m-t-xs m-b-none">
                                                                    <span className="m-r-xs">
                                                                        <i className="ion-chatbubbles" /> 0
                                                                    </span>
                                                                    <span className="pull-right">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="small kv-row-checkbox"
                                                                            name="selection[]"
                                                                            value={customer.customer_id}
                                                                            onChange={() => getSelect()}
                                                                        />
                                                                    </span>
                                                                </p>
                                                            </label>
                                                        </li>
                                                    ))
                                                    : <></>
                                                    // <li className="text-center empty-list">
                                                    //     <span className="text-danger">{translate('нет лидов')}</span>
                                                    // </li>
                                            }
                                        </ul>
                                    </div>
                                ))
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}