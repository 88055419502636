import {useTranslate} from "../../../../Hooks/translate.hook";
import {useHttp} from "../../../../Hooks/http.hook";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import URL from "../../../../Api/URL.json";

export default function EmployeeOptions () {

    // CONTEXT START
    const { employeeSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ accessChat, setAccessChat ] = useState(false);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!employeeSetting) return

        const { options } = employeeSetting;

        setAccessChat(options.accessChat);

    }, [ employeeSetting ])
    // INIT DATA END

    // ADD OPTIONS START
    async function addOptions (e) {

        e.preventDefault();

        employeeSetting.options = { accessChat };

        await request(`${URL.back_end}/setting`, 'PUT', { employeeSetting: JSON.stringify(employeeSetting) })

        window.location.reload();

    }
    // ADD OPTIONS END

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">
                    Настройки ЛК педагога
                </h4>
            </div>
            <form className="form-horizontal" onSubmit={e => addOptions(e)}>
                <div className="modal-body p-md">
                    <div className="form-group">
                        <div className="col-sm-8">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={accessChat}
                                    onChange={e => setAccessChat(e.target.checked)}
                                /> Доступ до чату з клієнтом
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-sm btn-w-m btn-success">
                        <i className="fa fa-save" /> { translate('Сохранить') }
                    </button>
                    <button type="button" className="btn btn-sm btn-w-m btn-white">
                        { translate('Отмена') }
                    </button>
                </div>
            </form>
        </div>
    )
}