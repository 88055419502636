import '../../../../Assets/css/ScheduleModal.css';
import TimeChosen from "../../../../Components/Input/TimeChosen";
import {useEffect, useRef, useState} from "react";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from '../../../../Api/URL.json';
import {useArchive} from "../../../../Hooks/archive.hook";
import {closeModal, openModal} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

export default function ScheduleModal (props) {

    // HOOK START
    const {request, error} = useHttp();
    const {pushArchive} = useArchive();
    const {translate} = useTranslate();
    // HOOK END

    // REF START
    const modal = useRef();
    // REF END

    // VALUE START
    const time = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
    // VALUE END

    // STATE START
    const [schedule, setSchedule] = useState({
        1 : [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        0: []
    })
    const [editSchedule, setEditSchedule] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (props.editSchedule === null) return
        let scheduleObj = {};
        for (let key in props.editSchedule) {
            if (props.editSchedule[key].length !== 0) {
                let timeArr = []
                for (let i = 0; i < props.editSchedule[key].length; i++) {
                    let [timeStart, timeEnd] = props.editSchedule[key][i].split("-");
                    let hourStart = +timeStart.split(":")[0];
                    let hourEnd = +timeEnd.split(":")[0];
                    for (let i = hourStart; i < hourEnd; i++) {
                        timeArr.push(i);
                    }
                }
                scheduleObj[key] = timeArr;
            } else {
                scheduleObj[key] = props.editSchedule[key];
            }
        }

        setSchedule(scheduleObj);
        setEditSchedule(scheduleObj);
    },[props.editSchedule])
    // INIT DATA END

    // FUNCTION START
    async function deleteSchedule () {
        await request(`${URL.back_end}/employee/${props.sql.employee_id}`, 'PUT', { schedule : null });
        window.location.reload();
    }

    function deleteHandler () {
        openModal('delete-schedule-modal');
        openModal('delete-schedule-overlay');
    }
    // FUNCTION END

    // ADD SCHEDULE START
    async function addSchedule (e) {
        e.preventDefault();

        let timeObj = {};

        for (let key in schedule) {
            let zeroArr = Array(24).fill(0),
                resultArr = [],
                timeArr = [],
                i = 0,
                hourFrom = null;

            for (let j = 0; j < schedule[key].length; j++) {
                zeroArr[schedule[key][j]] = schedule[key][j];
            }

            zeroArr.forEach(function (value,index) {
                if (value !== 0 && hourFrom === null) {
                    hourFrom = index;
                    timeArr[i] = [];
                    timeArr[i]['hourFrom'] = (index < 10 ? `0${index}` : index);
                    timeArr[i]['hourTo'] = '00';
                } else if (value === 0 && hourFrom !== null) {
                    timeArr[i]['hourTo'] = index < 10 ? `0${index}` : index;
                    hourFrom = null;
                    i++;
                } else if (value !== 0 && hourFrom !== null) {

                } else {
                    hourFrom = null;
                }
            })

            i = 0;
            // eslint-disable-next-line array-callback-return
            timeArr.map(function (item) {
                resultArr[i++] = `${item.hourFrom}:00-${item.hourTo}:00`
            })

            timeObj[key] = resultArr;
        }

        await request(`${URL.back_end}/employee/${props.sql.employee_id}`, 'PUT', {schedule : JSON.stringify(timeObj)})

        if (error) return console.log(error)

        await pushArchive(props.editSchedule === null ? 1 : 2, "EmployeeWorkingHour", props.sql.employee_id, timeObj, props.editSchedule === null ? null : props.editSchedule);

        let update_employee = {
            schedule: JSON.stringify(timeObj)
        }

        for (let key in props.sql) {
            if (key !== "schedule") {
                update_employee[key] = props.sql[key];
            }
        }

        props.updateSql(update_employee);
        closeModal(e, modal);
    }
    // ADD SCHEDULE END

    return (
        <div id="add-schedule" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal crm-ajax-form teacher-working-hour-form" onSubmit={e => addSchedule(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> {translate('График работы')} — {props.sql.name} </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*WARNING*/}
                            <p className="p-xs bg-warning m-b-md">
                                {translate(
                                    `Выделите кликом каждый полный рабочий час педагога.
                                При попытке добавить занятие в нерабочее время система будет выдавать
                                предупреждение.`
                                )}
                            </p>
                            {/*MONDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Пн')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={1}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                            {/*TUESDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Вт')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={2}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                            {/*WEDNESDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Ср')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={3}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                            {/*THURSDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Чт')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={4}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                            {/*FRIDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Пт')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={5}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                            {/*SATURDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Сб')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={6}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                            {/*SUNDAY*/}
                            <div className="form-group">
                                <div className="col-xs-1">
                                    <label className="control-label"><strong>{translate('Вс')}</strong></label>
                                </div>
                                <div className="col-xs-12">
                                    <TimeChosen
                                        day={0}
                                        time={time}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        editSchedule={editSchedule}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-danger btn-outline" title={translate('Очистить график')} onClick={deleteHandler}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <DeleteModal
                id="delete-schedule"
                handler={deleteSchedule}
            />
        </div>
    )
}