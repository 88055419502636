import '../../../Assets/css/TableResponsive.css';
import {useContext} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {SettingContext} from "../../../Context/SettingContext";
import ColumnsResponsive from "./ColumnsResponsive";

export default function TableResponsive (props) {

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return (
        <div className="table-responsive_component">
            <table className="table table-bordered details-table">
                <thead>
                <tr>
                    <td className="col-sm-4"> { translate('Клиенты') } </td>
                    <td className="col-sm-2"> { translate('Комиссия') } </td>
                    {
                        lessonSetting?.lessonOption.mark && (
                            <>
                                <td className="col-sm-2"> { translate('Оценка / Причина') } </td>
                                <td className="col-sm-2"> { translate('Оценка за ДЗ') } </td>
                            </>
                        )
                    }
                    <td className="col-sm-4"> { translate('Примечание') } </td>
                    <td className="col-sm-2"> { translate('Абонемент') } </td>
                    <td />
                </tr>
                </thead>
                <tbody>
                {
                    !props.customer.length && (
                        <tr className="details-empty">
                            <td colSpan="6">Клієнти не додані</td>
                        </tr>
                    )
                }
                {
                    props.customer.length ? props.customer.map(item => (
                        <ColumnsResponsive
                            data={item}
                            setCustomer={props.setCustomer}
                            customer={props.customer}
                            subject={props.subject}
                            const_subject={props.const_subject}
                            editLesson={props.editLesson}
                            type_lesson={props.type_lesson}
                            minute={props.minute}
                        />
                    )) : null
                }
                </tbody>
            </table>
        </div>
    )
}