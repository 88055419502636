import "../../../../../Assets/css/SettingStyle/EditModal.css";
import {useContext, useEffect, useRef, useState} from "react";
import {closeModal} from "../../../../../Function/common";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {errorNotify} from "../../../../../Function/Notify";
import URL from "../../../../../Api/URL.json";

export default function EditMark (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // CONTEXT START
    const {lessonSetting} = useContext(SettingContext);
    // CONTEXT END

    // STATE START
    const [ball, setBall] = useState('');
    const [fullName, setFullName] = useState(null);
    const [shortName, setShortName] = useState(null);
    const [tags, setTags] = useState(null);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if(!props.editMark) return
        setBall(props.editMark.ball);
        setFullName(props.editMark.fullName);
        setShortName(props.editMark.shortName);
        setTags(props.editMark.tags);
    }, [props.editMark])
    // INIT DATA END

    // EDIT MARK START
    async function editMark (e) {
        e.preventDefault();

        if (!ball.length) return errorNotify(`${translate('Необходимо заполнить')} "${translate('Оценка (балл)')}"`);

        let mark = {
            id: props.editMark.id,
            ball, fullName, shortName, tags,
            disabled: props.editMark.disabled
        }

        lessonSetting.mark = lessonSetting.mark.map(item => {
            if (item.id === props.editMark.id) {
                return mark;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return error;
        window.location.reload();
    }
    // EDIT MARK END

    return (
        <div id="edit-mark" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editMark(e)}>
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Редактировать оценку')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*BALL*/}
                            <div className="form-group field-grade-value required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="grade-value">{translate('Оценка (балл)')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 5`}
                                        value={ball}
                                        onChange={e => setBall(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*FULL NAME*/}
                            <div className="form-group field-grade-text">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="grade-text">{translate('Полное название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Отлично')}
                                        value={fullName}
                                        onChange={e => setFullName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*SHORT NAME*/}
                            <div className="form-group field-grade-text_short">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="grade-text_short">{translate('Краткое название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Отл')}
                                        value={shortName}
                                        onChange={e => setShortName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*TAGS*/}
                            <div className="form-group field-grade-tags">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="grade-tags">{translate('Теги')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={tags}
                                        onChange={e => setTags(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}