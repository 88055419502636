import '../../Assets/css/AcceptModal.css';
import {useRef} from "react";
import {useTranslate} from "../../Hooks/translate.hook";

export default function DeleteEntityModal () {

	// REF START
	const overlay = useRef();
	const alert = useRef();
	// REF END

	// HOOK START
	const { translate } = useTranslate();
	// HOOK END

	// BUTTON FUNC START
	function closeModal (e) {
		e.preventDefault();
		overlay.current.style.display = "none";
		alert.current.style.display = "none";
	}
	// BUTTON FUNC END

	return (
	  <>
		  <div className="sweet-overlay" id="sweet2-overlay" tabIndex={-1} style={{opacity: 1.05, display: "none"}} ref={overlay}/>
		  <div className="sweet-alert showSweetAlert visible" id="sweet2-alert" style={{marginTop: "-91px", display: "none"}} ref={alert}>
			  <h2>{ translate('Подтвердите действие') }</h2>
			  <p id="sweet2-text">{ translate('Хотите перенести в архив / восстановить?') }</p>
			  <div className="sa-button-container">
				  <button className="confirm" id="sweet2-confirm" tabIndex={1}>
					  { translate('Да') }
				  </button>
				  <button className="cancel" tabIndex={2} onClick={e => closeModal(e)}>
					  { translate('Нет') }
				  </button>
			  </div>
		  </div>
	  </>
	)
}