import URL from "../../../../Api/URL.json";
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {AuthContext} from "../../../../Context/AuthContext";
import fileSvg from "../../../../Assets/image/file.svg";
import "../../../../Assets/css/CommentBlock.css";
import {openModal} from "../../../../Function/common";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

export default function CommentBlock ({ sqlCard, managerData }) {

    // CONTEXT START
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ comments, setComments ] = useState([]);
    const [ newComment, setNewComment ] = useState('');
    const [ deleteCommentId, setDeleteCommentId ] = useState(null);
    // STATE END

    // INIT COMMENT START
    useEffect(() => {

        const userInterface = auth.role.interface;

        async function fetchData () {
            let data = [];

            if (userInterface === 1) {
                data = await request(`${URL.back_end}/group_comment/${sqlCard.group_id}`, 'GET');
            } else if (userInterface === 2) {
                const tempData = await request(`${URL.back_end}/group_comment/${sqlCard.group_id}`, 'GET');
                data = tempData.filter((comment) => comment.user_id === auth.userId);
            }

            setComments(data);
        }

        fetchData().catch(e => console.log(e))

    }, [auth, request, sqlCard])
    // INIT COMMENT END

    // CREATE NEW COMMENT START
    async function createNewComment (e) {
        e.preventDefault();

        if (!newComment.length) return

        let comment = {
            text : newComment,
            c_date : new Date().toLocaleDateString('en-CA'),
            user_id : auth.userId,
            group_id : sqlCard.group_id
        }

        const { insertId } = await request(`${URL.back_end}/group_comment`, 'POST', comment);

        comment.comment_id = insertId;
        setNewComment('');

        setComments([ comment, ...comments ]);
    }
    // CREATE NEW COMMENT END

    // EDIT COMMENT START
    async function editComment (e, comment_id) {
        e.preventDefault();

        if (!(e.target.value).length) return

        const currentBlock = e.target.closest(".task-note__body-text");
        const prevBlock = currentBlock.previousSibling;
        prevBlock.innerText = e.target.value;
        prevBlock.classList.remove('hide');
        currentBlock.classList.add('hide');

        await request(`${URL.back_end}/group_comment/${comment_id}`, 'PUT', { text : e.target.value })
    }
    // EDIT COMMENT END

    // DELETE COMMENT START
    function deleteHandler (comment_id) {
        openModal('delete-comment-overlay');
        openModal('delete-comment-modal');
        setDeleteCommentId(comment_id);
    }

    async function deleteComment () {
        await request(`${URL.back_end}/group_comment/${deleteCommentId}`, 'DELETE');
        const temp_arr = comments.filter(item => item.comment_id !== deleteCommentId);

        setComments(temp_arr);
    }
    // DELETE COMMENT END

    // SWAP BLOCK START
    function swapBlock (currentBlock) {
        const nextBlock = currentBlock.target.nextSibling;

        if (nextBlock.classList.contains('hide')) {
            nextBlock.classList.remove('hide');
            nextBlock.childNodes[0].style.height = currentBlock.target.offsetHeight + 'px';
            currentBlock.target.classList.add('hide');
        }
    }
    // SWAP BLOCK END

    return (
        <>
            <div className="ibox float-e-margins m-b-sm">
                <div className="ibox-content border-bottom">
                    <div className="row">
                        <div className="col-sm-2">
                            <h4 className="m-b-sm"> { translate('Комментарии') } </h4>
                        </div>
                        <div className="clearfix" />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '5px' }}>
                            <textarea
                                className="form-control"
                                placeholder={ translate('Ваш комментарий') }
                                value={newComment}
                                onChange={e => setNewComment(e.target.value)}
                                style={{ height : '32px' }}
                            />
                            <button className="btn btn-sm btn-w-m btn-white" disabled={ !newComment.length } style={{ height : '32px' }} onClick={e => createNewComment(e)}>
                                { translate('Добавить') }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                comments.map(item => (
                    <div className="row m-b-sm" key={item.comment_id + item.text}>
                        <div className="col-sm-12">
                            <div className="task-note-wrapper">
                                <div className="task-note-fixer">
                                    <div className="task-note">
                                        <div className="task-note__icon">
                                            <div className="task-note__icon-inner feed-note__icon-border">
                                                <img
                                                    alt="icon"
                                                    src={ fileSvg }
                                                    className="svg-notes--feed-note-dims"
                                                />
                                            </div>
                                        </div>
                                        <div className="task-note__content">
                                            <div className="task-note__header">
                                                <div className="task-note__header-inner">
                                                    <div className="task-note__header-inner-nowrap">
                                                        <div className="task-note__date">
                                                            <i className="ion-calendar" /> { item.c_date }
                                                            <i className="ion-person m-r-xs m-l-xs" />{ managerData.filter(manager => manager.user_id === item.user_id)[0]?.name }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-note__body" style={{ display : 'flex', paddingRight : '2px' }}>
                                                <div className="task-note__task-text">
                                                    <p className="task-note__body-text" onClick={e => swapBlock(e)}>
                                                        { item.text }
                                                    </p>
                                                    <p className="task-note__body-text hide">
                                                        <textarea
                                                            className="comment-input"
                                                            defaultValue={item.text}
                                                            style={{ border : 0, outline : 0, padding : 0, boxSizing : 'border-box', width : '100%', height : '30px' }}
                                                            onBlur={e => editComment(e, item.comment_id)}
                                                        />
                                                    </p>
                                                </div>
                                                <i className="fa fa-trash-o text-danger" onClick={() => deleteHandler(item.comment_id)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

            <DeleteModal
                id="delete-comment"
                handler={deleteComment}
                close={true}
            />
        </>
    )
}