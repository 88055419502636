import '../../../../../Assets/css/AddTariff.css';
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {filterDisable, closeModal} from "../../../../../Function/common";
import {SettingContext} from "../../../../../Context/SettingContext";
import React from "react";
import {validate} from "../../../../../Function/validateForm";
import moment from "moment";

export const AddTariffCustomer = React.memo(function MemoAddTariff ({ tariffData, customer_id }) {

    // REF START
    const modal = useRef();
    const btn_lesson = useRef();
    const btn_type_lesson = useRef();
    const btn_submit = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [tariff, setTariff] = useState({});
    const [date_start, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [date_end, setDateEnd] = useState('');
    const [separate_balance, setSeparateBalance] = useState('Раздельный');
    const [reset, setReset] = useState(false);
    const [lessons, setLessons] = useState([]);
    const [type_lessons, setTypeLessons] = useState([]);
    const [note, setNote] = useState(null);
    // STATE END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (date_end) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(date_start))).asDays();

            const newDateEnd = moment(date_end).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function lessonsHandler() {
        let elems = document.querySelectorAll('#tariff-lessons:checked');
        let arr = [].map.call(elems, function (obj) {
            return +obj.value;
        })

        setLessons([...arr]);
    }
    function typeLessonsHandler() {
        let elems = document.querySelectorAll('#tariff-type_lessons:checked');
        let arr = [].map.call(elems, function (obj) {
            return +obj.value;
        })

        setTypeLessons([...arr]);
    }
    // HANDLER END

    // MODAL FUNC START
    function lessonsCheckAll() {
        btn_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#tariff-lessons');

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].checked === false) {
                arr[i].checked = true;
            }
        }

        lessonsHandler();
    }
    function lessonsUncheckAll() {
        btn_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#tariff-lessons');

        for (let i = 0; i < arr.length; i++) {
            arr[i].checked = false;
        }

        lessonsHandler();
    }
    function typeLessonsCheckAll() {
        btn_type_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#tariff-type_lessons');

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].checked === false) {
                arr[i].checked = true;
            }
        }

        typeLessonsHandler();
    }
    function typeLessonsUncheckAll() {
        btn_type_lesson.current.classList.toggle("active");
        let arr = document.querySelectorAll('#tariff-type_lessons');

        for (let i = 0; i < arr.length; i++) {
            arr[i].checked = false;
        }

        typeLessonsHandler();
    }
    // MODAL FUNC END

    // ADD TARIFF START
    async function addTariff (e) {
        e.preventDefault();

        const validObj = {
            "Абонемент" : !Object.keys(tariff).length,
            "Период действия" : !date_start.length || !date_end.length,
            "Тип расчетов" : !separate_balance.length,
            "Предметы" : !lessons.length,
            "Типы уроков" : !type_lessons.length
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        let data = {
            tariff_id: tariff.tariff_id,
            type_lessons : type_lessons.length > 0 ? JSON.stringify(type_lessons) : null,
            lessons : lessons.length > 0 ? JSON.stringify(lessons) : null,
            date_start,
            date_end,
            separate_balance,
            note,
            reset,
            balance: 0
        }

        await request(`${URL.back_end}/customer_tariff/${customer_id}`, 'POST', data)
        if (error) return console.log(error)

        window.location.reload();
    }
    // ADD TARIFF END

    return (
        <div id="add-tariff-customer" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addTariff(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить абонемент')} </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*TARIFF*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Абонемент')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        type_id="tariff_id"
                                        topicSearch="title"
                                        sql={tariffData.filter(item => item.f_removed === 0)}
                                        setProps={setTariff}
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Период действия')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label style={{ position: "absolute", marginTop: "8px", marginLeft: "5px" }}>
                                                { translate('с') }
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control text-center"
                                                value={date_start}
                                                onChange={(event) => dateStartHandler(event)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label style={{ position: "absolute", marginTop: "8px", marginLeft: "5px" }}>
                                                { translate('до') }
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control text-center"
                                                max={moment(date_start).add(2, 'year').format('YYYY-MM-DD')}
                                                min={moment(date_start).add(1, 'day').format('YYYY-MM-DD')}
                                                value={date_end}
                                                onChange={e => setDateEnd(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*SEPARATE BALANCE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Тип расчетов')}</label>
                                </div>
                                <div className="col-sm-9 highlight-checkbox">
                                    {/*<label>*/}
                                    {/*    <input*/}
                                    {/*        type="radio"*/}
                                    {/*        className="js-reload-ctl"*/}
                                    {/*        name="separate_balance"*/}
                                    {/*        defaultValue="Базовый"*/}
                                    {/*        defaultChecked*/}
                                    {/*        onChange={e => {setSeparateBalance(e.target.value); setReset(false)}}*/}
                                    {/*    />*/}
                                    {/*    <strong className="m-l-xs">{translate('Базовый')}</strong>*/}
                                    {/*    &nbsp;– {translate('расчеты с клиентом ведутся по базовому счету карточки клиента')}*/}
                                    {/*</label>*/}
                                    <label>
                                        <input
                                            type="radio"
                                            name="separate_balance"
                                            className="js-reload-ctl"
                                            defaultValue="Раздельный"
                                            defaultChecked={true}
                                            onChange={e => setSeparateBalance(e.target.value)}
                                        />
                                        <strong className="m-l-xs">{translate('Раздельный')}</strong>
                                        &nbsp;– {translate('создается отдельный счет, который нужно будет выбирать при добавлении платежей')}
                                    </label>
                                    <label className={separate_balance !== "Раздельный" ? "hide" : null}>
                                        <input
                                            type="checkbox"
                                            onChange={e => setReset(e.target.checked)}
                                        />
                                        <strong className="m-l-xs">{translate('Сгораемый')}</strong>
                                        &nbsp;– {translate('когда период действия закончится мы обнулим баланс раздельного счета')}
                                    </label>
                                </div>
                            </div>
                            {/*LESSONS*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label no-padding">{translate('Предметы')}</label> <br />
                                    <a className="crm-dashed-link small crm-toggle-all active" ref={btn_lesson}>
                                        <span className="action-toggle-all" onClick={lessonsCheckAll}>{translate('выбрать все')}</span>
                                        <span className="action-toggle-none" onClick={lessonsUncheckAll}>{translate('снять все')}</span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple" onChange={lessonsHandler}>
                                    <div id="customertotariff-subject_ids">
                                        {
                                            lessonSetting ?
                                                filterDisable(lessonSetting.subject).map(item => (
                                                    <>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                value={item.id}
                                                                name="tariff-lessons"
                                                                id="tariff-lessons"
                                                            />
                                                            &nbsp;{item.name}
                                                        </label>
                                                        <br />
                                                    </>
                                                ))
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*TYPE LESSONS*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label no-padding">{translate('Типы уроков')}</label> <br />
                                    <a className="crm-dashed-link small crm-toggle-all active" ref={btn_type_lesson}>
                                        <span className="action-toggle-all" onClick={typeLessonsCheckAll}>{translate('выбрать все')}</span>
                                        <span className="action-toggle-none" onClick={typeLessonsUncheckAll}>{translate('снять все')}</span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple" onChange={typeLessonsHandler}>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={1}
                                                name="tariff-type_lessons"
                                                id="tariff-type_lessons"
                                            />
                                            &nbsp;{translate('Индивидуальный')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={2}
                                                name="tariff-type_lessons"
                                                id="tariff-type_lessons"
                                            />
                                            &nbsp;{translate('Групповой')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={3}
                                                name="tariff-type_lessons"
                                                id="tariff-type_lessons"
                                            />
                                            &nbsp;{translate('Пробный')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group field-customertotariff-note">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Комментарий')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <textarea
                                        className="form-control autoresize"
                                        rows="1"
                                        placeholder={translate('Любое текстовое примечание')}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-1 m-t-xs" />
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})