import React from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useContext} from "react";
import {openModal} from "../../../../Function/common";
import {SettingContext} from "../../../../Context/SettingContext";
import {info} from "../../../../Function/Notify";
import AppendixClientSvg from "./element/svg/AppendixClientSvg";
import AppendixAdminSvg from "./element/svg/AppendixAdminSvg";

function MemoChatMessage (props) {

    // CONTEXT START
    const { integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // COMP FUNC START
    function getDate (date) {
        let newDate = new Date(date*1000);
        let nowDate = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()-1);

        return newDate.toLocaleDateString() === nowDate.toLocaleDateString() ? translate('Сегодня') : newDate.toLocaleDateString() === tomorrow.toLocaleDateString() ? translate('Вчера') : newDate.toLocaleDateString();
    }

    function copyLink (e) {
        e.preventDefault();

        const crypto = ['a', 'q', 'w', 'R', 'o', 'k', 'L', 's', 'm', 'N'];

        const tempTextarea = document.createElement('textarea');
        const id = `${props.sqlCard.customer_id}`.split('').map(item => crypto[+item]).join('');

        tempTextarea.value = `t.me/${integration?.telegram.filter(item => item.id === props.chooseChat)[0]?.username}?start=${id}`;

        tempTextarea.style.position = 'absolute';
        tempTextarea.style.left = '-1000px';
        document.body.appendChild(tempTextarea);
        tempTextarea.select();

        document.execCommand('copy');

        document.body.removeChild(tempTextarea);

        info( translate('Ссылка скопирована') );
    }

    function formatTextWithLink (text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const textArray = text.split(' ');

        const resultArray = [];

        for (let i = 0; i < textArray.length; i++) {
            if (textArray[i].match(urlRegex)) {
                const wordArray = textArray[i].split('\n');
                let tempArr = [];

                for (let j = 0; j < wordArray.length; j++) {

                    if (wordArray[j].match(urlRegex)) {
                        tempArr.push(<a href={wordArray[j]} target="_blank" className="crm-dashed-link" style={{ color : '#9ed7fc' }} rel="noreferrer">{ wordArray[j] }</a>);
                    } else {
                        tempArr.push(wordArray[j] + ' ');
                    }

                    tempArr.push('\n');
                }

                tempArr.pop();
                resultArray.push(...tempArr);
            } else {
                resultArray.push(textArray[i] + ' ');
            }
        }

        return resultArray;
    }

    const getTime = (date) => new Date(date * 1000).toLocaleTimeString().slice(0, -3);

    function replyToMessage (message) {
        if (!message.reply_to_message) return <></>;

        const replyMessage = props.message.find((item) => item.message_id === message.reply_to_message);

        return (
            <div className="message-subheader">
                <div className="EmbeddedMessage">
                    <div className="message-text">
                        <p><span>{ replyMessage ? replyMessage.text : 'Повідомлення не знайдено' }</span></p>
                        { replyMessage && <div className="message-title">{ replyMessage.name }</div> }
                    </div>
                </div>
            </div>
        )
    }
    // COMP FUNC END

    // EVENT START
    function showMessageMenu (e, item, type) {

        if (e.button !== 2) return

        e.preventDefault();

        const scrolltop = (document.getElementById('scroll-chat').scrollTop + e.pageY) - 120;

        if (type === 'client') {
            props.setMenuStyle({
                left: '170px',
                top: `${scrolltop}px`
            })
        } else {
            props.setMenuStyle({
                right: '30px',
                top: `${scrolltop}px`
            })
        }

        props.setMenuData(item);

        const messageMenu = document.getElementById('message-menu');

        messageMenu.style.display = "block";

        window.onclick = function (event) {
            if (event.target !== messageMenu) {
                messageMenu.style.display = "none";
            }
        }
    }

    function mobileShowMessageMenu (e, item) {

        const pressTimer = window.setTimeout(function () {

            props.setMenuStyle({
                position : 'fixed',
                right: '15px',
                bottom : `${window.innerHeight - 50}px`,
                zIndex : 10000
            })

            props.setMenuData(item);

            const messageMenu = document.getElementById('message-menu');

            messageMenu.style.display = "block";

            window.onclick = function (event) {
                if (event.target !== messageMenu) {
                    messageMenu.style.display = "none";
                    window.clearTimeout(pressTimer);
                }
            }
        }, 1000);
    }
    // EVENT END

    // MESSAGE RENDER FUNC START
    function renderClientMessage (item) {

        return (
            <div className="feed-note" data-id={item.id}>
                {/*CONTENT*/}
                <div className="feed-note__content" onMouseDown={e => showMessageMenu(e, { edit : true , delete : true, ...item }, 'client')} onTouchStart={e => mobileShowMessageMenu(e, { edit : true, delete : true, ...item })}>
                    {/*BODY*/}
                    <div className="feed-note__body">
                        <div className="feed-note__message">
                            { replyToMessage(item) }
                            { formatTextWithLink(item.text) }
                            <span className="MessageMeta">
                                <span className="message-time">
                                    { getTime(item.date) }
                                </span>
                            </span>
                        </div>
                    </div>
                    <AppendixClientSvg />
                </div>
            </div>
        )
    }

    function renderAdminMessage (item) {

        return (
            <div className="feed-note feed-note-out" data-id={item.id}>
                {/*CONTENT*/}
                <div className="feed-note__content" onMouseDown={e => showMessageMenu(e, item, 'admin')} onTouchStart={e => mobileShowMessageMenu(e, item)}>
                    {/*BODY*/}
                    <div className="feed-note__body">
                        <div className="feed-note__message">
                            { formatTextWithLink(item.text) }
                            <span className="MessageMeta">
                                <span className="message-time">
                                    { getTime(item.date) }
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <AppendixAdminSvg />
            </div>
        )
    }

    function renderDocument (item, user) {

        const document = item.document ? JSON.parse(item.document) : null;
        const format = document.file_name.split('.')[1];
        const photoFormat = ['png', 'jpg', 'jpeg'];
        const videoFormat = ['mp4'];

        if (photoFormat.includes(format)) return renderPhoto({ photo : document.link, date : item.date, chat_id : item.chat_id, message_id : item.message_id, id : item.id, copy : true, edit : true }, user);
        else if (videoFormat.includes(format)) return renderVideo(item, user);

        function switchImageFile (data) {
            switch (data) {
                case 'txt' : return 'ion-document-text text-muted';
                case 'pdf' : return 'fa fa-file-pdf-o m-b-xs text-muted';
                default : return 'ion-document text-muted';
            }
        }

        return (
            <div className={`feed-note ${user === 'admin' ? 'feed-note-out' : ''}`} onMouseDown={e => user === 'admin' ? showMessageMenu(e, { copy : true, edit : true, ...item }, 'admin') : () => {}} onTouchStart={e => user === 'admin' ? mobileShowMessageMenu(e, { copy : true, edit : true, ...item }) : () => {}} data-id={item.id}>
                {/*CONTENT*/}
                <div className="feed-note__content">
                    {/*BODY*/}
                    <div className="feed-note__body">
                        <div className="feed-note__message text-center">
                            <a href={document.link} style={{ alignItems: 'center', textDecoration: 'none' }} target="_blank" title={ translate('Скачать документ') } rel="noreferrer">
                                <i className={switchImageFile(format)} style={{ color: '#000000' }} />
                                <span className="crm-dashed-link m-l-xs" style={{ color: '#FFFFFF' }}>{ document.originalName ? document.originalName : document.file_name }</span>
                            </a>
                            <span className="MessageMeta">
                                <span className="message-time">
                                    { getTime(item.date) }
                                </span>
                            </span>
                        </div>
                    </div>
                    {
                        user === 'admin' ? (
                            <div className="svg-appendix" style={{ right : '-0.49rem', bottom : 0, overflow : 'hidden' }}>
                                <svg width={9} height={20} xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <filter x="-50%" y="-14.7%" width="200%" height="141.2%" filterUnits="objectBoundingBox" id="a"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0.0621962482 0 0 0 0 0.138574144 0 0 0 0 0.185037364 0 0 0 0.15 0" in="shadowBlurOuter1" /></filter>
                                    </defs>
                                    <g fill="none" fillRule="evenodd">
                                        <path
                                            d="M6 17H0V0c.193 2.84.876 5.767 2.05 8.782.904 2.325 2.446 4.485 4.625 6.48A1 1 0 016 17z"
                                            fill="#000" filter="url(#a)"
                                        />
                                        <path
                                            d="M6 17H0V0c.193 2.84.876 5.767 2.05 8.782.904 2.325 2.446 4.485 4.625 6.48A1 1 0 016 17z"
                                            fill="#EEFFDE" className="corner"
                                        />
                                    </g>
                                </svg>
                            </div>
                        ) : <AppendixClientSvg />
                    }
                </div>
            </div>
        )
    }

    function renderPhoto (item, user) {

        return (
            <div
                className={`feed-note ${user === 'admin' ? 'feed-note-out' : ''}`}
                onMouseDown={e => user === 'admin' ? showMessageMenu(e, item, 'admin') : () => {}}
                onTouchStart={e => user === 'admin' ? mobileShowMessageMenu(e, item) : () => {}}
                data-id={item.id}
            >
                <div className="media-inner" onClick={() => {
                    props.setFullPhoto(item.photo);
                    openModal('modal-photo');
                }}>
                    <img
                        src={item.photo}
                        alt=""
                        className="short-media"
                    />
                    <span className="MessageMeta">
                        <span className="message-time">
                             { getTime(item.date) }
                        </span>
                    </span>
                </div>
            </div>
        )
    }

    function renderVideo (item, user) {

        const video = item.document ? JSON.parse(item.document) : null;

        return (
            <div
                className={`feed-note ${user === 'admin' ? 'feed-note-out' : ''}`}
                onMouseDown={e => user === 'admin' ? showMessageMenu(e, item, 'admin') : () => {}}
                onTouchStart={e => user === 'admin' ? mobileShowMessageMenu(e, item) : () => {}}
                data-id={item.id}
            >
                <div className="message-content-wrapper">
                    <div className="message-content no-text media has-shadow">
                        <div className="media-inner interactive">
                            <video
                                onClick={(event) => event.target.play()}
                                className="full-media with-blurred-bg"
                                playsInline="true"
                                src={video.link}
                            />
                            <span className="MessageMeta">
                                <span className="message-time">
                                     { getTime(item.date) }
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderVoice (item, user) {

        return (
            <div className="feed-note" data-id={item.id}>
                {/*CONTENT*/}
                <div className="feed-note__content" onMouseDown={e => showMessageMenu(e, { edit : true , delete : true, ...item }, 'client')} onTouchStart={e => mobileShowMessageMenu(e, { edit : true, delete : true, ...item })}>
                    <div className="feed-note__body">
                        <div className="feed-note__message" style={{ display: 'flex', alignItems: 'flex-end' }}>
                            { replyToMessage(item) }
                            <audio className="no-margin" controls="true" style={{ height: '40px' }}>
                                <source src={item.voice} type="audio/ogg" />
                            </audio>
                            <span className="MessageMeta">
                                <span className="message-time">
                                    { getTime(item.date) }
                                </span>
                            </span>
                        </div>
                    </div>
                    <AppendixClientSvg />
                </div>
            </div>
        )
    }

    function renderVideoNote (item, user) {
        return (
            <div
                className="feed-note"
                data-id={item.id}
                onMouseDown={e => showMessageMenu(e, { copy: true, edit : true , delete : true, ...item }, 'client')}
                onTouchStart={e => mobileShowMessageMenu(e, { copy: true, edit : true, delete : true, ...item })}
            >
                <div className="video-note-wrapper">
                    <div className="round-video">
                        <div className="video-wrapper">
                            <video
                                onClick={(event) => event.target.play()}
                                src={item.video_note}
                                className="full-media"
                                width={240}
                                disablePictureInPicture={true}
                                playsInline={true}
                                preload="auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    // MESSAGE RENDER FUNC END

    if (!props.sqlCard.chat_id) {
        return (
            <div className="sticky-date">
                <span className="invite-link" style={{ width : 'auto', cursor : 'pointer' }} onClick={e => copyLink(e)}>
                    { translate('Скопировать ссылку для подключения') }
                    <i className="fa fa-link m-l-xs" style={{ fontSize : '10px' }} />
                </span>
            </div>
        )
    } else if (props.message.length === 0) {
        return (
            <div className="sticky-date">
                <span style={{ width : 'auto' }}> { translate('Пока нету сообщений') } </span>
            </div>
        )
    }

    let date = [];

    return props.message.slice(-props.numShow).map(item => {

        let bool = false;

        if (!date.includes(getDate(item.date))) {
            date.push(getDate(item.date))
            bool = true;
        }

        if (item.username !== 'admin') {
            return (
                <>
                    { bool ? ( <div className="sticky-date"> <span> { getDate(item.date) } </span> </div>) : null }

                    { item.text && renderClientMessage(item) }
                    { item.photo && renderPhoto(item, 'client') }
                    { item.document && renderDocument(item, 'client') }
                    { item.voice && renderVoice(item, 'client') }
                    { item.video_note && renderVideoNote(item, 'client') }

                </>
            )
        } else {
            return (
                <>
                    { bool ? ( <div className="sticky-date"> <span> { getDate(item.date) } </span> </div> ) : null}

                    { item.text && renderAdminMessage(item) }
                    { item.document && renderDocument(item, 'admin') }

                </>
            )
        }
    })
}

export const ChatMessage = React.memo(MemoChatMessage);