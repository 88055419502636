import {useContext, useEffect, useRef, useState} from "react";
import Gender from "../../../Components/Input/Gender";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, closeModal} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useArchive} from "../../../Hooks/archive.hook";

export default function EditUser (props) {

    // REF START
    const modal = useRef();
    const passwordInput = useRef();
    const autoPasswordButton = useRef();
    // REF END

    // CONTEXT START
    const { customSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { error, request } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [role, setRole] = useState({});
    const [name, setName] = useState('');
    const [gender, setGender] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(undefined);
    const [interfaceLanguage, setInterfaceLanguage] = useState('');
    const [phone, setPhone] = useState('');
    // STATE END

    // VALID STATE START
    const [formValid, setFormValid] = useState(false);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    // VALID STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.userEdit).length) return

        setRole({ role_id : props.userEdit.role });
        setName(props.userEdit.name);
        setGender(props.userEdit.gender);
        setEmail(props.userEdit.email);
        setInterfaceLanguage(props.userEdit.interface_language);
        setPhone(props.userEdit.phone);

        filterDisableEntity(customSetting ? customSetting.customField : [], "user").map(item => {
            document.forms["edit_user"].elements["user-edit-"+item.systemName].value = props.userEdit[item.systemName];
        })

    }, [customSetting, props.userEdit])
    // INIT DATA END

    // VALID USE EFFECT START
    useEffect(() => {
        if (role.role_id === undefined || nameError || emailError || passwordError) {
            setFormValid(false);
        } else {
            setFormValid(true)
        }
    }, [role, name, email, password])
    // VALID USE EFFECT END

    // HANDLER START
    function nameHandler (e) {
        setName(e.target.value);
        if (e.target.value.length === 0) {
            setNameError(`${translate('Необходимо заполнить')} «${translate('ФИО')}».`);
        } else {
            setNameError('');
        }
    }
    function emailHandler (e) {
        setEmail(e.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(e.target.value)) {
            setEmailError(`${translate('Неверный')} «E-mail» ${translate('адрес')}.`);
        } else {
            setEmailError('');
        }
    }
    function passwordHandler (e) {
        setPassword(e.target.value);
        const re_number = /(?=.*[0-9]).*/;
        const re_letter = /(?=.*[^A-Za-z0-9]).*/;

        if (e.target.value.length <= 6) {
            setPasswordError(`${translate('Количество символов должно быть не меньше 6.')}`)
        } else {
            if (!re_number.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать хотя бы одну цифр.')}`)
            } else if (re_letter.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать только латинские буквы и цифры.')}`)
            } else {
                setPasswordError('');
            }
        }
    }

    function checkPasswordHandler (e) {
        passwordInput.current.disabled = !e.target.checked
        autoPasswordButton.current.disabled = !e.target.checked
        setPasswordError('');
        setPassword(undefined);
    }
    // HANDLER END

    // FUNCTION START
    async function buttonArchive (e, value) {
        e.preventDefault();

        await request(`${URL.back_end}/user/${props.userEdit.user_id}`, 'PUT', { f_removed: value })

        if (error) return console.log(error)

        props.sql.forEach(function (item, i) {
            if (props.userEdit.user_id === item.user_id) {
                props.sql[i].f_removed = value;
                props.updateSql([...props.sql]);
            }
        })

        closeModal(e, modal);
    }

    async function buttonDelete (e) {
        e.preventDefault();

        await request(`${URL.back_end}/user/${props.userEdit.user_id}`, 'DELETE');

        if (error) return console.log(error)

        window.location.reload();
    }
    // FUNCTION END

    // EDIT USER START
    async function editUser (e) {

        e.preventDefault();

        let user = {
            role: role.role_id,
            name, gender, email, password,
            interface_language: interfaceLanguage,
            phone
        }

        filterDisableEntity(customSetting.customField, "user").map(item => {
            user[item.systemName] = document.forms["edit_user"].elements["user-edit-"+item.systemName].value
        })

        const edit_user = await request(`${URL.back_end}/user/${props.userEdit.user_id}`, 'PUT', user);
        await pushArchive(2, 'user', props.userEdit.user_id, { ...user, user_id : props.userEdit.user_id, password : props.userEdit.password }, props.userEdit);

        if (error) return console.log(error)

        user.password = edit_user.password;

        user["user_id"] = props.userEdit.user_id;
        user["f_removed"] = props.userEdit.f_removed;

        props.sql.forEach(function (item, i) {
            if (props.userEdit.user_id === item.user_id) {
                props.sql[i] = user;
                props.updateSql([...props.sql]);
            }
        })

        closeModal(e, modal);
    }
    // EDIT USER END

    return (
        <div id="edit-user" className="modal in" ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form id="w0" className="form-horizontal" name="edit_user" onSubmit={e => editUser(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">
                                {translate('Править пользователя')} &nbsp;— &nbsp;
                                <span className="dropdown">
                                    <a className="dropdown-toggle crm-dashed-link text-lowercase">
                                        {translate('Активен')}
                                        <span className="caret" />
                                    </a>
                                </span>
                            </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*Роль(и)*/}
                            <div className="form-group field-user-role_ids required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-role_ids">
                                        {translate('Роль(и)')}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseSingleObject
                                        sql={props.roleData}
                                        setProps={setRole}
                                        value={props.roleData.filter(item => item.role_id === role.role_id)[0]?.name}
                                        type_id="role_id"
                                        topicSearch="name"
                                    />
                                </div>
                            </div>
                            {/*ФИО + ПОЛ*/}
                            <div className={`form-group required ${nameError ? "has-error" : ""}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-name">{translate('ФИО')}</label>
                                </div>
                                <div className="col-sm-6" style={{paddingRight: 0}}>
                                    <input
                                        type="text"
                                        id="user-name"
                                        className="form-control"
                                        name="name"
                                        placeholder={translate('Например, Иванов Иван')}
                                        value={name}
                                        onChange={e => nameHandler(e)}
                                    />
                                    <div className="small text-danger">{nameError ? nameError : null}</div>
                                </div>
                                <div className="col-sm-3" style={{paddingLeft: "1px"}}>
                                    <Gender
                                        setProps={setGender}
                                        value={gender}
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            {/*{*/}
                            {/*    role.role_id ?*/}
                            {/*        findCustomObject(props.roleData, "role_id", role.role_id).interface === 2 ?*/}
                            {/*            (*/}
                            {/*                <div className="form-group required">*/}
                            {/*                    <div className="col-sm-3">*/}
                            {/*                        <label className="control-label" htmlFor="user-email">Педагог</label>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="col-sm-9">*/}
                            {/*                        <InputChooseSingleObject*/}
                            {/*                            sql={props.employeeData}*/}
                            {/*                            setProps={setEmployee}*/}
                            {/*                            value={props.employeeData.filter(item => item.employee_id === props.userEdit.systemId)[0]?.name}*/}
                            {/*                            topicSearch="name"*/}
                            {/*                            type_id="employee_id"*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            )*/}
                            {/*            : null*/}
                            {/*        : null*/}
                            {/*}*/}
                            {/*EMAIL*/}
                            <div className={`form-group field-user-email required ${emailError ? "has-error" : ""}`}>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-email">E-mail</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="user-email"
                                        className="form-control"
                                        name="email"
                                        placeholder={`${translate('Например')}, user@mail.ua`}
                                        value={email}
                                        onChange={e => emailHandler(e)}
                                    />
                                    <div className="help-block">{emailError ? emailError : null}</div>
                                </div>
                            </div>
                            {/*CHANGE PASSWORD*/}
                            <div className={`form-group field-user-password ${passwordError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="m-t-xs">
                                        <input
                                            type="checkbox"
                                            id="user-change_password"
                                            name="user-change_password"
                                            onChange={e => checkPasswordHandler(e)}
                                        />
                                        &nbsp;{translate('Сменить пароль')}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="user-password"
                                        className="new-pass form-control"
                                        name="password"
                                        autoComplete="off"
                                        ref={passwordInput}
                                        disabled
                                        value={password}
                                        onChange={e => passwordHandler(e)}
                                    />
                                    <div className="help-block">{passwordError ? passwordError : null}</div>
                                </div>
                            </div>
                            {/*INTERFACE LANGUAGE*/}
                            <div className="form-group field-user-language">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="user-language">{translate('Язык интерфейса')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <select className="form-control" value={interfaceLanguage} onChange={e => setInterfaceLanguage(e.target.value)}>
                                        <option value="ua"> Українська </option>
                                        <option value="ru"> Русский </option>
                                        <option value="en"> English </option>
                                    </select>
                                </div>
                            </div>
                            {/*PHONE*/}
                            <div className="form-group field-user-phone">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Телефон') }
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <b> { translate('Дополнительные поля') } </b>
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "user").map(item => (
                                        <div className="form-group field-user-inn">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-inn"> { item.name } </label>
                                            </div>
                                            <div className="col-sm-9">
                                                {typeField(item, "user-edit-")}
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                {
                                    props.userEdit.f_removed === 0 ?
                                        <a className="btn btn-sm btn-danger btn-outline pull-left" title={translate('В архив')} onClick={e => buttonArchive(e, 1)}>
                                            <i className="fa fa-trash-o" />
                                        </a> :
                                        <>
                                            <a className="btn btn-sm btn-danger btn-outline pull-left" title={translate('Удалить')} onClick={e => buttonDelete(e)}>
                                                <i className="fa fa-trash-o" />
                                            </a>
                                            <a className="btn btn-sm btn-primary btn-outline pull-left" title={translate('Из архива')} onClick={e => buttonArchive(e, 0)}>
                                                <i className="fa fa-reply" />
                                            </a>
                                        </>
                                }
                                <a className="btn btn-sm btn-info btn-outline" title={ translate('История изменений') }>
                                    <i className="fa fa-history" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" disabled={!formValid}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}