// HOOK
import {useHttp} from "./http.hook";
import {useCallback} from "react";

// JSON
import URL from './../Api/URL.json';

import jwt_decode from "jwt-decode";

export const useArchive = () => {

    // HOOK START
    const { request, error } = useHttp();
    // HOOK END

    // FUNCTION START
    function nullObj (obj) {
        let new_obj = {};

        Object.assign(new_obj, obj);

        for (let key in new_obj) {
            new_obj[key] = null;
        }

        return new_obj;
    }
    // FUNCTION END

    const pushArchive = useCallback(async (action, entity, id, newField, oldField) => {

        if (action === 3 || action === 4) {
            newField = {
                ...newField,
                e_date: new Date().toISOString().split("T")[0]
            }
            oldField = {
                ...oldField,
                e_date: null
            }
        }

        if (newField === undefined || oldField === undefined || action === undefined || entity === undefined || id === undefined) return;

        oldField = oldField === null ? nullObj(newField) : oldField;

        try {
            const user_id = jwt_decode( JSON.parse(localStorage.getItem('userData')).token ).user_id

            await request(`${URL.back_end}/log`, 'POST', {
                action,
                entity,
                id,
                user_id,
                newField: JSON.stringify(newField),
                oldField: JSON.stringify(oldField)
            })

            if (error) throw new Error('200');
        } catch (e) {
            console.log(e);
        }
    }, [request, error])

    return { pushArchive };
}