import {useHttp} from "../../../../../Hooks/http.hook";
import {useEffect, useRef, useState} from "react";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal} from "../../../../../Function/common";
import {validate} from "../../../../../Function/validateForm";
import {useArchive} from "../../../../../Hooks/archive.hook";

export default function EditUser (props) {

    // REF START
    const modal = useRef();
    const password_input = useRef();
    const auto_password = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState(undefined);
    const [ status, setStatus ] = useState('');
    // STATE END

    // VALID STATE START
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    // VALID STATE END

    // INIT DATA START
    useEffect(() => {
        if(!Object.keys(props.editUser).length) return

        setName(props.editUser.name);
        setEmail(props.editUser.email);
        setStatus(props.editUser.status);
    }, [props.editUser])
    // INIT DATA END

    // HANDLER START
    function nameHandler(e) {

        setName(e.target.value);

        if (e.target.value.length === 0) {
            setNameError(`${translate('Необходимо заполнить')} «${translate('ФИО')}».`);
        } else {
            setNameError('');
        }
    }
    function emailHandler(e) {

        setEmail(e.target.value);

        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(e.target.value)) {
            setEmailError(`${translate('Неверный')} «E-mail».`);
        } else {
            setEmailError('');
        }
    }
    function passwordHandler(e) {

        setPassword(e.target.value);

        const re_number = /(?=.*[0-9]).*/;
        const re_letter = /(?=.*[^A-Za-z0-9]).*/;

        if (e.target.value.length <= 6) {
            setPasswordError(`${translate('Количество символов должно быть не меньше 6.')}`)
        } else {
            if (!re_number.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать хотя бы одну цифр.')}`)
            } else if (re_letter.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать только латинские буквы и цифры.')}`)
            } else {
                setPasswordError('');
            }
        }
    }
    function changePasswordHandler(e) {
        password_input.current.disabled = !e.target.checked;
        auto_password.current.disabled = !e.target.checked;

        if (e.target.checked === false) {
            setPassword(undefined);
            setPasswordError('');
        }
    }
    // HANDLER END

    // FUNCTION START
    async function btnDelete () {
        await request(`${URL.back_end}/user/${props.editUser.user_id}`, 'DELETE');
        await pushArchive(3, 'user', props.editUser.user_id);

        window.location.reload();
    }
    // FUNCTION END

    // EDIT USER START
    async function editUser(e) {
        e.preventDefault();

        const validObj = {
            "ФИО" : !name.length,
            "E-mail" : !email.length
        }

        if (validate(validObj, translate)) return

        e.currentTarget.disabled = true;

        await request(`${URL.back_end}/user/${props.editUser.user_id}`, 'PUT', { name, email, password });

        if (error) return console.log(error)

        await pushArchive(2, 'user', props.editUser.user_id, { user_id : props.editUser.user_id, name, email, role : props.editUser.role, password : props.editUser.password, systemId : props.editUser.systemId }, props.editUser);

        window.location.reload();
    }
    // EDIT USER END

    return (
        <div id="edit-user" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editUser(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Предоставить доступ') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*NAME*/}
                            <div className={`form-group field-user-name required ${nameError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('ФИО')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Иванов Иван')}
                                        value={name}
                                        onChange={e => nameHandler(e)}
                                    />
                                    <div className="help-block">{nameError ? nameError : null}</div>
                                </div>
                            </div>
                            {/*EMAIL*/}
                            <div className={`form-group field-user-email required ${emailError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">E-mail</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, email@mail.ua`}
                                        value={email}
                                        onChange={e => emailHandler(e)}
                                    />
                                    <div className="help-block">{emailError ? emailError : null}</div>
                                </div>
                            </div>
                            {/*CHANGE PASSWORD*/}
                            <div className={`form-group field-user-password ${passwordError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="m-t-xs">
                                        <input
                                            type="checkbox"
                                            id="user-change_password"
                                            onChange={e => changePasswordHandler(e)}
                                        />
                                        &nbsp;{translate('Сменить пароль')}
                                    </label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="new-pass form-control"
                                        disabled
                                        autoComplete="off"
                                        value={password}
                                        onChange={e => passwordHandler(e)}
                                        ref={password_input}
                                    />
                                    <div className="help-block">{passwordError ? passwordError : null}</div>
                                </div>
                            </div>
                            {/*STATUS*/}
                            <div className="form-group field-user-status">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Статус')}</label>
                                </div>
                                <div className="col-sm-7">
                                    <select className="form-control" onChange={e => setStatus(e.target.value)} value={status}>
                                        <option value={1}> { translate('Активен') } </option>
                                        <option value={2}> { translate('Заблокирован') } </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-danger btn-outline" title={ translate('Удалить') } onClick={() => btnDelete()}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" disabled={(nameError || emailError || passwordError)}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}