import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SideMenu from "../../../../Components/SideMenu/SideMenu";
import TabAction from "../../../../Components/TabAction";
import TableVisitLog from "./Table/TableVisitLog";

export default function ReportCustomer () {

    // HOOK START
    const navigate = useNavigate();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    const [ searchParams ] = useSearchParams();
    const report = searchParams.get('report');
    // FILTER PARAMS END

    // RENDER TABLE START
    function renderTable (type, params) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'visitLog' : return <TableVisitLog params={JSON.parse(params)} />
        }
    }
    // RENDER TABLE END

    return (
        <div id='wrapper'>
            {/* SIDE MENU */}
            <SideMenu page='customer' />
            {/* CONTENT */}
            <div id='page-wrapper' className="gray-bg">
                <div className='wrapper crm-content'>
                    {/* ACTIONS */}
                    <div className='row m-b-sm crm-stick crm-actions'>
                        <div className='col-sm-12'>
                            <div className='ibox-content border-bottom'>
                                {/*BUTTON BACK*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-w-m btn-sm btn-default"
                                    icon="ion-arrow-left-c"
                                    text="Назад"
                                    handler={() => navigate(-1)}
                                />
                                {/* PRINT */}
                                <TabAction
                                    access={true}
                                    className="btn btn-w-m btn-sm btn-info"
                                    icon="fa fa-print"
                                    text="На печать"
                                    handler={() => {}}
                                />
                            </div>
                        </div>
                    </div>
                    {/* BODY */}
                    { renderTable(report, filterParams) }
                </div>
            </div>
        </div>
    )
}