import React, {memo} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import TabHeader from "../../../Components/TabHeader";
import {AgileColumns} from "./AgileColumns";
import {pagination} from "../../../Function/common";
import {Link} from "react-router-dom";

export const AgileTable = memo(({ sql, setSql, pageSize, page, counts, viewMode, filterParams, setSelectedTask, loading }) => {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // FUNC TABLE START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;

        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            setSelectedTask(tempSelection);
        } else {
            selection.forEach(element => element.checked = false);
            setSelectedTask([])
        }
    }

    function renderTbody () {

        if (loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (sql.length) {
            return sql.map(item =>
                <AgileColumns
                    data={item}
                    sql={sql}
                    setSql={setSql}
                    setSelectedTask={setSelectedTask}
                />
            )
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // FUNC TABLE END

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="tabs-container m-b-sm">
                    {/* ACTIONS */}
                    <ul className="nav nav-tabs">
                        <li className={!filterParams ? 'active' : ''}>
                            <Link to="/company/1/agile/index?view_mode=table">
                                <i className="ion-pinpoint" /> { translate('Открыто') }
                            </Link>
                        </li>
                        <li className={filterParams ? JSON.parse(filterParams).status === '2' ? 'active' : '' : ''}>
                            <Link to={`/company/1/agile/index/${encodeURI(JSON.stringify({ status : '2' }))}?view_mode=table`}>
                                <i className="ion-checkmark-round" /> { translate('Выполнено') }
                            </Link>
                        </li>
                        <li className={filterParams ? JSON.parse(filterParams).f_removed === '1' ? 'active' : '' : ''}>
                            <Link to={`/company/1/agile/index/${encodeURI(JSON.stringify({ f_removed : '1' }))}?view_mode=table`}>
                                <i className="fa fa-trash-o" /> { translate('Архив') }
                            </Link>
                        </li>
                    </ul>
                    {/* TABLE */}
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane active">
                            <div className="panel-body">
                                <TabHeader
                                    counts={counts}
                                    entity='task'
                                />
                                <div className="border-bottom" />
                                <div id="w1-container" className="table-responsive kv-grid-container scroll-block">
                                    <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                                        <thead>
                                            <tr>
                                                <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{ width : '5.37%' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="select-on-check-all"
                                                        name="selection_all"
                                                        onClick={e => selection_all(e)}
                                                    />
                                                </th>
                                                <th style={{ width : '8.79%' }}> { translate('Создана') } </th>
                                                <th style={{ width : '20.12%' }}> { translate('Исполнитель') } </th>
                                                <th style={{ width : '10.15%' }}> { translate('Исполнить') } </th>
                                                <th style={{ width : '20.01%' }}> { translate('Описание задачи') } </th>
                                                <th style={{ width : '6.96%' }}> { translate('Опции') } </th>
                                                <th style={{ width : '14.96%' }}> { translate('Дата исполнения') } </th>
                                                <th style={{ width : '3.29%' }}> </th>
                                            </tr>
                                        </thead>
                                        <tbody> { renderTbody() } </tbody>
                                    </table>
                                </div>
                                <div className="text-center" translate="no">
                                    { pageSize && pagination(counts, pageSize, 'task', page, viewMode) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})