import {useEffect} from "react";

export default function TimeChosen (props) {

    useEffect(() => {
        for (let key in props.editSchedule) {
            for (let i = 0; i < props.editSchedule[key].length; i++) {
                document.querySelector(`[data-name="${key}-${props.editSchedule[key][i]}"]`).className = document.querySelector(`[data-name="${key}-${props.editSchedule[key][i]}"]`).className.replace("btn-white", "btn-success active");
            }
        }
    }, [props.editSchedule])

    function chooseHandler (e) {
        let obj = props.schedule;

        if (e.currentTarget.className.indexOf("active") === -1) {
            e.currentTarget.className = e.currentTarget.className.replace("btn-white", "btn-success active")
            obj[e.target.dataset.day].push(+e.target.dataset.hour);

            obj[e.target.dataset.day] = obj[e.target.dataset.day].sort(function(a, b) {
                return a - b;
            })

            props.setSchedule({
                1: obj[1],
                2: obj[2],
                3: obj[3],
                4: obj[4],
                5: obj[5],
                6: obj[6],
                0: obj[0]
            });
        } else {
            e.currentTarget.className = e.currentTarget.className.replace("btn-success active", "btn-white")
            obj[e.target.dataset.day] = obj[e.target.dataset.day].filter(item => {
                if (item !== +e.target.dataset.hour) {
                    return item;
                }
            })

            obj[e.target.dataset.day] = obj[e.target.dataset.day].sort(function(a, b) {
                return a - b;
            })

            props.setSchedule({
                1: obj[1],
                2: obj[2],
                3: obj[3],
                4: obj[4],
                5: obj[5],
                6: obj[6],
                0: obj[0]
            });
        }
    }

    function chooseAll (e) {
        let obj = props.schedule;
        let span = document.querySelectorAll(`[data-day='${props.day}']`)

        if (e.currentTarget.checked) {
            obj[props.day] = props.time;

            for (let i = 0; i < span.length; i++) {
                span[i].className = span[i].className.replace("btn-white", "btn-success active");
            }

        } else {
            obj[props.day] = [];

            for (let i = 0; i < span.length; i++) {
                span[i].className = span[i].className.replace("btn-success active", "btn-white");
            }
        }

        props.setSchedule({
            1: obj[1],
            2: obj[2],
            3: obj[3],
            4: obj[4],
            5: obj[5],
            6: obj[6],
            0: obj[0]
        });
    }

    return (
        <>
            <div className="btn-group btn-hour-row">
                {
                    props.time.map(item => (
                        <span className="btn btn-sm btn-day-hour js-day-hour btn-white" data-day={props.day} data-hour={item} data-name={`${props.day}-${item}`} onClick={e => chooseHandler(e)}>
                            {item <= 9 ? `0${item}` : item}
                        </span>
                    ))
                }
                <input
                    type="checkbox"
                    className="btn-select-day js-day-hour-select-day"
                    data-day={props.day}
                    onClick={e => chooseAll(e)}
                />
            </div>
        </>
    )
}