import React, {useRef, useState} from "react";
import {closeModal} from "../../Function/common";
import {useTranslate} from "../../Hooks/translate.hook";
import {InputChooseSingleObject} from "../InputChoose/InputChooseSingleObject";
import {validate} from "../../Function/validateForm";
import {useHttp} from "../../Hooks/http.hook";
import URL from "../../Api/URL.json";

export default function SwapChat ({ customer_id, chat_id, customerData }) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ chosenCustomer, setChosenCustomer ] = useState({});
    // STATE END

    // SWAP CHAT START
    async function swapChat (e) {
        e.preventDefault();

        if (validate({ "Клієнт" : !Object.keys(chosenCustomer).length }, translate)) return

        await request(`${URL.back_end}/customer/${customer_id}`, 'PUT', { chat_id : null });
        await request(`${URL.back_end}/customer/${chosenCustomer.customer_id}`, 'PUT', { chat_id });

        window.location.reload();
    }
    // SWAP CHAT END

    return (
        <div id="swap-chat" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => swapChat(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> Перенести чат </h4>
                        </div>
                        <div className="modal-body p-md">
                            <div className="form-group field-customer-name required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        Оберіть клієнта
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseSingleObject
                                        sql={customerData.filter(item => !item.chat_id && item.f_removed === 0)}
                                        setProps={setChosenCustomer}
                                        type_id="customer_id"
                                        topicSearch="name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}