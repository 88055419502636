import {useTranslate} from "../../../../Hooks/translate.hook";


export default function Facebook () {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function btn () {
        window.FB.login(function(response) {
            console.log(response);
        });
    }

    function btnLogout () {
        window.FB.logout(function(response) {
            console.log(response)
        });
    }

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title"> Facebook </h4>
            </div>
            <div className="modal-body p-md" style={{ paddingBottom: "5px" }}>
                <h4 className="font-noraml border-bottom m-b-sm m-t-none no-padding">
                    1. { translate('Войдите в Facebook') }
                </h4>
                <div className="fb-login-button"
                     data-width=""
                     data-size=""
                     data-button-type=""
                     data-layout=""
                     data-auto-logout-link="false"
                     data-use-continue-as="false"
                />
                <button onClick={() => btn()}>
                    login
                </button>
                <button onClick={() => btnLogout()}>
                    logout
                </button>
            </div>
        </>
    )
}