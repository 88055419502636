import {memo} from "react";
import {getTariffInfo} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";

export const TotalBalanceBlock = memo(({ sqlCard, tariffData }) => {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function renderBalance () {
        let { balance, lesson } = getTariffInfo(tariffData, sqlCard.balance);

        return (
            <span>
                <span className={balance > 0 ? 'text-navy' : 'text-danger'}>{ balance }</span> / <span className={lesson > 0 ? 'text-navy' : 'text-danger'}>{ lesson } {translate('уроков')}</span>
            </span>
        )
    }

    return (
        <div className="row font-bold">
            <div className="col-xs-5 m-b-sm">
                <h4 className="no-margins"> { translate('Общий остаток') } </h4>
            </div>
            <div className="col-xs-7 m-b-sm text-right">
                { renderBalance() }
            </div>
        </div>
    )
})