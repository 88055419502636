import {useContext, useEffect, useRef, memo, useState} from "react";
import {closeModal} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {success} from "../../../../Function/Notify";
import {Link} from "react-router-dom";
import {validate} from "../../../../Function/validateForm";
import {SettingContext} from "../../../../Context/SettingContext";

export const TurboSms = memo(function MemoTurboSms ({ sqlCard, chooseLink }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { integration, sms } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ phone, setPhone ] = useState('');
    const [ message, setMessage ] = useState('');
    // STATE END

    // VALUE START
    const crypto = ['a', 'q', 'w', 'R', 'o', 'k', 'L', 's', 'm', 'N'];
    // VALUE END

    // INIT DATA START
    useEffect(() => {
        const customerPhone = sqlCard.contact ? JSON.parse(sqlCard.contact).map(item => item.type === 'mobile' ? item.text : false)[0] : false;

        if (customerPhone) setPhone(customerPhone);

        setMessage(`${translate('Приглашение в телеграм')} : t.me/${integration && integration.telegram.filter(item => item.id === chooseLink)[0]?.username}?start=${`${sqlCard.customer_id}`.split('').map(item => crypto[+item]).join('')}`);
    }, [sqlCard, integration, chooseLink])
    // INIT DATA END

    // SEND TURBO START
    async function sendTurbo (e) {
        e.preventDefault();

        let validObj = {
            "Номер телефона" : !phone.length,
            "Сообщение" : !message.length
        }

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        let contact = phone.replace('+', '');

        await fetch(`https://api.turbosms.ua/message/send.json?recipients[0]=${contact}&sms[sender]=${sms.turbo.sender}&sms[text]=${encodeURI(message)}&token=${sms.turbo.token}`, { method : 'GET' })

        success(translate('Сообщение отправлено'))

    }
    // SEND TURBO END

    return (
        <div id="send-turbo" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => sendTurbo(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Отправить сообщение через TurboSms') } </h4>
                        </div>
                        <div className="modal-body p-md">
                            {
                                sms ? !sms.turbo.sender ? (
                                    <div className="col-sm-13">
                                        <p className="p-xs bg-warning text-center">
                                            { translate('TurboSms не подключен.') } <br />
                                            <Link to={'/settings/1/telegram/index'}> { translate('Подключить') } </Link>
                                        </p>
                                    </div>
                                ) : null : null
                            }
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="social-name"> { translate('Отправитель') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <span className="form-control text-muted" style={{ userSelect : "none" }}>
                                        { sms ? sms.turbo.sender : null }
                                    </span>
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="social-name"> { translate('Номер телефона') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 380664547558`}
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="social-access_token"> { translate('Сообщение') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <textarea
                                        className="form-control"
                                        placeholder={`${ translate('Например') }, ${translate('привет')}! `}
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m" id="btn_submit" disabled={sms ? Object.keys(sms.turbo).length < 0 : true}>
                                <i className="fa fa-send small" /> { translate('Отправить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})