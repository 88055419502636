import {useEffect, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useNavigate} from "react-router-dom";

export default function FilterLog (props) {

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [user_id, setUserId] = useState('');
    const [action, setAction] = useState('');
    const [entity, setEntity] = useState('');
    const [id, setId] = useState('');
    const [date_start, setDateStart] = useState('');
    const [date_end, setDateEnd] = useState('');
    const [oldKey, setOldKey] = useState('');
    const [oldValue, setOldValue] = useState('');
    const [oldOperation, setOldOperation] = useState('');
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    const [newOperation, setNewOperation] = useState('');
    // STATE END

    // OBJECT FILTER START
    Object.filter = function(obj, filtercheck) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }

    // INIT DATA START
    useEffect(() => {
        if (!props.value) {
            setUserId('');
            setAction('');
            setEntity('');
            setId('');
            setDateStart('');
            setDateEnd('');
            setOldKey('');
            setOldValue('');
            setOldOperation('');
            setNewKey('');
            setNewValue('');
            setNewOperation('');
            return;
        }

        setUserId(props.value.user_id);
        setAction(props.value.action);
        setEntity(props.value.entity);
        setId(props.value.id);
        setOldKey(props.value.oldKey);
        setOldValue(props.value.oldValue);
        setOldOperation(props.value.oldOperation);
        setNewKey(props.value.newKey);
        setNewValue(props.value.newValue);
        setNewOperation(props.value.newOperation);
        setDateStart(props.value.date_start);
        setDateEnd(props.value.date_end);
    }, [props.value])
    // INIT DATA END

    // FILTER LOG START
    async function filterLog (e) {
        e.preventDefault();

        const log = {
            user_id,
            action,
            entity,
            id,
            oldKey,
            oldValue,
            oldOperation,
            newKey,
            newValue,
            newOperation,
            date_start,
            date_end
        }

        const obj = Object.filter(log, (value) => value);

        if (Object.keys(obj).length) navigate(`/company/1/log/index/${encodeURI(JSON.stringify(obj))}`);
        else navigate(`/company/1/log/index/`);

    }
    // FILTER LOG END

    return (
        <div className="col-lg-4">
            <div className="ibox float-e-margins m-b-sm">
                <div className="ibox-content">
                    <form onSubmit={e => filterLog(e)}>
                        {/*USER*/}
                        <div className="form-group">
                            <label className="control-label"> { translate('Пользователь') } </label>

                            <select className="form-control" value={user_id} onChange={e => setUserId(e.target.value)}>
                                <option value=''> { translate('Не задано') } </option>
                                { sortArray(props.userData, 'name').map(item => <option value={item.user_id}> { item.name } </option>) }
                            </select>
                        </div>
                        {/*ACTION*/}
                        <div className="form-group">
                            <label className="control-label"> { translate('Действие') } </label>
                            <select className="form-control" value={action} onChange={e => setAction(e.target.value)}>
                                <option value=""> { translate('Не задано') } </option>
                                <option value="1"> { translate('Добавление') } </option>
                                <option value="2"> { translate('Правка') } </option>
                                <option value="3"> { translate('Удаление') } </option>
                                <option value="4"> { translate('Восстановление') } </option>
                            </select>
                        </div>
                        {/*ENTITY*/}
                        <div className="form-group">
                            <label className="control-label"> { translate('Программная единица') } </label>
                            <select className="form-control" value={entity} onChange={e => setEntity(e.target.value)}>
                                <option value=""> { translate('Не задано') } </option>
                                {/*<option value="role"> { translate('Роли') } </option>*/}
                                {/*<option value="bill"> { translate('Счета') } </option>*/}
                                {/*<option value="bonusSpend"> { translate('Списание бонусов') } </option>*/}
                                {/*<option value="branch"> { translate('Филиал') } </option>*/}
                                {/*<option value="Commodity"> { translate('Товар') } </option>*/}
                                {/*<option value="CommodityCategory"> { translate('Категория товара') } </option>*/}
                                {/*<option value="CommodityIncome"> { translate('Поступление товара') } </option>*/}
                                {/*<option value="Communication"> { translate('Комментарии') } </option>*/}
                                {/*<option value="Company"> { translate('Компания') } </option>*/}
                                <option value="crmFile"> { translate('CRM файлы') } </option>
                                {/*<option value="CrmTrigger"> { translate('CRM триггеры') } </option>*/}
                                <option value="customer"> { translate('Клиент') } </option>
                                {/*<option value="CustomerContract"> { translate('Договоры') } </option>*/}
                                <option value="customerGroup"> { translate('Участие в группе') } </option>
                                {/*<option value="CustomerPause"> { translate('Приостановка') } </option>*/}
                                {/*<option value="CustomerReject"> { translate('Причины отказов клиенов') } </option>*/}
                                {/*<option value="customerManager"> { translate('Ответственный') } </option>*/}
                                {/*<option value="Dashboard"> { translate('Элемент рабочего стола') } </option>*/}
                                {/*<option value="discount"> { translate('Скидки') } </option>*/}
                                {/*<option value="Duplicate"> { translate('Дубликаты') } </option>*/}
                                {/*<option value="FacebookMessage"> { translate('Facebook сообщение') } </option>*/}
                                {/*<option value="FieldVisibility"> { translate('Отображение полей') } </option>*/}
                                {/*<option value="Grade">Grade</option>*/}
                                <option value="group"> { translate('Группа') } </option>
                                {/*<option value="GroupMembershipStatus">GroupMembershipStatus</option>*/}
                                {/*<option value="groupStatus"> { translate('Статус группы') } </option>*/}
                                {/*<option value="Holiday"> { translate('Праздничные дни') } </option>*/}
                                {/*<option value="Import"> { translate('Импорт') } </option>*/}
                                {/*<option value="LeadForm"> { translate('Форма записи') } </option>*/}
                                {/*<option value="LeadReject"> { translate('Причина отказа') } </option>*/}
                                {/*<option value="LeadSource"> { translate('Источник') } </option>*/}
                                {/*<option value="LeadSourceCategory"> { translate('Группы источников') } </option>*/}
                                {/*<option value="LeadStatus"> { translate('Статусы клиентов') } </option>*/}
                                {/*<option value="LegalDetails"> { translate('Юридическая информация') } </option>*/}
                                <option value="lesson"> { translate('Урок/занятие') } </option>
                                {/*<option value="LessonDetails"> { translate('Детали уроков') } </option>*/}
                                {/*<option value="LessonReason"> { translate('Причина пропуска') } </option>*/}
                                {/*<option value="Knowledge"> { translate('Уровень знаний') } </option>*/}
                                {/*<option value="Location"> { translate('Локация') } </option>*/}
                                {/*<option value="MailMessage"> { translate('Mail сообщения') } </option>*/}
                                {/*<option value="MailTemplate">{ translate('Mail шаблоны') } </option>*/}
                                {/*<option value="Mailing"> { translate('Отправка Email') } </option>*/}
                                <option value="pay"> { translate('Платежи') } </option>
                                {/*<option value="payAccount"> { translate('Счет/Касса компании') } </option>*/}
                                {/*<option value="payDraft">PayDraft</option>*/}
                                {/*<option value="payItem"> { translate('Статья платежа') } </option>*/}
                                {/*<option value="payItemCategory"> { translate('Категория платежа') } </option>*/}
                                {/*<option value="periodClose"> { translate('Закрытый период') } </option>*/}
                                <option value="regularLesson"> { translate('Регулярный урок') } </option>
                                {/*<option value="regularLessonDisable"> { translate('Отключение участия в регулярных занятиях') } </option>*/}
                                {/*<option value="report"> { translate('Отчет') } </option>*/}
                                {/*<option value="room"> { translate('Аудитория') } </option>*/}
                                {/*<option value="SharedInstance"> { translate('Общие экземпляры') } </option>*/}
                                {/*<option value="qualification"> { translate('Квалификация') } </option>*/}
                                {/*<option value="SmsMessage"> { translate('SMS сообщение') } </option>*/}
                                {/*<option value="SmsTemplate"> { translate('SMS шаблоны') } </option>*/}
                                {/*<option value="Social">Social</option>*/}
                                {/*<option value="Streaming"> { translate('Онлайн стримы') } </option>*/}
                                {/*<option value="StudyStatus"> { translate('Статус клиента') } </option>*/}
                                {/*<option value="Subject"> { translate('Предмет') } </option>*/}
                                <option value="tariff"> { translate('Абонемент') } </option>
                                <option value="task"> { translate('Задача') } </option>
                                {/*<option value="TaskTemplate"> { translate('Шаблон задач') } </option>*/}
                                <option value="employee"> { translate('Учитель') } </option>
                                {/*<option value="TeacherRate"> { translate('Зарплатные ставки') } </option>*/}
                                {/*<option value="employeeWorkingHour"> { translate('График педагога') } </option>*/}
                                {/*<option value="TestResult"> { translate('Результаты тестов') } </option>*/}
                                <option value="user"> { translate('Пользователь') } </option>
                                {/*<option value="RecurringTask"> { translate('Повторяющаяся задача') } </option>*/}
                            </select>
                        </div>
                        {/*ID*/}
                        <div className="form-group">
                            <label className="control-label"> { translate('Идентификатор') } </label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={e => setId(e.target.value)}
                                value={id}
                            />
                        </div>
                        {/*PERIOD*/}
                        <div className="form-group">
                            <div className="clearfix">
                                <label> { translate('Период поиска') } </label>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('с') } </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_start}
                                            onChange={e => setDateStart(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('по') } </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_end}
                                            onChange={e => setDateEnd(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FIELD OLD*/}
                        <div className="form-group">
                            <div className="clearfix">
                                <label> { translate('Старые атрибуты') } </label>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Ключ') }
                                        value={oldKey}
                                        onChange={e => setOldKey(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <select className="form-control" value={oldOperation} onChange={e => setOldOperation(e.target.value)}>
                                        <option value=""> </option>
                                        <option value="="> = </option>
                                        <option value="<>"> != </option>
                                    </select>
                                </div>
                                <div className="col-sm-5">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Значение') }
                                        value={oldValue}
                                        onChange={e => setOldValue(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FIELD NEW*/}
                        <div className="form-group">
                            <div className="clearfix">
                                <label> { translate('Новые атрибуты') } </label>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Ключ') }
                                        value={newKey}
                                        onChange={e => setNewKey(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <select className="form-control" value={newOperation} onChange={e => setNewOperation(e.target.value)}>
                                        <option value=""> </option>
                                        <option value="="> = </option>
                                        <option value="<>"> != </option>
                                        <option value="*"> # </option>
                                        <option value="=="> == </option>
                                    </select>
                                </div>
                                <div className="col-sm-5">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Значение') }
                                        value={newValue}
                                        onChange={e => setNewValue(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*SUBMIT*/}
                        <button type="submit" className="btn btn-block btn-sm btn-primary">
                            <i className="fa fa-filter" /> { translate('Фильтр') }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}