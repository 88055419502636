import ManagerChosen from "../../../../Components/Input/ManagerChosen";
import InfoBlock from "./InfoBlock";
import shortid from "shortid";
import {openModal} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {findObject} from "../../../../Function/common";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export default function GeneralBlock ({ sqlCard, setSqlCard, auth }) {

    // CONTEXT START
    const { customerSetting, salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FUNCTION START
    function renderRefusal (refusal, is_lead, f_removed) {

        if (!customerSetting || !salesSetting || !f_removed) return <></>

        const refusal_obj = is_lead ? findObject(salesSetting.leadReject, sqlCard.refusal) : findObject(customerSetting.refusal, sqlCard.refusal);

        return (
            <div className="text-danger m-b-sm m-t-none font-bold">
                { translate('Отказ') }: { refusal_obj?.name }
            </div>
        )
    }

    function renderSwap (chat_id) {

        if (!chat_id) return

        return (
            <i
                className="ion-arrow-swap text-success m-l-xs m-r-xs"
                style={{ cursor : 'pointer' }}
                onClick={() => openModal('swap-chat')}
            />
        )

    }

    function renderStar () {

        async function doFavorite () {
            await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', { favorite : !sqlCard.favorite })
            window.location.reload();
        }

        if (sqlCard.favorite) {
            return <i className="fa fa-star" onClick={() => doFavorite()} style={{ color : "#dfdf00", cursor : 'pointer' }} />
        } else {
            return <i className="fa fa-star-o" onClick={() => doFavorite()} style={{ cursor : 'pointer' }} />
        }
    }
    // FUNCTION END

    return (
        <>
            { renderRefusal(sqlCard.refusal, sqlCard.is_lead, sqlCard.f_removed) }
            <div className={sqlCard.f_removed === 1 ? 'archive' : ''}>
                <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-xs-7 font-bold">
                        <h4 className="no-margins">
                            { sqlCard.name ? sqlCard.name : ( sqlCard.contact ? JSON.parse(sqlCard.contact).filter(item => item.type === 'mobile')[0]?.text : null ) }
                            { renderSwap(sqlCard.chat_id) }
                            { renderStar() }
                        </h4>
                    </div>
                    <ManagerChosen
                        data={sqlCard}
                        value={sqlCard.manager}
                        type_id="user_id"
                        topicSearch="name"
                    />
                </div>
                <InfoBlock
                    sqlCard={sqlCard}
                    setSqlCard={setSqlCard}
                    access={auth.role.interface === 1}
                />
                {
                    sqlCard.mainTags ? (
                        <div className="row" style={{ overflowWrap : 'break-word' }}>
                            <i className="fa fa-tags m-l-xs text-muted" />
                            { JSON.parse(sqlCard.mainTags).map(item => (
                                <span className="label m-l-xs" title={item} key={shortid.generate()}>
                                    { item }
                                </span>
                            )) }
                        </div>
                    ) : null
                }
            </div>
            <hr className="hr-line-dashed m-t-sm m-b-xs" />
        </>
    )
}