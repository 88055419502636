import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {filterDisable} from "../../../../Function/common";
import URL from "../../../../Api/URL.json";

export default function RequiredField () {

    // CONTEXT START
    const {customSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // VALUE START
    const means = [{"customer" : translate('Карточка клиента')}, {"group" : translate('Карточка группы')}, {"lead" : `Карточка лида`}, {"employee" : `${translate('Карточка педагога')}`}, {"tariff": `${translate('Абонементы')}`}, {"user" : `${translate('Модель пользователя')}`}, {"subject" : `${translate('Модель предмета')}`}, {"pay" : `${translate('Модель платежа')}`}, {"lesson" : `${translate('Модель урока')}`}, {"discount" : `${translate('Модель скидки')}`}]
    // VALUE END

    // COMP FUNC START
    async function disabledBaseField (data) {
        customSetting.requiredField = customSetting.requiredField.map(item => {
            if (item.id === data.id) {
                item.require = !data.require;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {customSetting: JSON.stringify(customSetting)})
        if (error) return console.log(error)
    }
    async function disabledCustomField (data) {
        customSetting.customField = customSetting.customField.map(item => {
            if (item.id === data.id) {
                item.require = !item.require;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {customSetting: JSON.stringify(customSetting)})
        if (error) return console.log(error)
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Обязательные поля')} <span className="text-muted">({customSetting ? customSetting.requiredField.length + filterDisable(customSetting.customField).length : 0})</span></h4>
            </div>
            <div className="modal-header p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-md">
                    {translate(
                        `Обязательные поля - активируйте обязательность заполнения полей
                    при добавлении/изменении карточки клиента.`
                    )}
                </p>
                {/*CONTENT*/}
                <div className="m-b-md">
                    {
                        customSetting ?
                            means.map(mean => {

                                let result_arr = [];
                                let bool = true;

                                customSetting.requiredField.map(field => {
                                    if (mean[field.entity] !== undefined) {
                                        bool = false;
                                        result_arr.push(
                                            <div className="border-bottom row sortableCustomer-item ui-sortable-handle">
                                                <div className="col-xs-9" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                    <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} />
                                                    <small style={field.require || field.require === null ? null : {opacity: 0.5}}> {translate(field.name)} <span className="text-muted">[{field.systemName}]</span></small>
                                                </div>
                                                <div className="col-xs-3 text-right" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                    {
                                                        field.require !== null ?
                                                            (
                                                                <a className="m-r-xs" onClick={() => disabledBaseField(field)}>
                                                                    <i className={`fa ${field.require ? "text-navy" : ""} fa-lg fa-toggle-${field.require ? "on" : "off"} fa-fw`} />
                                                                </a>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                                filterDisable(customSetting.customField).map(field => {
                                    if (mean[field.entity] !== undefined) {
                                        bool = false;
                                        result_arr.push(
                                            <div className="border-bottom row sortableCustomer-item ui-sortable-handle">
                                                <div className="col-xs-9" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                    <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} />
                                                    <small style={field.require ? null : {opacity: 0.5}}> {field.name} <span className="text-muted">[{field.systemName}]</span></small>
                                                </div>
                                                <div className="col-xs-3 text-right" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                    <a className="m-r-xs" onClick={() => disabledCustomField(field)}>
                                                        <i className={`fa ${field.require ? "text-navy" : ""} fa-lg fa-toggle-${field.require ? "on" : "off"} fa-fw`} />
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                                if (!bool) {
                                    return (
                                        <>
                                            <div className="border-bottom" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                                                <p className="text-navy font-bold no-padding no-margins"> ••• {Object.values(mean)[0]} </p>
                                            </div>
                                            <div className="sortableCustomer-list ui-sortable">
                                                {result_arr.map(item => item)}
                                            </div>
                                        </>
                                    )
                                }

                            })
                            : null
                    }
                </div>
            </div>
        </>
    )
}