import {memo, useContext, useEffect, useState} from "react";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import taskSuccess from "../../../../Assets/image/taskSuccess.svg";
import taskInProcess from "../../../../Assets/image/taskInProcess.svg";
import taskOverdue from "../../../../Assets/image/taskOverdue.svg";
import arrow from "../../../../Assets/image/arrow_down.svg";
import "../../../../Assets/css/TaskBlock.css";
import URL from "../../../../Api/URL.json";
import moment from "moment";
import {SettingContext} from "../../../../Context/SettingContext";
import EditAgile from "../../../Agile/Modal/EditAgile";
import {openModal} from "../../../../Function/common";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

export const TaskBlock = memo(({ sqlCard, managerData, customerData, groupData }) => {

    // CONTEXT START
    const { currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ tasks, setTasks ] = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ editTask, setEditTask ] = useState({});
    const [ deleteTaskId, setDeleteTaskId ] = useState(null);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!sqlCard.customer_id) return

        async function fetchData () {

            const task = await request(`${URL.back_end}/task_customer/${sqlCard.customer_id}`, 'GET');
            const user = await request(`${URL.back_end}/user_task`, 'GET');

            if (error) throw error

            setTasks(task.filter(item => item.f_removed === 0).sort( (a, b) => a.status - b.status ));
            setUsers(user);
        }

        fetchData().catch(e => console.log(e))

    }, [sqlCard])
    // INIT DATA END

    // HANDLER START
    async function doTask (item, status) {
        let result = document.getElementById(`task-result-${item.task_id}`).value;

        let date_done = null;

        if (status === 2) {
            date_done = currentTime.split('T')[0];
        }

        await request(`${URL.back_end}/task/${item.task_id}`, 'PUT', { status, result, date_done });

        let arr = tasks.map(task => {
            if (task.task_id === item.task_id) {
                task.status = status;
                task.result = result;
                task.date_done = date_done;
                return task;
            } else {
                return task;
            }
        })

        setTasks(arr);
    }

    async function handlerDateTime (obj, item) {
        await request(`${URL.back_end}/task/${item.task_id}`, 'PUT', obj);

        let arr = tasks.map(task => {
            if (task.task_id === item.task_id) {
                if (obj.date) {
                    task.date = obj.date;
                } else if (obj.time) {
                    task.time = obj.time;
                }
                return task;
            } else {
                return task;
            }
        })

        setTasks(arr);
    }

    function archiveHandler (task_id) {
        openModal('delete-task-overlay');
        openModal('delete-task-modal');
        setDeleteTaskId(task_id);
    }

    async function archiveTask () {
        await request(`${URL.back_end}/task/${deleteTaskId}`, 'PUT', { f_removed : 1 });

        const taskArray = tasks.filter(task => task.task_id !== deleteTaskId);

        setTasks(taskArray);
    }

    function showResult (e, item) {

        let task = document.getElementById(`task__result-${item.task_id}`)
        const arrow_down = document.getElementById(`task-arrow-${item.task_id}`)

        const handler = (event) => {
            if (!event.target.closest(`.task-note-${item.task_id}`)) {
                task.classList.add('hide');

                document.getElementsByClassName(`task-note-${item.task_id}`)[0].style.cursor = 'pointer';

                arrow_down.dataset.isActive = false;

                window.removeEventListener('click', handler, false);
            }
        }

        window.addEventListener('click', handler, false);

        document.getElementsByClassName(`task-note-${item.task_id}`)[0].style.cursor = 'default';

        arrow_down.dataset.isActive = true;

        task.classList.remove('hide')
    }
    // HANDLER END

    // RENDER FUNCTION START
    function getInfo (item) {
        if (item.status === 2) return { icon : taskSuccess, border : 'task-note-in-process' }

        const dateNow = moment().tz('Europe/Kiev').utcOffset(0).toDate();
        const dateTask = new Date(item.date);
        const [hours, minute] = item.time.split(':');
        dateTask.setHours(+hours);
        dateTask.setMinutes(+minute);

        if (dateNow < dateTask || !item.date) {
            return { icon : taskInProcess, border : 'task-note-in-process' }
        } else {
            return { icon : taskOverdue, border : 'task-note-overdue' }
        }
    }
    // RENDER FUNCTION END

    return (
        <>
            {
                tasks.map(item => (
                    <div className="row m-b-sm">
                        <div className="col-sm-12">
                            <div className={`task-note-wrapper task-note-${item.task_id}`} onClick={e => showResult(e, item)}>
                                <div className="task-note-fixer">
                                    <div className={`task-note ${ getInfo(item).border }`}>
                                        <div className="task-note__icon">
                                            <div className="task-note__icon-inner">
                                                <img
                                                    alt="icon"
                                                    src={ getInfo(item).icon }
                                                    style={{ width : '36px', height : '36px' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="task-note__content">
                                            <div className="task-note__header">
                                                <div className="task-note__header-inner">
                                                    <div className="task-note__header-inner-nowrap">
                                                        <div className="task-note__date">
                                                            { item.date ? <i className="ion-calendar" /> : null } { item.status === 1 ? item.date : item.date_done }
                                                            <i className={`ion-clock ${item.date ? 'm-l-xs' : null}`} /> { item.time }
                                                        </div>
                                                    </div>
                                                    <div className="task-note__header-info">
                                                        { translate('Создано') } : { managerData.filter(admin => admin.user_id === item.employer).map(item => item.name).join(', ') }.
                                                        &nbsp;{ translate('Исполнитель(и)') } : { users.filter(user => user.task_id === item.task_id).map(item => item.name).join(', ') }&nbsp;&nbsp;
                                                        <span className="arrow_down" data-is-active={false} id={`task-arrow-${item.task_id}`}>
                                                    <img
                                                        src={arrow}
                                                        style={{ verticalAlign : 'text-bottom' }}
                                                    />
                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-note__body">
                                                <div className="task-note__task-text">
                                                    <p className="task-note__body-text">
                                                        { item.text }
                                                        <small className="text-navy"> { item.result ? `( ${item.result} )` : null } </small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-task__result-wrapper hide" id={`task__result-${item.task_id}`}>
                                                <div className="card-task__result-wrapper__inner">
                                            <textarea
                                                className="form-control card-task__result-wrapper__inner__textarea"
                                                placeholder={ translate('Добавить результат') }
                                                defaultValue={ item.result }
                                                id={`task-result-${item.task_id}`}
                                            />
                                                    {
                                                        item.status === 1 ? (
                                                            <a className="btn btn-sm btn-success btn-outline m-l-xs" onClick={() => doTask(item, 2)}>
                                                                { translate('Выполнить') }
                                                            </a>
                                                        ) : (
                                                            <a className="btn btn-sm btn-info btn-outline m-l-xs" onClick={() => doTask(item, 1)}>
                                                                { translate('Вернуть') }
                                                            </a>
                                                        )
                                                    }
                                                </div>
                                                <div className="card-task__clone">
                                                    <span className="card-task__clone__dates">
                                                        <span className="card-task__clone__dates__preset">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id={`task-date-${item.task_id}`}
                                                                defaultValue={item.date}
                                                                onChange={e => handlerDateTime({ date : e.target.value }, item)}
                                                            />
                                                            <input
                                                                type="time"
                                                                className="form-control m-l-xs"
                                                                id={`task-time-${item.task_id}`}
                                                                style={{ width : '70%' }}
                                                                defaultValue={item.time}
                                                                onChange={e => handlerDateTime({ time : e.target.value }, item)}
                                                            />
                                                        </span>
                                                    </span>
                                                    <div className="card-task__buttons">
                                                        <a className="btn btn-sm btn-success btn-outline m-r-xs" onClick={() => { setEditTask(item); openModal('edit-agile') }}>
                                                            <i className="fa fa-pencil" />
                                                        </a>
                                                        <a className="btn btn-sm btn-danger btn-outline" onClick={() => archiveHandler(item.task_id)}>
                                                            <i className="fa fa-trash-o" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

            <EditAgile
                userData={managerData}
                customerData={customerData}
                groupData={groupData}
                editTask={editTask}
            />

            <DeleteModal
                id="delete-task"
                handler={archiveTask}
                close={true}
            />
        </>
    )
})