import { useTranslate } from "../../Hooks/translate.hook";
import { useHttp } from "../../Hooks/http.hook";
import { useContext, useEffect, useState } from "react";
import URL from "../../Api/URL.json";
import "../../Assets/css/Schedule.css";
import iconCalendar from "../../Assets/data/JSON/iconCalendar.json";
import { SettingContext } from "../../Context/SettingContext";
import { findObject } from "../../Function/common";
import {useNavigate} from "react-router-dom";

export default function EmployeeSchedule ({ employeeLesson }) {

    // CONTEXT START
    const { lessonSetting, currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [ lessons, setLessons ] = useState([]);
    // STATE END

    // VALUE START
    const dow = [translate('Воскресенье'), translate('Понедельник'), translate('Вторник'), translate('Среда'), translate('Четверг'), translate('Пятница'), translate('Суббота')];
    // VALUE END

    // INIT DATA START
    useEffect(() => {

        async function filterLessons () {
            setLessons(await filterLesson(employeeLesson))
        }

        filterLessons().catch(e => console.log(e))

    }, [employeeLesson])
    // INIT DATA END

    // FUNC START
    async function filterLesson (arr) {

        let temp_arr = [];

        for (let i = 0; i < 7; i++) {
            let temp_date = new Date(currentTime);
            temp_date.setDate(temp_date.getDate() + i);

            for (let j = 0; j < arr.length; j++) {
                if (temp_date.toDateString() === new Date(arr[j].date_start).toDateString()) {
                    arr[j].customers = await request(`${URL.back_end}/lesson_customer/${arr[j].lesson_id}`, 'GET');
                    arr[j].groups = await request(`${URL.back_end}/lesson_group/${arr[j].lesson_id}`, 'GET');
                    temp_arr.push(arr[j]);
                }
            }
        }

        return temp_arr;
    }

    function titleLesson (item) {

        let array = [];

        if (item.type_lesson === 1 || item.type_lesson === 3) {
            if (item.customers.length) {
                for (let i = 0; i < item.customers.length; i++) {
                    array.push(
                        <a className="m-r-xxs" href={`/company/1/customer/view?id=${item.customers[i].customer_id}`} target="_blank">
                            <i className={`${iconCalendar[item.type_lesson]} m-l-xs`} /> { item.customers[i].name }
                        </a>
                    )
                }
            } else {
                array.push( <> { findObject(lessonSetting.subject, item.subject)?.name } </> )
            }
        } else if (item.type_lesson === 2) {
            if (item.groups.length) {
                for (let i = 0; i < item.groups.length; i++) {
                    array.push(
                        <a className="m-r-xxs" href={`/company/1/group/view?id=${item.groups[i].group_id}`} target="_blank">
                            <i className={`${iconCalendar[item.type_lesson]} m-l-xs`} /> { item.groups[i].title }
                        </a>
                    )
                }
            } else {
                array.push( <> { findObject(lessonSetting.subject, item.subject)?.name } </> )
            }
        }

        return array;
    }

    function getDetail (item) {
        let { status } = item;
        let count = {
            navy : 0,
            warning : 0,
            danger : 0
        }

        item.customers.map(cust => {
            let { presence, debt, commission } = cust;

            if (!!presence && Number(commission) > 0 && status === 1 && !!debt === false) {
                count.navy += 1;
            } else if (!!presence === false && Number(commission) === 0 && status === 1) {
                count.warning += 1;
            } else if (!!presence === false && Number(commission) > 0 && status === 1 && !!debt === false) {
                count.danger += 1;
            } else if (!!presence && Number(commission) > 0 && status === 1 && !!debt === true) {
                count.danger += 1;
            } else if (!!presence === false && Number(commission) > 0 && status === 1 && !!debt === true) {
                count.danger += 1;
            }
        })

        if (count.navy > 0 || count.warning > 0 || count.danger > 0) {
            return (
                <>
                    (
                        { count.navy > 0 &&
                            <>
                                {count.navy}
                                {count.danger > 0 && <span className="text-muted" style={{ padding: "0 1px" }}>/</span>}
                            </>
                        }
                        {
                            count.danger > 0 &&
                                <>
                                    <span className="text-danger font-bold" title="Не оплачено">{count.danger}</span>
                                    { count.warning > 0 && <span className="text-muted" style={{ padding: "0 1px" }}>/</span> }
                                </>
                        }
                        {
                            count.warning > 0 &&
                                <>
                                    <span className="text-warning font-bold" title="Последнее оплаченное">{count.warning}</span>
                                </>
                        }
                    )
                </>
            )
        }
    }

    function calculateTime (item) {
        let [ hourStart, minuteStart ] = item.date_start.split('T')[1].slice(0, 5).split(':');
        let [ hourEnd, minuteEnd ] = item.date_end.split('T')[1].slice(0, 5).split(':');

        return ((Number(hourEnd) === 0 ? 24 : Number(hourEnd)) * 60 + Number(minuteEnd)) - (Number(hourStart) * 60 + Number(minuteStart));
    }

    function getStatus (item) {
        // eslint-disable-next-line default-case
        switch (item.status) {
            case 0 :
                if (item.regular_id !== null) return 'постоянный'
                else return 'разовый'
            case 1 : return 'проведен'
            case 2 : return 'отменен'
        }
    }

    function renderLesson (item) {

        function showTime (date_start, date_end) {
            let timeStart = date_start.split('T')[1].slice(0, 5);
            let timeEnd = date_end.split('T')[1].slice(0, 5);

            return `${timeStart} — ${timeEnd}`
        }

        return (
            <div className="small no-margins calendar-rec" style={{ padding: '2px' }}>
                <div>
                    { showTime(item.date_start, item.date_end) }
                    { titleLesson(item) } { getDetail(item) }
                    <span className="text-muted m-l-sm">
                        <i className="ion-clock" /> { calculateTime(item) } { translate('минут') }
                    </span>
                </div>
                <a className="text-primary lowercase">
                    <i className={ iconCalendar[getStatus(item)] } /> { translate(getStatus(item)) }
                </a>
            </div>
        )
    }
    // FUNC END

    return (
        <table className="table table-stripped table-condensed m-b-none border-bottom">
            <tbody>
                {
                    Array.from(new Set(lessons.map(item => new Date(item.date_start.split('T')[0]).toDateString())))
                        .map(item => (
                        <tr style={{ cursor : 'pointer' }}>
                            <td className="col-sm-2 text-muted">
                                <small>
                                    { dow[new Date(item).getDay()] }, <br />
                                    { new Date(item).toLocaleDateString() }
                                </small>
                            </td>
                            <td className="col-sm-10 lesson-data">
                                {
                                    lessons
                                        .sort( (a, b) => new Date(a.date_start) - new Date(b.date_start) )
                                        .map(les => new Date(les.date_start.split('T')[0]).toDateString() === new Date(item).toDateString() && renderLesson(les))
                                }
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}