import Menu from '../../../../Assets/data/JSON/menu.json';
import Power from '../../../../Assets/data/JSON/power.json';
import face from "../../../../Assets/data/JSON/interface.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import shortid from "shortid";

export default function RoleColumns (props) {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <tr className="w1">
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                />
            </td>
            <td className="w1">
                <center>
                    <big>
                        <strong>{props.data.rank}</strong>
                    </big>
                    <br />
                    «{props.data.name}»
                </center>
            </td>
            <td className="w1">
                <span className="text-muted">{translate('Интерфейс')}:</span> <br />
                {face[props.data.interface]} <br /> <br />
                <span className="text-muted">{translate('Предоставленные права')}:</span> <br />
                {
                    JSON.parse(props.data.power).map(item => (
                        <div key={shortid.generate()}>
                            <i className="ion-checkmark" />
                            {Power[item]}
                            <br />
                        </div>
                    ))
                }
            </td>
            <td className="w1">
                {
                    JSON.parse(props.data.menu).map(item => (
                       <div key={shortid.generate()}>
                           <small>
                               <i className={`${Menu[item].icon}`} /> {translate(Menu[item].name)}
                           </small>
                           <br />
                       </div>
                    ))
                }
            </td>
        </tr>
    )
}