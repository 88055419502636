// HOOK
import {Link} from "react-router-dom";
import {useTranslate} from "../../Hooks/translate.hook";

export default function Error (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return (
        <div className="middle-box text-center animated fadeInDown" style={{ height: `${window.innerHeight-1}px` }}>
            <h1 className="text-muted"> { translate('Упс!') } </h1>
            <div className="alert alert-danger m-t-md">
                <h3 className="font-noraml">
                    { translate('Запрашиваемая страница не найдена') }
                </h3>
                <div className="error-desc">
                    { props.text ? props.text : 'Перейдіть на головну сторінку' }
                </div>
                <div className="error-desc m-t-sm">
                    Not Found (#404)
                </div>
            </div>
            <Link to={props.link ? props.link : '/company/1/dashboard/index/'} className="btn btn-white btn-lg m-t-none">
                <i className="ion-ios-home-outline" /> { translate('Перейти на главную') }
            </Link>
        </div>
    )
}