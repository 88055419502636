import SideMenu from "../../Components/SideMenu/SideMenu";
import {Link} from "react-router-dom";
import '../../Assets/css/Setting.css';
import AddStatus from "./Modal/Client/Modal/AddStatus";
import AddKnowledge from "./Modal/Client/Modal/AddKnowledge";
import AddReason from "./Modal/Client/Modal/AddReason";
import AddSubject from "./Modal/Lesson/Modal/AddSubject";
import AddStream from "./Modal/Lesson/Modal/AddStream";
import AddGroupStatus from "./Modal/Lesson/Modal/AddGroupStatus";
import AddLocation from "./Modal/Lesson/Modal/AddLocation";
import AddRoom from "./Modal/Lesson/Modal/AddRoom";
import AddEmpQual from "./Modal/Employee/Modal/AddEmpQual";
import AddMark from "./Modal/Lesson/Modal/AddMark";
import {scrollTo} from "../../Function/ScrollTo";
import AddAccount from "./Modal/Pay/Modal/AddAccount";
import AddCategory from "./Modal/Pay/Modal/AddCategory";
import AddArticle from "./Modal/Pay/Modal/AddArticle";
import AddRefusal from "./Modal/Client/Modal/AddRefusal";
import AddCustomField from "./Modal/Custom/Modal/AddCustomField";
import {useTranslate} from "../../Hooks/translate.hook";
import AddBranch from "./Modal/BaseSettings/Modal/AddBranch";
import AddSourceGroup from "./Modal/Sales/Modal/AddSourceGroup";
import AddLeadSource from "./Modal/Sales/Modal/AddLeadSource";
import AddLeadStatus from "./Modal/Sales/Modal/AddLeadStatus";
import AddTelegram from "./Modal/Integration/Modal/AddTelegram";

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddTurbo from "./Modal/Integration/Modal/AddTurbo";
import AddLeadReject from "./Modal/Sales/Modal/AddLeadReject";
import AddLeadTags from "./Modal/Sales/Modal/AddLeadTags";
import AddTags from "./Modal/Sales/Modal/AddTags";

export default function Setting (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg">
                <div className="wrapper crm-content">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className="active font-normal">
                                        <Link to="/setting/1/default/index"> { translate('Настройки') } </Link>
                                    </li>
                                    {/*<li className="font-normal">*/}
                                    {/*    <Link to="/company/1/billing/index">{translate('Лицензирование')}</Link>*/}
                                    {/*</li>*/}
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body" style={{paddingTop: 0}}>
                                            <div className="settings-block">
                                                {/*SETTING MENU*/}
                                                <div className="settings-block__menu">
                                                    <div className="row m-b-md">
                                                        <div className="col-sm-12">
                                                            {/*BASE SETTING*/}
                                                            <h4 className="settings-block__menu-title">{translate('Базовые настройки')}</h4>
                                                            <ul className="settings-block__list nav">
                                                                <li className={props.menu === "base-options" ? "active" : ""}>
                                                                    <Link to="/settings/1/default/base-options">
                                                                        {translate('Основные настройки')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "branch" ? "active" : ""}>
                                                                    <Link to="/settings/1/branch/index">
                                                                        {translate('Филиалы')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "notification" ? "active" : ""}>
                                                                    <Link to="/settings/1/default/notification-options">
                                                                        {translate('Уведомления')}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                            {/*CLIENT*/}
                                                            <h4 className="settings-block__menu-title">{translate('Клиенты')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                <li className={props.menu === "study-status" ? "active" : ""}>
                                                                    <Link to="/settings/1/study-status/index">
                                                                        {translate('Статусы клиентов')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "knowledge-client" ? "active" : ""}>
                                                                    <Link to="/settings/1/level/index">
                                                                        {translate('Уровни знаний')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "reason-client" ? "active" : ""}>
                                                                    <Link to="/settings/1/reason/index">
                                                                        {translate('Причины пропуска уроков')}
                                                                    </Link>
                                                                </li>
                                                                {/*<li className={props.menu === "accountClient" ? "active" : ""}>*/}
                                                                {/*    <Link to="/settings/1/default/customer-options">*/}
                                                                {/*        {translate('Кабинет клиента')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                <li className={props.menu === "refusal-client" ? "active" : ""}>
                                                                    <Link to="/settings/1/customer-reject/index">
                                                                        {translate('Причины отказов')}
                                                                    </Link>
                                                                </li>
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/loyalty/index">*/}
                                                                {/*        {translate('Система лояльности')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                            </ul>
                                                            {/*SELLS*/}
                                                            <h4 className="settings-block__menu-title">{translate('Продажи')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                <li className={props.menu === "leadStatus" ? "active" : ""}>
                                                                    <Link to="/settings/1/lead-status/index">
                                                                        {translate('Этапы воронки продаж')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "sourceGroup" ? "active" : ""}>
                                                                    <Link to="/settings/1/lead-source-category/index">
                                                                        {translate('Группы источников')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "lead-source" ? "active" : ""}>
                                                                    <Link to="/settings/1/lead-source/index">
                                                                        {translate('Источники лидов')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "leadReject" ? "active" : ""}>
                                                                    <Link to="/settings/1/lead-reject/index">
                                                                        {translate('Причины отказов')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === 'leadTags' ? 'active' : ''}>
                                                                    <Link to="/settings/1/lead-tags/index">
                                                                        { translate('Теги статистики') }
                                                                    </Link>
                                                                </li>
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/lead-form/index">*/}
                                                                {/*        {translate('Виджеты захвата лидов с сайта')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/enroll/wizard">*/}
                                                                {/*        {translate('Виджет формы записи «визард»')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/online-schedule/wizard">*/}
                                                                {/*        {translate('Виджет «онлайн-расписание»')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                            </ul>
                                                            {/*LESSON*/}
                                                            <h4 className="settings-block__menu-title">{translate('Уроки')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                <li className={props.menu === "subject-lesson" ? "active" : ""}>
                                                                    <Link to="/settings/1/subject/index">
                                                                        {translate('Предметы')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "stream-lesson" ? "active" : ""}>
                                                                    <Link to="/settings/1/streaming/index">
                                                                        {translate('Онлайн стримы')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "group-status" ? "active" : ""}>
                                                                    <Link to="/settings/1/group-status/index">
                                                                        {translate('Статусы групп')}
                                                                    </Link>
                                                                </li>
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/group-membership-status/index">*/}
                                                                {/*        {translate('Статус участия в группе')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                <li className={props.menu === "lessonOption" ? "active" : ""}>
                                                                    <Link to="/settings/1/default/lesson-options">
                                                                        {translate('Настройка уроков')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "calendarOption" ? "active" : ""}>
                                                                    <Link to="/settings/1/default/calendar-options">
                                                                        {translate('Настройки календаря')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "room-lesson" ? "active" : ""}>
                                                                    <Link to="/settings/1/room/index">
                                                                        {translate('Локации - Аудитории')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "mark-lesson" ? "active" : ""}>
                                                                    <Link to="/settings/1/grade/index">
                                                                        {translate('Оценки')}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                            {/*EMPLOYEE*/}
                                                            <h4 className="settings-block__menu-title">{translate('Педагоги')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                <li className={props.menu === "emp-qual" ? "active" : ""}>
                                                                    <Link to="/settings/1/skill/index">
                                                                        {translate('Квалификации')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "emp-options" ? "active" : ""}>
                                                                    <Link to="/settings/1/default/teacher-options">
                                                                        {translate('Кабинет педагога')}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                            {/*INTEGRATION*/}
                                                            <h4 className="settings-block__menu-title">{translate('Интеграции')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/default/sms-options">*/}
                                                                {/*        SMS*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/default/sip-options">*/}
                                                                {/*        {translate('Телефония')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/default/acquiring-options">*/}
                                                                {/*        {translate('Интернет-эквайринг')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/default/fiscal-options">*/}
                                                                {/*        {translate('Онлайн касса')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                                <li className={props.menu === "facebook" ? "active" : ""}>
                                                                    <Link to="/settings/1/facebook/index">
                                                                        Facebook
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "telegram" ? "active" : ""}>
                                                                    <Link to="/settings/1/telegram/index">
                                                                        {translate('Чаты')}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                            {/*PAY*/}
                                                            <h4 className="settings-block__menu-title">{translate('Финансы')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                <li className={props.menu === "pay-account" ? "active" : ""}>
                                                                    <Link to="/settings/1/pay-account/index">
                                                                        {translate('Кассы и счета')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "pay-article" ? "active" : ""}>
                                                                    <Link to="/settings/1/pay-item/index">
                                                                        {translate('Статьи')}
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "pay-category" ? "active" : ""}>
                                                                    <Link to="/settings/1/pay-item-category/index">
                                                                        {translate('Категории статей')}
                                                                    </Link>
                                                                </li>
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/period-close/index">*/}
                                                                {/*        {translate('Закрытые периоды')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                            </ul>
                                                            {/*AUTO MOVE*/}
                                                            {/*<h4 className="settings-block__menu-title">{translate('Автодействия и рассылки')}</h4>*/}
                                                            {/*<ul className="settings-block__list nav" onClick={() => scrollTo(200)}>*/}
                                                            {/*    <li>*/}
                                                            {/*        <Link to="/settings/1/mailing/index">*/}
                                                            {/*            {translate('Автоматические рассылки')}*/}
                                                            {/*        </Link>*/}
                                                            {/*    </li>*/}
                                                            {/*    <li>*/}
                                                            {/*        <Link to="/settings/1/trigger/index">*/}
                                                            {/*            {translate('Триггеры и вебхуки')}*/}
                                                            {/*        </Link>*/}
                                                            {/*    </li>*/}
                                                            {/*    <li>*/}
                                                            {/*        <Link to="/settings/1/mail-template/layout">*/}
                                                            {/*            {translate('Настройка подложки email')}*/}
                                                            {/*        </Link>*/}
                                                            {/*    </li>*/}
                                                            {/*    <li>*/}
                                                            {/*        <Link to="/settings/1/sms-template/index">*/}
                                                            {/*            {translate('SMS шаблоны')}*/}
                                                            {/*        </Link>*/}
                                                            {/*    </li>*/}
                                                            {/*    <li>*/}
                                                            {/*        <Link to="/settings/1/mail-template/index">*/}
                                                            {/*            {translate('E-mail шаблоны')}*/}
                                                            {/*        </Link>*/}
                                                            {/*    </li>*/}
                                                            {/*    <li>*/}
                                                            {/*        <Link to="/settings/1/task-template/index">*/}
                                                            {/*            {translate('Шаблоны задач')}*/}
                                                            {/*        </Link>*/}
                                                            {/*    </li>*/}
                                                            {/*</ul>*/}
                                                            {/*CUSTOM*/}
                                                            <h4 className="settings-block__menu-title">{translate('Кастомизация')}</h4>
                                                            <ul className="settings-block__list nav" onClick={() => scrollTo(200)}>
                                                                <li className={props.menu === "custom-field" ? "active" : ""}>
                                                                    <Link to="/settings/1/custom-field/index">
                                                                        { translate('Дополнительные поля') }
                                                                    </Link>
                                                                </li>
                                                                <li className={props.menu === "requiredField" ? "active" : ""}>
                                                                    <Link to="/settings/1/required-field/index">
                                                                        { translate('Обязательные поля') }
                                                                    </Link>
                                                                </li>
                                                                {/*<li>*/}
                                                                {/*    <Link to="/settings/1/report/index">*/}
                                                                {/*        {translate('Печатные формы')}*/}
                                                                {/*    </Link>*/}
                                                                {/*</li>*/}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*SETTING CONTENT*/}
                                                <div className="settings-block__content m-t-md m-b-md">
                                                    { props.page ? props.page : null }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*BASE MODAL*/}
            <AddBranch />
            {/*CUSTOMER MODAL*/}
            <AddStatus />
            <AddKnowledge />
            <AddReason />
            <AddRefusal />
            {/*SALES*/}
            <AddLeadStatus />
            <AddSourceGroup />
            <AddLeadSource />
            <AddLeadReject />
            <AddLeadTags />
            <AddTags />
            {/*LESSON MODAL*/}
            <AddSubject />
            <AddStream />
            <AddGroupStatus />
            <AddLocation />
            <AddRoom />
            <AddMark />
            {/*EMPLOYEE*/}
            <AddEmpQual />
            {/*INTEGRATION*/}
            <AddTelegram />
            <AddTurbo />
            {/*PAY*/}
            <AddAccount />
            <AddCategory />
            <AddArticle />
            {/*CUSTOM*/}
            <AddCustomField />

        </div>
    )
}