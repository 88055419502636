import {useHttp} from "../../../../../Hooks/http.hook";
import {useEffect, useRef, useState} from "react";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal, openModal} from "../../../../../Function/common";
import {validate} from "../../../../../Function/validateForm";
import {useArchive} from "../../../../../Hooks/archive.hook";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";
import moment from "moment";

export default function EditGroup (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [group, setGroup] = useState({});
    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.editGroup).length) return

        setGroup({
            title: props.editGroup.title,
            group_id: props.editGroup.group_id
        })

        setDateStart(props.editGroup.date_start)
        setDateEnd(props.editGroup.date_end)
    }, [props.editGroup])
    // INIT DATA END

    // MODAL FUNC START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (dateEnd) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(dateStart))).asDays();

            const newDateEnd = moment(dateEnd).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function getDiapasonDate(start, end) {
        const date_end = new Date(end);
        date_end.setDate(date_end.getDate() + 1);

        for (var arr = [], dt = new Date(start); dt <= new Date(date_end); dt.setDate(dt.getDate()+1) ){
            arr.push(new Date(dt).toLocaleDateString());
        }

        return arr;
    }

    function deleteHandler () {
        openModal('delete-group-modal');
        openModal('delete-group-overlay');
    }

    async function buttonDelete () {
        let lessons = await request(`${URL.back_end}/group_lesson/${props.editGroup.group_id}`, 'GET');

        await request(`${URL.back_end}/customer_lesson/${props.sql.customer_id}`, 'DELETE', lessons.filter(item => item.edited === null && item.lesson_id).map(item => item.lesson_id))
        await request(`${URL.back_end}/group_customer/${props.editGroup.id}`, 'DELETE');
        await request(`${URL.back_end}/customer_regular_lesson`, 'DELETE', { customer_id : props.sql.customer_id, group_id : props.editGroup.group_id })
        await pushArchive(3, 'customerGroup', props.editGroup.id);

        if (error) return console.log(error);

        window.location.reload();
    }
    // MODAL FUNC END

    // EDIT GROUP START
    async function editGroup(e) {
        e.preventDefault();

        let validObj = {
            "Группа" : !Object.keys(group).length,
            "Период участия" : !dateStart || !dateEnd
        }

        if (validate(validObj, translate)) return

        e.currentTarget.disabled = true;

        await request(`${URL.back_end}/group_customer/${props.editGroup.id}`, 'PUT', { group_id: group.group_id, date_start: dateStart, date_end: dateEnd });

        let regularLesson = await request(`${URL.back_end}/filter_regular_lesson`, 'POST', { group_id : group.group_id });

        if (regularLesson.length) {
            for (let i = 0; i < regularLesson.length; i++) {
                let regular = await request(`${URL.back_end}/regular_lesson/${regularLesson[i].regular_id}`, 'GET');
                regular = regular.filter(item => item.edited !== 1);

                await request(`${URL.back_end}/customer_lesson/${props.sql.customer_id}`, 'DELETE', regular.map(item => item.lesson_id))

                regular = regular.filter(reg => getDiapasonDate(dateStart, dateEnd).includes(new Date(reg.date_start).toLocaleDateString()) && reg)

                for (let j = 0; j < regular.length; j++) {
                    await request(`${URL.back_end}/regular_lesson_customer/${regular[j].lesson_id}`, 'POST', { customer_id : props.sql.customer_id })
                }
            }
        }

        if (error) return console.log(error);

        await pushArchive(2, 'customerGroup', props.editGroup.id, { date_start: dateStart, date_end: dateEnd }, { date_start: props.editGroup.date_start, date_end: props.editGroup.date_end });

        window.location.reload();
    }
    // EDIT GROUP END

    return (
        <div id="edit-group" className="modal in" role="dialog" style={{ paddingRight: '16px' }} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title"> { translate('Участие в группе') } </h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => editGroup(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*NAME*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Клиент')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={props.sql.name}
                                    />
                                </div>
                            </div>
                            {/*GROUP*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Группа')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={group.title}
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Период участия')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        {/*DATE START*/}
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <span className="input-group-addon">{translate('с')}</span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={dateStart}
                                                    onChange={(event) => dateStartHandler(event)}
                                                />
                                            </div>
                                        </div>
                                        {/*DATE END*/}
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <span className="input-group-addon">{translate('по')}</span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    max={moment(dateStart).add(2, 'year').format('YYYY-MM-DD')}
                                                    min={moment(dateStart).add(1, 'day').format('YYYY-MM-DD')}
                                                    value={dateEnd}
                                                    onChange={(event) => setDateEnd(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-danger btn-outline" onClick={deleteHandler}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <DeleteModal
                id="delete-group"
                handler={buttonDelete}
            />
        </div>
    )
}