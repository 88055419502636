// HOOK
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useArchive} from "../../../../../Hooks/archive.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";

// JSON
import URL from '../../../../../Api/URL.json';

// CONTEXT
import {SettingContext} from "../../../../../Context/SettingContext";

// FUNCTION
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {findObject} from "../../../../../Function/common";
import {closeModal} from "../../../../../Function/common";
import {errorNotify, success} from "../../../../../Function/Notify";
import {validate} from "../../../../../Function/validateForm";

export default function EditQualification (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customerSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ subject, setSubject ] = useState({});
    const [ knowledge, setKnowledge ] = useState({});
    const [ note, setNote ] = useState(null);
    // STATE END

    // GET DOM START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    const sweet_text = document.getElementById("sweet-text");
    // GET DOM END

    // INIT DATE START
    useEffect(() => {
        if (!Object.keys(props.editQualifiaction).length) return

        setSubject(lessonSetting ? findObject(lessonSetting.subject, props.editQualifiaction.subject) ? findObject(lessonSetting.subject, props.editQualifiaction.subject) : {} : {});
        setKnowledge(customerSetting ? findObject(customerSetting.knowledge, props.editQualifiaction.knowledge) ? findObject(customerSetting.knowledge, props.editQualifiaction.knowledge) : {} : {});
        setNote(props.editQualifiaction.note);
    }, [props.editQualifiaction])
    // INIT DATE END

    // MODAL FUNC START
    function deleteQualification () {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";
        sweet_text.innerText = `${translate('Вы уверены, что хотите удалить запись?')}`;

        confirm_button.addEventListener('click', async function () {
            let arr_qualification = JSON.parse(props.sql.qualification);

            arr_qualification = arr_qualification.filter(item => {
                if (item.id !== props.editQualifiaction.id) {
                    return item;
                }
            })

            // INDEXING
            arr_qualification = arr_qualification.map((item,index) => {
                item.id = index;
                return item;
            })

            let data = { qualification: arr_qualification.length > 0 ? JSON.stringify(arr_qualification) : null }
            await request(`${URL.back_end}/customer/${props.sql.customer_id}`, 'PUT', data);
            if(error) return error;
            await pushArchive(3, "Knowledge", props.sql.customer_id);

            for (let key in props.sql) {
                if (key !== "qualification") {
                    data[key] = props.sql[key];
                }
            }

            props.updateSql(data);

            success(`Запись успешно удалена`)

            modal.current.style.display = "none";
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";
        })
    }
    // MODAL FUNC END

    // EDIT QUALIFICATION START
    async function editQualification (e) {
        e.preventDefault();

        let validObj = {
            "Предмет" : !Object.keys(subject).length,
            "Уровень" : !Object.keys(knowledge).length
        }

        if (validate(validObj, translate)) return

        e.currentTarget.disabled = true;

            // PROCESS

        let arr_qualification = JSON.parse(props.sql.qualification);

        let qualification = {
            id: props.editQualifiaction.id,
            subject: subject.id, knowledge: knowledge.id, note
        }

        arr_qualification = arr_qualification.map(item => {
            if (item.id === props.editQualifiaction.id) {
                return qualification;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/customer/${props.sql.customer_id}`, 'PUT', {qualification: JSON.stringify(arr_qualification)});
        if(error) return console.log(error);
        await pushArchive(2, "Knowledge", props.sql.customer_id, qualification, props.editQualifiaction);

        let update_customer = { qualification: JSON.stringify(arr_qualification) }

        for(let key in props.sql) {
            if (key !== "qualification") {
                update_customer[key] = props.sql[key];
            }
        }

        props.updateSql(update_customer);

        success(`Изменения сохранены`)

        modal.current.style.display = "none";
    }
    // EDIT QUALIFICATION END

    return (
        <div id="edit-qualification" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">{translate('Править квалификацию')}</h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => editQualification(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предмет')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? lessonSetting.subject.filter(item => item.disabled !== true) : []}
                                        type_id="id"
                                        topicSearch="name"
                                        setProps={setSubject}
                                        value={subject.name}
                                    />
                                </div>
                            </div>
                            {/*KNOWLEDGE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Уровень')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={customerSetting ? customerSetting.knowledge.filter(item => item.disabled !== true) : []}
                                        type_id="id"
                                        topicSearch="name"
                                        setProps={setKnowledge}
                                        value={knowledge.name}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Примечание')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Любое текстовое примечание')}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-sm btn-danger btn-outline" title={translate('Удалить')} onClick={deleteQualification}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}