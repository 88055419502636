import {openModal} from "../../../../Function/common";
import {useContext, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useHttp} from "../../../../Hooks/http.hook";
import {success} from "../../../../Function/Notify";
import EditTelegram from "./Modal/EditTelegram";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

export default function Telegram () {

    // CONTEXT START
    const { integration, sms } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ editTelegram, setEditTelegram ] = useState({});
    const [ deleteTelegram, setDeleteTelegram ] = useState(null);
    // STATE END

    // COMP FUNC START
    function deleteHandler (id) {
        setDeleteTelegram(id);
        openModal('delete-telegram-overlay');
        openModal('delete-telegram-modal');
    }

    async function deleteTelegramHandler () {
        integration.telegram = integration.telegram.filter(item => item.id !== deleteTelegram)

        await request(`${URL.back_end}/setting`, 'PUT', { integration: JSON.stringify(integration) })

        window.location.reload();
    }

    async function deleteTurbo () {
        sms.turbo = {};

        await request(`${URL.back_end}/setting`, 'PUT', { sms: JSON.stringify(sms) })

        if (error) return console.log(error);

        success(`Успешно удалено`)
    }
    // COMP FUNC END

    return (
        <>
            {/*TELEGRAM*/}
            <div className="modal-header">
                <h4 className="modal-title"> { translate('Чаты') } </h4>
            </div>
            <form className="form-horizontal telegram-form">
                <div className="modal-body p-md" style={{paddingBottom: "5px"}}>
                    <div className="row m-b-md">
                        <div className="col-sm-12">
                            <p className="p-xs bg-warning">
                                { translate(`Интеграция с чат-ботом позволяет отправлять в мессенджер сообщения клиентам.`) }
                            </p>
                            {
                                integration?.telegram.group_id === undefined && (
                                    <p className="p-xs bg-warning">
                                        { translate(`Чтобы включить уводомление сделайте телеграм бота администратором группы и введите команду '/connectCrm'`) }
                                    </p>
                                )
                            }
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <a className="btn btn-sm btn-block btn-success" onClick={() => openModal('add-telegram')}>
                                <i className="ion-plus" /> { translate('Добавить') }
                            </a>
                        </div>
                    </div>
                    {
                        integration ? integration.telegram.length ?
                            integration.telegram.map(item => (
                                <>
                                    <div className="border-bottom" />
                                    <div className="row p-xs">
                                        <div className="col-sm-8">
                                            { item.name } <span className="text-muted small"> [ID { item.id }] </span>
                                        </div>
                                        <div className="text-right col-sm-4">
                                            <a className="btn btn-xs btn-white m-r-xxs" onClick={() => { setEditTelegram(item); openModal('edit-telegram'); }}>
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item.id)}>
                                                <i className="fa fa-trash-o text-danger fa-fw" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="border-bottom" />
                                </>
                            ))
                            : null : null
                    }
                </div>
            </form>

            {/*TURBO SMS*/}
            <div className="modal-header m-t-sm">
                <h4 className="modal-title"> TurboSms </h4>
            </div>
            <form className="form-horizontal telegram-form">
                <div className="modal-body p-md" style={{paddingBottom: "5px"}}>
                    <div className="row m-b-md">
                        <div className="col-sm-12">
                            <p className="p-xs bg-warning">
                                <a href="https://turbosms.ua/api.html" target="_blank">
                                    { translate(`Инструкция для подключения`) }
                                </a>
                            </p>
                        </div>
                        <div className="col-sm-6 col-md-3" style={sms ? Object.keys(sms.turbo).length > 0 ? {display: 'none'} : {} : {}}>
                            <a className="btn btn-sm btn-block btn-success" onClick={() => openModal("add-turbo")}>
                                <i className="ion-plus" /> { translate('Добавить') }
                            </a>
                        </div>
                    </div>
                    {
                        sms ? Object.keys(sms.turbo).length > 0 ?
                            (
                                <>
                                    <div className="border-bottom" />
                                    <div className="row p-xs">
                                        <div className="col-sm-8">
                                            { sms.turbo.sender }
                                        </div>
                                        <div className="text-right col-sm-4">
                                            <a className="btn btn-xs btn-white m-r-xxs">
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={deleteTurbo}>
                                                <i className="fa fa-trash-o text-danger fa-fw" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="border-bottom" />
                                </>
                            )
                            : null : null
                    }
                </div>
            </form>
            <EditTelegram
                editTelegram={editTelegram}
            />
            <DeleteModal
                id="delete-telegram"
                handler={deleteTelegramHandler}
            />
        </>
    )
}