import {useTranslate} from "../../../../Hooks/translate.hook";

export default function ScheduleRow (props) {

    // HOOK START
    const {translate} = useTranslate()
    // HOOK END

    function switchDowReverse (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 1 :
                return `${translate('Пн')}`;
            case 2:
                return `${translate('Вт')}`;
            case 3:
                return `${translate('Ср')}`;
            case 4:
                return `${translate('Чт')}`;
            case 5:
                return `${translate('Пт')}`;
            case 6:
                return `${translate('Сб')}`;
            case 0:
                return `${translate('Вс')}`;
        }
    }

    return (
        <div className="row">
            {/*DAY*/}
            <div className="col-xs-2 text-center">
                <big className="text-uppercase font-bold big text-success"> {switchDowReverse(props.day)} </big>
            </div>
            {/*TIME*/}
            <div className="col-xs-10"><small> {props.time.map((item, index) => `${item}${props.time.length !== index+1 ? "," : ""} `)} </small></div>
        </div>
    )
}