import Gender from "../../../Components/Input/Gender";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import {useArchive} from "../../../Hooks/archive.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, closeModal, filterDisable, findObject} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import Contact from "../../../Components/Input/Contact";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {validate} from "../../../Function/validateForm";

export default function EditEmployee (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // CONTEXT START
    const { customSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // STATE START
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [contact, setContact] = useState([{}]);
    const [group, setGroup] = useState([]);
    const [status, setStatus] = useState('');
    const [note, setNote] = useState('');
    const [comment, setComment] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.employeeEdit).length) return

        setName(props.employeeEdit.name);
        setAge(props.employeeEdit.age);
        setGender(props.employeeEdit.gender);
        setContact(props.employeeEdit.contact ? JSON.parse(props.employeeEdit.contact) : [{}]);
        setStatus(props.employeeEdit.status);
        setNote(props.employeeEdit.note);
        setComment(props.employeeEdit.comment);
        setSubjects(props.employeeEdit.subjects ? JSON.parse(props.employeeEdit.subjects).map(item => findObject(filterDisable(lessonSetting.subject), +item)) : []);

        async function fetchData () {
            const groups = await request(`${URL.back_end}/employee_group/${props.employeeEdit.employee_id}`, 'GET');

            if (error) throw error;

            setGroup(groups);
        }

        fetchData().catch(e => console.log(e))

        filterDisableEntity(customSetting ? customSetting.customField : [], "employee").map(item => {
            document.forms["edit_employee"].elements["emp-edit-"+item.systemName].value = props.employeeEdit[item.systemName];
        })

    }, [props.employeeEdit])
    // INIT DATA END

    // EDIT EMPLOYEE START
    async function editEmployee (e) {
        e.preventDefault();

        const teacherNote = document.getElementById('edit_teacher_note').innerText;
        const teacherComment = document.getElementById('edit_teacher_comment').innerText;

        let validObj = { "ФИО" : !name.length }

        filterDisableEntity(customSetting.customField, "employee").map(item => {
            let value = document.forms["edit_employee"].elements["emp-edit-"+item.systemName].value;
            validObj[item.name] = item.require ? value !== "[]" ? !value.length : true : null
        })

        if (validate(validObj, translate)) return

        let employee = {
            name,
            age,
            gender,
            note: teacherNote,
            status,
            comment: teacherComment,
            contact: contact[0].type ? JSON.stringify(contact) : null,
            subjects: subjects.length ? JSON.stringify(subjects.map(item => item.id)) : null,
            f_removed: props.employeeEdit.f_removed
        }

        filterDisableEntity(customSetting.customField, "employee").map(item => {
            let value = document.forms["edit_employee"].elements["emp-edit-"+item.systemName].value;
            employee[item.systemName] = value.length ? value !== "[]" ? value : null : null;
        })

        await request(`${URL.back_end}/employee/${props.employeeEdit.employee_id}`, 'PUT', employee);
        await request(`${URL.back_end}/employee_group/${props.employeeEdit.employee_id}`, 'PUT', group.map(item => item.group_id));

        if (error) return console.log(error)

        await pushArchive(2, 'employee', props.employeeEdit.employee_id, { ...employee, employee_id : props.employeeEdit.employee_id }, props.employeeEdit);

        employee['employee_id'] = props.employeeEdit.employee_id;

        window.location.reload();
    }
    // EDIT EMPLOYEE END

    return (
        <div id="edit-employee" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title"> { translate('Редактировать педагога') } </h4>
                    </div>
                    <form className="form-horizontal" name="edit_employee" onSubmit={e => editEmployee(e)}>
                        {/*BODY*/}
                        <div className="modal-body" style={{ paddingBottom: 0 }}>
                            {/*NAME*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="teacher-name">{translate('ФИО')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="teacher-name"
                                        className="form-control"
                                        name="name"
                                        placeholder={translate('Например, Иванов Иван')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* BIRTHDAY + AGE */}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Дата рождения') }
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="date"
                                        className="form-control dob-input"
                                        value={age}
                                        onChange={e => setAge(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Gender
                                        setProps={setGender}
                                        value={gender}
                                    />
                                    <label className="font-noraml pull-right m-t-xs m-r-sm">{translate('Пол')}</label>
                                </div>
                            </div>
                            {/*CONTACT*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Контакты') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <Contact
                                        setContact={setContact}
                                        contact={contact}
                                    />
                                </div>
                            </div>
                            {/*SUBJECT*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предметы')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseMultiObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.subject): []}
                                        setProps={setSubjects}
                                        value={subjects}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*GROUP*/}
                            {
                                props.access ? (
                                    <div className="form-group">
                                        <div className="col-sm-3">
                                            <label className="control-label"> { translate('Группы') } </label>
                                        </div>
                                        <div className="col-sm-9">
                                            <InputChooseMultiObject
                                                sql={props.groupsData}
                                                setProps={setGroup}
                                                value={group}
                                                topicSearch="title"
                                                type_id="group_id"
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }
                            {/*STATUS*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Статус') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={status}
                                        onChange={e => setStatus(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Примечание') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <span
                                        id="edit_teacher_note"
                                        className="form-control textarea"
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                    >
                                        { note }
                                    </span>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Комментарий') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <span
                                        id="edit_teacher_comment"
                                        className="form-control textarea"
                                        role="textbox"
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                    >
                                        { comment }
                                    </span>
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "employee").map(item => (
                                        <div className={`form-group ${item.require && "required"}`}>
                                            <div className="col-sm-3">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-9">
                                                {typeField(item, "emp-edit-")}
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}