import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import moment from "moment";
import {SettingContext} from "../../../Context/SettingContext";
import {closeModal} from "../../../Function/common";
import operation from "../../../Assets/data/JSON/operation.json";
import iconOperation from "../../../Assets/data/JSON/iconOperation.json";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {validate} from "../../../Function/validateForm";
import URL from "../../../Api/URL.json";

export default function AddPayoutSalary ({ type_operation, employeeData }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [c_date, setDate] = useState(moment(currentTime).format('YYYY-MM-DD'));
    const [employee, setEmployee] = useState({});
    const [amount, setAmount] = useState(0);
    const [payer, setPayer] = useState('');
    const [note, setNote] = useState('');
    // STATE END

    // START ADD PAYOUT SALARY
    async function addPayoutSalary (e) {

        e.preventDefault();

        let validObj = {
            "Дата" : !c_date.length,
            "Педагог" : !Object.keys(employee).length,
            "Сумма" : !(/^[0-9]+(\.[0-9]+)?$/).test(amount)
        }

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        const payoutSalary = {
            type_operation : +type_operation,
            c_date : moment(c_date).format('YYYY-MM-DD'),
            employee_id : Object.keys(employee).length ? employee.employee_id : null,
            amount : Number(amount),
            payer, note
        }

        await request(`${URL.back_end}/payout_salary`, 'POST', payoutSalary);

        window.location.reload();
    }
    // END ADD PAYOUT SALARY

    return (
        <div id="add-payout-salary" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span>×</span>
                        </button>
                        <h4 className="modal-title">
                            { translate('Добавить') } <span className="text-lowercase"> { translate(operation[type_operation]) } </span>
                        </h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addPayoutSalary(e)}>
                        <div className="modal-body">
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Тип и дата') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-control" style={{ cursor: "not-allowed" }}>
                                                <i className={`${iconOperation[type_operation]} text-muted`} /> { translate(operation[type_operation]) }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={c_date}
                                                onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Педагог') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={employeeData}
                                        setProps={setEmployee}
                                        value={employee.name}
                                        topicSearch="name"
                                        type_id="employee_id"
                                    />
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="font-noraml control-label">
                                        { translate('Сумма') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 1000`}
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Плательщик') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, ФИО или наименование компании')}
                                        value={payer}
                                        onChange={e => setPayer(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Комментарий') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Оплата за обучение')}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}