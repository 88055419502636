import SideMenu from "../../Components/SideMenu/SideMenu";
import TabAction from "../../Components/TabAction";
import {useTranslate} from "../../Hooks/translate.hook";

export default function Informer () {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return (
        <div id='wrapper'>
            {/* SIDE MENU */}
            <SideMenu page='informer' />
            {/* CONTENT */}
            <div id='page-wrapper' className='gray-bg'>
                <div className='wrapper crm-content'>
                    {/* ACTIONS */}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    title='Добавить'
                                    handler={() => {}}
                                    icon='ion-plus'
                                    text='Добавить'
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    disable={false}
                                    title={translate('Изменить')}
                                    handler={() => {}}
                                    icon='ion-edit'
                                    text='Править'
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-danger"
                                    icon="fa fa-trash-o"
                                    text="Удалить"
                                    handler={() => {}}
                                    disable={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}