import {Link} from "react-router-dom";
import URL from '../../../Api/URL.json';
import {useHttp} from "../../../Hooks/http.hook";
import {useArchive} from "../../../Hooks/archive.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useContext,} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, findObject} from "../../../Function/common";
import shortid from "shortid";

export default function EmployeeColumns (props) {

    // CONTEXT START
    const { lessonSetting, customSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    const sweet_text = document.getElementById("sweet-text");

    const sweet2_overlay = document.getElementById("sweet2-overlay");
    const sweet2_alert = document.getElementById("sweet2-alert");
    const confirm2_button = document.getElementById("sweet2-confirm");
    const sweet2_text = document.getElementById("sweet2-text");
    // SWEET MODAL END

    // SELECT FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }
    // SELECT FUNC END

    // BUTTON FUNC START
    function buttonArchive (value) {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            // REQUEST
            await request(`${URL.back_end}/employee/${props.data.employee_id}`, 'PUT', { f_removed : value });

            if (error) return console.log(error);

            if (value === 0) {
                await pushArchive(4, 'employee', props.data.employee_id, { f_removed : 1 }, { f_removed : 0 })
            } else {
                await pushArchive(3, 'employee', props.data.employee_id, { f_removed : 0 }, { f_removed : 1 })
            }

            props.sql.forEach(function (item, i) {
                if (props.data.employee_id === item.employee_id) {
                    props.sql[i].f_removed = value;
                    props.updateSql([...props.sql]);
                }
            })
        })
    }

    function buttonDelete () {
        sweet2_alert.style.display = "block";
        sweet2_overlay.style.display = "block";
        sweet2_text.innerHTML =
          `Ви дійсно хочете видалити викладача? <br>
          <b><span style='color: red;'>Важливо!</span> Дані про коментарі, ставки, відповідальність, уроки в яких присутній викладач буде назавжди видалено.</b>`;

        confirm2_button.addEventListener('click', async function () {
            sweet2_alert.style.display = "none";
            sweet2_overlay.style.display = "none";

            await request(`${URL.back_end}/employee/${props.data.employee_id}`, 'DELETE');

            props.updateSql(props.sql.filter(item => item.employee_id !== props.data.employee_id));
        });
    }
    // BUTTON FUNC END

    return (
        <tr className={`w1 ${props.data.f_removed !== 0 && "danger"}`} style={{whiteSpace: "pre-wrap"}}>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={props.data.employee_id}
                    onClick={getSelect}
                />
            </td>
            <td>
                <Link to={`/company/1/employee/view?id=${props.data.employee_id}`} title={`Перейти на карточку ${props.data.name}`}>
                    <i className="ion-university m-r-xs" />
                    {props.data.f_removed === 1 ? <strike>{props.data.name}</strike> : props.data.name}
                </Link>
            </td>
            <td>
                {
                    props.data.subjects ?
                            JSON.parse(props.data.subjects).map(item => findObject(lessonSetting.subject, +item).name).join('\n')
                        : null
                }
            </td>
            <td>
                {
                    props.groupsData.filter(employee => employee.employee_id === props.data.employee_id).map(item => (
                        <>
                            <Link to={`/company/1/group/view?id=${item.group_id}`}>
                                { item.title }
                            </Link>
                            <br />
                        </>
                    ))
                }
            </td>
            <td>
               <span style={{fontSize : "11px"}}>
                   {
                       props.data.contact ?
                           JSON.parse(props.data.contact).map(item => (
                               <>
                                   <i className={`fa fa-${item.type} fa-fw`} />
                                   &nbsp;{item.text} {item.note && `(${item.note})`}
                                   <br />
                               </>
                           ))
                           : null
                   }
               </span>
            </td>
            <td className="text-navy">
                { props.data.status }
            </td>
            <td>
                { props.data.comment }
            </td>
            {/*<td>*/}
            {/*    { props.data.note }*/}
            {/*</td>*/}
            {
                customSetting ?
                    filterDisableEntity(customSetting.customField, "employee").map(item => (
                        <td key={shortid.generate()}>
                            {props.data[item.systemName] ? JSON.parse(props.data[item.systemName])[0] !== undefined ? JSON.parse(props.data[item.systemName]).join(", ") : props.data[item.systemName] : null}
                        </td>
                    ))
                    : null
            }
            <td>
                {
                    props.data.f_removed === 0 ?
                      <a className="pull-right" title={translate('В архив')} onClick={() => buttonArchive(1)}>
                          <i className="fa fa-lg fa-trash-o text-danger" />
                      </a> :
                      <>
                          <a className="pull-right m-l-sm" title={translate('Удалить')} onClick={() => buttonDelete()}>
                              <i className="fa fa-lg fa-trash-o text-danger" />
                          </a>
                          <a className="pull-right" title={translate('Из архива')} onClick={() => buttonArchive(0)}>
                              <i className="fa fa-reply text-navy" />
                          </a>
                      </>
                }
            </td>
        </tr>
    )
}