import React, {useContext, useState} from "react";
import {openModal} from "../../../../Function/common";
import {TariffRow} from "../Row/TariffRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from '../../../../Api/URL.json';
import { AddTariffCustomer } from "../Modal/Tariff/AddTariffCustomer";
import { EditTariffCustomer } from "../Modal/Tariff/EditTariffCustomer";
import {SettingContext} from "../../../../Context/SettingContext";
import currency from "../../../../Assets/data/JSON/currency.json";
import AddTariff from "../../../Tariff/Modal/AddTariff";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import {Collapse} from "react-collapse/lib/Collapse";
import ArrowDown from "../../../../Assets/image/arrow_down.svg";

export const TariffBlock = React.memo(function MemoTariffBlock ({ customerTariff, sqlCard, access }) {

    // HOOK START
    const { translate } = useTranslate();
    const { basicSetting } = useContext(SettingContext);
    const { request } = useHttp();
    // HOOK END

    // VALUE START
    const customerActiveTariff = customerTariff.filter((item) => item.f_removed === 0);
    const customerArchiveTariff = customerTariff.filter((item) => item.f_removed === 1);
    // VALUE END

    // STATE START
    const [ tariffData, setTariffData ] = useState([]);
    const [ editTariff, setEditTariff ] = useState({});
    const [ openCollapse, setOpenCollapse ] = useState(false);
    // STATE END

    if (!access) return

    // FUNCTION START
    async function addTariff () {
        if (tariffData.length > 0) return openModal("add-tariff-customer")

        const tariffs = await request(`${URL.back_end}/tariff`, 'GET');

        setTariffData([...tariffs]);

        openModal("add-tariff-customer")
    }

    async function recountBalance () {
        await request(`${URL.back_end}/recount_balance/${sqlCard.customer_id}`, 'POST');
        window.location.reload();
    }

    function handlerRecountBalance () {
        openModal('recount-balance-modal');
        openModal('recount-balance-overlay');
    }
    // FUNCTION END

    return (
        <>
            <hr className="hr-line-dashed m-t-sm m-b-sm" />
            {/*HEADER*/}
            <h4 className="no-padding m-b-xs m-t-xs">
                <a className="crm-dashed-link m-l-xl pull-right" onClick={addTariff}>
                    <span className="text-lowercase font-noraml">{ translate('Добавить') }</span>
                </a>
                <span className="text-muted">{ translate('Счета и абонементы') }</span>
                <a className="m-l-sm" title={translate('Пересчет проведенных уроков в соответствии с абонементами')}>
                    <i
                        className="fa fa-bolt text-navy"
                        style={{ cursor: 'pointer' }}
                        onClick={handlerRecountBalance}
                    />
                </a>
            </h4>
            {/*BODY*/}
            <div className="row">
                <div className="col-xs-12" style={{padding: "7px 5px"}}>
                    <div className="text-success font-bold">
                        <i className="ion-pricetag m-r-xs" /> { translate('Базовый счет') }
                    </div>
                    <div className="text-muted m-l-md">
                        { translate('Счет клиента по умолчанию') }
                    </div>
                    <div className="m-l-md font-bold">
                        { translate('Остаток по счету') } : <span className={sqlCard.balance > 0 ? 'text-navy' : 'text-danger'}> { sqlCard.balance ? sqlCard.balance : 0 } { basicSetting && currency[basicSetting.baseOption.currency] } </span>
                    </div>
                </div>
            </div>
            {
                customerActiveTariff.map((item) => (
                    <TariffRow
                        data={item}
                        tariffData={tariffData}
                        setEditTariff={setEditTariff}
                        setTariffData={setTariffData}
                    />
                ))
            }

            {
                customerArchiveTariff.length ? (
                    <>
                        <hr className="hr-line-dashed m-t-sm m-b-sm" />

                        <h4 className="no-padding m-b-xs m-t-xs flex c-pointer" onClick={() => setOpenCollapse(!openCollapse)}>
                            <span className="text-muted flex-1">Архівні абонементи</span>
                            <div className="arrow_down" data-is-active={openCollapse}>
                                <img src={ArrowDown} />
                            </div>
                        </h4>

                        <Collapse isOpened={openCollapse}>
                            {
                                customerArchiveTariff.map((item) => (
                                    <TariffRow
                                        archive={true}
                                        data={item}
                                        tariffData={tariffData}
                                        setEditTariff={setEditTariff}
                                        setTariffData={setTariffData}
                                    />
                                ))
                            }
                        </Collapse>
                    </>
                ) : null
            }

            {/*TARIFF*/}
            <AddTariffCustomer
                tariffData={tariffData}
                customer_id={sqlCard.customer_id}
            />

            <EditTariffCustomer
                tariffData={tariffData}
                editTariff={editTariff}
            />

            <AddTariff />

            <DeleteModal
                id="recount-balance"
                handler={recountBalance}
                text={'Ви впевнені, що хочете перерахувати рахунки?'}
            />
        </>
    )
})