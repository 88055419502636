import {closeModal} from "../../../../../Function/common";
import {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import "../../../../../Assets/css/SettingStyle/EditModal.css";
import URL from "../../../../../Api/URL.json";
import {errorNotify} from "../../../../../Function/Notify";

export default function EditBranch (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {basicSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // INIT DATA START
    useEffect(() => {
        if (!props.editBranch) return
        setName(props.editBranch.name);
    }, [props.editBranch])
    // INIT DATA END

    // STATE START
    const [name, setName] = useState('');
    // STATE END

    // EDIT BRANCH START
    async function editBranch (e) {
        e.preventDefault();

        if (!name.length) return errorNotify(`Необходимо заполнить "Название"`)

        let branch = {
            id: props.editBranch.id,
            name: name,
            disabled: props.editBranch.disabled
        }

        basicSetting.branch = basicSetting.branch.map(item => {
            if (item.id === props.editBranch.id) {
                return branch;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {basicSetting: JSON.stringify(basicSetting)})
        if (error) return error;
        window.location.reload();
    }
    // EDIT BRANCH END

    return (
        <div id="edit-branch" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editBranch(e)}>
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title">{translate('Изменить филиал')}</h4>
                        </div>
                        <div className="modal-body p-lg">
                            <div className="form-group field-branch-name required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="branch-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Киев')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}