import {useHttp} from "../../../Hooks/http.hook";
import {useEffect, useState} from "react";
import {getDateMonthDiapason, objectToUrlParams} from "../../../Function/common";
import URL from "../../../Api/URL.json";

export default function EmployeeTrialConversion () {

    // HOOK START
    const { request } = useHttp();
    // HOOK END

    // VALUE START
    const objDateMonthDiapason = getDateMonthDiapason();
    // VALUE END

    // STATE START
    const [ data, setData ] = useState({});
    const [ date_start, setDateStart ] = useState(objDateMonthDiapason.date_start);
    const [ date_end, setDateEnd ] = useState(objDateMonthDiapason.date_end);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const params_obj = {
                date_start, date_end
            }

            const data = await request(`${URL.back_end}/dashboard/employeeTrialConversion?${objectToUrlParams(params_obj)}`, 'GET');

            setData(data);
        }

        fetchData().catch(e => console.log(e))

    }, [date_start, date_end])
    // INIT DATA END

    // FUNCTION START
    function findCustomerCount (arr, employee_id) {
        const employee = arr.find((item) => item.employee_id === employee_id);

        if (employee) return employee.customer_count;
        else return 0;
    }
    // FUNCTION END

    return (
        <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
            <div className="dashboard-item">
                <label className="font-bold h4 text-center dashboard-item-head no-margins">
                    Конверсія по викладачам з пробного уроку
                    <h5>
                        від
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center', marginBottom : '5px' }}
                            onChange={e => setDateStart(e.target.value)}
                            value={date_start}
                        />
                        <br />
                        до
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center' }}
                            onChange={e => setDateEnd(e.target.value)}
                            value={date_end}
                        />
                    </h5>
                </label>
                <div className="row">
                    <div className="col-sm-12 no-padding">
                        <div className="dashboard-chart" id="chart-7">
                            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
                                <thead>
                                    <tr>
                                        <th> Викладач </th>
                                        <th> К-сть учнів з пробним (шт.) </th>
                                        <th> Конверсія (шт.) </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.totalTrialEmployee?.map((item) => (
                                            <tr>
                                                <td> { item.name } </td>
                                                <td> { item.customer_count } </td>
                                                <td> { findCustomerCount(data.trialEmployee, item.employee_id) } </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}