import {openModal} from "../../../../Function/common";
import SalaryRow from "../Row/SalaryRow";
import React, { useState } from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddSalary from "../Modal/Salary/AddSalary";
import EditSalary from "../Modal/Salary/EditSalary";

export default function SalaryBlock ({ sqlCard, wage, setSqlCard }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [editSalary, setEditSalary] = useState({});
    // STATE END

    return (
        <>
            {/*HEADER*/}
            <p className="no-padding m-b-xs m-t-xs">
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal('add-salary')}>
                    <span className="text-lowercase font-noraml"> {translate('Добавить')} </span>
                </a>
                <strong className="text-muted"> { translate('Зарплатные ставки') } </strong>
            </p>
            {/*BODY*/}
            {
                wage.length ? wage.map(item => (
                    <SalaryRow
                        data={item}
                        setEditSalary={setEditSalary}
                    />
                )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">({translate('не задано')})</div>
                        </div>
                    </div>
            }

            {/*SALARY*/}
            <AddSalary
                sqlCard={sqlCard}
                setSqlCard={setSqlCard}
            />

            <EditSalary
                sqlCard={sqlCard}
                setSqlCard={setSqlCard}
                editSalary={editSalary}
            />
        </>
    )
}