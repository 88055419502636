import "../../Assets/css/Chat.css";
import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";
import URL from "../../Api/URL.json";
import {success} from "../../Function/Notify";

export default function MessageMenu (props) {

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FUNC START
    function editMessage () {
        const { id, message_id, chat_id, text } = props.menuData;

        document.getElementById('text-message').innerText = text;
        props.setText(text);
        props.setEditMessage({ id, message_id, chat_id, text })
    }

    function getSelectedText() {
        if (window.getSelection) {
            return window.getSelection().toString();
        } else if (document.selection && document.selection.type != "Control") {
            return document.selection.createRange().text;
        }
        return "";
    }

    function copyMessage () {
        const { text } = props.menuData;

        const selectedText = getSelectedText();

        let copyTextarea = document.createElement("textarea");
        copyTextarea.style.position = "fixed";
        copyTextarea.style.opacity = "0";
        copyTextarea.textContent = selectedText ? selectedText : text;

        document.body.appendChild(copyTextarea);
        copyTextarea.select();
        document.execCommand("copy");
        document.body.removeChild(copyTextarea);

        success(`Сообщение скопировано`)
    }

    async function pinChatMessage () {
        const { id, chat_id, message_id, pin } = props.menuData;

        if (pin) {
            await request(`https://api.telegram.org/bot${props.token}/unpinChatMessage`, 'POST', { message_id, chat_id });
            await request(`${URL.back_end}/message/${id}`, 'PUT', { pin : 0 });
        } else {
            await request(`https://api.telegram.org/bot${props.token}/pinChatMessage`, 'POST', { message_id, chat_id });
            await request(`${URL.back_end}/message/${id}`, 'PUT', { pin : 1 });
        }

        const newMessage = props.message.map(msg => {
            if (msg.id === id) {
                msg.pin = pin ? 0 : 1;
                return msg;
            } else {
                return msg;
            }
        })

        props.setMessage(newMessage);
    }

    async function deleteMessage () {
        const { id, message_id, chat_id } = props.menuData;

        await request(`${URL.back_end}/message/${id}`, 'DELETE');
        await request(`https://api.telegram.org/bot${props.token}/deleteMessage`, 'POST',{ message_id, chat_id });

        if (error) return console.log(error)

        const newMessage = props.message.filter(msg => {
            if (msg.id !== id) {
                return msg;
            }
        })

        props.setMessage(newMessage);
    }
    // FUNC END

    return (
        <div className="ContextMenuContainer open shown">
            <div className="MessageContextMenu" style={ props.menuStyle } id="message-menu">
                <div className="bubble">
                    {
                        !props.menuData.edit ? (
                            <div className="scrollable-content custom-scroll" style={{ maxHeight: "545.6px" }}>
                                <div className="MenuItem compact" onClick={ () => editMessage() }>
                                    <i className="ion-edit small" style={{ fontSize: "12px", marginRight: "1.2rem" }} /> { translate('Править') }
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        !props.menuData.copy ? (
                            <div className="scrollable-content custom-scroll" style={{ maxHeight: "545.6px" }}>
                                <div className="MenuItem compact" onClick={ () => copyMessage() }>
                                    <i className="ion-ios-copy-outline" style={{ fontSize: "15px", marginRight: "1.42rem" }} /> { translate('Скопировать') }
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        <div className="scrollable-content custom-scroll" style={{ maxHeight: "545.6px" }}>
                            <div className="MenuItem compact" onClick={ () => pinChatMessage() }>
                                <i className="ion-pin" style={{ fontSize : '15px', marginRight: '1.4rem', marginLeft : '0.7rem', transform : 'rotate(40deg)' }} /> { translate(props.menuData.pin ? 'Открепить' : 'Закрепить') }
                            </div>
                        </div>
                    }
                    {
                        !props.menuData.delete ? (
                            <div className="scrollable-content custom-scroll" style={{ maxHeight: "545.6px" }}>
                                <div className="MenuItem compact" style={{ color : "#e53935" }} onClick={ () => deleteMessage() }>
                                    <i className="fa fa-trash-o" style={{ marginRight: "1.40rem", marginTop: ".027rem"}} /> { translate('Удалить') }
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}