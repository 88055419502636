import {Link} from "react-router-dom";
import {useTranslate} from "../../../Hooks/translate.hook";
import Dropdown from "../../../Components/DropdownMenu/Dropdown";
import {useContext} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {findObject, filterDisable, openModal} from "../../../Function/common";
import URL from "../../../Api/URL.json";
import {useHttp} from "../../../Hooks/http.hook";
import {success} from "../../../Function/Notify";
import {useArchive} from "../../../Hooks/archive.hook";

export default function LeadColumns ({ data, sql, setSql, managerData, setSelectedLead, setArchiveCustomer }) {

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    const sweet_text = document.getElementById("sweet-text");
    // SWEET MODAL END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    // HOOK END

    // LEAD FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(+element.value)
            }
        })

        setSelectedLead(tempSelection);
    }

    function leadArchive () {
        setArchiveCustomer(data);
        openModal('archive-modal');
    }

    function leadDelete () {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";
        sweet_text.innerText = `${translate('Вы действительно хотите удалить клиента безвозвратно?')}`;

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";

            await request(`${URL.back_end}/customer/${data.customer_id}`, 'DELETE');
            await pushArchive(3, 'customer', data.customer_id);

            if (error) return console.log(error);

            setSql(sql.filter(item => item.customer_id !== data.customer_id))

            success(translate('Клиент удален'))
        })
    }

    function renderTimeCreate () {
        if (!data.c_date) return <span className="not-set"> ({ translate('не задано') }) </span>

        let [date, time] = data.c_date.split('T');

        return (
            <span>
                <i className="ion-clock fa-fw" /> { time?.slice(0, 5) }
                <br />
                <i className="ion-calendar fa-fw" /> { date }
            </span>
        )
    }

    async function fromArchive () {

        await request(`${URL.back_end}/customer/${data.customer_id}`, 'PUT', { f_removed : 0 });

        setSql( sql.filter(item => item.customer_id !== data.customer_id) )

        success(translate('Клиент удален из архива'))

    }
    // LEAD FUNC END

    return (
        <tr className="w1">
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{ width : '50px' }}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={data.customer_id}
                    onClick={getSelect}
                />
            </td>
            <td>
                <div style={{ fontSize : '11px' }}>
                    { renderTimeCreate() }
                </div>
            </td>
            <td>
                <Link to={`/company/1/customer/view?id=${data.customer_id}`} title={`${translate('Перейти в карточку')} ${data.name}`}>
                    <i className="ion-person-add m-r-xxs" /> { data.name ? data.name : ( data.contact ? JSON.parse(data.contact).filter(item => item.type === 'mobile')[0]?.text : null ) }
                </Link>
                {/* ADD MOBILE */}
            </td>
            <td>
                <Dropdown
                    initValue={data.manager !== null ? managerData.filter(item => item.user_id === data.manager)[0]?.name : translate('Не задано')}
                    arr={managerData}
                    type='name'
                    objType='manager'
                    url={`/customer/${data.customer_id}`}
                    string={false}
                    resultType='user_id'
                />
            </td>
            <td>
                {
                    salesSetting && (
                        <Dropdown
                            initValue={data.leadStatus !== 0 ? findObject(filterDisable(salesSetting.leadStatus), data.leadStatus)?.name : translate('Не разобрано')}
                            arr={[{ id : 0, name : translate('Не разобрано') }, ...filterDisable(salesSetting.leadStatus)]}
                            type='name'
                            objType='leadStatus'
                            url={`/customer/${data.customer_id}`}
                            string={false}
                            resultType='id'
                            offNull={true}
                        />
                    )
                }
            </td>
            <td>
                {
                    data.f_removed === 0 ?
                        <a className="pull-right" title={translate('В архив')} onClick={() => leadArchive()}>
                            <i className="fa fa-lg fa-trash-o text-danger" />
                        </a> :
                        (
                            <>
                                <a className="pull-right" title={translate('Удалить')} onClick={leadDelete}>
                                    <i className="fa fa-lg fa-trash-o text-danger" />
                                </a>
                                <a className="pull-right" title={translate('Из архива')} onClick={() => fromArchive()}>
                                    <i className="fa fa-reply text-navy m-r-xs" />
                                </a>
                            </>
                        )
                }
            </td>
        </tr>
    )
}