import {useHttp} from "../../../../Hooks/http.hook";
import {useEffect, useState} from "react";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";

export default function FileBlock ({ sqlCard }) {

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ files, setFiles ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const response = await request(`${URL.back_end}/get-files/customer/${sqlCard.customer_id}`, 'GET');

            setFiles(response);
        }

        fetchData().catch(e => console.log(e))

    }, [sqlCard])
    // INIT DATA END

    if (!files.length) return <></>

    return (
        <div className="ibox float-e-margins m-b-sm">
            <div className="ibox-content border-bottom">
                <div className="row">
                    <div className="col-sm-12">
                        <h4 className="m-b-sm"> { translate('Файлы') } </h4>
                        <div className="clearfix" />
                        <div>
                            {
                                files.map(item => (
                                    <div style={{ marginLeft : '10px', position: 'relative' }}>
                                        <a href={`${URL.back_end}/download/customer/${sqlCard.customer_id}/${item}/2`} target="_blank" className="m-r-sm m-b-xs pull-left text-center crm-dashed-link" style={{ lineHeight: '15px', position: 'relative', zIndex: 1, marginRight : '20px' }}>
                                            <i className="ion-document-text" style={{ fontSize : '70px' }} />
                                            <br />
                                            { item }
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
