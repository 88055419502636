import '../../Assets/css/DateWidget.css';
import {useTranslate} from "../../Hooks/translate.hook";
import moment from "moment";

export default function DateWidget (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function chooseDate (type) {
        let [ year, month, day ] = props.dateStart.split('-');

        let date = new Date(+year,+month-1,+day);
        // eslint-disable-next-line default-case
        switch (type) {
            case 'день':
                date.setDate(date.getDate()+Number(props.dateEnd));
                // props.updateDateEnd(date.toLocaleDateString('en-CA'));
                props.updateDateEnd(moment(date).format('YYYY-MM-DD'))
                break;
            case 'неделя':
                date.setDate(date.getDate()+Number(props.dateEnd*7));
                // props.updateDateEnd(date.toLocaleDateString('en-CA'));
                props.updateDateEnd(moment(date).format('YYYY-MM-DD'))
                break;
            case 'месяц':
                date.setMonth(date.getMonth()+Number(props.dateEnd));
                // props.updateDateEnd(date.toLocaleDateString('en-CA'));
                props.updateDateEnd(moment(date).format('YYYY-MM-DD'))
                break;
            case 'лет':
                date.setFullYear(date.getFullYear()+Number(props.dateEnd));
                // props.updateDateEnd(date.toLocaleDateString('en-CA'));
                props.updateDateEnd(moment(date).format('YYYY-MM-DD'))
                break;
        }
    }

    return props.dateEnd && props.dateEnd.length <= 4 ?
         (
            <ul
                id="ui-id-3"
                tabIndex={0}
                className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                style={props.style}
            >
                <li className="ui-menu-item" onClick={() => chooseDate('день')}>
                    <div tabIndex={-1} className="ui-menu-item-wrapper date-widget-active">
                        {props.dateEnd} { props.dateEnd <= 1 ? translate('день') : translate('дней') }
                    </div>
                </li>
                <li className="ui-menu-item" onClick={() => chooseDate('неделя')}>
                    <div tabIndex={-1} className="ui-menu-item-wrapper date-widget-active">
                        {props.dateEnd} { props.dateEnd <= 1 ? translate('неделя') : translate('недель') }
                    </div>
                </li>
                <li className={`ui-menu-item ${+props.dateEnd < 100 ? null : "none"}`} onClick={() => chooseDate('месяц')}>
                    <div tabIndex={-1} className="ui-menu-item-wrapper date-widget-active">
                        {props.dateEnd} { props.dateEnd <= 1 ? translate('месяц') : translate('месяцев') }
                    </div>
                </li>
                <li className={`ui-menu-item ${+props.dateEnd < 10 ? null : "none"}`} onClick={() => chooseDate('лет')}>
                    <div tabIndex={-1} className="ui-menu-item-wrapper date-widget-active">
                        {props.dateEnd} { props.dateEnd <= 1 ? translate('год') : translate('лет') }
                    </div>
                </li>
            </ul>
        ) : null
}