import NumberLeadInManager from "../Elements/NumberLeadInManager";
import ConvertedIntoPayment from "../Elements/ConvertedIntoPayment";
import LostLeads from "../Elements/LostLeads";
import ActiveLeads from "../Elements/ActiveLeads";
import CourseCounter from "../Elements/CourseCounter";
import EmployeeActiveLost from "../Elements/EmployeeActiveLost";
import EmployeeTrialConversion from "../Elements/EmployeeTrialConversion";
import CustomerActiveLost from "../Elements/СustomerActiveLost";
import SpendLessonAnalytic from '../Elements/SpendLessonAnalytic';
import CalculationOfTurnover from '../Elements/CalculationOfTurnover';

export default function DashboardItems () {

  return (
	<div className="row dashboard-container crm-table flex flex-wrap justify-center gap-2">
	    <NumberLeadInManager />
	    <ConvertedIntoPayment />
	    <LostLeads />
	    <ActiveLeads />
	    <CourseCounter />
	    <EmployeeActiveLost />
	    <EmployeeTrialConversion />
		<CustomerActiveLost />
		<SpendLessonAnalytic />
		<CalculationOfTurnover />
	</div>
  )
}