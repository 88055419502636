// COMPONENT
import SideMenu from "../../Components/SideMenu/SideMenu";

// HOOK
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useTranslate} from "../../Hooks/translate.hook";
import TabAction from "../../Components/TabAction";
import {AgileBoard} from "./Table/AgileBoard";
import AddAgile from "./Modal/AddAgile";
import {openModal} from "../../Function/common";
import {useHttp} from "../../Hooks/http.hook";
import URL from "../../Api/URL.json";
import {useContext, useEffect, useState} from "react";
import EditAgile from "./Modal/EditAgile";
import {AgileTable} from "./Table/AgileTable";
import {FilterAgile} from "./Modal/FilterAgile";
import {SettingContext} from "../../Context/SettingContext";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import AcceptModal from "../../Components/Modal/AcceptModal";
import {useArchive} from "../../Hooks/archive.hook";

export default function Agile () {

    // CONTEXT START
    const { pageSize, currentTime } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // SWAP VIEW PARAMS START
    const [ searchParams ] = useSearchParams();
    const viewMode = searchParams.get('view_mode');
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // SWAP VIEW PARAMS END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ customerData, setCustomerData ] = useState([]);
    const [ groupData, setGroupData ] = useState([]);
    const [ userData, setUserData ] = useState([]);
    const [ selectedTask, setSelectedTask ] = useState([]);
    const [ editTask, setEditTask ] = useState({});
    const [ counts, setCounts ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!pageSize) return

        const params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            const queryParams = viewMode === 'table' ? {
                page, limit : pageSize.task, data : { status : 1, f_removed: 0, ...params }
            } : {
                page, nolimit : true, data : { status : 1, f_removed : 0, ...params }
            };

            const data = await request(`${URL.back_end}/filter_task`, 'POST', queryParams);

            if (error) throw error

            setSql(data.tasks);
            setCounts(data.counts);
            setLoading(false);
        }

        fetchData().catch(e => console.log(e))

    }, [page, pageSize, filterParams, viewMode])

    useEffect(() => {

        async function fetchData () {
            const customers = await request(`${URL.back_end}/customer`, 'GET');
            const groups = await request(`${URL.back_end}/group`, 'GET');
            const users = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } });

            if (error) throw error;

            setCustomerData(customers);
            setGroupData(groups);
            setUserData(users.users);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // HANDLER START
    function handlerEditTask () {
        if (selectedTask.length === 1) {
            setEditTask(sql.filter(item => item.task_id === +selectedTask[0])[0])
            openModal('edit-agile')
        }
    }

    async function doTask () {
        await request(`${URL.back_end}/task`, 'PUT', { data : { status : 2, date_done : currentTime.split('T')[0] }, arr : selectedTask });

        for (let i = 0; i < selectedTask.length; i++) {
            await pushArchive('3', 'task', selectedTask[i], { status : 2 }, { status : 1 })
        }

        window.location.reload();
    }

    async function archiveTask () {
        await request(`${URL.back_end}/task`, 'PUT', { data : { f_removed : 1 }, arr : selectedTask });

        for (let i = 0; i < selectedTask.length; i++) {
            await pushArchive('3', 'task', selectedTask[i], { f_removed : 1 }, { f_removed : 0 })
        }

        window.location.reload();
    }
    // HANDLER END

    return (
        <div id="wrapper">
            <SideMenu page="agile"/>
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: '624px' }}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON GROUP*/}
                                <div className="btn-group m-r-xs crm-view-change">
                                    <Link to="?view_mode=board" className={`btn btn-sm btn-white ${(!viewMode || viewMode === 'board') && 'active'}`} title={translate('Отображение: в виде доски (только открытые задачи)')}>
                                        <i className="ion-stats-bars fa fa-rotate-180" />
                                    </Link>
                                    <Link to="?view_mode=table" className={`btn btn-sm btn-white ${viewMode === 'table' && 'active'}`} title={translate('Отображение: в виде таблицы (все задачи)')}>
                                        <i className="ion-navicon-round" />
                                    </Link>
                                </div>
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    title={translate('Создать новую задачу')}
                                    handler={() => openModal('add-agile')}
                                    icon="ion-plus"
                                    text="Добавить"
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    title="Править задачу"
                                    handler={() => handlerEditTask()}
                                    disable={!selectedTask.length}
                                    icon="ion-edit"
                                    text="Править"
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-w-m btn-primary btn-sm"
                                    title="Выполнить"
                                    handler={() => doTask()}
                                    disable={!selectedTask.length}
                                    icon="ion-checkmark"
                                    text="Выполнить"
                                />
                                <TabAction
                                    access={true}
                                    className="btn btn-w-m btn-danger btn-sm"
                                    title="В архив"
                                    handler={() => archiveTask()}
                                    disable={!selectedTask.length}
                                    icon="fa fa-trash-o"
                                    text="В архив"
                                />
                                {/*<TabAction*/}
                                {/*    access={true}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-more"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'Повторяющиеся задачи', icon : 'fa fa-table' },*/}
                                {/*            { link : '#', text : 'Настройки задач', icon : 'fa fa-gear' },*/}
                                {/*            { link : '#', text : 'Шаблоны задач', icon : 'ion-android-list' },*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' },*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    title={translate('Фильтр')}
                                    handler={() => openModal('filter-agile')}
                                    icon="fa fa-filter"
                                    text="Фильтр"
                                />
                                {
                                    filterParams &&
                                        <FilterOptions
                                            data={JSON.parse(decodeURI(filterParams))}
                                            means={{
                                                text : translate('Текст содержит'),
                                                status : translate('Статус'),
                                                employer : translate('Автор'),
                                                user : translate('Исполнитель'),
                                                f_removed : translate('В архиве'),
                                                date_start : translate('Дата от'),
                                                date_end : translate('Дата до'),
                                                result : translate('Результат')
                                            }}
                                            meansData={{
                                                status : {
                                                    arr : [ { id : 1, name : translate('Открыта') }, { id : 2, name : translate('Выполнена') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                employer : {
                                                    arr : userData,
                                                    type_id : 'user_id',
                                                    topic : 'name'
                                                },
                                                user : {
                                                    arr : userData,
                                                    type_id : 'user_id',
                                                    topic : 'name'
                                                },
                                                f_removed : {
                                                    arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                }
                                            }}
                                            page='agile'
                                            viewMode={viewMode}
                                        />
                                }
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                    {
                        (!viewMode || viewMode === 'board') ? (
                            <AgileBoard
                                sql={sql}
                                setSql={setSql}
                                setSelectedTask={setSelectedTask}
                            />
                        ) : (
                            <AgileTable
                                sql={sql}
                                setSql={setSql}
                                pageSize={pageSize}
                                page={page}
                                counts={counts}
                                viewMode={viewMode}
                                filterParams={filterParams}
                                setSelectedTask={setSelectedTask}
                                loading={loading}
                            />
                        )
                    }
                </div>
            </div>

            {/* MODAL */}
            <AddAgile
                userData={userData}
                customerData={customerData}
                groupData={groupData}
            />

            <EditAgile
                userData={userData}
                customerData={customerData}
                groupData={groupData}
                editTask={editTask}
            />

            <FilterAgile
                userData={userData}
                viewMode={viewMode}
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
            />

            <AcceptModal />

        </div>
    );
}