import {useContext} from "react";
import {AuthContext} from "../../Context/AuthContext";
import {useTranslate} from "../../Hooks/translate.hook";
import {Link} from "react-router-dom";
import Menu from "../../Assets/data/JSON/menu.json";
import shortid from "shortid";

export default function MobileMenu (props) {

    // CONTEXT START
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // FUNCTION START
    function switchInterface (id) {
        // eslint-disable-next-line default-case
        switch (id) {
            case 1 : return (
                <li className={props.page === "profile" ? "active" : ""}>
                    <Link to={`/company/1/profile/index`}>
                        <i className="ion-person" /> {translate('Мой профиль')}
                    </Link>
                </li>
            )
            case 2 : return (
                <li className={props.page === "employee" ? "active" : ""}>
                    <Link to={`/teacher/1/profile/index`}>
                        <i className="ion-person" /> {translate('Мой профиль')}
                    </Link>
                </li>
            )
            case 3 : return (
                <li className={props.page === "customer" ? "active" : ""}>
                    <Link to={`/customer/1/profile/index`}>
                        <i className="ion-person" /> {translate('Мой профиль')}
                    </Link>
                </li>
            )
        }
    }
    // FUNCTION END

    return (
        <>
            { switchInterface(auth.role.interface) }

            {
                auth.role.menu !== undefined ?
                    JSON.parse(auth.role.menu).map(item => (
                        <li className={props.page === Menu[item].value ? "active" : ""} key={shortid.generate()}>
                            <Link to={`/company/1/${Menu[item].value}/index/`}>
                                <i className={Menu[item].icon} /> {translate(Menu[item].name)}
                            </Link>
                        </li>
                    )) : null
            }

            <li>
                <a onClick={() => auth.logout()}>
                    <i className="ion-android-exit fa" /> {translate('Выход')}
                </a>
            </li>
        </>
    )
}