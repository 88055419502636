import {useHttp} from "../../../Hooks/http.hook";
import {useContext, useEffect, useState} from "react";
import URL from "../../../Api/URL.json";
import {SettingContext} from "../../../Context/SettingContext";
import {findObject, getDateMonthDiapason, objectToUrlParams} from "../../../Function/common";

export default function CourseCounter () {

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    // HOOK END

    // VALUE START
    const objDateMonthDiapason = getDateMonthDiapason();
    // VALUE END

    // STATE START
    const [ data, setData ] = useState({});
    const [ date_start, setDateStart ] = useState(objDateMonthDiapason.date_start);
    const [ date_end, setDateEnd ] = useState(objDateMonthDiapason.date_end);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const params_obj = {
                date_start, date_end
            }

            const response = await request(`${URL.back_end}/dashboard/courseCounter?${objectToUrlParams(params_obj)}`, 'GET');

            setData(response);

        }

        fetchData().catch(e => console.log(e))

    }, [date_start, date_end, request])
    // INIT DATA END

    // FUNCTION START
    function renderBody () {

        if (!Object.keys(data).length && !lessonSetting) return <></>

        let column_arr = [];

        for (let key in data) {
            column_arr.push(
                <tr>
                    <td> { findObject(lessonSetting.subject, key)?.name } </td>
                    <td> { data[key] } </td>
                </tr>
            )
        }

        return column_arr;
    }
    // FUNCTION END

    return (
        <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
            <div className="dashboard-item">
                <label className="font-bold h4 text-center dashboard-item-head no-margins">
                    Учні та предмети
                    <h5>
                        від
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center', marginBottom : '5px' }}
                            onChange={e => setDateStart(e.target.value)}
                            value={date_start}
                        />
                        <br />
                        до
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center' }}
                            onChange={e => setDateEnd(e.target.value)}
                            value={date_end}
                        />
                    </h5>
                </label>
                <div className="row">
                    <div className="col-sm-12 no-padding">
                        <div className="dashboard-chart" id="chart-5">
                            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
                                <thead>
                                <tr>
                                    <th> Предмет </th>
                                    <th> К-сть учнів (шт.) </th>
                                </tr>
                                </thead>
                                <tbody>
                                    { renderBody() }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}