import { useContext, useEffect, useState } from 'react';
import { useHttp } from '../../../Hooks/http.hook';

import URL from '../../../Api/URL.json';
import CURRENCY from '../../../Assets/data/JSON/currency.json';
import { SettingContext } from '../../../Context/SettingContext';
import { objectToUrlParams } from '../../../Function/common';

export default function CalculationOfTurnover() {
	// CONTEXT START
	const { basicSetting, lessonSetting } = useContext(SettingContext);
	// CONTEXT END

	// HOOK START
	const { request } = useHttp();
	// HOOK END

	// STATE START
	const [ data, setData ] = useState([]);
	const [ subject, setSubject ] = useState('');
	const [ type_lesson, setType_lesson ] = useState('');
	// STATE END

	// INIT DATA START
	useEffect(() => {

		const params_obj = {
			subject,
			type_lesson
		}

		async function fetchData () {
			const response = await request(`${URL.back_end}/dashboard/calculateTurnover?${objectToUrlParams(params_obj)}`, 'GET');
			setData(response);
		}

		fetchData().catch((error) => console.log(error));
	}, [subject, type_lesson, request])
	// INIT DATA END

	return (
	  <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
		  <div className="dashboard-item">
			  <label className="font-bold h4 text-center dashboard-item-head no-margins">
				  Підрахунок обороту
			  </label>
			  <div className="row">
				  <div className="col-sm-12 no-padding">
					  <div className="dashboard-chart" id="chart-10">
						  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							  <div>
								  <b>Предмет</b>
								  <select
									style={{ outline: 0, border: 0, textAlign: 'center', marginBottom: '5px' }}
									onChange={event => setSubject(event.target.value)}
								  >
									  <option value=''> Всі </option>
									  {lessonSetting && lessonSetting.subject
										.filter((subject) => !subject.disabled)
										.map((subject) => <option value={subject.id}>{subject.name}</option>)}
								  </select>
							  </div>
							  <div>
								  <b>Тип уроку</b>
								  <select
									style={{ outline: 0, border: 0, textAlign: 'center', marginBottom: '5px' }}
									onChange={event => setType_lesson(event.target.value)}
								  >
									  <option value=''> Всі </option>
									  <option value='1'> Індивідуальний </option>
									  <option value='2'> Груповий </option>
									  <option value='3'> Пробний </option>
								  </select>
							  </div>
						  </div>
						  <table
							className='crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center'>
							  <thead>
							  <tr>
								  <th>Абонемент</th>
								  <th>Дохід за абонементом</th>
								  <th>Середне доходу</th>
							  </tr>
							  </thead>
							  <tbody>
							  {
								  data.map(item => (
									<tr>
									<td> {item.title} </td>
										<td> {item.total_amount} {CURRENCY[basicSetting.baseOption.currency]} </td>
										<td> {item.avg_amount} {CURRENCY[basicSetting.baseOption.currency]} </td>
									</tr>
								  ))
							  }
							  </tbody>
						  </table>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	)
}