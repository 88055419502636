import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal} from "../../../../../Function/common";
import {validate} from "../../../../../Function/validateForm";
import {useArchive} from "../../../../../Hooks/archive.hook";
import moment from "moment";

export default function AddGroup (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [group, setGroup] = useState({});
    const [dateStart, setDateStart] = useState(new Date().toLocaleDateString('en-CA'))
    const [dateEnd, setDateEnd] = useState('');
    // STATE END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (dateEnd) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(dateStart))).asDays();

            const newDateEnd = moment(dateEnd).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function getDiapasonDate(start, end) {
        const date_end = new Date(end);
        date_end.setDate(date_end.getDate() + 1);

        for (var arr = [], dt = new Date(start); dt <= new Date(date_end); dt.setDate(dt.getDate()+1) ){
            arr.push(new Date(dt).toLocaleDateString());
        }

        return arr;
    }
    // HANDLER END

    // ADD GROUP START
    async function addGroup(e) {
        e.preventDefault();

        let validObj = {
            "Группа" : !Object.keys(group).length,
            "Период участия" : !dateStart || !dateEnd
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        const groupCustomer = await request(`${URL.back_end}/group_customer/${group.group_id}`, 'POST', { customer_id: props.sql.customer_id, date_start: dateStart, date_end: dateEnd });

        let regularLesson = await request(`${URL.back_end}/filter_regular_lesson`, 'POST', { group_id : group.group_id })

        if (regularLesson.length) {

            for (let i = 0; i < regularLesson.length; i++) {
                let regular = await request(`${URL.back_end}/regular_lesson/${regularLesson[i].regular_id}`, 'GET');

                regular = regular.filter(reg => getDiapasonDate(dateStart, dateEnd).includes(new Date(reg.date_start).toLocaleDateString()) && reg)

                for (let j = 0; j < regular.length; j++) {
                    await request(`${URL.back_end}/regular_lesson_customer/${regular[j].lesson_id}`, 'POST', { customer_id : props.sql.customer_id })
                }

                await request(`${URL.back_end}/customer_regular_lesson/${props.sql.customer_id}`, 'POST', regularLesson[i]);
            }
        }

        if (error) return console.log(error);

        await pushArchive(1, 'customerGroup', groupCustomer.insertId, { customer_id: props.sql.customer_id, date_start: dateStart, date_end: dateEnd, group_id : group.group_id }, null);

        window.location.reload();
    }
    // ADD GROUP END

    return (
        <div id="add-group" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">{translate('Добавить в группу')}</h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addGroup(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*NAME*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Клиент')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={props.sql.name}
                                    />
                                </div>
                            </div>
                            {/*GROUP*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Группа')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={props.groupData.filter(item => item.f_removed === 0)}
                                        setProps={setGroup}
                                        topicSearch="title"
                                        type_id="group_id"
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Период участия')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        {/*DATE START*/}
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <span className="input-group-addon">{translate('с')}</span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={dateStart}
                                                    onChange={(event) => dateStartHandler(event)}
                                                />
                                            </div>
                                        </div>
                                        {/*DATE END*/}
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <span className="input-group-addon">{translate('по')}</span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    max={moment(dateStart).add(2, 'year').format('YYYY-MM-DD')}
                                                    min={moment(dateStart).add(1, 'day').format('YYYY-MM-DD')}
                                                    value={dateEnd}
                                                    onChange={(event) => setDateEnd(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}