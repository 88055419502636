import '../../Assets/css/UIMenu.css';

export default function UIMenu ({ result, filterChoose }) {

    const entityMean = {
        'ion-person' : 'customer',
        'ion-person-stalker' : 'group',
        'ion-university' : 'employee'
    }

    const idMean = {
        'ion-person' : 'customer_id',
        'ion-person-stalker' : 'group_id',
        'ion-university' : 'employee_id'
    }

    const topicMean = {
        'ion-person' : 'name',
        'ion-person-stalker' : 'title',
        'ion-university' : 'name'
    }

    return (
        <ul className={`ui-menu ui-widget ui-widget-content ui-autocomplete ui-front ${result.length === 0 ? "hide" : ""}`} style={{top: "40px", width: "351.612px"}}>
            {
                result.map(item => (
                    <li style={{padding: "5px"}} className={`ui-menu-item ${item.f_removed === 1 ? 'archive' : ''}`}>
                        <small className="ui-menu-item-wrapper">
                            <a href={`/company/1/${entityMean[filterChoose]}/view?id=${item[idMean[filterChoose]]}`} className="ui-link">
                                <i className="ion-person text-muted" /> {item[topicMean[filterChoose]]}
                            </a>
                        </small>
                    </li>
                ))
            }
        </ul>
    )
}