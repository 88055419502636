import {useEffect, useRef} from "react";
import {useTranslate} from "../../Hooks/translate.hook";

export default function Gender (props) {

    // REF START
    const male = useRef();
    const female = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    useEffect(() => {
        if (props.value !== undefined && props.value !== "") {
            if (props.value === 1) {
                male.current.classList = "btn btn-white active";
                female.current.classList = "btn btn-white";
            } else {
                female.current.classList = "btn btn-white active";
                male.current.classList = "btn btn-white";
            }
        }
    }, [props.value])

    // HANDLER START
    function handler (e) {
        props.setProps(+e.target.value);

        if (e.target.name === "male") {
            male.current.classList = "btn btn-white active";
            female.current.classList = "btn btn-white";
        } else {
            female.current.classList = "btn btn-white active";
            male.current.classList = "btn btn-white";
        }
    }
    // HANDLER END

    return (
        <div id="user-gender" className="btn-group pull-right" data-toggle="buttons">
            <label className="btn btn-white" ref={male} onClick={e => handler(e)}>
                <input type="radio" name="male" value={1} />
                <i className="ion-man" />
                <small> { translate('М.') } </small>
            </label>
            <label className="btn btn-white active" ref={female} onClick={e => handler(e)}>
                <input type="radio" name="female" value={2} />
                <i className="ion-woman" />
                <small> { translate('Ж.') } </small>
            </label>
        </div>
    )
}