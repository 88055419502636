import {memo, useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal} from "../../../Function/common";
import {useNavigate} from "react-router-dom";

export const FilterAgile = memo(({ userData, viewMode, value }) => {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [ text, setText ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ employer, setEmployer ] = useState('');
    const [ user, setUser ] = useState('');
    const [ f_removed, setFremoved ] = useState('');
    const [ date_start, setDateStart ] = useState('');
    const [ date_end, setDateEnd ] = useState('');
    const [ result, setResult ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!value) {
            setText('');
            setStatus('');
            setEmployer('');
            setUser('');
            setFremoved('');
            setDateStart('');
            setDateEnd('');
            setResult('');
            return
        }

        setText(value.text);
        setStatus(value.status);
        setEmployer(value.employer);
        setUser(value.user);
        setFremoved(value.f_removed);
        setDateStart(value.date_start);
        setDateEnd(value.date_end);
        setResult(value.result);

    }, [value])
    // INIT DATA END

    // FILTER AGILE START
    function filterAgile (e) {

        e.preventDefault();

        let task = { text, status, employer, user, f_removed, date_start, date_end, result }

        let obj = Object.filter(task, (value) => value);

        if (!Object.keys(obj).length) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/agile/index/${encodeURI(JSON.stringify(obj))}${viewMode ? `?view_mode=${viewMode}` : ''}`);
            closeModal(e, modal);
        }

    }
    // FILTER AGILE END

    return (
        <div id="filter-agile" className="modal in" style={{ paddingRight : '17px' }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterAgile(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                                <span className="sr-only"> { translate('Закрыть') } </span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        <div className="modal-body modal-body-search p-md">
                            {/* TEXT */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Текст содержит') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={text}
                                        onChange={e => setText(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* STATUS */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Статус') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={status} onChange={e => setStatus(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={1}> { translate('Открыта') } </option>
                                        <option value={2}> { translate('Выполнена') } </option>
                                    </select>
                                </div>
                            </div>
                            {/* EMPLOYER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Автор') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={employer} onChange={e => setEmployer(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            userData.filter(item => item.f_removed === 0).map(item => (
                                                <option value={item.user_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* USER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Исполнитель') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={user} onChange={e => setUser(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            userData.filter(item => item.f_removed === 0).map(item => (
                                                <option value={item.user_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* ARCHIVE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('В архиве') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={f_removed} onChange={e => setFremoved(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={0}> { translate('Активные') } </option>
                                        <option value={1}> { translate('Архивные') } </option>
                                    </select>
                                </div>
                            </div>
                            {/* DATE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Дата исполнения') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <div className="input-group date-filter">
                                                <span className="input-group-addon">
                                                    { translate('от') }
                                                </span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={date_start}
                                                    onChange={e => setDateStart(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs-6">
                                            <div className="input-group date-filter">
                                                <span className="input-group-addon">
                                                    { translate('до') }
                                                </span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={date_end}
                                                    onChange={e => setDateEnd(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* RESULT */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Результат') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={result}
                                        onChange={e => setResult(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-sm btn-danger btn-outline pull-left" title={translate('Сбросить фильтр и вернуться в таблицу')} onClick={e => {
                                closeModal(e, modal);
                                navigate(`/company/1/agile/index/${viewMode ? `?view_mode=${viewMode}` : ''}`)
                            }}>
                                <i className="fa fa-eraser fa-fw" />
                            </a>
                            <a className="btn btn-sm btn-success btn-outline pull-left" title={translate('Настройка полей')}>
                                <i className="fa fa-cog fa-fw" />
                            </a>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})