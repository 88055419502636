// HOOK
import {useNavigate, useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {useTranslate} from "../../../Hooks/translate.hook";
import React from "react";
import jwt_decode from "jwt-decode";

// COMPONENT
import SideMenu from "../../../Components/SideMenu/SideMenu";
import Error from "../../Error/Error";
import SpongeBob from "../../../Components/Table/SpongeBob";
import { Chat } from "./Chat/Chat";

// FUNCTION
import {
    filterDisableEntity,
    findObject,
    getCurrentWeekDateRange,
    getDateParamsMonthDiapason,
    openMenu,
    openModal
} from "../../../Function/common";

// MODAL
   // ACCEPT MODAL
import AcceptModal from "../../../Components/Modal/AcceptModal";
   // CUSTOMER
import EditCustomer from '../../Customer/Modal/EditCustomer';
   // ARCHIVE
import ArchiveModal from "../../../Components/Modal/ArchiveModal";

// STYLE
import '../../../Assets/css/SpongeBob.css'

// JSON
import URL from '../../../Api/URL.json';

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// BLOCK
import { TariffBlock } from "./Block/TariffBlock";
import { DiscountBlock } from "./Block/DiscountBlock";
import { QualificationBlock } from "./Block/QualificationBlock";
import { UserBlock } from "./Block/UserBlock";
import { GroupBlock } from "./Block/GroupBlock";
import { RegularLessonBlock } from "./Block/RegularLessonBlock";
import PayBlock from "./Block/PayBlock";
import LessonBlock from "./Block/LessonBlock";
import TabAction from "../../../Components/TabAction";
import {AuthContext} from "../../../Context/AuthContext";
import {TotalBalanceBlock} from "./Block/TotalBalanceBlock";
import {EmployeeBlock} from "./Block/EmployeeBlock";
import {TelegramBlock} from "./Block/TelegramBlock";
import {VisitLog} from "./Report/Modal/VisitLog";
import {TaskBlock} from "./Block/TaskBlock";
import {TaskInfoBlock} from "./Block/TaskInfoBlock";
import {useArchive} from "../../../Hooks/archive.hook";
import ManagerBlock from "./Block/ManagerBlock";
import GeneralBlock from "./Block/GeneralBlock";
import EditLead from "../../Lead/Modal/EditLead";
import FileBlock from "./Block/FileBlock";
import CommentBlock from "./Block/CommentBlock";
import SwapChat from "../../../Components/Modal/SwapChat";
import ContactBlock from "../../../Components/Block/ContactBlock";

function MemoCustomerCard (props) {

    // NAVIGATE START
    const navigate = useNavigate();
    // NAVIGATE END

    // CONTEXT START
    const { customSetting, employeeSetting, lessonSetting, integration } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // CUSTOMER ID START
    const [ searchParams ] = useSearchParams();
    const id = searchParams.get('id');
    // CUSTOMER ID END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [sqlCard, setSqlCard] = useState({});
    const [limitFilter, setLimitFilter] = useState(90);
    const [subjectFilter, setSubjectFilter] = useState('');

       // DATA SQL
    const [groupData, setGroupData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [lessonData, setLessonData] = useState([]);
    const [managerData, setManagerData] = useState([]);

        // RELATE SQL
    const [customerEmployee, setCustomerEmployee] = useState([]);
    const [customerGroup, setCustomerGroup] = useState([]);
    const [customerTariff, setCustomerTariff] = useState([]);

        // CHAT
    const [chooseChat, setChooseChat] = useState(1);
    // STATE END

    // INIT DATE START
    useEffect(() => {

        async function fetchData () {
                // REQUEST
            let customers = await request(`${URL.back_end}/customer`, "GET");
            const groups = await request(`${URL.back_end}/group`, "GET");
            const employees = await request(`${URL.back_end}/employee`, "GET");
            const customerEmployee = await request(`${URL.back_end}/customer_employee/${id}`, 'GET');
            const customerGroup = await request(`${URL.back_end}/customer_group/${id}`, 'GET');
            const customerTariff = await request(`${URL.back_end}/customer_tariff/${id}`, 'GET');
            const managers = await request(`${URL.back_end}/user`, 'GET');

            if (error) throw error;

               // SET
            setSqlCard(customers.filter(item => item.customer_id === +id)[0]);
            setGroupData(groups);
            setEmployeeData(employees);
            setCustomerData(customers);
            setCustomerEmployee(customerEmployee);
            setCustomerGroup(customerGroup);
            setCustomerTariff(customerTariff);
            setManagerData(managers);
        }

        fetchData().catch(e => console.log(e))
    }, [id, request, error])

    useEffect(() => {

        async function fetchData () {

            const { date_start, date_end } = getFilterDate(limitFilter);

            const params = {
                customer_id : id,
                subject: subjectFilter.length ? Number(subjectFilter) : undefined,
                date_start,
                date_end
            }

            let lesson_data;

            if (auth.role.interface === 2) {
                const employee_id = jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;

                lesson_data = await request(`${URL.back_end}/filter_customer_lesson`, 'POST', { employee_id, ...params })
            } else {
                lesson_data = await request(`${URL.back_end}/filter_customer_lesson`, 'POST', { ...params });
            }

            setLessonData(lesson_data);
        }

        fetchData().catch(e => console.log(e))

    }, [id, limitFilter, subjectFilter])

    useEffect(() => {

        if (!integration || !sqlCard.branch) return

        const { telegram } = integration;

        const telegram_arr = telegram.filter(item => Number(item.branch) === Number(sqlCard.branch));

        if (telegram_arr.length) {
            const telegram_id = telegram_arr[0]?.id;
            setChooseChat(telegram_id);
        }

    }, [integration, sqlCard?.branch])
    // INIT DATE END

    // FUNC START
    function getFilterDate (limit) {
        // eslint-disable-next-line default-case
        switch (limit) {
            case null: return { date_start: undefined, date_end: undefined };
            case 7: return getCurrentWeekDateRange();
            case 30: return getDateParamsMonthDiapason(0, 1);
            case 90: return getDateParamsMonthDiapason(-1, 2);
            case 180: return getDateParamsMonthDiapason(-3, 3);
        }
    }

    async function buttonFromArchive () {
        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', { f_removed: 0, refusal: null });
        await pushArchive(4, 'customer', sqlCard.customer_id, { refusal : null, f_removed : 0 }, { refusal : sqlCard.refusal, f_removed : 1 });

        if (error) return console.log(error)

        let obj = Object.assign({}, sqlCard);
        obj.f_removed = 0;
        obj.refusal = null;

        setSqlCard(obj)
    }
    // FUNC END

    // CHECK ERROR START
    if (error || sqlCard === undefined) return <Error text={`${translate('Клиента с')} id ${id} ${translate('не найдено')}`} />
    // CHECK ERROR END

    return (
        <div id={ props.chats ? null : "wrapper" }>
            {/*SIDE MENU*/}
            { props.chats ? null : <SideMenu page="customer" /> }
            <ToastContainer />
            {/*CONTENT*/}
            <div id={props.chats ? null : "page-wrapper"} className={props.chats ? null : "gray-bg"} style={{ minHeight: '624px' }}>
                <div className="wrapper crm-content">
                    <div>
                        <div className="row">
                            <div className={props.chats ? "col-sm-3 m-b-sm text-center crm-actions" : "col-sm-4 m-b-sm text-center crm-actions"} style={{float: "right"}} id="block-center">
                                <div className="ibox-content border-bottom">
                                    <TabAction
                                        access={true}
                                        className={"btn btn-sm btn-default btn-outline"}
                                        icon="ion-arrow-left-c"
                                        text={!props.chats ? 'Назад' : ''}
                                        handler={() => navigate(-1)}
                                        style={{ minWidth: `${props.chats ? '50px' : '90px'}` }}
                                    />
                                    <TabAction
                                        access={auth.role.interface === 1}
                                        className={"btn btn-sm btn-success btn-outline"}
                                        icon="ion-edit"
                                        text={!props.chats ? 'Править' : ''}
                                        handler={() => sqlCard.is_lead ? openModal('edit-lead') : openModal('edit-customer')}
                                        style={{ minWidth: `${props.chats ? '50px' : '90px'}` }}
                                    />
                                    <TabAction
                                        access={auth.role.interface === 1}
                                        className="dropdown btn-group no-padding"
                                        icon="ion-printer"
                                        dropdown={{
                                            buttonClass : 'btn btn-sm btn-info btn-outline dropdown-toggle',
                                            buttonStyle : {minWidth: `${props.chats ? '50px' : '90px'}`},
                                            text : `${!props.chats ? 'Отчеты' : ''}`,
                                            list : [
                                                { link : '#', text : 'Журнал посещений', icon : 'fa fa-file-text-o', handler : () => openModal('visit-log') }
                                            ]
                                        }}
                                    />
                                    <TabAction
                                        access={sqlCard.f_removed === 0 && auth.role.interface === 1}
                                        className={"btn btn-sm btn-danger btn-outline"}
                                        title={translate('В архив')}
                                        icon="fa fa-trash-o"
                                        text={!props.chats ? 'В архив' : ''}
                                        handler={() => openModal('archive-modal')}
                                        style={{ minWidth: `${props.chats ? '50px' : '90px'}` }}
                                    />
                                    <TabAction
                                        access={sqlCard.f_removed === 1 && auth.role.interface === 1}
                                        className={"btn btn-sm btn-primary btn-outline"}
                                        title={translate('Из архива')}
                                        icon="fa fa-reply"
                                        text={!props.chats ? 'Из архива' : ''}
                                        handler={() => buttonFromArchive()}
                                        style={{ minWidth: `${props.chats ? '50px' : '90px'}` }}
                                    />
                                </div>
                            </div>
                            <div className={props.chats ? "col-lg-6" : "col-lg-8"} style={{ position : 'initial' }}>
                                {/*COMMENT*/}
                                <div className="tabs-container m-b-sm ibox">
                                    {/*<ul className="nav nav-tabs" style={ auth.role.interface === 1 || (auth.role.interface === 2 && employeeSetting?.options.accessChat) ? { } : { display : 'none' } }>*/}
                                    {/*    <li className="active">*/}
                                    {/*        <a className="nav-bg-chats">*/}
                                    {/*            <span className="btn-group btn-group-xs" onClick={e => openMenu(e)}>*/}
                                    {/*                <i className="ion-chatboxes" />*/}
                                    {/*                <span className="caption"> { integration && integration.telegram.filter(item => item.id === chooseChat)[0]?.username } </span>*/}
                                    {/*                <span className="arrow_down btn dropdown-toggle" style={{ float : 'right' }}>*/}
                                    {/*                     <img*/}
                                    {/*                         src={arrow}*/}
                                    {/*                         style={{ verticalAlign : 'text-bottom', margin : 0 }}*/}
                                    {/*                         alt='arrow'*/}
                                    {/*                     />*/}
                                    {/*                </span>*/}
                                    {/*                <ul className="dropdown-menu">*/}
                                    {/*                    {*/}
                                    {/*                        integration && integration.telegram.map(item => (*/}
                                    {/*                            <li onClick={() => setChooseChat(item.id)}>*/}
                                    {/*                                <a> { item.username } <span className="text-muted small">({ item.name })</span> </a>*/}
                                    {/*                            </li>*/}
                                    {/*                        ))*/}
                                    {/*                    }*/}
                                    {/*                </ul>*/}
                                    {/*            </span>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*TAB CONTENT*/}
                                    <div className="tab-content">
                                        <Chat
                                            integration={integration}
                                            sqlCard={sqlCard}
                                            chatsSql={props.chatsSql}
                                            setChatsSql={props.setChatsSql}
                                            chooseChat={chooseChat}
                                            access={auth.role.interface === 1 || (auth.role.interface === 2 && employeeSetting?.options.accessChat)}
                                        />
                                    </div>
                                </div>
                                <div className="ibox float-e-margins m-b-sm">
                                    <div className="ibox-content border-bottom">
                                        {/*WIDGET*/}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h4 className="m-b-sm">
                                                    { translate('Виджет посещений') }
                                                    <small style={{ color: '#1D2129' }}> ({ lessonData.length } { translate('уроков') }) </small>
                                                    <span className="dropdown pull-right font-noraml m-l-md" onClick={e => openMenu(e)}>
                                                        <a className="dropdown-toggle crm-thin-link">
                                                            {
                                                                limitFilter ?
                                                                    <span className="text-muted sponge-bob-dd-limited">± {limitFilter} {translate('дней')}</span> :
                                                                    <span className="text-muted sponge-bob-dd-limited"> весь час </span>
                                                            }
                                                            <small className="caret m-b-xs" />
                                                        </a>
                                                        <ul className="dropdown-menu" style={{ marginLeft: '-90px' }}>
                                                            <li onClick={() => setLimitFilter(null)}>
                                                                <a> весь час </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(7)}>
                                                                <a> ±7 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(30)}>
                                                                <a> ±30 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(90)}>
                                                                <a> ±90 {translate('дней')} </a>
                                                            </li>
                                                            <li onClick={() => setLimitFilter(180)}>
                                                                <a> ±180 {translate('дней')} </a>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                    <span className="dropdown pull-right font-noraml m-l-md" onClick={e => openMenu(e)}>
                                                        <a className="dropdown-toggle crm-thin-link">
                                                            <span className="text-muted sponge-bob-dd-limited" style={{ textTransform: 'lowercase' }}>
                                                                {
                                                                    subjectFilter.length ?
                                                                        findObject(lessonSetting.subject, Number(subjectFilter))?.name :
                                                                        translate('Все')
                                                                }
                                                            </span>
                                                            <small className="caret m-b-xs" />
                                                        </a>
                                                        <ul className="dropdown-menu" style={{ marginLeft: '-90px' }}>
                                                            <li onClick={() => setSubjectFilter('')}>
                                                                <a> { translate('Все') } </a>
                                                            </li>
                                                            {
                                                                lessonSetting && lessonSetting.subject.map((item) => (
                                                                    <li onClick={() => setSubjectFilter(`${item.id}`)}>
                                                                        <a> { item.name } </a>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </span>
                                                    <div className="visible-xs m-t-sm" />
                                                </h4>
                                                <div className="clearfix" />
                                                {/*SPONGE BOB TABLE*/}
                                                <SpongeBob
                                                    lessonData={lessonData}
                                                    sqlCard={sqlCard}
                                                    groupData={groupData}
                                                    employeeData={employeeData}
                                                    customerData={customerData}
                                                    tariffData={customerTariff}
                                                    access={auth.role.interface}
                                                    chatView={props.chats}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CommentBlock
                                    sqlCard={sqlCard}
                                    managerData={managerData}
                                />
                                {
                                    auth.role.interface === 1 ? (
                                        <>
                                            <TaskBlock
                                                sqlCard={sqlCard}
                                                managerData={managerData}
                                                customerData={customerData}
                                                groupData={groupData}
                                            />
                                            <FileBlock
                                                sqlCard={sqlCard}
                                            />
                                        </>
                                    ) : null
                                }
                            </div>
                            <div className={props.chats ? "col-lg-3" : "col-lg-4"}>
                                <div className="ibox float-e-margins m-b-sm">
                                    <div className='ibox-content border-bottom'>
                                        {/* GENERAL INFO */}
                                        <GeneralBlock
                                          sqlCard={sqlCard}
                                          setSqlCard={setSqlCard}
                                          auth={auth}
                                        />
                                        {/* TOTAL BALANCE */}
                                        <TotalBalanceBlock
                                          sqlCard={sqlCard}
                                          customerTariff={customerTariff}
                                          access={auth.role.interface === 1}
                                        />
                                        {/* PAYS */}
                                        {
                                            auth.role.interface === 1 ? (
                                              <PayBlock
                                                customerData={customerData}
                                                sqlCard={sqlCard}
                                              />
                                            ) : null
                                        }
                                        {/* TASK */}
                                        <TaskInfoBlock
                                          sqlCard={sqlCard}
                                          customerData={customerData}
                                          groupData={groupData}
                                          access={auth.role.interface === 1}
                                        />
                                        {/* LESSON */}
                                        <LessonBlock
                                          sqlCard={sqlCard}
                                          lessonData={lessonData}
                                          groupData={groupData}
                                          employeeData={employeeData}
                                          customerData={customerData}
                                        />
                                        {
                                          (auth.role.interface === 1 && sqlCard.manager) && (
                                            <ManagerBlock
                                              sqlCard={sqlCard}
                                            />
                                          )
                                        }
                                        {/* EMPLOYEE */}
                                        <EmployeeBlock
                                          customerEmployee={customerEmployee}
                                          access={auth.role.interface === 1}
                                        />
                                        <div className='row'>
                                            <div
                                              className='col-xs-5 m-b-xs text-muted'> {translate('Дата рождения')} </div>
                                            <div className='col-xs-7 m-b-xs text-right'>
                                                {sqlCard.age ? sqlCard.age : <span
                                                  className='text-danger text-lowercase'> {translate('Не задано')} </span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div
                                              className='col-xs-5 m-b-xs text-muted'> {translate('Примечание')} </div>
                                            <div className='col-xs-7 m-b-xs text-right'>
                                                {sqlCard.note ? sqlCard.note : <span
                                                  className='text-danger text-lowercase'> {translate('Не задано')} </span>}
                                            </div>
                                        </div>
                                        {/* LEGAL */}
                                        {
                                          auth.role.interface === 1 && (
                                            <div className='row'>
                                                <div
                                                  className='col-xs-5 m-b-xs text-muted'> {translate('Заказчик')} </div>
                                                <div className='col-xs-7 m-b-xs text-right'>
                                                    {
                                                        sqlCard.legal ?
                                                          (<> <span className='text-muted'
                                                                    title='Тип клиента - Физ.лицо'> {translate('Физ.лицо')} </span> {sqlCard.legal} </>)
                                                          : <span
                                                            className='text-danger text-lowercase'> {translate('Не задано')} </span>
                                                    }
                                                </div>
                                            </div>
                                          )
                                        }
                                        {/* CONTACTS */}
                                        <ContactBlock
                                          contact={sqlCard.contact}
                                          access={auth.role.interface === 1}
                                        />
                                        {/* TELEGRAM */}
                                        <TelegramBlock
                                          sqlCard={sqlCard}
                                          integration={integration}
                                          access={auth.role.interface === 1}
                                        />
                                        {/* TARIFF */}
                                        <TariffBlock
                                          customerTariff={customerTariff}
                                          sqlCard={sqlCard}
                                          access={auth.role.interface === 1}
                                        />
                                        {/* DISCOUNT */}
                                        <DiscountBlock
                                          sqlCard={sqlCard}
                                          setSqlCard={setSqlCard}
                                          access={auth.role.interface === 1}
                                        />
                                        {/*MORE DETAIL*/}
                                        <hr className='hr-line-dashed m-t-sm m-b-sm' />
                                        {
                                            customSetting ?
                                              filterDisableEntity(customSetting.customField, 'customer').map(item => (
                                                <div className='row'>
                                                    <div
                                                      className='col-xs-5 text-muted m-b-xs font-bold'> {item.name} </div>
                                                    <div className='col-xs-7 m-b-xs text-right'>

                                                        {
                                                            sqlCard[item.systemName] ? (/\["/gi).test(sqlCard[item.systemName]) ? JSON.parse(sqlCard[item.systemName]).join(', ') : (item.type === '11' ?
                                                                <a
                                                                  href={sqlCard[item.systemName]}> {sqlCard[item.systemName]} </a> : sqlCard[item.systemName]) :
                                                              <span
                                                                className='text-danger text-lowercase'>{translate('Не задано')}</span>
                                                        }
                                                    </div>
                                                </div>
                                              ))
                                              : null
                                        }
                                        {/*Групи*/}
                                        <hr className='hr-line-dashed m-t-sm m-b-sm' />
                                        <GroupBlock
                                          customerGroup={customerGroup}
                                          groupData={groupData}
                                          sqlCard={sqlCard}
                                          setSqlCard={setSqlCard}
                                        />
                                        {/*Регулярные уроки*/}
                                        <hr className='hr-line-dashed m-t-sm m-b-sm' />
                                        <RegularLessonBlock
                                          sqlCard={sqlCard}
                                          customerGroup={customerGroup}
                                          groupData={groupData}
                                          employeeData={employeeData}
                                        />
                                        {/*Предмет и уровень*/}
                                        <hr className='hr-line-dashed m-t-sm m-b-sm' />
                                        <QualificationBlock
                                          sqlCard={sqlCard}
                                          setSqlCard={setSqlCard}
                                        />
                                        {/*Договоры*/}
                                        <hr className='hr-line-dashed m-t-sm m-b-sm hide' />
                                        <h4 className='no-padding m-b-xs m-t-xs hide'>
                                            <span className='text-muted'>{translate('Договоры')}</span>
                                            <a className='crm-dashed-link m-l-xl crm-modal-btn pull-right'>
                                                <span
                                                  className='text-lowercase font-noraml'>{translate('Добавить')}</span>
                                            </a>
                                        </h4>
                                        <div className='row hide'>
                                            <div className='col-sm-12'>
                                                <div className='text-danger alert-warning text-center p-xxs'>
                                                    ({translate('не задано')})
                                                </div>
                                            </div>
                                        </div>
                                        {/*Доступ в CRM*/}
                                        <UserBlock
                                          sqlCard={sqlCard}
                                          setSqlCard={setSqlCard}
                                          access={auth.role.interface === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*SWAP CHAT*/}
            <SwapChat
              customer_id={sqlCard.customer_id}
              chat_id={sqlCard.chat_id}
              customerData={customerData}
            />

            {/*ACCEPT MODAL*/}
            <AcceptModal />

            {/*EDIT CUSTOMER*/}
            <EditCustomer
                editCustomer={sqlCard}
                dataEmployee={employeeData}
                customerEmployee={customerEmployee}
                updateSql={setSqlCard}
                typeModal="card"
            />

            <EditLead
                dataEmployee={employeeData}
                editCustomer={sqlCard}
            />

            {/*ARCHIVE MODAL*/}
            <ArchiveModal
                customer_id={sqlCard.customer_id}
                name={sqlCard.name}
                page="card"
                sql={sqlCard}
                updateSql={setSqlCard}
                lead={sqlCard.is_lead}
            />

            {/* REPORT */}
            <VisitLog
                sqlCard={sqlCard}
            />
        </div>
    )
}

export const CustomerCard = React.memo(MemoCustomerCard);