import "../../Assets/css/Chats.css";
import {Link, useSearchParams} from "react-router-dom";
import {useTranslate} from "../../Hooks/translate.hook";

export default function ChatFeed (props) {

    // CUSTOMER ID START
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    // CUSTOMER ID END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // FUNC FEED START
    const getTime = (date) => new Date(date * 1000).toLocaleTimeString().slice(0, -3);

    function showChatsMenu (e, item) {
        e.preventDefault();

        if (e.button !== 2 ) return

        const scrolltop = (document.getElementById(props.block_id).scrollTop + e.pageY) - 50;

        props.setMenuStyle({
            right: `30px`,
            top: `${scrolltop-20}px`
        })

        props.setMenuData(item);

        const messageMenu = document.getElementById(props.menu_id);

        messageMenu.style.display = "block";

        window.onclick = function(event) {
            if (event.target !== messageMenu) {
                messageMenu.style.display = "none";
            }
        }
    }

    function renderText () {
        if (props.data.document) return translate('Документ');
        else if (props.data.photo) return translate('Изображение');
        else if (props.data.voice) return translate('Голосовое сообщение');
        else if (props.data.video_note) return translate('Видео сообщение');
        else if (props.data.text) return `${props.data.text.substring(0, 15)}${props.data.text.length > 15 ? '...' : ''}`;
    }

    function getRandomColor() {
        const colors = ['#FF5733', '#C70039', '#900C3F', '#581845', '#2E4053', '#138D75', '#28B463', '#2471A3', '#6A1B9A', '#A695E7', '#3DA1FD'];
        const randomIndex = Math.floor(Math.random() * colors.length);
        return { '--background-avatar-color': colors[randomIndex] };
    }
    // FUNC FEED END

    return (
        <div className={`feed-message-item ${+id === props.data.customer_id && 'feed-active'}`} onMouseDown={e => showChatsMenu(e, props.data)}>
            <Link to={`?id=${props.data.customer_id}`} className="notification-inner__navigate-link">
                <div className="notification-chat__non-select">
                    <div className="notification-chat__container-img">
                        <div className="notification-inner__avatar text-center">
                            <img
                                src={props.data.avatar}
                                alt={props.data.name[0]}
                                style={getRandomColor()}
                                className="chat-feed-avatar"
                            />
                        </div>
                    </div>
                </div>
                {/* MESSAGE */}
                <div className="notification-inner__container_text">
                    {/* TEXT */}
                    <div className="notification-inner__info_message">
                        {/* TITLE */}
                        <h2 className="notification-inner__title_message">
                            <span className="notification-inner__title_message_title">
                                { props.data.name }
                            </span>
                        </h2>
                        <span className="notification-inner__data_message">
                            { getTime(props.data.date) }
                        </span>
                    </div>
                    {/* MESSAGE TEXT */}
                    <span className="notification-inner__from__message">
                        <span className="notification-inner__from__message_height-h3">
                            { renderText() }
                            {
                                props.data.pinned ?
                                        <span className="notification-inner__title_message_pinned">
                                            <i
                                                className="ion-pin small"
                                                style={{ position : "absolute", marginLeft : "7px", transform : "rotate(27deg)" }}
                                            />
                                        </span>
                                    : null
                            }
                            {
                                !props.data.checked ?
                                        <span
                                            className="notification-inner__title_message_talk-id"
                                            style={ props.data.pinned ? { marginRight : "15px" } : {} }
                                        >
                                            NEW
                                        </span>
                                    : null
                            }
                        </span>
                    </span>
                </div>
            </Link>
        </div>
    )
}