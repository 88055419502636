import {useContext, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {closeModal, filterDisable, maxId} from "../../../../../Function/common";
import URL from "../../../../../Api/URL.json";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import shortid from "shortid";

export default function AddRoom () {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // CONTEXT START
    const {lessonSetting} = useContext(SettingContext);
    // CONTEXT END

    // STATE START
    const [location, setLocation] = useState('');
    const [name, setName] = useState('');
    const [longName, setLongName] = useState('');
    const [color, setColor] = useState('');
    const [is_custom, setIs_custom] = useState(false);
    // STATE END

    // ADD ROOM START
    async function addRoom (e) {
        e.preventDefault();

        if (!name.length) return errorNotify(`${translate('Необходимо заполнить')} "${translate('Краткое название')}"`)

        let room = {
            id: lessonSetting.room.length > 0 ? maxId(lessonSetting.room)+1 : 0,
            disabled: false,
            location, name, longName, color, is_custom
        }

        lessonSetting.room.push(room);
        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return error;
        window.location.reload();
    }
    // ADD ROOM END

    return (
        <div id="add-room" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addRoom(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить аудиторию')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*LOCATION*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="room-location_id">{translate('Локация')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => setLocation(e.target.value)}>
                                        <option value="" defaultChecked={true}>{translate('Не задано')}</option>
                                        {
                                            lessonSetting ? filterDisable(lessonSetting.location).map(item => (
                                                <option value={item.id}>
                                                    {item.name}
                                                </option>
                                            )) : null
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="room-name">{translate('Краткое название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Ауд 1')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*LONG NAME*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="room-note">{translate('Полное название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Аудитория № 101')}
                                        value={longName}
                                        onChange={e => setLongName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*COLOR*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Цвет в календаре')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="clear" onChange={e => setColor(e.target.value)}>
                                        {
                                            [
                                                "#1a7bb9", "#00aa00", "#9acfea", "#ffc6c6", "#ffcd70", "#4a4a4a", "#ea394c", "#b4b6b8", "#3C578C",
                                                "#ffff00", "#ebebeb", "#26d7d9", "#006600", "#c26629", "#9e0505", "#adff2f", "#00FF7F", "#2A8D9C",
                                                "#6600FF", "#FF00FF", "#D36E70", "#D2B48C", "#8E4585", "#4C244C", "#641349", "#20214F", "#7B68EE",
                                                "#46394B", "#98C793", "#1C6B72", "#1C6B72", "#30D5C8", "#34C924", "#1E90FF", "#4B0082", "#FF4D00",
                                                "#FF0033"
                                            ].map(item => (
                                                <label className="btn btn-xs pull-left" style={{marginBottom: "2px", border: `1px solid ${item}`, borderBottom: `5px solid ${item}`}} key={shortid.generate()}>
                                                    <input
                                                        type="radio"
                                                        name="room-color"
                                                        defaultChecked={item === color}
                                                        value={item}
                                                        disabled={is_custom}
                                                    />
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={e => setIs_custom(e.target.checked)}
                                        />
                                        &nbsp;{translate('Свой цвет')}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="color"
                                        className="form-control"
                                        id="custom-color"
                                        value={color}
                                        disabled={!is_custom}
                                        onChange={e => setColor(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}