// ROUTES
import {useRoutes} from "./routes";

// PACKAGE
import jwt_decode from "jwt-decode";

// HOOK
import {useAuth} from "./Hooks/auth.hook";
import {useEffect, useState} from "react";
import {useHttp} from "./Hooks/http.hook";

// CONTEXT
import {AuthContext} from "./Context/AuthContext";
import {SettingContext} from "./Context/SettingContext";

// JSON
import URL from './Api/URL.json';

// CSS
import './styles/global.css';
import './styles/resizableColumns.min.css';
import './styles/jquery-ui.min.css';
import './styles/sweetalert.css';
import './styles/fullcalendar.css';
import './styles/chosen.css';
import './App.css';

function App() {

    const { token, login, logout, userId, role, name, interface_language, systemId } = useAuth();
    const { request } = useHttp();

    const isAuthenticated = !!token;
    const routes = useRoutes(isAuthenticated, role);

    // STATE START
    const [settings, setSetting] = useState({});
    const [locale, setLocale] = useState('');
    // STATE END

    useEffect(() => {

        const unixTime = Math.floor((new Date().getTime())/1000);
        const userData = JSON.parse(localStorage.getItem('userData'));

        // CHECK EXP TIME
        if (userData && !Object.keys(userData).length) logout();
        if (userData && jwt_decode(userData.token).exp < unixTime) logout();

        async function fetchData () {
            const [ settings ] = await request(`${URL.back_end}/setting`, "GET");

            setSetting(settings);
            setLocale(JSON.parse(settings.basicSetting).baseOption.interfaceLanguage);
        }

        fetchData().catch(e => console.log(e))

    }, [request])

    return (
        <div className="App">
            <AuthContext.Provider value={{ token, login, logout, userId, isAuthenticated, role, name, interface_language, systemId }}>
                    <SettingContext.Provider value={{
                        basicSetting: settings.basicSetting ? JSON.parse(settings.basicSetting) : null,
                        customerSetting: settings.customerSetting ? JSON.parse(settings.customerSetting) : null,
                        salesSetting: settings.salesSetting ? JSON.parse(settings.salesSetting) : null,
                        lessonSetting: settings.lessonSetting ? JSON.parse(settings.lessonSetting) : null,
                        employeeSetting: settings.employeeSetting ? JSON.parse(settings.employeeSetting) : null,
                        paySetting: settings.paySetting ? JSON.parse(settings.paySetting) : null,
                        customSetting: settings.customSetting ? JSON.parse(settings.customSetting) : null,
                        integration: settings.integration ? JSON.parse(settings.integration) : null,
                        sms : settings.sms ? JSON.parse(settings.sms) : null,
                        pageSize: settings.pageSize ? JSON.parse(settings.pageSize) : null,
                        locale: locale ? locale : null,
                        currentTime : settings?.currentTime
                    }}>
                        { routes }
                    </SettingContext.Provider>
            </AuthContext.Provider>
        </div>
    );
}

export default App;
