import React, {useContext, useRef, useState} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {useHttp} from "../../../Hooks/http.hook";
import {useTranslate} from "../../../Hooks/translate.hook";
import URL from "../../../Api/URL.json";
import {ToastContainer} from "react-toastify";
import Gender from "../../../Components/Input/Gender";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {typeField} from "../../../Function/TypeField";
import {closeModal, filterDisable, filterDisableEntity, findObject, findRequired, getRequired, openMenu} from "../../../Function/common";
import {AuthContext} from "../../../Context/AuthContext";
import shortid from "shortid";
import Contact from "../../../Components/Input/Contact";
import {validate} from "../../../Function/validateForm";
import {useArchive} from "../../../Hooks/archive.hook";
import {InputChooseMultiArray} from "../../../Components/InputChoose/InputChooseMultiArray";
import {useNavigate} from "react-router-dom";

export default function AddLead (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const auth = useContext(AuthContext);
    const { customSetting, salesSetting, integration } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState(2);
    const [contact, setContact] = useState([{ type : 'mobile' }]);
    const [legal, setLegal] = useState(null);
    const [note, setNote] = useState('');
    const [source, setSource] = useState({});
    const [employee, setEmployee] = useState([]);
    const [branch, setBranch] = useState('');
    const [leadStatus, setLeadStatus] = useState(0);
    const [mainTags, setMainTags] = useState([]);
    // STATE END

    // HANDLER START
    function showAdditional (e) {
        e.preventDefault();

        let additional = document.querySelectorAll('.js-additional');
        let display = additional[0].style.display === "none";

        for (let i = 0; i < additional.length; i++) {
            if (display) {
                additional[i].style.display = "block";
            } else {
                additional[i].style.display = "none";
            }
        }
    }
    // HANDLER END

    // ADD CUSTOMER START
    async function addCustomer (e) {
        e.preventDefault();

        const leadNote = document.getElementById('lead_note').textContent;

        let validObj = {
            "ФИО" : !name.length,
            "Дата рождения" : findRequired(customSetting.requiredField, "age", "customer").require ? !age.length : null,
            "Контакт" : findRequired(customSetting.requiredField, "contact", "customer").require ? !contact.value : null,
            "Пол" : findRequired(customSetting.requiredField, "gender", "customer").require ? !gender.length : null,
            "Примечание" : findRequired(customSetting.requiredField, "note", "customer").require ? !note.length : null,
            "Отв. педагог" : findRequired(customSetting.requiredField, "employee", "customer").require ? !employee.length : null
        }

        filterDisableEntity(customSetting.customField, "customer").map(item => {
            validObj[item.name] = item.require ? !document.forms["add_lead"].elements[item.systemName].value : null
        })

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        let tags = {};

        filterDisable(salesSetting.leadTags).map(item => {
            tags[item.name] = document.forms['add_lead'].elements[item.name].value;
        })

        let customer = {
            name,
            age,
            gender,
            branch,
            contact: contact ? JSON.stringify(contact) : null,
            legal,
            note: leadNote,
            leadStatus,
            source: source.id,
            is_lead: 1,
            manager: auth.userId,
            tags : Object.keys(tags).length ? JSON.stringify(tags) : null,
            mainTags : mainTags.length ? JSON.stringify(mainTags) : null,
            f_removed : 0,
            c_date : `${new Date().toLocaleDateString('en-CA')}T${new Date().toLocaleTimeString()}Z`
        }

        filterDisableEntity(customSetting.customField, "customer").map(item => {
            let value = document.forms["add_lead"].elements[item.systemName].value;
            customer[item.systemName] = value ? value : null;
        })

        const { insertId } = await request(`${URL.back_end}/customer`, "POST", customer);
        await request(`${URL.back_end}/customer_employee/${insertId}`, 'POST', employee.map(item => item.employee_id));
        await pushArchive(1, 'customer', insertId, customer, null);

        if (error) return console.log(error);

        navigate(insertId ? `/company/1/customer/view?id=${insertId}` : `/company/1/lead/index/`);

        window.location.reload();
    }
    // ADD CUSTOMER END

    return (
        <div id="add-lead" className="modal in" ref={modal}>
            <ToastContainer />
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addCustomer(e)} name="add_lead">
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title">
                                { translate('Добавить лида') } —&nbsp;&nbsp;
                                <span className="dropdown" onClick={e => openMenu(e)}>
                                   <a className="dropdown-toggle crm-dashed-link text-lowercase" role="button">
                                       { leadStatus ? findObject(salesSetting.leadStatus, leadStatus).name : translate('Не разобрано') }
                                       <span className="caret" />
                                   </a>
                                   <ul className="dropdown-menu">
                                       <li onClick={() => setLeadStatus(0)}>
                                           <a> { translate('Не разобрано') } </a>
                                       </li>
                                       {
                                           salesSetting &&
                                               filterDisable(salesSetting.leadStatus).map(item => (
                                                   <li onClick={() => setLeadStatus(item.id)}>
                                                       <a> { item.name } </a>
                                                   </li>
                                               ))
                                       }
                                   </ul>
                               </span>
                            </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*ФИО*/}
                            <div className="form-group field-customer-name required">
                                <div className="col-sm-3">
                                    <label className="control-label"> {translate('ФИО')} </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                      type="text"
                                      id="customer-name"
                                      className="form-control"
                                      name="name"
                                      placeholder={translate('Например, Иванов Иван')}
                                      value={name}
                                      onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*Дата рождения + пол*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "age", "customer") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {translate('Дата рождения')}
                                    </label>
                                </div>
                                <div className="col-sm-3" style={{ paddingRight: 0 }}>
                                    <input
                                      type="date"
                                      className="form-control dob-input"
                                      value={age}
                                      onChange={e => setAge(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Gender setProps={setGender} />
                                    <label className="font-noraml pull-right m-t-xs m-r-sm"> {translate('Пол')} </label>
                                </div>
                            </div>
                            {/*Контакты*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "contact", "customer") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"> {translate('Контакты')} </label>
                                </div>
                                <div className="col-sm-9">
                                    <Contact
                                      setContact={setContact}
                                      contact={contact}
                                    />
                                </div>
                            </div>
                            {/*Заказчик*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> {translate('Заказчик')} </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                      type="text"
                                      id="customer-legal_name"
                                      className="form-control"
                                      placeholder={translate('Имя заказчика')}
                                      value={legal}
                                      onChange={e => {
                                          setLegal(e.target.value)
                                      }}
                                    />
                                </div>
                            </div>
                            {/*Примечание*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "note", "customer") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"
                                           htmlFor="customer-note">{translate('Примечание')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <span
                                      id="lead_note"
                                      className="form-control textarea"
                                      role="textbox"
                                      contentEditable="true"
                                      suppressContentEditableWarning={true}
                                    />
                                </div>
                            </div>
                            {/* Источник */}
                            <div className="form-group field-customer-lead_source_id">
                                <div className="col-sm-3">
                                    <label className="control-label"> {translate('Источник')} </label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseSingleObject
                                      sql={salesSetting ? filterDisable(salesSetting.leadSource) : []}
                                      setProps={setSource}
                                      topicSearch="name"
                                      type_id="id"
                                    />
                                </div>
                            </div>
                            {/*Отв. педагог*/}
                            <div
                              className={`form-group ${customSetting ? getRequired(customSetting.requiredField, "employee", "customer") : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"
                                           htmlFor="customer-teacher_ids">{translate('Отв. педагог')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseMultiObject
                                      sql={props.dataEmployee}
                                      type_id={"employee_id"}
                                      topicSearch="name"
                                      setProps={setEmployee}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        Бот за замовчуванням
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    {
                                        integration ?
                                          (
                                            <select className="form-control" onChange={e => setBranch(e.target.value)}>
                                                <option value=''> {translate('Не задано')} </option>
                                                {integration.telegram.map(item => <option
                                                  value={item.id}> {item.username} </option>)}
                                            </select>
                                          )
                                          : null
                                    }
                                </div>
                            </div>
                            {/*Дополнительные поля*/}
                            <div className="form-group">
                                <div className="col-sm-4"><b>{translate('Дополнительные поля')}</b></div>
                            </div>
                            {
                                customSetting ?
                                  filterDisableEntity(customSetting.customField, "customer").map(item => (
                                    <div className={`form-group ${item.require ? "required" : ""}`}
                                         key={shortid.generate()}>
                                        <div className="col-sm-3">
                                            <label className="control-label">{item.name}</label>
                                        </div>
                                        <div className="col-sm-9">
                                            {typeField(item)}
                                        </div>
                                    </div>
                                  ))
                                  : null
                            }
                            {/* MAIN TAGS */}
                            <div className="form-group">
                                <div className="col-sm-3"><b> {translate('Теги')} </b></div>
                                <div className="col-sm-9">
                                    <InputChooseMultiArray
                                      data={salesSetting ? filterDisable(salesSetting.tags).map(item => item.name) : []}
                                      getProps={mainTags}
                                      setProps={setMainTags}
                                    />
                                </div>
                            </div>
                            {/* LEAD TAGS */}
                            {
                                salesSetting ?
                                  filterDisable(salesSetting.leadTags).length ? (
                                    <>
                                        <a className="pull-right crm-dashed-link m-l-sm text-lowercase"
                                           onClick={e => showAdditional(e)}>
                                            {translate('Показать')} ⇳
                                        </a>
                                        <div className="form-group">
                                            <div className="col-sm-4"><b> {translate('Теги статистики')} </b></div>
                                        </div>
                                    </>
                                  ) : null
                                  : null
                            }
                            {
                                salesSetting ?
                                  filterDisable(salesSetting.leadTags).map(item => (
                                    <div className="form-group js-additional" style={{ display: "none" }}>
                                        <div className="col-sm-3">
                                            <label className="control-label"> {item.name} </label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                              type="text"
                                              className="form-control"
                                              name={item.name}
                                            />
                                        </div>
                                    </div>
                                  ))
                                  : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white"
                                    onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}