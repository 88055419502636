import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import iconCalendar from "../../../Assets/data/JSON/iconCalendar.json";
import typeLessons from "../../../Assets/data/JSON/typeLessons.json";
import {useArchive} from "../../../Hooks/archive.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {
    filterDisable,
    filterDisableEntity,
    closeModal,
    findRequired,
    getRequired,
    addMinutesToTime
} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {validate} from "../../../Function/validateForm";
import moment from "moment";

export default function AddLesson (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, customSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [time, setTime] = useState('');
    const [minute, setMinute] = useState('');
    const [audience, setAudience] = useState({});
    const [group, setGroup] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [subject, setSubject] = useState({});
    const [employee, setEmployee] = useState([]);
    const [l_note, setNote] = useState('');
    // STATE END

    useEffect(() => {
        if (props.customer) setCustomer([props.customer])
        if (props.group) setGroup([props.group])
    }, [props.customer, props.group])

    // MODAL FUNC START
    function dateEndHandler() {
        let time = document.getElementById(props.id ? props.id+'time' : 'time').value;
        let minute = document.getElementById(props.id ? props.id+'minute' : 'minute').value;

        if (!time || !minute) return

        const lessonDate = dateStart.split('T')[0];

        return `${lessonDate}T${addMinutesToTime(time, Number(minute))}:00Z`;
    }

    function getTimeEnd (time, minute) {
        if (!time.length || !minute.length) return null;

        let hours = +time.split(":")[0];
        let minutes = Number(time.split(":")[1]) + Number(minute);

        const newDate = moment().tz('Europe/Kiev').toDate();
        newDate.setHours(+hours);
        newDate.setMinutes(minutes);

        return newDate.toLocaleTimeString().slice(0,5);
    }

    function getDiapasonDate(start, end) {
        for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt).toLocaleDateString());
        }
        return arr;
    }
    // MODAL FUNC END

    // ADD LESSON START
    async function addLesson(e) {
        e.preventDefault();

        const date_end = dateEndHandler();
        const noteValue = document.getElementById('l_note').innerText;

        const validity = {
            "Дата" : !dateStart.length,
            "Время" : !date_end,
            "Предмет" : !Object.keys(subject).length,
            "Клиенты" : props.type_lesson === 1 ? !customer.length : false,
            "Группа" : props.type_lesson === 2 ? !group.length : false,
            "Комментарий" : findRequired(customSetting.requiredField, 'l_note', 'lesson').require ? !noteValue : noteValue.length > 1000,
            "Педагог" : findRequired(customSetting.requiredField, 'employee', 'lesson').require ? !employee.length : false
        }

        if (validate(validity, translate)) return

        btn_submit.current.disabled = true;

        const time = document.getElementById(props.id ? props.id + 'time' : 'time').value;
        const date_start = `${dateStart}T${time}:00Z`;

        let lesson = {
            date_start: date_start,
            date_end: date_end,
            audience: Object.keys(audience).length > 0 ? audience.id : -1,
            subject: subject.id,
            type_lesson: props.type_lesson,
            l_note: noteValue
        }

        filterDisableEntity(customSetting.customField, "lesson").map(item => {
            lesson[item.systemName] = document.forms["add_lesson"].elements[item.systemName].value
        })

        const { insertId } = await request(`${URL.back_end}/lesson`, 'POST', lesson);

        if (props.type_lesson === 2) {
            const customers = await request(`${URL.back_end}/group_customer/${group[0].group_id}`, 'GET');
            await request(`${URL.back_end}/lesson_customer/${insertId}`, 'POST', customers.map(item => getDiapasonDate(item.date_start, item.date_end).includes(new Date(date_start).toLocaleDateString()) && item.customer_id));
        }

        if (group.length) await request(`${URL.back_end}/lesson_group/${insertId}`, 'POST', group.map(item => item.group_id));
        if (employee.length) await request(`${URL.back_end}/lesson_employee/${insertId}`, 'POST', employee.map(item => item.employee_id))
        if (customer.length) await request(`${URL.back_end}/lesson_customer/${insertId}`, 'POST', customer.map(item => item.customer_id))

        if (error) return console.log(error);

        await pushArchive(1, 'lesson', insertId, lesson, null);

        window.location.reload();
    }
    // ADD LESSON END

    return (
        <div id={props.id ? props.id : "add-lesson"} className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title"><i className={iconCalendar[props.type_lesson]} /> {translate(typeLessons[props.type_lesson])} — {translate('запланировать')} </h4>
                    </div>
                    <form className="form-horizontal" name="add_lesson" onSubmit={e => addLesson(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*DATE START*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Дата')}</label>
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="form-control"
                                        autoComplete="off"
                                        value={dateStart}
                                        onChange={e => setDateStart(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-3 no-padding">
                                    <div className="m-t-xs">
                                        <a>
                                            <big>
                                                <i className="ion-help-circled" />
                                            </big>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*DATE END*/}
                            <div className="form-group required">
                                <label className="col-sm-3 control-label" style={{textAlign: "left"}}>{translate('Время')}</label>
                                <div className="col-sm-3">
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('с') }&nbsp; </span>
                                        <input
                                            type="time"
                                            id={props.id ? props.id + "time" : "time"}
                                            className="form-control time-input time-from"
                                            name="time"
                                            value={time}
                                            onChange={e => setTime(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="input-group">
                                        <span className="input-group-addon">{translate('мин')}</span>
                                        <input
                                            type="number"
                                            className="form-control duration-input hide-arrows"
                                            id={props.id ? props.id+"minute" : "minute"}
                                            name="minute"
                                            step="1"
                                            min="0"
                                            max="1000"
                                            value={minute}
                                            onChange={e => setMinute(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {
                                    getTimeEnd(time, minute) ?
                                        (
                                            <div className="col-sm-3 text-muted" style={{paddingTop: "8px"}}>
                                                {translate('До')} <span className="time-to">{getTimeEnd(time,minute)}</span>
                                            </div>
                                        )
                                        : null
                                }
                            </div>
                            {/*AUDIENCE*/}
                            <div className="form-group room-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Аудитория')}</label>
                                </div>
                                <div className="col-sm-6 spinable">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.room) : []}
                                        setProps={setAudience}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                                <div className="col-sm-3 text-muted info" style={{paddingTop: "8px"}}>
                                    <span>{lessonSetting ? filterDisable(lessonSetting.room).length : 0}</span> {translate('доступно')}
                                </div>
                            </div>
                            {/*GROUP*/}
                            {
                                props.type_lesson === 2 ?
                                    (
                                        <div className="form-group required">
                                            <div className="col-sm-3">
                                                <label className="control-label"> { translate('Группа') } </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <InputChooseMultiObject
                                                    sql={props.groupData.filter(item => item.f_removed === 0)}
                                                    setProps={setGroup}
                                                    value={group}
                                                    topicSearch="title"
                                                    type_id="group_id"
                                                />
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <div className="form-group required">
                                            <div className="col-sm-3">
                                                <label className="control-label"> { translate('Клиенты') } </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <InputChooseMultiObject
                                                    sql={props.customerData.filter(item => item.f_removed === 0)}
                                                    setProps={setCustomer}
                                                    value={customer}
                                                    limit={props.type_lesson === 1 && 1}
                                                    limitError={props.type_lesson === 1 && "Перевищення ліміту участників"}
                                                    topicSearch="name"
                                                    type_id="customer_id"
                                                />
                                            </div>
                                        </div>
                                    )
                            }
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Предмет') } </label>
                                </div>
                                <div className="col-sm-6">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.subject) : []}
                                        setProps={setSubject}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'employee', 'lesson') : ''}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Педагог(и)') } </label>
                                </div>
                                <div className="col-sm-6">
                                    <InputChooseMultiObject
                                        sql={props.employeeData.filter(item => item.f_removed === 0)}
                                        setProps={setEmployee}
                                        topicSearch="name"
                                        type_id="employee_id"
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'l_note', 'lesson') : ''}`}>
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Комментарий') } </label>
                                </div>
                                <div className="col-sm-6">
                                    <span
                                        id="l_note"
                                        className="form-control textarea"
                                        role="textbox"
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                    />
                                    <div className="help-block text-danger">
                                        { l_note.length > 1000 && 'Введіть коментар не більше 1000 символів' }
                                    </div>
                                </div>
                            </div>
                            {/*CUSTOM FIELD*/}
                            {
                                customSetting &&
                                    filterDisableEntity(customSetting.customField, "lesson").map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label"> { item.name } </label>
                                            </div>
                                            <div className="col-sm-6">
                                                { typeField(item) }
                                            </div>
                                            <div className="col-sm-offset-3 col-sm-9" />
                                        </div>
                                    ))
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}