import {memo, useEffect, useState} from "react";
import {openModal} from "../../../../Function/common";
import CustomerRow from "../Row/CustomerRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddCustomer from "../Modal/Customer/AddCustomer";
import EditCustomer from "../Modal/Customer/EditCustomer";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export const CustomerBlock = memo(function ({ sqlCard, regularLesson, dataCustomer, groupLesson }) {

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ editCustomer, setEditCustomer ] = useState({});
    const [ groupCustomer, setGroupCustomer ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!sqlCard.group_id) return

        async function fetchData () {
            const customers = await request(`${URL.back_end}/group_customer/${sqlCard.group_id}`, 'GET');

            setGroupCustomer(customers.filter(item => item.f_removed === 0));
        }

        fetchData().catch(e => console.log(e))

    }, [request, sqlCard])
    // INIT DATA END

    return (
        <>
            <div className="row no-padding m-b-sm m-t-xs">
                <div className="col-xs-9 col-lg-10">
                    <strong className="text-muted pull-left">
                        {translate('Участники')}&nbsp;
                        { sqlCard.limit && <>({ groupCustomer.length }/<span className={groupCustomer.length > sqlCard.limit && 'text-warning'}>{ sqlCard.limit }</span>)</> }
                    </strong>
                </div>
                <div className="col-xs-3 col-lg-2">
                    {
                        sqlCard.f_removed === 0 ? (
                            <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal('add-customer')}>
                                <span className="text-lowercase">{translate('Добавить')}</span>
                            </a>
                        ) : null
                    }
                </div>
            </div>
            <div>
                {
                    groupCustomer.length ?
                        groupCustomer.map((item, index) => (
                            <CustomerRow
                                data={item}
                                index={index+1}
                                setEditCustomer={setEditCustomer}
                            />
                        )) :
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="text-danger alert-warning text-center p-xxs">
                                    ({translate('не задано')})
                                </div>
                            </div>
                        </div>
                }
            </div>
            <div className={`text-right ${!groupCustomer.length && 'none'}`}>
                <a className="text-right" href={`/company/1/customer/index/%7B"group":"${sqlCard.group_id}"%7D`} target="_blank">
                    <i className="ion-arrow-up-c m-r-xs" />{translate('Открыть в списке')}
                </a>
            </div>

            <AddCustomer
                sql={sqlCard}
                regularLesson={regularLesson}
                dataCustomer={dataCustomer}
                groupCustomer={groupCustomer}
            />

            <EditCustomer
                sql={sqlCard}
                regularLesson={regularLesson}
                dataCustomer={dataCustomer}
                editCustomer={editCustomer}
                groupLesson={groupLesson}
            />
        </>
    )
})