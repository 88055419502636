import {Link} from "react-router-dom";
import EmployeeColumns from "./EmployeeColumns";
import {useTranslate} from "../../../Hooks/translate.hook";
import React, {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity} from "../../../Function/common";
import shortid from "shortid";
import URL from "../../../Api/URL.json";
import {useHttp} from "../../../Hooks/http.hook";

export default function EmployeeTable (props) {

    // CONTEXT START
    const { customSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ groupsData, setGroupsData ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {
            const groups = await request(`${URL.back_end}/employee_group`, 'GET');

            if (error) throw error;

            setGroupsData(groups);
        }

        fetchData().catch(e => console.log(e))
    }, [])
    // INIT DATA END

    // EMPLOYEE TABLE FUNC START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;

        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            props.updateRows(tempSelection);
        }
        else {
            selection.forEach(element => element.checked = false);
            props.updateRows([])
        }
    }

    function renderTbody () {

        if (props.loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (props.sql.length) {
            return props.sql.map(item => (
                <EmployeeColumns
                    data={item}
                    sql={props.sql}
                    groupsData={groupsData}
                    updateSql={props.updateSql}
                    updateRows={props.updateRows}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // EMPLOYEE TABLE FUNC END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.98%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection_all"
                                onClick={e => selection_all(e)}
                            />
                        </th>
                        <th style={{width: "9.36%"}}>
                            <Link to="/company/1/employee/index?sort=-name">
                                {translate('ФИО')}
                            </Link>
                        </th>
                        <th style={{ width : '10%' }}>
                            <Link to="/company/1/employee/index?sort=-name">
                                { translate('Предметы') }
                            </Link>
                        </th>
                        <th style={{width : "10%"}}>
                            <a> { translate('Группы') } </a>
                        </th>
                        <th style={{width : "15.68%"}}>
                            <Link to="/company/1/employee/index?sort=contact">
                                {translate('Контакты')}
                            </Link>
                        </th>
                        <th style={{width : '10%'}}>
                            <a> { translate('Статус') } </a>
                        </th>
                        <th style={{ width : '5%' }}>
                            { translate('Комментарий') }
                        </th>
                        {/*<th style={{width : "13.15%"}}>*/}
                        {/*    <Link to="/company/1/employee/index?sort=note">*/}
                        {/*        {translate('Примечание')}*/}
                        {/*    </Link>*/}
                        {/*</th>*/}
                        {
                            customSetting ?
                                filterDisableEntity(customSetting.customField, "employee").map(item => (
                                    <th style={{width : "10%"}} key={shortid.generate()}>
                                        <Link to={`/company/1/employee/index?sort=${item.name}`}>
                                            {item.name}
                                        </Link>
                                    </th>
                                ))
                                : null
                        }
                        <th style={{width : "2.28%"}}>
                            &nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody> { renderTbody() } </tbody>
            </table>
        </div>
    )
}