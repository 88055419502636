import { useRef, useState } from "react";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import { closeModal } from "../../../../../Function/common";
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";

export default function PayrollStatement ({ sqlCard, employeeData }) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [ employee, setEmployee ] = useState({});

    const [ date_start, setDateStart ] = useState(() => {
        let date = new Date();
        const result_date = new Date(date.getFullYear(), date.getMonth(), 1);
        return moment(result_date).format('YYYY-MM-DD');
    });

    const [ date_end, setDateEnd ] = useState(() => {
        let date = new Date();
        const result_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return moment(result_date).format('YYYY-MM-DD');
    });
    // STATE END

    // HANDLER START
    function handlerDate (date, setDate, value, bool) {
        let newDate = new Date(date);

        setDate(moment(new Date(newDate.getFullYear(), newDate.getMonth() + value, bool)).format('YYYY-MM-DD'));
    }
    // HANDLER END

    // PAYROLL START
    function payrollStatement (e) {

        e.preventDefault();

        const obj = {
            employee_id : employeeData ? employee.employee_id : sqlCard.employee_id,
            date_start, date_end
        }

        navigate(`/company/1/employee/view/${encodeURI(JSON.stringify(obj))}?report=payrollStatement`);
        closeModal(e, modal);
    }
    // PAYROLL END

    return (
        <div id='payroll-statement' className='modal in' ref={modal}>
            <div className='modal-dialog'>
                <div className='modal-content common-modal-content'>
                    <form className='form-horizontal' onSubmit={e => payrollStatement(e)}>
                        <div className='modal-header'>
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className='modal-title'> { translate('Выписка по зарплате') } </h4>
                        </div>
                        <div className='modal-body p-md'>
                            {/* EMPLOYEE */}
                            <div className='form-group'>
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Педагог') } </label>
                                </div>
                                <div className="col-sm-7">
                                    {
                                        employeeData ? (
                                            <InputChooseSingleObject
                                                sql={employeeData}
                                                setProps={setEmployee}
                                                topicSearch="name"
                                                type_id="employee_id"
                                            />
                                        ) : (
                                            <span className="form-control input-sm text-muted"> { sqlCard.name } </span>
                                        )
                                    }
                                </div>
                            </div>
                            {/* DATE START */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Начало периода') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-addon" onClick={() => handlerDate(date_start, setDateStart, -1, 1)}>
                                            <a href="#"> M- </a>
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_start}
                                            onChange={e => setDateStart(e.target.value)}
                                        />
                                        <span className="input-group-addon" onClick={() => handlerDate(date_start, setDateStart, 1, 1)}>
                                            <a href="#"> M+ </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* DATE END */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Конец периода') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-addon" onClick={() => handlerDate(date_end, setDateEnd, 0, 0)}>
                                            <a href="#"> M- </a>
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_end}
                                            onChange={e => setDateEnd(e.target.value)}
                                        />
                                        <span className="input-group-addon" onClick={() => handlerDate(date_end, setDateEnd, 2, 0)}>
                                            <a href="#"> M+ </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-info">
                                <i className="fa fa-check" /> { translate('Сформировать') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}