import React, {memo, useEffect, useState} from "react";
import {openModal} from "../../../../Function/common";
import UserRow from "../Row/UserRow";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddUser from "../Modal/User/AddUser";
import EditUser from "../Modal/User/EditUser";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export const UserBlock = memo(function ({ sqlCard, setSqlCard, access }) {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ accounts, setAccounts ]   = useState([]);
    const [ editUser, setEditUser ] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!Object.keys(sqlCard).length) return

        async function fetchData () {

            const account = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 3, systemId : sqlCard.customer_id } })

            if (error) throw error

            setAccounts(account.users);

        }

        fetchData().catch(e => console.log(e))

    }, [ sqlCard ])
    // INIT DATA END

    if (!access) return

    return (
        <>
            <hr className="hr-line-dashed m-t-sm m-b-sm" />
            <h4 className="no-padding m-b-xs m-t-xs">
                <span className="text-muted">{ translate('Доступ в CRM') }</span>
                <a className="crm-dashed-link m-l-xl crm-modal-btn pull-right" onClick={() => openModal("add-user")}>
                    <span className="text-lowercase font-noraml">{ translate('Предоставить') }</span>
                </a>
            </h4>
            {
                accounts.length ?
                    accounts.map(item => (
                        <UserRow
                            data={item}
                            setEditUser={setEditUser}
                        />
                    )) :
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-danger alert-warning text-center p-xxs">
                                ({ translate('не задано') })
                            </div>
                        </div>
                    </div>
            }

            {/*USER*/}
            <AddUser
                sql={sqlCard}
                updateSql={setSqlCard}
            />

            <EditUser
                sql={sqlCard}
                updateSql={setSqlCard}
                editUser={editUser}
            />
        </>
    )
})