import {findObject, openModal} from "../../../../Function/common";
import {useContext} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import typeLessons from "../../../../Assets/data/JSON/typeLessons.json";
import currency from "../../../../Assets/data/JSON/currency.json";
import {useTranslate} from "../../../../Hooks/translate.hook";

export default function SalaryRow ({ data, setEditSalary }) {

    // CONTEXT START
    const { lessonSetting, basicSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function renderInfo () {
        if (!lessonSetting) return null;

        const type_lessons = JSON.parse(data.type_lessons);
        const lessons      = JSON.parse(data.lessons);

        return type_lessons.map(item => translate(typeLessons[item])).join(' / ') + (type_lessons.length && ' / ') + lessons.map(item => {
            item = findObject(lessonSetting.subject, item)?.name;
            return item;
        }).join(' / ');
    }

    function editSalaryHandler (e) {
        e.preventDefault();
        openModal('edit-salary');
        setEditSalary(data);
    }

    return (
        <div className="row">
            <a className="col-sm-12 p-xxs crm-hover-block crm-modal-btn" onClick={e => editSalaryHandler(e)}>
                <i className="ion-edit pull-right" />
                <strong className="text-success small rates-strong" title={renderInfo()}>
                    { data.lesson_duration && (<><i className="ion-clock" /> { data.lesson_duration }: </>) }
                    { renderInfo() }
                </strong>
                <span style={{overflow: "hidden", display: "inline-block"}}>&nbsp;— { data.amount } { basicSetting && currency[basicSetting.baseOption.currency] } </span>
            </a>
        </div>
    )
}