import React, {memo, useState} from "react";
import {openModal} from "../../../../Function/common";
import {useTranslate} from "../../../../Hooks/translate.hook";
import URL from "../../../../Api/URL.json";
import {useHttp} from "../../../../Hooks/http.hook";
import {TurboSms} from "../Modal/TurboSms";

export const TelegramBlock = memo(({ sqlCard, integration, access }) => {

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ chooseLink, setChooseLink ] = useState(0);
    // STATE END

    // VALUE START
    const crypto = ['a', 'q', 'w', 'R', 'o', 'k', 'L', 's', 'm', 'N'];
    // VALUE END

    // FUNC START
    async function deleteChat () {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Відключивши клієнта від бота, історія чату буде втрачена. Продовжити?')) return

        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', { chat_id : null })
        window.location.reload();
    }

    async function handlerChat (archive) {
        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', { archive })
        window.location.reload();
    }
    // FUNC END

    if (!access) return

    return (
         <>
             {
                 integration &&
                     (
                         <div className="row">
                             <div className="col-xs-5 m-b-sm text-muted"> { translate('Телеграм') } </div>
                             <div className="col-xs-7 m-b-xs text-right">
                                 {
                                     sqlCard.chat_id ?
                                         (
                                             <div>
                                                 {/*{*/}
                                                 {/*    sqlCard.archive ?*/}
                                                 {/*        (*/}
                                                 {/*            <>*/}
                                                 {/*                <span className="text-danger"> { translate('Архив') } </span>*/}
                                                 {/*                <i className="fa fa-reply text-navy" style={{ cursor: 'pointer', fontSize : '10px' }} title={translate('Вернуть из архива')} onClick={() => handlerChat(0)} />*/}
                                                 {/*            </>*/}
                                                 {/*        )*/}
                                                 {/*        : (*/}
                                                 {/*            <>*/}
                                                 {/*                <span className="text-navy"> { translate('Активно') } </span>*/}
                                                 {/*                <i className="fa fa-trash-o text-danger" style={{ cursor: 'pointer' }} title={translate('Архивировать')} onClick={() => handlerChat(1)} />*/}
                                                 {/*            </>*/}
                                                 {/*        )*/}
                                                 {/*}*/}
                                                 <span className="text-navy"> { translate('Активно') } </span>
                                                 <i className="fa fa-trash-o text-danger" style={{ cursor: 'pointer' }} title={translate('Архивировать')} onClick={() => deleteChat()} />
                                             </div>
                                         )
                                         :
                                         integration.telegram.map(item => (
                                             <>
                                                 <a> t.me/{item.username}?start={`${sqlCard.customer_id}`.split('').map(item => crypto[+item])} </a>
                                                 <a title="Отправить сообщение через TurboSms" onClick={() => { openModal('send-turbo'); setChooseLink(item.id) }}>
                                                     <i className="ion-chatbox m-l-xxs" />
                                                 </a>
                                             </>
                                         ))
                                 }
                             </div>
                         </div>
                     )
             }

             <TurboSms
                 sqlCard={sqlCard}
                 chooseLink={chooseLink}
             />
         </>
    )
})