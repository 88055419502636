import {useRef, useState} from "react";
import {smoothScroll} from "../../../../Function/ScrollTo";
import {useTranslate} from "../../../../Hooks/translate.hook";

export default function PinBlock ({ message }) {

    // REF START
    const indicator = useRef();
    const clipCurrent = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ currMessage, setCurrMessage ] = useState(message[ message.length - 1 ]);
    // STATE END

    function scrollToMessage () {
        const messageBlock = document.querySelector(`.feed-note[data-id="${currMessage.id}"]`);

        smoothScroll(document.getElementById('scroll-chat'), messageBlock, 300);

        if (message.length !== 1) {
            const index = message.findIndex((element) => {
                return element.id === currMessage.id;
            });

            const result_arr = message.slice(0, index);

            if (!result_arr.length) {
                setCurrMessage(message[ message.length - 1 ])
            } else {
                setCurrMessage(result_arr[ result_arr.length - 1 ])
            }
        }
    }

    function renderSvg () {

        const length = message.length;
        let d = '';

        if (!indicator.current) return null;

        if (length === 1) {
            indicator.current.style.clipPath = 'url("#clipPath1")';
            // clipCurrent.current.style.height = '40px';
            // clipCurrent.current.style.transform = 'translateY(0px)';
            return null;
        } else {
            indicator.current.style.clipPath = 'url("#clipPath2")';
        }

        switch (length) {
            case 2 : {
                d = 'M0,1a1,1,0,0,1,2,0v15a1,1,0,0,1,-2,0ZM0,20a1,1,0,0,1,2,0v15a1,1,0,0,1,-2,0Z';
                // clipCurrent.current.style.height = '19px';
                // clipCurrent.current.style.transform = 'translateY(20px)';
                break;
            }
            case 3 : {
                d = 'M0,1a1,1,0,0,1,2,0v9a1,1,0,0,1,-2,0ZM0,13a1,1,0,0,1,2,0v10a1,1,0,0,1,-2,0ZM0,26a1,1,0,0,1,2,0v9a1,1,0,0,1,-2,0Z';
                // clipCurrent.current.style.height = '11px';
                // clipCurrent.current.style.transform = 'translateY(25px)';
                break;
            }
            case 4 : {
                d = 'M0,1a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0ZM0,10.5a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0ZM0,20a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0ZM0,29.5a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0Z';
                // clipCurrent.current.style.height = '13px';
                // clipCurrent.current.style.transform = 'translateY(28px)';
                break;
            }
            default : {
                if (length > 3) {
                    // clipCurrent.current.style.height = '13px';
                    // clipCurrent.current.style.transform = 'translateY(28px)';
                    d = 'M0,1a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0ZM0,10.5a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0ZM0,20a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0ZM0,29.5a1,1,0,0,1,2,0v5.5a1,1,0,0,1,-2,0Z';
                }
                break;
            }
        }

        return (
            <svg height="0" width="0">
                <defs>
                    <clipPath id="clipPath2">
                        <path d={d}/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    function renderText () {

        if (currMessage.text) return <span> { currMessage.text } </span>
        else if (currMessage.photo) return <span> { translate('Изображение') } </span>
        else if (currMessage.document) {
            const { originalName } = JSON.parse(currMessage.document);
            return <span> { originalName } </span>
        }
    }

    return (
        <div className="ripple-wrapper" onClick={ () => scrollToMessage() }>
            <div className="ripple-indicator-wrapper">
                <div className="ripple-indicator" ref={indicator}>
                    <span>
                        { renderSvg() }
                    </span>
                    {/*<div className="clip-current" id="clip-current" ref={clipCurrent} />*/}
                </div>
            </div>
            <div className="ripple-container">
                <div className="ripple-message-name">
                    <span> Pinned Message </span>
                </div>
                <div className="ripple-message">
                    <div>
                        <p className="ripple-message-text">
                            <span> { renderText() } </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}