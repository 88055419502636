// PACKAGE
import jwt_decode from "jwt-decode";

// COMPONENT
import TabHeader from "../../Components/TabHeader";
import TabAction from "../../Components/TabAction";
import SideMenu from "../../Components/SideMenu/SideMenu";
import {CustomerTable} from "./Table/CustomerTable";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import { ToastContainer } from 'react-toastify';

// HOOK
import {useContext, useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";

// JSON
import URL from '../../Api/URL.json';

// MODAL
import {AddCustomer} from "./Modal/AddCustomer";
import AcceptModal from "../../Components/Modal/AcceptModal";
import EditCustomer from "./Modal/EditCustomer";
import FilterCustomer from "./Modal/FilterCustomer";

// FUNCTION
import { openModal, filterDisable, pagination } from "../../Function/common";

// CONTEXT
import {SettingContext} from "../../Context/SettingContext";
import {AuthContext} from "../../Context/AuthContext";
import ImportModal from "../../Components/Modal/ImportModal/ImportModal";


export default function Customer () {

    // CONTEXT START
    const { customSetting, customerSetting, salesSetting, pageSize } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { error, request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [sql, setSql] = useState([]);
    const [counts, setCounts] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editCustomer, setEditCustomer] = useState({});

        // DATA
    const [dataEmployee, setDataEmployee] = useState([]);
    const [dataGroup, setDataGroup] = useState([]);
    const [managerData, setManagerData] = useState([]);
    const [loading, setLoading] = useState(true);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!auth.role.interface || !pageSize) return

        const params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            let data;

            if (auth.role.interface === 2) {
                const employee_id = +jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;
                data = await request(`${URL.back_end}/customer_filter`, 'POST', { page, limit : pageSize.customer, data : { is_lead : 0, f_removed : 0, employee_interface : employee_id, ...params } });
            } else {
                data = await request(`${URL.back_end}/customer_filter`, 'POST', { page, limit : pageSize.customer, data : { is_lead : 0, f_removed : 0, ...params } });
            }

            if (error) throw error;

            setSql(data.customers);
            setCounts(data.counts);
            setLoading(false);
        }

        fetchData().catch(e => console.log(e))
    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {

            const employees = await request(`${URL.back_end}/employee`, 'GET');
            const groups = await request(`${URL.back_end}/group`, 'GET');
            const managers = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1, f_removed : 0 } });

            if (error) throw error;

            setDataEmployee(employees);
            setDataGroup(groups);
            setManagerData(managers.users);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // BUTTON FUNC START
    function buttonEdit () {
        if (selectedRows.length === 1) {
            openModal('edit-customer');
            setEditCustomer(sql.filter(item => item.customer_id === +selectedRows[0])[0])
        }
    }
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            <ToastContainer />
            {/*SIDE MENU*/}
            <SideMenu page="customer" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: '624px' }}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/* ACTIONS */}
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-success"
                                    title={translate('Добавить клиента')}
                                    handler={() => openModal('add-customer')}
                                    icon='ion-plus'
                                    text='Добавить'
                                />
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-success"
                                    disable={selectedRows.length <= 0}
                                    title={translate('Изменить клиента')}
                                    handler={() => buttonEdit()}
                                    icon='ion-edit'
                                    text='Править'
                                />
                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon='ion-printer'*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-info btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'Кто сегодня оплатит?', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Предотвращение потери', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Список посещающих за период', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Приостановки за период', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Договоры за период', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Причины потери клиентов', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Сверка по клиентам', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Прогноз оплаты', icon : 'fa fa-file-text-o' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="dropdown btn-group no-padding"
                                    icon='fa fa-ellipsis-h'
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',
                                        text : 'Другое',
                                        list : [
                                            // { link : '/company/1/pause/index', text : 'Приостановки', icon : 'fa fa-fw ion-ios-pause' },
                                            // { link : '/company/1/discount/index', text : 'Управление скидками', icon : 'fa fa-fw fa-ticket' },
                                            // { divider : true },
                                            // { link : '/company/1/log/index/', text : 'История изменений', icon : 'fa fa-fw fa-history' },
                                            // { divider : true },
                                            { link : '#', text : 'Импорт из файла...', icon : 'fa fa-fw fa-upload', handler : () => openModal('import-modal') },
                                            { link : '#', text : 'Экспорт в файл...', icon : 'fa fa-fw fa-download' }
                                        ]
                                    }}
                                />
                                {/*BUTTON FILTER*/}
                                <div className="btn-group btn-group-sm dropdown pull-right">
                                    <TabAction
                                        access={true}
                                        className='btn btn-primary'
                                        style={{ width : '96px' }}
                                        handler={() => openModal('filter-customer')}
                                        icon='fa fa-filter'
                                        text='Фильтр'
                                    />
                                    <a className="btn btn-primary dropdown-toggle" style={{paddingRight: "7px", paddingLeft: "7px", width: "24px"}}>
                                        <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/company/1/customer/index">
                                                { translate('Сбросить фильтр') }
                                            </Link>
                                        </li>
                                        <li className="divider" />
                                        <li>
                                            <a> { translate('Управление фильтрами') } </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*FILTER OPTIONS*/}
                                {
                                    (filterParams && customerSetting && salesSetting) &&
                                        <FilterOptions
                                            data={JSON.parse(decodeURI(filterParams))}
                                            means={{
                                                source : translate('Источник'),
                                                status: `${translate('Статус')}`,
                                                name: `${translate('ФИО')}`,
                                                gender: `${translate('Пол')}`,
                                                ageMin: `${translate('Возраст от')}`,
                                                ageMax: `${translate('Возраст до')}`,
                                                contact: `${translate('Контакт')}`,
                                                group: `${translate('Группа')}`,
                                                groups : `${translate('Группа')}`,
                                                f_removed: `${translate('Архив')}`,
                                                manager: `${translate('Ответственный')}`,
                                                employee: `${translate('Педагог')}`,
                                                refusal: `${translate('Причина отказа')}`,
                                                note: `${translate('Комментарий')}`,
                                                debtor: `${translate('Должник')}`,
                                                noTariff : translate('Абонемент'),
                                                freeLesson : translate('Проведено бесплатно'),
                                                lastLesson : translate('Остался 1 урок'),
                                                zeroLesson : translate('Осталось 0 уроков'),
                                                duplicateContact: translate('Дублирование клиентов'),
                                                favorite : translate('Отмеченных клиентов'),
                                                withIndiv : 'З ідивідуальними',
                                                withoutLesson : 'Без уроків',
                                                endTariff : translate('Абонемент'),
                                                ...customSetting.customField.reduce((acc, item) => {
                                                    acc[item.systemName] = item.name;
                                                    return acc;
                                                }, {})
                                            }}
                                            meansData={{
                                                source : {
                                                    arr : filterDisable(salesSetting.leadSource),
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                manager : {
                                                    arr : [ { user_id : -1, name : translate('Без ответственного') }, ...managerData ],
                                                    type_id : 'user_id',
                                                    topic : 'name'
                                                },
                                                status : {
                                                    arr : filterDisable(customerSetting.status),
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                group : {
                                                    arr : dataGroup,
                                                    type_id : 'group_id',
                                                    topic : 'title'
                                                },
                                                favorite : {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                f_removed : {
                                                    arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                employee: {
                                                    arr : dataEmployee,
                                                    type_id : 'employee_id',
                                                    topic : 'name'
                                                },
                                                refusal : {
                                                    arr : filterDisable(customerSetting.refusal),
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                groups : {
                                                    arr : [ { id : 0, name : translate('Без группы') }, { id : 1, name : translate('С группой') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                gender : {
                                                    arr : [ { id : 1, name : translate('Мужчина') }, { id : 2, name : translate('Женщина') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                endTariff : {
                                                    arr : [ { id : 1, name : translate('Просроченный абонемент') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                noTariff : {
                                                    arr : [ { id : 1, name : translate('Без абонемента') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                debtor : {
                                                    arr : [ { id : 1, name : translate('Есть долг') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                freeLesson : {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                lastLesson : {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                zeroLesson: {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                duplicateContact: {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                withIndiv : {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                withoutLesson : {
                                                    arr : [ { id : 1, name : translate('клиенты') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                }
                                            }}
                                            page="customer"
                                        />
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to={'/company/1/customer/index'}> { translate('Текущие') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).groups === '0' ? 'active' : '' : null}>
                                        <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ groups : '0' }))}`}> { translate('Без группы') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).groups === '1' ? 'active' : '' : null}>
                                        <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ groups : '1' }))}`}> { translate('С группой') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).withIndiv === '1' ? 'active' : '' : null}>
                                        <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ withIndiv : '1' }))}`}> З індив. </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).withoutLesson === '1' ? 'active' : '' : null}>
                                        <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ withoutLesson : '1' }))}`}> Без уроків </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).f_removed === 1 ? 'active' : '' : null}>
                                        <Link to={`/company/1/customer/index/${encodeURI(JSON.stringify({ f_removed : 1 }))}`}> { translate('Архивные') } </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tap-pane active">
                                        <div className="panel-body" style={{ borderTop: 'none' }}>
                                            <div className="grid-view is-bs3 hide-resize">
                                                {/*INFO TABLE*/}
                                                <TabHeader
                                                    counts={counts}
                                                    entity='customer'
                                                />
                                                <div className="border-bottom" />
                                                {/*TABLE VIEW*/}
                                                <CustomerTable
                                                    sql={sql}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                    managerData={managerData}
                                                    loading={loading}
                                                    access={auth.role.interface === 1}
                                                />
                                                <div className="text-center" translate="no">
                                                    { pageSize && pagination(counts, pageSize, 'customer', page) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*MODAL*/}
            <AddCustomer
                dataEmployee={dataEmployee}
            />

            <EditCustomer
                sql={sql}
                updateSql={setSql}
                dataEmployee={dataEmployee}
                editCustomer={editCustomer}
            />

            <FilterCustomer
                dataGroup={dataGroup}
                dataEmployee={dataEmployee}
                managerData={managerData}
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                access={auth.role.interface === 1}
            />

            <AcceptModal />

            <ImportModal
                entity="customer"
            />
        </div>
    );
}