import SideMenu from "../../Components/SideMenu/SideMenu";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import UserTable from "./Table/UserTable";
import URL from "../../Api/URL.json";
import {useHttp} from "../../Hooks/http.hook";
import AddUser from "./Modal/AddUser";
import AcceptModal from "../../Components/Modal/AcceptModal";
import EditUser from "./Modal/EditUser";
import {useTranslate} from "../../Hooks/translate.hook";
import {SettingContext} from "../../Context/SettingContext";
import { ToastContainer } from 'react-toastify';
import {openModal, pagination} from "../../Function/common";
import TabHeader from "../../Components/TabHeader";
import TabAction from "../../Components/TabAction";
import FilterUser from "./Modal/FilterUser";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";

export default function User () {

    // CONTEXT START
    const { pageSize } = useContext(SettingContext);
    // CONTEXT END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [ sql, setSql ] = useState([]);
    const [ selectedRows, setSelectedRows ] = useState([]);
    const [ userEdit, setUserEdit ] = useState({});

        // DATA
    const [ counts, setCounts ] = useState();
    const [ roleData, setRoleData ] = useState([]);
    const [ employeeData, setEmployeeData ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    // STATE END

    // BUTTON FUNC START
    function buttonEdit () {
        if (selectedRows.length === 1) {
            openModal('edit-user');
            setUserEdit(sql.filter(item => item.user_id === +selectedRows[0])[0])
        }
    }
    // BUTTON FUNC END

    // INIT DATA START
    useEffect( () => {

        if (!pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            const data = await request(`${URL.back_end}/user_filter`, 'POST', { page, limit : pageSize.user, data : { f_removed : 0, ...params } });

            if (error) throw error

            setSql(data.users);
            setCounts(data.counts);
            setLoading(false);
        }

        fetchData().catch(e => console.log(e))

    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {

            const roles = await request(`${URL.back_end}/role`, 'GET');
            const employees = await request(`${URL.back_end}/employee`, 'GET');

            if (error) throw error

            setRoleData(roles);
            setEmployeeData(employees);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="user" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: '624px' }}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="/company/1/user/index" className="btn btn-sm btn-white active" title={translate('Управление пользователями')}>
                                        <i className="ion-person" />
                                    </Link>
                                    <Link to="/company/1/user/role-index" className="btn btn-sm btn-white" title={translate('Управление ролями')}>
                                        <i className="ion-ios-body" />
                                    </Link>
                                </div>
                                {/*BUTTON ADD*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-plus"
                                    text="Добавить"
                                    handler={() => openModal('add-user')}
                                />
                                {/*BUTTON EDIT*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    disable={!selectedRows.length}
                                    handler={() => buttonEdit()}
                                />
                                {/*BUTTON ANOTHER*/}
                                {/*<TabAction*/}
                                {/*    access={true}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="fa fa-ellipsis-h"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' },*/}
                                {/*            { link : '#', text : 'Экспорт в файл...', icon : 'fa fa-download' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*BUTTON FILTER*/}
                                <TabAction
                                    access={true}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    icon="fa fa-filter"
                                    text="Фильтр"
                                    handler={() => openModal('filter-user')}
                                />
                                {
                                    filterParams &&
                                        <FilterOptions
                                            data={JSON.parse(decodeURI(filterParams))}
                                            means={{
                                                role : translate('Роль'),
                                                name : translate('ФИО'),
                                                phone : translate('Телефон'),
                                                email : 'E-mail',
                                                gender : translate('Пол'),
                                                f_removed : translate('Архивные')
                                            }}
                                            meansData={{
                                                role : {
                                                    arr : roleData,
                                                    type_id : 'role_id',
                                                    topic : 'name'
                                                },
                                                f_removed : {
                                                    arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                },
                                                gender : {
                                                    arr : [ { id : 1, name : translate('Мужчина') }, { id : 2, name : translate('Женщина') } ],
                                                    type_id : 'id',
                                                    topic : 'name'
                                                }
                                            }}
                                            page='user'
                                        />
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to="/company/1/user/index"> { translate('Текущие') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).f_removed === 1 ? 'active' : '' : ''}>
                                        <Link to={`/company/1/user/index/${encodeURI(JSON.stringify({ f_removed : 1 }))}`}> { translate('Архивные') } </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body">
                                            <div className="grid-view is-bs3 hide-resize">
                                                {/*TABLE HEADER*/}
                                                <TabHeader
                                                    counts={counts}
                                                    entity='user'
                                                />
                                                {/*TABLE BODY*/}
                                                <div className="border-bottom" />
                                                <UserTable
                                                    sql={sql}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                    roleData={roleData}
                                                    loading={loading}
                                                />
                                                <div className="text-center" translate="no">
                                                    { pageSize && pagination(counts, pageSize, 'user', page) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*MODAL*/}
            <AddUser
                sql={sql}
                updateSql={setSql}
                roleData={roleData}
                employeeData={employeeData}
            />

            <EditUser
                sql={sql}
                updateSql={setSql}
                userEdit={userEdit}
                roleData={roleData}
                employeeData={employeeData}
            />

            <FilterUser
                roleData={roleData}
                params={filterParams ? JSON.parse(filterParams) : null}
            />

            <AcceptModal />
        </div>
    )
}