import {Link} from "react-router-dom";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {useContext, useEffect} from "react";
import {SettingContext} from "../../../Context/SettingContext";
import {findObject, filterDisable} from "../../../Function/common";
import shortid from "shortid";
import $ from "jquery";
import {useTranslate} from "../../../Hooks/translate.hook";
import Dropdown from "../../../Components/DropdownMenu/Dropdown";
import {useArchive} from "../../../Hooks/archive.hook";

function jHandler () {
    $('.client-more').click(function (event) {
        event.stopPropagation();
        $(this).hide();
        $(this).siblings('.hide').removeClass('hide');
        return false;
    })
}

export default function GroupColumns (props) {

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const confirm_button = document.getElementById("sweet-confirm");
    // SWEET MODAL END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // INIT JHANDLER START
    useEffect(() => {
        jHandler()
    }, [])
    // INIT JHANDLER END

    // SELECT FUNC START
    function getSelect () {
        let selection = document.getElementsByName('selection[]');
        let tempSelection = []

        selection.forEach(element => {
            if (element.checked) {
                tempSelection.push(element.value)
            }
        })

        props.updateRows(tempSelection);
    }
    // SELECT FUNC END

    // BUTTON FUNC START
    function buttonArchive (value) {
        sweet_alert.style.display = "block";
        sweet_overlay.style.display = "block";

        confirm_button.addEventListener('click', async function () {
            sweet_alert.style.display = "none";
            sweet_overlay.style.display = "none";
            // REQUEST

            await request(`${URL.back_end}/group/${props.data.group_id}`, 'PUT', { f_removed: value });

            if (value === 0) {
                await pushArchive(4, 'group', props.data.group_id, { f_removed : 1 }, { f_removed : 0 })
            } else {
                await pushArchive(3, 'group', props.data.group_id, { f_removed : 0 }, { f_removed : 1 })
            }

            if (error) return console.log(error)

            props.sql.forEach(function (item, i) {
                if (props.data.group_id === item.group_id) {
                    props.sql[i].f_removed = value;
                    props.updateSql([...props.sql]);
                }
            })
        })
    }
    // BUTTON FUNC END

    return (
        <tr className={`w1 ${props.data.f_removed !== 0 ? "danger" : null}`}>
            <td className="skip-export kv-align-center kv-align-middle w1 kv-row-select" style={{width: "50px"}}>
                <input
                    type="checkbox"
                    className="kv-row-checkbox"
                    name="selection[]"
                    value={props.data.group_id}
                    onClick={getSelect}
                />
            </td>
            {/*TITLE*/}
            <td>
                <Link to={`/company/1/group/view?id=${props.data.group_id}`}>
                    <i className="ion-person-stalker m-r-xs" /> {props.data.f_removed !== 0 ? <strike>{props.data.title}</strike> : props.data.title}
                </Link>
            </td>
            {/*STATUS*/}
            <td>
                {
                    lessonSetting && (
                        <Dropdown
                            initValue={findObject(lessonSetting.groupStatus, props.data.status).name}
                            arr={filterDisable(lessonSetting.groupStatus)}
                            type='name'
                            objType='status'
                            url={`/group/${props.data.group_id}`}
                            string={false}
                            resultType='id'
                        />
                    )
                }
            </td>
            {/*DESCRIPTION*/}
            <td>
                { props.data.description }
            </td>
            {/*CLIENT*/}
            <td>
                <span title={translate('Количество участников')} className={props.customersData.filter(group => group.group_id === props.data.group_id).length > 0 ? null : "none"}>
                    <a href={`/company/1/customer/index?group_id=${props.data.group_id}`} target="_blank" style={{display: "inline-block"}}>
                        {props.customersData.filter(group => group.group_id === props.data.group_id).length ? props.customersData.filter(group => group.group_id === props.data.group_id).length : null}
                    </a> :&nbsp;
                    {
                        props.customersData.filter(group => group.group_id === props.data.group_id).map((item, index) => (
                            <Link to={`/company/1/customer/view?id=${item.customer_id}`} className={`m-r-xs ${index >= 2 ? 'hide' : ''}`} title="Открыть карточку" style={{display: "block"}} key={shortid.generate()}>
                                {item.name}
                            </Link>
                        ))
                    }
                    {
                        props.customersData.filter(group => group.group_id === props.data.group_id).length > 0 ? props.customersData.filter(group => group.group_id === props.data.group_id).length >= 2 ? (
                            <a className="crm-dashed-link client-more">
                                и ещё {props.customersData.filter(group => group.group_id === props.data.group_id).length - 2}
                            </a>
                        ) : null : null
                    }
                </span>
            </td>
            {/*KNOWLEDGE*/}
            <td>
                { props.data.knowledge }
            </td>
            {/*EMPLOYEE*/}
            <td>
                {
                    props.employeesData.filter(group => group.group_id === props.data.group_id).map(item => (
                        <div key={shortid.generate()}>
                            <Link to={`/company/1/employee/view?id=${item.employee_id}`}>
                                {item.name}
                            </Link>
                            <br />
                        </div>
                    ))
                }
            </td>
            {/*COLOR*/}
            <td>
                { props.data.date_start } { props.data.date_end && `- ${props.data.date_end}` }
            </td>
            {/*BUTTON*/}
            <td>
                {
                    props.data.f_removed === 0 ?
                        <a className="pull-right" title={translate('В архив')} onClick={() => buttonArchive(1)}>
                            <i className="fa fa-lg fa-trash-o text-danger" />
                        </a> :
                        <a className="pull-right" title={translate('Из архива')} onClick={() => buttonArchive(0)}>
                            <i className="fa fa-reply text-navy" />
                        </a>
                }
            </td>
        </tr>
    )
}