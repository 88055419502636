import {findObjectWCheck, openMenu} from "../../../../Function/common";
import shortid from "shortid";
import React, {useContext} from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";
import URL from "../../../../Api/URL.json";
import {useHttp} from "../../../../Hooks/http.hook";
import {useArchive} from "../../../../Hooks/archive.hook";
import {SettingContext} from "../../../../Context/SettingContext";

export default function InfoBlock ({ sqlCard, setSqlCard, access }) {

    // CONTEXT START
    const { customerSetting, salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    const { request } = useHttp();
    // HOOK END

    if (!access) return

    // HANDLER START
    async function chooseStatus (status) {
        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', {status: status})

        await pushArchive(2, `CustomerStatus`, sqlCard.customer_id, {status: status}, {status: sqlCard.status});

        window.location.reload();
    }

    async function chooseLeadStatus (status) {
        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', {leadStatus: status})

        await pushArchive(2, `CustomerStatus`, sqlCard.customer_id, {leadStatus: status}, {leadStatus: sqlCard.leadStatus});

        window.location.reload();
    }

    async function chooseSource (source) {
        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', {source: source})

        await pushArchive(2, `CustomerStatus`, sqlCard.customer_id, {source: source}, {source: sqlCard.source});

        window.location.reload();
    }

    async function changeClient (is_lead) {
        await request(`${URL.back_end}/customer/${sqlCard.customer_id}`, 'PUT', {is_lead})
        window.location.reload();
    }
    // HANDLER END

    // RENDER START
    function renderStatus () {
        if (!customerSetting) return <></>

        return (
            <span className="dropdown m-r-sm" onClick={e => openMenu(e)}>
                        <a className="dropdown-toggle crm-dashed-link text-muted text-lowercase" title={translate('Изменить статус')}>
                            {
                                findObjectWCheck(customerSetting.status, sqlCard.status) ?
                                    findObjectWCheck(customerSetting.status, sqlCard.status).name : translate('Статус')
                            }
                        </a>
                        <ul role="menu" className="dropdown-menu">
                            <li onClick={() => chooseStatus(null)}>
                                <a>
                                    <i className="ion-checkmark" style={sqlCard.status === null ? {opacity: 1} : {opacity: 0.1}}/> Не задано
                                </a>
                            </li>
                            {
                                customerSetting.status.map(item => {
                                    if (!item.disabled) {
                                        return (
                                            <li onClick={() => chooseStatus(item.id)} key={shortid.generate()}>
                                                <a>
                                                    <i className="ion-checkmark" style={item.id === sqlCard.status ? {opacity: 1} : {opacity: 0.1}} /> {item.name}
                                                </a>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </span>
        )
    }

    function renderLeadStatus () {
        if (!salesSetting) return <></>

        return (
            <span className="dropdown m-r-sm" onClick={e => openMenu(e)}>
                        <a className="dropdown-toggle crm-dashed-link text-muted text-lowercase" title={translate('Изменить статус')}>
                            {
                                findObjectWCheck(salesSetting.leadStatus, sqlCard.leadStatus) ?
                                    findObjectWCheck(salesSetting.leadStatus, sqlCard.leadStatus).name : translate('Етап')
                            }
                        </a>
                        <ul role="menu" className="dropdown-menu">
                            <li onClick={() => chooseLeadStatus(null)}>
                                <a>
                                    <i className="ion-checkmark" style={sqlCard.leadStatus === null ? {opacity: 1} : {opacity: 0.1}}/> Не задано
                                </a>
                            </li>
                            {
                                salesSetting.leadStatus.map(item => {
                                    if (!item.disabled) {
                                        return (
                                            <li onClick={() => chooseLeadStatus(item.id)} key={shortid.generate()}>
                                                <a>
                                                    <i className="ion-checkmark" style={item.id === sqlCard.leadStatus ? {opacity: 1} : {opacity: 0.1}} /> {item.name}
                                                </a>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </span>
        )
    }
    // RENDER END

    return (
        <div className="row" style={{ userSelect : 'none' }}>
            <div className="col-xs-7 font-bold">
                <h5 className="font-noraml m-t-sm no-padding visible-xs-inline-block visible-sm-inline-block visible-md-inline-block visible-lg-inline-block">
                    <span className="dropdown m-r-sm" onClick={e => openMenu(e)}>
                        <a className="dropdown-toggle crm-dashed-link text-muted text-lowercase" title="Состояние - действующий клиент">
                            { translate(`${sqlCard.is_lead === 0 ? 'Клиент' : 'Лид'}`) }
                        </a>
                        <ul role="menu" className="dropdown-menu">
                            {
                                sqlCard.is_lead === 0 ?
                                    (
                                        <li onClick={() => changeClient(1)}>
                                            <a>{translate('Отметить как лида')}</a>
                                        </li>
                                    ) :
                                    (
                                        <li onClick={() => changeClient(0)}>
                                            <a>{translate('Отметить как клиента')}</a>
                                        </li>
                                    )
                            }
                        </ul>
                    </span>
                    { sqlCard.is_lead ? renderLeadStatus() : renderStatus() }
                    <div className="hidden-sm hidden-md hidden-lg m-b-sm" />
                    <span className="dropdown m-r-sm" onClick={e => openMenu(e)}>
                        <a className="dropdown-toggle crm-dashed-link text-muted text-lowercase" title={translate('Изменить источник')}>
                            {
                                salesSetting ?
                                    findObjectWCheck(salesSetting.leadSource, sqlCard.source) ?
                                        findObjectWCheck(salesSetting.leadSource, sqlCard.source).name :
                                        translate('Источник')
                                    : null
                            }
                        </a>
                        <ul role="menu" className="dropdown-menu">
                            <li onClick={() => chooseSource(null)}>
                                <a>
                                    <i className="ion-checkmark" style={sqlCard.source === null ? {opacity: 1} : {opacity: 0.1}}/> Не задано
                                </a>
                            </li>
                            {
                                salesSetting ?
                                    salesSetting.leadSource.map(item => {
                                        if (!item.disabled) {
                                            return (
                                                <li onClick={() => chooseSource(item.id)} key={shortid.generate()}>
                                                    <a>
                                                        <i className="ion-checkmark" style={item.id === sqlCard.source ? {opacity: 1} : {opacity: 0.1}} /> {item.name}
                                                    </a>
                                                </li>
                                            )
                                        }
                                    }) : null
                            }
                        </ul>
                    </span>
                </h5>
            </div>
        </div>
    )
}