import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useContext, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {openModal} from "../../../../Function/common";
import URL from "../../../../Api/URL.json";
import EditLeadTags from "./Modal/EditLeadTags";
import EditTags from "./Modal/EditTags";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

export default function LeadTags () {

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ editLeadTag, setEditLeadTag ] = useState({});
    const [ editTag, setEditTag ] = useState({});
    const [ deleteTag, setDeleteTag ] = useState({});
    const [ deleteType, setDeleteType ] = useState('');
    // STATE END

    // FUNCTION START
    async function disabledLeadTags (data, type) {
        salesSetting[type] = salesSetting[type].map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', { salesSetting: JSON.stringify(salesSetting) })
    }

    function deleteHandler (item, type) {
        setDeleteTag(item);
        setDeleteType(type);
        openModal('delete-lead-tag-overlay');
        openModal('delete-lead-tag-modal');
    }

    async function deleteLeadTagsHandler () {
        salesSetting[deleteType] = salesSetting[deleteType].filter(item => {
            if (item.id !== deleteTag.id) {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', { salesSetting: JSON.stringify(salesSetting) })

        window.location.reload();
    }
    // FUNCTION END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">
                    { translate('Теги статистики') } <span className="text-muted">({salesSetting?.leadTags.length})</span>
                </h4>
            </div>
            <div className="modal-body p-md">
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={ translate('Фильтр по названию') }
                                />
                                <span className="input-group-addon" style={{ cursor: 'pointer' }}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-lead-tags")}>
                                <i className="ion-plus" /> { translate('Добавить') }
                            </a>
                        </div>
                    </div>
                </form>
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                    {
                        salesSetting?.leadTags.map(item => (
                            <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {opacity: 0.5} : {}} data-id={`${item.id}`}>
                                <td className="col-sm-10">
                                    <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                    <small className="text-muted"> [ID {item.id}] </small>
                                </td>
                                <td className="text-right col-sm-2">
                                    <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledLeadTags(item, 'leadTags')}>
                                        <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                    </a>
                                    <a className="btn btn-xs btn-white m-r-xxs" onClick={() => { openModal("edit-lead-tags"); setEditLeadTag(item) }}>
                                        <i className="fa fa-pencil text-success fa-fw" />
                                    </a>
                                    <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item, 'leadTags')}>
                                        <i className="fa fa-trash-o text-danger fa-fw" />
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <div className="modal-header">
                <h4 className="modal-title">
                    { translate('Теги') } <span className="text-muted">({salesSetting?.tags.length})</span>
                </h4>
            </div>
            <div className="modal-body p-md">
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={ translate('Фильтр по названию') }
                                />
                                <span className="input-group-addon" style={{ cursor: 'pointer' }}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-tags")}>
                                <i className="ion-plus" /> { translate('Добавить') }
                            </a>
                        </div>
                    </div>
                </form>
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                    {
                        salesSetting?.tags.map(item => (
                            <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {opacity: 0.5} : {}} data-id={`${item.id}`}>
                                <td className="col-sm-10">
                                    <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                    <small className="text-muted"> [ID {item.id}] </small>
                                </td>
                                <td className="text-right col-sm-2">
                                    <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledLeadTags(item, 'tags')}>
                                        <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                    </a>
                                    <a className="btn btn-xs btn-white m-r-xxs" onClick={() => { openModal("edit-tags"); setEditTag(item) }}>
                                        <i className="fa fa-pencil text-success fa-fw" />
                                    </a>
                                    <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item, 'tags')}>
                                        <i className="fa fa-trash-o text-danger fa-fw" />
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>

            <EditLeadTags
                editLeadTag={editLeadTag}
            />

            <EditTags
                editTag={editTag}
            />

            <DeleteModal
                id="delete-lead-tag"
                handler={deleteLeadTagsHandler}
            />
        </>
    )
}