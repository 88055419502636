import {useEffect, useRef, useState} from "react";
import {closeModal} from "../../../Function/common";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import URL from "../../../Api/URL.json";
import {useArchive} from "../../../Hooks/archive.hook";
import {validate} from "../../../Function/validateForm";

export default function EditAgile ({ userData, customerData, groupData, editTask }) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [ text, setText ] = useState('');
    const [ users, setUsers ] = useState([]);
    const [ date, setDate ] = useState(new Date().toLocaleDateString('en-CA'));
    const [ time, setTime ] = useState('');
    const [ customers, setCustomers ] = useState([]);
    const [ groups, setGroups ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!Object.keys(editTask).length) return

        setText(editTask.text);
        setDate(editTask.date);
        setTime(editTask.time);

        async function fetchData () {
            const user = await request(`${URL.back_end}/user_task/${editTask.task_id}`, 'GET');
            const customer = await request(`${URL.back_end}/customer_task/${editTask.task_id}`, 'GET');
            const group = await request(`${URL.back_end}/group_task/${editTask.task_id}`, 'GET');

            if (error) throw error

            setUsers(user);
            setCustomers(customer);
            setGroups(group);
        }

        fetchData().catch(e => console.log(e))

    }, [editTask])
    // INIT DATA END

    // ADD AGILE START
    async function editAgile (e) {
        e.preventDefault();

        const valid_obj = {
            "Исполнитель" : !users.length
        }

        if (validate(valid_obj, translate)) return

        document.getElementById('e_btn_submit').disabled = true;

        const taskData = {
            text,
            users : users.length ? users : undefined,
            date,
            time : time ? time : '23:59',
            customers : customers.length ? customers : undefined,
            groups : groups.length ? groups : undefined,
            status : editTask.status
        }

        await request(`${URL.back_end}/task/${editTask.task_id}`, 'PUT', taskData);
        await pushArchive(2, 'task', editTask.task_id, { ...taskData, task_id : editTask.task_id, c_date : editTask.c_date, employer : editTask.employer, users : users.map(item => item.name), customers : customers.map(item => item.name), groups : groups.map(item => item.title) }, editTask);

        window.location.reload();
    }
    // ADD AGILE END

    return (
        <div id="edit-agile" className="modal in" style={{ paddingRight: "17px" }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editAgile(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Править задачу') } </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/* TASK */}
                            <div className="form-group field-task-text required">
                                <div className="col-sm-12">
                                    <textarea
                                        className="form-control autoresize js-task-text"
                                        rows="1"
                                        style={{ fontSize: "12px", height: "31px" }}
                                        placeholder={ `${translate('Например')}, ${translate('позвонить клиенту')}` }
                                        value={text}
                                        onChange={e => setText(e.target.value)}
                                    />
                                </div>
                            </div>
                            <h4 className="border-bottom m-b-md m-t-lg no-padding"> { translate('Кто и когда будет выполнять?') } </h4>
                            {/* EXECUTOR */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Исполнитель') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={userData.filter(item => item.f_removed === 0)}
                                        type_id="user_id"
                                        topicSearch="name"
                                        value={users}
                                        setProps={setUsers}
                                    />
                                </div>
                            </div>
                            {/* DATE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Когда исполнить') } </label>
                                </div>
                                <div className="col-sm-4">
                                    <input
                                        type="date"
                                        className="form-control crm-datepicker"
                                        autoComplete="off"
                                        value={date}
                                        onChange={e => setDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={time}
                                        onChange={e => setTime(e.target.value)}
                                    />
                                </div>
                            </div>
                            <h4 className="border-bottom m-b-md m-t-lg no-padding">
                                { translate('Привязать к задаче клиента или группу') }?
                            </h4>
                            {/* CUSTOMERS */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label m-t-xxs"> { translate('Клиент(ы)') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={customerData.filter(item => item.f_removed === 0)}
                                        type_id="customer_id"
                                        topicSearch="name"
                                        value={customers}
                                        setProps={setCustomers}
                                    />
                                </div>
                            </div>
                            {/*GROUPS*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label m-t-xxs"> { translate('Группа') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={groupData.filter(item => item.f_removed === 0)}
                                        type_id="group_id"
                                        topicSearch="title"
                                        value={groups}
                                        setProps={setGroups}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="e_btn_submit">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}