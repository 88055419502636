import {useTranslate} from "../Hooks/translate.hook";
import {openMenu} from "../Function/common";
import {Link} from "react-router-dom";

export default function TabAction ({ access, className, textClassName, text, style, title, handler, dropdown, icon, disable }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return access && (
        dropdown ? (
            <div className={className} style={style} title={title} onClick={e => openMenu(e)}>
                <button type="button" className={dropdown.buttonClass} style={dropdown.buttonStyle}>
                    <i className={icon} />
                    <span className="hidden-xs"> { translate(dropdown.text) } </span>
                    <span className="caret" />
                </button>
                <ul className="dropdown-menu">
                    {
                        dropdown.list.map(item => (
                            item.divider ? <li className="divider" /> : (
                                <li>
                                    <a href={`${item.link}`} className="crm-modal-btn" onClick={item.handler}>
                                        <i className={item.icon} /> { translate(item.text) }
                                    </a>
                                </li>
                            )
                        ))
                    }
                </ul>
            </div>
        ) : (
            <button className={className} onClick={handler} style={style} title={title} disabled={disable}>
                <i className={icon} />
                <span className={textClassName ? textClassName : 'hidden-xs'}> { translate(text) } </span>
            </button>
        )
    )
}