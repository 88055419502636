import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {success} from "../../../../Function/Notify";
import {useTranslate} from "../../../../Hooks/translate.hook";

export default function LessonOption () {

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ homework, setHomework ] = useState();
    const [ topic, setTopic ] = useState();
    const [ mark, setMark ] = useState();
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!lessonSetting) return

        setHomework(lessonSetting.lessonOption.homework);
        setTopic(lessonSetting.lessonOption.topic);
        setMark(lessonSetting.lessonOption.mark);
    }, [lessonSetting])
    // INIT DATA END

    // ADD LESSON OPTION START
    async function addLessonOption (e) {
        e.preventDefault();

        lessonSetting.lessonOption = {homework, topic, mark}

        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})
        if (error) return console.log(error)
        success("Изменения сохранены")
    }
    // ADD LESSON OPTION END

    return (
        <div className="settings-block__content-content">
            <ToastContainer />
            <div id="settings-container" className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title"> { translate('Опциональные поля в уроках') } </h4>
                </div>
                <form className="form-horizontal" onSubmit={e => addLessonOption(e)}>
                    <div className="modal-body p-md">
                        <div className="form-group">
                            <div className="col-sm-4"> { translate('Отображение полей') } </div>
                            <div className="col-sm-8">
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={homework}
                                            onClick={e => setHomework(e.currentTarget.checked)}
                                        />
                                        &nbsp;{ translate('Домашние задания') }
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={topic}
                                            onClick={e => setTopic(e.currentTarget.checked)}
                                        />
                                        &nbsp;{ translate('Тема урока') }
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={mark}
                                            onClick={e => setMark(e.currentTarget.checked)}
                                        />
                                        &nbsp;{ translate('Выставление оценок') }
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <div className="col-sm-4"> Дополнительные опции </div>*/}
                        {/*    <div className="col-sm-8">*/}
                        {/*        <label>*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                value={1}*/}
                        {/*            />*/}
                        {/*            &nbsp;Автоматически проводить вчерашние уроки*/}
                        {/*        </label>*/}
                        {/*        <label>*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                value={2}*/}
                        {/*            />*/}
                        {/*            &nbsp;Разрешить планировать уроки на прошлое*/}
                        {/*        </label>*/}
                        {/*        <label>*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                value={3}*/}
                        {/*            />*/}
                        {/*            &nbsp;Разрешить проведение будущих уроков*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="form-group">*/}
                        {/*    <div className="col-sm-4">*/}
                        {/*        <label className="control-label" htmlFor="lessonoptionsform-lesson_fill_method">Предзаполнение урока</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-sm-8">*/}
                        {/*        <select className="form-control">*/}
                        {/*            <option value="">Не задано</option>*/}
                        {/*            <option value="1">Ответственный педагог</option>*/}
                        {/*            <option value="2">Уровень знаний</option>*/}
                        {/*            <option value="3">Привязанный абонемент</option>*/}
                        {/*            <option value="4">Предыдущий урок</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="form-group">*/}
                        {/*    <div className="col-sm-4 p-xxs">*/}
                        {/*        <label>*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                value={1}*/}
                        {/*            />*/}
                        {/*            &nbsp;Использовать ак/ч*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-sm-8">*/}
                        {/*        <span style={{position: "absolute", paddingTop: "8px", paddingLeft: "12px"}} className="text-muted">мин</span>*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            className="form-control crm-academic-hour"*/}
                        {/*            placeholder="например, 45"*/}
                        {/*            disabled={true}*/}
                        {/*            style={{paddingLeft: "50px"}}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-sm btn-w-m btn-success">
                            <i className="fa fa-save" /> { translate('Сохранить') }
                        </button>
                        <button type="button" className="btn btn-sm btn-w-m btn-white">
                            { translate('Отмена') }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}