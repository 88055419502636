import {Link} from "react-router-dom";
import {openMenu, openModal} from "../../../../Function/common";
import React, {useState} from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddLesson from "../../../Calendar/Modal/AddLesson";

export default function LessonBlock ({ sqlCard, lessonData, groupData, employeeData, customerData }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ typeLesson, setTypeLesson ] = useState();
    // STATE END

    // FUNCTION START
    function renderList (f_removed) {
        if (f_removed === 1) return <></>

        return (
            <span className="dropdown" onClick={e => openMenu(e)}>
                <a className="dropdown-toggle text-success m-l-sm crm-dashed-link text-lowercase" title={translate('Добавить')}>
                    { translate('Добавить') }
                </a>
                <ul role="menu" className="dropdown-menu dropdown-left">
                    <li>
                        <a className="crm-modal-btn" onClick={e => typeHandler(e, 1)}>
                            <i className="ion-person" /> {translate('Индивидуальный')}
                        </a>
                    </li>
                    <li>
                        <a className="crm-modal-btn" onClick={e => typeHandler(e, 3)}>
                            <i className="ion-asterisk" /> {translate('Пробный')}
                        </a>
                    </li>
                </ul>
            </span>
        )
    }

    function typeHandler (e, type) {
        e.preventDefault();
        setTypeLesson(type);
        openModal('add-cast-lesson');
    }
    // FUNCTION END

    return (
        <>
            <div className="row">
                <div className="col-xs-5 text-muted m-b-xs">
                    { translate('Уроки') } <a target="_blank" href={`/company/1/log/index/${encodeURI(JSON.stringify({ newKey : 'customer_ids', entity : 'Lesson', newValue : sqlCard.customer_id, newOperation : '==' }))}`} rel="noreferrer"> <i className="fa fa-history text-navy"/> </a>
                </div>
                <div className="col-xs-7 m-b-xs text-right">
                    <Link to="/" className="crm-dashed-link" title="План / Факт" target="_blank">
                        п {lessonData.length} / ф {lessonData.filter(item => item.status === 1).length}
                    </Link>
                    { renderList(sqlCard.f_removed) }
                </div>
            </div>

            <AddLesson
                groupData={groupData}
                employeeData={employeeData}
                customerData={customerData}
                type_lesson={typeLesson}
                customer={sqlCard}
                id="add-cast-lesson"
            />
        </>
    )
}