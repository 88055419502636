import {openModal} from "../../../../Function/common";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import URL from "../../../../Api/URL.json";
import $ from 'jquery';
import 'jquery-ui-bundle';
import EditLeadStatus from "./Modal/EditLeadStatus";
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/salesSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'leadStatus'}),
                success: function(data){
                    setting.salesSetting = data;
                }
            });
        }
    });
}

export default function LeadStatus () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [editLeadStatus, setEditLeadStatus] = useState({});
    const [deleteLeadStatus, setDeleteLeadStatus] = useState({});
    // STATE END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.salesSetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // COMP FUNC START
    async function disabledLeadStatus (data) {
        salesSetting.leadStatus = salesSetting.leadStatus.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {salesSetting: JSON.stringify(salesSetting)})

        if (error) return console.log(error)
    }

    function deleteHandler (item) {
        setDeleteLeadStatus(item);
        openModal('delete-lead-status-overlay');
        openModal('delete-lead-status-modal');
    }

    async function deleteLeadStatusHandler () {
        salesSetting.leadStatus = salesSetting.leadStatus.filter(item => {
            if (item.id !== deleteLeadStatus.id) {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {salesSetting: JSON.stringify(salesSetting)})

        window.location.reload();
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Этапы воронки продаж')} <span className="text-muted">({salesSetting ? salesSetting.leadStatus.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-lead-status")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                    {
                        salesSetting ?
                            salesSetting.leadStatus.map(item => (
                                <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {opacity: 0.5} : {}} data-id={`${item.id}`}>
                                    <td className="col-sm-10">
                                        <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} />
                                        <small className="m-r-xs m-l-xs" style={{ marginBottom: "2px", border: `1px solid ${item.color}`, borderBottom: `5px solid ${item.color}` }}> { item.name } </small>
                                        <small className="text-muted"> [ID {item.id}] </small>
                                    </td>
                                    <td className="text-right col-sm-2">
                                        <a className="m-r-xs" title={translate('Сделать неактивным')} onClick={() => disabledLeadStatus(item)}>
                                            <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                        </a>
                                        <a className="btn btn-xs btn-white m-r-xxs" onClick={() => {openModal("edit-lead-status"); setEditLeadStatus(item)}}>
                                            <i className="fa fa-pencil text-success fa-fw" />
                                        </a>
                                        <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item)}>
                                            <i className="fa fa-trash-o text-danger fa-fw" />
                                        </a>
                                    </td>
                                </tr>
                            )) : null
                    }
                    </tbody>
                </table>
            </div>
            <EditLeadStatus
                editLeadStatus={editLeadStatus}
            />
            <DeleteModal
                id="delete-lead-status"
                handler={deleteLeadStatusHandler}
            />
        </>
    )
}