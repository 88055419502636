import {openMenu} from "../../Function/common";
import {useTranslate} from "../../Hooks/translate.hook";
import {useHttp} from "../../Hooks/http.hook";
import shortid from "shortid";
import URL from "../../Api/URL.json";

export default function Dropdown ({ initValue, arr, type, objType, url, string, resultType, offNull }) {

    // HOOK START
    const { translate }      = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    async function chooseValue (value) {

        let obj = {};
        obj[objType] = value !== null ? string ? JSON.stringify(value) : value : null;

        await request(`${URL.back_end}${url}`, 'PUT', obj);

        if (error) return error;

        window.location.reload();
    }

    return (
        <div className="btn-group btn-group-xs" onClick={e => openMenu(e)}>
            <button className="btn btn-white dropdown-toggle">
                { initValue ? initValue : translate('Не задано') }
            </button>
            <ul className="dropdown-menu">
                {
                    !offNull ? (
                        <li onClick={() => chooseValue(null)} key={shortid.generate()}>
                            <a> { translate('Не задано') } </a>
                        </li>
                    ) : null
                }
                {
                    arr.map(item => (
                        <li onClick={() => chooseValue(resultType ? item[resultType] : item)} key={shortid.generate()}>
                            <a> { item[type] } </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}