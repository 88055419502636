import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";
import URL from "../../Api/URL.json";
import "../../Assets/css/Chat.css";

export default function ChatsMenu (props) {

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FUNCTION START
    async function checkAll (e) {
        e.preventDefault();

        await request(`${URL.back_end}/message_checked/${props.menuData.chat_id}`, 'PUT', { checked : true });

        if (error) return console.log(error)

        let result_arr = props.sql.map(item => {
            if (item.chat_id === props.menuData.chat_id) {
                item.checked = true;
                return item;
            } else {
                return item;
            }
        })

        props.setSql(result_arr);
    }

    async function unCheck (e) {
        e.preventDefault();

        await request(`${URL.back_end}/message/${props.menuData.id}`, 'PUT', { checked : false });

        if (error) return console.log(error)

        let result_arr = props.sql.map(item => {
            if (item.id === props.menuData.id) {
                item.checked = 0;
                return item;
            } else {
                return item;
            }
        })

        props.setSql(result_arr);
    }

    async function pinChat (e) {
        e.preventDefault();

        await request(`${URL.back_end}/customer/${props.menuData.customer_id}`, 'PUT', { pinned : !props.menuData.pinned })

        if (error) return console.log(error)

        let result_arr = props.sql.map(item => {
            if (item.chat_id === props.menuData.chat_id) {
                item.pinned = !props.menuData.pinned;
                return item;
            } else {
                return item;
            }
        })

        props.setSql(result_arr.sort((a, b) => b.date - a.date).sort((a, b) => Number(b.pinned) - Number(a.pinned)));
    }

    async function archiveChat (e) {
        e.preventDefault();

        await request(`${URL.back_end}/customer/${props.menuData.customer_id}`, 'PUT', { archive : !props.menuData.archive })

        if (error) return console.log(error)

        let result_arr = props.sql.map(item => {
            if (item.chat_id === props.menuData.chat_id) {
                item.archive = Number(!props.menuData.archive);
                return item;
            } else {
                return item;
            }
        })

        props.setSql(result_arr);
    }

    // async function deleteChat (e) {
    //     e.preventDefault();
    //
    //     await request(`${URL.back_end}/message_all/${props.menuData.chat_id}`, 'DELETE');
    //
    //     if (error) return console.log(error);
    //
    //     let result_arr = props.sql.filter(item => item.chat_id !== props.menuData.chat_id);
    //
    //     props.setSql(result_arr);
    // }
    // FUNCTION END

    return (
        <div className="ContextMenuContainer open shown">
            <div className="MessageContextMenu" style={ props.menuStyle } id={props.menu_id}>
                <div className="bubble">
                    <div className="scrollable-content custom-scroll" style={{maxHeight: "545.6px"}} onClick={e => pinChat(e)}>
                        <div className="MenuItem compact">
                            <i className="ion-pin small" style={{ marginRight: "1.45rem", marginLeft : ".8rem", transform : "rotate(27deg)" }} /> { translate(props.menuData.pinned ? 'Открепить' : 'Закрепить') }
                        </div>
                    </div>
                    <div className="scrollable-content custom-scroll" style={{maxHeight: "545.6px"}} onClick={e => archiveChat(e)}>
                        <div className="MenuItem compact">
                            <i className="ion-archive small" style={{ marginRight: "1.2rem", marginLeft: ".5rem" }} /> { translate(props.menuData.archive ? 'Разархивировать' : 'Архивировать') }
                        </div>
                    </div>
                    <div className="scrollable-content custom-scroll" style={{maxHeight: "545.6px"}} onClick={e => props.menuData.checked ? unCheck(e) : checkAll(e)}>
                        <div className="MenuItem compact">
                            <i className={`ion-chatbubble${props.menuData.checked ? '' : '-working'} small`} /> { translate(props.menuData.checked ? 'Пометить непрочитанным' : 'Прочитать') }
                        </div>
                    </div>
                    {/*<div className="scrollable-content custom-scroll" style={{maxHeight: "545.6px"}} onClick={e => deleteChat(e)}>*/}
                    {/*    <div className="MenuItem compact" style={{ color : "#e53935" }}>*/}
                    {/*        <i className="fa fa-trash-o" style={{ marginRight: "1.23rem", marginLeft : ".6555rem", marginBottom : ".1rem" }} /> { translate('Удалить чат') }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}