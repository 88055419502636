import {useEffect, useRef, useState} from "react";
import InputChooseSingle from "../../../Components/InputChoose/InputChooseSingle";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal} from "../../../Function/common";
import {useNavigate} from "react-router-dom";

export default function FilterTariff (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [title, setTitle] = useState('');
    const [billing, setBilling] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [f_removed, setFremoved] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.value) {
            setTitle('');
            setBilling('');
            setPriceMin('');
            setPriceMax('');
            setFremoved('');
            return
        }

        setTitle(props.value.title);
        setBilling(props.value.billing);
        setPriceMin(props.value.priceMin);
        setPriceMax(props.value.priceMax);
        setFremoved(props.value.f_removed);
    }, [props.value])
    // INIT DATA END

    // FILTER TARIFF START
    function filterTariff (e) {
        e.preventDefault();

        let tariff = {
            title,
            billing,
            priceMin,
            priceMax,
            f_removed
        }

        let obj = Object.filter(tariff, (value) => value);

        if (Object.keys(obj).length === 0) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/tariff/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }
    }
    // FILTER TARIFF END

    return (
        <div id="filter-tariff" className="modal in" style={{ paddingRight: "16px" }} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterTariff(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body modal-body-search p-md">
                            {/*TITLE*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Название') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Введите название') }
                                        value={ title }
                                        onChange={ e => setTitle(e.target.value) }
                                    />
                                </div>
                            </div>
                            {/*BILLING*/}
                            {/*<div className="form-group">*/}
                            {/*    <div className="col-sm-4">*/}
                            {/*        <label className="control-label">*/}
                            {/*            { translate('Тарификация') }*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-sm-8">*/}
                            {/*        <InputChooseSingle*/}
                            {/*            data={["Поурочная", "Помесячная", "Недельная"]}*/}
                            {/*            setProps={setBilling}*/}
                            {/*            value={billing}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*PRICE*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Стоимость') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={translate('От')}
                                                value={priceMin}
                                                onChange={e => setPriceMin(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={translate('До')}
                                                value={priceMax}
                                                onChange={e => setPriceMax(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            {/*RESET FILTER*/}
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр и вернуться в таблицу') } onClick={e => { closeModal(e, modal); navigate('/company/1/tariff/index/') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            {/*SETTING FIELDS*/}
                            {/*<a className="crm-ajax-link btn btn-sm btn-success btn-outline pull-left" title={ translate('Настройка полей') }>*/}
                            {/*    <i className="fa fa-cog fa-fw" />*/}
                            {/*</a>*/}
                            {/*CANCEL*/}
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            {/*ACCEPT*/}
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}