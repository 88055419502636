import SideMenu from "../../Components/SideMenu/SideMenu";
import {useTranslate} from "../../Hooks/translate.hook";
import DashboardItems from "./Block/DashboardItems";
import "../../Assets/css/Dashboard.css";

export default function Dashboard () {

    // HOOK START
    const {translate} = useTranslate();
    // HOOK END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="dashboard"/>
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*DASHBOARD*/}
                    <div id="dashboard-pjax">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="ibox float-e-margins m-b-sm p-m">
                                    <div style={{ paddingBottom: 0 }}>
                                        {/*<EmptyDashboard />*/}
                                        <DashboardItems />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}