import {openModal} from "../../../../Function/common";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditMark from "./EditModal/EditMark";
import $ from 'jquery';
import 'jquery-ui-bundle';
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/lessonSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'mark'}),
                success: function(data){
                    setting.lessonSetting = data;
                }
            });
        }
    });
}

export default function MarkLesson () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const {lessonSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.lessonSetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // STATE START
    const [editMark, setEditMark] = useState({});
    const [deleteMark, setDeleteMark] = useState({});
    // STATE END

    // COMPONENT FUNCTION START
    function deleteHandler (item) {
        setDeleteMark(item);
        openModal('delete-mark-lesson-overlay');
        openModal('delete-mark-lesson-modal');
    }

    async function deleteMarkHandler () {
        lessonSetting.mark = lessonSetting.mark.filter(item => {
            if (item.id !== deleteMark.id) {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {lessonSetting: JSON.stringify(lessonSetting)})

        window.location.reload();
    }
    // COMPONENT FUNCTION END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title"> {translate('Настройка системы оценок')} </h4>
            </div>
            <div className="modal-body p-md">
                {/*WARNING*/}
                <p className="p-xs bg-warning m-b-lg">
                    {translate('Создайте список всех возможных оценок, чтобы сформировать статистику успеваемости клиентов.')}
                </p>
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-mark")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                        {
                            lessonSetting ?
                                lessonSetting.mark.map(item => (
                                    <tr className="sortable-item ui-sortable-handle" data-id={`${item.id}`}>
                                        <td className="col-sm-10">
                                            <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.fullName}
                                            <small className="text-muted"> [{item.ball}] </small>
                                        </td>
                                        <td className="text-right col-sm-2">
                                            <a className="btn btn-xs btn-white m-r-xxs" title={translate('Редактировать оценку')} onClick={() => {openModal("edit-mark"); setEditMark(item)}}>
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item)}>
                                                <i className="fa fa-trash-o text-danger fa-fw" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
            <EditMark
                editMark={editMark}
            />
            <DeleteModal
                id="delete-mark-lesson"
                handler={deleteMarkHandler}
            />
        </>
    )
}