// IMPORT
import {Route, Routes} from "react-router-dom";

// COMPONENT
    // PROFILE
import Profile from "./Pages/Profile/Profile";
    // CARD
import { CustomerCard } from "./Pages/Card/Customer/CustomerCard";
import EmployeeCard from "./Pages/Card/Employee/EmployeeCard";
import GroupCard from "./Pages/Card/Group/GroupCard";
    // PAGE

    // ANOTHER
import Dashboard from "./Pages/Dashboard/Dashboard";
import Agile from "./Pages/Agile/Agile";
import Customer from "./Pages/Customer/Customer";
import Group from "./Pages/Group/Group";
import Employee from "./Pages/Employee/Employee";
import Pay from "./Pages/Pay/Pay";
import Bill from "./Pages/Bill/Bill";
import Tariff from "./Pages/Tariff/Tariff";
import Lead from "./Pages/Lead/Lead";
import User from "./Pages/User/User";
import Role from "./Pages/User/Role/Role";
import Auth from "./Pages/Auth/Auth";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Error from "./Pages/Error/Error";
import Lesson from "./Pages/Lesson/Lesson";
import CalendarTeacher from "./Pages/CalendarTeacher/CalendarTeacher";
import Log from "./Pages/Log/Log";
import Setting from "./Pages/Setting/Setting";
import BaseOptions from "./Pages/Setting/Modal/BaseSettings/BaseOptions";
import StatusClient from "./Pages/Setting/Modal/Client/StatusClient";
import KnowledgeClient from "./Pages/Setting/Modal/Client/KnowledgeClient";
import ReasonClient from "./Pages/Setting/Modal/Client/ReasonClient";
import SubjectLesson from "./Pages/Setting/Modal/Lesson/SubjectLesson";
import StreamLesson from "./Pages/Setting/Modal/Lesson/StreamLesson";
import StatusGroup from "./Pages/Setting/Modal/Lesson/StatusGroup";
import RoomLesson from "./Pages/Setting/Modal/Lesson/RoomLesson";
import EmployeeQualification from "./Pages/Setting/Modal/Employee/EmployeeQualification";
import MarkLesson from "./Pages/Setting/Modal/Lesson/MarkLesson";
import PayAccount from "./Pages/Setting/Modal/Pay/PayAccount";
import PayCategory from "./Pages/Setting/Modal/Pay/PayCategory";
import PayArticle from "./Pages/Setting/Modal/Pay/PayArticle";
import RefusalClient from "./Pages/Setting/Modal/Client/RefusalClient";
import CustomField from "./Pages/Setting/Modal/Custom/CustomField";
import Branch from "./Pages/Setting/Modal/BaseSettings/Branch";
import Notification from "./Pages/Setting/Modal/BaseSettings/Notification";
import AccountClient from "./Pages/Setting/Modal/Client/AccountClient";
import SourceGroup from "./Pages/Setting/Modal/Sales/SourceGroup";
import LeadSource from "./Pages/Setting/Modal/Sales/LeadSource";
import RequiredField from "./Pages/Setting/Modal/Custom/RequiredField";
import LessonOption from "./Pages/Setting/Modal/Lesson/LessonOption";
import CalendarOption from "./Pages/Setting/Modal/Lesson/CalendarOption";
import LeadStatus from "./Pages/Setting/Modal/Sales/LeadStatus";
import Telegram from "./Pages/Setting/Modal/Integration/Telegram";
import CustomerProfile from "./Pages/Profile/CustomerProfile/CustomerProfile";
import EmployeeProfile from "./Pages/Profile/EmployeeProfile/EmployeeProfile";
import Chats from "./Pages/Chats/Chats";
import Calendars from "./Pages/Calendar/Calendar";
import Informer from "./Pages/Informer/Informer";
import ReportCustomer from "./Pages/Card/Customer/Report/ReportCustomer";
import ReportEmployee from "./Pages/Card/Employee/Report/ReportEmployee";

// CSS
import "./Assets/css/Theme.css";

// JSON
import infoMenu from "./Assets/data/JSON/menu.json";
import Facebook from "./Pages/Setting/Modal/Integration/Facebook";
import LeadReject from "./Pages/Setting/Modal/Sales/LeadReject";
import LeadTags from "./Pages/Setting/Modal/Sales/LeadTags";
import EmployeeOptions from "./Pages/Setting/Modal/Employee/EmployeeOptions";

export const useRoutes = (isAuthenticated, role) => {

    const menu = role ? JSON.parse(role.menu) : [];

    // SWITCH START
    function switchElement (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 'dashboard' : return <Dashboard />
            case 'calendar' : return <Calendars />
            case 'agile' : return <Agile />
            case 'customer' : return <Customer />
            case 'group' : return <Group />
            case 'employee' : return <Employee />
            case 'pay' : return <Pay />
            case 'tariff' : return <Tariff />
            case 'lead' : return <Lead />
            case 'user' : return <User />
            case 'log' : return <Log />
            case 'chats' : return <Chats />
            case 'informer' : return <Informer />
        }
    }

    function getProfile (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 1 : return <Route exact path="/company/1/profile/index/" element={<Profile />} />;
            case 2 : return <Route exact path="/teacher/1/profile/index" element={<EmployeeProfile />} />
            case 3 : return <Route exact path="/customer/1/profile/index" element={<CustomerProfile />} />
        }
    }

    function getProfileElement (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 1 : return <Profile />
            case 2 : return <EmployeeProfile />
            case 3 : return <CustomerProfile />
        }
    }

    function getProfileLink (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 1 : return "/company/1/profile/index/";
            case 2 : return "/teacher/1/profile/index";
            case 3 : return "/customer/1/profile/index";
        }
    }
    // SWITCH END

    if (isAuthenticated) {
        return (
            <div className={localStorage.getItem('theme')}>
                <Header />
                <Routes>
                    {/* PROFILE */}
                    <Route exact path="/" element={ getProfileElement(role.interface) } />

                    {/*PAGE*/}
                    { menu.map(item => <Route exact path={infoMenu[item].link} element={switchElement(infoMenu[item].value)} />) }

                    {/*DOUBLE PAGE*/}
                    {
                        menu.includes(2) &&
                            (
                                <>
                                    <Route exact path="/company/1/lesson/index/" element={<Lesson/>} />
                                    <Route exact path="/company/1/calendar-teacher/index" element={<CalendarTeacher />} />
                                </>
                            )
                    }
                    { menu.includes(12) && <Route exact path="/company/1/user/role-index/" element={<Role/>} /> }
                    { menu.includes(7) && <Route exact path="/company/1/bill/index/" element={<Bill/>} /> }

                    {/*PROFILE*/}
                    { getProfile(role.interface) }

                    {
                        (role.interface === 1 || role.interface === 2) && (
                            <>
                                {/*CARD*/}
                                <Route exact path="/company/1/customer/view/" element={<CustomerCard/>} />
                                <Route exact path="/company/1/group/view/" element={<GroupCard/>}/>
                            </>
                        )
                    }

                    {
                        role.interface !== 3 &&
                            (
                                <>
                                    {/*FILTER*/}
                                    <Route exact path="/company/1/customer/index/:filterParams" element={<Customer />} />
                                    <Route exact path="/company/1/employee/index/:filterParams" element={<Employee/>}/>
                                    <Route exact path="/company/1/group/index/:filterParams" element={<Group />} />
                                    <Route exact path="/company/1/tariff/index/:filterParams" element={<Tariff />} />
                                    <Route exact path="/company/1/calendar/index/:filterParams" element={<Calendars/>}/>
                                    <Route exact path="/company/1/log/index/:filterParams" element={<Log />}  />
                                    <Route exact path="/company/1/user/index/:filterParams" element={<User />} />
                                    <Route exact path="/company/1/customer/view/:filterParams" element={<ReportCustomer />} />
                                    <Route exact path="/company/1/employee/view/:filterParams" element={<ReportEmployee />} />
                                    <Route exact path="/company/1/agile/index/:filterParams" element={<Agile />} />
                                    <Route exact path="/company/1/lead/index/:filterParams" element={<Lead />} />
                                    <Route exact path="/company/1/chats/index/:filterParams" element={<Chats />} />
                                    <Route exact path="/company/1/calendar-teacher/index/:filterParams" element={<CalendarTeacher />} />
                                </>
                            )
                    }

                    <Route exact path="/company/1/lesson/index/:filterParams" element={<Lesson/>} />
                    <Route exact path="/company/1/pay/index/:filterParams" element={<Pay/>}/>

                    {/*SETTING*/}
                    {
                        role.interface === 1 &&
                            (
                                <>
                                    <Route exact path="/settings/1/default/index" element={<Setting />} />
                                    <Route exact path="/settings/1/default/base-options" element={<Setting page={<BaseOptions/>} menu="base-options" />} />
                                    <Route exact path="/settings/1/study-status/index" element={<Setting page={<StatusClient/>} menu="study-status" />} />
                                    <Route exact path="/settings/1/level/index" element={<Setting page={<KnowledgeClient />} menu="knowledge-client" />} />
                                    <Route exact path="/settings/1/reason/index" element={<Setting page={<ReasonClient />} menu="reason-client" />} />
                                    <Route exact path="/settings/1/subject/index" element={<Setting page={<SubjectLesson />} menu="subject-lesson" />} />
                                    <Route exact path="/settings/1/streaming/index" element={<Setting page={<StreamLesson />} menu="stream-lesson" />} />
                                    <Route exact path="/settings/1/group-status/index" element={<Setting page={<StatusGroup />} menu="group-status" />} />
                                    <Route exact path="/settings/1/room/index" element={<Setting page={<RoomLesson />} menu="room-lesson" />} />
                                    <Route exact path="/settings/1/grade/index" element={<Setting page={<MarkLesson />} menu="mark-lesson" />} />
                                    <Route exact path="/settings/1/skill/index" element={<Setting page={<EmployeeQualification />} menu="emp-qual" />} />
                                    <Route exact path="/settings/1/pay-account/index" element={<Setting page={<PayAccount />} menu="pay-account" />} />
                                    <Route exact path="/settings/1/pay-item-category/index" element={<Setting page={<PayCategory />} menu="pay-category" />}/>
                                    <Route exact path="/settings/1/pay-item/index" element={<Setting page={<PayArticle />} menu="pay-article" />} />
                                    <Route exact path="/settings/1/customer-reject/index" element={<Setting page={<RefusalClient />} menu="refusal-client" />} />
                                    <Route exact path="/settings/1/custom-field/index" element={<Setting page={<CustomField />} menu="custom-field" />} />
                                    <Route exact path="/settings/1/branch/index" element={<Setting page={<Branch />} menu="branch" />} />
                                    <Route exact path="/settings/1/default/notification-options" element={<Setting page={<Notification />} menu="notification" />} />
                                    <Route exact path="/settings/1/default/customer-options" element={<Setting page={<AccountClient />} menu="accountClient" />} />
                                    <Route exact path="/settings/1/lead-source-category/index" element={<Setting page={<SourceGroup />} menu="sourceGroup" />} />
                                    <Route exact path="/settings/1/lead-source/index" element={<Setting page={<LeadSource />} menu="lead-source" />} />
                                    <Route exact path="/settings/1/required-field/index" element={<Setting page={<RequiredField />} menu="requiredField" />} />
                                    <Route exact path="/settings/1/default/lesson-options" element={<Setting page={<LessonOption />} menu="lessonOption" />} />
                                    <Route exact path="/settings/1/default/calendar-options" element={<Setting page={<CalendarOption />} menu="calendarOption" />} />
                                    <Route exact path="/settings/1/lead-status/index" element={<Setting page={<LeadStatus />} menu="leadStatus" />} />
                                    <Route exact path="/settings/1/telegram/index" element={<Setting page={<Telegram />} menu="telegram" />} />
                                    <Route exact path="/settings/1/facebook/index" element={<Setting page={<Facebook />} menu="facebook" />} />
                                    <Route exact path="/settings/1/lead-reject/index" element={<Setting page={<LeadReject />} menu="leadReject" />} />
                                    <Route exact path="/settings/1/lead-tags/index" element={<Setting page={<LeadTags />} menu="leadTags" />} />
                                    <Route exact path="/settings/1/default/teacher-options" element={<Setting page={<EmployeeOptions />} menu="emp-options" />} />

                                    {/* CARD */}
                                    <Route exact path="/company/1/employee/view/" element={<EmployeeCard/>} />
                                </>
                            )
                    }

                    {/*ERROR*/}
                    <Route exact path="*" element={<Error link={getProfileLink(role.interface)} />} />
                </Routes>
                <Footer />
            </div>
        );
    } else {
        // AUTH IS NOT SUCCESSFUL
        return (
            <div className={`main ${localStorage.getItem('theme')}`}>
                <Routes>
                    <Route exact path="*" element={<Auth />}/>
                </Routes>
            </div>
        )
    }
}