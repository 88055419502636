import "../../../../../Assets/css/SettingStyle/EditModal.css";
import {closeModal, filterDisable} from "../../../../../Function/common";
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {errorNotify} from "../../../../../Function/Notify";
import URL from "../../../../../Api/URL.json";

export default function EditArticle (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [type_payment, setType_payment] = useState([]);
    // STATE END

    // CONTEXT START
    const {paySetting} = useContext(SettingContext);
    // CONTEXT END

    // INIT DATA START
    useEffect(() => {
        if (!props.editArticle) return
        setName(props.editArticle.name);
        setCategory(props.editArticle.category);
        setType_payment(props.editArticle.type_payment);
    }, [props.editArticle])
    // INIT DATA END

    // EDIT ARTICLE START
    async function editArticle (e) {
        e.preventDefault();

        let elems = document.querySelectorAll('#edit-pay-item:checked');
        let type_payments = [].map.call(elems, function (obj) {
            return obj.value;
        })

        let validity = {
            "Категория" : !category.toString().length,
            "Название" : !name.length,
            "Типы платежей" : !type_payments.length,
            result: 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                errorNotify(`Необходимо заполнить "${key}"`);
                validity.result += 1;
            }
        }

        if (validity.result > 0) return

        let article = {
            id: props.editArticle.id,
            payment: props.editArticle.payment,
            name,
            category: +category,
            type_payment: type_payments,
            disabled: props.editArticle.disabled
        }

        if (props.editArticle.category !== +category) {
            paySetting.category = paySetting.category.map(item => {
                if (+item.id === +category) {
                    item.article += 1;
                    return item;
                } else if (+item.id === props.editArticle.category) {
                    item.article -= 1;
                    return item;
                } else {
                    return item;
                }
            })
        }

        paySetting.article = paySetting.article.map(item => {
            if (item.id === props.editArticle.id) {
                return article;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        if (error) return error;
        window.location.reload();
    }
    // EDIT ARTICLE END

    return (
        <div id="edit-article" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editArticle(e)}>
                        {/*HEADER*/}
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> Править статью </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*CATEGORY*/}
                            <div className="form-group field-payitem-category_id required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payitem-category_id">{translate('Категория')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => setCategory(e.target.value)}>
                                        <option value="">{translate('Не задано')}</option>
                                        {
                                            paySetting ?
                                                filterDisable(paySetting.category).map(item => (
                                                    <option value={item.id} selected={category === item.id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                                : null
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group field-payitem-name required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payitem-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Аренда')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*TYPE PAYMENT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label no-padding" htmlFor="payitem-pay_type_ids">{translate('Типы платежей')}</label>
                                    <br />
                                    <a className="small crm-dashed-link">
                                        {translate('выбрать все')}
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple">
                                    {
                                        type_payment ?
                                            (
                                                <>
                                                    <label>
                                                        <input type="checkbox" id="edit-pay-item" name="pay-item" value="1" defaultChecked={type_payment.includes("1")} /> {translate('Доход')}
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input type="checkbox" id="edit-pay-item" name="pay-item" value="2" defaultChecked={type_payment.includes("2")} /> {translate('Возврат средств')}
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input type="checkbox" id="edit-pay-item" name="pay-item" value="3" defaultChecked={type_payment.includes("3")} /> {translate('Корректировка')}
                                                    </label>
                                                </>
                                            )
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}