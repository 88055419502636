import {useTranslate} from "../../../../Hooks/translate.hook";

export default function ProfilePayBlock ({ payCounts }) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    return (
        <div className="row">
            <div className="col-xs-5 m-b-sm">
                { translate('Платежей') }
            </div>
            <div className="col-xs-7 m-b-sm text-right">
                { payCounts ? <span> { payCounts } { translate('шт.') }  </span> : <span className="text-danger font-bold"> { translate('ни одного') } </span> }
            </div>
        </div>
    )
}