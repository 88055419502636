import SideMenu from "../../Components/SideMenu/SideMenu";
import {Link} from "react-router-dom";
import {useRef} from "react";

export default function Bill () {

    // REF START
    const btn_pagination = useRef();
    // REF END

    // BUTTON FUNC START
    function buttonPagination () {
        btn_pagination.current.classList.toggle("open");
    }
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="pay"/>
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="/company/1/pay/index" className="btn btn-sm btn-white" title="Платежи">
                                        <i className="ion-cash" />
                                    </Link>
                                    <Link to="/company/1/bill/index" className="btn btn-sm btn-white active" title="Счёта">
                                        <i className="ion-pricetag" />
                                    </Link>
                                </div>
                                {/*BUTTON FILTER*/}
                                <a className="btn btn-sm btn-w-m btn-primary pull-right crm-ajax-link">
                                    <i className="fa fa-filter" />
                                    <span className="hidden-xs"> Фильтр </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body" style={{paddingTop: "10px"}}>
                                            <div id="pay-pjax">
                                                <div
                                                    id="w1"
                                                    className="grid-view is-bs3 hide-resize"
                                                    data-krajee-grid="kvGridInit_0e81f109"
                                                    data-krajee-ps="ps_w1_container">
                                                    {/*TABLE HEADER*/}
                                                    <div className="text-muted text-right m-b-xs">
                                                        <span style={{lineHeight: 0}}>
                                                            Отображены строки&nbsp;
                                                            <b>1 — 2</b>
                                                            . Всего в базе&nbsp;
                                                            <b>2</b>
                                                            . &nbsp;&nbsp;
                                                            <span className="visible-xs"><br /></span>
                                                            <a className="crm-ajax-link crm-dashed-link" href="#">
                                                                Настройка полей
                                                            </a>
                                                            . &nbsp; Пагинация&nbsp;
                                                            <span className="dropdown" ref={btn_pagination}>
                                                                <a href="#" className="dropdown-toggle crm-dashed-link" onClick={buttonPagination}>
                                                                    20 строк
                                                                </a>
                                                                <ul className="dropdown-menu" style={{marginLeft: "-90px"}}>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=20">
                                                                            20 строк
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=50">
                                                                            50 строк
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=100">
                                                                            100 строк
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=200">
                                                                            200 строк
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/company/1/pay/index?pageSize=500">
                                                                            500 строк
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    {/*TABLE BODY*/}
                                                    <div className="border-bottom" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}