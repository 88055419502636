import {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import {closeModal} from "../../../../../Function/common";
import URL from "../../../../../Api/URL.json";

export default function EditTags ({ editTag }) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ name, setName ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!Object.keys(editTag).length) return

        setName(editTag.name);

    }, [editTag])
    // INIT DATA END

    // EDIT TAGS START
    async function editTags (e) {
        e.preventDefault();

        if (!name.length) return errorNotify(`Необходимо заполнить "Название"`)

        const tag = {
            id: editTag.id,
            name,
            disabled: editTag.disabled
        }


        salesSetting.tags = salesSetting.tags.map(item => {
            if (item.id === editTag.id) {
                return tag;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', { salesSetting: JSON.stringify(salesSetting) })

        if (error) return error;

        window.location.reload();
    }
    // EDIT TAGS END

    return (
        <div id="edit-tags" className="modal in" style={{ paddingRight: '16px' }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editTags(e)}>
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> Редагувати тег </h4>
                        </div>
                        <div className="modal-body p-md">
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Название') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ `${translate('Например')}, fb` }
                                        value={ name }
                                        onChange={ e => setName(e.target.value) }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}