import "../../../../../Assets/css/SettingStyle/EditModal.css";
import {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import {closeModal} from "../../../../../Function/common";
import URL from "../../../../../Api/URL.json";

export default function EditLeadStatus (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ name, setName ] = useState('');
    const [ color, setColor ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (Object.keys(props.editLeadStatus).length === 0) return

        setName(props.editLeadStatus.name);
        setColor(props.editLeadStatus.color);
    }, [props.editLeadStatus])
    // INIT DATA END

    // EDIT LEAD STATUS START
    async function editLeadStatus (e) {
        e.preventDefault();

        if (!name.length) return errorNotify(`Необходимо заполнить "Название"`)

        let leadStatus = {
            id: props.editLeadStatus.id,
            name, color,
            disabled: props.editLeadStatus.disabled
        }

        salesSetting.leadStatus = salesSetting.leadStatus.map(item => {
            if (item.id === props.editLeadStatus.id) {
                return leadStatus;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', { salesSetting: JSON.stringify(salesSetting) })

        if (error) return error;

        window.location.reload();
    }
    // EDIT LEAD STATUS END

    return (
        <div id="edit-lead-status" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editLeadStatus(e)}>
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Править этап воронки')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Пробный урок')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Цвет') } </label>
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="color"
                                        className="form-control"
                                        value={color}
                                        onChange={e => setColor(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}