import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import {useEffect, useState} from "react";
import moment from "moment";
import URL from "../../../Api/URL.json";
import {Link} from "react-router-dom";

export default function TaskHeader ({ userData }) {

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [ task, setTask ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        const date_start = moment().tz('Europe/Kiev').toDate().toLocaleDateString('en-CA');
        const time_start = moment().tz('Europe/Kiev').format('HH:mm');
        const [ hours, minutes ] = time_start.split(':');
        const time_end = `${Number(hours)+2}:${minutes}`;

        async function fetchData () {
            let data = await request(`${URL.back_end}/filter_task`, 'POST', { nolimit : true, data : { user : userData.user_id, time_start, time_end, date_start, date_end : date_start } });

            for (let i = 0; i < data.tasks.length; i++) {
                const currentTask = data.tasks[i];
                const customers = await request(`${URL.back_end}/customer_task/${currentTask.task_id}`, 'GET');
                const groups = await request(`${URL.back_end}/group_task/${currentTask.task_id}`, 'GET');

                data.tasks[i].customers = customers;
                data.tasks[i].groups = groups;
            }

            setTask(data.tasks);
        }

        fetchData().catch(e => console.log(e))

    }, [userData])
    // INIT DATA END

    return (
        <>
            {
                task.length ? (
                    <a className="flashing" data-toggle="dropdown">
                        <i className="ion-clipboard text-success" style={{ fontSize : '15px' }} />
                    </a>
                ) : (
                    <a> <i className="ion-clipboard" style={{ fontSize : '15px' }} /> </a>
                )
            }
            <ul className="dropdown-menu dropdown-left" style={{ right : '-265px' }}>
                {
                    task.map(item => (
                        <li className="text-success" style={{ minWidth : '300px', maxWidth : '300px' }}>
                            <a style={{ margin : 0 }}>
                                { item.text.substring(0, 28) } (<b className="text-right"> { item.time } </b>)
                                <span className="pull-right text-muted font-bold m-l-sm"> { item.task_id } </span>
                                <br />
                                {
                                    item.customers.map(customer => (
                                        <Link to={`/company/1/customer/view?id=${customer.customer_id}`} className="no-padding m-t-xxs m-b-xxs m-r-xs">
                                            <small className="text-muted">
                                                <i className="ion-person m-r-xxs" /> { customer.name }
                                            </small>
                                        </Link>
                                    ))
                                }
                                <br />
                                {
                                    item.groups.map(group => (
                                        <Link to={`/company/1/group/view?id=${group.group_id}`} className="no-padding m-t-xxs m-b-xxs m-r-xs">
                                            <small className="text-muted">
                                                <i className="ion-person-stalker m-r-xxs" /> { group.title }
                                            </small>
                                        </Link>
                                    ))
                                }
                            </a>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}