import {memo} from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddLesson from "../../../Calendar/Modal/AddLesson";
import {openModal} from "../../../../Function/common";

export const LessonsBlock = memo(({ sqlCard, groupLesson, employeeData, customerData, groupData }) => {

    const { translate } = useTranslate();

    function renderInfo () {
        const plan = groupLesson.length;
        const fact = groupLesson.filter(item => item.status === 1).length;
        const cancel = groupLesson.filter(item => item.status === 2).length;

        return `п ${plan} / ф ${fact} / о ${cancel}`;
    }

    return (
        <>
            <div className="row">
                <div className="col-xs-5 text-muted m-b-sm">{translate('Уроки')}</div>
                <div className="col-xs-7 m-b-sm text-right">
                    <a className="crm-dashed-link" title="План / Факт / Отменен">
                        { renderInfo() }
                    </a>
                    <a className="text-success m-l-sm crm-dashed-link text-lowercase crm-modal-btn" onClick={() => openModal('add-lesson')}>
                        {translate('Добавить')}
                    </a>
                </div>
            </div>

            <AddLesson
                sql={sqlCard}
                employeeData={employeeData}
                customerData={customerData}
                groupData={groupData}
                group={sqlCard}
                type_lesson={2}
            />
        </>
    )
})