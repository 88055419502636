import {closeModal} from "../../../../../Function/common";
import {useContext, useRef, useState} from "react";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useHttp} from "../../../../../Hooks/http.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {errorNotify} from "../../../../../Function/Notify";
import URL from "../../../../../Api/URL.json";

export default function AddTurbo () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { sms } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ sender, setSender ] = useState('');
    const [ token, setToken ] = useState('');
    // STATE END

    // ADD TURBO START
    async function addTurbo (e) {
        e.preventDefault();

        let validity = {
            "Отправитель" : !sender.length,
            "Api Token" : token.length < 40,
            result : 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                validity.result += 1;
                errorNotify(`${translate('Необходимо заполнить')} "${ translate(key) }"`)
            }
        }

        if (validity.result > 0) return

        sms.turbo = { sender, token }

        await request(`${URL.back_end}/setting`, 'PUT', {sms: JSON.stringify(sms)})

        if (error) return error;

        window.location.reload();
    }
    // ADD TURBO END

    return (
        <div id="add-turbo" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addTurbo(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить')} TurboSms </h4>
                        </div>
                        <div className="modal-body p-md">
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="social-name"> { translate('Отправитель') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, RIST`}
                                        value={sender}
                                        onChange={e => setSender(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label" htmlFor="social-access_token">Api Token</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Вставьте токен') }
                                        value={token}
                                        onChange={e => setToken(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-success btn-w-m">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}