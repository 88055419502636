import SideMenu from "../../Components/SideMenu/SideMenu";
import '../../Assets/css/Log.css';
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import URL from '../../Api/URL.json';
import FilterLog from "./Modal/FilterLog";
import FeedElement from "./FeedElement/FeedElement";
import {useParams, useSearchParams} from "react-router-dom";
import {pagination} from "../../Function/common";
import {SettingContext} from "../../Context/SettingContext";

export default function Log () {

    // CONTEXT START
    const { pageSize } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    // HOOK END

    // STATE START
    const [sql, setSql] = useState([]);
    const [counts, setCounts] = useState(0);
    const [userData, setUserData] = useState([]);
    // STATE END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // FILTER PARAMS START
    const {filterParams} = useParams();
    // FILTER PARAMS END

    // INIT DATA START
    useEffect(() => {
        const params = filterParams ? JSON.parse(filterParams) : {};

        async function fetchData () {
            const logs = await request(`${URL.back_end}/log_filter`, 'POST', { page, limit: 20, data : params });

            if (error) throw error;

            setSql(logs.log);
            setCounts(logs.counts);
        }

        fetchData().catch(e => console.log(e))
    }, [page, filterParams])

    useEffect(() => {

        async function fetchData () {
            const users = await request(`${URL.back_end}/user`, 'GET');

            if (error) throw error;

            setUserData(users);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="log" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "656px"}}>
                <div className="wrapper crm-content">
                    <div className="row">
                        {/*MAIN INFO*/}
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content">
                                    <div id="w0" className="feed-activity-list">
                                        { sql.map(item => <FeedElement data={item} user={userData.filter(user => user.user_id === item.user_id)[0]} />) }
                                    </div>
                                    <div className="text-center" translate="no">
                                        { pageSize && pagination(counts, pageSize, 'log', page) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FILTER*/}
                        <FilterLog
                            userData={userData}
                            value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}