import {useRef, useState} from "react";
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal} from "../../../../../Function/common";
import {validate} from "../../../../../Function/validateForm";
import {useArchive} from "../../../../../Hooks/archive.hook";
import moment from "moment";

export default function AddCustomer (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [customer, setCustomer] = useState({});
    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState('');
    // STATE END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (dateEnd) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(dateStart))).asDays();

            const newDateEnd = moment(dateEnd).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function getDiapasonDate(start, end) {
        const date_end = new Date(end);
        date_end.setDate(date_end.getDate() + 1);

        for (var arr = [], dt = new Date(start); dt <= new Date(date_end); dt.setDate(dt.getDate()+1) ){
            arr.push(new Date(dt).toLocaleDateString());
        }

        return arr;
    }
    // HANDLE END

    // ADD CUSTOMER TO GROUP START
    async function addCustomer (e) {
        e.preventDefault();

        let validObj = {
            "Клиент" : !Object.keys(customer).length,
            "Период участия" : !dateStart || !dateEnd
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        const customerGroup = await request(`${URL.back_end}/group_customer/${props.sql.group_id}`, 'POST', { customer_id: +customer.customer_id, date_start: dateStart, date_end: dateEnd });

        if (props.regularLesson.length) {

            const diapasonMember = getDiapasonDate(dateStart, dateEnd);

            for (let i = 0; i < props.regularLesson.length; i++) {
                let regular = await request(`${URL.back_end}/regular_lesson/${props.regularLesson[i].regular_id}`, 'GET');
                regular = regular.filter(reg => diapasonMember.includes(new Date(reg.date_start).toLocaleDateString()) && reg);

                for (let j = 0; j < regular.length; j++) {
                    await request(`${URL.back_end}/regular_lesson_customer/${regular[j].lesson_id}`, 'POST', { customer_id : +customer.customer_id })
                }

                await request(`${URL.back_end}/customer_regular_lesson/${customer.customer_id}`, 'POST', props.regularLesson[i]);
            }
        }

        await pushArchive(1, 'customerGroup', customerGroup.insertId, { group_id : props.sql.group_id, customer_id: +customer.customer_id, date_start: dateStart, date_end: dateEnd }, null);

        if (error) return console.log(error);

        window.location.reload();
    }
    // ADD CUSTOMER TO GROUP END

    return (
        <div id="add-customer" className="modal in" role="dialog" style={{ paddingRight: "16px" }} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span>×</span>
                        </button>
                        <h4 className="modal-title"> {translate('Добавить участника')} </h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addCustomer(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*WARNING*/}
                            {
                                props.sql.limit && props.sql.limit <= props.groupCustomer.length && (
                                    <p className="p-xs bg-warning m-b-md text-center">
                                        { translate('Достигнут лимит участников. Максимум') } { props.sql.limit }
                                    </p>
                                )
                            }
                            {/*TITLE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Группа')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={props.sql.title}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            {/*CUSTOMER*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Клиент')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={props.dataCustomer.filter(item => item.f_removed === 0)}
                                        topicSearch="name"
                                        type_id="customer_id"
                                        setProps={setCustomer}
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Период участия')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <span className="input-group-addon">{translate('с')}</span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={dateStart}
                                                    onChange={(event) => dateStartHandler(event)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <span className="input-group-addon">{translate('по')}</span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    max={moment(dateStart).add(2, 'year').format('YYYY-MM-DD')}
                                                    min={moment(dateStart).add(1, 'day').format('YYYY-MM-DD')}
                                                    value={dateEnd}
                                                    onChange={(event) => setDateEnd(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}