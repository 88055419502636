import {useTranslate} from "../../../../Hooks/translate.hook";
import {openModal} from "../../../../Function/common";

export default function UserRow(props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    function editUser() {
        props.setEditUser(props.data);
        openModal('edit-user');
    }

    return (
        <div className="row crm-hover-block" onClick={editUser}>
            <a className="crm-modal-btn">
                <div className="col-sm-12 p-xxs">
                    <i className="ion-edit pull-right" />
                    <strong className="text-success"> { props.data.email } </strong>
                    &nbsp;—&nbsp;
                    <small className="text-muted"> { translate('Клиент') } </small>
                </div>
            </a>
        </div>
    )
}