import InputMask from "react-input-mask";
import InputChooseMultiObject from "../../../../../Components/InputChoose/InputChooseMultiObject";
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from '../../../../../Api/URL.json';
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {limitYearDate, closeModal, filterDisable, findObject, openModal} from "../../../../../Function/common";
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import InputChooseSingle from "../../../../../Components/InputChoose/InputChooseSingle";
import {validate} from "../../../../../Function/validateForm";
import {useArchive} from "../../../../../Hooks/archive.hook";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";
import moment from "moment";

export default function EditRegularLesson (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // STATE START
    const [dow, setDow] = useState([]);
    const [timeStart, setTimeStart] = useState('');
    const [timeEnd, setTimeEnd] = useState('');
    const [audience, setAudience] = useState({});
    const [subject, setSubject] = useState({});
    const [employee, setEmployee] = useState([]);
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');

        // HELP STATE
    const [minute, setMinute] = useState('60');
    // STATE END

    // VALID STATE START
    const [timeStartError, setTimeStartError] = useState('');
    const [timeEndError, setTimeEndError] = useState('');
    // VALID STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.editRegularLesson).length) return

        setDow(switchDowReverse(props.editRegularLesson.dow));
        setTimeStart(props.editRegularLesson.time_start);
        setTimeEnd(props.editRegularLesson.time_end);
        setAudience({id: props.editRegularLesson.audience});
        setSubject({id: props.editRegularLesson.subject});
        setDateStart(props.editRegularLesson.date_start);
        setDateEnd(props.editRegularLesson.date_end);

        if (props.editRegularLesson.employee) {
            let employee_ids = JSON.parse(props.editRegularLesson.employee);
            let employee_arr = props.employeeData.filter(item => employee_ids.includes(item.employee_id));
            setEmployee(employee_arr);
        } else {
            setEmployee([]);
        }

    }, [props.editRegularLesson])
    // INIT DATA END

    // MODAL FUNC START
    function switchDow (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case `${translate('Понедельник')}` :
                return 1;
            case `${translate('Вторник')}`:
                return 2;
            case `${translate('Среда')}`:
                return 3;
            case `${translate('Четверг')}`:
                return 4;
            case `${translate('Пятница')}`:
                return 5;
            case `${translate('Суббота')}`:
                return 6;
            case `${translate('Воскресенье')}`:
                return 0;
        }
    }
    function switchDowReverse (value) {
        // eslint-disable-next-line default-case
        switch (value) {
            case 1 :
                return `${translate('Понедельник')}`;
            case 2:
                return `${translate('Вторник')}`;
            case 3:
                return `${translate('Среда')}`;
            case 4:
                return `${translate('Четверг')}`;
            case 5:
                return `${translate('Пятница')}`;
            case 6:
                return `${translate('Суббота')}`;
            case 0:
                return `${translate('Воскресенье')}`;
        }
    }
    // MODAL FUNC END

    // HANDLER START
    function dateStartHandler (event) {
        const newDateStart = event.target.value;
        setDateStart(newDateStart);

        if (!newDateStart) {
            setDateEnd('');
        } else if (dateEnd) {
            const diffDateDays = moment.duration(moment(newDateStart).diff(moment(dateStart))).asDays();

            const newDateEnd = moment(dateEnd).add(diffDateDays, 'day').format('YYYY-MM-DD');
            setDateEnd(newDateEnd);
        }
    }

    function timeStartHandler(e) {
        setTimeStart(e.target.value);
        if ((/_/g).test(e.target.value)) {
            setTimeStartError(`${translate('Необходимо заполнить')} «${translate('Начало')}».`);
        } else {
            setTimeStartError('');
        }
        durationHandler(e.target.value)
    }
    function minuteHandler(e) {
        setMinute(e.target.value);
        durationHandler(timeStart);
    }
    function timeEndHandler(e) {
        setTimeEnd(e.target.value);
        if ((/_/g).test(e.target.value)) {
            setTimeEndError(`${translate('Необходимо заполнить')} «${translate('Конец')}».`);
        } else {
            setTimeEndError('');
        }
        if (!e.target.value || !timeStart) return
        let [startHour, startMinute] = timeStart.split(":");
        let [endHour, endMinute] = e.target.value.split(":");
        let result_minute = `${(Number(endHour)*60+Number(endMinute))-(Number(startHour)*60+Number(startMinute))}`;
        setMinute(Number(result_minute) ? result_minute : "");
    }
    function durationHandler(start) {
        let help_minute = document.getElementById('edit-help-minute').value;
        if (!start || !help_minute) return
        let [startHour, startMinute] = start.split(":");
        let date = new Date();
        date.setHours(+startHour);
        date.setMinutes(+startMinute+Number(help_minute));
        setTimeEnd(`${date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()}`);
    }
    // HANDLER END

    // DELETE LESSON START
    function deleteHandler () {
        openModal('delete-regular-modal');
        openModal('delete-regular-overlay');
    }

    async function deleteLesson () {
        await request(`${URL.back_end}/regular_lesson/${props.editRegularLesson.regular_id}`, 'DELETE');
        await pushArchive(3, 'regularLesson', props.editRegularLesson.regular_id);

        window.location.reload();
    }
    // DELETE LESSON END

    // EDIT REGULAR LESSON START
    async function editRegularLesson(e) {
        e.preventDefault();

        let validObj = {
            "День недели" : !dow.length,
            "Длительность" : (!timeStart.length || !timeEnd.length),
            "Период" : (!dateStart.length || !dateEnd.length),
            "Предмет" : !Object.keys(subject).length,
            "Педагог(и)" : !employee.length
        }

        if (validate(validObj, translate)) return

        e.currentTarget.disabled = true;

        const lesson = {
            lesson_id: props.editRegularLesson.regular_id,
            dow : switchDow(dow),
            time_start: timeStart,
            time_end: timeEnd,
            audience: audience.id,
            subject: subject.id,
            customer: [{name: props.sql.name, customer_id: props.sql.customer_id}],
            employee: employee,
            date_start: dateStart,
            date_end: dateEnd,
            group: props.editLessonGroup.title ? [{ name: props.editLessonGroup.title, group_id: props.editLessonGroup.group_id }] : [],
            type_lesson: props.editRegularLesson.type_lesson
        }

        await request(`${URL.back_end}/regular_lesson/${props.editRegularLesson.regular_id}`, 'PUT', lesson);
        await pushArchive(2, 'regularLesson', props.editRegularLesson.regular_id, { ...lesson, regular_id : props.editRegularLesson.regular_id, customer_id : props.sql.customer_id }, props.editRegularLesson);

        if (error) return console.log(error);

        window.location.reload();
    }
    // EDIT REGULAR LESSON END

    return (
        <div id="edit-regular-lesson" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editRegularLesson(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title">{translate('Регулярный урок')} ({props.editRegularLesson.type_lesson === 2 ? props.editLessonGroup.title : props.sql.name})</h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*WARNING*/}
                            <p className="p-xs bg-warning m-b-md text-center">
                                {
                                    props.editRegularLesson.type_lesson === 2 ? <>{translate('Сохраняется для группы')} <strong> { props.editLessonGroup.title } </strong></> :
                                        <>{translate('Сохраняется для клиента')} <strong>{props.sql.name}</strong></>
                                }
                            </p>
                            {/*DAY OF WEEK*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('День недели')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingle
                                        data={[`${translate('Понедельник')}`, `${translate('Вторник')}`, `${translate('Среда')}`, `${translate('Четверг')}`, `${translate('Пятница')}`, `${translate('Суббота')}`, `${translate('Воскресенье')}`]}
                                        setProps={setDow}
                                        getProps={dow}
                                        value={dow}
                                    />
                                </div>
                            </div>
                            {/*DURATION*/}
                            <div className={`form-group required ${timeStartError || timeEndError ? "has-error" : null}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Длительность')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        {/*TIME START*/}
                                        <div className="col-sm-4">
                                            <input
                                                type="time"
                                                className="form-control time-input time-to text-center"
                                                value={timeStart}
                                                onChange={(event) => timeStartHandler(event)}
                                            />
                                            <div className="help-block">{timeStartError ? timeStartError : null}</div>
                                        </div>
                                        {/*MINUTE*/}
                                        <div className="col-sm-4">
                                            <span className="text-muted" style={{position: "absolute", margin: "8px 0 0 10px"}}>{translate('мин')}</span>
                                            <input
                                                type="number"
                                                className="form-control duration-input text-center"
                                                id="edit-help-minute"
                                                min={0}
                                                max={300}
                                                value={minute}
                                                onChange={e => minuteHandler(e)}
                                            />
                                        </div>
                                        {/*TIME END*/}
                                        <div className="col-sm-4">
                                            <input
                                                type="time"
                                                className="form-control time-input time-to text-center"
                                                value={timeEnd}
                                                min={timeStart}
                                                onChange={(event) => timeEndHandler(event)}
                                            />
                                            <div className="help-block">{timeEndError ? timeEndError : null}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*AUDIENCE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Аудитория')}</label>
                                </div>
                                <div className="col-sm-8">
                                    {
                                        <InputChooseSingleObject
                                            sql={lessonSetting ? filterDisable(lessonSetting.room) : []}
                                            setProps={setAudience}
                                            value={lessonSetting ? findObject(lessonSetting.room, audience.id) ? findObject(lessonSetting.room, audience.id).name : null : null}
                                            topicSearch="name"
                                            type_id="id"
                                        />
                                    }
                                </div>
                            </div>
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Предмет') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.subject) : []}
                                        setProps={setSubject}
                                        value={lessonSetting ? findObject(lessonSetting.subject, subject.id) ? findObject(lessonSetting.subject, subject.id).name : null : null}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Педагог(и)') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseMultiObject
                                        sql={props.employeeData.filter(item => item.f_removed === 0)}
                                        setProps={setEmployee}
                                        topicSearch="name"
                                        type_id="employee_id"
                                        value={employee}
                                    />
                                </div>
                            </div>
                            {/*PERIOD*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Период')}</label>
                                </div>
                                {/*DATE START*/}
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <span className="input-group-addon">↦</span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={dateStart}
                                            onChange={(event) => dateStartHandler(event)}
                                        />
                                    </div>
                                </div>
                                {/*DATE END*/}
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input
                                            type="date"
                                            className="form-control"
                                            max={moment(dateStart).add(1, 'year').format('YYYY-MM-DD')}
                                            min={moment(dateStart).add(1, 'day').format('YYYY-MM-DD')}
                                            value={dateEnd}
                                            onChange={e => setDateEnd(e.target.value)}
                                        />
                                        <span className="input-group-addon">↤</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <span className="pull-left">
                                <a className="btn btn-danger btn-outline" onClick={deleteHandler}>
                                    <i className="fa fa-trash-o" />
                                </a>
                            </span>
                            <button type="button" className="btn btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <DeleteModal
                id="delete-regular"
                handler={deleteLesson}
                text={translate('Вы уверены, что хотите удалить запись?')}
            />
        </div>
    )
}