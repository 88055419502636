// HOOK
import {useContext, useState} from "react";
import {useTranslate} from "../../Hooks/translate.hook";

// MODAL
import EditLesson from "../../Pages/Calendar/Modal/EditLesson";
import SpendLesson from "../../Pages/Lesson/Modal/SpendLesson";

// JSON
import iconCalendar from '../../Assets/data/JSON/iconCalendar.json';
import iconStatus from '../../Assets/data/JSON/iconStatus.json';

// COMPONENT
import CalendarPopover from "../Popover/CalendarPopover";

// SHORTID
import shortid from "shortid";
import {useHttp} from "../../Hooks/http.hook";
import URL from "../../Api/URL.json";
import {getTariffCount} from "../../Function/common";
import {SettingContext} from "../../Context/SettingContext";

export default function SpongeBob (props) {

    // CONTEXT START
    const { currentTime } = useContext(SettingContext);
    // CONTEXT END

    // VALUE START
    const dayOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    let { countLesson } = getTariffCount(props.tariffData);
    // VALUE END

    // HOOK START
    const { translate } = useTranslate();
    const { request } = useHttp();
    // HOOK END

    // STATE START
    const [legendCheck, setLegendCheck] = useState(false);
    const [popoverData, setPopoverData] = useState({});
    const [popoverStyle, setPopoverStyle] = useState({});
    const [editLesson, setEditLesson] = useState({});
    // STATE END

    function shortDate(innerDate) {
        const [year, month, day] = innerDate.split("T")[0].split("-");
        return `${day}.${month}`;
    }

    function getStatus (lesson, time) {
        const { presence, status } = lesson;

        if (time && status === 0) return iconStatus[4]

        if (presence || presence === null) {
            return iconStatus[status];
        } else {
            return iconStatus["3"];
        }
    }

    function getBackground (lesson, count) {
        let { status, commission } = lesson;
        let presence = !!lesson.presence;
        let debt = !!lesson.debt;

        if (presence && Number(commission) > 0 && status === 1 && debt === false) {
            return "bg-success text-navy";
        } else if (presence === false && Number(commission) === 0 && status === 1) {
            return "bg-warning text-warning";
        } else if (presence === false && Number(commission) > 0 && status === 1 && debt === false) {
            return "bg-warning text-danger";
        } else if (status === 2) {
            return "gray-bg text-muted archive";
        } else if (status === 0 && commission === null && presence === false && debt === false) {
            if (count > 0) {
                return "bg-success text-navy";
            } else {
                return "gray-bg pull-left";
            }
        } else if (presence && Number(commission) > 0 && status === 1 && debt === true) {
            return "bg-danger text-danger";
        } else if (presence === false && Number(commission) > 0 && status === 1 && debt === true) {
            return "bg-danger text-danger";
        } else if (presence && Number(commission) === 0 && status === 1 && debt === false) {
            return "bg-blue text-blue";
        }
    }

    async function showPopover (e, item) {

        setPopoverData({});

        const customers = await request(`${URL.back_end}/lesson_customer/${item.lesson_id}`, 'GET');
        const employees = await request(`${URL.back_end}/lesson_employee/${item.lesson_id}`, 'GET');
        const groups = await request(`${URL.back_end}/lesson_group/${item.lesson_id}`, 'GET');
        const storageFile = await request(`${URL.back_end}/get-files/lesson/${item.lesson_id}`, 'GET');

        setPopoverData({
            lesson_id: item.lesson_id,
            regular_id: item.regular_id,
            type_lesson: item.type_lesson,
            date_start: item.date_start,
            date_end: item.date_end,
            startTime: item.time_start,
            endTime: item.time_end,
            audience: item.audience,
            subject: item.subject,
            topic: item.topic,
            homework: item.homework,
            l_note: item.l_note,
            dow: item.dow,
            status: item.status,
            customer: customers,
            employee: employees,
            group: groups,
            storageFile,
            access : props.access
        });

        const top = props.chatView ? 900 : props.access === 1 ? 850 : ( props.access === 2 ? 160 : 300 );

        setPopoverStyle({
            top: `${e.pageY - top}px`,
            left: `${e.pageX - (props.chatView ? 490 : 160)}px`,
            minWidth: "330px",
            display: "block"
        })
    }

    function filterLesson (lessonData) {
        if (lessonData !== undefined) return lessonData.sort((a, b) => new Date(a.date_start) - new Date(b.date_start));
    }

    return (
        <>
            <div className="sponge-bob">
                {
                    // eslint-disable-next-line array-callback-return
                    filterLesson(props.lessonData).map(lesson => {
                        if (new Date(currentTime.split('T')[0]).getTime() > new Date(lesson.date_end).getTime()) {

                            return (
                                (
                                    <div className={`${getBackground(lesson)} pull-left m-b-xs m-r-xs text-center bob-field ${lesson.status === 0 ? 'red_flash_bob' : ''}`} onClick={e => showPopover(e, lesson)} key={shortid.generate()}>
                                        <i className={getStatus(lesson, true)} />
                                        <i className={`${iconCalendar[lesson.type_lesson]} popover-container m-r-xxs`} />
                                        { translate( dayOfWeek[ new Date( lesson.date_start.split('T')[0] ).getDay() ] ) }
                                        <br />
                                        { shortDate(lesson.date_start) }
                                    </div>
                                )
                            )
                        }
                    })
                }
                <div className="pull-left m-r-xs m-b-xs text-center font-bold bob-divider" title={translate('Слева от флага - прошедшие занятия, справа - будущие.')}>
                    <i className="ion-flag" />
                    <br /> { translate('сейчас') }
                </div>
                {
                    // eslint-disable-next-line array-callback-return
                    filterLesson(props.lessonData).map(lesson => {
                        if (new Date(currentTime.split('T')[0]).getTime() < new Date(lesson.date_end).getTime()) {

                            let count = 0;

                            if (lesson.status === 0) {
                                let [ value ] = countLesson.filter(item => item.lessons.includes(lesson.subject) && item.type_lessons.includes(lesson.type_lesson) && item.count > 0);

                                if (value) {
                                    count = value.count;
                                    countLesson = countLesson.map(item => {
                                        if (item.id === value.id) {
                                            item.count -= 1;
                                            return item;
                                        } else {
                                            return item;
                                        }
                                    })
                                }
                            }

                            return (
                                (
                                    <div className={`${getBackground(lesson, count)} pull-left m-b-xs m-r-xs text-center bob-field`} onClick={e => showPopover(e, lesson)} key={shortid.generate()}>
                                        <i className={getStatus(lesson)} />
                                        <i className={`${iconCalendar[lesson.type_lesson]} popover-container m-r-xxs`} />
                                        { translate( dayOfWeek[ new Date( lesson.date_start.split('T' )[0]).getDay() ] ) }
                                        <br />
                                        { shortDate(lesson.date_start) }
                                    </div>
                                )
                            )
                        }
                    })
                }
            </div>
            <div className="clearfix" />
            <div className="row sponge-bob-legend m-t-sm">
                <div className={`col-sm-4 m-b-xs toggle-hide ${legendCheck ? "hide" : ""}`}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="toggle-hide crm-dashed-link bob-legend-toggle" onClick={() => setLegendCheck(true)}>
                        {translate('Показать легенду')}
                    </a>
                </div>
                <div className={legendCheck ? "" : "hide"}>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="gray-bg">01.01</span>
                        { translate('Запланирован') }
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-success text-navy">01.01</span>
                        { translate('Предоплачен') }
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-blue text-blue"><i className="fa fa-check bob-done" />01.01</span>
                        { translate('Проведён бесплатно') }
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-success text-navy"><i className="fa fa-check bob-done" />01.01</span>
                        {translate('Проведён и оплачен')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-danger text-danger"><i className="fa fa-check bob-done" />01.01</span>
                        {translate('Проведён в долг')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-danger text-danger"><i className="fa fa-times bob-done" />01.01</span>
                        {translate('Пропуск в долг')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-warning text-warning"><i className="fa fa-times bob-done" />01.01</span>
                        {translate('Бесплатный пропуск')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="bg-warning text-danger"><i className="fa fa-times bob-done" />01.01</span>
                        {translate('Пропуск оплач.')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="gray-bg text-muted"><i className="fa fa-question bob-done" />01.01</span>
                        {translate('Забыли провести?')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="gray-bg text-muted archive"><i className="ion-pause bob-done" />01.01</span>
                        {translate('Приостановлен клиентом')}
                    </div>
                    <div className="col-sm-4 m-b-xs toggle-hide">
                        <span className="gray-bg text-muted archive"><i className="fa fa-minus-circle bob-done" />01.01</span>
                        { translate('Отменён') }
                    </div>
                </div>
            </div>
            {/*MODAL*/}

            <CalendarPopover
                data={popoverData}
                setData={setPopoverData}
                popoverStyle={popoverStyle}
                setPopoverStyle={setPopoverStyle}
                setEditLesson={setEditLesson}
                side={"right"}
                sideStyle={{top: "50%"}}
            />

            <EditLesson
                groupData={props.groupData}
                employeeData={props.employeeData}
                editLesson={editLesson}
            />

            <SpendLesson
                employeeData={props.employeeData}
                groupData={props.groupData}
                customerData={props.customerData}
                editLesson={popoverData}
            />
        </>
    )
}