import "../../../../../Assets/css/SettingStyle/EditModal.css";
import {closeModal} from "../../../../../Function/common";
import {useContext, useEffect, useRef, useState} from "react";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import URL from "../../../../../Api/URL.json";

export default function EditCategory (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {paySetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.editCategory) return
        setName(props.editCategory.name);
    }, [props.editCategory])
    // INIT DATA END

    // EDIT CATEGORY START
    async function editCategory (e) {
        e.preventDefault();

        if (!name.length) return errorNotify(`Необходимо заполнить "Название"`)

        let category = {
            id: props.editCategory.id,
            name,
            article: props.editCategory.article,
            disabled: props.editCategory.disabled
        }

        paySetting.category = paySetting.category.map(item => {
            if (item.id === props.editCategory.id) {
                return category;
            } else {
                return item;
            }
        })

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        if (error) return error;
        window.location.reload();
    }
    // EDIT CATEGORY END

    return (
        <div id="edit-category" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => editCategory(e)}>
                        {/*HEADER*/}
                        <div className="fix-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> Править категорию статей </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payitemcategory-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Маркетинг')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="fix-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}