import {useEffect, useMemo, useRef, useState} from "react";
import shortid from "shortid";
import '../../Assets/css/InputChooseSingle.css';
import {useTranslate} from "../../Hooks/translate.hook";

export default function InputChooseSingle (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // REF START
    let chosen_drop = useRef();
    let chosen_container = useRef();
    let chosen_result = useRef();
    let chosen_single = useRef();
    // REF END

    // STATE START
    const [choseValue,setChoseValue] = useState(translate('Выберите'));
    const [searchValue, setSearchValue] = useState('');
    // STATE END

    // CHECK PROPS START
    useEffect(() => {
        if (props.value !== undefined && props.value !== "") {
            setChoseValue(props.value);
            chosen_single.current.classList = "chosen-single";
        }
    }, [props])
    // CHECK PROPS END

    // USE MEMO START
    const searchItems = useMemo(() => {
        if (searchValue !== "" && searchValue !== " ")
        {
            return props.data.filter(item => item.includes(searchValue));
        } else {
            return [];
        }
    },[searchValue])
    // USE MEMO END

    // SHOW CHOSEN START
    function chosenShow () {
        chosen_drop.current.style.left = "0";
        chosen_container.current.classList += " chosen-container-active chosen-with-drop"
        document.addEventListener("click", function (event) {
            if (!chosen_container.current.contains(event.target)) {
                chosen_drop.current.style.left = "-9999px";
                chosen_container.current.classList = "chosen-container chosen-container-single";
            }
        })
    }
    // SHOW CHOSEN END

    // CUSTOM INPUT FUNC START
    function addChoice (e) {
        setChoseValue(e.target.dataset.name);
        props.setProps(e.target.dataset.name);
        chosen_drop.current.style.left = "-9999px";
        chosen_container.current.classList = "chosen-container chosen-container-single";
        chosen_single.current.classList = "chosen-single";
    }

    function resetChose () {
        setChoseValue(translate('Выберите'));
        props.setProps('');
        chosen_single.current.classList = "chosen-single chosen-default";
    }

    function searchQuery (e) {
        setSearchValue(e.target.value);
    }
    // CUSTOM INPUT FUNC END

    return (
        <div className="chosen-container chosen-container-single" style={{width: "100%"}} ref={chosen_container}>
            <a className="chosen-single chosen-default" tabIndex={-1} onClick={chosenShow} ref={chosen_single}>
                <span>{choseValue}</span>
                {choseValue !== translate('Выберите') ? <abbr className="search-choice-close" onClick={resetChose} /> : null}
                <div>
                    <b />
                </div>
            </a>
            <div className="chosen-drop" ref={chosen_drop}>
                <div className="chosen-search">
                    <input type="text" autoComplete="off" onChange={e => searchQuery(e)} value={searchValue} />
                </div>
                <ul className="chosen-results" ref={chosen_result}>
                    {
                        searchItems.length > 0 ?
                            searchItems.map (item => (
                                <li className="active-result" data-name={item} key={shortid.generate()} onClick={e => addChoice(e)}>{item}</li>
                            )) :
                            props.data.map(item => (
                                <li className="active-result" data-name={item} key={shortid.generate()} onClick={e => addChoice(e)}>{item}</li>
                            ))
                    }
                </ul>
            </div>
        </div>
    )
}