import SideMenu from "../../Components/SideMenu/SideMenu";
import InputMask from "react-input-mask";
import URL from '../../Api/URL.json';

import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../Context/AuthContext";
import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";

// TOAST
import { ToastContainer } from 'react-toastify';
import {success} from "../../Function/Notify";
import 'react-toastify/dist/ReactToastify.css';
import {useArchive} from "../../Hooks/archive.hook";

export default function Profile () {

    // REF START
    const passwordInput = useRef();
    // REF END

    // CONTEXT START
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { error, request } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [sqlCard, setSqlCard] = useState({});

    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState(undefined);
    const [language, setLanguage] = useState('');
    // STATE END

    // VALID STATE START
    const [formValid, setFormValid] = useState(false);
    const [nameError, setNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    // VALID STATE END

    // API GET INFO START
    useEffect(() => {
        async function fetchData () {
            const data = await request(`${URL.back_end}/user/${auth.userId}`, 'GET');

            setSqlCard(data[0]);
            setName(data[0].name);
            setGender(data[0].gender);
            setPhone(data[0].phone);
            setLanguage(data[0].interface_language);
        }

        fetchData().catch(e => console.log(e))

    }, [auth.userId, request])
    // API GET INFO END

    // VALID USE EFFECT START
    useEffect(() => {
        if (nameError || passwordError) {
            setFormValid(false)
        } else {
            setFormValid(true);
        }
    }, [name, password])
    // VALID USE EFFECT END

    // HANDLER START

    function nameHandler (e) {
        setName(e.target.value);

        if (e.target.value.length === 0) {
            setNameError(`${translate('Необходимо заполнить')} «${translate('ФИО')}».`);
        } else {
            setNameError('');
        }
    }

    function passwordHandler (e) {
        setPassword(e.target.value);

        const re_number = /(?=.*[0-9]).*/;
        const re_letter = /(?=.*[^A-Za-z0-9]).*/;

        if (e.target.value.length <= 6) {
            setPasswordError(`${translate('Количество символов должно быть не меньше 6.')}`)
        } else {
            if (!re_number.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать хотя бы одну цифр.')}`)
            } else if (re_letter.test(e.target.value)) {
                setPasswordError(`${translate('Пароль должен содержать только латинские буквы и цифры.')}`)
            } else {
                setPasswordError('');
            }
        }
    }

    function checkPasswordHandler (e) {
        passwordInput.current.disabled = !e.target.checked;
        setPasswordError('');
        setPassword(undefined);
    }
    // HANDLER END

    // CHANGE USER START
    async function changeUser (e) {
        e.preventDefault();

        const user = { name, gender, phone, password, interface_language: language }

        localStorage.setItem('i18n', language);

        await request(`${URL.back_end}/user/${sqlCard.user_id}`, 'PUT', user);
        await pushArchive(2, 'user', sqlCard.user_id, { ...user, role : sqlCard.role, email : sqlCard.email, avatar : sqlCard.avatar, user_id : sqlCard.user_id, password : sqlCard.password }, sqlCard);

        if (error) return console.log(error);

        window.location.reload();

    }
    // CHANGE USER END

    // FUNC START
    function encodeImageFileAsURL(element) {
        let file = element.currentTarget.files[0];
        let reader = new FileReader();
        reader.onloadend = async function() {
            await request(`${URL.back_end}/user/${sqlCard.user_id}`, "PUT", {avatar: reader.result})
            document.getElementById("ava").src = `${reader.result}`;
        }
        reader.readAsDataURL(file);
    }

    function renderGender (gender) {
        if (gender === 1) {
            return (<><i className="ion-man" /> { translate('Мужчина') }</>)
        } else if (gender === 2) {
            return (<><i className="ion-woman" /> { translate('Женщина') } </>)
        }
    }
    // FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <ToastContainer />
            <SideMenu page="profile" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{ minHeight: "624px" }}>
                <div className="wrapper crm-content">
                    <div className="row">
                        {/*PROFILE INFO*/}
                        <div className="col-md-8 col-sm-7">
                            <div className="ibox m-b-sm">
                                <div className="ibox-content border-bottom p-sm">
                                    {/*HEADER*/}
                                    <div className="m-b-sm flex items-center">
                                        {/*AVATAR*/}
                                        <span className="dropdown pull-left m-r-sm">
                                            <label className="no-margins" htmlFor="avatar">
                                                <a className="crm-avatar-btn" title={translate('Загрузить фото')} style={{position: "relative", zIndex: 1}}>
                                                    <img
                                                        className="img-thumbnail img-circle"
                                                        id="ava"
                                                        src={ sqlCard.avatar }
                                                        style={{width: "50px", height: "50px"}}
                                                        alt="avatar"
                                                    />
                                                </a>
                                            </label>
                                            <input type="file" id="avatar" onChange={e => encodeImageFileAsURL(e)} style={{display: "none"}} />
                                        </span>
                                        {/* NAME */}
                                        <h2 className="font-noraml no-margins no-padding flex-1">
                                            {sqlCard.name}
                                        </h2>
                                        <span className="text-right text-muted">
                                            { renderGender(sqlCard.gender) }
                                        </span>
                                        <div className="clearfix" />
                                    </div>
                                    <form id="w1" className="form-horizontal profile-form" onSubmit={e => changeUser(e)}>
                                        {/* WARNING */}
                                        <div className="m-b-md bg-warning p-xs">
                                            <i className="ion-ios-information-outline" />
                                            <em> {translate('Чтобы изменить фотографию — кликните по ней.')} </em>
                                        </div>
                                        {/* USER-NAME */}
                                        <div className={`form-group field-user-name required ${nameError ? "has-error" : null}`}>
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-name">{translate('ФИО')}</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    id="user-name"
                                                    className="form-control"
                                                    aria-required="true"
                                                    value={name}
                                                    onChange={e => nameHandler(e)}
                                                />
                                                <div className="help-block">{nameError ? nameError : null}</div>
                                            </div>
                                        </div>
                                        {/*GENDER*/}
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-gender">{translate('Пол')}</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <select id="user-gender" className="form-control" onChange={e => setGender(e.target.value)} value={gender}>
                                                    <option value={0}>{translate('Не задано')}</option>
                                                    <option value={1}>{translate('Мужчина')}</option>
                                                    <option value={2}>{translate('Женщина')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/*PHONE*/}
                                        <div className="form-group field-user-phone">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-phone">{translate('Телефон')}</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <InputMask
                                                    id="user-phone"
                                                    name="phone"
                                                    className="form-control"
                                                    mask="+99(999)999-9999"
                                                    placeholder="+_(___)____-____"
                                                    value={phone}
                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                                <div className="help-block" />
                                            </div>
                                        </div>
                                        {/*PASSWORD*/}
                                        <div className={`form-group field-user-password ${passwordError ? "has-error" : null}`}>
                                            <div className="col-sm-3">
                                                <label className="m-t-xs">
                                                    <input
                                                        type="checkbox"
                                                        id="user-change_password"
                                                        onChange={e => checkPasswordHandler(e)}
                                                    />
                                                    &nbsp;{translate('Сменить пароль')}
                                                </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    id="user-password"
                                                    className="new-pass form-control"
                                                    disabled
                                                    autoComplete="off"
                                                    ref={passwordInput}
                                                    value={password}
                                                    onChange={e => passwordHandler(e)}
                                                />
                                                <div className="help-block">{passwordError ? passwordError : null}</div>
                                            </div>
                                        </div>
                                        {/*INTERFACE LANGUAGE*/}
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label" htmlFor="user-language">
                                                    {translate('Язык интерфейса')}
                                                </label>
                                            </div>
                                            <div className="col-sm-9">
                                                <select id="user-language" className="form-control" onChange={e => setLanguage(e.target.value)} value={language}>
                                                    <option value="ua">Український</option>
                                                    <option value="ru">Русский</option>
                                                    <option value="en">English</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-success btn-outline btn-w-m" disabled={!formValid}>
                                                <i className="fa fa-save" /> {translate('Сохранить')}
                                            </button>
                                        </div>
                                        <div className="clearfix" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/*PROFILE MORE INFO*/}
                        <div className="col-md-4 col-sm-5">
                            <div className="ibox m-b-sm">
                                <div className="ibox-content border-bottom p-md">
                                    {/*ID*/}
                                    <div className="row font-bold">
                                        <div className="col-sm-4">
                                            <h4 className="no-margins">ID</h4>
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <h4 className="no-margins">{sqlCard.user_id}</h4>
                                        </div>
                                    </div>
                                    {/*EMAIL*/}
                                    <div className="row m-t-sm">
                                        <div className="col-sm-4">
                                            E-mail
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            {sqlCard.email}
                                        </div>
                                    </div>
                                    {/*/!*KEY API*!/*/}
                                    {/*<div className="row m-t-sm">*/}
                                    {/*    <div className="col-sm-4">*/}
                                    {/*        {translate('Ключ')} API (v2api)*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-sm-8 text-right">*/}
                                    {/*        In process...*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*/!*X-APP-KEY*!/*/}
                                    {/*<div className="row m-t-sm">*/}
                                    {/*    <div className="col-sm-4">*/}
                                    {/*        X-APP-KEY*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-sm-8 text-right">*/}
                                    {/*        In process...*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*ROLE*/}
                                    <div className="row m-t-sm">
                                        <div className="col-sm-4">
                                            {translate('Роль(и)')}
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            { auth && auth.role.name }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}