import {createContext} from "react";

function noop() {}

export const AuthContext = createContext({
    token: null,
    userId: null,
    role: null,
    interface_language : null,
    login: noop(),
    logout: noop(),
    isAuthenticated: false
})