import {openMenu} from "../Function/common";
import {useTranslate} from "../Hooks/translate.hook";
import {useContext} from "react";
import {SettingContext} from "../Context/SettingContext";
import URL from "../Api/URL.json";
import {useHttp} from "../Hooks/http.hook";

export default function TabHeader ({ counts, entity }) {

    // CONTEXT START
    const {pageSize} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // FUNCTION START
    function renderInfoRow () {
        if (!pageSize) return

        if (!counts) return `0.`;

        return `1 — ${counts >= pageSize[entity] ? pageSize[entity] : counts}.`;
    }
    // FUNCTION END

    // HANDLER START
    async function changePagination (size) {
        if (!pageSize) return

        pageSize[entity] = size;

        await request(`${URL.back_end}/setting`, 'PUT', { pageSize: JSON.stringify(pageSize) })

        if (error) return error;

        window.location.reload();
    }
    // HANDLER END

    return (
        <div className="text-muted text-right m-b-xs">
            <span style={{lineHeight: 0}}>
                {translate('Отображены строки')}
                <b> { renderInfoRow() } </b>
                { translate('Всего в базе') }
                <b> { counts }. </b>
                <span className="visible-xs"><br /></span>
                {/*<a className="crm-dashed-link"> {translate('Настройка полей')}.</a>*/}
                &nbsp; {translate('Пагинация')}&nbsp;
                <span className="dropdown" onClick={e => openMenu(e)}>
                    <a className="dropdown-toggle crm-dashed-link">
                        { pageSize && pageSize[entity]} {translate('строк') }
                    </a>
                    <ul className="dropdown-menu" style={{marginLeft: "-90px"}}>
                        <li>
                            <a onClick={() => changePagination(20)}>
                                20 {translate('строк')}
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePagination(50)}>
                                50 {translate('строк')}
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePagination(100)}>
                                100 {translate('строк')}
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePagination(200)}>
                                200 {translate('строк')}
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePagination(500)}>
                                500 {translate('строк')}
                            </a>
                        </li>
                    </ul>
                </span>
            </span>
        </div>
    )
}