import {closeModal} from "../../../../../Function/common";
import React, {useRef, memo, useState, useContext} from "react";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {useNavigate} from "react-router-dom";
import {SettingContext} from "../../../../../Context/SettingContext";
import {filterDisable} from "../../../../../Function/common";
import {validate} from "../../../../../Function/validateForm";
import moment from "moment";

export const RegisterOfLesson =  memo(({ sqlCard }) => {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [date_start, setDateStart] = useState(() => {
        let date = new Date();
        const result_date = new Date(date.getFullYear(), date.getMonth(), 1);
        return moment(result_date).format('YYYY-MM-DD');
    });

    const [date_end, setDateEnd] = useState(() => {
        let date = new Date();
        const result_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return moment(result_date).format('YYYY-MM-DD');
    });

    const [type_lessons, setTypeLessons] = useState([]);
    const [subjects, setSubjects] = useState([]);
    // STATE END

    // HANDLER START
    function typeLessonsHandler () {
        let elems = document.querySelectorAll('#report-type_lessons:checked');
        let arr = [].map.call(elems, (obj) => +obj.value);

        setTypeLessons([...arr]);
    }

    function subjectsHandler () {
        let elems = document.querySelectorAll('#report-subjects:checked');
        let arr = [].map.call(elems, (obj) => +obj.value);

        setSubjects([...arr]);
    }

    function handlerDate (date, setDate, value, bool) {
        let newDate = new Date(date);

        setDate(moment(new Date(newDate.getFullYear(), newDate.getMonth() + value, bool)).format('YYYY-MM-DD'));
    }

    function selectAllSubjects (e) {
        e.preventDefault();

        let flag = e.currentTarget.classList.contains('active');

        if (flag) {
            setSubjects(filterDisable(lessonSetting.subject).map(item => item.id))
        } else {
            setSubjects([]);
        }

        e.currentTarget.classList.toggle('active');
    }

    function selectAllType (e) {
        e.preventDefault();

        let flag = e.currentTarget.classList.contains('active');

        if (flag) {
            setTypeLessons([1,2,3]);
        } else {
            setTypeLessons([]);
        }

        e.currentTarget.classList.toggle('active');
    }
    // HANDLER END

    // REGISTER OF LESSON START
    function registerOfLesson (e) {
        e.preventDefault();

        let validObj = {
            "Типы" : !type_lessons.length,
            "Предметы" : !subjects.length
        }

        if (validate(validObj, translate)) return

        const obj = {
            employee_id : sqlCard.employee_id,
            date_start, date_end,
            type_lessons, subjects
        }

        navigate(`/company/1/employee/view/${encodeURI(JSON.stringify(obj))}?report=registerOfLesson`);
        closeModal(e, modal);
    }
    // REGISTER OF LESSON END

    return (
        <div id='register-of-lesson' className='modal in' ref={modal}>
            <div className='modal-dialog'>
                <div className='modal-content common-modal-content'>
                    <form className='form-horizontal' onSubmit={e => registerOfLesson(e)}>
                        <div className='modal-header'>
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className='modal-title'> { translate('Журнал посещений') } </h4>
                        </div>
                        <div className='modal-body p-md'>
                            {/* EMPLOYEE */}
                            <div className='form-group'>
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Педагог') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <span className="form-control input-sm text-muted"> { sqlCard.name } </span>
                                </div>
                            </div>
                            {/* DATE START */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Начало периода') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-addon" onClick={() => handlerDate(date_start, setDateStart, -1, 1)}>
                                            <a href="#"> M- </a>
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_start}
                                            onChange={e => setDateStart(e.target.value)}
                                        />
                                        <span className="input-group-addon" onClick={() => handlerDate(date_start, setDateStart, 1, 1)}>
                                            <a href="#"> M+ </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* DATE END */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Конец периода') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-addon" onClick={() => handlerDate(date_end, setDateEnd, 0, 0)}>
                                            <a href="#"> M- </a>
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_end}
                                            onChange={e => setDateEnd(e.target.value)}
                                        />
                                        <span className="input-group-addon" onClick={() => handlerDate(date_end, setDateEnd, 2, 0)}>
                                            <a href="#"> M+ </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* SUBJECTS */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label no-padding"> { translate('Предметы') } </label> <br />
                                    <a className="crm-dashed-link small crm-toggle-all active" onClick={e => selectAllSubjects(e)}>
                                        <span className="action-toggle-all"> { translate('выбрать все') } </span>
                                        <span className="action-toggle-none"> { translate('снять все') } </span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple" onChange={() => subjectsHandler()}>
                                    <div>
                                        {
                                            lessonSetting && filterDisable(lessonSetting.subject).map(item => (
                                                <>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value={item.id}
                                                            checked={subjects.includes(item.id)}
                                                            name="report-subjects"
                                                            id="report-subjects"
                                                        />
                                                        &nbsp;{ item.name }
                                                    </label>
                                                    <br />
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* TYPE LESSON */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label no-padding"> { translate('Типы') } </label> <br />
                                    <a className="crm-dashed-link small crm-toggle-all active" onClick={e => selectAllType(e)}>
                                        <span className="action-toggle-all"> { translate('выбрать все') } </span>
                                        <span className="action-toggle-none"> { translate('снять все') } </span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple" onChange={() => typeLessonsHandler()}>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={1}
                                                checked={type_lessons.includes(1)}
                                                name="report-type_lessons"
                                                id="report-type_lessons"
                                            />
                                            &nbsp;{translate('Индивидуальный')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={2}
                                                checked={type_lessons.includes(2)}
                                                name="report-type_lessons"
                                                id="report-type_lessons"
                                            />
                                            &nbsp;{translate('Групповой')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={3}
                                                checked={type_lessons.includes(3)}
                                                name="report-type_lessons"
                                                id="report-type_lessons"
                                            />
                                            &nbsp;{translate('Пробный')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-info">
                                <i className="fa fa-check" /> { translate('Сформировать') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})