import iconOperation from "../../../Assets/data/JSON/iconOperation.json";
import operation from "../../../Assets/data/JSON/operation.json";
import InputChooseSingle from "../../../Components/InputChoose/InputChooseSingle";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {useHttp} from "../../../Hooks/http.hook";
import {useContext, useEffect, useRef, useState} from "react";
import URL from "../../../Api/URL.json";
import {SettingContext} from "../../../Context/SettingContext";
import {AuthContext} from "../../../Context/AuthContext";
import {findObject, filterDisabledAccess, filterDisable, filterDisableEntity, closeModal} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {validate} from "../../../Function/validateForm";
import moment from "moment";
import {useArchive} from "../../../Hooks/archive.hook";

export default function EditPay (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, paySetting, customSetting, currentTime } = useContext(SettingContext);
    const { userId } = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [c_date, setDate] = useState(moment(currentTime).format('YYYY-MM-DD'));
    const [account, setAccount] = useState({});
    const [article, setArticle] = useState({});
    const [dataBill, setDataBill] = useState([]);
    const [bill, setBill] = useState({});
    const [amount, setAmount] = useState('');
    const [payer, setPayer] = useState('');
    const [note, setNote] = useState('');
    const [location, setLocation] = useState(null);
    const [customer, setCustomer] = useState({});
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.editPay).length) return

        let find_bill = props.tariffData.filter(item => item.id === props.editPay.bill && item.customer_id === props.editPay.customer_id)[0];
        setBill(props.editPay.bill === -1 ? { id : -1, title : translate('Базовый счет') } : find_bill ? find_bill : {} );
        setDate(props.editPay.c_date);
        setAccount({id : props.editPay.account});
        setArticle({id: props.editPay.article});
        setAmount(props.editPay.amount);
        setLocation(props.editPay.location);
        setCustomer(props.customerData.filter(item => item.customer_id === props.editPay.customer_id)[0]);
        setNote(props.editPay.note);
        setPayer(props.editPay.payer);

        filterDisableEntity(customSetting ? customSetting.customField : [], "pay").map(item => {
            document.forms["pay_edit"].elements["pay-edit-"+item.systemName].value = props.editPay[item.systemName];
        })

    }, [props.editPay])
    // INIT DATA END

    // BILL INIT START
    useEffect(() => {
        if (customer.name === undefined) return

        async function relateFetch () {
            let bills = await request(`${URL.back_end}/customer_tariff/${customer.customer_id}`, 'GET');

            bills = bills.filter((item) => item.f_removed === 0).map((item) => {
                let lessons = lessonSetting ? item.lessons ? JSON.parse(item.lessons).map(item => findObject(lessonSetting.subject, +item).name).join('/') : '' : ''
                item.title = `${item.title} / ${lessons} / ${item.balance}`
                return item;
            })

            if (error) throw error

            setDataBill([{id: -1, title: translate('Базовый счет')}, ...bills]);
        }

        relateFetch().catch(e => console.log(e))
    }, [customer])
    // BILL INIT END

    // EDIT PAY START
    async function editPay (e) {
        e.preventDefault();

        let validObj = {
            "Дата" : !c_date.length,
            "Клиент" : !Object.keys(customer).length,
            "Клиентский счёт" : !Object.keys(bill).length,
            "Сумма" : props.typeOperation !== 3 ? !(/^[0-9]+(\.[0-9]+)?$/).test(amount) : !(/^-?[0-9]+(\.[0-9]+)?$/).test(amount)
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        if (+account.id !== +props.editPay.account) {
            paySetting.account = paySetting.account.map(item => {
                if (+item.id === +account.id) {
                    item.payment += 1;
                    return item;
                } else {
                    return item;
                }
            })
            await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        }

        if (+article.id !== +props.editPay.article) {
            paySetting.article = paySetting.article.map(item => {
                if (+item.id === +article.id) {
                    item.payment += 1;
                    return item;
                } else {
                    return item;
                }
            })
            await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        }

        let pay = {
            type_operation: +props.editPay.type_operation,
            account: account.id,
            article: article.id,
            bill: Object.keys(bill).length ? bill.id : null,
            customer_id: Object.keys(customer).length ? customer.customer_id : null,
            c_date : moment(c_date).format('YYYY-MM-DD'),
            amount, payer, note, location
        }

        filterDisableEntity(customSetting.customField, "pay").map(item => {
            pay[item.systemName] = document.forms["pay_edit"].elements["pay-edit-"+item.systemName].value
        })

        if (bill.id === -1) {
            let balance = props.customerData.filter(item => item.customer_id === customer.customer_id)[0].balance;

            if (props.editPay.type_operation === 1) {
                balance -= Number(props.editPay.amount);
                balance += Number(amount);
            } else if (props.editPay.type_operation === 3) {
                balance -= Number(props.editPay.amount);
                balance += Number(amount);
            } else {
                balance += Number(props.editPay.amount);
                balance -= Number(amount);
            }

            await request(`${URL.back_end}/customer/${customer.customer_id}`, 'PUT', { balance });

        } else {
            let tariffs = dataBill.filter(item => {
                if (item.id === bill.id) {
                    if (props.editPay.type_operation === 1) {
                        item.balance -= Number(props.editPay.amount);
                        item.balance += Number(amount);
                    } else if (props.editPay.type_operation === 3) {
                        item.balance -= Number(props.editPay.amount);
                        item.balance += Number(amount);
                    } else {
                        item.balance += Number(props.editPay.amount);
                        item.balance -= Number(amount);
                    }
                    return item;
                }
            })[0]

            await request(`${URL.back_end}/customer_tariff/${tariffs.id}`, 'PUT', { balance : tariffs.balance })
        }

        await request(`${URL.back_end}/pay/${props.editPay.pay_id}`, 'PUT', pay);

        await pushArchive(2, 'pay', props.editPay.pay_id, { ...pay, pay_id : props.editPay.pay_id }, props.editPay);

        if (error) return error;

        window.location.reload();
    }
    // EDIT PAY END

    return (
        <div id={props.id ? props.id : 'edit-pay'} className="modal in" ref={modal}>
            <div className="modal-dialog" role="dialog">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">
                            {translate('Править')} <span className="text-lowercase">{translate(operation[props.editPay.type_operation])}</span>
                        </h4>
                    </div>
                    <form className="form-horizontal" name="pay_edit" onSubmit={e => editPay(e)}>
                        {/*BODY*/}
                        <div className="modal-body" style={{paddingBottom: 0}}>
                            {/*PAY TYPE AND DATE*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="pay-document_date">{translate('Тип и дата')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-control" style={{cursor: "not-allowed"}}>
                                                <i className={`${iconOperation[props.editPay.type_operation]} text-muted`} /> {translate(operation[props.editPay.type_operation])}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={c_date}
                                                onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*PAY ACCOUNT AND ARTICLE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="pay-pay_account_id">{translate('Счет и статья')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputChooseSingleObject
                                                sql={paySetting ? filterDisabledAccess(paySetting.account, userId) : []}
                                                setProps={setAccount}
                                                value={paySetting ? findObject(paySetting.account, account.id) ? findObject(paySetting.account, account.id).name : null : null}
                                                topicSearch="name"
                                                type_id="id"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputChooseSingleObject
                                                sql={paySetting ? filterDisable(paySetting.article).filter(item => item.type_payment.includes(`${props.editPay.type_operation}`)) : []}
                                                setProps={setArticle}
                                                value={paySetting ? findObject(paySetting.article, article.id) ? findObject(paySetting.article, article.id).name : null : null}
                                                topicSearch="name"
                                                type_id="id"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*LOCATION*/}
                            <div className="form-group field-pay-location_id">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Локация')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingle
                                        data={["Zoom"]}
                                        setProps={setLocation}
                                        value={location ? location : `(${translate('не задано')})`}
                                    />
                                </div>
                            </div>
                            {/*CUSTOMER*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Клиент')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <InputChooseSingleObject
                                        sql={props.customerData}
                                        setProps={setCustomer}
                                        topicSearch="name"
                                        type_id="customer_id"
                                        value={customer.name}
                                    />
                                    <div className={`help-block text-danger ${customer.name !== undefined ? "hide" : null}`}>
                                        {translate('Выберите клиента, если вы пополняете клиентский счет')}
                                    </div>
                                </div>
                            </div>
                            {/*BILL*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Клиентский счёт') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group">
                                        <InputChooseSingleObject
                                            sql={dataBill}
                                            setProps={setBill}
                                            topicSearch="title"
                                            type_id="id"
                                            value={bill.title}
                                        />
                                        <div className="input-group-addon" style={{minWidth: "80px"}}>
                                            <a className="selected-customer crm-add-tariff text-lowercase">
                                                <small className="selected-customer-hide text-lowercase">{translate('Добавить')}</small>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`help-block text-danger ${customer.name !== undefined ? "hide" : null}`}>{translate('Выберите счет, если используются раздельные счета')}</div>
                                </div>
                            </div>
                            {/*AMOUNT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="font-noraml control-label">
                                        { translate('Сумма') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`${translate('Например')}, 1000`}
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*PAYER*/}
                            <div className="form-group field-pay-payer_name">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Плательщик') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, ФИО или наименование компании')}
                                        value={payer}
                                        onChange={e => setPayer(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group field-pay-note">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Комментарий') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Оплата за обучение')}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "pay").map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-8">
                                                { typeField(item, "pay-edit-") }
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}