import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisable, objectToUrlParams, getDateMonthDiapason} from "../../../Function/common";
import URL from "../../../Api/URL.json";

export default function ActiveLeads () {

    // CONTEXT START
    const { salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    // HOOK END

    // VALUE START
    const objDateMonthDiapason = getDateMonthDiapason();
    // VALUE END

    // STATE START
    const [ data, setData ] = useState([]);
    const [ chooseManager, setChooseManager ] = useState('');
    const [ dateFrom, setDateFrom ] = useState(objDateMonthDiapason.date_start);
    const [ dateTo, setDateTo ] = useState(objDateMonthDiapason.date_end);
    const [ source, setSource ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const params_obj = {
                dateFrom, dateTo, source
            }

            const response = await request(`${URL.back_end}/dashboard/activeLeads?${objectToUrlParams(params_obj)}`, 'GET');

            setData(response);

        }

        fetchData().catch(e => console.log(e))

    }, [source, dateFrom, dateTo, request])
    // INIT DATA END

    return (
        <div className="col-sm-5 m-b-md shadow-lg rounded white-bg">
            <div className="dashboard-item">
                <label className="font-bold h4 text-center dashboard-item-head no-margins">
                    Активні ліди
                    <h5>
                        Джерело трафіку
                        <select style={{ outline : 0, border : 0, textAlign : 'center', marginBottom : '5px' }} onChange={e => setSource(e.target.value)}>
                            <option value=''> Усі </option>
                            {
                                salesSetting ?
                                    filterDisable(salesSetting.leadSource).map(item => (
                                        <option value={item.id}> { item.name } </option>
                                    ))
                                    : null
                            }
                        </select> <br />
                        від
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center', marginBottom : '5px' }}
                            onChange={e => setDateFrom(e.target.value)}
                            value={dateFrom}
                        />
                        <br />
                        до
                        <input
                            type="date"
                            style={{ outline : 0, border : 0, padding : 0, margin : 0, textAlign : 'center' }}
                            onChange={e => setDateTo(e.target.value)}
                            value={dateTo}
                        />
                    </h5>
                </label>
                <div className="row">
                    <div className="col-sm-12 no-padding">
                        <div className="dashboard-chart" id="chart-4">
                            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
                                <thead>
                                <tr>
                                    <th>
                                        <select style={{ outline : 0, border : 0, textAlign : 'center' }} onChange={e => setChooseManager(e.target.value)}>
                                            <option value={''}> Менеджер </option>
                                            { data.map(item => <option value={item.user_id}> { item.name } </option> ) }
                                        </select>
                                    </th>
                                    <th> Активні (шт.) </th>
                                    <th> Активні (%) </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    chooseManager ?
                                        data.filter(item => item.user_id === +chooseManager).map(item => (
                                            <tr>
                                                <td> { item.name } </td>
                                                <td> { item.active } </td>
                                                <td> { item.active_percentage } % </td>
                                            </tr>
                                        ))
                                        : data.map(item => (
                                            <tr>
                                                <td> { item.name } </td>
                                                <td> { item.active } </td>
                                                <td> { item.active_percentage } % </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}