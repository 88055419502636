// HOOK
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslate} from "../../Hooks/translate.hook";
import React from "react";

// CCS
import '../../Assets/css/InputChooseSingle.css';

// FUNCTION
import shortid from "shortid";

export const InputChooseSingleObject = React.memo(function MemoInputChooseSingleObject (props) {

    // REF START
    const chosen_drop = useRef();
    const chosen_container = useRef();
    const chosen_result = useRef();
    const chosen_single = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [choseValue,setChoseValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    // STATE END

    // CHECK PROPS START
    useEffect(() => {
        if (props.value !== undefined && props.value !== null) {
            setChoseValue(props.value);
            chosen_single.current.classList = "chosen-single";
        }
    }, [props.value])
    // CHECK PROPS END

    // USE MEMO START
    const searchItems = useMemo(() => {
        if (searchValue) {
            return props.sql.filter(item => item[props.topicSearch].toLowerCase().includes(searchValue.toLowerCase()));
        } else {
            return [];
        }
    },[searchValue])
    // USE MEMO END

    // SHOW CHOSEN START
    function chosenShow () {
        chosen_drop.current.style.left = '0';
        chosen_container.current.classList += " chosen-container-active chosen-with-drop"

        document.addEventListener("click", (event) => {
            if (!chosen_container.current.contains(event.target)) {
                chosen_drop.current.style.left = "-9999px";
                chosen_container.current.classList = "chosen-container chosen-container-single";
            }
        })
    }
    // SHOW CHOSEN END

    // CUSTOM INPUT FUNC START
    function addChoice (e) {
        let obj = { name: e.target.dataset.name }

        obj[props.type_id] = +e.target.dataset.id;
        setChoseValue(e.target.dataset.name);
        props.setProps(obj);

        chosen_drop.current.style.left = "-9999px";
        chosen_container.current.classList = "chosen-container chosen-container-single";
        chosen_single.current.classList = "chosen-single";
    }

    function resetChose () {
        setChoseValue('');
        props.setProps({});
        chosen_single.current.classList = "chosen-single chosen-default";
    }
    // CUSTOM INPUT FUNC END

    // FUNCTION START
    function sortArray (arr) {
        return arr.filter(item => item[props.topicSearch]).sort((a, b) => a[props.topicSearch].replace(/і/g, 'и').localeCompare(b[props.topicSearch].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div className="chosen-container chosen-container-single" style={{ width: '100%' }} ref={chosen_container}>
            <a className="chosen-single chosen-default" tabIndex={-1} onClick={props.disabled ? () => {} : chosenShow} style={ props.disabled ? { color : '#ccc' } : {} } ref={chosen_single}>
                <span> { choseValue ? choseValue : translate('Выберите') } </span>
                { choseValue ? <abbr className="search-choice-close" onClick={resetChose} /> : null }
                <div>
                    <b />
                </div>
            </a>
            <div className="chosen-drop" ref={chosen_drop}>
                <div className="chosen-search">
                    <input
                        type="text"
                        autoComplete="off"
                        onChange={e => setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                </div>
                <ul className="chosen-results" ref={chosen_result}>
                    {
                        searchItems.length > 0 ?
                            sortArray(searchItems).map (item => (
                                <li className="active-result" key={shortid.generate()} data-name={item[props.topicSearch]} data-id={item[props.type_id]} onClick={e => addChoice(e)}>{item[props.topicSearch]}</li>
                            )) :
                            sortArray(props.sql).map(item => (
                                <li className="active-result" key={shortid.generate()} data-name={item[props.topicSearch]} data-id={item[props.type_id]} onClick={e => addChoice(e)}>{item[props.topicSearch]}</li>
                            ))
                    }
                </ul>
            </div>
        </div>
    )
})