import {useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal} from "../../../Function/common";
import {Link, useNavigate} from "react-router-dom";

export default function FilterUser ({ roleData, params }) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [ role, setRole ] = useState('');
    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ gender, setGender ] = useState('');
    const [ f_removed, setFremoved ] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!params) {
            setRole('');
            setName('');
            setPhone('');
            setEmail('');
            setGender('');
            setFremoved('');
            return
        }

        setRole(params.role);
        setName(params.name);
        setPhone(params.phone);
        setEmail(params.email);
        setGender(params.gender);
        setFremoved(params.f_removed);

    }, [params])
    // INIT DATA END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // FILTER START
    function filterUser (e) {
        e.preventDefault();

        let user = { role, name, phone, email, gender, f_removed }

        let obj = Object.filter(user, (value) => value);

        if (!Object.keys(obj).length) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/user/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }

    }
    // FILTER END

    return (
        <div id="filter-user" className="modal in" style={{ paddingRight : "17px" }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterUser(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        <div className="modal-body modal-body-search p-md">
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Роль') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={role} onChange={e => setRole(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            roleData.map(item => (
                                                <option value={item.role_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('ФИО') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> E-mail </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Пол') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={gender} onChange={e => setGender(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={1}> { translate('Мужчина') } </option>
                                        <option value={2}> { translate('Женщина') } </option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Архив') } </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={f_removed} onChange={e => setFremoved(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={0}> { translate('Активные') } </option>
                                        <option value={1}> { translate('Архивные') } </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={translate('Сбросить фильтр и вернуться в таблицу')} onClick={e => { closeModal(e, modal); navigate('/company/1/user/index') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            {/*<a className="btn btn-sm btn-success btn-outline pull-left" href="#" title={translate('Настройка полей')}>*/}
                            {/*    <i className="fa fa-cog fa-fw" />*/}
                            {/*</a>*/}
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}