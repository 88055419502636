// PACKAGE
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import jwt_decode from "jwt-decode";

// CONTEXT
import {AuthContext} from "../../Context/AuthContext";
import {SettingContext} from "../../Context/SettingContext";

// LOCALE
import ruLocale from '@fullcalendar/core/locales/ru';
import uaLocale from '@fullcalendar/core/locales/uk';
import enLocale from '@fullcalendar/core/locales/en-gb'

// HOOK
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";

// JSON
import URL from '../../Api/URL.json';
import iconCalendar from '../../Assets/data/JSON/iconCalendar.json';

// COMPONENT
import SideMenu from "../../Components/SideMenu/SideMenu";
import AddLesson from "./Modal/AddLesson";
import EditLesson from "./Modal/EditLesson";
import CalendarPopover from "../../Components/Popover/CalendarPopover";
import FilterLesson from "./Modal/FilterLesson";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import SpendLesson from "../Lesson/Modal/SpendLesson";

// FUNCTION
import {findObject, openModal} from "../../Function/common";

// CSS
import '../../Assets/css/Calendar.css'

// TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TabAction from "../../Components/TabAction";

export default function Calendars () {

    // REF START
    const fc_media_screen = document.getElementsByClassName('fc-media-screen')[0];
    // REF END

    // CONTEXT START
    const { basicSetting, lessonSetting } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // VALUE START
    const storageDate = localStorage.getItem('calendar_date') ? localStorage.getItem('calendar_date') : new Date();
    // VALUE END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // STATE START
    const [typeLesson, setTypeLesson] = useState('');
    const [popoverData, setPopoverData] = useState({});
    const [popoverStyle, setPopoverStyle] = useState({});
    const [editLesson, setEditLesson] = useState({});

        // DATA
    const [groupData, setGroupData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [managerData, setManagerData] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData() {

            const groups = await request(`${URL.back_end}/group`, 'GET')
            const employees = await request(`${URL.back_end}/employee`, 'GET')
            const customers = await request(`${URL.back_end}/customer`, 'GET')
            const managers = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } })

            if (error) throw error;

            setGroupData(groups);
            setEmployeeData(employees);
            setCustomerData(customers);
            setManagerData(managers.users);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // BUTTON FUNC START
    function handlerTypeLesson(e, format) {
        e.preventDefault();

        setTypeLesson(format);
        openModal('add-lesson')
    }
    // BUTTON FUNC END

    // LOCALE SWITCH START
    function switchLocale () {
        let locale = basicSetting ? basicSetting.baseOption.interfaceLanguage : null;
        if (!locale) return ruLocale;
        // eslint-disable-next-line default-case
        switch (locale) {
            case 'ua' : return uaLocale;
            case 'en' : return enLocale;
            case 'ru' : return ruLocale;
        }
    }
    // LOCALE SWITCH END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="calendar" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions calendar-create-buttons">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BUTTON GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="/company/1/calendar/index" className="btn btn-sm btn-white active" title={translate('Отображение: в виде календаря')}>
                                        <i className="ion-calendar" />
                                    </Link>
                                    <Link to="/company/1/lesson/index" className="btn btn-sm btn-white" title={translate('Отображение: в виде таблицы')}>
                                        <i className="ion-navicon-round" />
                                    </Link>
                                    {
                                        auth.role.interface === 1 &&
                                            <Link to="/company/1/calendar-teacher/index" className="btn btn-sm btn-white" title={translate('График работы педагогов')}>
                                                <i className="ion-university" />
                                            </Link>
                                    }
                                </div>
                                {/*BUTTON ADD*/}
                                <TabAction
                                    access={auth.role.interface === 1 || auth.role.interface === 2}
                                    className="dropdown btn-group no-padding"
                                    icon="ion-plus"
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-success btn-w-m dropdown-toggle',
                                        text : 'Добавить',
                                        list : [
                                            { text : 'Индивидуальный', icon : 'ion-person', handler : (e) => handlerTypeLesson(e, 1) },
                                            { text : 'Групповой', icon : 'ion-person-stalker', handler : (e) => handlerTypeLesson(e, 2) },
                                            { text : 'Пробный', icon : 'ion-asterisk', handler : (e) => handlerTypeLesson(e, 3) }
                                        ]
                                    }}
                                />
                                {/* BUTTON REPORT */}
                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-printer"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-info btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'Расписание уроков', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Реестр проведённых уроков', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Пропущенные уроки', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Убыточные уроки', icon : 'fa fa-file-text-o' },*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/* BUTTON ANOTHER */}
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="dropdown btn-group no-padding"
                                    icon="fa fa-ellipsis-h"
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',
                                        text : 'Другое',
                                        list : [
                                            { link : '/settings/1/default/calendar-options', text : 'Настройки календаря', icon : 'fa fa-cog' }
                                        ]
                                    }}
                                />
                                {/* FILTER */}
                                <TabAction
                                    access={auth.role.interface === 1 || auth.role.interface === 2}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    handler={() => openModal('filter-lesson')}
                                    icon='fa fa-filter'
                                    text='Фильтр'
                                />
                                {/*FILTER OPTIONS*/}
                                {
                                    ( filterParams && lessonSetting ) &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    customer: translate('Клиент'),
                                                    employee: translate('Педагог'),
                                                    manager : translate('Ответственный'),
                                                    group: translate('Группа'),
                                                    subject: translate('Предмет'),
                                                    type_lesson: translate('Тип урока'),
                                                    audience: translate('Аудитория'),
                                                    status : translate('Статус'),
                                                    status3 : translate('Статус')
                                                }}
                                                meansData={{
                                                    customer : {
                                                        arr : customerData,
                                                        type_id : 'customer_id',
                                                        topic : 'name'
                                                    },
                                                    employee : {
                                                        arr : employeeData,
                                                        type_id : 'employee_id',
                                                        topic : 'name'
                                                    },
                                                    manager : {
                                                        arr : managerData,
                                                        type_id : 'user_id',
                                                        topic : 'name'
                                                    },
                                                    group : {
                                                        arr : groupData,
                                                        type_id : 'group_id',
                                                        topic : 'title'
                                                    },
                                                    subject : {
                                                        arr : lessonSetting.subject,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    audience : {
                                                        arr : lessonSetting.room,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    status : {
                                                        arr : [ { id : 1, name : translate('Проведен') }, { id : 2, name : translate('Отменен') }, { id : 0, name : translate('Запланирован') } ],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    status3 : {
                                                        arr : [ { id : 2, name : translate('Отменено') } ],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    type_lesson : {
                                                        arr : [ { id : 1, name : translate('Индивидуальный')}, { id : 2, name : translate('Групповой') }, { id : 3, name : translate('Пробный') } ],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                }}
                                                page="calendar"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="ibox float-e-margins m-b-sm">
                                <div className="ibox-content">
                                    <FullCalendar
                                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                                        locales={[switchLocale()]}
                                        initialView={lessonSetting ? lessonSetting.calendarOption.initialView : 'timeGridDay'}
                                        timeZone='UTC'
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                        }}
                                        initialDate={storageDate}
                                        allDaySlot={false}
                                        height="auto"
                                        fixedWeekCount={false}
                                        slotLabelFormat={[{ hour:"2-digit", minute: "2-digit" }]}
                                        displayEventEnd={true}
                                        displayEventTime={true}
                                        eventOrder="sorting"
                                        slotDuration={lessonSetting ? lessonSetting.calendarOption.interval : "00:15:00"}
                                        slotMinTime={lessonSetting ? lessonSetting.calendarOption.minTime : "09:00:00"}
                                        slotMaxTime={lessonSetting ? lessonSetting.calendarOption.maxTime : "22:00:00"}
                                        loading={(isLoading) => {

                                            const toolBar = document.getElementsByClassName('fc-toolbar-chunk')[1];

                                            if (isLoading) {

                                                if (!toolBar) return

                                                const width = toolBar.clientWidth + 50;

                                                const spinner = document.createElement(`div`);
                                                spinner.id = 'calendar-spinner';
                                                spinner.style = `position:absolute; top: 15px; width: ${width}px; height: 35px; background: white`;
                                                spinner.innerHTML = `<div class='sk-spinner sk-spinner-wave'> <div class='sk-rect1'></div> <div class='sk-rect2'></div> <div class='sk-rect3'></div> <div class='sk-rect4'></div> <div class='sk-rect5'></div> </div>`;

                                                toolBar.prepend(spinner);

                                            } else {
                                                const spinner = document.getElementById('calendar-spinner');

                                                if (!spinner) return

                                                toolBar.removeChild(spinner);
                                            }

                                        }}
                                        events={
                                            async (event) => {
                                                let { startStr, endStr } = event;

                                                localStorage.setItem('calendar_date', startStr.split('T')[0]);

                                                const systemId = +jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;
                                                const params = filterParams ? JSON.parse(filterParams) : null;

                                                return await request(`${URL.back_end}/calendar_lesson`, 'POST', { startStr, endStr, face : auth.role.interface, systemId, params : { ...params,  employeeNoArchive : 1 } });
                                            }
                                        }
                                        selectable={true}
                                        eventClick={(info) => {
                                            fc_media_screen.style.opacity = "0.5";

                                            setPopoverData({ ...info.event.extendedProps.description, ...{ access : auth.role.interface } });

                                            setPopoverStyle({
                                                top: `${info.jsEvent.pageY-200}px`, left: `${info.jsEvent.pageX-160}px`, minWidth: "330px", display: "block"
                                            })
                                        }}
                                        eventDidMount={
                                             (info) => {
                                                let data = info.event.extendedProps.description;

                                                let time = {}, event_title = {}, title = {};

                                                let audienceRoom = lessonSetting ? lessonSetting.calendarOption.showAudience ? findObject(lessonSetting.room, data.audience) ? findObject(lessonSetting.room, data.audience).name : null : null : null;
                                                let lessonSubject = lessonSetting ? lessonSetting.calendarOption.showSubject ? findObject(lessonSetting.subject, data.subject) ? findObject(lessonSetting.subject, data.subject).name : null : null : null;

                                                if (info.view.type === "dayGridMonth") {
                                                    let temp = info.el.childNodes[0];
                                                    temp.className = "m-l-xs";
                                                    time = info.el.childNodes[1];
                                                    title = info.el.childNodes[2];
                                                    info.el.style.borderColor = info.borderColor;
                                                } else {
                                                    time = info.el.childNodes[0].childNodes[0].childNodes[0];
                                                    event_title = info.el.childNodes[0].childNodes[0].childNodes[1];
                                                    title = event_title?.childNodes[0];
                                                }

                                                if (time) {
                                                    if (data.status === 1) {
                                                        time.innerHTML = `<big><i class="fa fa-check"></i></big><span>${ info.timeText }</span> ${audienceRoom ? `<i id="a-m-xs" class="ion-location"></i>${audienceRoom}` : ''}`
                                                    } else if (data.status === 2) {
                                                        time.innerHTML = `<big><i class="fa fa-minus-circle"></i></big><span>${ info.timeText }</span> ${audienceRoom ? `<i id="a-m-xs" class="ion-location"></i>${audienceRoom}` : ''}`
                                                        info.el.classList += " text-dec-line";
                                                    } else {
                                                        time.innerHTML = `<big><i class="fa fa-square-o"></i></big><span>${ info.timeText }</span> ${audienceRoom ? `<i id="a-m-xs" class="ion-location"></i>${audienceRoom}` : ''}`
                                                    }
                                                }

                                                if (title) {
                                                    title.innerHTML =
                                                        `${lessonSubject ? `<i class="ion-ios-book"></i> ${lessonSubject}` : ''}
                                                     ${data.customer.length || data.group.length ? `<i class='${iconCalendar[data.type_lesson]}'></i>` : ''}
                                                     ${data.customer.length ? `${data.customer.map(item => item.name)}` : ''}
                                                     ${data.group.length ? `${data.group.map(item => item.title)}` : ''}
                                                     ${lessonSetting ? lessonSetting.calendarOption.showEmployee ? data.employee.length ? `<i class="ion-university"></i>${data.employee.map(item => item.name)}`: '' : '' : ''}
                                                     ${lessonSetting ? lessonSetting.calendarOption.showNote ? data.l_note ? `<i>${data.l_note}</i>` : '' : '' : ''}`
                                                }
                                            }
                                        }
                                        dateClick={
                                            (info) => {
                                                if (info.view.type === "dayGridMonth") this.changeView('timeGridDay', info.date);
                                            }
                                        }
                                        dayMaxEventRows={true}
                                        views={{ dayGridMonth: { dayMaxEventRows: 3 } }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*MODAL*/}

            {/*LESSON*/}
            <AddLesson
                groupData={groupData}
                employeeData={employeeData}
                customerData={customerData}
                type_lesson={typeLesson}
            />

            <EditLesson
                groupData={groupData}
                employeeData={employeeData}
                customerData={customerData}
                editLesson={editLesson}
            />

            <SpendLesson
                employeeData={employeeData}
                groupData={groupData}
                customerData={customerData}
                editLesson={popoverData}
            />

            <FilterLesson
                customerData={customerData}
                employeeData={employeeData}
                groupData={groupData}
                managerData={managerData}
                page="calendar"
                access={auth.role.interface === 1}
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
            />

            {/*POPOVER*/}
            <CalendarPopover
                data={popoverData}
                setData={setPopoverData}
                popoverStyle={popoverStyle}
                setPopoverStyle={setPopoverStyle}
                setEditLesson={setEditLesson}
                side='top'
                sideStyle={{ left: '50%' }}
            />
        </div>
    );
}