import {useContext, useEffect, useRef, useState} from "react";
import {closeModal, maxId} from "../../../../../Function/common";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from "../../../../../Api/URL.json";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import shortid from "shortid";

export default function AddAccount () {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const {paySetting, basicSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');

    // DATA
    const [userData, setUserData] = useState([]);
    // STATE END

    // GET USER DATA START
    useEffect(() => {
        async function fetchData () {
            const user = await request(`${URL.back_end}/user`, 'GET');
            if (error) throw error;
            await setUserData(user);
        }
        fetchData().catch(e => console.log(e));
    }, [])
    // GET USER DATA END

    // ADD ACCOUNT START
    async function addAccount (e) {
        e.preventDefault();

        let elems = document.querySelectorAll('#allowed-user:checked');
        let allowed = [].map.call(elems, function (obj) {
            return obj.value;
        })

        let validity = {
            "Название" : !name.length,
            "Доступно для" : !allowed.length,
            result: 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                errorNotify(`${translate('Необходимо заполнить')} "${key}"`)
                validity.result += 1;
            }
        }

        if (validity.result > 0) return

        let account = {
            id: paySetting.account.length > 0 ? maxId(paySetting.account)+1 : 0,
            branch : basicSetting.baseOption.nameCompany,
            payment: 0,
            name, allowed,
            disabled: false
        }

        paySetting.account.push(account);
        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        if (error) return error;
        window.location.reload();
    }
    // ADD ACCOUNT END

    return (
        <div id="add-account" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addAccount(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить кассу (счет)')} </h4>
                        </div>
                        <div className="modal-body p-md">
                            {/*BRANCH*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Филиал')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <span className="form-control">{basicSetting ? basicSetting.baseOption.nameCompany : null}</span>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group field-payaccount-name required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payaccount-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Касса №1')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*ALLOWED*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payaccount-user_ids">{translate('Доступно для')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <div id="payaccount-user_ids">
                                        {
                                            userData.map(item => (
                                                <div>
                                                    <label>
                                                        <input type="checkbox" name="pay-account" id="allowed-user" value={item.user_id} /> {item.name}
                                                    </label>
                                                    <br />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}