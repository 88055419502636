import "../../../../Assets/css/SettingStyle/Notification.css"
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import URL from "../../../../Api/URL.json";

export default function Notification () {

    // CONTEXT START
    const {basicSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [debtor, setDebtor] = useState('');
    const [debtorIs, setDebtorIs] = useState('');
    const [tariff, setTariff] = useState('');
    const [duplicate, setDuplicate] = useState('');
    const [free, setFree] = useState('');
    const [lastLesson, setLastLesson] = useState(false);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!basicSetting) return

        const { notification } = basicSetting;

        setDebtor(notification.debtor);
        setDebtorIs(notification.debtorIs);
        setTariff(notification.tariff);
        setDuplicate(notification.duplicate);
        setFree(notification.free);
        setLastLesson(notification.lastLesson);

    }, [basicSetting])
    // INIT DATA END

    // ADD NOTIFICATION START
    async function addNotification (e) {
        e.preventDefault();

        basicSetting.notification = { debtor, debtorIs, tariff, duplicate, free, lastLesson }

        await request(`${URL.back_end}/setting`, 'PUT', { basicSetting: JSON.stringify(basicSetting) })

        if (error) return error;

        window.location.reload();
    }
    // ADD NOTIFICATION END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title"> { translate('Настройка области уведомлений') } </h4>
            </div>
            <form className="form-horizontal" onSubmit={e => addNotification(e)}>
                <div className="modal-body p-md">
                    <div className="form-group">
                        <div className="col-sm-3">
                            <label className="control-label">
                                { translate('О должниках') }
                            </label>
                        </div>
                        <div className="col-sm-5">
                            <select className="form-control" onChange={e => setDebtor(e.target.value)} value={debtor}>
                                <option value="0"> { translate('Не уведомлять') } </option>
                                <option value="1"> { translate('Только если сегодня урок') } </option>
                                <option value="2"> { translate('Из всех активных клиентов') } </option>
                            </select>
                            <div className="help-block" />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-3">
                            <label className="control-label">
                                { translate('Должник это') }
                            </label>
                        </div>
                        <div className="col-sm-5">
                            <select className="form-control" onChange={e => setDebtorIs(e.target.value)}>
                                <option value="0" selected={debtorIs === "0"}>{translate('Есть долг на общем остатке')}</option>
                                <option value="1" selected={debtorIs === "1"}>{translate('Есть долг хотя бы на 1 активном счету')}</option>
                                <option value="2" selected={debtorIs === "2"}>{translate('Есть долг хотя бы на 1 активном или архивном счету')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-3">
                            <label className="control-label">
                                { translate('Нет абонемента') }
                            </label>
                        </div>
                        <div className="col-sm-5">
                            <select className="form-control" onChange={e => setTariff(e.target.value)}>
                                <option value="0" selected={tariff === "0"}>{translate('Не уведомлять')}</option>
                                <option value="1" selected={tariff === "1"}>{translate('Только если сегодня урок')}</option>
                                <option value="2" selected={tariff === "2"}>{translate('Из всех активных клиентов')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-3">
                            <label className="control-label">
                                { translate('Дублирование клиентов') }
                            </label>
                        </div>
                        <div className="col-sm-5">
                            <select className="form-control" onChange={e => setDuplicate(e.target.value)}>
                                <option value="0" selected={duplicate === "0"}>{translate('Не уведомлять')}</option>
                                <option value="1" selected={duplicate === "1"}>{translate('При совпадении номера телефона')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-3">
                            <label className="control-label">
                                { translate('Проведено бесплатно') }
                            </label>
                        </div>
                        <div className="col-sm-5">
                            <select className="form-control" onChange={e => setFree(e.target.value)} value={free}>
                                <option value="0"> { translate('Не уведомлять') } </option>
                                <option value="1"> { translate('Количество клиентов') } </option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-3"> {translate('Прочие уведомления')} </div>
                        <div className="col-sm-5">
                            {/*<div>*/}
                            {/*    <label>*/}
                            {/*        <input type="checkbox" value="1" checked="" /> {translate('Задачи пользователя на сегодня')}*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <label>*/}
                            {/*        <input type="checkbox" value="1" checked="" /> {translate('Просроченные задачи пользователя')}*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <label>*/}
                            {/*        <input type="checkbox" value="1" checked="" /> {translate('О непроведённых уроках за вчера')}*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <label>*/}
                            {/*        <input type="checkbox" value="1" /> {translate('Уведомление о д/р')}*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={lastLesson}
                                        onChange={e => setLastLesson(e.target.checked)}
                                    /> { translate('Остался 1 урок на счету') }
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-sm btn-w-m btn-success">
                        <i className="fa fa-save" /> { translate('Сохранить') }
                    </button>
                    <button type="button" className="btn btn-sm btn-w-m btn-white">
                        { translate('Отмена') }
                    </button>
                </div>
            </form>
        </>
    )
}