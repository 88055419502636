import {errorNotify} from "./Notify";

function validate (obj, translate) {

    let count = 0;

    for (let key in obj) {
        if (obj[key]) {
            count += 1;
            errorNotify(`${translate('Необходимо заполнить')} "${translate(key)}"`);
        }
    }

    return count;
}

export {validate}