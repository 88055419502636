import {useContext, useRef, useState} from "react";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from '../../../../../Api/URL.json';
import {useArchive} from "../../../../../Hooks/archive.hook";
import {SettingContext} from "../../../../../Context/SettingContext";
import {InputChooseSingleObject} from "../../../../../Components/InputChoose/InputChooseSingleObject";
import {filterDisable, findObject} from "../../../../../Function/common";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {closeModal} from "../../../../../Function/common";
import {success} from "../../../../../Function/Notify";
import {validate} from "../../../../../Function/validateForm";

export default function AddQualification (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, employeeSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { pushArchive } = useArchive();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ subject, setSubject ] = useState({});
    const [ knowledge, setKnowledge ] = useState({});
    const [ count, setCount ] = useState(1);
    // STATE END

    // FUNCTION START
    function renderBody () {

        let array = [];

        for (let i = 0; i < count; i++) {

            const disabled = count === 1 ? false : (count - 1 !== i);

            array.push(
                <>
                    {/*SUBJECT*/}
                    <div className="form-group required disabled">
                        <div className="col-sm-3">
                            <label className="control-label"> { translate('Предмет') } </label>
                        </div>
                        <div className="col-sm-7">
                            <InputChooseSingleObject
                                sql={lessonSetting ? filterDisable(lessonSetting.subject) : []}
                                setProps={setSubject}
                                disabled={disabled}
                                type_id="id"
                                topicSearch="name"
                            />
                        </div>
                    </div>
                    {/*QUALIFICATION*/}
                    <div className="form-group required">
                        <div className="col-sm-3">
                            <label className="control-label"> { translate('Квалификация') } </label>
                        </div>
                        <div className="col-sm-7">
                            <InputChooseSingleObject
                                sql={employeeSetting ? filterDisable(employeeSetting.qualification) : []}
                                setProps={setKnowledge}
                                disabled={disabled}
                                topicSearch="name"
                                type_id="id"
                            />
                        </div>
                    </div>
                </>
            )
        }

        return array;
    }

    async function addMoreQual (e) {

        await addQualification(e);

        if (Object.keys(subject).length && Object.keys(knowledge).length) {
            setCount( count + 1 );
            setSubject({});
            setKnowledge({});
        }
    }
    // FUNCTION END

    // ADD QUALIFICATION START
    async function addQualification (e) {

        e.preventDefault();

        let validObj = {
            "Предмет" : !Object.keys(subject).length,
            "Квалификация" : !Object.keys(knowledge).length
        }

        if (validate(validObj, translate)) return

        let arr_qualification = props.sql.qualification ? JSON.parse(props.sql.qualification) : null;

        let data = {
            id : arr_qualification !== null ? arr_qualification.length : 0,
            subject: subject.id, knowledge: knowledge.id
        }

        arr_qualification !== null ? arr_qualification.push(data) : arr_qualification = [data]

        await request(`${URL.back_end}/employee/${props.sql.employee_id}`, 'PUT', {qualification: JSON.stringify(arr_qualification)});

        if (error) return console.log(error)

        await pushArchive(1, "Qualification", props.sql.employee_id, data, null);

        let update_customer = { qualification: JSON.stringify(arr_qualification) }

        for (let key in props.sql) {
            if (key !== "qualification") {
                update_customer[key] = props.sql[key];
            }
        }

        props.updateSql(update_customer);

        success(`Квалификация добавлена`)
    }
    // ADD QUALIFICATION END

    return (
        <div id="add-qualification" className="modal in" role="dialog" style={{ paddingRight: '16px' }} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span>×</span>
                        </button>
                        <h4 className="modal-title"> { translate('Добавить квалификацию') } </h4>
                    </div>
                    <form className="form-horizontal" onSubmit={e => addQualification(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            { renderBody() }
                            <div className="form-group text-right">
                                <a className="crm-dashed-link" onClick={e => addMoreQual(e)}>
                                    Зберегти та додати ще +
                                </a>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit} onClick={() => modal.current.style.display = 'none'}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}