import {openModal} from "../../../../Function/common";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import URL from "../../../../Api/URL.json";
import {useHttp} from "../../../../Hooks/http.hook";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditReason from "./Modal/EditReason";
import $ from 'jquery';
import 'jquery-ui-bundle';

function drag (setting) {
    $('#studystatussearch-f_name').focus();

    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/customerSetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'reason'}),
                success: function(data){
                    setting.customerSetting = data;
                }
            });
        }
    });
}

export default function ReasonClient () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const {customerSetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [editReason, setEditReason] = useState({});
    // STATE END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.customerSetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // COMPONENT FUNC START
    async function disabledReason (data) {
        customerSetting.reason = customerSetting.reason.map(item => {
            if (item.id === data.id) {
                item.disabled = !data.disabled;
                return item;
            } else {
                return item;
            }
        });
        await request(`${URL.back_end}/setting`, 'PUT', {customerSetting: JSON.stringify(customerSetting)})
    }
    // COMPONENT FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Причины пропуска')} <span className="text-muted">({customerSetting ? customerSetting.reason.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-sm btn-block btn-success text-lowercase" onClick={() => openModal("add-reason")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed m-b-sm border-bottom">
                    <tbody className="sortable-list ui-sortable">
                        {
                            customerSetting ?
                                customerSetting.reason.map(item => (
                                    <tr className="sortable-item ui-sortable-handle" style={item.disabled ? {paddingRight: "16px", opacity: 0.5} : {paddingRight: "16px"}} data-id={`${item.id}`}>
                                        <td className="col-sm-7">
                                            <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                            <small className="text-muted"> [ID {item.id}] </small>
                                        </td>
                                        <td className="col-sm-3"> {item.type === '1' && '*'} {item.value} </td>
                                        <td className="text-right col-sm-2">
                                            <a className="m-r-xs" onClick={() => disabledReason(item)}>
                                                <i className={`fa ${item.disabled ? "" : "text-navy"} fa-lg fa-toggle-${item.disabled ? "off" : "on"} fa-fw`} />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={() => {openModal("edit-reason"); setEditReason(item)}}>
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
            <EditReason
                editReason={editReason}
            />
        </>
    )
}