// HOOK
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import {useTranslate} from "../../Hooks/translate.hook";

// COMPONENT
import MobileMenu from "../SideMenu/MobileMenu";
import {SearchBlock} from "./Block/SearchBlock";
import {Alert} from "./Block/Alert";

// JSON
import URL from "../../Api/URL.json";

// FUNCTION
import {openMenu} from "../../Function/common";

// JWT
import jwt_decode from "jwt-decode";
import TaskHeader from "./Block/TaskHeader";

export default function Header () {

    // CONTEXT START
    const userData = jwt_decode( JSON.parse( localStorage.getItem('userData') ).token );
    // CONTEXT END

    // HOOK START
    const { request } = useHttp();
    const { translate } = useTranslate();
    // HOOK END

    // STATE START
    const [ customerData, setCustomerData ] = useState([]);
    // STATE END

    // FUNCTION START
    function refactorNameCustomer (arr) {
        const customer_arr = arr.map(item => {
            if (item.is_lead) {
                item.name = item.name + ` (${translate('Лид')})`
                return item;
            } else {
                item.name = item.name + ` (${translate('Клиент')})`
                return item;
            }
        });

        if (userData.role.interface !== 2) {
            return customer_arr.map(item => {
                const phone = item.contact ? JSON.parse(item.contact).filter(item => item.type === 'mobile')[0]?.text : false;
                if (phone) item.name = item.name + ` (${phone.replace(/[\D+]/gi, '')})`;
                return item;
            })
        } else {
            return customer_arr;
        }
    }
    // FUNCTION END

    // INIT DATA START
    useEffect(() => {

        if (userData.role.interface === 3) return

        async function fetchData () {
            let data;

            if (userData.role.interface === 1) {
                data = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit : true });
            } else if (userData.role.interface === 2) {
                data = await request(`${URL.back_end}/customer_filter`, 'POST', { nolimit : true, data : { employee_interface : userData.systemId } });
            }

            const customers = refactorNameCustomer(data.customers);

            setCustomerData(customers);
        }

        fetchData().catch(e => console.log(e))

    }, [translate])
    // INIT DATA END

    // COMP FUNC START
    function showMenu () {
        let nav_side_menu = document.getElementById("nav-side-menu");
        let sidebar_collapse = document.getElementById("sidebar-collapse");
        let side_menu = document.getElementById("side-menu");
        let body = document.getElementById("body-nav");

        if (nav_side_menu.style.display === "none") {
            nav_side_menu.style.display = "block";
            sidebar_collapse.style.display = "block";
            side_menu.style.display = "block";
            body.classList = "supportDesktop supportOffline";
        } else {
            nav_side_menu.style.display = "none";
            sidebar_collapse.style.display = "none";
            side_menu.style.display = "none";
            body.classList = "supportDesktop supportOffline mini-navbar";
        }
    }
    // COMP FUNC END

    return (
        <div className="border-bottom no-padding m-b-sm white-bg" id="main-header">
            <nav className="navbar no-margins no-padding" role="navigation">
                <ul className="nav navbar-top-links no-margins no-padding">
                    {/*MENU*/}
                    <li className="pull-left border-right hidden-xs nav-action">
                        <a className="crm-menu-toggle" style={{ paddingTop: '5px' }} title={translate('Свернуть / развернуть меню')} onClick={showMenu}>
                            <big className="fa-2x">
                                <i className="ion-navicon" />
                            </big>
                        </a>
                    </li>
                    {/*MOBILE MENU*/}
                    <li className="dropdown pull-left border-right visible-xs nav-action" onClick={e => openMenu(e)}>
                        <a title={translate('Свернуть / развернуть меню')} className="crm-menu-toggle" style={{ paddingTop: '5px' }}>
                            <big className="fa-2x">
                                <i className="ion-navicon" />
                            </big>
                        </a>
                        <ul className="dropdown-menu">
                            <MobileMenu />
                        </ul>
                    </li>
                    {/*LOGO*/}
                    <li className="border-right alfa-header text-center pull-left no-padding no-margins hidden-xs">
                        <a href="/" style={{ paddingTop: '12px' }}>
                            <span className="logo"> RIST </span>
                        </a>
                    </li>
                    {
                        userData.role.interface === 1 ?
                            (
                                <>
                                    {/*SETTING*/}
                                    <li className="nav-action pull-left border-right">
                                        <Link to="/settings/1/default/index" title={translate('Настройки CRM')}>
                                            <i className="ion-wrench" />
                                        </Link>
                                    </li>
                                    {/*ALERT*/}
                                    <li className="pull-left border-right dropdown dropdown-hover">
                                        <Alert
                                            customerData={customerData}
                                        />
                                    </li>
                                    {/*TASK*/}
                                    <li className="pull-left border-right dropdown dropdown-hover">
                                        <TaskHeader
                                            userData={userData}
                                        />
                                    </li>
                                </>
                            )
                            : null
                    }
                    {
                      userData.role.interface === 2 ? (
                        <li className="pull-left border-right dropdown dropdown-hover">
                            <Alert
                              customerData={customerData}
                            />
                        </li>
                      ) : null
                    }
                    {/*THEME*/}
                    <li className="nav-action pull-left border-right" onClick={() => {localStorage.getItem('theme') !== "light"  ? localStorage.setItem('theme', 'light') : localStorage.setItem('theme', 'dark'); window.location.reload()}}>
                        <a title={translate('Изменить тему сайта')}>
                            <i className={`fa fa-${localStorage.getItem('theme') === "light" || !localStorage.getItem('theme') ? "sun" : "moon"}-o`} />
                        </a>
                    </li>
                    {/*SEARCH*/}
                    { userData.role.interface === 1 || userData.role.interface === 2 ? <SearchBlock customerData={customerData} /> : null }
                </ul>
            </nav>
        </div>
    );
}