import {useContext, useEffect, useRef, useState} from "react";
import InputChooseSingle from "../../../Components/InputChoose/InputChooseSingle";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal, filterDisable} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {useNavigate} from "react-router-dom";

export default function FilterGroup (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [employee, setEmployee] = useState('');
    const [knowledge, setKnowledge] = useState('');
    const [status, setStatus] = useState('');
    const [f_removed, setFremoved] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!props.value) {
            setTitle('');
            setDescription('');
            setEmployee('');
            setStatus('');
            setFremoved('');
            return;
        }

        setTitle(props.value.title);
        setDescription(props.value.description);
        setEmployee(props.value.employee);
        setStatus(props.value.status);
        setFremoved(props.value.f_removed);
    }, [props.value])
    // INIT DATA END

    // FILTER GROUP START
    function filterGroup (e) {
        e.preventDefault();

        let group = {
            title,
            description,
            employee,
            knowledge,
            status,
            f_removed
        }

        let obj = Object.filter(group, (value) => value);

        if (Object.keys(obj).length === 0) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/group/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }
    }
    // FILTER GROUP END

    // FUNCTION START
    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div id="filter-group" className="modal in" style={{ paddingRight: "16px" }} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => filterGroup(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body modal-body-search p-md">
                            {/*TITLE*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Название') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={ translate('Введите, название группы') }
                                        value={ title }
                                        onChange={ e => setTitle(e.target.value) }
                                    />
                                </div>
                            </div>
                            {/*DESCRIPTION*/}
                            {/*<div className="form-group">*/}
                            {/*    <div className="col-sm-4">*/}
                            {/*        <label className="control-label">*/}
                            {/*            { translate('Описание') }*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-sm-8">*/}
                            {/*        <input*/}
                            {/*            type="text"*/}
                            {/*            className="form-control"*/}
                            {/*            placeholder={ translate('Введите, описание') }*/}
                            {/*            value={ description }*/}
                            {/*            onChange={ e => setDescription(e.target.value) }*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*EMPLOYEE*/}
                            {
                                props.access && (
                                    <div className="form-group">
                                        <div className="col-sm-4">
                                            <label className="control-label">
                                                { translate('Отв. педагог') }
                                            </label>
                                        </div>
                                        <div className="col-sm-8">
                                            <select className="form-control" value={employee} onChange={e => setEmployee(e.target.value)}>
                                                <option value=''> { translate('Не задано') } </option>
                                                {
                                                    sortArray(props.employeeData, 'name').map(item => (
                                                        <option value={`${item.employee_id}`}> { item.name } </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                )
                            }
                            {/*STATUS*/}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Статус') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={status} onChange={e => setStatus(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            lessonSetting && filterDisable(lessonSetting.groupStatus).map(item => (
                                                <option value={`${item.id}`}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* ARCHIVE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Архив') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={f_removed} onChange={e => setFremoved(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='0'> { translate('Активные') } </option>
                                        <option value='1'> { translate('Архивные') } </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            {/*RESET FILTER*/}
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={translate('Сбросить фильтр и вернуться в таблицу')} onClick={e => { closeModal(e, modal); navigate('/company/1/group/index/') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            {/*SETTING FIELDS*/}
                            {/*<a className="crm-ajax-link btn btn-sm btn-success btn-outline pull-left" title={translate('Настройка полей')}>*/}
                            {/*    <i className="fa fa-cog fa-fw" />*/}
                            {/*</a>*/}
                            {/*CANCEL*/}
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            {/*ACCEPT*/}
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> {translate('Применить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}