import {openModal} from "../../../../Function/common";
import {useContext, useEffect, useState} from "react";
import {SettingContext} from "../../../../Context/SettingContext";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";
import {useTranslate} from "../../../../Hooks/translate.hook";
import EditCategory from "./EditModal/EditCategory";
import $ from 'jquery';
import 'jquery-ui-bundle';
import DeleteModal from "../../../../Components/Modal/DeleteModal";

function drag (setting) {
    $('.sortable-list').sortable({
        connectWith: '.sortable-list',
        distance: 5,
        tolerance: 'pointer',
        items: '.sortable-item',
        update: function(event, ui)
        {
            let data = [];

            $('.sortable-item').each(function(){
                data.push($(this).data('id'));
            });

            $.ajax({
                url: `${URL.back_end}/setting/sort/paySetting`,
                type: "POST",
                headers: {'Content-type' : 'application/json'},
                data: JSON.stringify({data, row: 'category'}),
                success: function(data){
                    setting.paySetting = data;
                }
            });
        }
    });
}

export default function PayCategory () {

    // CONTEXT START
    const setting = useContext(SettingContext);
    const {paySetting} = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // INIT DRAG START
    useEffect(() => {
        if (!setting.paySetting) return
        drag(setting)
    }, [setting])
    // INIT DRAG END

    // STATE START
    const [editCategory, setEditCategory] = useState({});
    const [deleteCategory, setDeleteCategory] = useState({});
    // STATE END

    // COMP FUNC START
    function deleteHandler (item) {
        setDeleteCategory(item);
        openModal('delete-pay-category-overlay');
        openModal('delete-pay-category-modal');
    }

    async function deleteCategoryHandler () {
        paySetting.category = paySetting.category.filter(item => {
            if (item.id !== deleteCategory.id) {
                return item;
            }
        });

        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})

        window.location.reload();
    }
    // COMP FUNC END

    return (
        <>
            <div className="modal-header">
                <h4 className="modal-title">{translate('Категории статей доходов и расходов')} <span className="text-muted">({paySetting ? paySetting.category.length : null})</span></h4>
            </div>
            <div className="modal-body p-md">
                {/*FORM*/}
                <form>
                    <div className="row m-b-md">
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate('Фильтр по названию')}
                                />
                                <span className="input-group-addon" style={{cursor: "pointer"}}>
                                    <i className="ion-search" />
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <a className="btn btn-block btn-sm btn-success" onClick={() => openModal("add-category")}>
                                <i className="ion-plus" /> {translate('Добавить')}
                            </a>
                        </div>
                    </div>
                </form>
                {/*TABLE*/}
                <table className="table table-stripped table-condensed border-bottom m-b-md">
                    <tbody className="sortable-list ui-sortable">
                        {
                            paySetting ?
                                paySetting.category.map(item => (
                                    <tr className="sortable-item ui-sortable-handle" data-id={`${item.id}`}>
                                        <td>
                                            <i className="ion-arrow-swap fa fa-rotate-90 text-muted" style={{opacity: 0.5}} /> {item.name}
                                            <span className="text-muted"> [ID {item.id}]</span>
                                        </td>
                                        <td className="text-right">
                                            <span className="text-muted m-r-sm">
                                                {item.article} {translate('статей')}
                                            </span>
                                            <a className="btn btn-xs btn-white m-r-xxs" onClick={() => {openModal("edit-category"); setEditCategory(item)}}>
                                                <i className="fa fa-pencil text-success fa-fw" />
                                            </a>
                                            <a className="btn btn-xs btn-white" onClick={() => deleteHandler(item)}>
                                                <i className="fa fa-trash-o text-danger fa-fw" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
            <EditCategory
                editCategory={editCategory}
            />
            <DeleteModal
                id="delete-pay-category"
                handler={deleteCategoryHandler}
            />
        </>
    )
}