import SideMenu from "../../Components/SideMenu/SideMenu";
import {useContext, useEffect, useRef, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useHttp} from "../../Hooks/http.hook";
import URL from '../../Api/URL.json';
import GroupTable from "./Table/GroupTable";
import AddGroup from "./Modal/AddGroup";
import AcceptModal from "../../Components/Modal/AcceptModal";
import EditGroup from "./Modal/EditGroup";
import FilterGroup from "./Modal/FilterGroup";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import {useTranslate} from "../../Hooks/translate.hook";
import {SettingContext} from "../../Context/SettingContext";
import {openModal, pagination, filterDisable} from "../../Function/common";
import { ToastContainer } from 'react-toastify';
import jwt_decode from "jwt-decode";
import {AuthContext} from "../../Context/AuthContext";
import TabHeader from "../../Components/TabHeader";
import TabAction from "../../Components/TabAction";

export default function Group () {

    // CONTEXT START
    const { lessonSetting, pageSize } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // FILTER PARAMS START
    const {filterParams} = useParams();
    // FILTER PARAMS END

    // REF START
    const btn_filter_setting = useRef();
    // REF END

    // STATE START
    const [sql, setSql] = useState([]);
    const [counts, setCounts] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [editGroup, setEditGroup] = useState({});

       // DATE
    const [employeeData, setEmployeeData] = useState([]);
    const [loading, setLoading] = useState(true);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData () {
            let data;

            if (auth.role.interface === 2) {
                const employee_id = +jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;
                data = await request(`${URL.back_end}/group_filter`, 'POST', { page, limit : pageSize.group, data : { f_removed : 0, employee_interface : employee_id, ...params }})
            } else {
                data = await request(`${URL.back_end}/group_filter`, 'POST', { page, limit : pageSize.group, data : { f_removed : 0, ...params }})
            }

            if (error) throw error;

            data.groups = data.groups.sort((a, b) => a.title.replace(/і/g, 'и').localeCompare(b.title.replace(/і/g, 'и')));

            setSql(data.groups);
            setCounts(data.counts);
            setLoading(false);
        }
        fetchData().catch(e => console.log(e))
    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {

            const employees = await request(`${URL.back_end}/employee`, 'GET');

            if (error) throw error

            setEmployeeData(employees);
        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    // BUTTON FUNC START
    function buttonEdit () {
        if (selectedRows.length === 1) {
            openModal('edit-group');
            setEditGroup(sql.filter(item => item.group_id === +selectedRows[0])[0])
        }
    }

    function buttonFilterSetting () {
        btn_filter_setting.current.classList.toggle("open");
    }
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="group" />
            <ToastContainer />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-success crm-modal-btn"
                                    icon="ion-plus"
                                    text="Добавить"
                                    handler={() => openModal('add-group')}
                                />
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    disable={selectedRows.length <= 0}
                                    handler={() => buttonEdit()}
                                />
                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-printer"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-info btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'Посещаемость групп за период', icon : 'fa fa-file-text-o' },*/}
                                {/*            { link : '#', text : 'Приток и отток за период', icon : 'fa fa-file-text-o' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="fa fa-ellipsis-h"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : [*/}
                                {/*            { link : '#', text : 'История изменений', icon : 'fa fa-history' },*/}
                                {/*            { link : '#', text : 'Экспорт в файл...', icon : 'fa fa-download' }*/}
                                {/*        ]*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <TabAction
                                    access={auth.role.interface !== 1}
                                    className="btn btn-w-m btn-success btn-sm"
                                    icon="fa fa-eye"
                                    text="Смотреть"
                                />
                                {/*BUTTON FILTER*/}
                                <div className="btn-group btn-group-sm dropdown pull-right" ref={btn_filter_setting}>
                                    <a className="btn btn-primary" style={{width: '96px'}} onClick={() => openModal("filter-group")}>
                                        <i className="fa fa-filter" />
                                        <span className="hidden-xs"> {translate('Фильтр')} </span>
                                    </a>
                                    <a className="btn btn-primary dropdown-toggle" style={{paddingRight: "7px", paddingLeft: "7px", width: "24px"}} onClick={buttonFilterSetting}>
                                        <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/company/1/group/index">
                                                { translate('Сбросить фильтр') }
                                            </Link>
                                        </li>
                                        <li className="divider" />
                                        <li>
                                            <a href="#">
                                                { translate('Управление фильтрами') }
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*FILER OPTIONS*/}
                                {
                                    (filterParams && lessonSetting) &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    title: translate('Название'),
                                                    employee: translate('Отв. педагог'),
                                                    knowledge: translate('Уровень знаний'),
                                                    description : translate('Описание'),
                                                    status: translate('Статус'),
                                                    f_removed: translate('Архив')
                                                }}
                                                meansData={{
                                                    employee: {
                                                        arr : employeeData,
                                                        type_id : 'employee_id',
                                                        topic : 'name'
                                                    },
                                                    status : {
                                                        arr : filterDisable(lessonSetting.groupStatus),
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    f_removed : {
                                                        arr : [{ id : 0, name : translate('Активные') }, { id : 1, name : translate('Архивные') }],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    }
                                                }}
                                                page="group"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            {/*TABS CONTAINER*/}
                            <div className="tabs-container m-b-sm">
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to={'/company/1/group/index'}> { translate('Текущие') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).f_removed === 1 ? 'active' : '' : null}>
                                        <Link to={`/company/1/group/index/${encodeURI(JSON.stringify({ f_removed : 1 }))}`}>
                                            { translate('Архивные') }
                                        </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body">
                                            <div className="grid-view is-bs3 hide-resize">
                                                <TabHeader
                                                    counts={counts}
                                                    entity='group'
                                                />
                                                <div className="border-bottom" />
                                                {/*TABLE VIEW*/}
                                                <GroupTable
                                                    sql={sql}
                                                    updateSql={setSql}
                                                    updateRows={setSelectedRows}
                                                    loading={loading}
                                                />
                                                <div className="text-center" translate="no">
                                                    { pageSize && pagination(counts, pageSize, 'group', page) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL*/}
            <AddGroup
                sql={sql}
                updateSql={setSql}
                employeeData={employeeData}
            />

            <EditGroup
                sql={sql}
                updateSql={setSql}
                employeeData={employeeData}
                editGroup={editGroup}
            />

            <FilterGroup
                employeeData={employeeData}
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                access={auth.role.interface === 1}
            />

            <AcceptModal />
        </div>
    )
}