import {useTranslate} from "../../Hooks/translate.hook";
import {useNavigate} from "react-router-dom";

export default function FilterOptions ({ data, means, meansData, page, viewMode }) {

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    let arr = Object.values(data);
    let result = arr.filter(word => word.length > 0);

    function removeFilter (e) {

        let obj = data;
        delete obj[e.target.dataset.key];

        if (result.length - 1) {
            navigate(`/company/1/${page}/index/${encodeURI(JSON.stringify(obj))}${viewMode ? `?view_mode=${viewMode}` : ''}`);
        } else {
            navigate(`/company/1/${page}/index/${viewMode ? `?view_mode=${viewMode}` : ''}`);
        }

    }

    function list (obj) {
        let result = [];

        function getTopic (arr, topic) {

            if (!arr.length) return '';

            return arr[0][topic];
        }

        for (let key in obj) {
            result.push(
                <span className="label" style={{paddingRight: "15px", marginRight: "2px"}}>
                    { means[key] } : { meansData[key] ? meansData[key].arr.length ? getTopic(meansData[key].arr.filter(item => item[meansData[key].type_id] === +obj[key]), meansData[key].topic) : obj[key] : obj[key] }
                    <a title={ translate('Отменить этот фильтр') } style={{ fontSize: "14px", marginLeft: "2px", marginTop: "3px", position: "absolute" }} data-key={key} onClick={e => removeFilter(e)}>
                        ×
                    </a>
                </span>
            );
        }

        return result;
    }

    return (
        <div className="row m-t-sm">
            <div className="col-sm-12">
                <small className="text-muted border-left" style={{ paddingLeft: '4px' }}> { translate('Применён фильтр') } — </small>
                { list(data).map(item => item) }
                {/*<a className="small m-l-sm crm-dashed-link text-lowercase" title={ translate('Сохранить фильтр для быстрого поиска') }>*/}
                {/*    { translate('Сохранить') }*/}
                {/*</a>*/}
            </div>
        </div>
    )
}