import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {closeModal, filterDisableEntity} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisable} from "../../../Function/common";
import {useNavigate} from "react-router-dom";
import {typeField} from "../../../Function/TypeField";
import UIMenuInput from "../../../Components/InputChoose/UIMenuInput";

export default function FilterCustomer (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { customSetting, customerSetting, salesSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // OBJECT FILTER START
    Object.filter = function( obj, filtercheck ) {
        let result = {};
        Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key]; })
        return result;
    };
    // OBJECT FILTER END

    // STATE START
    const [source, setSource] = useState('');
    const [status, setStatus] = useState('');
    const [name, setName] = useState('');
    const [refusal, setRefusal] = useState('');
    const [gender, setGender] = useState('');
    const [f_removed, setFremoved] = useState();
    const [contact, setContact] = useState('');
    const [group, setGroup] = useState('');
    const [manager, setManager] = useState('');
    const [employee, setEmployee] = useState('');
    const [note, setNote] = useState('');
    const [groups, setGroups] = useState('');

        // SEARCH
    const [employeeSearch, setEmployeeSearch] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {

        if (!props.value) {
            setSource('');
            setStatus('');
            setName('');
            setManager('');
            setRefusal('');
            setGender('');
            setContact('');
            setFremoved('');
            setNote('');
            setEmployee('');
            setGroup('');
            setGroups('');

            customSetting && filterDisableEntity(customSetting.customField, 'customer').filter(item => item?.type !== '13').map(item => document.forms["filter_customer"].elements[item.systemName].value = '');

            return
        }

        setSource(props.value.source);
        setStatus(props.value.status);
        setName(props.value.name);
        setRefusal(props.value.refusal);
        setGender(props.value.gender);
        setContact(props.value.contact);
        setFremoved(props.value.f_removed);
        setNote(props.value.note);
        setEmployee(props.value.employee);
        setGroup(props.value.group);
        setGroups(props.value.groups);
        setManager(props.value.manager);

        customSetting && filterDisableEntity(customSetting?.customField, 'customer').filter(item => item?.type !== '13').map(item => document.forms["filter_customer"].elements[item.systemName].value = props.value[item.systemName] ? props.value[item.systemName] : '');
        
    }, [props.value])
    // INIT DATA END

    // FILTER CUSTOMER START
    function filterCustomer(e) {
        e.preventDefault();

        let customer = {
            source,
            name,
            manager,
            gender,
            contact,
            f_removed,
            note,
            status,
            refusal,
            employee,
            group,
            groups
        }

        filterDisableEntity(customSetting.customField, 'customer').filter(item => item.type !== '13').map(item => {
            const value = document.forms["filter_customer"].elements[item.systemName].value;
            customer[item.systemName] = value.length ? value : null;
        })

        let obj = Object.filter(customer, (value) => value);

        if (!Object.keys(obj).length) {
            closeModal(e, modal);
        } else {
            navigate(`/company/1/customer/index/${encodeURI(JSON.stringify(obj))}`);
            closeModal(e, modal);
        }

    }
    // FILTER CUSTOMER END

    // FUNCTION START
    function sortArray (arr, topic) {
        return arr.filter(item => item[topic]).sort((a, b) => a[topic].replace(/і/g, 'и').localeCompare(b[topic].replace(/і/g, 'и')));
    }
    // FUNCTION END

    return (
        <div id="filter-customer" className="modal in" ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" name="filter_customer" onSubmit={e => filterCustomer(e)}>
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> { translate('Фильтрация данных') } </h4>
                        </div>
                        <div className="modal-body modal-body-search p-md">
                            {/* SOURCE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Источник') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={source} onChange={e => setSource(e.target.value)}>
                                        <option value=' '> { translate('Не задано') } </option>
                                        {
                                            salesSetting && filterDisable(salesSetting.leadSource).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* STATUS */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Статус') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={status} onChange={e => setStatus(e.target.value)}>
                                        <option value=' '> { translate('Не задано') } </option>
                                        {
                                            customerSetting && filterDisable(customerSetting.status).map(item => (
                                                <option value={item.id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* NAME */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('ФИО') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* MANAGER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Ответственный') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={manager} onChange={e => setManager(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value={-1}> { translate('Без ответственного') } </option>
                                        {
                                            sortArray(props.managerData, 'name').map(item => (
                                                <option value={item.user_id}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* EMPLOYEE */}
                            {
                                props.access && (
                                    <div className="form-group">
                                        <div className="col-sm-4">
                                            <label className="control-label">
                                                { translate('Педагог') }
                                            </label>
                                        </div>
                                        <div className="col-sm-8">
                                            <UIMenuInput
                                                data={props.dataEmployee.filter(item => item.f_removed === 0).sort((a, b) => a.name.replace(/і/g, 'и').localeCompare(b.name.replace(/і/g, 'и')))}
                                                searchText={employeeSearch}
                                                setSearchText={setEmployeeSearch}
                                                setId={setEmployee}
                                                value={employee}
                                                placeholder="Поиск педагога"
                                                type_id="employee_id"
                                                topic="name"
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {/* REFUSAL */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Причина отказа') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={refusal} onChange={e => { setRefusal(e.target.value); setFremoved('1'); }}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            customerSetting && filterDisable(customerSetting.refusal).map(item => (
                                                <option value={`${item.id}`}> { item.name } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* GENDER */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Пол') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={gender} onChange={e => setGender(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='1'> { translate('Мужчина') } </option>
                                        <option value='2'> { translate('Женщина') } </option>
                                    </select>
                                </div>
                            </div>
                            {/* CONTACT */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Контакт') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contact}
                                        onChange={e => setContact(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* GROUP */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Группа') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" value={group} onChange={e => setGroup(e.target.value)}>
                                        <option value=''> { translate('Не задано') } </option>
                                        {
                                            sortArray(props.dataGroup, 'title').map(item => (
                                                <option value={`${item.group_id}`}> { item.title } </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* ARCHIVE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Архив') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => setFremoved(e.target.value)} value={f_removed}>
                                        <option value=''> { translate('Не задано') } </option>
                                        <option value='0'> { translate('Активные') } </option>
                                        <option value='1'> { translate('Архивные') } </option>
                                    </select>
                                </div>
                            </div>
                            {/* NOTE */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        { translate('Примечание') }
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, 'customer').filter(item => item.type !== '13').map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-4">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-8">
                                                { typeField(item) }
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-danger btn-outline pull-left" title={ translate('Сбросить фильтр и вернуться в таблицу') } onClick={e => { closeModal(e, modal); navigate('/company/1/customer/index/') }}>
                                <i className="fa fa-eraser fa-fw" />
                            </button>
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-w-m btn-sm btn-primary">
                                <i className="fa fa-filter" /> { translate('Применить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}