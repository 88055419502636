import Gender from "../../../Components/Input/Gender";
import React, {useContext, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {closeModal} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {filterDisableEntity, filterDisable} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import Contact from "../../../Components/Input/Contact";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {validate} from "../../../Function/validateForm";
import {useArchive} from "../../../Hooks/archive.hook";

export default function AddEmployee (props) {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // CONTEXT START
    const { customSetting, lessonSetting } = useContext(SettingContext);
    // CONTEXT END

    // STATE START
    const [name, setName] = useState('');
    const [age, setAge] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [gender, setGender] = useState(null);
    const [contact, setContact] = useState([{ type : 'mobile' }]);
    const [group, setGroup] = useState([]);
    const [status, setStatus] = useState('');
    const [note, setNote] = useState(null);
    const [comment, setComment] = useState('');
    // STATE END

    // ADD EMPLOYEE START
    async function addEmployee (e) {
        e.preventDefault();

        const teacherNote = document.getElementById('teacher_note').innerText;
        const teacherComment = document.getElementById('teacher_comment').innerText;

        let validObj = { "ФИО" : !name.length }

        filterDisableEntity(customSetting.customField, "employee").map(item => {
            let value = document.forms["add_employee"].elements[item.systemName].value;
            validObj[item.name] = item.require ? value !== "[]" ? !value.length : true : null
        })

        if (validate(validObj, translate)) return

        document.getElementById('btn_submit').disabled = true;

        let employee = {
            name,
            age,
            gender,
            note: teacherNote,
            status,
            comment: teacherComment,
            contact : contact[0].type ? JSON.stringify(contact) : null,
            subjects : subjects.length ? JSON.stringify(subjects.map(item => item.id)) : null,
            f_removed: 0
        }

        filterDisableEntity(customSetting.customField, "employee").map(item => {
            let value = document.forms["add_employee"].elements[item.systemName].value;
            employee[item.systemName] = value.length ? value !== "[]" ? value : null : null;
        })

        const { insertId } = await request(`${URL.back_end}/employee`, 'POST', employee);
        await request(`${URL.back_end}/employee_group/${insertId}`, 'POST', group.map(item => item.group_id));
        await pushArchive(1, 'employee', insertId, employee, null);

        if (error) return console.log(error)

        window.location.reload();
    }
    // ADD EMPLOYEE END

    return (
        <div id="add-employee" className="modal in" role="dialog" ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title"> { translate('Добавить педагога') } </h4>
                    </div>
                    <form className="form-horizontal" name="add_employee" onSubmit={e => addEmployee(e)}>
                        {/*BODY*/}
                        <div className="modal-body" style={{paddingBottom: 0}}>
                            {/*NAME*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('ФИО') }
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="teacher-name"
                                        className="form-control"
                                        name="name"
                                        placeholder={translate('Например, Иванов Иван')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* BIRTHDAY +  AGE */}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        { translate('Дата рождения') }
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="date"
                                        className="form-control dob-input"
                                        value={age}
                                        onChange={e => setAge(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Gender setProps={setGender} />
                                    <label className="font-noraml pull-right m-t-xs m-r-sm">{translate('Пол')}</label>
                                </div>
                            </div>
                            {/*CONTACT*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="teacher-contact_contacts">{translate('Контакты')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <Contact
                                        setContact={setContact}
                                        contact={contact}
                                    />
                                </div>
                            </div>
                            {/*SUBJECT*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предметы')}</label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseMultiObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.subject): []}
                                        setProps={setSubjects}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*GROUPS*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Группы') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <InputChooseMultiObject
                                        sql={props.groupsData}
                                        setProps={setGroup}
                                        topicSearch="title"
                                        type_id="group_id"
                                    />
                                </div>
                            </div>
                            {/*STATUS*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Статус') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setStatus(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Примечание') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <span
                                        id="teacher_note"
                                        className="form-control textarea"
                                        role="textbox"
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Комментарий') } </label>
                                </div>
                                <div className="col-sm-9">
                                    <span
                                        id="teacher_comment"
                                        className="form-control textarea"
                                        role="textbox"
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                    />
                                </div>
                            </div>
                            {
                                customSetting ?
                                    filterDisableEntity(customSetting.customField, "employee").map(item => (
                                        <div className={`form-group ${item.require && "required"}`}>
                                            <div className="col-sm-3">
                                                <label className="control-label">{item.name}</label>
                                            </div>
                                            <div className="col-sm-9">
                                                {typeField(item)}
                                            </div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" id="btn_submit">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}