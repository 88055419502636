import React, {memo} from "react";
import TabHeader from "../../../Components/TabHeader";
import {useTranslate} from "../../../Hooks/translate.hook";
import LeadColumns from "./LeadColumns";
import {pagination} from "../../../Function/common";

export const LeadTable = memo(({ sql, setSql, managerData, pageSize, page, counts, viewMode, setSelectedLead, setArchiveCustomer, loading }) => {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // FUNC TABLE START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;

        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(+element.value));
            setSelectedLead(tempSelection);
        } else {
            selection.forEach(element => element.checked = false);
            setSelectedLead([])
        }
    }

    function renderTbody () {

        if (loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (sql.length) {
            return sql.map(item => (
                <LeadColumns
                    data={item}
                    sql={sql}
                    setSql={setSql}
                    managerData={managerData}
                    setSelectedLead={setSelectedLead}
                    setArchiveCustomer={setArchiveCustomer}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // FUNC TABLE END

    return (
        <div className="tab-content">
            <div id="tab-1" className="tab-pane active">
                <div className="panel-body">
                    <TabHeader
                        counts={counts}
                        entity='lead'
                    />
                    <div className="border-bottom" />
                    <div id="w1-container" className="table-responsive kv-grid-container scroll-block">
                        <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                            <thead>
                                <tr>
                                    <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{ width : '5.75%' }}>
                                        <input
                                            type="checkbox"
                                            className="select-on-check-all"
                                            name="selection_all"
                                            onClick={e => selection_all(e)}
                                        />
                                    </th>
                                    <th>
                                        <a>
                                            { translate('Добавлен') }
                                        </a>
                                    </th>
                                    <th>
                                        <a>
                                            { translate('ФИО') }
                                        </a>
                                    </th>
                                    <th>
                                        <a>
                                            { translate('Ответственный') }
                                        </a>
                                    </th>
                                    <th>
                                        <a>
                                            { translate('Этап воронки') }
                                        </a>
                                    </th>
                                    <th>
                                        &nbsp;
                                    </th>
                                </tr>
                            </thead>
                            <tbody> { renderTbody() } </tbody>
                        </table>
                    </div>
                    <div className="text-center" translate="no">
                        { pageSize && pagination(counts, pageSize, 'lead', page, viewMode) }
                    </div>
                </div>
            </div>
        </div>
    )
})