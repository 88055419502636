import {useContext, useRef, useState} from "react";
import {closeModal, filterDisable, maxId} from "../../../../../Function/common";
import {SettingContext} from "../../../../../Context/SettingContext";
import {useHttp} from "../../../../../Hooks/http.hook";
import URL from "../../../../../Api/URL.json";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {errorNotify} from "../../../../../Function/Notify";
import shortid from "shortid";

export default function AddArticle () {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const {request, error} = useHttp();
    const {translate} = useTranslate();
    // HOOK END

    // STATE START
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    // STATE END

    // CONTEXT START
    const {paySetting} = useContext(SettingContext);
    // CONTEXT END

    // ADD ARTICLE START
    async function addArticle (e) {
        e.preventDefault();

        let elems = document.querySelectorAll('#pay-item:checked');
        let type_payment = [].map.call(elems, function (obj) {
            return obj.value;
        })

        let validity = {
            "Категория" : !category.length,
            "Название" : !name.length,
            "Типы платежей" : !type_payment.length,
            result: 0
        }

        for (let key in validity) {
            if (validity[key] && key !== "result") {
                errorNotify(`Необходимо заполнить "${key}"`);
                validity.result += 1;
            }
        }

        if (validity.result > 0) return

        let article = {
            id: paySetting.article.length > 0 ? maxId(paySetting.article)+1 : 0,
            payment: 0,
            name,
            category: +category,
            type_payment,
            disabled: false
        }

        paySetting.article.push(article);
        paySetting.category = paySetting.category.map(item => {
            if (+item.id === +category) {
                item.article += 1;
                return item;
            } else {
                return item;
            }
        })
        await request(`${URL.back_end}/setting`, 'PUT', {paySetting: JSON.stringify(paySetting)})
        if (error) return error;
        window.location.reload();
    }
    // ADD ARTICLE END

    return (
        <div id="add-article" className="modal in" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog">
                <div className="modal-content common-modal-content">
                    <form className="form-horizontal" onSubmit={e => addArticle(e)}>
                        {/*HEADER*/}
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title"> {translate('Добавить статью')} </h4>
                        </div>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*CATEGORY*/}
                            <div className="form-group field-payitem-category_id required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payitem-category_id">{translate('Категория')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => setCategory(e.target.value)}>
                                        <option value="">{translate('Не задано')}</option>
                                        {
                                            paySetting ?
                                                filterDisable(paySetting.category).map(item => (
                                                    <option value={item.id} key={shortid.generate()}>
                                                        {item.name}
                                                    </option>
                                                ))
                                                : null
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*NAME*/}
                            <div className="form-group field-payitem-name required">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="payitem-name">{translate('Название')}</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={translate('Например, Аренда')}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*TYPE PAYMENT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label no-padding" htmlFor="payitem-pay_type_ids">{translate('Типы платежей')}</label>
                                    <br />
                                    <a className="small crm-dashed-link">
                                        {translate('выбрать все')}
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple">
                                    <label>
                                        <input type="checkbox" id="pay-item" name="pay-item" value="1" /> {translate('Доход')}
                                    </label>
                                    <br />
                                    <label>
                                        <input type="checkbox" id="pay-item" name="pay-item" value="2" /> {translate('Возврат средств')}
                                    </label>
                                    <br />
                                    <label>
                                        <input type="checkbox" id="pay-item" name="pay-item" value="3" /> {translate('Корректировка')}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                {translate('Отмена')}
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> {translate('Сохранить')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}