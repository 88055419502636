import SideMenu from "../../Components/SideMenu/SideMenu";
import {LessonTable} from "./Table/LessonTable";
import AddLesson from '../Calendar/Modal/AddLesson';
import SpendLesson from './Modal/SpendLesson';
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useHttp} from "../../Hooks/http.hook";
import URL from '../../Api/URL.json';
import AcceptModal from "../../Components/Modal/AcceptModal";
import FilterLesson from "../Calendar/Modal/FilterLesson";
import FilterOptions from "../../Components/FilterOptions/FilterOptions";
import {useArchive} from "../../Hooks/archive.hook";
import {useTranslate} from "../../Hooks/translate.hook";
import {openModal, pagination} from "../../Function/common";
import jwt_decode from "jwt-decode";
import {AuthContext} from "../../Context/AuthContext";
import TabAction from "../../Components/TabAction";
import {SettingContext} from "../../Context/SettingContext";
import TabHeader from "../../Components/TabHeader";

export default function Lesson () {

    // CONTEXT START
    const { lessonSetting, pageSize } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // PAGE START
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    // PAGE END

    // FILTER PARAMS START
    const { filterParams } = useParams();
    // FILTER PARAMS END

    // SWEET MODAL START
    const sweet_overlay = document.getElementById("sweet-overlay");
    const sweet_alert = document.getElementById("sweet-alert");
    const sweet_text = document.getElementById("sweet-text");
    const confirm_button = document.getElementById("sweet-confirm");
    // SWEET MODAL END

    // STATE START
    const [sql, setSql] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [typeLesson, setTypeLesson] = useState('');
    const [editLesson, setEditLesson] = useState({});

       // DATA
    const [counts, setCounts] = useState();
    const [loading, setLoading] = useState(true);
    const [employeeData, setEmployeeData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [customerLesson, setCustomerLesson] = useState([]);
    const [employeeLesson, setEmployeeLesson] = useState([]);
    const [managerData, setManagerData] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!pageSize) return

        let params = filterParams ? JSON.parse(filterParams) : {};

        setLoading(true);

        async function fetchData() {
            let data;

            if (auth.role.interface === 2) {
                const employee_id = +jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;
                data = await request(`${URL.back_end}/lesson_filter`, 'POST', { page, limit : pageSize.lesson, data : { employee : employee_id, ...params } })
            } else if (auth.role.interface === 3) {
                const customer_id = +jwt_decode(JSON.parse(localStorage.getItem('userData')).token).systemId;
                data = await request(`${URL.back_end}/lesson_filter`, 'POST', { page, limit : pageSize.lesson, data : { customer : customer_id, ...params } })
            } else {
                data = await request(`${URL.back_end}/lesson_filter`, 'POST', { page, limit : pageSize.lesson, data : { ...params } })
            }

            if (error) throw error;

            setSql(data.lessons)
            setCounts(data.counts)
            setLoading(false)
        }
        fetchData().catch(e => console.log(e))
    }, [page, pageSize, filterParams])

    useEffect(() => {

        async function fetchData () {
            const employees = await request(`${URL.back_end}/employee`, 'GET');
            const groups = await request(`${URL.back_end}/group`, 'GET');
            const customers = await request(`${URL.back_end}/customer`, 'GET');
            const customersLesson = await request(`${URL.back_end}/lesson_customer`, 'GET');
            const employeesLesson = await request(`${URL.back_end}/lesson_employee`, 'GET');
            const managers = await request(`${URL.back_end}/user_filter`, 'POST', { nolimit : true, data : { interface : 1 } });

            if (error) throw error;

            setEmployeeData(employees)
            setGroupData(groups)
            setCustomerData(customers)
            setCustomerLesson(customersLesson)
            setEmployeeLesson(employeesLesson)
            setManagerData(managers.users)
        }

        fetchData().catch(e => console.log(e))
    }, [])
    // INIT DATA END

    // BUTTON FUNC START
    function handlerTypeLesson (e, format) {
        e.preventDefault();
        setTypeLesson(format);
        openModal('add-lesson');
    }

    function buttonEdit () {
        if (selectedRows.length === 1) {
            openModal('spend-lesson');
            // eslint-disable-next-line array-callback-return
            setEditLesson(sql.filter(item => item.lesson_id === +selectedRows[0])[0])
        }
    }

    function buttonDelete () {
        if (selectedRows.length === 1) {
            sweet_alert.style.display = "block";
            sweet_overlay.style.display = "block";
            sweet_text.innerText = "Вы уверены, что хотите удалить запись?";

            confirm_button.addEventListener('click', async function () {
                sweet_alert.style.display = "none";
                sweet_overlay.style.display = "none";

                // REQUEST
                await request(`${URL.back_end}/lesson/${selectedRows[0]}`, 'DELETE');

                if (error) return console.log(error)

                await pushArchive(3, 'lesson', +selectedRows[0])

                setSql(sql.filter(item => item.lesson_id !== +selectedRows[0]))
            })
        } else {
            sweet_alert.style.display = "block";
            sweet_overlay.style.display = "block";
            sweet_text.innerText = "Вы уверены, что хотите удалить запись?";

            confirm_button.addEventListener('click', async function () {
                sweet_alert.style.display = "none";
                sweet_overlay.style.display = "none";

                // REQUEST
                await request(`${URL.back_end}/lesson`, 'DELETE', {lesson_ids : selectedRows});

                if (error) return console.log(error)

                for (let i = 0; i < selectedRows.length; i++) {
                    await pushArchive(3, 'lesson', +selectedRows[i])
                }

                setSql(sql.filter(item => !selectedRows.includes(item.lesson_id.toString())))
            })
        }
    }
    // BUTTON FUNC END

    return (
        <div id="wrapper">
            {/*SIDE MENU*/}
            <SideMenu page="calendar" />
            {/*CONTENT*/}
            <div id="page-wrapper" className="gray-bg" style={{minHeight: "624px"}}>
                <div className="wrapper crm-content">
                    {/*ACTIONS*/}
                    <div className="row m-b-sm crm-stick crm-actions">
                        <div className="col-sm-12">
                            <div className="ibox-content border-bottom">
                                {/*BTN GROUP*/}
                                <div className="btn-group crm-view-change m-r-xs">
                                    <Link to="/company/1/calendar/index" className="btn btn-sm btn-white" title={translate('Отображение: в виде календаря')}>
                                        <i className="ion-calendar" />
                                    </Link>
                                    <Link to="/company/1/lesson/index" className="btn btn-sm btn-white active" title={translate('Отображение: в виде таблицы')}>
                                        <i className="ion-navicon-round" />
                                    </Link>
                                    {
                                        auth.role.interface === 1 &&
                                            <Link to="/company/1/calendar-teacher/index" className="btn btn-sm btn-white" title={translate('График работы педагогов')}>
                                                <i className="ion-university" />
                                            </Link>
                                    }
                                </div>
                                <TabAction
                                    access={auth.role.interface !== 3}
                                    className="dropdown btn-group no-padding"
                                    icon="ion-plus"
                                    dropdown={{
                                        buttonClass : 'btn btn-sm btn-success btn-w-m dropdown-toggle',
                                        text : 'Добавить',
                                        list : [
                                            { text : 'Индивидуальный', icon : 'ion-person', handler : e => handlerTypeLesson(e, 1)},
                                            { text : 'Групповой', icon : 'ion-person-stalker', handler : e => handlerTypeLesson(e, 2)},
                                            { text : 'Пробный', icon : 'ion-asterisk', handler : e => handlerTypeLesson(e, 3)}
                                        ]
                                    }}
                                />
                                <TabAction
                                    access={auth.role.interface !== 3}
                                    className="btn btn-sm btn-w-m btn-success"
                                    icon="ion-edit"
                                    text="Править"
                                    disable={selectedRows.length !== 1}
                                    handler={() => buttonEdit()}
                                />
                                <TabAction
                                    access={auth.role.interface === 1}
                                    className="btn btn-sm btn-w-m btn-danger"
                                    icon="fa fa-trash-o"
                                    text="Удалить"
                                    disable={!selectedRows.length}
                                    handler={() => buttonDelete()}
                                />
                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="ion-printer"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-info btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Отчеты',*/}
                                {/*        list : []*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*<TabAction*/}
                                {/*    access={auth.role.interface === 1}*/}
                                {/*    className="dropdown btn-group no-padding"*/}
                                {/*    icon="fa fa-ellipsis-h"*/}
                                {/*    dropdown={{*/}
                                {/*        buttonClass : 'btn btn-sm btn-default btn-w-m dropdown-toggle',*/}
                                {/*        text : 'Другое',*/}
                                {/*        list : []*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <TabAction
                                    access={auth.role.interface !== 3}
                                    className="btn btn-sm btn-w-m btn-primary pull-right"
                                    icon="fa fa-filter"
                                    text="Фильтр"
                                    handler={() => openModal('filter-lesson')}
                                />
                                {/*FILTER OPTIONS*/}
                                {
                                    ( lessonSetting && filterParams ) &&
                                        (
                                            <FilterOptions
                                                data={JSON.parse(decodeURI(filterParams))}
                                                means={{
                                                    customer: translate('Клиент'),
                                                    employee: translate('Педагог'),
                                                    manager : translate('Ответственный'),
                                                    group: translate('Группа'),
                                                    subject: translate('Предмет'),
                                                    type_lesson: translate('Тип урока'),
                                                    audience: translate('Аудитория'),
                                                    status : translate('Статус'),
                                                    dateStart : translate('с'),
                                                    dateEnd : translate('до'),
                                                    lostLesson: 'Заняття',
                                                }}
                                                meansData={{
                                                    customer : {
                                                        arr : customerData,
                                                        type_id : 'customer_id',
                                                        topic : 'name'
                                                    },
                                                    employee : {
                                                        arr : employeeData,
                                                        type_id : 'employee_id',
                                                        topic : 'name'
                                                    },
                                                    manager : {
                                                      arr : managerData,
                                                      type_id : 'user_id',
                                                      topic : 'name'
                                                    },
                                                    group : {
                                                        arr : groupData,
                                                        type_id : 'group_id',
                                                        topic : 'title'
                                                    },
                                                    subject : {
                                                        arr : lessonSetting.subject,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    audience : {
                                                        arr : lessonSetting.room,
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    status : {
                                                        arr : [ { id : 1, name : translate('Проведен') }, { id : 2, name : translate('Отменен') }, { id : 0, name : translate('Запланирован') } ],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    type_lesson : {
                                                        arr : [ { id : 1, name : translate('Индивидуальный')}, { id : 2, name : translate('Групповой') }, { id : 3, name : translate('Пробный') } ],
                                                        type_id : 'id',
                                                        topic : 'name'
                                                    },
                                                    lostLesson : {
                                                        arr : [ { id: 1, name: 'Не проведені' } ],
                                                        type_id: 'id',
                                                        topic: 'name'
                                                    }
                                                }}
                                                page="lesson"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/*TABLE*/}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs-container m-b-sm">
                                {/*NAV TABS*/}
                                <ul className="nav nav-tabs">
                                    <li className={!filterParams ? 'active' : ''}>
                                        <Link to="/company/1/lesson/index/"> { translate('Все') } </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).status === '1' ? 'active' : '' : ''}>
                                        <Link
                                            to={`/company/1/lesson/index/${encodeURI(JSON.stringify({ status : '1' }))}`}
                                        >
                                            { translate('Проведен') }
                                        </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).status === '2' ? 'active' : '' : ''}>
                                        <Link
                                            to={`/company/1/lesson/index/${encodeURI(JSON.stringify({ status : '2' }))}`}
                                        >
                                            { translate('Отменен') }
                                        </Link>
                                    </li>
                                    <li className={filterParams ? JSON.parse(filterParams).status === '0' ? 'active' : '' : ''}>
                                        <Link
                                            to={`/company/1/lesson/index/${encodeURI(JSON.stringify({ status : '0' }))}`}
                                        >
                                            { translate('Запланирован') }
                                        </Link>
                                    </li>
                                </ul>
                                {/*TAB CONTENT*/}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active">
                                        <div className="panel-body">
                                            <div id="w1">
                                                {/*HEADER*/}
                                                <TabHeader
                                                    counts={counts}
                                                    entity='lesson'
                                                />
                                                <div className="border-bottom" />
                                                {/*TABLE VIEW*/}
                                                <LessonTable
                                                    sql={sql}
                                                    updateSql={setSql}
                                                    customerLesson={customerLesson}
                                                    employeeLesson={employeeLesson}
                                                    updateRows={setSelectedRows}
                                                    loading={loading}
                                                />
                                                <div className="text-center" translate="no">
                                                    { pageSize && pagination(counts, pageSize, 'lesson', page) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*MODAL*/}
            {/*<AddLesson*/}
            {/*    sql={sql}*/}
            {/*    updateSql={setSql}*/}
            {/*    employeeData={employeeData}*/}
            {/*    groupData={groupData}*/}
            {/*    customerData={customerData}*/}
            {/*    type_lesson={typeLesson}*/}
            {/*/>*/}

            <AddLesson
                groupData={groupData}
                employeeData={employeeData}
                customerData={customerData}
                type_lesson={typeLesson}
            />

            <SpendLesson
                sql={sql}
                updateSql={setSql}
                employeeData={employeeData}
                groupData={groupData}
                customerData={customerData}
                editLesson={editLesson}
            />

            <FilterLesson
                customerData={customerData}
                employeeData={employeeData}
                groupData={groupData}
                managerData={managerData}
                page="lesson"
                value={filterParams ? JSON.parse(decodeURI(filterParams)) : null}
                access={auth.role.interface === 1}
            />

            <AcceptModal />
        </div>
    )
}