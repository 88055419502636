import {closeModal, findObject} from "../../../Function/common";
import {useContext, useEffect, useRef, useState} from "react";
import {useTranslate} from "../../../Hooks/translate.hook";
import {useHttp} from "../../../Hooks/http.hook";
import URL from "../../../Api/URL.json";
import {SettingContext} from "../../../Context/SettingContext";

export default function AnalyticModal () {

	// REF START
	const modal = useRef();
	// REF END

	// CONTEXT START
	const { salesSetting } = useContext(SettingContext);
	// CONTEXT END

	// HOOK START
	const { request } = useHttp();
	const { translate } = useTranslate();
	// HOOK END

	// STATE START
	const [analyticData, setAnalyticData] = useState([]);
	// STATE END

	// INIT DATA START
	useEffect(() => {

		async function fetchData () {
			const data = await request(`${URL.back_end}/mailing_history`, 'GET');
			setAnalyticData(data);
		}

		fetchData().catch((event) => console.log(event));

	}, [])
	// INIT DATA END

	return (
		<div id="analytic-modal" className="modal in" ref={modal}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content common-modal-content" style={{ overflow: 'hidden' }}>
					<form className="form-horizontal" style={{ overflow: 'auto' }}>
						{/*HEADER*/}
						<div className="modal-header">
							<button type="button" className="close" onClick={e => closeModal(e, modal)}>
								<span>×</span>
							</button>
							<h4 className="modal-title"> Аналітика розсилки повідомлень </h4>
						</div>
						{/*BODY*/}
						<div className="modal-body p-md" style={{ width: '2000px' }}>
							<div className="table-responsive kv-grid-container scroll-block">
								<div className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap table-text-center">
									<thead>
										<tr>
											<th> ID </th>
											<th> Назва бота </th>
											<th style={{ width: '10%' }}> Повідомлення </th>
											<th> Файл </th>
											<th> Клієнтів </th>
											<th> Активних клієнтів </th>
											<th> Архівних клієнтів </th>
											<th> Лідів </th>
											<th> Активних лідів </th>
											<th> Архівних лідів </th>
											<th> Джерела </th>
										</tr>
									</thead>
									<tbody>
										{
											analyticData.map((data) => (
												<tr>
													<td style={{ width: '1%' }}> { data.id } </td>
													<td style={{ width: '2%' }}> { data.bot_name } </td>
													<td style={{ width: 'auto' }}> { data.message } </td>
													<td style={{ width: '1%' }}> { data.file ? data.file : <i className="text-danger small">не задано</i> } </td>
													<td style={{ width: '2%' }}> { data.customerCount } </td>
													<td style={{ width: '2%' }}> { data.customerActiveCount } </td>
													<td style={{ width: '2%' }}> { data.customerArchiveCount } </td>
													<td style={{ width: '2%' }}> { data.leadCount } </td>
													<td style={{ width: '2%' }}> { data.leadActiveCount } </td>
													<td style={{ width: '2%' }}> { data.leadArchiveCount } </td>
													<td style={{ width: '2%' }}>
														{
															(data.sources && salesSetting) ?
																JSON.parse(data.sources).map((item) => findObject(salesSetting.leadSource, Number(item)).name).join(', ')
																: null
														}
													</td>
												</tr>
											))
										}
									</tbody>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}