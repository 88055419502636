function calculateSalary (lessons, wage, customersLesson) {

    function getCustomerCount (type, reasonType, arr) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 1 : return arr.filter(item => item.presence === 1 || item.presence === true)
            case 2 : return arr.filter(item => (item.presence === 0 || item.presence === false) && JSON.parse(reasonType).includes(+item.reason))
            case 3 : return arr.filter(item => (item.presence === 0 || item.presence === false) ? JSON.parse(reasonType).includes(+item.reason) : item)
        }
    }

    function getDuration (date_start, date_end) {
        let [hourStart, minuteStart] = date_start.split('T')[1].slice(0, 5).split(':');
        let [hourEnd, minuteEnd] = date_end.split('T')[1].slice(0, 5).split(':');
        return ( Number(hourEnd) * 60 + Number(minuteEnd) ) - ( Number(hourStart) * 60 + Number(minuteStart) );
    }

    let salary = 0;
    let clearSalary = 0;
    let progressive = false;

    for (let i = 0; i < lessons.length; i++) {

        let lessonDuration = getDuration(lessons[i].date_start, lessons[i].date_end);
        let customer       = customersLesson.filter(cust => cust.lesson_id === lessons[i].lesson_id);

        const lessonDate = new Date(lessons[i].date_end);

        for (let j = 0; j < wage.length; j++) {

            const wageDateStart = new Date(wage[j].date_start + 'T00:00:00Z');
            const wageDateEnd   = new Date(wage[j].date_end + 'T23:59:59Z');

            if (lessonDate <= wageDateStart || lessonDate >= wageDateEnd) continue;

            // SUBJECT + TYPE
            if (!JSON.parse(wage[j].lessons).includes(lessons[i].subject) || !JSON.parse(wage[j].type_lessons).includes(lessons[i].type_lesson)) continue;

            // DURATION
            if (!wage[j].increasePropDuration) {
                if (wage[j].lesson_duration && (Number(wage[j].lesson_duration) !== lessonDuration)) continue;
            }

            // COUNT CLIENT
            let customerCount = getCustomerCount(wage[j].type, wage[j].reason, customer).length;

            if (customerCount === 0) continue;

            if (wage[j].client_from || wage[j].client_to) {
                if (wage[j].client_from && wage[j].client_to) {
                    if ( Number(wage[j].client_from) === Number(wage[j].client_to) ) {
                        if ( Number(wage[j].client_from) !== customerCount ) {
                            continue;
                        }
                    } else if ( Number(wage[j].client_from) > customerCount || Number(wage[j].client_to) < customerCount ) {
                        continue;
                    }
                } else if (wage[j].client_from && !wage[j].client_to) {
                    if ( Number(wage[j].client_from) > customerCount ) {
                        continue;
                    }
                } else if (!wage[j].client_from && wage[j].client_to) {
                    if ( Number(wage[j].client_to) < customerCount ) {
                        continue;
                    }
                }
            }

            let balance = 0;

            clearSalary += Number(wage[j].amount);
            progressive = wage[j].increasePerClient || wage[j].increasePropDuration;

            if (!wage[j].increasePerClient && !wage[j].increasePropDuration) {
                balance += Number(wage[j].amount);
            } else {
                if (wage[j].increasePerClient) balance += customerCount * Number(wage[j].amount);
                if (wage[j].increasePropDuration && wage[j].lesson_duration) {
                    balance = (lessonDuration / wage[j].lesson_duration).toFixed(2) * (balance === 0 ? Number(wage[j].amount) : balance);
                }
            }

            salary += balance;
        }

    }

    return { salary, clearSalary, progressive };
}

export { calculateSalary }