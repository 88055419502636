import iconCalendar from "../../../Assets/data/JSON/iconCalendar.json";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {useContext, useEffect, useRef, useState} from "react";
import {useHttp} from "../../../Hooks/http.hook";
import URL from '../../../Api/URL.json';
import {
    addMinutesToTime,
    closeModal,
    filterDisable,
    filterDisableEntity,
    findObject,
    findRequired,
    getRequired
} from "../../../Function/common";
import {SettingContext} from "../../../Context/SettingContext";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import TableResponsive from "../../../Components/Table/Responnsive/TableResponsive";
import {validate} from "../../../Function/validateForm";
import typeLessons from "../../../Assets/data/JSON/typeLessons.json";
import moment from "moment";
import {errorNotify, success} from "../../../Function/Notify";
import FileList from "../../../Components/List/FileList";
import {useArchive} from "../../../Hooks/archive.hook";

export default function SpendLesson (props) {

    // REF START
    const modal = useRef();
    const btn_submit = useRef();
    // REF END

    // VALUE START
    const calendar_block = document.getElementsByClassName('fc-media-screen')[0];
    // VALUE END

    // CONTEXT START
    const { lessonSetting, customSetting } = useContext(SettingContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // STATE START
    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState('');
    const [time, setTime] = useState('');
    const [minute, setMinute] = useState('');
    const [subject, setSubject] = useState('');
    const [audience, setAudience] = useState('');
    const [employee, setEmployee] = useState([]);
    const [group, setGroup] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [topic, setTopic] = useState('');
    const [homework, setHomework] = useState('');
    const [note, setNote] = useState('');

        // FILE
    const [storageFile, setStorageFile] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.editLesson).length) {
            setCustomer([]);
            setGroup([]);
            setAudience('');
            setTopic('');
            setHomework('');
            setNote('');
            return;
        }

        let [ hoursStart, minuteStart ] = props.editLesson.date_start.split('T')[1].slice(0, 5).split(':');
        let [ hoursEnd, minuteEnd ] = props.editLesson.date_end.split('T')[1].slice(0, 5).split(':');

        setTime(`${hoursStart}:${minuteStart}`);
        setMinute(( ( Number(hoursEnd) === 0 ? 24 : Number(hoursEnd) ) * 60 + Number(minuteEnd) ) - ( Number(hoursStart) * 60 + Number(minuteStart) ))
        setDateStart(props.editLesson.date_start.split('T')[0]);
        setDateEnd(props.editLesson.date_end);
        setSubject({ id : props.editLesson.subject });
        setAudience({ id : props.editLesson.audience });
        setTopic(props.editLesson.topic);
        setHomework(props.editLesson.homework);
        setNote(props.editLesson.l_note ? props.editLesson.l_note : '');

        async function relateFetch () {
            const groups = await request(`${URL.back_end}/lesson_group/${props.editLesson.lesson_id}`, 'GET')
            const customers = await request(`${URL.back_end}/lesson_customer/${props.editLesson.lesson_id}`, 'GET');
            const employees = await request(`${URL.back_end}/lesson_employee/${props.editLesson.lesson_id}`, 'GET');
            const getFiles = await request(`${URL.back_end}/get-files/lesson/${props.editLesson.lesson_id}`, 'GET');

            if (error) throw error;

            setGroup(groups);
            setCustomer(customers.filter(item => props.editLesson.status === 1 ? item.presence !== null : item.f_removed === 0));
            setEmployee(employees);
            setStorageFile(getFiles);
        }

        relateFetch().catch(e => console.log(e))

        filterDisableEntity(customSetting ? customSetting.customField : [], "lesson").map(item => {
            document.forms["edit_lesson"].elements["lesson-edit-"+item.systemName].value = props.editLesson[item.systemName];
        })

    }, [props.editLesson])
    // INIT DATA END

    // HANDLER START
    function dateStartHandler (e) {
        setDateStart(moment(e.target.value).format('YYYY-MM-DD'));
        dateEndHandler();
    }

    function timeHandler (e) {
        setTime(e.target.value);
        if (!(/_/g).test(e.target.value)) {
            dateEndHandler();
        }
    }

    function minuteHandler (e) {
        setMinute(e.target.value);
        if (!(/_/g).test(e.target.value)) {
            dateEndHandler();
        }
    }

    function dateEndHandler () {
        const inputTime = document.getElementById('spend-time').value;
        const inputMinute = document.getElementById('spend-minute').value;

        if (!inputTime || !inputMinute) return

        const lessonDate = dateStart.split('T')[0];
        setDateEnd(`${lessonDate}T${addMinutesToTime(inputTime, Number(inputMinute))}:00Z`);
    }
    // HANDLER END

    // FUNCTION START
    const selectAllCustomer = (e, value) => {
        e.preventDefault();

        document.getElementById('deselect-all-edit').classList.toggle('hide');
        document.getElementById('select-all-edit').classList.toggle('hide');

        let arr = customer.map(item => {
            item.presence = value;
            return item;
        })

        setCustomer(arr);
    };

    async function sendImportFile (e) {
        e.preventDefault();

        if (e.target.files[0].size > 10000000) return errorNotify(`Розмір файлу не може перевищувати 10 мегабайт`);

        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        const response = await request(`${URL.back_end}/save-file/lesson/${props.editLesson.lesson_id}`, 'POST', formData, {}, true);
        await pushArchive(1, 'crmFile', props.editLesson.lesson_id, { file_name : e.target.files[0].name, lesson_id : props.editLesson.lesson_id }, null);

        if (response.message) return errorNotify(response.message);

        const getFiles = await request(`${URL.back_end}/get-files/lesson/${props.editLesson.lesson_id}`, 'GET');

        setStorageFile(getFiles);

        e.target.value = '';

        success( translate('Файл загружен') );
    }

    function clearState () {
        setDateEnd('');
        setTime('');
        setMinute('');
        setSubject('');
        setAudience('');
        setEmployee([]);
        setGroup([]);
        setCustomer([]);
        setTopic('');
        setHomework('');
        setNote('');
        setStorageFile([]);
    }

    function handlerGroup (value) {

        setGroup(value);

        function getDiapasonDate(start, end) {
            for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                arr.push(new Date(dt).toLocaleDateString());
            }
            return arr;
        }

        function checkDiapason (start, end) {
            if (!Object.keys(props.editLesson).length) return true;
            return getDiapasonDate(start, end).includes(new Date(props.editLesson.date_start).toLocaleDateString())
        }

        async function relateFetch () {
            if (!value.length) return

            let customerFromGroups = await request(`${URL.back_end}/customer_group_range`, 'POST', value.map(item => Number(item.group_id)));
            const customer_ids = customer.map(item => item.customer_id);

            if (error) throw error;

            customerFromGroups = customerFromGroups.filter(item => !customer_ids.includes(item.customer_id) && checkDiapason(item.date_start, item.date_end) && item.f_removed === 0)

            setCustomer([...customer, ...customerFromGroups])
        }

        relateFetch().catch(e => console.log(e));
    }
    // FUNCTION END

    // EDIT LESSON START
    async function editLesson (e) {
        e.preventDefault();

        const time = document.getElementById('spend-time').value;
        const date_start = `${dateStart}T${time}:00Z`;

        let validObj = {
            "Дата и время" : !date_start || !dateEnd,
            "Предмет" : !Object.keys(subject).length,
            "Педагоги" : !employee.length,
            "Кто был?" : !group.length && !customer.length,
            "Комментарий" : findRequired(customSetting.requiredField, 'l_note', 'lesson').require ? !note.length : note?.length > 1000
        }

        if (validate(validObj, translate)) return

        btn_submit.current.disabled = true;

        let lesson = {
            topic,
            homework,
            l_note : note,
            edited: true,
            status: 1,
            date_start: date_start,
            date_end: dateEnd,
            subject: subject.id,
            audience: Object.keys(audience).length > 0 ? audience.id : -1,
            type_lesson: props.editLesson.type_lesson,
            commission: customer.length !== 0 ? customer.map(item => item.commission).reduce((prev,curr) => prev + curr) : null
        }

        filterDisableEntity(customSetting.customField, "lesson").map(item => {
            lesson[item.systemName] = document.forms["edit_lesson"].elements["lesson-edit-"+item.systemName].value
        })

        await request(`${URL.back_end}/spend_lesson/${props.editLesson.lesson_id}`, 'POST', { lesson, customer, group, employee, edited: props.editLesson.status === 1 })
        await pushArchive(2, 'lesson', props.editLesson.lesson_id, { ...lesson, lesson_id : props.editLesson.lesson_id, edited : props.editLesson.edited, regular_id : props.editLesson.regular_id }, props.editLesson);

        if (error) return console.log(error)

        window.location.reload()
    }
    // EDIT LESSON END

    return (
        <div id="spend-lesson" className="modal in" style={{ paddingRight: '16px' }} ref={modal}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => {
                            closeModal(e, modal);
                            clearState();
                            if (calendar_block) calendar_block.style.opacity = 1
                        }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title">
                            <i className={iconCalendar[props.editLesson.type_lesson]} /> {translate(typeLessons[props.editLesson.type_lesson])} — {translate('провести')}
                        </h4>
                    </div>
                    <form className="form-horizontal lesson-done-form" name="edit_lesson" onSubmit={e => editLesson(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*DATETIME*/}
                            <div className="form-group required">
                                <div className="col-sm-2">
                                    <label className="control-label"> { translate('Дата и время') } </label>
                                </div>
                                <div className="col-sm-5 date">
                                    <input
                                        type="date"
                                        className="form-control date-control"
                                        autoComplete="off"
                                        value={dateStart}
                                        onChange={e => dateStartHandler(e)}
                                    />
                                </div>
                                <div className="col-sm-5">
                                    <div className="input-group">
                                        <span className="input-group-addon text-muted"><small> { translate('с') } </small></span>
                                        <input
                                            type="time"
                                            className="form-control time-input time-from text-center"
                                            id="spend-time"
                                            value={time}
                                            onChange={e => timeHandler(e)}
                                        />
                                        <span className="text-muted input-group-addon" style={{ borderLeft: 0, borderRight: 0 }}>
                                            <small> { translate('мин') } </small>
                                        </span>
                                        <input
                                            type="number"
                                            className="form-control duration-input hide-arrows"
                                            id="spend-minute"
                                            step={1}
                                            min={0}
                                            max={1000}
                                            style={{ minWidth: '70px' }}
                                            value={minute}
                                            onChange={e => minuteHandler(e)}
                                        />
                                        {/*SHOW TIME TO*/}
                                        <span className={`input-group-addon text-muted ${dateEnd ? '' : 'hide'}`}>
                                            <small>
                                                <span className="text-lowercase"> { translate('До') } </span>
                                                <span className="time-to"> { dateEnd && dateEnd.split('T')[1].slice(0, 5) } </span>
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*SUBJECT + AUDIENCE*/}
                            <div className="form-group required">
                                <div className="col-sm-2">
                                    <label className="control-label">{translate('Предмет')}</label>
                                </div>
                                <div className="col-sm-5">
                                    {
                                        props.editLesson.status === 1 ?
                                            (
                                                <div className="form-control">
                                                    { lessonSetting && findObject(lessonSetting.subject, subject.id)?.name }
                                                </div>
                                            )
                                            :
                                            (
                                                <InputChooseSingleObject
                                                    sql={lessonSetting ? filterDisable(lessonSetting.subject) : []}
                                                    setProps={setSubject}
                                                    value={lessonSetting ? findObject(lessonSetting.subject, subject.id) ? findObject(lessonSetting.subject, subject.id).name : null : null}
                                                    topicSearch="name"
                                                    type_id="id"
                                                />
                                            )
                                    }
                                </div>
                                <div className="col-sm-5">
                                    <div className="input-group">
                                        <span className="input-group-addon text-muted"><i className="ion-location m-l-xxs m-r-xxs" /></span>
                                        <InputChooseSingleObject
                                            sql={lessonSetting ? filterDisable(lessonSetting.room) : []}
                                            setProps={setAudience}
                                            value={lessonSetting ? findObject(lessonSetting.room, audience.id) ? findObject(lessonSetting.room, audience.id).name : null : null}
                                            topicSearch="name"
                                            type_id="id"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div className="form-group required">
                                <div className="col-sm-2">
                                    <label className="control-label">{translate('Педагог(и)')}</label>
                                </div>
                                <div className="col-sm-10">
                                    <InputChooseMultiObject
                                        sql={props.employeeData.filter(item => item.f_removed === 0)}
                                        setProps={setEmployee}
                                        topicSearch="name"
                                        type_id="employee_id"
                                        value={employee}
                                    />
                                </div>
                            </div>
                            {/*Кто был?*/}
                            <div className="form-group required">
                                <div className="col-sm-2">
                                    <label className="control-label">{translate('Кто был?')}</label>
                                </div>
                                {
                                    props.editLesson.type_lesson === 1 ? (
                                        <div className="col-sm-10">
                                            <InputChooseMultiObject
                                                sql={props.customerData.filter(item => item.f_removed === 0)}
                                                topicSearch="name"
                                                type_id="customer_id"
                                                value={customer}
                                                setProps={setCustomer}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {/*GROUP*/}
                                            <div className="col-sm-5">
                                                <InputChooseMultiObject
                                                    sql={props.groupData.filter(item => item.f_removed === 0)}
                                                    topicSearch="title"
                                                    type_id="group_id"
                                                    value={group}
                                                    setProps={(value) => handlerGroup(value)}
                                                />
                                            </div>
                                            {/*CUSTOMER*/}
                                            <div className="col-sm-5">
                                                <InputChooseMultiObject
                                                    sql={props.customerData.filter(item => item.f_removed === 0)}
                                                    topicSearch="name"
                                                    type_id="customer_id"
                                                    value={customer}
                                                    setProps={setCustomer}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            {/*TABLE RESPONSIVE*/}
                            <div className="row">
                                <div className="col-sm-2" />
                                <div className="col-sm-10">
                                    <TableResponsive
                                        group={group}
                                        customer={customer}
                                        setCustomer={setCustomer}
                                        subject={subject}
                                        const_subject={{ id : props.editLesson.subject }}
                                        editLesson={props.editLesson}
                                        type_lesson={props.editLesson.type_lesson}
                                        minute={minute}
                                    />
                                </div>
                            </div>
                            {/*TOPIC*/}
                            <div className={`form-group ${lessonSetting ? lessonSetting.lessonOption.topic ? "" : "hide" : null}`}>
                                <div className="col-sm-2">
                                    <label className="control-label">{translate('Тема')}</label>
                                </div>
                                <div className="col-sm-10">
                                    <textarea
                                        className="form-control autoresize ui-autocomplete-input"
                                        rows={1}
                                        autoComplete="off"
                                        value={topic}
                                        onChange={e => setTopic(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*HOMEWORK*/}
                            <div className={`form-group ${lessonSetting ? lessonSetting.lessonOption.homework ? "" : "hide" : null}`}>
                                <div className="col-sm-2">
                                    <label className="control-label">{translate('Домашнее задание')}</label>
                                </div>
                                <div className="col-sm-10">
                                    <textarea
                                        className="form-control autoresize"
                                        rows={1}
                                        autoComplete="off"
                                        value={homework}
                                        onChange={e => setHomework(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* NOTE */}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'l_note', 'lesson') : ''}`}>
                                <div className="col-sm-2">
                                    <label className="control-label"> { translate('Коментарий') } </label>
                                </div>
                                <div className="col-sm-10">
                                    <textarea
                                        className="form-control autoresize"
                                        rows={1}
                                        autoComplete="off"
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                    <div className="help-block text-danger">
                                        { note?.length > 1000 && 'Введіть коментар не більше 1000 символів' }
                                    </div>
                                </div>
                            </div>
                            {/*CUSTOM FIELD*/}
                            {
                                customSetting &&
                                    filterDisableEntity(customSetting.customField, "lesson").map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-2">
                                                <label className="control-label"> { item.name } </label>
                                            </div>
                                            <div className="col-sm-10">
                                                { typeField(item, "lesson-edit-") }
                                            </div>
                                        </div>
                                    ))
                            }
                            <div className="form-group">
                                <div className="col-sm-2">
                                    <label className="control-label">
                                        { translate('Файлы') }
                                    </label>
                                </div>
                                <div className="col-sm-10">
                                    <div className="m-b-none m-t-none" style={{ position : 'relative' }}>
                                        <label htmlFor="importFile" style={{ display : 'block' }}>
                                            <a className="m-r-sm m-b-xs pull-left text-center crm-dashed-link" style={{ lineHeight: '15px', position: 'relative', zIndex: 1 }} title={ translate('Загрузить файл') }>
                                                <i className="fa fa-paperclip fa-lg text-muted" />
                                                <br />
                                                { translate('Загрузить новый') }
                                            </a>
                                        </label>
                                        <input
                                            type="file"
                                            multiple={true}
                                            id="importFile"
                                            style={{ display : 'none' }}
                                            onChange={e => sendImportFile(e)}
                                        />

                                        {/* UPLOAD FILES */}
                                        <FileList
                                            storageFile={storageFile}
                                            setStorageFile={setStorageFile}
                                            entity="lesson"
                                            type="modal"
                                            id={props.editLesson.lesson_id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => {
                                closeModal(e, modal);
                                clearState();
                                calendar_block.style.opacity = 1;
                            }}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success" ref={btn_submit}>
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}