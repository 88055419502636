import React, {memo, useEffect, useState} from "react";
import {useTranslate} from "../../../../Hooks/translate.hook";
import AddAgile from "../../../Agile/Modal/AddAgile";
import {openModal} from "../../../../Function/common";
import {useHttp} from "../../../../Hooks/http.hook";
import URL from "../../../../Api/URL.json";

export const TaskInfoBlock = memo( ({ sqlCard, customerData, groupData, access }) => {

    // HOOK START
    const { translate } = useTranslate();
    const { request, error } = useHttp();
    // HOOK END

    // STATE START
    const [ userData, setUserData ] = useState([]);
    // STATE END

    // INIT DATA START
    useEffect(() => {

        async function fetchData () {

            const users = await request(`${URL.back_end}/user`, 'GET');

            if (error) throw error

            setUserData(users);

        }

        fetchData().catch(e => console.log(e))

    }, [])
    // INIT DATA END

    if (!access) return

    return (
        <>
            <div className="row">
                <div className="col-xs-5 text-muted m-b-xs">
                    { translate('Задачи') }
                </div>
                <div className="col-xs-7 m-b-xs text-right">
                    <a className="text-success text-lowercase m-l-sm crm-dashed-link" title={ translate('Добавить задачу') } onClick={() => openModal('add-agile')}>
                        { translate('Добавить') }
                    </a>
                </div>
            </div>

            <AddAgile
                userData={userData}
                customerData={customerData}
                groupData={groupData}
                customer={sqlCard}
            />
        </>
    )
})