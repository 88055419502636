import iconCalendar from "../../../Assets/data/JSON/iconCalendar.json";
import typeLessons from "../../../Assets/data/JSON/typeLessons.json";
import InputChooseMultiObject from "../../../Components/InputChoose/InputChooseMultiObject";
import {useHttp} from "../../../Hooks/http.hook";
import {useContext, useEffect, useRef, useState} from "react";
import URL from "../../../Api/URL.json";
import {SettingContext} from "../../../Context/SettingContext";
import {InputChooseSingleObject} from "../../../Components/InputChoose/InputChooseSingleObject";
import {
    findObject,
    filterDisable,
    filterDisableEntity,
    closeModal,
    findObjectWCheck,
    getRequired, findRequired, addMinutesToTime, openModal
} from "../../../Function/common";
import {typeField} from "../../../Function/TypeField";
import {useTranslate} from "../../../Hooks/translate.hook";
import {validate} from "../../../Function/validateForm";
import moment from "moment";
import {useArchive} from "../../../Hooks/archive.hook";
import {AuthContext} from "../../../Context/AuthContext";
import DeleteModal from "../../../Components/Modal/DeleteModal";

export default function EditLesson (props) {

    // REF START
    const modal = useRef();
    // REF END

    // CONTEXT START
    const { lessonSetting, customSetting } = useContext(SettingContext);
    const auth = useContext(AuthContext);
    // CONTEXT END

    // HOOK START
    const { request, error } = useHttp();
    const { translate } = useTranslate();
    const { pushArchive } = useArchive();
    // HOOK END

    // FIX TIME START
    const fixTime = (time) => time <= 9 ? `0${time}` : time;
    // FIX TIME END

    // STATE START
    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState('');
    const [time, setTime] = useState('');
    const [minute, setMinute] = useState('');
    const [audience, setAudience] = useState('');
    const [group, setGroup] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [subject, setSubject] = useState('');
    const [employee, setEmployee] = useState([]);
    const [l_note, setNote] = useState('');
    // STATE END

    // INIT DATA START
    useEffect(() => {
        if (!Object.keys(props.editLesson).length) return;

        let date_start = props.editLesson.date_start.split('T')[0];
        let timeStart, min;

        let [ startHour, startMinute ] = props.editLesson.date_start.split('T')[1].slice(0, 5).split(':');
        let [ endHour, endMinute ] = props.editLesson.date_end.split('T')[1].slice(0, 5).split(':');

        timeStart = `${fixTime(Number(startHour))}:${fixTime(Number(startMinute))}`;
        min = ((Number(endHour) === 0 ? 24 : Number(endHour)) * 60 + Number(endMinute)) - (Number(startHour) * 60 + Number(startMinute));
        setMinute(min.toString());
        setTime(timeStart);
        setDateStart(date_start);
        setDateEnd(props.editLesson.date_end);
        setAudience({id : props.editLesson.audience});
        setSubject({id : props.editLesson.subject});
        setNote(props.editLesson.l_note ? props.editLesson.l_note : '');

        async function relateFetch () {
            if (props.editLesson.type_lesson === 2) {
                const res = await request(`${URL.back_end}/lesson_group/${props.editLesson.lesson_id}`, 'GET');
                setGroup(res);
            } else {
                const res = await request(`${URL.back_end}/lesson_customer/${props.editLesson.lesson_id}`, 'GET');
                setCustomer(res);
            }

            const res = await request(`${URL.back_end}/lesson_employee/${props.editLesson.lesson_id}`, 'GET');

            setEmployee(res);
        }

        relateFetch().catch(e => console.log(e))

        filterDisableEntity(customSetting ? customSetting.customField : [], "lesson").map(item => {
            document.forms["edit_lesson"].elements["lesson-edit-"+item.systemName].value = props.editLesson[item.systemName];
        })

        dateEndHandler(timeStart, min.toString());
    }, [props.editLesson])
    // INIT DATA END

    // MODAL FUNC START
    function dateEndHandler(t, m) {
        let time, minute;

        if (t !== undefined && m !== undefined) {
            time = t;
            minute = m;
        } else {
            time = document.getElementById('edit-time').value;
            minute = document.getElementById('edit-minute').value;
        }

        if (!time || !minute) return

        const lessonDate = dateStart.split('T')[0];

        return `${lessonDate}T${addMinutesToTime(time, Number(minute))}:00Z`;
    }

    function getTimeEnd (time, minute) {
        if (!time.length || !minute.length) return null;

        let hours = +time.split(":")[0];
        let minutes = Number(time.split(":")[1]) + Number(minute);

        let newDate = new Date();
        newDate.setHours(+hours);
        newDate.setMinutes(minutes);

        return newDate.toLocaleTimeString().slice(0,5);
    }
    // MODAL FUNC END

    // DELETE LESSON START
    function deleteHandler () {
        openModal('delete-lesson-modal');
        openModal('delete-lesson-overlay');
    }

    async function deleteLesson () {
        await request(`${URL.back_end}/lesson/${props.editLesson.lesson_id}`, 'DELETE');
        await pushArchive(3, 'lesson', props.editLesson.lesson_id);
        window.location.reload();
    }
    // DELETE LESSON END

    // EDIT LESSON START
    async function editLesson (e) {
        e.preventDefault();

        const date_end = dateEndHandler();
        const noteValue = document.getElementById('edit-l_note').innerText;

        const validity = {
            "Дата" : !dateStart.length,
            "Время" : !date_end,
            "Предмет" : !Object.keys(subject).length,
            "Комментарий" : findRequired(customSetting.requiredField, 'l_note', 'lesson').require ? !noteValue : noteValue.length > 1000,
            "Педагог" : findRequired(customSetting.requiredField, 'employee', 'lesson').require ? !employee.length : false
        }

        if (validate(validity, translate)) return

        e.currentTarget.disabled = true;

        const time = document.getElementById('edit-time').value;
        const date_start = `${dateStart}T${time}:00Z`;

        let lesson = {
            audience : Object.keys(audience).length > 0 ? audience.id : -1,
            subject : subject.id,
            type_lesson : props.editLesson.type_lesson,
            date_start : date_start,
            date_end : date_end,
            edited : true,
            l_note: noteValue
        }

        filterDisableEntity(customSetting.customField, "lesson").map(item => {
            let value = document.forms["edit_lesson"].elements["lesson-edit-"+item.systemName].value
            lesson[item.systemName] = value.length ? value : null;
        })

        await request(`${URL.back_end}/lesson/${props.editLesson.lesson_id}`, 'PUT', lesson)

        if (props.editLesson.type_lesson === 2) {
            await request(`${URL.back_end}/lesson_group/${props.editLesson.lesson_id}`, 'PUT', group.map(item => item.group_id))
        } else {
            await request(`${URL.back_end}/lesson_customer/${props.editLesson.lesson_id}`, 'PUT', customer.map(item => item.customer_id))
        }

        await request(`${URL.back_end}/lesson_employee/${props.editLesson.lesson_id}`, 'PUT', employee.map(item => item.employee_id))

        await pushArchive(2, 'lesson', props.editLesson.lesson_id, lesson, { edited : props.editLesson.edited, l_note : props.editLesson.l_note, subject : props.editLesson.subject, audience : props.editLesson.audience, date_end : props.editLesson.date_end, date_start : props.editLesson.date_start, type_lesson : props.editLesson.type_lesson });

        if (error) return console.log(error);

        window.location.reload();
    }
    // EDIT LESSON END

    return (
        <div id="edit-lesson" className="modal in" role="dialog" style={{paddingRight: "16px"}} ref={modal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content common-modal-content">
                    {/*HEADER*/}
                    <div className="modal-header">
                        <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title"><i className={ iconCalendar[props.editLesson.type_lesson] } /> { translate(typeLessons[props.editLesson.type_lesson]) } — { translate('Правити') } </h4>
                    </div>
                    <form className="form-horizontal" name="edit_lesson" onSubmit={e => editLesson(e)}>
                        {/*BODY*/}
                        <div className="modal-body p-md">
                            {/*DATE START*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label> { translate('Дата') } </label>
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        className="form-control"
                                        autoComplete="off"
                                        value={dateStart}
                                        onChange={e => setDateStart(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-3 no-padding">
                                    <div className="m-t-xs">
                                        <a>
                                            <big><i className="ion-help-circled" /></big>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*DATE END*/}
                            <div className="form-group required">
                                <label className="col-sm-3 control-label" style={{textAlign: "left"}}>
                                    { translate('Время') }
                                </label>
                                <div className="col-sm-3">
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('с') }&nbsp; </span>
                                        <input
                                            type="time"
                                            id="edit-time"
                                            className="form-control time-input time-from"
                                            name="time"
                                            value={time}
                                            onChange={e => setTime(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="input-group">
                                        <span className="input-group-addon"> { translate('мин') } </span>
                                        <input
                                            type="number"
                                            className="form-control duration-input"
                                            id="edit-minute"
                                            name="minute"
                                            step="1"
                                            min="0"
                                            max="1000"
                                            value={minute}
                                            onChange={e => setMinute(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {
                                    getTimeEnd(time, minute) ?
                                        (
                                            <div className="col-sm-3 text-muted" style={{paddingTop: "8px"}}>
                                                {translate('До')} <span className="time-to">{getTimeEnd(time, minute)}</span>
                                            </div>
                                        )
                                        : null
                                }
                            </div>
                            {/*AUDIENCE*/}
                            <div className="form-group room-group">
                                <div className="col-sm-3">
                                    <label className="control-label"> { translate('Аудитория') } </label>
                                </div>
                                <div className="col-sm-6 spinable">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.room) : []}
                                        setProps={setAudience}
                                        value={lessonSetting ? findObjectWCheck(lessonSetting.room, audience.id) ? findObjectWCheck(lessonSetting.room, audience.id).name : null : null}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                                <div className="col-sm-3 text-muted info" style={{paddingTop: "8px"}}>
                                    <span>{lessonSetting ? filterDisable(lessonSetting.room).length : 0}</span> {translate('доступно')}
                                </div>
                            </div>
                            {/*GROUP*/}
                            {
                                props.editLesson.type_lesson === 2 ?
                                    (
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label">{translate('Группа')}</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <InputChooseMultiObject
                                                    sql={props.groupData.filter(item => item.f_removed === 0)}
                                                    setProps={setGroup}
                                                    value={group}
                                                    topicSearch="title"
                                                    type_id="group_id"
                                                />
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label">{translate('Клиенты')}</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <InputChooseMultiObject
                                                    sql={props.customerData ? props.customerData.filter(item => item.f_removed === 0) : []}
                                                    setProps={setCustomer}
                                                    value={customer}
                                                    limit={props.type_lesson === 1 && 1}
                                                    limitError={props.type_lesson === 1 && "Перевищення ліміту участників"}
                                                    topicSearch="name"
                                                    type_id="customer_id"
                                                />
                                            </div>
                                        </div>
                                    )
                            }
                            {/*SUBJECT*/}
                            <div className="form-group required">
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Предмет')}</label>
                                </div>
                                <div className="col-sm-6">
                                    <InputChooseSingleObject
                                        sql={lessonSetting ? filterDisable(lessonSetting.subject) : []}
                                        setProps={setSubject}
                                        value={lessonSetting ?  findObject(lessonSetting.subject, subject.id) ? findObject(lessonSetting.subject,subject.id).name : "" : null}
                                        topicSearch="name"
                                        type_id="id"
                                    />
                                </div>
                            </div>
                            {/*EMPLOYEE*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'employee', 'lesson') : ''}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Педагог(и)')}</label>
                                </div>
                                <div className="col-sm-6">
                                    <InputChooseMultiObject
                                        sql={props.employeeData.filter(item => item.f_removed === 0)}
                                        setProps={setEmployee}
                                        topicSearch="name"
                                        type_id="employee_id"
                                        value={employee}
                                    />
                                </div>
                            </div>
                            {/*NOTE*/}
                            <div className={`form-group ${customSetting ? getRequired(customSetting.requiredField, 'l_note', 'lesson') : ''}`}>
                                <div className="col-sm-3">
                                    <label className="control-label">{translate('Комментарий')}</label>
                                </div>
                                <div className="col-sm-6">
                                    <span
                                        id="edit-l_note"
                                        className="form-control textarea"
                                        role="textbox"
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                    >
                                        { l_note }
                                    </span>
                                    <div className="help-block text-danger">
                                        { l_note?.length > 1000 && 'Введіть коментар не більше 1000 символів' }
                                    </div>
                                </div>
                            </div>
                            {/*CUSTOM FIELD*/}
                            {
                                customSetting &&
                                    filterDisableEntity(customSetting.customField, "lesson").map(item => (
                                        <div className="form-group">
                                            <div className="col-sm-3">
                                                <label className="control-label"> { item.name } </label>
                                            </div>
                                            <div className="col-sm-6">
                                                { typeField(item, "lesson-edit-") }
                                            </div>
                                            <div className="col-sm-offset-3 col-sm-9" />
                                        </div>
                                    ))
                            }
                        </div>
                        {/*FOOTER*/}
                        <div className="modal-footer">
                            {
                                auth.role.interface === 1 && props.editLesson.status !== 1 ? (
                                    <span className="pull-left">
                                        <a className="btn btn-sm btn-danger btn-outline" onClick={deleteHandler}>
                                            <i className="fa fa-trash-o" />
                                        </a>
                                    </span>
                                ) : null
                            }
                            <button type="button" className="btn btn-sm btn-w-m btn-white" onClick={e => closeModal(e, modal)}>
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-success">
                                <i className="fa fa-save" /> { translate('Сохранить') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <DeleteModal
                id="delete-lesson"
                handler={deleteLesson}
                text="Ви впевнені, що хочете видалити урок?"
            />

        </div>
    )
}