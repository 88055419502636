import React, {memo, useRef, useState} from "react";
import {closeModal} from "../../../../../Function/common";
import {useTranslate} from "../../../../../Hooks/translate.hook";
import {validate} from "../../../../../Function/validateForm";
import {useNavigate} from "react-router-dom";

export const VisitLog = memo(({ sqlCard }) => {

    // REF START
    const modal = useRef();
    // REF END

    // HOOK START
    const { translate } = useTranslate();
    const navigate = useNavigate();
    // HOOK END

    // STATE START
    const [date_start, setDateStart] = useState(() => {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('en-CA')
    });

    const [date_end, setDateEnd] = useState(() => {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('en-CA')
    });

    const [type_lessons, setTypeLessons] = useState([]);
    // STATE END

    // HANDLER START
    function typeLessonsHandler () {
        let elems = document.querySelectorAll('#tariff-type_lessons:checked');
        let arr = [].map.call(elems, function (obj) {
            return obj.value;
        })

        setTypeLessons([...arr]);
    }

    function handlerDate (date, setDate, value, bool) {
        let newDate = new Date(date);

        setDate(new Date(newDate.getFullYear(), newDate.getMonth() + value, bool).toLocaleDateString('en-CA'))
    }
    // HANDLER END

    // SUBMIT START
    function visitLog (e) {
        e.preventDefault();

        let validObj = {
            "Типы" : !type_lessons.length
        }

        if (validate(validObj, translate)) return

        let log = {
            customer_id : sqlCard.customer_id,
            date_start, date_end, type_lessons
        }

        navigate(`/company/1/customer/view/${encodeURI(JSON.stringify(log))}?report=visitLog`);
        closeModal(e, modal);
    }
    // SUBMIT END

    return (
        <div id='visit-log' className='modal in' ref={modal}>
            <div className='modal-dialog'>
                <div className='modal-content common-modal-content'>
                    <form className='form-horizontal' onSubmit={e => visitLog(e)}>
                        <div className='modal-header'>
                            <button type="button" className="close" onClick={e => closeModal(e, modal)}>
                                <span>×</span>
                            </button>
                            <h4 className='modal-title'> { translate('Журнал посещений') } </h4>
                        </div>
                        <div className='modal-body p-md'>
                            {/* CLIENT */}
                            <div className='form-group'>
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Клиент') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <span className="form-control input-sm text-muted"> { sqlCard.name } </span>
                                </div>
                            </div>
                            {/* DATE START */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Начало периода') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-addon" onClick={() => handlerDate(date_start, setDateStart, -1, 1)}>
                                            <a href="#"> M- </a>
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_start}
                                            onChange={e => setDateStart(e.target.value)}
                                        />
                                        <span className="input-group-addon" onClick={() => handlerDate(date_start, setDateStart, 1, 1)}>
                                            <a href="#"> M+ </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* DATE END */}
                            <div className="form-group">
                                <div className="col-sm-4">
                                    <label className="control-label"> { translate('Конец периода') } </label>
                                </div>
                                <div className="col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-addon" onClick={() => handlerDate(date_end, setDateEnd, 0, 0)}>
                                            <a href="#"> M- </a>
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_end}
                                            onChange={e => setDateEnd(e.target.value)}
                                        />
                                        <span className="input-group-addon" onClick={() => handlerDate(date_end, setDateEnd, 2, 0)}>
                                            <a href="#"> M+ </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* TYPE LESSON */}
                            <div className="form-group required">
                                <div className="col-sm-4">
                                    <label className="control-label no-padding"> { translate('Типы') } </label> <br />
                                    <a className="crm-dashed-link small crm-toggle-all active">
                                        <span className="action-toggle-all"> { translate('выбрать все') } </span>
                                        <span className="action-toggle-none"> { translate('снять все') } </span>
                                    </a>
                                </div>
                                <div className="col-sm-8 checkboxlist-simple" onChange={typeLessonsHandler}>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={1}
                                                name="tariff-type_lessons"
                                                id="tariff-type_lessons"
                                            />
                                            &nbsp;{translate('Индивидуальный')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={2}
                                                name="tariff-type_lessons"
                                                id="tariff-type_lessons"
                                            />
                                            &nbsp;{translate('Групповой')}
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={3}
                                                name="tariff-type_lessons"
                                                id="tariff-type_lessons"
                                            />
                                            &nbsp;{translate('Пробный')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-sm btn-w-m btn-white">
                                { translate('Отмена') }
                            </button>
                            <button type="submit" className="btn btn-sm btn-w-m btn-info">
                                <i className="fa fa-check" /> { translate('Сформировать') }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})