import {Link} from "react-router-dom";
import PayColumns from "./PayColumns";
import {useTranslate} from "../../../Hooks/translate.hook";
import React from "react";

export default function PayTable (props) {

    // HOOK START
    const { translate } = useTranslate();
    // HOOK END

    // PAY TABLE FUNC START
    function selection_all (e) {
        let selection = document.getElementsByName('selection[]');
        let status = e.target.checked;

        if (status) {
            let tempSelection = []
            selection.forEach(element => element.checked = true);
            selection.forEach(element => tempSelection.push(element.value));
            props.updateRows(tempSelection);
        }
        else {
            selection.forEach(element => element.checked = false);
            props.updateRows([])
        }
    }

    function renderTbody () {

        if (props.loading) {
            return (
                <tr>
                    <td colSpan={11} style={{ backgroundColor : 'white' }}>
                        <div className='spiner-example'>
                            <div className='sk-spinner sk-spinner-wave sk-margin'>
                                <div className='sk-rect1'></div>
                                <div className='sk-rect2'></div>
                                <div className='sk-rect3'></div>
                                <div className='sk-rect4'></div>
                                <div className='sk-rect5'></div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else if (props.sql.length) {
            return props.sql.map(item => (
                <PayColumns
                    sql={props.sql}
                    customerData={props.customerData}
                    employeeData={props.employeeData}
                    tariffData={props.tariffData}
                    updateSql={props.updateSql}
                    updateRows={props.updateRows}
                    data={item}
                />
            ))
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <div className="empty">
                            { translate('Ничего не найдено.') }
                        </div>
                    </td>
                </tr>
            )
        }

    }
    // PAY TABLE FUNC END

    return (
        <div id="w1-container" className="table-responsive kv-grid-container">
            <table className="crm-table border-bottom kv-grid-table table table-hover table-striped kv-table-wrap">
                <thead>
                    <tr>
                        <th className="kv-all-select kv-align-center kv-align-middle skip-export" style={{width: "3.98%"}}>
                            <input
                                type="checkbox"
                                className="select-on-check-all"
                                name="selection_all"
                                onClick={e => selection_all(e)}
                            />
                        </th>
                        <th style={{width: "9%"}}>
                            <Link to='/company/1/pay/index?lang=en-En&sort=document_date'>
                                {translate('Дата')}
                            </Link>
                        </th>
                        <th style={{width: "9.61%"}}>
                            <Link to='/company/1/pay/index?lang=en-En&sort=pay_type_id'>
                                {translate('Тип операции')}
                            </Link>
                        </th>
                        <th style={{width: "5.35%"}}>
                            <Link to="/company/1/pay/index?lang=en-En&sort=income">
                                {translate('Сумма')}
                            </Link>
                        </th>
                        <th style={{width: "7.44%"}}>
                            <Link to="/company/1/pay/index?lang=en-En&sort=pay_account_id">
                                {translate('Счет (касса)')}
                            </Link>
                        </th>
                        <th style={{width: "7.6%"}}>
                            <Link to="company/1/pay/index?lang=en-En&sort=pay_item_id">
                                {translate('Статья')}
                            </Link>
                        </th>
                        <th style={{width: "12.49%"}}>
                            <Link to="/company/1/pay/index?lang=en-En&sort=destination">
                                {translate('Назначение')}
                            </Link>
                        </th>
                        <th style={{width: "12.16%"}}>
                            <Link to="/company/1/pay/index?lang=en-En&sort=payer_name">
                                {translate('Плательщик')}
                            </Link>
                        </th>
                        <th style={{width: "13.29%"}}>
                            <Link to="/company/1/pay/index?lang=en-En&sort=note">
                                {translate('Комментарий')}
                            </Link>
                        </th>
                    </tr>
                </thead>
                <tbody> { renderTbody() } </tbody>
            </table>
        </div>
    )
}